import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './useStyles';

const CustomizedYLabel = props => {
  const { x, y } = props;
  const classes = useStyles();
  return (
    <text x={x} y={y} transform="translate(0, -2)" className={classes.text}>
      Value of Fund
    </text>
  );
};

CustomizedYLabel.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
};

export default CustomizedYLabel;
