import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  icon: {
    width: 22,
    height: 22,
    backgroundColor: '#ebebeb',
    border: '1px solid #c3c3c3',
    borderRadius: '50%',
  },
  checkedIcon: {
    position: 'relative',
    '&::before': {
      content: '""',
      backgroundColor: '#25333b',
      display: 'block',
      position: 'absolute',
      width: 12,
      height: 12,
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '50%',
    },
  },
}));

export default useStyles;
