import PropTypes from 'prop-types';
import * as React from 'react';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { stringify } from 'query-string';

const LinkToFundTickers = ({ record }) => {
  return record ? (
    <Button
      label="Tickers"
      component={Link}
      to={{
        pathname: '/fund_tickers',
        search: stringify({
          page: 1,
          perPage: 25,
          order: 'ASC',
          filter: JSON.stringify({ fundPortfolioIdEq: record.id }),
        }),
      }}
    >
      Tickers
    </Button>
  ) : null;
};

LinkToFundTickers.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
};

export default LinkToFundTickers;
