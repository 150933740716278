import Routes from 'routes/ApiRoutes';
import FetchHelpers from 'utils/FetchHelpers';

export default {
  show(analysisId, params) {
    const path = Routes.apiV1CustomerPortfolioAnalysisPath(analysisId);
    return FetchHelpers.get(path, params);
  },
  create(params) {
    const path = Routes.apiV1CustomerPortfolioAnalysesPath();
    return FetchHelpers.post(path, params);
  },
  create_batch(params) {
    const path = Routes.createBatchApiV1CustomerPortfolioAnalysesPath();
    return FetchHelpers.post(path, params);
  },
};
