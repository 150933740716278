import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  () => ({
    root: {
      '& img': {
        maxHeight: '100%',
        height: 'auto',
      },
    },
    small: {
      height: 34,
      fontSize: 14,
    },
    medium: {
      height: 46,
      fontSize: 19,
      '@media print': {
        height: 24,
      },
    },
    large: {
      height: 90,
      fontSize: 51,
    },
  }),
  { name: 'Slug' },
);

export default useStyles;
