import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { List, ListItem, ListItemText, Collapse } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import {
  getFormattedNumber,
  getPointedFormattedPercent,
  getPointedIntPercent,
  POINTED_THOUSAND_SEPARATOR_FORMAT,
} from 'utils/numberHelpers';
import { isBlank } from 'utils';
import GroupedAssetClassesCategoriesPresenter from 'presenters/GroupedAssetClassesCategories';
import Row from '../Row';

import useStyles from './useStyles';

const SubTree = ({
  root,
  selected,
  onChangeSelected,
  batchId,
  selectedAccountIds,
  openChildren,
  toggleNode,
  level,
}) => {
  const classes = useStyles();

  const { children, assetClasses } = root;
  const hasSubCategory = !isBlank(children);
  const hasItems = !isBlank(assetClasses);
  const isOpen = openChildren.includes(root.id);

  return (
    <>
      <ListItem onClick={() => (hasSubCategory || hasItems) && toggleNode(root)} className={classes.listItem}>
        <ListItemText className={clsx(classes.listItemText, classes.centerCell)} disableTypography>
          {getFormattedNumber(
            GroupedAssetClassesCategoriesPresenter.totalAmount(root),
            POINTED_THOUSAND_SEPARATOR_FORMAT,
          )}
        </ListItemText>
        <ListItemText className={clsx(classes.listItemText, classes.centerCell)} disableTypography>
          {getPointedFormattedPercent(GroupedAssetClassesCategoriesPresenter.percent(root))}
        </ListItemText>
        <ListItemText
          className={classes.listItemText}
          disableTypography
          style={{ paddingLeft: level * 16, fontWeight: '500' }}
        >
          {GroupedAssetClassesCategoriesPresenter.name(root)}
        </ListItemText>
        <ListItemText className={clsx(classes.listItemText, classes.centerCell)} disableTypography>
          {getPointedIntPercent(GroupedAssetClassesCategoriesPresenter.totalMatchScore(root))}
        </ListItemText>
        {(hasSubCategory || hasItems) && isOpen && <ExpandLess className={classes.icon} />}
        {(hasSubCategory || hasItems) && !isOpen && <ExpandMore className={classes.icon} />}
      </ListItem>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {hasItems &&
            assetClasses.map(item => (
              <Row
                key={item.id}
                selectedAssetClass={selected}
                onChangeSelectedAssetClass={onChangeSelected}
                groupedTickerAssetClass={item}
                batchId={batchId}
                selectedAccountIds={selectedAccountIds}
              />
            ))}
          {hasSubCategory &&
            children.map(node => (
              <SubTree
                key={node.id}
                root={node}
                openChildren={openChildren}
                toggleNode={toggleNode}
                level={level + 1}
                selected={selected}
                onChangeSelected={onChangeSelected}
                batchId={batchId}
                selectedAccountIds={selectedAccountIds}
              />
            ))}
        </List>
      </Collapse>
    </>
  );
};

export default SubTree;

SubTree.propTypes = {
  root: PropTypes.shape(),
  selected: PropTypes.number,
  onChangeSelected: PropTypes.func.isRequired,
  batchId: PropTypes.number.isRequired,
  selectedAccountIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  openChildren: PropTypes.arrayOf(PropTypes.number),
  toggleNode: PropTypes.func.isRequired,
  level: PropTypes.number,
};
