import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '0 60px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    fontFamily: theme.typography.font.ttHovesPro,
    fontWeight: 'normal',
    fontSize: 40,
    textAlign: 'center',
    marginBottom: 9,
    marginTop: 12,
  },
  description: {
    fontFamily: theme.typography.font.ttHovesPro,
    fontWeight: 300,
    fontSize: 15,
    color: '#000',
    lineHeight: '22px',
    maxWidth: 536,
    marginTop: 20,
  },
  buttonWrapper: {
    alignSelf: 'center',
    marginTop: 17,
    textTransform: 'uppercase',
  },
  buttonRoot: {
    fontFamily: theme.typography.font.ttHovesPro,
    fontSize: 12,
    letterSpacing: '0.01em',
  },
}));

export default useStyles;
