import React from 'react';
import { BooleanField, Filter, TextInput, List, Datagrid, TextField, NumberField } from 'react-admin';

import ExportsRepository from 'repositories/admin/fund_ticker/ExportsRepository';

import FundTickerActions from './FundTickerActions';

const FundTickerFilter = props => (
  <Filter {...props}>
    <TextInput label="Fund ID" source="fundIdEq" alwaysOn />
    <TextInput
      label="Company Ticker / Company Name"
      source="CompanyTickerOrCompanyNameCont"
      style={{ width: 270 }}
      alwaysOn
    />
  </Filter>
);

const FundTickerList = props => {
  const handleExport = () => {
    ExportsRepository.download();
  };

  return (
    <List
      {...props}
      filters={<FundTickerFilter />}
      actions={<FundTickerActions handleExportButtonClick={handleExport} />}
    >
      <Datagrid>
        <TextField source="id" />
        <TextField source="company_ticker" />
        <TextField source="company_name" />
        <NumberField label="Total Weight(%)" source="total_weight" options={{ maximumFractionDigits: 2 }} />
        <NumberField label="Scoreable Weight(%)" source="scoreable_weight" options={{ maximumFractionDigits: 2 }} />
        <NumberField source="amount" />
        <TextField source="company_cusip" />
        <BooleanField source="scored" sortBy="type" />
      </Datagrid>
    </List>
  );
};

export default FundTickerList;
