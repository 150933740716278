import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  card: {
    padding: theme.spacing(3),
    paddingLeft: theme.spacing(1),
  },
  treeItem: {
    padding: [theme.spacing(1.5), 0],
    '&:focus > .MuiTreeItem-content .MuiTreeItem-label, &:hover > .MuiTreeItem-content .MuiTreeItem-label': {
      background: 'transparent',
    },
    '& > .MuiTreeItem-content': {
      gap: '8px',
    },
  },
  treeItemContent: {
    display: 'flex',
    gap: '16px',
    alignItems: 'center',
  },
  buttons: {
    marginLeft: 'auto',
    display: 'flex',
    gap: '8px',
  },
  field: {
    pointerEvents: 'none',
  },
}));

export default useStyles;
