/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import LensesRepository from 'repositories/LensesRepository';
import { FETCH_STATUSES } from 'presenters/FetchStatusPresenter';

const initialState = {
  lenses: {
    status: FETCH_STATUSES.idle,
    items: [],
    meta: null,
  },
};

const customerLensDefinitionSlice = createSlice({
  name: 'customerLensDefinition',
  initialState,
  reducers: {
    loadLensesStart(state) {
      state.lenses.status = FETCH_STATUSES.loading;
    },
    loadLensesSuccess(state, { payload }) {
      state.lenses.status = FETCH_STATUSES.success;
      state.lenses.items = payload.lenses;
      state.lenses.meta = payload.meta;
    },
    loadLensesFail(state) {
      state.lenses.status = FETCH_STATUSES.failure;
      state.lenses.items = null;
      state.lenses.meta = null;
    },
  },
});

export const { loadLensesStart, loadLensesSuccess, loadLensesFail } = customerLensDefinitionSlice.actions;

export default customerLensDefinitionSlice.reducer;

export const useCustomerLensDefinitionActions = () => {
  const dispatch = useDispatch();

  const loadLenses = () => {
    dispatch(loadLensesStart());

    return LensesRepository.index()
      .then(({ data }) => dispatch(loadLensesSuccess(data)))
      .catch(() => dispatch(loadLensesFail()));
  };

  return {
    loadLenses,
  };
};
