/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import ImportRequestRepository from 'repositories/admin/fund/ImportRequestRepository';
import BaseFundsRepository from 'repositories/admin/fund/BaseFundsRepository';
import { FETCH_STATUSES } from 'presenters/FetchStatusPresenter';

const initialState = {
  currentFundImport: {
    id: null,
    state: null,
    status: FETCH_STATUSES.idle,
    errors: '',
    data: [],
  },

  finishFundImport: {
    status: FETCH_STATUSES.idle,
    errors: '',
  },

  baseFunds: {
    status: FETCH_STATUSES.idle,
    data: [],
  },

  matchedFunds: {
    status: FETCH_STATUSES.idle,
    data: [],
  },
};

const fundImportSlice = createSlice({
  name: 'currentFundImport',
  initialState,
  reducers: {
    createFundImportRequestStart(state) {
      state.currentFundImport.status = FETCH_STATUSES.loading;
    },
    createFundImportRequestSuccess(state, { payload }) {
      state.currentFundImport.status = FETCH_STATUSES.success;
      state.currentFundImport.id = payload.id;
      state.currentFundImport.state = payload.state;
    },
    createFundImportRequestFail(state, { payload }) {
      state.currentFundImport.status = FETCH_STATUSES.failure;
      state.currentFundImport.state = payload.state;
      state.currentFundImport.errors = payload.messages;
    },
    loadFundImportRequestStart(state) {
      state.currentFundImport.status = FETCH_STATUSES.loading;
    },
    loadFundImportRequestSuccess(state, { payload }) {
      state.currentFundImport.status = FETCH_STATUSES.success;
      state.currentFundImport.state = payload.state;
      state.currentFundImport.data = payload.validationResult;
    },
    loadFundImportRequestFail(state, { payload }) {
      state.currentFundImport.status = FETCH_STATUSES.failure;
      state.currentFundImport.errors = payload.messages;
      state.currentFundImport.state = payload.state;
    },
    finishFundImportStart(state) {
      state.currentFundImport.status = FETCH_STATUSES.loading;
    },
    finishFundImportSuccess(state) {
      state.currentFundImport.status = FETCH_STATUSES.success;
    },
    finishFundImportFail(state, { payload }) {
      state.currentFundImport.status = FETCH_STATUSES.failure;
      state.currentFundImport.errors = payload.messages;
    },
    loadBaseFundsStart(state) {
      state.baseFunds.status = FETCH_STATUSES.loading;
    },
    loadBaseFundsSuccess(state, { payload }) {
      state.baseFunds.status = FETCH_STATUSES.success;
      state.baseFunds.data = payload.funds;
    },
    loadBaseFundsFail(state) {
      state.baseFunds.status = FETCH_STATUSES.failure;
    },
    fundsSearchStart(state) {
      state.matchedFunds.status = FETCH_STATUSES.loading;
    },
    fundsSearchSuccess(state, { payload }) {
      state.matchedFunds.status = FETCH_STATUSES.success;
      state.matchedFunds.data = payload.items;
    },
    fundsSearchFail(state) {
      state.matchedFunds.status = FETCH_STATUSES.failure;
    },
  },
});

export const {
  createFundImportRequestStart,
  createFundImportRequestSuccess,
  createFundImportRequestFail,
  loadFundImportRequestStart,
  loadFundImportRequestSuccess,
  loadFundImportRequestFail,
  finishFundImportStart,
  finishFundImportSuccess,
  finishFundImportFail,
  loadBaseFundsStart,
  loadBaseFundsSuccess,
  loadBaseFundsFail,
  fundsSearchStart,
  fundsSearchSuccess,
  fundsSearchFail,
} = fundImportSlice.actions;

export default fundImportSlice.reducer;

export const useFundImportRequestActions = () => {
  const dispatch = useDispatch();

  const createFundImportRequest = async params => {
    dispatch(createFundImportRequestStart());

    try {
      const { importRequest } = await ImportRequestRepository.create(params);
      dispatch(createFundImportRequestSuccess(importRequest));
      return importRequest;
    } catch (error) {
      dispatch(createFundImportRequestFail(error));
      return error;
    }
  };

  const loadFundImportRequest = async importRequestId => {
    dispatch(loadFundImportRequestStart());

    try {
      const {
        data: { importRequest },
      } = await ImportRequestRepository.show(importRequestId);
      dispatch(loadFundImportRequestSuccess(importRequest));

      return importRequest;
    } catch (error) {
      dispatch(loadFundImportRequestFail(error));
      return error;
    }
  };

  const finishFundImport = async (requestIdParams, params) => {
    dispatch(finishFundImportStart());

    try {
      const {
        data: { importRequest },
      } = await ImportRequestRepository.update(requestIdParams, params);
      dispatch(finishFundImportSuccess(importRequest));

      return importRequest;
    } catch (error) {
      dispatch(finishFundImportFail(error));
      return error;
    }
  };

  const loadBaseFunds = async () => {
    dispatch(loadBaseFundsStart());

    try {
      const { data } = await BaseFundsRepository.index();
      dispatch(loadBaseFundsSuccess(data));

      return data;
    } catch (e) {
      dispatch(loadBaseFundsFail());

      return e;
    }
  };

  const fundsSearch = async params => {
    dispatch(fundsSearchStart());

    try {
      const { data } = await BaseFundsRepository.search(params);
      dispatch(fundsSearchSuccess(data));

      return data;
    } catch (e) {
      dispatch(fundsSearchFail());

      return e;
    }
  };

  return {
    createFundImportRequest,
    loadFundImportRequest,
    finishFundImport,
    loadBaseFunds,
    fundsSearch,
  };
};
