import PropTypes from 'prop-types';
import PillarPresenter from 'presenters/PillarPresenter';
import Presenter from 'utils/PropTypesPresenter';

export default new Presenter(
  {
    id: PropTypes.number,
    grouppedWeightText: PropTypes.string,
    grouppedWeightFullText: PropTypes.string,
    pillar: PillarPresenter.shape(),
  },
  {
    sortedPillarAnalyses(analyses) {
      return analyses.slice().sort((a, b) => a.pillar.displayOrder - b.pillar.displayOrder);
    },
  },
);
