import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

import useStyles from './useStyles';

const HowCalculated = props => {
  const { onClose } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.title}>How is this calculated?</div>
      <div className={classes.description}>
        <p>The Reflection Match score is an overall measure of how aligned the investment is to your Lens.</p>
        <p>
          To calculate your Reflection Match score, securities are scored by assessing over 400 ESG data points covering
          16 ESG-related policies and procedural areas.
        </p>
        <div className={classes.terms}>
          Are they leading their industry or are they trailing in terms of:
          <ul className={classes.termsList}>
            <li className={classes.term}>Clear policies</li>
            <li className={classes.term}>Effective practices</li>
            <li className={classes.term}>Disclosure</li>
          </ul>
        </div>
        <p>
          These securities scores are then weighted based upon how important each of the 16 ESG-related policies and
          procedures are to you as defined in your Lens and how much of your investment is in these securities.
        </p>
      </div>
      <div className={classes.buttonWrapper}>
        <Button variant="text" size="small" onClick={onClose} classes={{ root: classes.buttonRoot }}>
          Close
        </Button>
      </div>
    </div>
  );
};

HowCalculated.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default HowCalculated;
