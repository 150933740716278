import PropTypes from 'prop-types';

import Presenter from 'utils/PropTypesPresenter';

import PillarPresenter from 'presenters/PillarPresenter';
import { IMPORTANCE_LEVELS } from 'presenters/ValuePresenter';
import CustomerCategoryValuePresenter from 'presenters/CustomerCategoryValuePresenter';

export default new Presenter(
  {
    id: PropTypes.number,
    value: PropTypes.string,
    pillar: PillarPresenter.shape(),
  },
  {
    importantsLevel(customerPillarValue) {
      const value = this.value(customerPillarValue);
      return IMPORTANCE_LEVELS.find(level => level.key === value).value;
    },
    getRecalculatedPillarValue(pillarValue, customerCategoryValues) {
      const currentPillar = this.pillar(pillarValue);
      const pillarId = PillarPresenter.id(currentPillar);

      const pillarCustomerCategoryValues = CustomerCategoryValuePresenter.getCustomerCategoryValuesForPillar(
        pillarId,
        customerCategoryValues,
      );

      const categoryValuesPoints = CustomerCategoryValuePresenter.getPointsForCustomerCategoryValues(
        pillarCustomerCategoryValues,
      );

      const weightiestCategoryValue = CustomerCategoryValuePresenter.getWeightiestCategoryValue(categoryValuesPoints);

      return {
        ...pillarValue,
        value: weightiestCategoryValue,
      };
    },
  },
);
