/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import CustomerLensesRepository from 'repositories/customer/CustomerLensesRepository';
import AdvisorCustomerLensesRepository from 'repositories/advisor/household/CustomerLensesRepository';
import { FETCH_STATUSES } from 'presenters/FetchStatusPresenter';

const initialState = {
  customerLens: {
    status: FETCH_STATUSES.idle,
    data: {},
  },
};

const customerLensCreationSlice = createSlice({
  name: 'customerLensCreation',
  initialState,
  reducers: {
    loadCustomerLensStart(state) {
      state.customerLens.status = FETCH_STATUSES.loading;
    },
    loadCustomerLensSuccess(state, { payload }) {
      state.customerLens.status = FETCH_STATUSES.success;
      state.customerLens.data = { ...payload.customerLens };
    },
    loadCustomerLensFail(state) {
      state.customerLens.status = FETCH_STATUSES.failure;
    },
    resetCustomerLensState(state) {
      state.customerLens.status = FETCH_STATUSES.idle;
      state.customerLens.data = {};
    },
    createCustomerLensStart(state) {
      state.customerLens.status = FETCH_STATUSES.loading;
    },
    createCustomerLensSuccess(state, { payload }) {
      state.customerLens.status = FETCH_STATUSES.success;
      state.customerLens.data = { ...payload.customerLens };
    },
    createCustomerLensFail(state) {
      state.customerLens.status = FETCH_STATUSES.failure;
    },
    setCustomerLens(state, { payload }) {
      state.customerLens.data = payload;
    },
    updateCustomerLensStart(state) {
      state.customerLens.status = FETCH_STATUSES.loading;
    },
    updateCustomerLensSuccess(state) {
      state.customerLens.status = FETCH_STATUSES.success;
    },
    updateCustomerLensFail(state) {
      state.customerLens.status = FETCH_STATUSES.failure;
    },
    resetCustomerLensStart(state) {
      state.customerLens.status = FETCH_STATUSES.loading;
    },
    resetCustomerLensSuccess(state, { payload }) {
      state.customerLens.status = FETCH_STATUSES.success;
      state.customerLens.data = { ...payload.customerLens };
    },
    resetCustomerLensFail(state) {
      state.customerLens.status = FETCH_STATUSES.failure;
    },
  },
});

export const {
  loadCustomerLensStart,
  loadCustomerLensSuccess,
  loadCustomerLensFail,
  resetCustomerLensState,
  createCustomerLensStart,
  createCustomerLensSuccess,
  createCustomerLensFail,
  updateCustomerLensStart,
  updateCustomerLensSuccess,
  updateCustomerLensFail,
  setCustomerLens,
  resetCustomerLensStart,
  resetCustomerLensSuccess,
  resetCustomerLensFail,
} = customerLensCreationSlice.actions;

export default customerLensCreationSlice.reducer;

export const useCustomerLensCreationActions = () => {
  const dispatch = useDispatch();

  const loadCustomerLens = customerLensId => {
    dispatch(loadCustomerLensStart());

    return CustomerLensesRepository.show(customerLensId)
      .then(({ data }) => dispatch(loadCustomerLensSuccess(data)))
      .catch(() => dispatch(loadCustomerLensFail()));
  };

  const createCustomerLens = params => {
    dispatch(createCustomerLensStart());

    return CustomerLensesRepository.create({ customerLens: params })
      .then(({ data }) => dispatch(createCustomerLensSuccess(data)))
      .catch(() => dispatch(createCustomerLensFail()));
  };

  const createAdvisorCustomerLens = (householdId, params) => {
    return AdvisorCustomerLensesRepository.create(householdId, { customerLens: params })
      .then(({ data }) => dispatch(createCustomerLensSuccess(data)))
      .catch(() => dispatch(createCustomerLensFail()));
  };

  const updateCustomerLens = (customerLensId, params) => {
    dispatch(updateCustomerLensStart());

    return CustomerLensesRepository.update(customerLensId, { customerLens: params })
      .then(() => dispatch(updateCustomerLensSuccess()))
      .catch(() => dispatch(updateCustomerLensFail()));
  };

  const changeCustomerLens = customerLens => {
    dispatch(setCustomerLens(customerLens));
  };

  const resetCustomerLens = customerLensId => {
    dispatch(resetCustomerLensStart());

    return CustomerLensesRepository.reset(customerLensId)
      .then(({ data }) => dispatch(resetCustomerLensSuccess(data)))
      .catch(() => dispatch(resetCustomerLensFail()));
  };

  const resetCustomerLensStateAction = () => {
    dispatch(resetCustomerLensState());
  };

  return {
    loadCustomerLens,
    resetCustomerLensState: resetCustomerLensStateAction,
    createCustomerLens,
    createAdvisorCustomerLens,
    updateCustomerLens,
    changeCustomerLens,
    resetCustomerLens,
  };
};
