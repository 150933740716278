export const COMPANY_TYPE = {
  company: 'Company',
  fund: 'Fund',
};

export const TICKER_KIND = {
  core: 'core',
  other: 'other',
  stock: 'stock',
};
