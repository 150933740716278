import React from 'react';
import PropTypes from 'prop-types';
import { Filter, TextInput, List, Datagrid, TextField, DateField } from 'react-admin';
import LinkToFundTickers from './LinkToFundTickers';

import ExportsRepository from 'repositories/admin/fund_portfolio/ExportsRepository';

import FundPortfolioActions from './FundPortfolioActions';

const FundPortfolioFilter = props => (
  <Filter {...props}>
    <TextInput label="Fund ID" source="fundIdEq" alwaysOn />
    <TextInput label="Fund Ticker / Fund Name" source="FundTickerOrFundNameCont" style={{ width: 270 }} alwaysOn />
  </Filter>
);

const FundPortfolioList = props => {
  const { data } = props;

  const handleExport = () => {
    ExportsRepository.download();
  };

  return (
    <List
      {...props}
      filters={<FundPortfolioFilter />}
      actions={<FundPortfolioActions handleExportButtonClick={handleExport} />}
    >
      <Datagrid>
        <TextField source="id" />
        <TextField source="fund_ticker" />
        <TextField source="fund_name" />
        <TextField source="nport" />
        <TextField source="sync_state" />
        <DateField source="created_at" />
        <LinkToFundTickers record={data} />
      </Datagrid>
    </List>
  );
};

FundPortfolioList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
};

export default FundPortfolioList;
