import PropTypes from 'prop-types';

import Presenter from 'utils/PropTypesPresenter';

import HouseholdPresenter from 'presenters/HouseholdPresenter';
import PartnerPresenter from 'presenters/PartnerPresenter';

const USER_TYPES = {
  admin: 'Admin',
  customer: 'Customer',
  advisor: 'Advisor',
};

export default new Presenter(
  {
    id: PropTypes.number,
    fullName: PropTypes.string,
    email: PropTypes.string,
    type: PropTypes.string,
    householdId: PropTypes.number,
    externalId: PropTypes.string,
    accountIds: PropTypes.arrayOf(PropTypes.number),
    recommendationsAvailable: PropTypes.bool,
    partner: PartnerPresenter.shape(),
  },
  {
    isAdmin(user) {
      return this.type(user) === USER_TYPES.admin;
    },
    isCustomer(user) {
      return this.type(user) === USER_TYPES.customer;
    },
    isAdvisor(user) {
      return this.type(user) === USER_TYPES.advisor;
    },
    isReportActive(user, analysisId) {
      const currentCustomerPortfolioAnalysisId = this.customerPortfolioAnalysisId(user);
      return analysisId === currentCustomerPortfolioAnalysisId;
    },
    havingSeveralCustomers(user) {
      const household = this.household(user);
      const customers = HouseholdPresenter.customers(household);
      return customers.length > 1;
    },
    sortedCustomers(customers) {
      return customers.slice().sort((c1, c2) => this.externalId(c1) - this.externalId(c2));
    },
    partnerSlug(user) {
      return this.partner(user).slug;
    },
  },
);
