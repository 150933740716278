import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  field: {
    width: '100%',
    marginBottom: 25,
  },
  error: {
    position: 'absolute',
    color: '#D73E34',
    fontSize: theme.typography.pxToRem(9),
    paddingLeft: 16,
    top: '100%',
  },
  imagePreviewer: {
    position: 'relative',
  },
  removeImageWrapper: {
    position: 'absolute',
    top: -10,
    right: -30,
  },
  uploadField: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    height: 120,
    borderRadius: 4,
    border: [1.5, 'dashed', '#dfe2ef'],
    marginTop: 20,
  },
  uploadText: {
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 1.3,
    paddingLeft: 16,
    textAlign: 'left',
    width: 170,
    color: theme.palette.text.secondary,
  },
  input: {
    position: 'absolute',
    opacity: 0,
    width: '100%',
    height: '100%',
    top: 0,
  },
  uploadWrapper: {
    display: 'flex',
    alignItems: 'center',
    margin: [0, 'auto'],
  },
  uploadedFile: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 20,
  },
  uploadedFileName: {
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 1,
    paddingLeft: 8,
  },
  removeButton: {
    margin: 0,
  },
  removeButtonIcon: {
    width: 12,
    height: 12,
  },
  imagePreview: {
    maxHeight: 100,
    maxWidth: 350,
  },
}));
