import { useSelector } from 'react-redux';

import { useKpiActions } from 'admin/sections/Kpi/KpiSlice';
import getFetchStatus from 'utils/getFetchStatus';

export const useKpi = () => {
  const { handleKpiLoad, handleKpiExport } = useKpiActions();
  const data = useSelector(state => state.KpiSlice.kpi.data);
  const isLoading = useSelector(state => state.KpiSlice.kpi.isLoading);
  const kpiStatus = useSelector(state => state.KpiSlice.kpi.status);
  const kpiStatuses = getFetchStatus(kpiStatus);

  return {
    handleKpiLoad,
    handleKpiExport,
    data,
    kpiStatuses,
    isLoading,
  };
};
