import Routes from 'routes/ApiRoutes';
import FetchHelpers from 'utils/FetchHelpers';

export default {
  index(params) {
    const path = Routes.apiV1AdvisorSavedFundsPath();
    return FetchHelpers.get(path, params);
  },
  create(params) {
    const path = Routes.apiV1AdvisorSavedFundsPath();
    return FetchHelpers.post(path, params);
  },
  destroy(id, params) {
    const path = Routes.apiV1AdvisorSavedFundPath(id);
    return FetchHelpers.delete(path, params);
  },
};
