import MuiButton from './MuiButton';
import MuiLinearProgress from './MuiLinearProgress';
import MuiMobileStepper from './MuiMobileStepper';
import MuiPopover from './MuiPopover';
import MuiChip from './MuiChip';
import MuiIconButton from './MuiIconButton';
import MuiAccordion from './MuiAccordion';
import MuiAccordionSummary from './MuiAccordionSummary';
import MuiAccordionDetails from './MuiAccordionDetails';
import MuiTab from './MuiTab';
import MuiTabs from './MuiTabs';
import MuiTooltip from './MuiTooltip';
import MuiInputBase from './MuiInputBase';

export default {
  ...MuiButton,
  ...MuiLinearProgress,
  ...MuiMobileStepper,
  ...MuiPopover,
  ...MuiChip,
  ...MuiIconButton,
  ...MuiAccordion,
  ...MuiAccordionSummary,
  ...MuiAccordionDetails,
  ...MuiTab,
  ...MuiTabs,
  ...MuiTooltip,
  ...MuiInputBase,
};
