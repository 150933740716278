/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import KpiRepository from 'repositories/admin/KpiRepository';
import { FETCH_STATUSES } from 'presenters/FetchStatusPresenter';

import { format } from 'date-fns';

const FULL_DATE_FORMAT = 'EEE, d MMM yyyy HH:mm:ss';

const initialState = {
  kpi: {
    data: {},
    status: FETCH_STATUSES.loading,
  },
};

const kpiSlice = createSlice({
  name: 'kpi',
  initialState,
  reducers: {
    loadKpiStart(state) {
      state.kpi.status = FETCH_STATUSES.loading;
    },
    loadKpiSuccess(state, { payload }) {
      state.kpi.data = payload.kpi;
      state.kpi.status = FETCH_STATUSES.success;
    },
  },
});

export const { loadKpiStart, loadKpiSuccess } = kpiSlice.actions;

export default kpiSlice.reducer;

export const useKpiActions = () => {
  const dispatch = useDispatch();

  const handleKpiLoad = async (
    lastSignInDateStart,
    lastSignInDateEnd,
    creationDateStart,
    creationDateEnd,
    fromDate,
    toDate,
  ) => {
    dispatch(loadKpiStart());
    const { data } = await KpiRepository.show({
      q: {
        active_after: format(lastSignInDateStart, FULL_DATE_FORMAT),
        active_before: format(lastSignInDateEnd, FULL_DATE_FORMAT),
        created_after: format(creationDateStart, FULL_DATE_FORMAT),
        created_before: format(creationDateEnd, FULL_DATE_FORMAT),
        from_date: format(fromDate, FULL_DATE_FORMAT),
        to_date: format(toDate, FULL_DATE_FORMAT),
      },
    });
    dispatch(loadKpiSuccess(data));
  };

  const handleKpiExport = async (
    lastSignInDateStart,
    lastSignInDateEnd,
    creationDateStart,
    creationDateEnd,
    fromDate,
    toDate,
    template,
  ) => {
    await KpiRepository.export({
      q: {
        active_after: format(lastSignInDateStart, FULL_DATE_FORMAT),
        active_before: format(lastSignInDateEnd, FULL_DATE_FORMAT),
        created_after: format(creationDateStart, FULL_DATE_FORMAT),
        created_before: format(creationDateEnd, FULL_DATE_FORMAT),
        from_date: format(fromDate, FULL_DATE_FORMAT),
        to_date: format(toDate, FULL_DATE_FORMAT),
      },
      template,
      format: 'xlsx',
    });
  };

  return {
    handleKpiLoad,
    handleKpiExport,
  };
};
