export const USER_TYPES = [
  { id: 'Admin', name: 'Admin' },
  { id: 'Customer', name: 'Customer' },
  { id: 'Advisor', name: 'Advisor' },
];

export const UserType = {
  admin: 'Admin',
  customer: 'Customer',
  advisor: 'Advisor',
};
