export default {
  user_type: 'Advisor',
  user_id: '1116',
  institution_id: '2',
  advisor_name: 'Tony Advisor',
  advisor_id: '1116',
  household_id: '1116',
  clients: [
    {
      client_id: '1121',
      client_name: 'Jarvis',
      birth_year: '1950',
    },
    {
      client_id: '1122',
      client_name: 'Friday',
      birth_year: '1980',
    },
    {
      client_id: '1123',
      client_name: 'Karen',
      birth_year: '2000',
    },
  ],
  accounts: [
    {
      account_id: '1116',
      account_owner: ['1121'],
      account_name: 'Pepps',
      total_account_value: '70000.0',
      tickers: [
        {
          ticker: 'ESGU',
          ticker_amount: '100000.0',
        },
        {
          ticker: 'SUSC',
          ticker_amount: '100000.0',
        },
        {
          ticker: 'LQD',
          ticker_amount: '100000.0',
        },
        {
          ticker: 'IVV',
          ticker_amount: '100000.0',
        },
      ],
      holdings: [
        {
          holding_name: 'I',
          holding_amount: '20000.0',
        },
      ],
      asset_classes: [
        {
          asset_class_id: '3',
          asset_class_percent: '100',
        },
      ],
    },
    {
      account_id: '1117',
      account_owner: ['1122'],
      account_name: 'Happy',
      total_account_value: '80000.0',
      tickers: [
        {
          ticker: 'ESGU',
          ticker_amount: '100000.0',
        },
        {
          ticker: 'SUSC',
          ticker_amount: '100000.0',
        },
      ],
      holdings: [
        {
          holding_name: 'II',
          holding_amount: '20000.0',
        },
      ],
      asset_classes: [
        {
          asset_class_id: '3',
          asset_class_percent: '100',
        },
      ],
    },
    {
      account_id: '1118',
      account_owner: ['1123'],
      account_name: 'Peter',
      total_account_value: '80000.0',
      tickers: [
        {
          ticker: 'LQD',
          ticker_amount: '100000.0',
        },
        {
          ticker: 'IVV',
          ticker_amount: '100000.0',
        },
      ],
      holdings: [
        {
          holding_name: 'II',
          holding_amount: '20000.0',
        },
      ],
      asset_classes: [
        {
          asset_class_id: '3',
          asset_class_percent: '100',
        },
      ],
    },
    {
      account_id: '1119',
      account_owner: ['1123'],
      account_name: 'Nad',
      total_account_value: '80000.0',
      tickers: [
        {
          ticker: 'ESGU',
          ticker_amount: '100000.0',
        },
        {
          ticker: 'SUSC',
          ticker_amount: '100000.0',
        },
      ],
      holdings: [
        {
          holding_name: 'II',
          holding_amount: '20000.0',
        },
      ],
      asset_classes: [
        {
          asset_class_id: '3',
          asset_class_percent: '100',
        },
      ],
    },
  ],
};
