import React from 'react';
import PropTypes from 'prop-types';
import { BarChart, ResponsiveContainer, XAxis, YAxis, CartesianGrid } from 'recharts';

import CustomizedTick from './components/CustomizedTick';
import CustomizedItalicTick from './components/CustomizedItalicTick';
import CustomizedYAxisTick from './components/CustomizedYAxisTick';

import useStyles from './useStyles';

const Chart = props => {
  const { data, verticalPoints, children } = props;

  const classes = useStyles();

  return (
    <ResponsiveContainer>
      <BarChart
        data={data}
        className={classes.barChart}
        margin={{ top: 0, right: 6, bottom: 0, left: 39 }}
        barCategoryGap="10%"
        barGap={4}
      >
        <CartesianGrid verticalPoints={verticalPoints} stroke="#eee" />
        <XAxis
          dataKey="seeWeightLabel"
          orientation="top"
          allowDataOverflow
          tick={<CustomizedItalicTick />}
          tickLine={false}
          axisLine={false}
        />
        <XAxis
          dataKey="label"
          orientation="top"
          allowDataOverflow
          xAxisId="label"
          tick={<CustomizedTick />}
          tickLine={false}
          axisLine={false}
        />
        <YAxis
          ticks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
          label={{ value: 'Value of Fund', angle: 0, position: 'top', offset: 30 }}
          tickFormatter={dat => `${dat} %`}
          interval={0}
          tickLine={false}
          axisLine={false}
          tick={<CustomizedYAxisTick />}
        />
        {children}
      </BarChart>
    </ResponsiveContainer>
  );
};

Chart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  verticalPoints: PropTypes.arrayOf(PropTypes.number).isRequired,
  children: PropTypes.node.isRequired,
};

export default Chart;
