import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  theme => ({
    alignedValue: {
      paddingLeft: 18,
    },
    itemData: {
      fontWeight: 300,
      fontSize: 16,
      '& td:first-child': {
        paddingLeft: 21,
        paddingTop: 4,
        paddingBottom: 10,
        '@media print': {
          paddingBottom: 4,
        },
      },
      borderBottom: '1px solid #d8d8d8',
      '@media print': {
        fontSize: 13,
        letterSpacing: '0.01em',
      },
    },
    action: {
      justifyContent: 'flex-end',
      width: '100%',
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        justifyContent: 'center',
        width: '130%',
      },
      '@media print': {
        padding: 0,
      },
    },
    actionWrapper: {
      '@media print': {
        display: 'none',
      },
    },
  }),
  { name: 'CustomerCategoryValue' },
);

export default useStyles;
