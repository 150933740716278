/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import FundsRepository from 'repositories/FundsRepository';
import CompaniesRepository from 'repositories/CompaniesRepository';
import UsersRepository from 'repositories/UsersRepository';
import AssetClassesRepository from 'repositories/advisor/AssetClassesRepository';
import { FETCH_STATUSES } from 'presenters/FetchStatusPresenter';

const initialState = {
  funds: {
    status: FETCH_STATUSES.idle,
    data: [],
  },

  companies: {
    status: FETCH_STATUSES.idle,
    data: [],
  },

  assetClasses: {
    status: FETCH_STATUSES.idle,
    data: [],
  },
};

const manuallyEnteredPortfolioSlice = createSlice({
  name: 'manuallyEnteredPortfolio',
  initialState,
  reducers: {
    loadFundsStart(state) {
      state.funds.status = FETCH_STATUSES.loading;
    },
    loadFundsSuccess(state, { payload }) {
      state.funds.status = FETCH_STATUSES.success;
      state.funds.data = payload.funds;
    },
    loadFundsFail(state) {
      state.funds.status = FETCH_STATUSES.failure;
    },
    loadCompaniesStart(state) {
      state.companies.status = FETCH_STATUSES.loading;
    },
    loadCompaniesSuccess(state, { payload }) {
      state.companies.status = FETCH_STATUSES.success;
      state.companies.data = payload.companies;
    },
    loadCompaniesFail(state) {
      state.companies.status = FETCH_STATUSES.failure;
    },
    loadAssetClassesStart(state) {
      state.assetClasses.status = FETCH_STATUSES.loading;
    },
    loadAssetClassesSuccess(state, { payload }) {
      state.assetClasses.status = FETCH_STATUSES.success;
      state.assetClasses.data = payload.assetClasses;
    },
    loadAssetClassesFail(state) {
      state.assetClasses.status = FETCH_STATUSES.failure;
    },
  },
});

export const {
  loadFundsStart,
  loadFundsSuccess,
  loadFundsFail,
  loadCompaniesStart,
  loadCompaniesSuccess,
  loadCompaniesFail,
  loadAssetClassesStart,
  loadAssetClassesSuccess,
  loadAssetClassesFail,
} = manuallyEnteredPortfolioSlice.actions;

export default manuallyEnteredPortfolioSlice.reducer;

export const useManuallyEnteredPortfolioActions = () => {
  const dispatch = useDispatch();

  const loadFunds = async value => {
    dispatch(loadFundsStart());

    try {
      const { data } = await FundsRepository.index({
        q: { ticker_or_name_cont: value, ordered_by_ticker_match: value },
        per_page: 10,
      });
      loadFundsSuccess(data);

      return data;
    } catch (e) {
      loadFundsFail();

      return e;
    }
  };

  const loadCompanies = async value => {
    dispatch(loadCompaniesStart());

    try {
      const { data } = await CompaniesRepository.index({
        q: { ticker_or_name_cont: value, ordered_by_ticker_match: value },
        per_page: 10,
      });
      loadCompaniesSuccess(data);

      return data;
    } catch (e) {
      loadCompaniesFail();

      return e;
    }
  };

  const loadAssetClasses = async () => {
    dispatch(loadAssetClassesStart());

    try {
      const { data } = await AssetClassesRepository.index({
        per_page: 1000,
      });
      dispatch(loadAssetClassesSuccess(data));

      return data;
    } catch (e) {
      dispatch(loadAssetClassesFail());

      return e;
    }
  };

  const createUser = async params => {
    await UsersRepository.create(params);
  };

  return {
    loadFunds,
    loadCompanies,
    loadAssetClasses,
    createUser,
  };
};
