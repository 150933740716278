import Presenter from 'utils/PropTypesPresenter';
import { findBy } from 'utils/storeUtils';

export const IMPORTANCE_LEVELS = [
  { key: 'important', value: 'Important', points: 1 },
  { key: 'very_important', value: 'Very Important', points: 2 },
  { key: 'extremely_important', value: 'Extremely Important', points: 4 },
];

export default new Presenter(
  {},
  {
    getPointsFor(key) {
      const { points } = findBy('key', key, IMPORTANCE_LEVELS);
      return points;
    },
    getLabelFor(key) {
      const { value } = findBy('key', key, IMPORTANCE_LEVELS);
      return value;
    },
  },
);
