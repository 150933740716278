import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isNil, path } from 'ramda';
import { BarChart, Bar, YAxis, XAxis, CartesianGrid, LabelList } from 'recharts';

import TickerMetaPresenter from 'presenters/TickerMetaPresenter';
import CustomerFundAnalysisPresenter from 'presenters/CustomerFundAnalysisPresenter';

import { getGridVerticalPoints } from 'utils/gridUtils';
import { toFixedFloat } from 'utils/numberHelpers';

import CustomizedTick from './components/CustomizedTick';
import CustomBar from './components/CustomBar';
import CustomizedLabel from './components/CustomizedLabel';
import CustomizedYAxisTick from './components/CustomizedYAxisTick';
import CustomizedYLabel from './components/CustomizedYLabel';

import useStyles from './useStyles';

const Chart = props => {
  const { flaggedTickers, selectedFunds, isShowValue } = props;
  const classes = useStyles();

  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  const [tooltipContent, setTooltipContent] = useState('');
  const { top, left, marginLeft } = tooltipPosition;

  const handleCustomBarMouseEnter = fundName => payload => {
    const { width, x, y, barOptions, name: flagName } = payload;
    const marginLeftValue = width / 2;

    setTooltipPosition({
      top: y,
      left: x,
      marginLeft: marginLeftValue,
    });

    const { value } = barOptions[fundName];
    const shortenedValue = toFixedFloat(value, 1);
    const content = `${shortenedValue}% of ${fundName} is in one or more securities involved in ${flagName}`;
    setTooltipContent(content);
    setIsTooltipVisible(true);
  };

  const handleCustomBarMouseLeave = () => {
    setIsTooltipVisible(false);
  };

  const handleTooltipMouseEnter = () => {
    setIsTooltipVisible(true);
  };

  const handleTooltipMouseLeave = () => {
    setIsTooltipVisible(false);
  };

  const initBarOptions = selectedFunds.reduce(
    (acc, fund) => ({ ...acc, [CustomerFundAnalysisPresenter.ticker(fund)]: { value: 0 } }),
    {},
  );
  const chartData = flaggedTickers.reduce((chartDataAcc, ticker) => {
    const totalWeight = Number(TickerMetaPresenter.totalWeight(ticker));
    const flags = TickerMetaPresenter.flags(ticker);
    const fundTicker = TickerMetaPresenter.fundTicker(ticker);

    return flags.reduce((flagsAcc, flag) => {
      const flagOptions = flagsAcc.find(({ name }) => name === flag);
      if (isNil(flagOptions)) {
        const newOption = {
          name: flag,
          barOptions: { ...initBarOptions, [fundTicker]: { value: totalWeight } },
        };

        return [...flagsAcc, newOption];
      }

      const value = path(['barOptions', fundTicker, 'value'], flagOptions);
      const newValue = isNil(value) ? totalWeight : value + totalWeight;
      const newOption = {
        ...flagOptions,
        barOptions: { ...flagOptions.barOptions, [fundTicker]: { value: newValue } },
      };
      const newFlagsAcc = flagsAcc.filter(({ name }) => name !== flag);

      return [...newFlagsAcc, newOption];
    }, chartDataAcc);
  }, []);

  const gridVerticalPointsCount = isEmpty(chartData) ? 0 : chartData.length - 1;
  const gridVerticalPoints = getGridVerticalPoints(gridVerticalPointsCount);

  const renderBar = fund => (
    <Bar
      key={CustomerFundAnalysisPresenter.id(fund)}
      dataKey={({ barOptions }) => barOptions[CustomerFundAnalysisPresenter.ticker(fund)].value}
      shape={<CustomBar fund={fund} />}
      onMouseEnter={handleCustomBarMouseEnter(CustomerFundAnalysisPresenter.ticker(fund))}
      onMouseLeave={handleCustomBarMouseLeave}
      isAnimationActive={false}
    >
      <LabelList
        position="bottom"
        text={CustomerFundAnalysisPresenter.ticker(fund)}
        content={<CustomizedLabel isShowValue={isShowValue} />}
      />
    </Bar>
  );

  return (
    <div className={classes.chart}>
      <BarChart
        data={chartData}
        width={803}
        height={355}
        className={classes.barChart}
        margin={{ top: 0, right: 6, bottom: 0, left: 39 }}
      >
        <CartesianGrid stroke="#eee" verticalPoints={gridVerticalPoints} />
        <XAxis
          dataKey="name"
          orientation="top"
          allowDataOverflow
          tick={<CustomizedTick />}
          tickLine={false}
          axisLine={false}
        />
        <YAxis
          ticks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
          label={<CustomizedYLabel />}
          interval={0}
          tickLine={false}
          axisLine={false}
          tick={<CustomizedYAxisTick />}
        />
        {selectedFunds.map(renderBar)}
      </BarChart>
      {isTooltipVisible && (
        <div
          className={classes.customTooltip}
          style={{ top, left, marginLeft }}
          onMouseEnter={handleTooltipMouseEnter}
          onMouseLeave={handleTooltipMouseLeave}
        >
          <div className={classes.content}>
            <div className={classes.description}>{tooltipContent}</div>
          </div>
        </div>
      )}
    </div>
  );
};

Chart.propTypes = {
  flaggedTickers: PropTypes.arrayOf(TickerMetaPresenter.shape()).isRequired,
  selectedFunds: PropTypes.arrayOf(CustomerFundAnalysisPresenter.shape()).isRequired,
  isShowValue: PropTypes.bool,
};

Chart.defaultProps = {
  isShowValue: false,
};

export default Chart;
