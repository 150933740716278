import Routes from 'routes/ApiRoutes';
import FetchHelpers from 'utils/FetchHelpers';

export default {
  index(params) {
    const path = Routes.adminApiV1UsersPath();

    return FetchHelpers.get(path, params);
  },
  impersonate(userId) {
    const path = Routes.impersonateAdminApiV1UserPath(userId);

    return FetchHelpers.put(path);
  },
  stopImpersonating() {
    const path = Routes.stopImpersonatingAdminApiV1UsersPath();

    return FetchHelpers.delete(path);
  },
  triggerJob() {
    const path = Routes.triggerJobAdminApiV1UsersPath();

    return FetchHelpers.get(path);
  },
  downloadExport(params) {
    const path = Routes.exportAdminApiV1UsersPath();

    return FetchHelpers.download(path, params);
  },
  export() {
    const path = Routes.exportStateAdminApiV1UsersPath();

    return FetchHelpers.get(path);
  },
};
