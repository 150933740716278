import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import { isEmpty } from 'ramda';
import { useReactToPrint } from 'react-to-print';
import clsx from 'clsx';

import refresh from 'assets/refresh.svg';
import question from 'assets/question.svg';

import { useReflectionReportRequest } from 'hooks';
import MultipleAccountsDropdown from 'components/MultipleAccountsDropdown';
import Loader from 'components/Loader';
import HowCalculated from 'components/HowCalculated';
import SamplePortfolioInfo from 'components/SamplePortfolioInfo';
import Drawer from 'components/Drawer';
import SystemMessage from 'components/SystemMessage';
import PdfTemplate from 'components/PdfTemplate';

import pdfIcon from 'assets/pdf.svg';

import ReflectionReportPresenter from 'presenters/ReflectionReportPresenter';
import CustomerFundAnalysisPresenter from 'presenters/CustomerFundAnalysisPresenter';
import CustomerLensPresenter from 'presenters/CustomerLensPresenter';
import TickerMetaPresenter from 'presenters/TickerMetaPresenter';
import CustomerFundsReportPresenter from 'presenters/CustomerFundsReportPresenter';
import CompanyPresenter from 'presenters/CompanyPresenter';

import Compare from './components/Compare';
import Stat from './components/Stat';

import useStyles from './useStyles';

const INTERVAL_BEFORE_OPEN_PRINTING_FRAME = 500;

const Main = props => {
  const {
    reports,
    customerLens,
    onChangeCurrentReport,
    currentReport,
    onRefreshReportConfirmationShow,
    flaggedTickers,
    customerFundAnalyses,
    onSetCurrentCompanyId,
    selectedIssuesDefinedFundIds,
    onSetSelectedIssuesDefinedFundIds,
    selectedCompaniesScoredFundIds,
    onSetSelectedCompaniesScoredFundIds,
    selectedPercentValueHeldFundIds,
    onSetSelectedPercentValueHeldFundIds,
    currentCompanyId,
    selectedAccountIds,
    reportCreatedAt,
    scoredCompanies,
    scoredCompaniesOptions,
    isScoredCompaniesOptionsLoading,
    currentCompany,
    isCurrentCompanyLoading,
    onChangeSelectedAccount,
    isSamplePortfolio,
    customerFundsReportLoadingStatus,
    mixedReportStatus,
    customerFundsReportMetrics,
    selectedMatchScoreReportsFundIds,
    overviewFundIds,
    isMultipleAccountMode,
    accountOptions,
  } = props;

  const { currentReportStatuses } = useReflectionReportRequest();

  const classes = useStyles();
  const componentRef = useRef();
  const [isPdfContentVisible, setIsPdfContentVisible] = useState(false);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: '@page { size: letter landscape; margin: 0px }',
    documentTitle: 'MatchScore.pdf',
    onBeforeGetContent: () => {
      return new Promise(resolve => {
        setIsPdfContentVisible(true);
        setTimeout(() => {
          resolve();
        }, INTERVAL_BEFORE_OPEN_PRINTING_FRAME);
      });
    },
    onAfterPrint: () => {
      setIsPdfContentVisible(false);
    },
  });

  const [isDrawerShown, setIsDrawerShown] = useState(false);
  const [isHowIsItCalculatedDrawerShown, setIsHowIsItCalculatedDrawerShown] = useState(false);
  const [isSamplePortfolioDrawerShown, setIsSamplePortfolioDrawerShown] = useState(false);

  const pdfContentClassNames = clsx({
    [classes.hidden]: !isPdfContentVisible,
    [classes.notVisible]: true,
  });

  const handleHowIsThisCalculatedShow = () => {
    setIsDrawerShown(true);
    setIsHowIsItCalculatedDrawerShown(true);
  };

  const handleSamplePortfolioDrawerShow = () => {
    setIsDrawerShown(true);
    setIsSamplePortfolioDrawerShown(true);
  };

  const handleDrawerHide = () => {
    setIsDrawerShown(false);
    setIsSamplePortfolioDrawerShown(false);
    setIsHowIsItCalculatedDrawerShown(false);
  };

  const selectedReportsFundIds = isEmpty(selectedMatchScoreReportsFundIds)
    ? overviewFundIds
    : selectedMatchScoreReportsFundIds;

  const renderContent = () => {
    return (
      <div className={classes.root} ref={componentRef}>
        {isSamplePortfolio && (
          <div className={classes.systemMessage}>
            <SystemMessage
              title="This account does not contain enough information to score the investment, so a sample portfolio has been provided."
              onClick={handleSamplePortfolioDrawerShow}
            />
          </div>
        )}
        <div className={classes.header}>
          <div className={classes.general}>
            <div className={classes.title}>
              <span>Your Alignment Report</span>
              <IconButton type="button" className={classes.question} onClick={handleHowIsThisCalculatedShow}>
                <img src={question} alt="question" width="18" height="18" />
              </IconButton>
              <IconButton
                type="button"
                className={classes.PDFIcon}
                onClick={handlePrint}
                label="save to pdf"
                size="medium"
              >
                <img src={pdfIcon} alt="Save to PDF" width="30" height="30" />
              </IconButton>
            </div>
            <div className={classes.info}>
              {!isEmpty(reportCreatedAt) && (
                <>
                  <div className={classes.time}>{reportCreatedAt}</div>
                  <div className={classes.refresh}>
                    <IconButton type="button" onClick={onRefreshReportConfirmationShow} size="small">
                      <img src={refresh} alt="refresh icon" width="16" height="16" />
                    </IconButton>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className={classes.dropdown}>
            <MultipleAccountsDropdown
              onChange={onChangeSelectedAccount}
              selected={selectedAccountIds}
              options={accountOptions}
            />
          </div>
        </div>
        <div className={classes.compare}>
          <Compare
            list={reports}
            onChange={onChangeCurrentReport}
            mixedReportStatus={mixedReportStatus}
            selectedReportsFundIds={selectedMatchScoreReportsFundIds}
          />
        </div>

        <Drawer open={isDrawerShown} onClose={handleDrawerHide}>
          {isHowIsItCalculatedDrawerShown && <HowCalculated onClose={handleDrawerHide} />}
          {isSamplePortfolioDrawerShown && <SamplePortfolioInfo onClose={handleDrawerHide} />}
        </Drawer>

        {currentReportStatuses.isLoading ? (
          <Loader caption="Loading..." />
        ) : (
          <Stat
            currentReport={currentReport}
            customerLens={customerLens}
            flaggedTickers={flaggedTickers}
            customerFundAnalyses={customerFundAnalyses}
            onSetCurrentCompanyId={onSetCurrentCompanyId}
            selectedIssuesDefinedFundIds={selectedIssuesDefinedFundIds}
            onSetSelectedIssuesDefinedFundIds={onSetSelectedIssuesDefinedFundIds}
            selectedCompaniesScoredFundIds={selectedCompaniesScoredFundIds}
            onSetSelectedCompaniesScoredFundIds={onSetSelectedCompaniesScoredFundIds}
            selectedPercentValueHeldFundIds={selectedPercentValueHeldFundIds}
            onSetSelectedPercentValueHeldFundIds={onSetSelectedPercentValueHeldFundIds}
            selectedReportsFundIds={selectedReportsFundIds}
            currentCompanyId={currentCompanyId}
            scoredCompanies={scoredCompanies}
            scoredCompaniesOptions={scoredCompaniesOptions}
            isScoredCompaniesOptionsLoading={isScoredCompaniesOptionsLoading}
            currentCompany={currentCompany}
            isCurrentCompanyLoading={isCurrentCompanyLoading}
            customerFundsReportLoadingStatus={customerFundsReportLoadingStatus}
            mixedReportStatus={mixedReportStatus}
            customerFundsReportMetrics={customerFundsReportMetrics}
            isMultipleAccountMode={isMultipleAccountMode}
          />
        )}
      </div>
    );
  };

  return (
    <>
      {renderContent()}
      <div className={pdfContentClassNames}>
        <div ref={componentRef}>
          <PdfTemplate>
            <div className={classes.pdfWrapper}>{renderContent()}</div>
          </PdfTemplate>
        </div>
      </div>
    </>
  );
};

Main.propTypes = {
  reports: PropTypes.arrayOf(ReflectionReportPresenter.shape()).isRequired,
  customerLens: CustomerLensPresenter.shape().isRequired,
  onChangeCurrentReport: PropTypes.func.isRequired,
  currentReport: ReflectionReportPresenter.shape().isRequired,
  onRefreshReportConfirmationShow: PropTypes.func.isRequired,
  flaggedTickers: PropTypes.arrayOf(TickerMetaPresenter.shape()).isRequired,
  customerFundAnalyses: PropTypes.arrayOf(CustomerFundAnalysisPresenter.shape()).isRequired,
  onSetCurrentCompanyId: PropTypes.func.isRequired,
  onSetSelectedIssuesDefinedFundIds: PropTypes.func.isRequired,
  selectedIssuesDefinedFundIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  onSetSelectedCompaniesScoredFundIds: PropTypes.func.isRequired,
  selectedCompaniesScoredFundIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  onSetSelectedPercentValueHeldFundIds: PropTypes.func.isRequired,
  selectedPercentValueHeldFundIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  selectedAccountIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  onChangeSelectedAccount: PropTypes.func.isRequired,
  currentCompanyId: PropTypes.number,
  reportCreatedAt: PropTypes.string,
  scoredCompanies: PropTypes.arrayOf(CompanyPresenter.shape()).isRequired,
  scoredCompaniesOptions: PropTypes.arrayOf(CompanyPresenter.shape()).isRequired,
  isScoredCompaniesOptionsLoading: PropTypes.bool.isRequired,
  currentCompany: CompanyPresenter.shape().isRequired,
  isCurrentCompanyLoading: PropTypes.bool.isRequired,
  isSamplePortfolio: PropTypes.bool.isRequired,
  customerFundsReportLoadingStatus: PropTypes.string.isRequired,
  mixedReportStatus: PropTypes.string.isRequired,
  customerFundsReportMetrics: CustomerFundsReportPresenter.shape().isRequired,
  selectedMatchScoreReportsFundIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  overviewFundIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  isMultipleAccountMode: PropTypes.bool,
  accountOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.number,
      label: PropTypes.string,
    }),
  ).isRequired,
};

Main.defaultProps = {
  currentCompanyId: null,
  reportCreatedAt: '',
  isMultipleAccountMode: false,
};

export default Main;
