import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  text: {
    fontFamily: theme.typography.font.ttHovesPro,
    fontSize: 10,
    fill: '#232020',
    fontWeight: 700,
    fontStyle: 'italic',
  },
}));

export default useStyles;
