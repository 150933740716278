import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  () => ({
    recommendedFundsContainer: {
      width: 640,
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      fontSize: 12,
    },
    head: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    dropdownContainer: {
      minWidth: 150,
    },
    title: {
      fontSize: 32,
      padding: [15, 0],
    },
    recommendationContainer: {
      border: '2px #E4E4E4 solid',
    },
    fullHeight: {
      height: '100%',
    },
    emptyText: {
      fontSize: 24,
      fontWeight: 200,
      width: 400,
      margin: 'auto',
      marginTop: 32,
    },
    headRow: {
      '& > th': {
        fontSize: 12,
        padding: [16, 6],
      },
    },
    table: {
      background: '#FAFAFA',
    },
    centerCell: {
      textAlign: 'center',
    },
  }),
  { name: 'RecommendedFunds' },
);

export default useStyles;
