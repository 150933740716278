import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  text: {
    fontFamily: theme.typography.font.ttHovesPro,
    fontWeight: 'bold',
    fontSize: 13,
  },
  smallText: {
    fontFamily: theme.typography.font.ttHovesPro,
    fontWeight: 'bold',
    fontSize: 12,
    letterSpacing: '0.01em',
  },
}));

export default useStyles;
