import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  backLink: {
    display: 'inline-flex',
    fontFamily: theme.typography.font.ttHovesPro,
    fontSize: '12px',
    fontWeight: 'bold',
    letterSpacing: '0.01em',
    lineHeight: '20px',
    '& p': {
      margin: 0,
    },
    '& > svg': {
      width: 6,
      height: 'inherit',
      marginRight: '10px',
      transition: '300ms',
    },
    '&:hover svg': {
      transform: 'translateX(-50%)',
    },
  },
  black: {
    color: '#232020',
    '& > svg': {
      fill: '#232020',
    },
  },
  white: {
    color: '#fff',
    '& > svg': {
      fill: '#fff',
    },
  },
}));

export default useStyles;
