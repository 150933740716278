import PropTypes from 'prop-types';
import React from 'react';

import MuiLink from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';
import { stringify } from 'query-string';

const LinkToFundPortfolios = props => {
  const { record } = props;

  return record ? (
    <MuiLink
      component={RouterLink}
      color="secondary"
      to={{
        pathname: '/fund_portfolios',
        search: stringify({
          page: 1,
          perPage: 25,
          order: 'ASC',
          filter: JSON.stringify({ fundIdEq: record.id }),
        }),
      }}
    >
      All Portfolios
    </MuiLink>
  ) : null;
};

LinkToFundPortfolios.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
};

export default LinkToFundPortfolios;
