import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import { isEmpty } from 'ramda';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { useHistory } from 'react-router-dom';

import { initialValues, validationSchema, attributesToSubmit } from 'forms/AdvisorForm';
import { useManuallyEnteredPortfolioActions } from 'store/ManuallyEnteredPortfolioSlice';
import useApp from 'hooks/useApp';
import AppRoutes from 'routes/AppRoutes';
import Loader from 'components/Loader';

import Household from './components/Household';
import useStyles from './useStyles';

const AdvisorForm = () => {
  const classes = useStyles();

  const [serverError, setServerError] = useState('');

  const { createAdvisorHousehold, isLoading, changeShouldRedirectOnSelectedAccountChange } = useApp();
  const { loadAssetClasses } = useManuallyEnteredPortfolioActions();
  const history = useHistory();

  useEffect(() => {
    loadAssetClasses();
    changeShouldRedirectOnSelectedAccountChange(false);
  }, []);

  const handleInputChange = (handleChange, setErrors) => event => {
    setErrors({});
    handleChange(event);
  };

  const handleSubmit = async (params, actions) => {
    try {
      const values = attributesToSubmit(params);
      await createAdvisorHousehold(values);
      actions.setSubmitting(false);
      history.push(AppRoutes.rootPath());
    } catch (error) {
      setServerError('Something went wrong!');
    }
  };

  if (isLoading) return <Loader />;

  return (
    <main className={classes.main}>
      <Container component="div" maxWidth="md" className={classes.container}>
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5" gutterBottom color="primary">
            Please, fill in the form
          </Typography>

          <Formik
            onSubmit={handleSubmit}
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnChange={false}
            validateOnBlur
          >
            {({ values, handleChange, errors, setFieldValue, setErrors, isSubmitting }) => (
              <Form noValidate>
                <Household
                  values={values}
                  errors={errors}
                  setErrors={setErrors}
                  setFieldValue={setFieldValue}
                  disabled={isSubmitting}
                  onChange={handleInputChange(handleChange, setErrors)}
                />
                {!isEmpty(serverError) && <div className={classes.error}>{serverError}</div>}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  disabled={isSubmitting}
                >
                  Submit
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </Container>
    </main>
  );
};

export default AdvisorForm;
