import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  rootBig: {
    textAlign: 'center',
  },
  recommendationsButtonBig: {
    padding: '35px 175px',
    borderRadius: 45,
  },
  recommendationsButtonSmall: {
    padding: '13px 30px',
    borderRadius: 35,
    fontSize: 18,
    fontWeight: 'bold',
  },
}));

export default useStyles;
