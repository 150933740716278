import { useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import LensPresenter from 'presenters/LensPresenter';
import getFetchStatus from 'utils/getFetchStatus';

import { useCustomerLensDefinitionActions } from 'store/CustomerLensDefinitionSlice';

export default () => {
  const { loadLenses } = useCustomerLensDefinitionActions();

  const lensesSelector = state => {
    return state.CustomerLensDefinitionSlice.lenses.items;
  };
  const lensesStatus = useSelector(state => state.CustomerLensDefinitionSlice.lenses.status);
  const lensesStatuses = getFetchStatus(lensesStatus);

  const getOtherLenses = createSelector(lensesSelector, items => items.filter(item => LensPresenter.isOtherLens(item)));
  const getCustomizedLenses = createSelector(lensesSelector, items =>
    items.filter(lens => LensPresenter.isCustomizedLens(lens)),
  );
  const getBalancedLenses = createSelector(lensesSelector, items =>
    items.filter(lens => LensPresenter.isBalancedLens(lens)),
  );

  const otherLenses = useSelector(getOtherLenses);
  const customizedLenses = useSelector(getCustomizedLenses);
  const balancedLenses = useSelector(getBalancedLenses);

  return {
    loadLenses,
    otherLenses,
    customizedLenses,
    balancedLenses,
    lensesStatuses,
  };
};
