import React, { useEffect, useState } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { Switch, Route, useHistory } from 'react-router';
import { isEmpty, isNil, append, without, head, equals, map, reject, intersection, take } from 'ramda';
import { useDebouncedCallback } from 'use-debounce';

import AppRoutes from 'routes/AppRoutes';

import Button from '@material-ui/core/Button';
import Drawer from 'components/Drawer';
import Heading from 'components/Heading';
import UpdateInfo from 'components/UpdateInfo';
import SaveReportConfirmation from 'components/SaveReportConfirmation';
import PortfolioAnalysisInsights from 'components/ReflectionReport/PortfolioAnalysisInsights';
import Side from 'components/ReflectionReport/Side';
import Main from 'components/ReflectionReport/Main';
import Reports from 'components/ReflectionReport/Reports';
import RecommendationLink from 'components/ReflectionReport/RecommendationLink';

import { REPORT_OVERVIEW } from 'presenters/ReflectionReportPresenter';
import PortfolioAnalysisPresenter from 'presenters/PortfolioAnalysisPresenter';
import CustomerFundAnalysisPresenter from 'presenters/CustomerFundAnalysisPresenter';
import UserPresenter from 'presenters/UserPresenter';
import AccountPresenter from 'presenters/AccountPresenter';
import CustomerLensPresenter from 'presenters/CustomerLensPresenter';

import { useReflectionReportRequest, useReflectionReportSaving } from 'hooks';
import useApp from 'hooks/useApp';

import { findBy } from 'utils/storeUtils';
import { isElementExist, hasSingleElement } from 'utils/arrayUtils';
import { objectToQueryString } from 'utils/urlUtils';
import { getUrlParam } from 'utils/location';
import { generateReport } from 'utils/reflectionReportUtils';

import useStyles from './useStyles';

const MAXIMUM_COUNT_OF_SELECTED_INSIGHT_FUNDS = 5;

const ReflectionReport = () => {
  const routeParams = useParams();
  const history = useHistory();

  const analysisId = Number(routeParams.id);
  const initialSelectedFundIds = map(Number, getUrlParam('selected_fund_ids', []));

  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [selectedInsightsFundIds, setSelectedInsightsFundIds] = useState([]);
  const [selectedIssuesDefinedFundIds, setSelectedIssuesDefinedFundIds] = useState([]);
  const [selectedCompaniesScoredFundIds, setSelectedCompaniesScoredFundIds] = useState([]);
  const [selectedPercentValueHeldFundIds, setSelectedPercentValueHeldFundIds] = useState([]);
  const [selectedMatchScoreReportsFundIds, setSelectedMatchScoreReportsFundIds] = useState([]);
  const [overviewFundIds, setOverviewFundIds] = useState([]);
  const [currentCompanyId, setCurrentCompanyId] = useState(null);
  const [isSaveReportConfirmationShow, setIsSaveReportConfirmationShow] = useState(false);
  const [isRefreshReportConfirmationShow, setIsRefreshReportConfirmationShow] = useState(false);
  const [shouldRedirectToCurrentReport, setShouldRedirectToCurrentReport] = useState(false);
  const [customerPortfolioAnalysisBatchId, setCustomerPortfolioAnalysisBatchId] = useState(null);

  const classes = useStyles();
  const {
    loadCustomerFundAnalysesOptions,
    loadPortfolioAnalysis,
    loadFullPortfolioAnalysis,
    loadFlaggedTickers,
    loadInsights,
    setCurrentReport,
    loadMetrics,
    loadMixedReport,
    loadReportMetrics,
    duplicateCustomerLens,
    createPortfolioAnalysis,
    createPortfolioAnalysesBatch,
    createAdvisorPortfolioAnalysis,
    createAdvisorPortfolioAnalysesBatch,
    loadScoredCompanies,
    loadInsightsScoredCompanies,
    resetToInitialState,
    createCustomerRecommendation,
    createAdvisorRecommendation,
    customerFundAnalysesOptions,
    currentReport,
    portfolioAnalysis,
    flaggedTickers,
    fundScores,
    customerFundsReport,
    customerFundsReportMetrics,
    customerFundsReportStatuses,
    scoredCompanies,
    loadScoredCompany,
    scoredCompany,
    scoredCompanyStatuses,
    loadScoredCompaniesOptions,
    scoredCompaniesOptions,
    scoredCompaniesOptionsStatuses,
    loadInsightsScoredCompaniesOptions,
    insightsScoredCompaniesOptions,
    insightsScoredCompaniesOptionsStatuses,
    insightsScoredCompanies,
    insightsScoredCompaniesStatuses,
    mixedReportStatus,
    customerFundsReportMetricsLoadingStatus,
  } = useReflectionReportRequest();

  const {
    showLoader,
    hideLoader,
    changeSelectedCustomer,
    loadAdvisorCustomerAccounts,
    changeAccounts,
    loadAccounts,
    changeSelectedAccount,
    changeShouldRedirectOnSelectedAccountChange,
    hideRefreshReportModal,
    currentUser,
    customersList,
    shouldShowRefreshReportModal,
    selectedCustomer,
    selectedAccount,
    accounts,
  } = useApp();

  const [debouncedHandleSetCurrentReport] = useDebouncedCallback(() => {
    handleSetCurrentReport(); // eslint-disable-line
  }, 1000);

  const updateSelectedInsightsFundIds = fundIds => {
    const insightsSelectedFundIds = take(MAXIMUM_COUNT_OF_SELECTED_INSIGHT_FUNDS, fundIds);
    setSelectedInsightsFundIds(insightsSelectedFundIds);
  };

  const updateAdditionalFundFilters = fundIds => {
    updateSelectedInsightsFundIds(fundIds);
    setSelectedIssuesDefinedFundIds(fundIds);
    setSelectedCompaniesScoredFundIds(fundIds);
    setSelectedPercentValueHeldFundIds(fundIds);
  };

  const [debouncedUpdateFundFilters] = useDebouncedCallback(fundIds => {
    updateAdditionalFundFilters(fundIds);
  }, 1000);

  const [debouncedLoadReportMetrics] = useDebouncedCallback(fundIds => {
    loadReportMetrics(analysisId, fundIds);
  }, 1000);

  const accountsList = AccountPresenter.asList(accounts, selectedCustomer);

  const [cachedSelectedCustomer] = useState(selectedCustomer);

  const handleRedirectAfterSaveReport = () => {
    changeShouldRedirectOnSelectedAccountChange(true);

    if (shouldRedirectToCurrentReport) {
      const currentAnalysisId = AccountPresenter.customerPortfolioAnalysisId(selectedAccount);
      const redirectUrl = isNil(currentAnalysisId)
        ? AppRoutes.customerLensLensesPath()
        : AppRoutes.portfolioAnalysisPath(currentAnalysisId);

      history.push(redirectUrl);
    }

    setIsSaveReportConfirmationShow(false);
  };

  const handleSaveReportConfirmationShow = () => {
    setIsSaveReportConfirmationShow(true);
  };
  const handleSaveReportConfirmationHide = () => {
    setIsSaveReportConfirmationShow(false);
    handleRedirectAfterSaveReport();
  };

  const { isCurrentReportActive, handleReportSave } = useReflectionReportSaving({
    currentSelectedCustomer: cachedSelectedCustomer,
    analysisId,
  });

  const getAccountOptionList = () => {
    const targetPortfolioAnalysisField = isCurrentReportActive
      ? 'customerPortfolioAnalysis'
      : 'cachedCustomerPortfolioAnalysis';
    const currentReportPortfolioAnalysisBatchId = PortfolioAnalysisPresenter.customerPortfolioAnalysesBatchId(
      currentReport,
    );

    const getOptions = account => {
      if (isNil(account[targetPortfolioAnalysisField])) {
        return { key: null, label: account.value };
      }

      if (
        currentReportPortfolioAnalysisBatchId !==
        account[targetPortfolioAnalysisField]?.customerPortfolioAnalysesBatchId
      ) {
        return { key: null, label: account.value };
      }

      return {
        key: account[targetPortfolioAnalysisField].id,
        label: account.value,
      };
    };

    const options = map(getOptions, accountsList);
    return options;
  };

  const accountOptions = getAccountOptionList();

  const selectedAccountIds = isCurrentReportActive
    ? [AccountPresenter.customerPortfolioAnalysisId(selectedAccount)]
    : [AccountPresenter.cachedCustomerPortfolioAnalysisId(selectedAccount)];

  const handleRefreshReportConfirmationShow = () => {
    setIsRefreshReportConfirmationShow(true);
  };
  const handleRefreshReportConfirmationHide = () => {
    setIsRefreshReportConfirmationShow(false);
  };

  const customerLens = PortfolioAnalysisPresenter.customerLens(portfolioAnalysis);
  const customerLensId = CustomerLensPresenter.id(customerLens);

  const createMixedAnalysisReport = async (fundIds = []) => {
    const mixedAnalysisReport = await loadMixedReport(analysisId, fundIds);

    const { categoryAnalyses, totalAnalysis, pillarAnalyses, reflectionMatchScore } = mixedAnalysisReport;
    const report = PortfolioAnalysisPresenter.toReflectionReport({
      ...portfolioAnalysis,
      portfolioCategoryAnalyses: categoryAnalyses,
      portfolioTotalAnalysis: totalAnalysis,
      portfolioPillarAnalyses: pillarAnalyses,
      reflectionMatchScore,
      ticker: 'Mixed Analysis',
      name: 'Mixed Analysis',
    });

    return report;
  };

  const handleSetCurrentReport = async () => {
    const batchId = PortfolioAnalysisPresenter.customerPortfolioAnalysesBatchId(currentReport);
    const report = await generateReport(selectedMatchScoreReportsFundIds, batchId, createMixedAnalysisReport);
    setCurrentReport(report);
  };

  const handleReportLoad = async () => {
    Promise.all([loadFullPortfolioAnalysis(analysisId), loadCustomerFundAnalysesOptions(analysisId)])
      .then(([analysisData, customerFundAnalysesData]) => {
        loadFlaggedTickers(analysisId);
        loadInsights(analysisId);
        const { customerPortfolioAnalysis } = analysisData;
        const { customerFundAnalyses: loadedCustomerFundAnalyses } = customerFundAnalysesData;
        const reportFundIds = loadedCustomerFundAnalyses.map(fund => CustomerFundAnalysisPresenter.id(fund));
        const currentBatchId = PortfolioAnalysisPresenter.customerPortfolioAnalysesBatchId(customerPortfolioAnalysis);
        const preselectedFundIds = intersection(reportFundIds, initialSelectedFundIds);
        const selectedFundIds = isEmpty(preselectedFundIds) ? reportFundIds : preselectedFundIds;

        let report = {};
        if (!isEmpty(preselectedFundIds)) {
          report = generateReport(preselectedFundIds, currentBatchId, createMixedAnalysisReport);
          setSelectedMatchScoreReportsFundIds(preselectedFundIds);
        } else {
          report = PortfolioAnalysisPresenter.toReflectionReport(customerPortfolioAnalysis);
        }
        updateAdditionalFundFilters(selectedFundIds);
        setOverviewFundIds(reportFundIds);
        setCustomerPortfolioAnalysisBatchId(currentBatchId);
        return report;
      })
      .then(report => {
        setCurrentReport(report);
      })
      .finally(() => {
        hideLoader();
        setIsDataLoaded(true);
      });
  };

  const handleRedirectAdvisorAfterRefresh = async analysis => {
    changeShouldRedirectOnSelectedAccountChange(false);

    const accountIds = UserPresenter.accountIds(selectedCustomer).filter(id => id !== selectedAccount.id);
    const batchId = PortfolioAnalysisPresenter.customerPortfolioAnalysesBatchId(analysis);

    if (!isEmpty(accountIds)) {
      await createAdvisorPortfolioAnalysesBatch({
        customerPortfolioAnalysis: {
          customerPortfolioAnalysesBatchId: batchId,
          accountIds,
          customerLensId,
        },
      });
    }

    if (UserPresenter.recommendationsAvailable(currentUser)) {
      createAdvisorRecommendation({
        recommendation: {
          customerId: selectedCustomer.id,
          customerLensId,
          customerPortfolioAnalysesBatchId: batchId,
        },
      });
    }

    const customerIds = customersList.map(({ id }) => id);
    const accs = await loadAdvisorCustomerAccounts({
      q: { userAccountsUserIdIn: customerIds },
    });
    changeAccounts(accs);
    const initAccount = accs.find(account => account.id === selectedAccount.id);
    const redirectAnalysisId = AccountPresenter.cachedCustomerPortfolioAnalysisId(initAccount);
    history.push(AppRoutes.portfolioAnalysisPath(redirectAnalysisId));
  };

  const handleRedirectCustomerAfterRefresh = async analysis => {
    changeShouldRedirectOnSelectedAccountChange(true);
    const accountIds = UserPresenter.accountIds(currentUser).filter(id => id !== selectedAccount.id);

    const batchId = PortfolioAnalysisPresenter.customerPortfolioAnalysesBatchId(analysis);
    if (!isEmpty(accountIds)) {
      await createPortfolioAnalysesBatch({
        customerPortfolioAnalysis: {
          customerPortfolioAnalysesBatchId: batchId,
          accountIds,
          customerLensId,
        },
      });
    }

    if (UserPresenter.recommendationsAvailable(currentUser)) {
      createCustomerRecommendation({
        recommendation: {
          customerLensId,
          customerPortfolioAnalysesBatchId: batchId,
        },
      });
    }

    const accs = await loadAccounts();
    changeAccounts(accs);
    const initAccount = accs.find(account => account.id === selectedAccount.id);
    const redirectAnalysisId = AccountPresenter.customerPortfolioAnalysisId(initAccount);
    history.push(AppRoutes.portfolioAnalysisPath(redirectAnalysisId));
  };

  const handlePollAnalysis = async (onSuccess, id = analysisId) => {
    const analysis = await loadPortfolioAnalysis(id);

    if (PortfolioAnalysisPresenter.isInProgress(analysis)) {
      setTimeout(() => handlePollAnalysis(onSuccess, id), 500);
    } else if (PortfolioAnalysisPresenter.isFinished(analysis)) {
      onSuccess();
    }
  };

  const handleAdvisorPortfolioRefresh = async () => {
    const accountId = selectedAccount.id;
    const analysis = await createAdvisorPortfolioAnalysis({
      customerPortfolioAnalysis: { accountId, customerLensId },
    });

    handleRedirectAdvisorAfterRefresh(analysis);
  };

  const handleCustomerPortfolioRefresh = async () => {
    const accountId = selectedAccount.id;
    const analysis = await createPortfolioAnalysis({
      customerPortfolioAnalysis: { accountId, customerLensId },
    });
    handleRedirectCustomerAfterRefresh(analysis);
  };

  const handlePortfolioRefresh = async () => {
    showLoader('loading');
    if (UserPresenter.isAdvisor(currentUser)) {
      handleAdvisorPortfolioRefresh();
    } else {
      handleCustomerPortfolioRefresh();
    }
  };

  const handleChangeSelectedAccount = accountIds => {
    const compactedAccountIds = reject(isNil, accountIds);
    if (equals(compactedAccountIds, selectedAccountIds)) {
      return;
    }

    if (hasSingleElement(compactedAccountIds)) {
      const accountId = head(compactedAccountIds);
      const accountObject =
        findBy('customerPortfolioAnalysisId', accountId, accounts) ||
        findBy('cachedCustomerPortfolioAnalysisId', accountId, accounts);

      changeSelectedAccount(accountObject);
      return;
    }

    const search = objectToQueryString({ account_ids: compactedAccountIds });
    history.push({
      pathname: AppRoutes.multipleAccountsPortfolioAnalysisPath(customerPortfolioAnalysisBatchId),
      search,
    });
  };

  useEffect(() => {
    showLoader('loading');
    handlePollAnalysis(handleReportLoad);
    changeShouldRedirectOnSelectedAccountChange(isCurrentReportActive);

    if (shouldShowRefreshReportModal) {
      handleRefreshReportConfirmationShow();
      hideRefreshReportModal();
    }

    return resetToInitialState;
  }, []);

  useEffect(() => {
    const fundIds = isEmpty(selectedMatchScoreReportsFundIds) ? overviewFundIds : selectedMatchScoreReportsFundIds;
    debouncedUpdateFundFilters(fundIds);
    debouncedLoadReportMetrics(fundIds);
  }, [selectedMatchScoreReportsFundIds]);

  useEffect(() => {
    if (!isNil(currentCompanyId)) {
      loadScoredCompany(analysisId, currentCompanyId, selectedCompaniesScoredFundIds);
    }
  }, [currentCompanyId]);

  useEffect(() => {
    if (!isEmpty(selectedInsightsFundIds)) {
      loadMetrics(analysisId, selectedInsightsFundIds);
      loadInsightsScoredCompanies(analysisId, selectedInsightsFundIds);
      loadInsightsScoredCompaniesOptions(analysisId, selectedInsightsFundIds);
    }
  }, [selectedInsightsFundIds]);

  useEffect(() => {
    if (!isEmpty(selectedCompaniesScoredFundIds)) {
      loadScoredCompanies(analysisId, selectedCompaniesScoredFundIds);
      loadScoredCompaniesOptions(analysisId, selectedCompaniesScoredFundIds);
    }
  }, [selectedCompaniesScoredFundIds]);

  useEffect(() => {
    const shouldHideEffect =
      UserPresenter.isCustomer(currentUser) ||
      isCurrentReportActive ||
      cachedSelectedCustomer.id === selectedCustomer.id;
    if (shouldHideEffect) {
      return;
    }

    handleSaveReportConfirmationShow();
    setShouldRedirectToCurrentReport(true);
  }, [selectedCustomer]);

  useEffect(() => {
    if (UserPresenter.isCustomer(currentUser) || cachedSelectedCustomer.id !== selectedCustomer.id) {
      return;
    }

    const newAnalysisId = isCurrentReportActive
      ? AccountPresenter.customerPortfolioAnalysisId(selectedAccount)
      : AccountPresenter.cachedCustomerPortfolioAnalysisId(selectedAccount);
    history.push(AppRoutes.portfolioAnalysisPath(newAnalysisId));
  }, [selectedAccount]);

  if (!isDataLoaded) {
    return null;
  }

  const changeReportFundIds = fundId => {
    if (isElementExist(fundId, selectedMatchScoreReportsFundIds)) {
      return without([fundId], selectedMatchScoreReportsFundIds);
    }
    return append(fundId, selectedMatchScoreReportsFundIds);
  };

  const handleChangeCurrentReport = selectedReport => {
    const { ticker: label, id } = selectedReport;
    const fundIds = changeReportFundIds(id);

    if (label === REPORT_OVERVIEW.label || isEmpty(fundIds)) {
      const report = PortfolioAnalysisPresenter.toReflectionReport(portfolioAnalysis);
      setSelectedMatchScoreReportsFundIds([]);
      setCurrentReport(report);
      return;
    }

    setSelectedMatchScoreReportsFundIds(fundIds);
    debouncedHandleSetCurrentReport();
  };

  const sortedCustomerFundAnalyses = CustomerFundAnalysisPresenter.sortedByTotalAmount(customerFundAnalysesOptions);

  const scoredFundScores = fundScores.filter(({ id }) => customerFundAnalysesOptions.some(fund => fund.id === id));

  const sortedFundScores = CustomerFundAnalysisPresenter.sortedByTotalAmount(scoredFundScores);

  const reports = [{ ticker: REPORT_OVERVIEW.label, name: REPORT_OVERVIEW.description }, ...sortedCustomerFundAnalyses];

  const reportCreatedAt = PortfolioAnalysisPresenter.formattedCreatedAt(portfolioAnalysis);

  const isRecommendationsAvailable =
    UserPresenter.recommendationsAvailable(currentUser) && portfolioAnalysis.recommendationId;

  const isSamplePortfolio = PortfolioAnalysisPresenter.isSample(portfolioAnalysis);

  return (
    <div className={classes.root}>
      <div className={classes.reportContainer}>
        <div className={classes.container}>
          <div className={classes.headLine}>
            <Heading
              intro="Are you aligned?"
              title="Review Your Results"
              description="Using your selections, we have reviewed your investments for how aligned they are to your values."
              className={classes.heading}
            />
          </div>
          <div className={classes.report}>
            <div className={classes.side}>
              <Side
                currentUser={currentUser}
                customerLens={customerLens}
                onDuplicateCustomerLens={duplicateCustomerLens}
                analysisId={analysisId}
                accounts={accounts}
              />
            </div>
            <div className={classes.main}>
              <Main
                reports={reports}
                customerLens={customerLens}
                onChangeCurrentReport={handleChangeCurrentReport}
                currentReport={currentReport}
                onRefreshReportConfirmationShow={handleRefreshReportConfirmationShow}
                flaggedTickers={flaggedTickers}
                customerFundAnalyses={sortedCustomerFundAnalyses}
                onSetCurrentCompanyId={setCurrentCompanyId}
                selectedIssuesDefinedFundIds={selectedIssuesDefinedFundIds}
                onSetSelectedIssuesDefinedFundIds={setSelectedIssuesDefinedFundIds}
                selectedCompaniesScoredFundIds={selectedCompaniesScoredFundIds}
                onSetSelectedCompaniesScoredFundIds={setSelectedCompaniesScoredFundIds}
                selectedPercentValueHeldFundIds={selectedPercentValueHeldFundIds}
                onSetSelectedPercentValueHeldFundIds={setSelectedPercentValueHeldFundIds}
                currentCompanyId={currentCompanyId}
                currentUser={currentUser}
                accounts={accountsList}
                selectedAccountIds={selectedAccountIds}
                onChangeSelectedCustomer={changeSelectedCustomer}
                reportCreatedAt={reportCreatedAt}
                scoredCompanies={scoredCompanies}
                scoredCompaniesOptions={scoredCompaniesOptions}
                isScoredCompaniesOptionsLoading={scoredCompaniesOptionsStatuses.isLoading}
                currentCompany={scoredCompany}
                isCurrentCompanyLoading={scoredCompanyStatuses.isLoading}
                onChangeSelectedAccount={handleChangeSelectedAccount}
                isSamplePortfolio={isSamplePortfolio}
                customerFundsReportLoadingStatus={customerFundsReportMetricsLoadingStatus}
                mixedReportStatus={mixedReportStatus}
                customerFundsReportMetrics={customerFundsReportMetrics}
                selectedMatchScoreReportsFundIds={selectedMatchScoreReportsFundIds}
                overviewFundIds={overviewFundIds}
                accountOptions={accountOptions}
              />
            </div>
          </div>
        </div>
      </div>

      <Drawer open={isSaveReportConfirmationShow} onClose={handleSaveReportConfirmationHide}>
        <SaveReportConfirmation
          onConfirm={handleReportSave(handleRedirectAfterSaveReport)}
          onClose={handleSaveReportConfirmationHide}
        >
          <>
            <div className={classes.saveDescriptionTitle}>
              {`Would you like to save this Reflection Report for ${UserPresenter.fullName(
                cachedSelectedCustomer,
              )} to view?`}
            </div>
            Client must have access to MyBlocks within
            <br />
            the Client Portal
          </>
        </SaveReportConfirmation>
      </Drawer>
      <Drawer open={isRefreshReportConfirmationShow} onClose={handleRefreshReportConfirmationHide}>
        <UpdateInfo onClose={handleRefreshReportConfirmationHide} onUpdate={handlePortfolioRefresh} />
      </Drawer>

      <Switch>
        <Route path={AppRoutes.portfolioAnalysisPath(':id')} exact>
          <PortfolioAnalysisInsights
            customerFundsReport={customerFundsReport}
            currentReport={currentReport}
            portfolioAnalysis={portfolioAnalysis}
            fundScores={sortedFundScores}
            onSetSelectedFundIds={updateSelectedInsightsFundIds}
            selectedFundIds={selectedInsightsFundIds}
            isCustomerFundsReportLoading={customerFundsReportStatuses.isLoading}
            maximumNumberOfSelectedFunds={MAXIMUM_COUNT_OF_SELECTED_INSIGHT_FUNDS}
          />
        </Route>
        <Route
          path={[
            AppRoutes.portfolioAnalysisPathPercentValueHeldPath(':id'),
            AppRoutes.portfolioAnalysisIssuesDefinedPath(':id'),
            AppRoutes.portfolioAnalysisCompaniesScoredPath(':id'),
          ]}
          exact
        >
          <Reports
            customerFundsReport={customerFundsReport}
            customerFundAnalyses={sortedCustomerFundAnalyses}
            flaggedTickers={flaggedTickers}
            onSetCurrentCompanyId={setCurrentCompanyId}
            onSetSelectedFundIds={updateSelectedInsightsFundIds}
            selectedFundIds={selectedInsightsFundIds}
            currentCompanyId={currentCompanyId}
            scoredCompanies={insightsScoredCompanies}
            isScoredCompaniesLoading={insightsScoredCompaniesStatuses.isLoading}
            scoredCompaniesOptions={insightsScoredCompaniesOptions}
            isScoredCompaniesOptionsLoading={insightsScoredCompaniesOptionsStatuses.isLoading}
            currentCompany={scoredCompany}
            isCurrentCompanyLoading={scoredCompanyStatuses.isLoading}
          />
        </Route>

        <Redirect to={AppRoutes.portfolioAnalysisPath(analysisId)} />
      </Switch>
      <div className={classes.buttonsGroup}>
        {!isCurrentReportActive && (
          <Button type="button" className={classes.save} variant="text" onClick={handleSaveReportConfirmationShow}>
            SAVE
          </Button>
        )}
        {isRecommendationsAvailable && (
          <RecommendationLink
            portfolioAnalysisIds={[portfolioAnalysis.id]}
            recommendationId={portfolioAnalysis.recommendationId}
            text="See Recommendations"
          />
        )}
      </div>
    </div>
  );
};

export default ReflectionReport;
