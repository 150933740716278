import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex',
      width: '100%',
      padding: '45px 0',
      [theme.breakpoints.up('md')]: {
        padding: '109px 0',
      },
    },
    side: {
      flexBasis: 200,
      backgroundColor: '#232020',
      color: '#fff',
      paddingTop: 147,
      flexShrink: 0,
    },
    main: {
      flexGrow: 1,
      width: '100%',
      overflow: 'hidden',
    },
    titleWrapper: {
      display: 'flex',
      justifyContent: 'center',
    },
    title: {
      fontFamily: theme.typography.font.ttHovesPro,
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: 52,
      color: '#232020',
      textAlign: 'center',
    },
    select: {
      width: '100%',
      marginBottom: 40,
      padding: [0, 25],
    },
    divider: {
      marginBottom: 88,
      width: '100%',
      maxWidth: 800,
      margin: '0 auto',
    },
    header: {
      fontFamily: theme.typography.font.ttHovesPro,
      fontWeight: 300,
      fontSize: 19,
      color: '#FFFFFF',
      textAlign: 'left',
      lineHeight: '20px',
      textTransform: 'uppercase',
      marginBottom: 8,
    },
    PDFIcon: {
      marginLeft: 25,
      display: 'inline-block',
      color: '#000',
    },
    icon: {
      fontSize: 40,
    },
    caption: {
      fontFamily: theme.typography.font.ttHovesPro,
      fontWeight: 300,
      fontSize: 18,
      letterSpacing: '0.01em',
      color: '#454545',
      textAlign: 'center',
      marginBottom: 34,
    },
    tabWrapper: {
      fontFamily: theme.typography.font.ttHovesPro,
      fontWeight: 700,
      fontSize: 13,
      color: '#868686',
      letterSpacing: '0.01em',
    },
    tabs: {
      marginBottom: 90,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      '@global': {
        '.Mui-selected': {
          fontFamily: theme.typography.font.ttHovesPro,
          fontWeight: 700,
          fontSize: 12,
          color: '#000000',
          letterSpacing: '0.01em',
          '& $tabWrapper': {
            color: '#000000',
          },
        },
      },
    },
    tabsIndicator: {
      backgroundColor: '#000',
    },
    tabsScroller: {
      borderBottom: '1px solid #dedede',
    },
    tabsRoot: {
      minHeight: 38,
    },
    tabRoot: {
      minHeight: 38,
      marginRight: 0,
    },
    hidden: {
      display: 'none',
    },
    notVisible: {
      visibility: 'hidden',
    },
  }),
  { name: 'IssuesDefined' },
);

export default useStyles;
