import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';
import { useHistory, useParams } from 'react-router-dom';
import Button from '@material-ui/core/Button';

import AppRoutes from 'routes/AppRoutes';

import { useApp, useMountMethods } from 'hooks';

import Heading from 'components/Heading';

import { IMPORTANCE_LEVELS } from 'presenters/ValuePresenter';
import CustomerLensPresenter from 'presenters/CustomerLensPresenter';

import Pillar from './components/Pillar';
import ResetButton from 'components/ResetButton';

import useStyles from './useStyles';

const CustomerLensValuesSet = props => {
  const {
    customerLens,
    onLoadCustomerLens,
    onUpdateCustomerLens,
    onResetCustomerLens,
    onChangeCustomerCategoryValue,
    onChangeCustomerPillarValue,
  } = props;

  const classes = useStyles();
  const [areValuesChanged, setAreValuesChanged] = useState(false);
  const isLensDefault = CustomerLensPresenter.lensDefault(customerLens);
  const history = useHistory();
  const routeParams = useParams();
  const { showLoader, hideLoader } = useApp();

  const shouldShowResetButton = areValuesChanged || !isLensDefault;

  const customerLensId = Number(routeParams.id);
  const handleLoadCustomerLens = () => {
    if (isEmpty(customerLens)) {
      showLoader('calibrating');
      onLoadCustomerLens(customerLensId).finally(() => hideLoader());
    }
  };

  useMountMethods({ onMount: handleLoadCustomerLens });

  if (isEmpty(customerLens)) {
    return null;
  }

  const customerPillarValues = CustomerLensPresenter.sortedCustomerPillarValues(customerLens);
  const customerCategoryValues = CustomerLensPresenter.sortedCustomerCategoryValues(customerLens);

  const handleClickBackButton = () => {
    history.push(AppRoutes.customerLensLensesPath());
  };

  const handleUpdateCustomerLens = () => {
    const params = {
      customerPillarValuesAttributes: customerPillarValues,
      customerCategoryValuesAttributes: customerCategoryValues,
    };

    showLoader('calibrating');
    onUpdateCustomerLens(CustomerLensPresenter.id(customerLens), params)
      .then(() => {
        history.push(AppRoutes.customerLensFlagsPath(CustomerLensPresenter.id(customerLens)));
      })
      .catch(() => hideLoader());
  };

  const handleResetSelections = () => {
    if (shouldShowResetButton && isLensDefault) {
      onLoadCustomerLens(customerLensId).finally(() => hideLoader());
      setAreValuesChanged(false);
      return;
    }

    showLoader('reset selections');
    onResetCustomerLens(customerLensId).finally(() => hideLoader());
    setAreValuesChanged(false);
  };

  const handleCustomerCategoryValueChange = (customerCategoryValue, newValue) => {
    setAreValuesChanged(true);
    onChangeCustomerCategoryValue(customerCategoryValue, newValue);
  };

  const handleCustomerPillarValueChange = (customerPillarValue, value) => {
    setAreValuesChanged(true);
    onChangeCustomerPillarValue(customerPillarValue, value);
  };

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Heading
          intro="What's important to you?"
          title="Refine Your Lens"
          description="While you may have a primary focus for your values-based investments, there may be certain company
              policies and practices that you find more important than others."
          className={classes.heading}
        />
        <ResetButton isActive={shouldShowResetButton} onClick={handleResetSelections} />
        <div className={classes.main}>
          <div className={classes.titleItem}>
            <div className={classes.titleItemSide} />
            <div className={classes.titleItemMain}>
              <table className={classes.titleItemTable}>
                <thead>
                  <tr>
                    <td className={classes.name}>Policies & Practices</td>
                    {IMPORTANCE_LEVELS.map(level => (
                      <td key={level.value} className={classes[level.key]}>
                        {level.value}
                      </td>
                    ))}
                    <td className={classes.titleItemAction} />
                  </tr>
                </thead>
              </table>
            </div>
          </div>
          <div className={classes.pillars}>
            {customerPillarValues.map(customerPillarValue => (
              <Pillar
                key={customerPillarValue.id}
                customerPillarValue={customerPillarValue}
                customerCategoryValues={customerCategoryValues}
                onChangeCustomerPillarValue={handleCustomerPillarValueChange}
                onChangeCustomerCategoryValue={handleCustomerCategoryValueChange}
              />
            ))}
          </div>
        </div>
      </div>
      <div className={classes.formActions}>
        <div className={classes.buttonWrapper}>
          <Button variant="contained" color="primary" size="large" onClick={handleUpdateCustomerLens}>
            Continue
          </Button>
        </div>
        <div className={classes.buttonWrapper}>
          <Button size="small" variant="text" onClick={handleClickBackButton}>
            Back
          </Button>
        </div>
      </div>
    </div>
  );
};

CustomerLensValuesSet.propTypes = {
  customerLens: CustomerLensPresenter.shape().isRequired,
  onLoadCustomerLens: PropTypes.func.isRequired,
  onUpdateCustomerLens: PropTypes.func.isRequired,
  onChangeCustomerPillarValue: PropTypes.func.isRequired,
  onChangeCustomerCategoryValue: PropTypes.func.isRequired,
  onResetCustomerLens: PropTypes.func.isRequired,
};

export default CustomerLensValuesSet;
