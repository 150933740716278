export default {
  user_type: 'Advisor',
  user_id: '201',
  institution_id: '2',
  advisor_name: 'Jake Advisor',
  advisor_id: '201',
  household_id: '200',
  clients: [
    {
      client_id: '200',
      client_name: 'Jake',
      birth_year: '1975',
    },
  ],
  accounts: [
    {
      account_id: '200',
      account_owner: ['200'],
      account_name: 'John Account',
      total_account_value: '70000.0',
      tickers: [
        {
          ticker: 'MMM',
          ticker_amount: '20000.0',
        },
        {
          ticker: 'FFF',
          ticker_amount: '30000.0',
        },
      ],
      holdings: [
        {
          holding_name: 'HoldingName1',
          holding_amount: '20000.0',
        },
      ],
      asset_classes: [
        {
          asset_class_id: '3',
          asset_class_percent: '100',
        },
      ],
    },
  ],
};
