import React from 'react';
import PropTypes from 'prop-types';
import { Bar, LabelList } from 'recharts';

import Chart from 'components/Chart';
import ChartLabelRotated from 'components/ChartLabelRotated';
import ChartBar from 'components/ChartBar';

import FundScorePresenter from 'presenters/FundScorePresenter';

const InsightsChart = props => {
  const { chartData, insights, onBarMouseEnter, onBarMouseLeave, verticalPoints, isShowValue } = props;

  const renderChartLabel = () => <ChartLabelRotated isShowValue={isShowValue} />;

  const renderBar = insight => (
    <Bar
      key={FundScorePresenter.id(insight)}
      dataKey={({ barOptions }) => barOptions[FundScorePresenter.fundName(insight)].value}
      shape={<ChartBar fund={insight} />}
      onMouseEnter={onBarMouseEnter(insight)}
      onMouseLeave={onBarMouseLeave}
      isAnimationActive={false}
    >
      <LabelList position="bottom" text={FundScorePresenter.fundTicker(insight)} content={renderChartLabel()} />
    </Bar>
  );

  return (
    <Chart data={chartData} verticalPoints={verticalPoints}>
      {insights.map(renderBar)}
    </Chart>
  );
};

InsightsChart.propTypes = {
  chartData: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  insights: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  verticalPoints: PropTypes.arrayOf(PropTypes.number).isRequired,
  onBarMouseEnter: PropTypes.func,
  onBarMouseLeave: PropTypes.func,
  isShowValue: PropTypes.bool,
};

InsightsChart.defaultProps = {
  onBarMouseEnter: () => {},
  onBarMouseLeave: () => {},
  isShowValue: false,
};

export default InsightsChart;
