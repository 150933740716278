import React from 'react';

import ReflectionReportPresenter from 'presenters/ReflectionReportPresenter';
import CustomerLensPresenter from 'presenters/CustomerLensPresenter';
import { nanoid } from 'nanoid';

import useStyles from './useStyles';

import Pillar from './components/Pillar';

const Panels = props => {
  const { customerLens, currentReport } = props;
  const classes = useStyles();
  const pillarAnalyses = ReflectionReportPresenter.sortedPillarAnalyses(currentReport);
  const customerCategoryValues = CustomerLensPresenter.sortedCustomerCategoryValues(customerLens);

  return (
    <div className={classes.root}>
      {pillarAnalyses.map(fundPillarAnalysis => (
        <Pillar
          key={nanoid()}
          fundPillarAnalysis={fundPillarAnalysis}
          currentReport={currentReport}
          customerCategoryValues={customerCategoryValues}
        />
      ))}
    </div>
  );
};

Panels.propTypes = {
  currentReport: ReflectionReportPresenter.shape().isRequired,
  customerLens: CustomerLensPresenter.shape().isRequired,
};

export default Panels;
