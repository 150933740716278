import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { Switch, Route } from 'react-router';

import AppRoutes from 'routes/AppRoutes';

import { useApp, useCustomerLensCreation } from 'hooks';
import PillarPresenter from 'presenters/PillarPresenter';
import CategoryPresenter from 'presenters/CategoryPresenter';
import CustomerLensPresenter from 'presenters/CustomerLensPresenter';
import CustomerPillarValuePresenter from 'presenters/CustomerPillarValuePresenter';
import CustomerCategoryValuePresenter from 'presenters/CustomerCategoryValuePresenter';
import UserPresenter from 'presenters/UserPresenter';

import { setById } from 'utils/storeUtils';

import CustomerLensValuesSet from './containers/CustomerLensValuesSet';
import CustomerLensDefinition from './containers/CustomerLensDefinition';
import CustomerLensFlagsSet from './containers/CustomerLensFlagsSet';

const CustomerLensCreation = props => {
  const { currentUser } = props;

  const {
    loadCustomerLens,
    createCustomerLens,
    createAdvisorCustomerLens,
    updateCustomerLens,
    changeCustomerLens,
    resetCustomerLens,
    resetCustomerLensState,
    customerLens,
  } = useCustomerLensCreation();

  const { selectedCustomer, changeShouldRedirectOnSelectedAccountChange } = useApp();

  const customerPillarValues = CustomerLensPresenter.customerPillarValues(customerLens);
  const customerCategoryValues = CustomerLensPresenter.customerCategoryValues(customerLens);
  const customerLensFlags = CustomerLensPresenter.customerLensFlags(customerLens);

  useEffect(() => {
    changeShouldRedirectOnSelectedAccountChange(true);

    return resetCustomerLensState;
  }, []);

  const handleCustomerLensCreate = params => {
    if (UserPresenter.isAdvisor(currentUser)) {
      const householdId = UserPresenter.householdId(currentUser);
      return createAdvisorCustomerLens(householdId, params);
    }

    return createCustomerLens(params);
  };

  const handleChangeCustomerPillarValue = (customerPillarValue, value) => {
    const customerPillarValueId = CustomerPillarValuePresenter.id(customerPillarValue);
    const pillar = CustomerPillarValuePresenter.pillar(customerPillarValue);
    const customerPillarValuePillarId = PillarPresenter.id(pillar);

    const newCustomerPillarValues = customerPillarValues.map(pillarValue => {
      if (customerPillarValueId === CustomerPillarValuePresenter.id(pillarValue)) {
        return { ...pillarValue, value };
      }

      return CustomerPillarValuePresenter.getRecalculatedPillarValue(pillarValue, customerCategoryValues);
    });

    const newCustomerCategoryValues = customerCategoryValues.map(customerCategoryValue => {
      const category = CustomerCategoryValuePresenter.category(customerCategoryValue);
      const pillarId = CategoryPresenter.pillarId(category);
      if (pillarId !== customerPillarValuePillarId) return customerCategoryValue;

      return { ...customerCategoryValue, value };
    });

    changeCustomerLens({
      ...customerLens,
      customerPillarValues: newCustomerPillarValues,
      customerCategoryValues: newCustomerCategoryValues,
    });
  };

  const handleChangeCustomerCategoryValue = (customerCategoryValue, newValue) => {
    const customerCategoryValueId = CustomerCategoryValuePresenter.id(customerCategoryValue);
    const newCustomerCategoryValues = setById(customerCategoryValues, customerCategoryValueId, ['value'], newValue);

    const category = CustomerCategoryValuePresenter.category(customerCategoryValue);
    const pillarId = CategoryPresenter.pillarId(category);

    const pillarCustomerCategoryValues = CustomerCategoryValuePresenter.getCustomerCategoryValuesForPillar(
      pillarId,
      newCustomerCategoryValues,
    );

    const categoryValuesPoints = CustomerCategoryValuePresenter.getPointsForCustomerCategoryValues(
      pillarCustomerCategoryValues,
    );

    const weightiestCategoryValue = CustomerCategoryValuePresenter.getWeightiestCategoryValue(categoryValuesPoints);

    const newCustomerPillarValues = customerPillarValues.map(customerPillarValue => {
      const pillar = CustomerPillarValuePresenter.pillar(customerPillarValue);
      const currentPillarId = PillarPresenter.id(pillar);
      if (pillarId === currentPillarId) {
        return { ...customerPillarValue, value: weightiestCategoryValue };
      }

      return CustomerPillarValuePresenter.getRecalculatedPillarValue(customerPillarValue, newCustomerCategoryValues);
    });

    changeCustomerLens({
      ...customerLens,
      customerCategoryValues: newCustomerCategoryValues,
      customerPillarValues: newCustomerPillarValues,
    });
  };

  return (
    <>
      <Switch>
        <Route path={AppRoutes.customerLensLensesPath()}>
          <CustomerLensDefinition onCreateCustomerLens={handleCustomerLensCreate} customerLens={customerLens} />
        </Route>
        <Route path={AppRoutes.customerLensValuesPath(':id')}>
          <CustomerLensValuesSet
            customerLens={customerLens}
            onUpdateCustomerLens={updateCustomerLens}
            onResetCustomerLens={resetCustomerLens}
            onChangeCustomerPillarValue={handleChangeCustomerPillarValue}
            onLoadCustomerLens={loadCustomerLens}
            onChangeCustomerCategoryValue={handleChangeCustomerCategoryValue}
          />
        </Route>
        <Route path={AppRoutes.customerLensFlagsPath(':id')} exact>
          <CustomerLensFlagsSet
            customerLens={customerLens}
            customerLensFlags={customerLensFlags}
            onLoadCustomerLens={loadCustomerLens}
            onUpdateCustomerLens={updateCustomerLens}
            currentUser={currentUser}
            selectedCustomer={selectedCustomer}
          />
        </Route>

        <Redirect to={AppRoutes.customerLensLensesPath()} />
      </Switch>
    </>
  );
};

CustomerLensCreation.propTypes = {
  currentUser: UserPresenter.shape().isRequired,
};

export default CustomerLensCreation;
