import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    minWidth: 350,
  },
  links: {
    marginTop: 4,
    marginRight: 15,
  },
}));
