import React from 'react';
import PropTypes from 'prop-types';
import { trim } from 'utils/stringHelpers';
import useStyles from './useStyles';

const TRIM_COUNTER = 6;

const ChartLabelRotated = props => {
  const { x, y, width, height, text, value, isShowValue } = props;
  const classes = useStyles();
  const label = trim(text, TRIM_COUNTER);

  return (
    <text
      x={x + width / 2}
      y={y + height}
      textAnchor="end"
      dominantBaseline="middle"
      transform={`translate(0, 10) rotate(-90, ${x + width / 2}, ${y + height})`}
      className={classes.text}
    >
      {label}
      <title>{text}</title>
      {isShowValue && <tspan className={classes.smallText}> ({parseFloat(value).toFixed(2)})</tspan>}
    </text>
  );
};

ChartLabelRotated.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  text: PropTypes.string,
  value: PropTypes.string,
  isShowValue: PropTypes.bool,
};

export default ChartLabelRotated;
