import PropTypes from 'prop-types';

import Presenter from 'utils/PropTypesPresenter';

import CustomerPresenter from 'presenters/CustomerPresenter.js';

export default new Presenter(
  {
    id: PropTypes.number,
    customers: PropTypes.arrayOf(CustomerPresenter.shape()),
  },
  {},
);
