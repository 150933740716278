import { isEmpty, reverse, omit } from 'ramda';

import { COMPANY_TYPE, TICKER_KIND } from 'enums/Ticker';

export const prepareTickers = (customerFunds, assetClasses) => {
  const preparedTickers = [];

  customerFunds.forEach(customerFund => {
    const { name, id, ticker, companies, kind } = customerFund.fund;

    const isCompany = kind === TICKER_KIND.stock;

    if (isCompany) {
      const companyTickers = companies.map(company => ({
        id: customerFund.id,
        assetClass: assetClasses.find(assetClassItem => assetClassItem.id === customerFund.assetClassIds[0]),
        entityName: company.name,
        name: company.ticker,
        companyId: company.id,
        type: COMPANY_TYPE.company,
        amount: Math.floor(Number(customerFund.amount) / companies.length),
      }));

      preparedTickers.push(...companyTickers);

      return;
    }

    if (!isCompany) {
      const isEmptyAssetClassIds = isEmpty(customerFund.assetClassIds);
      const assetClass = isEmptyAssetClassIds
        ? null
        : assetClasses.find(assetClassItem => assetClassItem.id === customerFund.assetClassIds[0]);

      const fundTicker = {
        id: customerFund.id,
        assetClass,
        entityName: name,
        name: ticker,
        fundId: id,
        type: COMPANY_TYPE.fund,
        amount: Number(customerFund.amount),
      };

      preparedTickers.push(fundTicker);
    }
  });

  return preparedTickers;
};

export const prepareHousehold = (data, assetClasses) => {
  const { id, externalId, institutionId, name, customers, accounts } = data;

  const preparedCustomers = reverse(customers).map(customer => ({
    ...omit(['fullName'], customer),
    customerName: customer.fullName,
    birthDate: null,
  }));

  const preparedAccounts = accounts.map(account => ({
    ...omit(['name', 'customerFunds'], account),
    accountName: account.name,
    tickers: prepareTickers(account.customerFunds, assetClasses),
  }));

  return {
    id,
    externalId,
    institutionId,
    name,
    customers: preparedCustomers,
    accounts: preparedAccounts,
  };
};
