import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  theme => ({
    container: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: 15,
      marginBottom: 100,
    },
    buttonsGroup: {
      display: 'flex',
      flexDirection: 'column',
      width: 235,
      margin: 'auto',
      marginBottom: 65,
    },
    continueButton: {
      padding: 13,
      fontSize: 18,
      marginBottom: 15,
    },
    backBottom: {
      color: theme.palette.primary.main,
      fontSize: 18,
    },
    nav: {
      display: 'flex',
      alignItems: 'center',
      fontFamily: theme.typography.font.ttHovesPro,
      fontWeight: 700,
      fontSize: 12,
      letterSpacing: '0.01em',
      marginTop: 23,
      marginBottom: 45,
      padding: '0 32px',
      maxWidth: 1440,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    loaderWrap: {
      height: 542,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }),
  { name: 'Recommendation' },
);

export default useStyles;
