import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  theme => ({
    root: {
      marginTop: 50,
      padding: '0 30px',
    },
    slider: {
      '& .slick-arrow::before': {
        color: 'black',
      },
    },
    title: {
      fontFamily: theme.typography.font.ttHovesPro,
      fontWeight: 700,
      color: '#232020',
      fontSize: 13,
      marginRight: 19,
    },
    active: {
      '&$button': {
        borderColor: '#232020',
        padding: [4, 3],
      },
      '& $buttonlabel': {
        color: '#232020',
      },
    },
    button: {
      marginRight: 10,
      borderColor: '#dfdfdf',
      color: '#868686',
      '&:last-child': {
        marginRight: 0,
      },
    },
    buttonRoot: {
      border: '1px solid #dfdfdf',
      padding: [4, 3],
      maxWidth: '80%',
    },
    buttonlabel: {
      fontFamily: theme.typography.font.ttHovesPro,
      fontWeight: 700,
      color: '#868686',
      fontSize: 13,
      letterSpacing: 0,
      textTransform: 'uppercase',
    },
    checkIcon: {
      width: 15,
      height: 13,
      marginRight: 7,
    },
  }),
  { name: 'Compare' },
);

export default useStyles;
