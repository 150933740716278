import React from 'react';
import MaterialRadio from '@material-ui/core/Radio';

import useStyles from './useStyles';
import clsx from 'clsx';

const Radio = props => {
  const classes = useStyles();

  return (
    <MaterialRadio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
};

export default Radio;
