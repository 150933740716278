import { indexOf, length, isEmpty, symmetricDifference, forEach, pick } from 'ramda';

const NOT_FOUND_INDEX = -1;

export const isElementExist = (element, array) => indexOf(element, array) !== NOT_FOUND_INDEX;

export const isLastElement = (index, array) => length(array) - 1 === index;

export const hasSingleElement = array => length(array) === 1;

export const isEquals = (arr1, arr2) => isEmpty(symmetricDifference(arr1, arr2));

export const uniqArray = (fields, arr) => {
  const uniqObjects = new Map();

  forEach(obj => {
    const objKey = JSON.stringify(pick(fields, obj));
    uniqObjects.set(objKey, obj);
  }, arr);

  return Array.from(uniqObjects.values());
};
