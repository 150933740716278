import PropTypes from 'prop-types';
import { getIntPercent } from 'utils/numberHelpers';
import Presenter from 'utils/PropTypesPresenter';

import CustomerLensPresenter from 'presenters/CustomerLensPresenter';

export const REPORT_OVERVIEW = { label: 'Overview', description: 'Portfolio Overview' };

const OVERWIEW = 'Overview';
const STOCK = 'stock';

export default new Presenter(
  {
    id: PropTypes.number,
    name: PropTypes.string,
    ticker: PropTypes.string,
    customerLens: CustomerLensPresenter.shape(),
    reflectionMatchScore: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    totalCompaniesCount: PropTypes.number,
    scoredCompaniesCount: PropTypes.number,
    scoredAmountPercents: PropTypes.string,
    companies: PropTypes.arrayOf(PropTypes.shape()),
    scoredAmount: PropTypes.string,
    totalAmount: PropTypes.string,
    pillarAnalyses: PropTypes.arrayOf(PropTypes.shape()),
    categoryAnalyses: PropTypes.arrayOf(PropTypes.shape()),
    companiesWithAttachedFlagsCount: PropTypes.number,
  },
  {
    isOverview(report) {
      return this.name(report) === REPORT_OVERVIEW.label;
    },
    matchScorePercent(report) {
      const value = this.reflectionMatchScore(report);
      return getIntPercent(value);
    },
    sortedPillarAnalyses(report) {
      const pillarAnalyses = this.pillarAnalyses(report).slice();
      return pillarAnalyses.sort((a, b) => a.pillar.displayOrder - b.pillar.displayOrder);
    },
    categoryAnalysesBySlug(report, slug) {
      return this.categoryAnalyses(report)
        .filter(({ category: { pillar } }) => pillar.slug === slug)
        .sort((a, b) => a.category.displayOrder - b.category.displayOrder);
    },
    description(report) {
      if (report.ticker === OVERWIEW) {
        return this.name(report);
      }
      if (report.kind === STOCK) {
        return '';
      }
      return `${this.name(report)} (${this.ticker(report)})`;
    },
  },
);
