import Routes from 'routes/ApiRoutes';
import FetchHelpers from 'utils/FetchHelpers';

export default {
  show(params) {
    const path = Routes.adminApiV1ConfigurationPath();

    return FetchHelpers.get(path, params);
  },

  update(params) {
    const path = Routes.adminApiV1ConfigurationPath();

    return FetchHelpers.putMultipartFormData(path, params);
  },
};
