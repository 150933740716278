import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    marginRight: 40,
    width: 640,
  },
  title: {
    fontSize: 32,
  },
  head: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: [15, 0],
    borderBottom: '1px #E4E4E4 solid',
  },
  dropdown: {
    minWidth: 200,
  },
  list: {
    borderBottom: '1px #e0e0e0 solid',
  },
  listItem: {
    gridTemplateColumns: '140px 50px 1fr 50px 22px',
    columnGap: '12px',
    display: 'grid',
    paddingTop: '6px',
    paddingBottom: '6px',
    borderTop: '1px #e0e0e0 solid',
  },
  listHead: {
    padding: theme.spacing(2),
    color: '#232020',
  },
  listItemText: {
    color: '#232020',
    fontWeight: 300,
    overflowWrap: 'break-word',
    textAlign: 'center',
  },
  bold: {
    fontWeight: 'bold',
  },
  loader: {
    minHeight: 500,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default useStyles;
