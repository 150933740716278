import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  warning: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    fontSize: 18,
  },
  title: {
    fontFamily: theme.typography.font.ttHovesPro,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 52,
    color: '#232020',
    textAlign: 'center',
    marginBottom: 27,
  },
  remark: {
    fontSize: 30,
  },
  select: {
    width: '100%',
    marginBottom: 30,
    padding: [0, 25],
  },
  chartDescription: {
    fontFamily: theme.typography.font.ttHovesPro,
    fontWeight: 700,
    fontSize: 12,
    letterSpacing: '0.01em',
    color: '#232020',
  },
  divider: {
    marginBottom: 45,
    width: '100%',
    maxWidth: 800,
    margin: '0 auto',
  },
  chart: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '85%',
  },
  caption: {
    fontFamily: theme.typography.font.ttHovesPro,
    fontWeight: 300,
    fontSize: 12,
    letterSpacing: '0.01em',
    color: '#515151',
    textAlign: 'left',
    marginRight: 14,
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '-26px',
  },
}));

export default useStyles;
