import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-admin';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import { useHistory } from 'react-router-dom';

import UsersRepository from 'repositories/admin/UsersRepository';

import UserPresenter from 'presenters/UserPresenter';

import AdminRoutes from 'routes/AdminRoutes';

const ImpersonateButton = props => {
  const { record: user } = props;

  const history = useHistory();

  const handleImpersonate = async e => {
    e.stopPropagation();

    const userId = UserPresenter.id(user);
    await UsersRepository.impersonate(userId);
    history.push(AdminRoutes.moneyguide());
  };

  return (
    <Button
      aria-label="impersonate"
      type="button"
      onClick={handleImpersonate}
      size="small"
      disabled={UserPresenter.isAdmin(user)}
    >
      <RemoveRedEyeIcon />
    </Button>
  );
};

ImpersonateButton.propTypes = {
  record: PropTypes.shape(),
};

export default ImpersonateButton;
