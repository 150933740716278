import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  theme => ({
    main: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.ivory,
    },
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    logo: {
      margin: theme.spacing(1),
    },
    form: {
      width: '100%',
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    autocompleteEndAdornment: {
      display: 'flex',
    },
    signInLink: {
      margin: theme.spacing(2, 0, 2),
      backgroundColor: theme.palette.governance,
    },
  }),
  { name: 'ManuallyEnteredPortfolio' },
);

export default useStyles;
