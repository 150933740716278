export default {
  MuiChip: {
    root: {
      borderRadius: 5,
      textTransform: 'uppercase',
      fontFamily: 'TTHovesPro',
    },
    label: {
      fontWeight: 700,
      fontSize: 12,
      letterSpacing: '0.01em',
      color: '#000',
      paddingLeft: 9,
      paddingRight: 9,
    },
    outlined: {
      border: '1px solid #232020',
    },
  },
};
