import rollbar from 'rollbar';

const rollbarConfig = {
  accessToken: process.env.ROLLBAR_CLIENT_ACCESS_TOKEN,
  enabled: ['staging', 'demo', 'production'].includes(process.env.ROLLBAR_ENV),
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: process.env.ROLLBAR_ENV,
    client: {
      javascript: {
        source_map_enabled: true,
        guess_uncaught_frames: true,
      },
    },
  },
};

export default rollbar.init(rollbarConfig);
