import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  input: {
    backgroundColor: theme.palette.common.white,
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: `16px`,
  },
}));

export default useStyles;
