import { fetchUtils } from 'react-admin';

function authenticityToken() {
  const token = document.querySelector('meta[name="csrf-token"]');
  return token ? token.content : null;
}

export const httpClient = (url, options = {}) => {
  if (!options.headers) {
    // eslint-disable-next-line no-param-reassign
    options.headers = new Headers({ Accept: 'application/json' });
  }
  options.headers.set('X-CSRF-Token', authenticityToken());
  options.headers.set('X-Requested-With', 'XMLHttpRequest');
  return fetchUtils.fetchJson(url, options);
};

const authProvider = {
  login: ({ username, password }) => {
    const request = new Request('/admin/api/v1/session', {
      method: 'POST',
      body: JSON.stringify({ user: { email: username, password } }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    });
    return fetch(request).then(response => {
      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
      }
      localStorage.setItem('token', true);
    });
  },
  logout: () => {
    const request = new Request('/admin/api/v1/session', {
      method: 'DELETE',
      headers: new Headers({
        'Content-Type': 'application/json',
        'X-CSRF-Token': authenticityToken(),
        'X-Requested-With': 'XMLHttpRequest',
      }),
    });
    return fetch(request).then(() => {
      localStorage.removeItem('token');
    });
  },
  checkAuth: () => (localStorage.getItem('token') ? Promise.resolve() : Promise.reject()),
  checkError: error => {
    const { status } = error;
    if (status === 401 || status === 403) {
      localStorage.removeItem('token');
      return Promise.reject();
    }
    return Promise.resolve();
  },
  getPermissions: () => Promise.resolve(),
};

export default authProvider;
