import React from 'react';
import { isEmpty, lensPath, view } from 'ramda';
import { FieldArray } from 'formik';
import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import AddIcon from '@material-ui/icons/Add';

import { valuesShape } from 'containers/AdvisorForm/propTypes';
import { initialCustomerValues, initialAccountValues } from 'forms/AdvisorForm';

import Customer from './components/Customer';
import Account from './components/Account';

import useStyles from './useStyles';

const Household = props => {
  const classes = useStyles();
  const { onChange, onRemoveCustomer, onRemoveAccount, values, errors, setFieldValue, setErrors, disabled } = props;
  const { household } = values;

  const householdName = household.name;

  const householdNameErrorLens = lensPath(['household', 'name']);
  const householdNameError = view(householdNameErrorLens, errors);

  return (
    <Paper variant="outlined" className={classes.root}>
      <Typography component="h2" variant="h5" color="primary">
        Household{isEmpty(householdName) ? '' : `: ${householdName}`}
      </Typography>

      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="household.name"
        label="Name"
        name="household.name"
        autoFocus
        onChange={onChange}
        value={householdName}
        disabled={disabled}
        error={Boolean(householdNameError)}
        helperText={Boolean(householdNameError) && householdNameError}
      />

      <div className={classes.content}>
        <Typography component="h3" variant="h6" color="primary">
          Customers
        </Typography>

        <FieldArray name="household.customers">
          {({ remove: removeCustomer, push: pushCustomer }) => (
            <div>
              {values.household.customers.map((customer, customerIndex) => (
                <Customer
                  key={`household.customer.${customer.id}`}
                  errors={errors}
                  customerIndex={customerIndex}
                  customer={customer}
                  onChange={onChange}
                  setFieldValue={setFieldValue}
                  onCustomerRemove={onRemoveCustomer(removeCustomer, customer)}
                  disabledDeletion={values.household.customers.length === 1}
                  disabled={disabled}
                />
              ))}
              <Button
                type="button"
                variant="contained"
                color="secondary"
                className={classes.addCustomerButton}
                disabled={disabled}
                startIcon={<AddIcon />}
                onClick={() => pushCustomer({ ...initialCustomerValues, id: nanoid() })}
              >
                Add Customer
              </Button>
            </div>
          )}
        </FieldArray>
      </div>

      <div className={classes.content}>
        <Typography component="h3" variant="h6" color="primary">
          Accounts
        </Typography>

        <FieldArray name="household.accounts">
          {({ remove: removeAccount, push: pushAccount }) => (
            <div>
              {values.household.accounts.map((account, accountIndex) => (
                <Account
                  key={`household.account.${account.id}`}
                  accountIndex={accountIndex}
                  account={account}
                  onChange={onChange}
                  onAccountRemove={onRemoveAccount(removeAccount, account)}
                  values={values}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  setErrors={setErrors}
                  disabledDeletion={values.household.accounts.length === 1}
                  disabled={disabled}
                />
              ))}
              <Button
                type="button"
                variant="contained"
                color="secondary"
                className={classes.addAccountButton}
                disabled={disabled}
                startIcon={<AddIcon />}
                onClick={() => pushAccount({ ...initialAccountValues, id: nanoid() })}
              >
                Add Account
              </Button>
            </div>
          )}
        </FieldArray>
      </div>
    </Paper>
  );
};

Household.propTypes = {
  values: valuesShape,
  errors: PropTypes.shape({}).isRequired,
  onChange: PropTypes.func.isRequired,
  onRemoveCustomer: PropTypes.func,
  onRemoveAccount: PropTypes.func,
  setFieldValue: PropTypes.func.isRequired,
  setErrors: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

Household.defaultProps = {
  onRemoveCustomer: () => {},
  onRemoveAccount: () => {},
};

export default Household;
