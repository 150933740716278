import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  theme => ({
    header: {
      minHeight: 94,
      boxShadow: '-4px 4px 15px 0 rgba(0,0,0,0.10)',
      backgroundColor: '#fff',
      display: 'flex',
      alignItems: 'center',
      padding: '0 48px 0 40px',
      zIndex: 1,
      flexShrink: 0,
      fontFamily: theme.typography.font.ttHovesPro,
    },
    progress: {
      flexGrow: 1,
      flexShrink: 0,
      paddingRight: 24,
      [theme.breakpoints.up('md')]: {
        paddingRight: 48,
      },
    },
    logo: {
      width: 140,
      marginRight: 40,
      '& img': {
        width: '100%',
        display: 'block',
      },
      [theme.breakpoints.up('md')]: {
        width: 170,
        marginRight: 60,
      },
    },
    logoImage: {
      maxHeight: 100,
      maxWidth: 175,
    },
    menu: {
      display: 'flex',
      flexDirection: 'column',
    },
    userContainer: {
      minWidth: 135,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      flexShrink: 0,
      [theme.breakpoints.up('md')]: {
        minWidth: 175,
      },
    },
    greeting: {
      padding: '0 20px 0 9px',
      fontWeight: 300,
      fontSize: 13,
      letterSpacing: '0.01em',
      transform: 'translateY(-1px)',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    selectContainer: {
      width: 175,
      marginTop: 10,
    },
    addButton: {
      fontSize: '9px',
      borderWidth: '1px',
      margin: 0,
      marginLeft: '20px',
      maxWidth: '110px',
      padding: '5px 10px',
      lineHeight: '12px',
    },
  }),
  { name: 'Header' },
);

export default useStyles;
