import { makeStyles } from '@material-ui/core/styles';
import gradient from './images/gradient.svg';

const useStyles = makeStyles(
  theme => ({
    mainHeader: {
      color: '#232020',
      fontWeight: 900,
      fontSize: 21,
      marginBottom: 27,
    },
    compare: {
      marginBottom: 32,
    },
    counter: {
      backgroundImage: `url(${gradient})`,
      width: 140,
      height: 128,
      color: theme.palette.background.ivory,
      padding: '8px 15px 7px 15px',
      overflow: 'hidden',
      marginRight: 20,
      backgroundSize: '100% 100%',

      [theme.breakpoints.up('md')]: {
        width: 200,
        height: 185,
        marginRight: 30,
      },
      '@media print': {
        colorAdjust: 'exact',
        '-webkit-print-color-adjust': 'exact',
      },
    },
    loader: {
      width: 200,
      display: 'flex',
      justifyContent: 'center',
    },
    value: {
      fontSize: 40,
      fontWeight: 500,
      top: -9,
      position: 'relative',
      width: '100%',
      [theme.breakpoints.up('md')]: {
        fontSize: 66,
        top: -3,
      },
    },
    number: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      display: 'block',
      lineHeight: 1,
    },
    counterTitle: {
      position: 'absolute',
      fontSize: 13,
      fontWeight: 700,
      bottom: 0,
      left: '50%',
      transform: 'translateX(-50%)',
      marginBottom: '-22px',
    },
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      height: '100%',
      textAlign: 'center',
    },
    info: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 31,
      '@media print': {
        marginBottom: 0,
        height: 185,
      },
    },
    cards: {
      display: 'flex',
      alignItems: 'center',
    },
    card: {
      marginRight: 10,
      '&:last-child': {
        marginRight: 0,
      },
      flexDirection: 'column',
      display: 'flex',
    },
    icon: {
      width: 32,
      height: 32,
      color: '#999',
      marginBottom: 10,
    },
  }),
  { name: 'Stat' },
);

export default useStyles;
