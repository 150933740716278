import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';
import AppRoutes from 'routes/AppRoutes';

import Button from '@material-ui/core/Button';

import { objectToQueryString } from 'utils/urlUtils';

import useStyles from './useStyles';
import clsx from 'clsx';

const RecommendationLink = props => {
  const { recommendationId, size, text, link, portfolioAnalysisIds } = props;
  const classes = useStyles();

  const isBig = size === 'big';
  const isSmall = size === 'small';

  const rootStyles = clsx({ [classes.rootBig]: isBig });
  const buttonStyles = clsx({
    [classes.recommendationsButtonBig]: isBig,
    [classes.recommendationsButtonSmall]: isSmall,
  });

  const search = objectToQueryString({ portfolio_analyses_ids: portfolioAnalysisIds });

  const generateLink = () => {
    const pathName = isEmpty(link) ? AppRoutes.recommendationsPath(recommendationId) : link;
    return { pathname: pathName, search };
  };

  return (
    <div className={rootStyles}>
      <Link to={generateLink()}>
        <Button classes={{ root: buttonStyles }} variant="contained" color="primary" size={isBig ? 'large' : 'medium'}>
          {text}
        </Button>
      </Link>
    </div>
  );
};

RecommendationLink.propTypes = {
  text: PropTypes.string,
  recommendationId: PropTypes.number.isRequired,
  size: PropTypes.oneOf(['small', 'big']),
  link: PropTypes.string,
  portfolioAnalysisIds: PropTypes.arrayOf(PropTypes.number),
};

RecommendationLink.defaultProps = {
  size: 'small',
  text: 'Continue',
  link: '',
  portfolioAnalysisIds: [],
};

export default RecommendationLink;
