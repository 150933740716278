import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  () => ({
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    issue: {
      marginLeft: 16,
      fontSize: 17,
      letterSpacing: '0.01em',
    },
  }),
  { name: 'IssuesItem' },
);

export default useStyles;
