import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';

import AppLayout from 'layouts/AppLayout';

import CustomerLensCreation from 'containers/CustomerLensCreation';
import ReflectionReport from 'containers/ReflectionReport';
import Recommendation from 'containers/Recommendation';
import Welcome from 'containers/Welcome';

import UserPresenter from 'presenters/UserPresenter';
import SavedFundPresenter from 'presenters/SavedFundPresenter';

import AppRoutes from 'routes/AppRoutes';

const CustomerUserSwitch = props => {
  const { onSignOut, currentUser, onStopImpersonating, currentUserMeta, savedFunds, onDestroySavedFund } = props;

  return (
    <AppLayout
      savedFunds={savedFunds}
      onDestroySavedFund={onDestroySavedFund}
      onSignOut={onSignOut}
      currentUser={currentUser}
      onStopImpersonating={onStopImpersonating}
      currentUserMeta={currentUserMeta}
    >
      <Switch>
        <Route path={AppRoutes.rootPath()} exact>
          <Welcome currentUser={currentUser} />
        </Route>
        <Route path={AppRoutes.customerLensPath()}>
          <CustomerLensCreation currentUser={currentUser} />
        </Route>
        <Route
          path={AppRoutes.recommendationsPath(':recommendationId')}
          render={routerProps => <Recommendation key={routerProps.match.params.id} />}
        />
        <Route
          path={AppRoutes.portfolioAnalysisPath(':id')}
          render={routerProps => <ReflectionReport key={routerProps.match.params.id} />}
        />

        <Redirect to={AppRoutes.rootPath()} />
      </Switch>
    </AppLayout>
  );
};

CustomerUserSwitch.propTypes = {
  savedFunds: PropTypes.arrayOf(SavedFundPresenter.shape()).isRequired,
  onDestroySavedFund: PropTypes.func.isRequired,
  onSignOut: PropTypes.func.isRequired,
  currentUser: UserPresenter.shape().isRequired,
  onStopImpersonating: PropTypes.func.isRequired,
  currentUserMeta: PropTypes.shape().isRequired,
};

export default CustomerUserSwitch;
