import React from 'react';
import { isNil } from 'ramda';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';

import AppRoutes from 'routes/AppRoutes';
import { useApp } from 'hooks';
import Heading from 'components/Heading';

import AccountPresenter from 'presenters/AccountPresenter';
import UserPresenter from 'presenters/UserPresenter';

import useStyles from './useStyles';

const CustomerWelcome = () => {
  const classes = useStyles();
  const history = useHistory();

  const { currentUser, selectedAccount } = useApp();

  const portfolioAnalysisId = AccountPresenter.customerPortfolioAnalysisId(selectedAccount);
  if (!isNil(portfolioAnalysisId)) {
    history.push(AppRoutes.portfolioAnalysisPath(portfolioAnalysisId));
    return null;
  }

  const handleGetStarted = () => {
    history.push(AppRoutes.customerLensLensesPath());
  };

  const title = isNil(UserPresenter.fullName(currentUser))
    ? 'Welcome.'
    : `Welcome, ${UserPresenter.fullName(currentUser)}.`;

  return (
    <>
      <Heading title={title} description="Let’s get started on your Impact Investing journey." />
      <div className={classes.actions}>
        <Button variant="contained" color="primary" size="large" onClick={handleGetStarted}>
          Get Started
        </Button>
      </div>
    </>
  );
};

export default CustomerWelcome;
