import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    heading: {
      marginTop: 84,
      marginBottom: 49,
    },
    container: {
      width: '100%',
      maxWidth: 840,
      margin: '0 auto',
      [theme.breakpoints.up('md')]: {
        maxWidth: 960,
      },
    },
    main: {
      marginBottom: 40,
    },
    nextButtonRoot: {
      background: '#0F5E75',
      borderRadius: 35,
      width: 294,
      height: 70,
      marginBottom: 25,
      '&:hover': {
        background: '#0F5E75',
        borderRadius: 35,
      },
      '&:disabled': {
        opacity: 0.4,
      },
    },
    formActions: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      marginTop: 71,
      marginBottom: 83,
    },
    buttonWrapper: {
      alignSelf: 'center',
      marginBottom: 30,
    },
  }),
  { name: 'CustomerLensFlagsSet' },
);

export default useStyles;
