import { useEffect } from 'react';

export default ({ onMount, onUnmount = () => {} }, observableData = []) => {
  useEffect(() => {
    if (onMount) {
      onMount();
    }

    return onUnmount;
  }, observableData);
};
