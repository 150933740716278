const typography = {
  font: {
    ttHovesPro: 'TTHovesPro',
  },
  h2: {
    fontFamily: 'TTHovesPro',
    fontWeight: 500,
    fontSize: '64px',
    lineHeight: '64px',
    letterSpacing: '-0.02em',
  },
  h5: {
    fontFamily: 'TTHovesPro',
    fontWeight: 400,
    fontSize: '1.7rem',
    lineHeight: '1.5rem',
  },
  h6: {
    fontFamily: 'TTHovesPro',
    fontWeight: 400,
    fontSize: '1.3rem',
    lineHeight: '1.6rem',
  },
};

export default typography;
