import PropTypes from 'prop-types';
import CategoryPresenter from 'presenters/CategoryPresenter';
import Presenter from 'utils/PropTypesPresenter';

export default new Presenter(
  {
    id: PropTypes.number,
    grouppedWeightText: PropTypes.string,
    category: CategoryPresenter.shape(),
    grouppedWeightFullText: PropTypes.string,
  },

  {
    sortedCategoryAnalyses(analyses) {
      return analyses.slice().sort((a, b) => a.category.displayOrder - b.category.displayOrder);
    },
  },
);
