import React from 'react';
import notAllow from './images/notAllow.svg';
import useStyles from './useStyles';

import FlagPresenter from 'presenters/FlagPresenter';

const IssuesItem = ({ flag }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <img src={notAllow} alt="flag icon" />
      <div className={classes.issue}>{FlagPresenter.name(flag)}</div>
    </div>
  );
};

IssuesItem.propTypes = {
  flag: FlagPresenter.shape(),
};

export default IssuesItem;
