/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import FundTickersRepository from 'repositories/portfolioAnalysis/FundTickersRepository';
import MultipleAccountFundTickersRepository from 'repositories/customerPortfolioAnalysesBatches/FundTickersRepository';
import { FETCH_STATUSES } from 'presenters/FetchStatusPresenter';

const initialState = {
  fundTickers: {
    status: FETCH_STATUSES.idle,
    data: [],
  },
};

const detailedFundInformationSlice = createSlice({
  name: 'detailedFundInformation',
  initialState,
  reducers: {
    loadFundTickersStart(state) {
      state.fundTickers.status = FETCH_STATUSES.loading;
    },
    loadFundTickersSuccess(state, { payload }) {
      state.fundTickers.status = FETCH_STATUSES.success;
      state.fundTickers.data = payload.fundTickers;
    },
    loadFundTickersFail(state) {
      state.fundTickers.status = FETCH_STATUSES.failure;
    },
  },
});

export const {
  loadFundTickersStart,
  loadFundTickersSuccess,
  loadFundTickersFail,
} = detailedFundInformationSlice.actions;

export default detailedFundInformationSlice.reducer;

export const useDetailedFundInformationActions = () => {
  const dispatch = useDispatch();

  const loadFundTickers = (portfolioAnalysisId, params) => {
    dispatch(loadFundTickersStart());

    return FundTickersRepository.index(portfolioAnalysisId, {
      q: {
        typeEq: 'ScorableFundTicker',
        s: 'total_weight desc',
        withoutPagination: true,
        ...params,
      },
    })
      .then(({ data }) => {
        dispatch(loadFundTickersSuccess(data));
        return Promise.resolve(data);
      })
      .catch(() => dispatch(loadFundTickersFail()));
  };

  const loadMultipleAccountsFundTickers = (portfolioAnalysisBatchId, accountIds, params) => {
    dispatch(loadFundTickersStart());

    return MultipleAccountFundTickersRepository.index(portfolioAnalysisBatchId, {
      q: {
        customer_portfolio_analysis_id_in: accountIds,
        type_eq: 'ScorableFundTicker',
        s: 'total_weight desc',
        withoutPagination: true,
        ...params,
      },
    })
      .then(({ data }) => {
        dispatch(loadFundTickersSuccess(data));
        return Promise.resolve(data);
      })
      .catch(() => dispatch(loadFundTickersFail()));
  };

  return {
    loadFundTickers,
    loadMultipleAccountsFundTickers,
  };
};
