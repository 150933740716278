import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  theme => ({
    root: {
      paddingRight: 15,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    title: {
      fontFamily: theme.typography.font.ttHovesPro,
      fontWeight: 'normal',
      fontSize: 40,
      textAlign: 'center',
      marginBottom: 2,
      marginTop: 1,
    },
    description: {
      fontFamily: theme.typography.font.ttHovesPro,
      fontWeight: 300,
      fontSize: 16,
      letterSpacing: '0.01em',
      color: '#000',
      textAlign: 'center',
      lineHeight: '22px',
      marginBottom: 24,
      maxWidth: 536,
    },
    image: {
      borderRadius: '50%',
      marginBottom: 24,
    },
    value: {
      width: '100%',
      maxWidth: 640,
    },
    buttonRoot: {
      border: '2px solid #232020',
      borderRadius: 31,
      width: 132,
      marginTop: 69,
    },
    buttonLabel: {
      fontSize: 12,
      color: '#232020',
      letterSpacing: '0.02em',
      textTansform: 'uppercase',
      fontFamily: theme.typography.font.ttHovesPro,
      fontWeight: 'bold',
    },
  }),
  { name: 'PillarInfo' },
);

export default useStyles;
