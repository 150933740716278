import React from 'react';
import PropTypes from 'prop-types';
import Loader from 'components/Loader';

import useStyles from './useStyles';

const CardContent = props => {
  const { title, value, isLoading } = props;
  const classes = useStyles();
  return (
    <div className={classes.block}>
      <div className={classes.title}>{title}</div>
      {isLoading ? (
        <div className={classes.loader}>
          <Loader />
        </div>
      ) : (
        <div className={classes.value}>
          <span>{value}</span>
        </div>
      )}
    </div>
  );
};

CardContent.propTypes = {
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isLoading: PropTypes.bool.isRequired,
};

export default CardContent;
