import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';

import CustomerFundAnalysisPresenter from 'presenters/CustomerFundAnalysisPresenter';
import CompanyPresenter from 'presenters/CompanyPresenter';

import Compare from 'components/Compare';
import PdfTemplate from 'components/PdfTemplate';

import Chart from 'components/IssuesDefined/components/Chart';
import Table from 'components/IssuesDefined/components/Table';

import useStyles from './useStyles';

const IssuesDefinedPDF = props => {
  const {
    customerFundAnalyses,
    selectedFundIds,
    scoredCompanies,
    selectedFundTickers,
    uniqTickersCount,
    sortedTickers,
    selectedFunds,
  } = props;
  const classes = useStyles();

  return (
    <PdfTemplate>
      <div className={classes.root}>
        <div className={classes.main}>
          <div className={classes.titleWrapper}>
            <div className={classes.title}>Flagged Securities</div>
          </div>
          <div className={classes.select}>
            <Compare list={customerFundAnalyses} selectedToCompare={selectedFundIds} multiple />
          </div>
          <p className={classes.caption}>
            {isEmpty(selectedFundTickers) ? (
              'Please, select one or more funds.'
            ) : (
              <>
                {selectedFundTickers} has <strong>{uniqTickersCount}</strong> securities with one or more flags.
              </>
            )}
          </p>
          {uniqTickersCount > 0 && (
            <div className={classes.tabs}>
              <div className={classes.reportTitle}>Securities</div>
              <div className={classes.tableWrapper}>
                <Table
                  flaggedTickers={sortedTickers}
                  selectedFundIds={selectedFundIds}
                  scoredCompanies={scoredCompanies}
                />
              </div>
              <div className={classes.reportTitle}>% Value</div>
              <div className={classes.chartContainer}>
                <Chart selectedFunds={selectedFunds} flaggedTickers={sortedTickers} isShowValue />
              </div>
            </div>
          )}
        </div>
      </div>
    </PdfTemplate>
  );
};

IssuesDefinedPDF.propTypes = {
  customerFundAnalyses: PropTypes.arrayOf(CustomerFundAnalysisPresenter.shape()).isRequired,
  selectedFundTickers: PropTypes.string,
  selectedFunds: PropTypes.arrayOf(CustomerFundAnalysisPresenter.shape()).isRequired,
  selectedFundIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  scoredCompanies: PropTypes.arrayOf(CompanyPresenter.shape()).isRequired,
  uniqTickersCount: PropTypes.number,
  sortedTickers: PropTypes.arrayOf(CustomerFundAnalysisPresenter.shape()).isRequired,
};

export default IssuesDefinedPDF;
