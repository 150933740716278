export default {
  MuiAccordion: {
    root: {
      boxShadow: 'none',
      borderRadius: 0,
      backgroundColor: 'transparent',
      '&:before': {
        display: 'none',
      },
      '&.Mui-expanded': {
        marginTop: 0,
        marginBottom: 24,
      },
    },
  },
};
