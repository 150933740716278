import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    height: 50,
    padding: '15px 29px 8px',
    display: 'flex',
    backgroundColor: '#4daf51',
    boxShadow: '-6px 2px 10px 2px rgba(0,0,0,0.10)',
    cursor: 'pointer',
    borderRadius: 'inherit',
  },
  title: {
    color: '#fff',
    fontFamily: theme.typography.font.ttHovesPro,
    fontWeight: 600,
    fontSize: 13,
    letterSpacing: 0.3,
    lineHeight: '16px',
  },
  icon: {
    width: 14,
    height: 12,
    marginRight: 15,
    '& svg': {
      fill: '#fff',
    },
  },
}));

export default useStyles;
