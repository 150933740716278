import React from 'react';
import PropTypes from 'prop-types';

import Checkbox from '@material-ui/core/Checkbox';

import checkBox from 'assets/tableCheckBox.svg';
import checkBoxChecked from 'assets/tableCheckBoxChecked.svg';

import useStyles from './useStyles';

const FundCheckbox = props => {
  const { id, onChange, disabled, checked } = props;
  const classes = useStyles();

  return (
    <Checkbox
      classes={{
        root: classes.checkbox,
      }}
      checked={checked}
      disabled={disabled}
      onChange={() => onChange(id)}
      color="default"
      icon={<img src={checkBox} alt="checkBox" />}
      checkedIcon={<img src={checkBoxChecked} alt="checked" />}
      value={id}
      inputProps={{ 'aria-labelledby': id }}
    />
  );
};

FundCheckbox.propTypes = {
  id: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
};

FundCheckbox.defaultProps = {
  disabled: false,
  checked: false,
};

export default FundCheckbox;
