import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const CompaniesIcon = props => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M58 30c0 15.464-12.536 28-28 28S2 45.464 2 30 14.536 2 30 2s28 12.536 28 28zm2 0c0 16.569-13.431 30-30 30C13.431 60 0 46.569 0 30 0 13.431 13.431 0 30 0c16.569 0 30 13.431 30 30zM30.466 16.97c1.134 0 2.054-.948 2.054-2.118s-.92-2.119-2.054-2.119-2.053.949-2.053 2.119c0 1.17.92 2.118 2.053 2.118zm3.734-2.118a3.876 3.876 0 01-1.805 3.298c2.09.739 3.76 2.417 4.544 4.545a.972.972 0 01-.541 1.242.925.925 0 01-1.204-.558c-.802-2.175-2.828-3.713-5.194-3.713s-4.393 1.538-5.194 3.713a.925.925 0 01-1.204.558.972.972 0 01-.541-1.242c.863-2.344 2.801-4.143 5.193-4.74a3.887 3.887 0 01-1.52-3.103c0-2.127 1.67-3.852 3.732-3.852 2.062 0 3.734 1.725 3.734 3.852zm11.614 14c0 1.17-.962 2.118-2.148 2.118-1.186 0-2.147-.948-2.147-2.118s.961-2.119 2.147-2.119c1.187 0 2.148.949 2.148 2.119zm-.13 3.298a3.84 3.84 0 001.887-3.298c0-2.128-1.748-3.852-3.905-3.852-2.156 0-3.904 1.724-3.904 3.852a3.83 3.83 0 001.59 3.103 7.772 7.772 0 00-3.271 1.677l-1.47-7.024c-.195-.935-1.053-1.608-2.05-1.608h-9.122c-.997 0-1.855.673-2.05 1.608l-1.577 7.535a7.684 7.684 0 00-3.093-1.992 3.847 3.847 0 001.867-3.3A3.857 3.857 0 0016.724 25a3.857 3.857 0 00-3.862 3.852c0 1.273.619 2.401 1.573 3.103a7.706 7.706 0 00-5.372 4.74.966.966 0 001.805.684 5.755 5.755 0 015.373-3.712c1.598 0 3.044.653 4.088 1.713l.926 1.262.027.035-1.24 5.93c-.26 1.238.73 2.393 2.05 2.393h15.816c1.32 0 2.31-1.155 2.05-2.392l-1.405-6.72a.974.974 0 00.173-.16 5.815 5.815 0 014.452-2.062 5.817 5.817 0 015.433 3.713.98.98 0 001.26.558.959.959 0 00.565-1.242 7.761 7.761 0 00-4.752-4.545zm-28.96-1.18a2.121 2.121 0 002.124-2.118c0-1.17-.951-2.119-2.124-2.119a2.121 2.121 0 00-2.124 2.119c0 1.17.95 2.118 2.124 2.118zM25.439 27l-3.346 16h15.814l-3.346-16h-9.122zM27 34.196a1 1 0 00-.98.804l-1 4.804A1 1 0 0026 41h8a1 1 0 00.98-1.196l-1-4.804a1 1 0 00-.98-.804h-6zM27.22 39l.6-3h4.36l.6 3h-5.56z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default CompaniesIcon;
