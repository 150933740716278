import React from 'react';
import { isEmpty } from 'ramda';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Link as ReactRouterLink } from 'react-router-dom';

import useStyles from './useStyles';

const Link = props => {
  const { children, className, variant, external, disabled, ...rest } = props;
  const classes = useStyles();

  const rootClassNames = clsx(classes.root, className, disabled && classes.disabled, {
    [classes[variant]]: !isEmpty(variant),
  });

  if (external) {
    return (
      <a {...rest} className={rootClassNames}>
        {children}
      </a>
    );
  }

  return (
    <ReactRouterLink {...rest} className={rootClassNames}>
      {children}
    </ReactRouterLink>
  );
};

Link.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  variant: PropTypes.string,
  external: PropTypes.bool,
  disabled: PropTypes.bool,
};

Link.defaultProps = {
  external: false,
  className: null,
  variant: '',
  disabled: false,
};

export default Link;
