import Routes from 'routes/ApiRoutes';
import FetchHelpers from 'utils/FetchHelpers';

export default {
  create(params) {
    const path = Routes.apiV1AdvisorHouseholdsPath();

    return FetchHelpers.post(path, params);
  },
  index(householdId) {
    const path = Routes.apiV1AdvisorHouseholdPath(householdId);

    return FetchHelpers.get(path);
  },
  update(householdId, params) {
    const path = Routes.apiV1AdvisorHouseholdPath(householdId);

    return FetchHelpers.put(path, params);
  },
  makeCurrent(id, params) {
    const path = Routes.makeCurrentApiV1AdvisorHouseholdPath(id);

    return FetchHelpers.put(path, params);
  },
};
