import TTHovesProDemiBoldTtf from './fonts/TT-Hoves-Pro-DemiBold.ttf';
import TTHovesProDemiBoldWoff from './fonts/TT-Hoves-Pro-DemiBold.woff';
import TTHovesProDemiBoldWoff2 from './fonts/TT-Hoves-Pro-DemiBold.woff2';

import TTHovesProLightTtf from './fonts/TT-Hoves-Pro-Light.ttf';
import TTHovesProLightWoff from './fonts/TT-Hoves-Pro-Light.woff';
import TTHovesProLightWoff2 from './fonts/TT-Hoves-Pro-Light.woff2';

import TTHovesProMediumTtf from './fonts/TT-Hoves-Pro-Medium.ttf';
import TTHovesProMediumWoff from './fonts/TT-Hoves-Pro-Medium.woff';
import TTHovesProMediumWoff2 from './fonts/TT-Hoves-Pro-Medium.woff2';

import TTHovesProRegularTtf from './fonts/TT-Hoves-Pro-Regular.ttf';
import TTHovesProRegularWoff from './fonts/TT-Hoves-Pro-Regular.woff';
import TTHovesProRegularWoff2 from './fonts/TT-Hoves-Pro-Regular.woff2';

export const ttHovesProDemiBold = {
  fontFamily: 'TTHovesPro',
  fontStyle: 'normal',
  fontWeight: 600,
  src: `
    local('TT Hoves Pro DemiBold'),
    url(${TTHovesProDemiBoldWoff}) format('woff'),
    url(${TTHovesProDemiBoldWoff2}) format('woff2'),
    url(${TTHovesProDemiBoldTtf}) format('truetype')
  `,
};

export const ttHovesProMedium = {
  fontFamily: 'TTHovesPro',
  fontStyle: 'normal',
  fontWeight: 500,
  src: `
    local('TTHovesPro-Md'),
    url(${TTHovesProMediumWoff}) format('woff'),
    url(${TTHovesProMediumWoff2}) format('woff2'),
    url(${TTHovesProMediumTtf}) format('truetype')
  `,
};

export const ttHovesProRegular = {
  fontFamily: 'TTHovesPro',
  fontStyle: 'normal',
  fontWeight: 400,
  src: `
    url(${TTHovesProRegularWoff}) format('woff'),
    url(${TTHovesProRegularWoff2}) format('woff2'),
    url(${TTHovesProRegularTtf}) format('truetype')
  `,
};

export const ttHovesProLight = {
  fontFamily: 'TTHovesPro',
  fontStyle: 'normal',
  fontWeight: 300,
  src: `
    local('TTHovesPro-Lt'),
    url(${TTHovesProLightWoff}) format('woff'),
    url(${TTHovesProLightWoff2}) format('woff2'),
    url(${TTHovesProLightTtf}) format('truetype')
  `,
};
