import React from 'react';
import {
  TextField,
  Edit,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  NumberInput,
  TextInput,
  DateInput,
} from 'react-admin';

import SelectAssetClass from './components/SelectAssetClass';

const InstitutionFundEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextField label="Fund ID" source="fund_id" />
      <TextField label="Fund Name" source="fund.name" />
      <NumberInput source="amount" />
      <ReferenceInput label="Institution Name" source="institution_id" reference="institutions" perPage={200}>
        <SelectInput optionText="name" />
      </ReferenceInput>
      <SelectAssetClass source="asset_class_id" />
      <NumberInput source="first_year" />
      <NumberInput source="third_year" />
      <NumberInput source="fifth_year" />
      <NumberInput source="standard_deviation" />
      <NumberInput source="cash" />
      <NumberInput source="bond" />
      <NumberInput source="stock" />
      <NumberInput source="alts" />
      <NumberInput source="cmdty" />
      <TextInput source="link_url" type="text" />
      <SelectInput
        label="Kind"
        source="kind"
        choices={[
          { id: 'model', name: 'model' },
          { id: 'fund', name: 'fund' },
        ]}
        alwaysOn
      />
      <DateInput label="Last update at" source="last_updated_at" />
    </SimpleForm>
  </Edit>
);

export default InstitutionFundEdit;
