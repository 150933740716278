import Routes from 'routes/ApiRoutes';
import FetchHelpers from 'utils/FetchHelpers';

export default {
  index(portfolioAnalysisId, params) {
    const path = Routes.apiV1PortfolioAnalysisCustomerFundAnalysesPath(portfolioAnalysisId);
    return FetchHelpers.get(path, params);
  },
  metrics(portfolioAnalysisId, params) {
    const path = Routes.metricsApiV1PortfolioAnalysisCustomerFundAnalysesPath(portfolioAnalysisId);
    return FetchHelpers.get(path, params);
  },
  mixedAnalysis(portfolioAnalysisId, params) {
    const path = Routes.apiV1PortfolioAnalysisMixedAnalysisPath(portfolioAnalysisId);
    return FetchHelpers.get(path, params);
  },
  insights(portfolioAnalysisId, params) {
    const path = Routes.insightsApiV1PortfolioAnalysisCustomerFundAnalysesPath(portfolioAnalysisId);
    return FetchHelpers.get(path, params);
  },
  flaggedTickers(portfolioAnalysisId, params) {
    const path = Routes.flaggedTickersApiV1PortfolioAnalysisCustomerFundAnalysesPath(portfolioAnalysisId);
    return FetchHelpers.get(path, params);
  },
  optionsList(portfolioAnalysisId, params) {
    const path = Routes.optionsListApiV1PortfolioAnalysisCustomerFundAnalysesPath(portfolioAnalysisId);
    return FetchHelpers.get(path, params);
  },
};
