import React, { useEffect } from 'react';

import UserPresenter from 'presenters/UserPresenter';

import { useApp } from 'hooks';

import AdvisorWelcome from './containers/AdvisorWelcome';
import CustomerWelcome from './containers/CustomerWelcome';

const Welcome = props => {
  const { currentUser } = props;

  const { changeShouldRedirectOnSelectedAccountChange } = useApp();

  useEffect(() => {
    changeShouldRedirectOnSelectedAccountChange(false);
  }, []);

  if (UserPresenter.isCustomer(currentUser)) {
    return <CustomerWelcome currentUser={currentUser} />;
  }

  if (UserPresenter.isAdvisor(currentUser)) {
    return <AdvisorWelcome currentUser={currentUser} />;
  }

  return null;
};

Welcome.propTypes = {
  currentUser: UserPresenter.shape().isRequired,
};

export default Welcome;
