/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import CustomerPortfolioAnalysesRepository from 'repositories/customer/CustomerPortfolioAnalysesRepository';
import AdvisorCustomerPortfolioAnalysesRepository from 'repositories/advisor/CustomerPortfolioAnalysesRepository';
import CustomerAccountsRepository from 'repositories/advisor/CustomerAccountsRepository';
import CustomerLensesRepository from 'repositories/advisor/household/CustomerLensesRepository';
import CustomerFundAnalysesRepository from 'repositories/portfolioAnalysis/CustomerFundAnalysesRepository';
import CompaniesRepository from 'repositories/portfolioAnalysis/CompaniesRepository';
import CustomerRecommendationsRepository from 'repositories/customer/RecommendationsRepository';
import AdvisorRecommendationsRepository from 'repositories/advisor/RecommendationsRepository';
import { FETCH_STATUSES } from 'presenters/FetchStatusPresenter';

const initialState = {
  fundScores: {
    status: FETCH_STATUSES.idle,
    data: [],
  },
  portfolioAnalysis: {
    status: FETCH_STATUSES.idle,
    data: {},
  },
  currentReport: {
    status: FETCH_STATUSES.idle,
    data: {},
  },
  customerFundAnalyses: {
    status: FETCH_STATUSES.idle,
    data: [],
  },
  customerFundAnalysesOptions: {
    status: FETCH_STATUSES.idle,
    data: [],
  },
  flaggedTickers: {
    status: FETCH_STATUSES.idle,
    data: [],
  },
  customerFundsReport: {
    status: FETCH_STATUSES.idle,
    data: {},
  },
  customerFundsReportMetrics: {
    status: FETCH_STATUSES.idle,
    error: null,
    data: {},
  },
  mixedPortfolioAnalysis: {
    status: FETCH_STATUSES.idle,
    error: null,
    data: null,
  },
  scoredCompanies: {
    status: FETCH_STATUSES.idle,
    data: [],
  },
  scoredCompany: {
    status: FETCH_STATUSES.idle,
    data: {},
  },
  scoredCompaniesOptions: {
    status: FETCH_STATUSES.idle,
    data: [],
  },
  insightsScoredCompanies: {
    status: FETCH_STATUSES.idle,
    data: [],
  },
  insightsScoredCompaniesOptions: {
    status: FETCH_STATUSES.idle,
    data: [],
  },
};

const reflectionReportSlice = createSlice({
  name: 'customerLensReport',
  initialState,
  reducers: {
    loadFlaggedTickersStart(state) {
      state.flaggedTickers.status = FETCH_STATUSES.loading;
    },
    loadFlaggedTickersSuccess(state, { payload }) {
      state.flaggedTickers.status = FETCH_STATUSES.success;
      state.flaggedTickers.data = payload.flaggedTickers;
    },
    loadFlaggedTickersFail(state) {
      state.flaggedTickers.status = FETCH_STATUSES.failure;
    },
    resetFlaggedTickers(state) {
      state.flaggedTickers.status = FETCH_STATUSES.idle;
      state.flaggedTickers.data = [];
    },
    loadFundScoresStart(state) {
      state.fundScores.status = FETCH_STATUSES.loading;
    },
    loadFundScoresSuccess(state, { payload }) {
      state.fundScores.status = FETCH_STATUSES.success;
      state.fundScores.data = payload;
    },
    loadFundScoresFail(state) {
      state.fundScores.status = FETCH_STATUSES.failure;
    },
    resetFundScores(state) {
      state.fundScores.status = FETCH_STATUSES.idle;
      state.fundScores.data = [];
    },
    loadPortfolioAnalysisStart(state) {
      state.portfolioAnalysis.status = FETCH_STATUSES.loading;
    },
    loadPortfolioAnalysisSuccess(state, { payload }) {
      state.portfolioAnalysis.status = FETCH_STATUSES.success;
      state.portfolioAnalysis.data = payload.customerPortfolioAnalysis;
    },
    loadPortfolioAnalysisFail(state) {
      state.portfolioAnalysis.status = FETCH_STATUSES.failure;
    },
    resetPortfolioAnalysis(state) {
      state.portfolioAnalysis.status = FETCH_STATUSES.idle;
      state.portfolioAnalysis.data = {};
    },
    loadCustomerFundAnalysesStart(state) {
      state.customerFundAnalyses.status = FETCH_STATUSES.loading;
    },
    loadCustomerFundAnalysesSuccess(state, { payload }) {
      state.customerFundAnalyses.status = FETCH_STATUSES.success;
      state.customerFundAnalyses.data = payload.customerFundAnalyses;
    },
    loadCustomerFundAnalysesFail(state) {
      state.customerFundAnalyses.status = FETCH_STATUSES.failure;
    },
    resetCustomerFundAnalyses(state) {
      state.customerFundAnalyses.status = FETCH_STATUSES.idle;
      state.customerFundAnalyses.data = [];
    },
    loadCustomerFundAnalysesOptionsStart(state) {
      state.customerFundAnalysesOptions.status = FETCH_STATUSES.loading;
    },
    loadCustomerFundAnalysesOptionsSuccess(state, { payload }) {
      state.customerFundAnalysesOptions.status = FETCH_STATUSES.success;
      state.customerFundAnalysesOptions.data = payload.customerFundAnalyses;
    },
    loadCustomerFundAnalysesOptionsFail(state) {
      state.customerFundAnalysesOptions.status = FETCH_STATUSES.failure;
    },
    resetCustomerFundAnalysesOptions(state) {
      state.customerFundAnalysesOptions.status = FETCH_STATUSES.idle;
      state.customerFundAnalysesOptions.data = [];
    },
    setCurrentReport(state, { payload }) {
      state.currentReport.status = FETCH_STATUSES.success;
      state.currentReport.data = payload;
    },
    resetCurrentReport(state) {
      state.currentReport.status = FETCH_STATUSES.idle;
      state.currentReport.data = {};
    },
    loadCustomerFundAnalysesReportStart(state) {
      state.customerFundsReport.status = FETCH_STATUSES.loading;
    },
    loadCustomerFundAnalysesReportSuccess(state, { payload }) {
      state.customerFundsReport.status = FETCH_STATUSES.success;
      state.customerFundsReport.data = payload;
    },
    loadCustomerFundAnalysesReportFail(state) {
      state.customerFundsReport.status = FETCH_STATUSES.failure;
    },
    resetCustomerFundAnalysesReport(state) {
      state.customerFundsReport.status = FETCH_STATUSES.idle;
      state.customerFundsReport.data = {};
    },
    loadCustomerFundAnalysesReportMetricsStart(state) {
      state.customerFundsReportMetrics.status = FETCH_STATUSES.loading;
    },
    loadCustomerFundAnalysesReportMetricsSuccess(state, { payload }) {
      state.customerFundsReportMetrics.status = FETCH_STATUSES.success;
      state.customerFundsReportMetrics.data = payload;
    },
    loadCustomerFundAnalysesReportMetricsFail(state) {
      state.customerFundsReportMetrics.status = FETCH_STATUSES.failure;
    },
    resetCustomerFundAnalysesReportMetrics(state) {
      state.customerFundsReportMetrics.status = FETCH_STATUSES.idle;
      state.customerFundsReportMetrics.data = {};
    },
    loadMixedPortfolioAnalysisStart(state) {
      state.mixedPortfolioAnalysis.status = FETCH_STATUSES.loading;
    },
    loadMixedPortfolioAnalysisSuccess(state, { payload }) {
      state.mixedPortfolioAnalysis.status = FETCH_STATUSES.success;
      state.mixedPortfolioAnalysis.data = payload;
    },
    loadMixedPortfolioAnalysisFail(state) {
      state.mixedPortfolioAnalysis.status = FETCH_STATUSES.failure;
    },
    loadScoredCompaniesStart(state) {
      state.scoredCompanies.status = FETCH_STATUSES.loading;
    },
    loadScoredCompaniesSuccess(state, { payload }) {
      state.scoredCompanies.status = FETCH_STATUSES.success;
      state.scoredCompanies.data = payload.companies;
    },
    loadScoredCompaniesFail(state) {
      state.scoredCompanies.status = FETCH_STATUSES.failure;
    },
    resetScoredCompanies(state) {
      state.scoredCompanies.status = FETCH_STATUSES.idle;
      state.scoredCompanies.data = [];
    },
    loadScoredCompanyStart(state) {
      state.scoredCompany.status = FETCH_STATUSES.loading;
    },
    loadScoredCompanySuccess(state, { payload }) {
      state.scoredCompany.status = FETCH_STATUSES.success;
      state.scoredCompany.data = payload.company;
    },
    loadScoredCompanyFail(state) {
      state.scoredCompany.status = FETCH_STATUSES.failure;
    },
    resetScoredCompany(state) {
      state.scoredCompany.status = FETCH_STATUSES.idle;
      state.scoredCompany.data = {};
    },
    loadScoredCompaniesOptionsStart(state) {
      state.scoredCompaniesOptions.status = FETCH_STATUSES.loading;
    },
    loadScoredCompaniesOptionsSuccess(state, { payload }) {
      state.scoredCompaniesOptions.status = FETCH_STATUSES.success;
      state.scoredCompaniesOptions.data = payload.companies;
    },
    loadScoredCompaniesOptionsFail(state) {
      state.scoredCompaniesOptions.status = FETCH_STATUSES.failure;
    },
    resetScoredCompaniesOptions(state) {
      state.scoredCompaniesOptions.status = FETCH_STATUSES.idle;
      state.scoredCompaniesOptions.data = [];
    },
    loadInsightsScoredCompaniesStart(state) {
      state.insightsScoredCompanies.status = FETCH_STATUSES.loading;
    },
    loadInsightsScoredCompaniesSuccess(state, { payload }) {
      state.insightsScoredCompanies.status = FETCH_STATUSES.success;
      state.insightsScoredCompanies.data = payload.companies;
    },
    loadInsightsScoredCompaniesFail(state) {
      state.insightsScoredCompanies.status = FETCH_STATUSES.failure;
    },
    resetInsightsScoredCompanies(state) {
      state.insightsScoredCompanies.status = FETCH_STATUSES.idle;
      state.insightsScoredCompanies.data = [];
    },
    loadInsightsScoredCompaniesOptionsStart(state) {
      state.insightsScoredCompaniesOptions.status = FETCH_STATUSES.loading;
    },
    loadInsightsScoredCompaniesOptionsSuccess(state, { payload }) {
      state.insightsScoredCompaniesOptions.status = FETCH_STATUSES.success;
      state.insightsScoredCompaniesOptions.data = payload.companies;
    },
    loadInsightsScoredCompaniesOptionsFail(state) {
      state.insightsScoredCompaniesOptions.status = FETCH_STATUSES.failure;
    },
    resetInsightsScoredCompaniesOptions(state) {
      state.insightsScoredCompaniesOptions.status = FETCH_STATUSES.idle;
      state.insightsScoredCompaniesOptions.data = [];
    },
  },
});

export const {
  loadFlaggedTickersStart,
  loadFlaggedTickersSuccess,
  loadFlaggedTickersFail,
  loadFundScoresStart,
  loadFundScoresSuccess,
  loadFundScoresFail,
  loadPortfolioAnalysisStart,
  loadPortfolioAnalysisSuccess,
  loadPortfolioAnalysisFail,
  loadCustomerFundAnalysesStart,
  loadCustomerFundAnalysesSuccess,
  loadCustomerFundAnalysesFail,
  loadCustomerFundAnalysesOptionsStart,
  loadCustomerFundAnalysesOptionsSuccess,
  loadCustomerFundAnalysesOptionsFail,
  setCurrentReport,
  loadCustomerFundAnalysesReportStart,
  loadCustomerFundAnalysesReportSuccess,
  loadCustomerFundAnalysesReportFail,
  loadCustomerFundAnalysesReportMetricsStart,
  loadCustomerFundAnalysesReportMetricsSuccess,
  loadCustomerFundAnalysesReportMetricsFail,
  resetCustomerFundAnalysesReportMetrics,
  loadMixedPortfolioAnalysisStart,
  loadMixedPortfolioAnalysisSuccess,
  loadMixedPortfolioAnalysisFail,
  loadScoredCompaniesStart,
  loadScoredCompaniesSuccess,
  loadScoredCompaniesFail,
  loadScoredCompanyStart,
  loadScoredCompanySuccess,
  loadScoredCompanyFail,
  loadScoredCompaniesOptionsStart,
  loadScoredCompaniesOptionsSuccess,
  loadScoredCompaniesOptionsFail,
  loadInsightsScoredCompaniesStart,
  loadInsightsScoredCompaniesSuccess,
  loadInsightsScoredCompaniesFail,
  loadInsightsScoredCompaniesOptionsStart,
  loadInsightsScoredCompaniesOptionsSuccess,
  loadInsightsScoredCompaniesOptionsFail,
  resetFlaggedTickers,
  resetFundScores,
  resetPortfolioAnalysis,
  resetCustomerFundAnalyses,
  resetCustomerFundAnalysesOptions,
  resetCurrentReport,
  resetCustomerFundAnalysesReport,
  resetScoredCompanies,
  resetScoredCompany,
  resetScoredCompaniesOptions,
  resetInsightsScoredCompanies,
  resetInsightsScoredCompaniesOptions,
} = reflectionReportSlice.actions;

export default reflectionReportSlice.reducer;

export const useReflectionReportActions = () => {
  const dispatch = useDispatch();

  const setCurrentReportAction = report => {
    dispatch(setCurrentReport(report));
  };

  const loadFlaggedTickers = portfolioAnalysisId => {
    dispatch(loadFlaggedTickersStart());

    return CustomerFundAnalysesRepository.flaggedTickers(portfolioAnalysisId)
      .then(({ data }) => {
        dispatch(loadFlaggedTickersSuccess(data));

        return Promise.resolve(data);
      })
      .catch(() => dispatch(loadFlaggedTickersFail()));
  };

  const loadInsights = portfolioAnalysisId => {
    dispatch(loadFundScoresStart());

    return CustomerFundAnalysesRepository.insights(portfolioAnalysisId)
      .then(({ data }) => {
        dispatch(loadFundScoresSuccess(data));

        return Promise.resolve(data);
      })
      .catch(() => dispatch(loadFundScoresFail()));
  };

  const loadPortfolioAnalysis = async analysisId => {
    dispatch(loadPortfolioAnalysisStart());

    const {
      data: { customerPortfolioAnalysis },
    } = await CustomerPortfolioAnalysesRepository.show(analysisId, { include: [''] });
    return customerPortfolioAnalysis;
  };

  const loadFullPortfolioAnalysis = async analysisId => {
    dispatch(loadPortfolioAnalysisStart());

    try {
      const { data } = await CustomerPortfolioAnalysesRepository.show(analysisId, {
        include: [
          'customer_lens.final_lens',
          'customer_lens.customer_category_values.category',
          'customer_lens.customer_pillar_values.pillar',
          'customer_lens.customer_lens_flags.flag',
          'customer_lens.selected_lenses.lens',
          'portfolio_total_analysis',
          'portfolio_pillar_analyses.pillar',
          'portfolio_category_analyses.category',
          'portfolio_category_analyses.category.pillar',
        ],
      });
      dispatch(loadPortfolioAnalysisSuccess(data));
      return data;
    } catch (error) {
      dispatch(loadPortfolioAnalysisFail());
      return error;
    }
  };

  const loadCustomerFundAnalyses = async portfolioAnalysisId => {
    dispatch(loadCustomerFundAnalysesStart());

    try {
      const { data } = await CustomerFundAnalysesRepository.index(portfolioAnalysisId);

      dispatch(loadCustomerFundAnalysesSuccess(data));
      return data;
    } catch (error) {
      dispatch(loadCustomerFundAnalysesFail());
      return error;
    }
  };

  const loadCustomerFundAnalysesOptions = async portfolioAnalysisId => {
    dispatch(loadCustomerFundAnalysesOptionsStart());

    try {
      const { data } = await CustomerFundAnalysesRepository.optionsList(portfolioAnalysisId);

      dispatch(loadCustomerFundAnalysesOptionsSuccess(data));
      return data;
    } catch (error) {
      dispatch(loadCustomerFundAnalysesOptionsFail());
      return error;
    }
  };

  const loadReportMetrics = (portfolioAnalysisId, customerFundAnalysisIds) => {
    dispatch(loadCustomerFundAnalysesReportMetricsStart());

    return CustomerFundAnalysesRepository.metrics(portfolioAnalysisId, {
      q: { id_in: customerFundAnalysisIds },
    })
      .then(({ data }) => {
        dispatch(loadCustomerFundAnalysesReportMetricsSuccess(data));
        return Promise.resolve(data);
      })
      .catch(e => {
        dispatch(loadCustomerFundAnalysesReportMetricsFail());
        return Promise.reject(e);
      });
  };

  const loadMixedReport = async (portfolioAnalysisId, customerFundAnalysisIds) => {
    dispatch(loadMixedPortfolioAnalysisStart());

    try {
      const mixedReport = await CustomerFundAnalysesRepository.mixedAnalysis(portfolioAnalysisId, {
        fundAnalysesIds: customerFundAnalysisIds,
      });
      const { data } = mixedReport;
      dispatch(loadMixedPortfolioAnalysisSuccess(data));
      return data;
    } catch (error) {
      dispatch(loadMixedPortfolioAnalysisFail());
      return error;
    }
  };

  const loadMetrics = (portfolioAnalysisId, customerFundAnalysisIds) => {
    dispatch(loadCustomerFundAnalysesReportStart());

    return CustomerFundAnalysesRepository.metrics(portfolioAnalysisId, {
      q: { id_in: customerFundAnalysisIds },
    })
      .then(({ data }) => {
        dispatch(loadCustomerFundAnalysesReportSuccess(data));
        return Promise.resolve(data);
      })
      .catch(e => {
        dispatch(loadCustomerFundAnalysesReportFail());
        return Promise.reject(e);
      });
  };

  const loadScoredCompanies = (analysisId, customerFundIds) => {
    dispatch(loadScoredCompaniesStart());

    return CompaniesRepository.index(analysisId, {
      customerFundAnalysisQ: { idIn: customerFundIds },
      companyQ: { s: 'total_weight desc' },
      perPage: 20,
    })
      .then(({ data }) => {
        dispatch(loadScoredCompaniesSuccess(data));
        return Promise.resolve(data);
      })
      .catch(e => {
        dispatch(loadScoredCompaniesFail());

        return Promise.reject(e);
      });
  };

  const loadScoredCompany = (analysisId, companyId, customerFundIds) => {
    dispatch(loadScoredCompanyStart());

    return CompaniesRepository.show(analysisId, companyId, {
      customerFundAnalysisQ: { idIn: customerFundIds },
      companyQ: { s: 'total_weight desc' },
    })
      .then(({ data }) => {
        dispatch(loadScoredCompanySuccess(data));
        return Promise.resolve(data);
      })
      .catch(e => {
        dispatch(loadScoredCompanyFail());

        return Promise.reject(e);
      });
  };

  const loadScoredCompaniesOptions = (analysisId, customerFundIds) => {
    dispatch(loadScoredCompaniesOptionsStart());

    return CompaniesRepository.optionsList(analysisId, {
      customerFundAnalysisQ: { idIn: customerFundIds },
      companyQ: { s: 'total_weight desc' },
    })
      .then(({ data }) => {
        dispatch(loadScoredCompaniesOptionsSuccess(data));
        return Promise.resolve(data);
      })
      .catch(e => {
        dispatch(loadScoredCompaniesOptionsFail());

        return Promise.reject(e);
      });
  };

  const loadInsightsScoredCompanies = (analysisId, customerFundIds) => {
    dispatch(loadInsightsScoredCompaniesStart());

    return CompaniesRepository.index(analysisId, {
      customerFundAnalysisQ: { idIn: customerFundIds },
      companyQ: { s: 'total_weight desc' },
      perPage: 20,
    })
      .then(({ data }) => {
        dispatch(loadInsightsScoredCompaniesSuccess(data));
        return Promise.resolve(data);
      })
      .catch(e => {
        dispatch(loadInsightsScoredCompaniesFail());

        return Promise.reject(e);
      });
  };

  const loadInsightsScoredCompaniesOptions = (analysisId, customerFundIds) => {
    dispatch(loadInsightsScoredCompaniesOptionsStart());

    return CompaniesRepository.optionsList(analysisId, {
      customerFundAnalysisQ: { idIn: customerFundIds },
      companyQ: { s: 'total_weight desc' },
    })
      .then(({ data }) => {
        dispatch(loadInsightsScoredCompaniesOptionsSuccess(data));
        return Promise.resolve(data);
      })
      .catch(e => {
        dispatch(loadInsightsScoredCompaniesOptionsFail());

        return Promise.reject(e);
      });
  };

  const updateAccount = (accountId, params) => CustomerAccountsRepository.update(accountId, params);

  const duplicateCustomerLens = (customerLensId, householdId) =>
    CustomerLensesRepository.duplicate(customerLensId, householdId);

  const createPortfolioAnalysesBatch = async params => {
    const {
      data: { customerPortfolioAnalysesBatch },
    } = await CustomerPortfolioAnalysesRepository.create_batch(params);

    return customerPortfolioAnalysesBatch;
  };

  const createPortfolioAnalysis = async params => {
    const {
      data: { customerPortfolioAnalysis },
    } = await CustomerPortfolioAnalysesRepository.create(params);

    return customerPortfolioAnalysis;
  };

  const createAdvisorPortfolioAnalysesBatch = async params => {
    const {
      data: { customerPortfolioAnalysesBatch },
    } = await AdvisorCustomerPortfolioAnalysesRepository.create_batch(params);

    return customerPortfolioAnalysesBatch;
  };

  const createAdvisorPortfolioAnalysis = async params => {
    const {
      data: { customerPortfolioAnalysis },
    } = await AdvisorCustomerPortfolioAnalysesRepository.create(params);

    return customerPortfolioAnalysis;
  };

  const createCustomerRecommendation = async params => {
    const {
      data: { recommendation },
    } = await CustomerRecommendationsRepository.create(params);

    return recommendation;
  };

  const createAdvisorRecommendation = async params => {
    const {
      data: { recommendation },
    } = await AdvisorRecommendationsRepository.create(params);

    return recommendation;
  };

  const resetToInitialState = () => {
    dispatch(resetFlaggedTickers());
    dispatch(resetFundScores());
    dispatch(resetPortfolioAnalysis());
    dispatch(resetCustomerFundAnalyses());
    dispatch(resetCustomerFundAnalysesOptions());
    dispatch(resetCurrentReport());
    dispatch(resetCustomerFundAnalysesReport());
    dispatch(resetScoredCompanies());
    dispatch(resetScoredCompany());
    dispatch(resetScoredCompaniesOptions());
    dispatch(resetInsightsScoredCompanies());
    dispatch(resetInsightsScoredCompaniesOptions());
  };

  return {
    loadCustomerFundAnalyses,
    loadCustomerFundAnalysesOptions,
    loadPortfolioAnalysis,
    loadFullPortfolioAnalysis,
    loadFlaggedTickers,
    loadInsights,
    setCurrentReport: setCurrentReportAction,
    loadMetrics,
    loadMixedReport,
    loadReportMetrics,
    updateAccount,
    duplicateCustomerLens,
    createPortfolioAnalysis,
    createPortfolioAnalysesBatch,
    createAdvisorPortfolioAnalysis,
    createAdvisorPortfolioAnalysesBatch,
    loadScoredCompanies,
    loadScoredCompany,
    loadScoredCompaniesOptions,
    loadInsightsScoredCompanies,
    loadInsightsScoredCompaniesOptions,
    resetToInitialState,
    createCustomerRecommendation,
    createAdvisorRecommendation,
  };
};
