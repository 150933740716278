import React from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'ramda';

import useStyles from './useStyles';

import Loader from 'components/Loader';

const Ball = props => {
  const { description, value, score, totalScore, isLoading } = props;
  const classes = useStyles();
  const info = isNil(value) ? `of ${totalScore} ${description}` : description;
  const renderValue = () => <div className={classes.percentage}>{value}</div>;
  const renderScore = () => (
    <div className={classes.scores}>
      <span className={classes.score}>{score}</span>
    </div>
  );

  return (
    <div className={classes.root}>
      {isLoading ? (
        <div className={classes.loader}>
          <Loader />
        </div>
      ) : (
        <div className={classes.value}>
          <div className={classes.counter}>
            {isNil(value) ? renderScore() : renderValue()}
            <div className={classes.description}>{info}</div>
          </div>
        </div>
      )}
    </div>
  );
};

Ball.propTypes = {
  description: PropTypes.string.isRequired,
  value: PropTypes.string,
  score: PropTypes.string,
  totalScore: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
};

Ball.defaultProps = {
  value: null,
  score: null,
  totalScore: null,
};

export default Ball;
