import { FetchStatuses } from 'enums/FetchStatuses';

const getFetchStatus = status => {
  const isIdle = status === FetchStatuses.idle;
  const isLoading = status === FetchStatuses.loading;
  const isSucceeded = status === FetchStatuses.success;
  const isFailed = status === FetchStatuses.failure;

  return {
    isIdle,
    isLoading,
    isSucceeded,
    isFailed,
  };
};

export default getFetchStatus;
