import PropTypes from 'prop-types';
import Presenter from 'utils/PropTypesPresenter';

import FundPresenter from 'presenters/FundPresenter';

export default new Presenter(
  {
    id: PropTypes.number,
    fund: FundPresenter.shape(),
  },
  {
    fundId(fund) {
      const fundItem = this.fund(fund);

      return FundPresenter.id(fundItem);
    },
    fundName(fund) {
      const fundItem = this.fund(fund);

      return FundPresenter.name(fundItem);
    },
    fundTicker(fund) {
      const fundItem = this.fund(fund);

      return FundPresenter.ticker(fundItem);
    },
  },
);
