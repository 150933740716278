import { ttHovesProDemiBold, ttHovesProLight, ttHovesProMedium, ttHovesProRegular } from './fonts';

export default theme => ({
  '@global': {
    '@font-face': [ttHovesProDemiBold, ttHovesProMedium, ttHovesProLight, ttHovesProRegular],
    html: {
      WebkitFontSmoothing: 'antialiased',
      MozOsxFontSmoothing: 'grayscale',
      boxSizing: 'border-box',
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.htmlFontSize,
      fontWeight: theme.typography.fontWeightRegular,
      padding: 0,
      margin: 0,
    },
    '*, *::before, *::after': {
      boxSizing: 'inherit',
    },
    body: {
      minHeight: '100vh',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      fontSize: theme.typography.fontSize,
      padding: 0,
      margin: 0,
      backgroundColor: '#fff',
      '@media print': {
        backgroundColor: theme.palette.common.white,
      },
      '& > #reflection_app': {
        display: 'flex',
        minHeight: '100vh',
        flexDirection: 'column',
        backgroundColor: '#fff',
      },
    },
    a: {
      color: 'inherit',
      textDecoration: 'none',
    },
    'input, textarea, button': {
      fontFamily: 'inherit',
      fontSize: 'inherit',
      fontWeight: 'inherit',
    },
  },
});
