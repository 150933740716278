import Routes from 'routes/ApiRoutes';
import FetchHelpers from 'utils/FetchHelpers';

export default {
  create() {
    const path = Routes.createExportAdminApiV1UserExportsPath();

    return FetchHelpers.get(path);
  },
  show() {
    const path = Routes.adminApiV1UserExportsPath();

    return FetchHelpers.get(path);
  },
  download() {
    const path = Routes.downloadAdminApiV1UserExportsPath();

    return FetchHelpers.download(path);
  },
};
