import 'core-js/stable';
import 'regenerator-runtime/runtime';
import { hot } from 'react-hot-loader/root';
import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { Router, matchPath } from 'react-router-dom';
import { compose } from 'redux';
import { CSSTransition } from 'react-transition-group';
import { isEmpty, head, isNil } from 'ramda';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { SnackbarProvider } from 'notistack';

import store from 'store';

import { useApp } from 'hooks';

import Loader from 'components/Loader';

import ThemeProvider from 'layouts/ThemeProvider';

import NotAuthenticatedUserSwitch from 'routeSwitches/NotAuthenticatedUserSwitch';
import CustomerUserSwitch from 'routeSwitches/CustomerUserSwitch';
import AdvisorUserSwitch from 'routeSwitches/AdvisorUserSwitch';

import AppRoutes from 'routes/AppRoutes';
import UserPresenter from 'presenters/UserPresenter';
import AccountPresenter from 'presenters/AccountPresenter';
import history from 'utils/history';
import { getAnalysisId } from 'utils/location';
import 'utils/errorTracking';
import 'utils/feedbackCollector';

const AUTO_HIDE_DURATION = 5000;

const App = () => {
  const [preparingApp, changePreparingAppState] = useState(false);

  const {
    loadConfigurations,
    signIn,
    signOut,
    loadCurrentUser,
    onLoaderExit,
    stopImpersonating,
    updateCustomersList,
    changeSelectedCustomer,
    loadAdvisorCustomerAccounts,
    loadAccounts,
    changeAccounts,
    changeSelectedAccount,
    loadCustomerSavedFunds,
    destroyCustomerSavedFund,
    loadAdvisorSavedFunds,
    destroyAdvisorSavedFund,
    changeShouldRedirectOnSelectedAccountChange,
    makeCurrentAdvisorHousehold,
    currentUser,
    currentUserMeta,
    currentUserStatuses,
    loading,
    loaderCaption,
    customersList,
    selectedCustomer,
    selectedAccount,
    accounts,
    savedFunds,
    shouldRedirectOnSelectedAccountChange,
  } = useApp();

  const handleStopImpersonating = () => {
    stopImpersonating().then(() => {
      window.location.reload(AppRoutes.rootPath());
    });
  };

  const getInitSelectedAccount = (accountsList, customer) => {
    const accountsByCustomer = accountsList.filter(account => UserPresenter.accountIds(customer).includes(account.id));
    const initAccount = head(accountsByCustomer);
    if (!matchPath(window.location.pathname, { path: AppRoutes.portfolioAnalysisPath(':id') })) {
      return initAccount;
    }

    return accountsByCustomer.find(account => AccountPresenter.isReportActive(account, getAnalysisId())) ?? initAccount;
  };

  const getInitSelectedCustomer = (customers, accountsList, householdId) => {
    const initCustomer = customers.find(customer => customer.householdId === householdId);
    if (!matchPath(window.location.pathname, { path: AppRoutes.portfolioAnalysisPath(':id') })) {
      return initCustomer;
    }

    const accountByAnalysis = accountsList.find(account => AccountPresenter.isReportActive(account, getAnalysisId()));
    if (!accountByAnalysis) {
      return initCustomer;
    }

    return (
      customers.find(
        customer =>
          UserPresenter.accountIds(customer).includes(accountByAnalysis.id) && customer.householdId === householdId,
      ) ?? initCustomer
    );
  };

  const prepareApp = async () => {
    changeShouldRedirectOnSelectedAccountChange(false);
    try {
      await loadConfigurations();
      const { user } = await loadCurrentUser();

      if (UserPresenter.isAdvisor(user)) {
        const householdId = UserPresenter.householdId(user);
        const list = await updateCustomersList();
        const customerIds = list.map(customer => customer.id);
        const accountsList = await loadAdvisorCustomerAccounts({ q: { userAccountsUserIdIn: customerIds } });
        const initialSelectedCustomer = getInitSelectedCustomer(list, accountsList, householdId);

        if (initialSelectedCustomer) {
          loadAdvisorSavedFunds(initialSelectedCustomer.id);
        }

        changeSelectedCustomer(initialSelectedCustomer);
        changeAccounts(accountsList);
      } else if (UserPresenter.isCustomer(user)) {
        loadCustomerSavedFunds();
        const accountsList = await loadAccounts();
        changeAccounts(accountsList);
      }
    } finally {
      changePreparingAppState(false);
      changeShouldRedirectOnSelectedAccountChange(true);
    }
  };

  const handleDestroySavedFund = fundId => {
    if (UserPresenter.isAdvisor(currentUser)) {
      destroyAdvisorSavedFund(fundId, selectedCustomer.id);
    }
    if (UserPresenter.isCustomer(currentUser)) {
      destroyCustomerSavedFund(fundId);
    }
  };

  const handleSignIn = async value => {
    changePreparingAppState(true);
    await signIn(value);
    prepareApp();
  };

  const handleSignOut = () => {
    signOut();
  };

  useEffect(() => {
    if (UserPresenter.isAdmin(currentUser)) {
      window.location.href = AppRoutes.adminPath();
    }
  }, [currentUser]);

  useEffect(() => {
    changePreparingAppState(true);
    prepareApp();
  }, []);

  useEffect(() => {
    if (isNil(selectedCustomer)) {
      return;
    }
    const householdId = UserPresenter.householdId(currentUser);
    if (householdId !== selectedCustomer.householdId) {
      makeCurrentAdvisorHousehold(selectedCustomer.householdId, { household: selectedCustomer.household });
      changeShouldRedirectOnSelectedAccountChange(true);
    }

    const initialSelectedAccount = getInitSelectedAccount(accounts, selectedCustomer);
    changeSelectedAccount(initialSelectedAccount);
    loadAdvisorSavedFunds(selectedCustomer.id);
  }, [selectedCustomer]);

  useEffect(() => {
    if (isEmpty(accounts)) {
      return;
    }
    const initialSelectedAccount = UserPresenter.isAdvisor(currentUser)
      ? getInitSelectedAccount(accounts, selectedCustomer)
      : getInitSelectedAccount(accounts, currentUser);
    changeSelectedAccount(initialSelectedAccount);
  }, [accounts]);

  useEffect(() => {
    if (isNil(selectedAccount)) {
      return;
    }

    if (shouldRedirectOnSelectedAccountChange) {
      const analysisId = AccountPresenter.customerPortfolioAnalysisId(selectedAccount);
      const redirectUrl = isNil(analysisId)
        ? AppRoutes.customerLensLensesPath()
        : AppRoutes.portfolioAnalysisPath(analysisId);
      history.push(redirectUrl);
    }
  }, [selectedAccount]);

  const shouldHideRenderSwitch = currentUserStatuses.isLoading || preparingApp;

  const renderSwitch = () => {
    if (shouldHideRenderSwitch) {
      return null;
    }

    if (isEmpty(currentUser)) {
      return <NotAuthenticatedUserSwitch onSignIn={handleSignIn} onPrepareApp={prepareApp} />;
    }

    if (UserPresenter.isAdvisor(currentUser)) {
      return (
        <AdvisorUserSwitch
          savedFunds={savedFunds}
          onDestroySavedFund={handleDestroySavedFund}
          onSignOut={handleSignOut}
          currentUser={currentUser}
          currentUserMeta={currentUserMeta}
          onStopImpersonating={handleStopImpersonating}
          customersList={customersList}
          selectedCustomer={selectedCustomer}
          onChangeSelectedCustomer={changeSelectedCustomer}
        />
      );
    }

    return (
      <CustomerUserSwitch
        savedFunds={savedFunds}
        onDestroySavedFund={handleDestroySavedFund}
        onSignOut={handleSignOut}
        currentUser={currentUser}
        currentUserMeta={currentUserMeta}
        onStopImpersonating={handleStopImpersonating}
      />
    );
  };

  return (
    <ThemeProvider>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <SnackbarProvider
          autoHideDuration={AUTO_HIDE_DURATION}
          anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
          TransitionProps={{ direction: 'up' }}
        >
          <Router history={history}>
            <CSSTransition in={loading} timeout={600} classNames="alert" onExited={() => onLoaderExit()} unmountOnExit>
              <Loader caption={loaderCaption} size="big" />
            </CSSTransition>
            {renderSwitch()}
          </Router>
        </SnackbarProvider>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

const addStore = ProxyComponent => () => (
  <Provider store={store}>
    <ProxyComponent />
  </Provider>
);

export default compose(hot, addStore)(App);
