import PropTypes from 'prop-types';
import { isNil } from 'ramda';

import Presenter from 'utils/PropTypesPresenter';
import UserPresenter from 'presenters/UserPresenter';
import CustomerPortfolioAnalysisPresenter from 'presenters/CustomerPortfolioAnalysisPresenter';

const DIGITS_COUNT = 4;

const SOURCE_TYPES = {
  system: 'system',
  import: 'import',
};

export default new Presenter(
  {
    id: PropTypes.number,
    name: PropTypes.string,
    source: PropTypes.string,
    cachedCustomerPortfolioAnalysisId: PropTypes.number,
    cachedCustomerPortfolioAnalysis: CustomerPortfolioAnalysisPresenter.shape(),
    customerPortfolioAnalysisId: PropTypes.number,
    customerPortfolioAnalysis: CustomerPortfolioAnalysisPresenter.shape(),
    customerPortfolioId: PropTypes.number,
    externalId: PropTypes.string,
  },
  {
    isSystem(account) {
      return this.source(account) === SOURCE_TYPES.system;
    },
    isImported(account) {
      return this.source(account) === SOURCE_TYPES.import;
    },
    nameWithId(account) {
      if (this.isSystem(account)) {
        return this.name(account);
      }

      const idLastDigits = this.externalId(account).slice(-DIGITS_COUNT);
      return `${this.name(account)}-${idLastDigits}`;
    },
    asList(accounts, customer = null) {
      if (isNil(customer)) {
        return accounts.map(account => ({ ...account, key: account.id, value: this.nameWithId(account) }));
      }

      return accounts
        .filter(account => UserPresenter.accountIds(customer).includes(account.id))
        .map(account => ({ ...account, key: account.id, value: this.nameWithId(account) }));
    },
    isReportActive(account, portfolioAnalysisId) {
      return this.customerPortfolioAnalysisId(account) === portfolioAnalysisId;
    },
  },
);
