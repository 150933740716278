import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  text: {
    fontFamily: theme.typography.font.ttHovesPro,
    fontWeight: 300,
    fontSize: 13,
    color: '#515151',
    letterSpacing: '0.01em',
  },
}));

export default useStyles;
