import PropTypes from 'prop-types';

import Presenter from 'utils/PropTypesPresenter';

const STATES = {
  inProgress: 'in_progress',
  failed: 'failed',
  finished: 'finished',
  validated: 'validated',
};

export default new Presenter(
  {
    state: PropTypes.string,
    message: PropTypes.string,
  },
  {
    isInProgress(importRequest) {
      return this.state(importRequest) === STATES.inProgress;
    },
    isValidated(importRequest) {
      return this.state(importRequest) === STATES.validated;
    },
    isCrashed(importRequest) {
      return this.state(importRequest) === STATES.failed;
    },
    isFinished(importRequest) {
      return this.state(importRequest) === STATES.finished;
    },
  },
);
