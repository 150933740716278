import Routes from 'routes/ApiRoutes';
import FetchHelpers from 'utils/FetchHelpers';

export default {
  show(params) {
    const path = Routes.adminApiV1KpiPath(params.format);

    return FetchHelpers.get(path, params);
  },
  export(params) {
    const path = Routes.exportAdminApiV1KpiPath(params.format);

    return FetchHelpers.download(path, params);
  },
};
