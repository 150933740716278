export const Status = {
  correct: 'success',
  conflict: 'fail',
  merged: 'merged',
};

export const StatusForCreated = {
  added: 'added',
  matched: 'matched',
  applied: 'applied',
};

export const StatusAction = {
  added: 'add',
  matched: 'match',
  applied: 'apply',
};

export const State = {
  edited: 'edit',
  added: 'add',
};
