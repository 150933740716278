/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import CustomerFundAnalysesRepository from 'repositories/customerPortfolioAnalysesBatches/CustomerFundAnalysesRepository';
import CompaniesRepository from 'repositories/customerPortfolioAnalysesBatches/CompaniesRepository';
import { FETCH_STATUSES } from 'presenters/FetchStatusPresenter';

const initialState = {
  fundScores: {
    status: FETCH_STATUSES.idle,
    error: null,
    data: [],
  },
  portfolioAnalysis: {
    status: FETCH_STATUSES.idle,
    error: null,
    data: {},
  },
  customerFundAnalyses: {
    status: FETCH_STATUSES.idle,
    error: null,
    data: {},
  },
  customerFundAnalysesOptions: {
    status: FETCH_STATUSES.idle,
    data: [],
  },
  flaggedTickers: {
    status: FETCH_STATUSES.idle,
    error: null,
    data: [],
  },
  customerFundAnalysesMetrics: {
    status: FETCH_STATUSES.idle,
    error: null,
    data: {},
  },
  customerFundsReportMetrics: {
    status: FETCH_STATUSES.idle,
    error: null,
    data: {},
  },
  mixedPortfolioAnalysis: {
    status: FETCH_STATUSES.idle,
    error: null,
    data: {},
  },
  scoredCompanies: {
    status: FETCH_STATUSES.idle,
    error: null,
    data: [],
  },
  scoredCompany: {
    status: FETCH_STATUSES.idle,
    data: {},
  },
  scoredCompaniesOptions: {
    status: FETCH_STATUSES.idle,
    data: [],
  },
  insightsScoredCompanies: {
    status: FETCH_STATUSES.idle,
    error: null,
    data: [],
  },
  insightsScoredCompaniesOptions: {
    status: FETCH_STATUSES.idle,
    data: [],
  },
};

const multipleAccountsReflectionReportSlice = createSlice({
  name: 'multipleAccountCustomerLensReport',
  initialState,
  reducers: {
    loadFlaggedTickersStart(state) {
      state.flaggedTickers.status = FETCH_STATUSES.loading;
    },
    loadFlaggedTickersSuccess(state, { payload }) {
      state.flaggedTickers.status = FETCH_STATUSES.success;
      state.flaggedTickers.data = payload.flaggedTickers;
    },
    loadFlaggedTickersFail(state) {
      state.flaggedTickers.status = FETCH_STATUSES.failure;
    },
    resetFlaggedTickers(state) {
      state.flaggedTickers.status = FETCH_STATUSES.idle;
      state.flaggedTickers.data = [];
    },
    loadFundScoresStart(state) {
      state.fundScores.status = FETCH_STATUSES.loading;
    },
    loadFundScoresSuccess(state, { payload }) {
      state.fundScores.status = FETCH_STATUSES.success;
      state.fundScores.data = payload;
    },
    loadFundScoresFail(state) {
      state.fundScores.status = FETCH_STATUSES.failure;
    },
    resetFundScores(state) {
      state.fundScores.status = FETCH_STATUSES.idle;
      state.fundScores.data = [];
    },
    loadPortfolioAnalysisStart(state) {
      state.portfolioAnalysis.status = FETCH_STATUSES.loading;
    },
    loadPortfolioAnalysisSuccess(state, { payload }) {
      state.portfolioAnalysis.status = FETCH_STATUSES.success;
      state.portfolioAnalysis.data = { ...payload };
    },
    loadPortfolioAnalysisFail(state) {
      state.portfolioAnalysis.status = FETCH_STATUSES.failure;
    },
    resetPortfolioAnalysis(state) {
      state.portfolioAnalysis.status = FETCH_STATUSES.idle;
      state.portfolioAnalysis.data = {};
    },
    loadCustomerFundAnalysesStart(state) {
      state.customerFundAnalyses.status = FETCH_STATUSES.loading;
    },
    loadCustomerFundAnalysesSuccess(state, { payload }) {
      state.customerFundAnalyses.status = FETCH_STATUSES.success;
      state.customerFundAnalyses.data = payload.customerFundAnalyses;
    },
    loadCustomerFundAnalysesFail(state) {
      state.customerFundAnalyses.status = FETCH_STATUSES.failure;
    },
    resetCustomerFundAnalyses(state) {
      state.customerFundAnalyses.status = FETCH_STATUSES.idle;
      state.customerFundAnalyses.data = [];
    },
    loadCustomerFundAnalysesOptionsStart(state) {
      state.customerFundAnalysesOptions.status = FETCH_STATUSES.loading;
    },
    loadCustomerFundAnalysesOptionsSuccess(state, { payload }) {
      state.customerFundAnalysesOptions.status = FETCH_STATUSES.success;
      state.customerFundAnalysesOptions.data = payload.customerFundAnalyses;
    },
    loadCustomerFundAnalysesOptionsFail(state) {
      state.customerFundAnalysesOptions.status = FETCH_STATUSES.failure;
    },
    resetCustomerFundAnalysesOptions(state) {
      state.customerFundAnalysesOptions.status = FETCH_STATUSES.idle;
      state.customerFundAnalysesOptions.data = [];
    },
    loadCustomerFundAnalysesMetricsStart(state) {
      state.customerFundAnalysesMetrics.status = FETCH_STATUSES.loading;
    },
    loadCustomerFundAnalysesMetricsSuccess(state, { payload }) {
      state.customerFundAnalysesMetrics.status = FETCH_STATUSES.success;
      state.customerFundAnalysesMetrics.data = payload;
    },
    loadCustomerFundAnalysesMetricsFail(state) {
      state.customerFundAnalysesMetrics.status = FETCH_STATUSES.failure;
    },
    resetCustomerFundAnalysesMetrics(state) {
      state.customerFundAnalysesMetrics.status = FETCH_STATUSES.idle;
      state.customerFundAnalysesMetrics.data = {};
    },
    loadCustomerFundAnalysesReportMetricsStart(state) {
      state.customerFundsReportMetrics.status = FETCH_STATUSES.loading;
    },
    loadCustomerFundAnalysesReportMetricsSuccess(state, { payload }) {
      state.customerFundsReportMetrics.status = FETCH_STATUSES.success;
      state.customerFundsReportMetrics.data = payload;
    },
    loadCustomerFundAnalysesReportMetricsFail(state) {
      state.customerFundsReportMetrics.status = FETCH_STATUSES.failure;
    },
    resetCustomerFundAnalysesReportMetrics(state) {
      state.customerFundsReportMetrics.status = FETCH_STATUSES.idle;
      state.customerFundsReportMetrics.data = {};
    },
    loadMixedPortfolioAnalysisStart(state) {
      state.mixedPortfolioAnalysis.status = FETCH_STATUSES.loading;
    },
    loadMixedPortfolioAnalysisSuccess(state, { payload }) {
      state.mixedPortfolioAnalysis.status = FETCH_STATUSES.success;
      state.mixedPortfolioAnalysis.data = payload;
    },
    loadMixedPortfolioAnalysisFail(state) {
      state.mixedPortfolioAnalysis.status = FETCH_STATUSES.failure;
    },
    loadScoredCompaniesStart(state) {
      state.scoredCompanies.status = FETCH_STATUSES.loading;
    },
    loadScoredCompaniesSuccess(state, { payload }) {
      state.scoredCompanies.status = FETCH_STATUSES.success;
      state.scoredCompanies.data = payload.companies;
    },
    loadScoredCompaniesFail(state) {
      state.scoredCompanies.status = FETCH_STATUSES.failure;
    },
    resetScoredCompanies(state) {
      state.scoredCompanies.status = FETCH_STATUSES.idle;
      state.scoredCompanies.data = [];
    },
    loadScoredCompanyStart(state) {
      state.scoredCompany.status = FETCH_STATUSES.loading;
    },
    loadScoredCompanySuccess(state, { payload }) {
      state.scoredCompany.status = FETCH_STATUSES.success;
      state.scoredCompany.data = payload.company;
    },
    loadScoredCompanyFail(state) {
      state.scoredCompany.status = FETCH_STATUSES.failure;
    },
    resetScoredCompany(state) {
      state.scoredCompany.status = FETCH_STATUSES.idle;
      state.scoredCompany.data = {};
    },
    loadScoredCompaniesOptionsStart(state) {
      state.scoredCompaniesOptions.status = FETCH_STATUSES.loading;
    },
    loadScoredCompaniesOptionsSuccess(state, { payload }) {
      state.scoredCompaniesOptions.status = FETCH_STATUSES.success;
      state.scoredCompaniesOptions.data = payload.companies;
    },
    loadScoredCompaniesOptionsFail(state) {
      state.scoredCompaniesOptions.status = FETCH_STATUSES.failure;
    },
    resetScoredCompaniesOptions(state) {
      state.scoredCompaniesOptions.status = FETCH_STATUSES.idle;
      state.scoredCompaniesOptions.data = [];
    },
    loadInsightsScoredCompaniesStart(state) {
      state.insightsScoredCompanies.status = FETCH_STATUSES.loading;
    },
    loadInsightsScoredCompaniesSuccess(state, { payload }) {
      state.insightsScoredCompanies.status = FETCH_STATUSES.success;
      state.insightsScoredCompanies.data = payload.companies;
    },
    loadInsightsScoredCompaniesFail(state) {
      state.insightsScoredCompanies.status = FETCH_STATUSES.failure;
    },
    resetInsightsScoredCompanies(state) {
      state.insightsScoredCompanies.status = FETCH_STATUSES.idle;
      state.insightsScoredCompanies.data = [];
    },
    loadInsightsScoredCompaniesOptionsStart(state) {
      state.insightsScoredCompaniesOptions.status = FETCH_STATUSES.loading;
    },
    loadInsightsScoredCompaniesOptionsSuccess(state, { payload }) {
      state.insightsScoredCompaniesOptions.status = FETCH_STATUSES.success;
      state.insightsScoredCompaniesOptions.data = payload.companies;
    },
    loadInsightsScoredCompaniesOptionsFail(state) {
      state.insightsScoredCompaniesOptions.status = FETCH_STATUSES.failure;
    },
    resetInsightsScoredCompaniesOptions(state) {
      state.insightsScoredCompaniesOptions.status = FETCH_STATUSES.idle;
      state.insightsScoredCompaniesOptions.data = [];
    },
  },
});

export const {
  loadFlaggedTickersStart,
  loadFlaggedTickersSuccess,
  loadFlaggedTickersFail,
  loadFundScoresStart,
  loadFundScoresSuccess,
  loadFundScoresFail,
  loadPortfolioAnalysisStart,
  loadPortfolioAnalysisSuccess,
  loadPortfolioAnalysisFail,
  loadCustomerFundAnalysesStart,
  loadCustomerFundAnalysesSuccess,
  loadCustomerFundAnalysesFail,
  loadCustomerFundAnalysesOptionsStart,
  loadCustomerFundAnalysesOptionsSuccess,
  loadCustomerFundAnalysesOptionsFail,
  loadCustomerFundAnalysesMetricsStart,
  loadCustomerFundAnalysesMetricsSuccess,
  loadCustomerFundAnalysesMetricsFail,
  resetCustomerFundAnalysesMetrics,
  loadCustomerFundAnalysesReportMetricsStart,
  loadCustomerFundAnalysesReportMetricsSuccess,
  loadCustomerFundAnalysesReportMetricsFail,
  resetCustomerFundAnalysesReportMetrics,
  loadMixedPortfolioAnalysisStart,
  loadMixedPortfolioAnalysisSuccess,
  loadMixedPortfolioAnalysisFail,
  loadScoredCompaniesStart,
  loadScoredCompaniesSuccess,
  loadScoredCompaniesFail,
  loadScoredCompanyStart,
  loadScoredCompanySuccess,
  loadScoredCompanyFail,
  loadScoredCompaniesOptionsStart,
  loadScoredCompaniesOptionsSuccess,
  loadScoredCompaniesOptionsFail,
  loadInsightsScoredCompaniesStart,
  loadInsightsScoredCompaniesSuccess,
  loadInsightsScoredCompaniesFail,
  loadInsightsScoredCompaniesOptionsStart,
  loadInsightsScoredCompaniesOptionsSuccess,
  loadInsightsScoredCompaniesOptionsFail,
  resetFlaggedTickers,
  resetFundScores,
  resetPortfolioAnalysis,
  resetScoredCompanies,
  resetScoredCompany,
  resetScoredCompaniesOptions,
  resetCustomerFundAnalysesOptions,
  resetCustomerFundAnalyses,
  resetInsightsScoredCompanies,
  resetInsightsScoredCompaniesOptions,
} = multipleAccountsReflectionReportSlice.actions;

export default multipleAccountsReflectionReportSlice.reducer;

export const useMultipleAccountReflectionReportActions = () => {
  const dispatch = useDispatch();

  const loadFlaggedTickers = (portfolioAnalysisBatchId, accountIds) => {
    dispatch(loadFlaggedTickersStart());

    return CustomerFundAnalysesRepository.flaggedTickers(portfolioAnalysisBatchId, {
      q: { customer_portfolio_analysis_id_in: accountIds },
    })
      .then(({ data }) => {
        dispatch(loadFlaggedTickersSuccess(data));

        return Promise.resolve(data);
      })
      .catch(() => dispatch(loadFlaggedTickersFail()));
  };

  const loadInsights = (portfolioAnalysisBatchId, accountIds) => {
    dispatch(loadFundScoresStart());

    return CustomerFundAnalysesRepository.insights(portfolioAnalysisBatchId, {
      q: { customer_portfolio_analysis_id_in: accountIds },
    })
      .then(({ data }) => {
        dispatch(loadFundScoresSuccess(data));

        return Promise.resolve(data);
      })
      .catch(() => dispatch(loadFundScoresFail()));
  };

  const loadFullPortfolioAnalysis = async (portfolioAnalysisBatchId, accountIds) => {
    dispatch(loadPortfolioAnalysisStart());

    try {
      const { data } = await CustomerFundAnalysesRepository.mixedAnalysis(portfolioAnalysisBatchId, {
        q: { customer_portfolio_analysis_id_in: accountIds },
      });
      dispatch(loadPortfolioAnalysisSuccess(data));
      return data;
    } catch (error) {
      dispatch(loadPortfolioAnalysisFail());
      return error;
    }
  };

  const loadMixedReport = async (portfolioAnalysisBatchId, accountIds, customerFundAnalysisIds) => {
    dispatch(loadMixedPortfolioAnalysisStart());

    try {
      const mixedReport = await CustomerFundAnalysesRepository.mixedAnalysis(portfolioAnalysisBatchId, {
        q: { customer_portfolio_analysis_id_in: accountIds },
        fundAnalysesIds: customerFundAnalysisIds,
      });
      const { data } = mixedReport;
      dispatch(loadMixedPortfolioAnalysisSuccess(data));
      return data;
    } catch (error) {
      dispatch(loadMixedPortfolioAnalysisFail());
      return error;
    }
  };

  const loadCustomerFundAnalyses = async (portfolioAnalysisId, accountIds) => {
    dispatch(loadCustomerFundAnalysesStart());

    try {
      const { data } = await CustomerFundAnalysesRepository.index(portfolioAnalysisId, {
        q: { customer_portfolio_analysis_id_in: accountIds },
      });

      dispatch(loadCustomerFundAnalysesSuccess(data));
      return data;
    } catch (error) {
      dispatch(loadCustomerFundAnalysesFail());
      return error;
    }
  };

  const loadCustomerFundAnalysesOptions = async (portfolioAnalysisId, accountIds) => {
    dispatch(loadCustomerFundAnalysesOptionsStart());

    try {
      const { data } = await CustomerFundAnalysesRepository.optionsList(portfolioAnalysisId, {
        q: { customer_portfolio_analysis_id_in: accountIds },
      });

      dispatch(loadCustomerFundAnalysesOptionsSuccess(data));
      return data;
    } catch (error) {
      dispatch(loadCustomerFundAnalysesOptionsFail());
      return error;
    }
  };

  const loadReportMetrics = (portfolioAnalysisId, accountIds, customerFundAnalysisIds) => {
    dispatch(loadCustomerFundAnalysesReportMetricsStart());

    return CustomerFundAnalysesRepository.metrics(portfolioAnalysisId, {
      q: { id_in: customerFundAnalysisIds, customer_portfolio_analysis_id_in: accountIds },
    })
      .then(({ data }) => {
        dispatch(loadCustomerFundAnalysesReportMetricsSuccess(data));
        return Promise.resolve(data);
      })
      .catch(e => {
        dispatch(loadCustomerFundAnalysesReportMetricsFail());
        return Promise.reject(e);
      });
  };

  const loadMetrics = (portfolioAnalysisId, accountIds, customerFundAnalysisIds) => {
    dispatch(loadCustomerFundAnalysesMetricsStart());

    return CustomerFundAnalysesRepository.metrics(portfolioAnalysisId, {
      q: { id_in: customerFundAnalysisIds, customer_portfolio_analysis_id_in: accountIds },
    })
      .then(({ data }) => {
        dispatch(loadCustomerFundAnalysesMetricsSuccess(data));
        return Promise.resolve(data);
      })
      .catch(e => {
        dispatch(loadCustomerFundAnalysesMetricsFail());
        return Promise.reject(e);
      });
  };

  const loadScoredCompanies = (portfolioAnalysisBatchId, accountIds, customerFundIds) => {
    dispatch(loadScoredCompaniesStart());

    return CompaniesRepository.index(portfolioAnalysisBatchId, {
      customerFundAnalysisQ: { idIn: customerFundIds },
      companyQ: { s: 'total_weight desc' },
      q: { customer_portfolio_analysis_id_in: accountIds },
      perPage: 20,
    })
      .then(({ data }) => {
        dispatch(loadScoredCompaniesSuccess(data));
        return Promise.resolve(data);
      })
      .catch(e => {
        dispatch(loadScoredCompaniesFail());

        return Promise.reject(e);
      });
  };

  const loadScoredCompany = (portfolioAnalysisBatchId, companyId, accountIds, customerFundIds) => {
    dispatch(loadScoredCompanyStart());

    return CompaniesRepository.show(portfolioAnalysisBatchId, companyId, {
      customerFundAnalysisQ: { idIn: customerFundIds },
      companyQ: { s: 'total_weight desc' },
      q: { customer_portfolio_analysis_id_in: accountIds },
    })
      .then(({ data }) => {
        dispatch(loadScoredCompanySuccess(data));
        return Promise.resolve(data);
      })
      .catch(e => {
        dispatch(loadScoredCompanyFail());

        return Promise.reject(e);
      });
  };

  const loadScoredCompaniesOptions = (portfolioAnalysisBatchId, accountIds, customerFundIds) => {
    dispatch(loadScoredCompaniesOptionsStart());

    return CompaniesRepository.optionsList(portfolioAnalysisBatchId, {
      customerFundAnalysisQ: { idIn: customerFundIds },
      companyQ: { s: 'total_weight desc' },
      q: { customer_portfolio_analysis_id_in: accountIds },
    })
      .then(({ data }) => {
        dispatch(loadScoredCompaniesOptionsSuccess(data));
        return Promise.resolve(data);
      })
      .catch(e => {
        dispatch(loadScoredCompaniesOptionsFail());

        return Promise.reject(e);
      });
  };

  const loadInsightsScoredCompanies = (portfolioAnalysisBatchId, accountIds, customerFundIds) => {
    dispatch(loadInsightsScoredCompaniesStart());

    return CompaniesRepository.index(portfolioAnalysisBatchId, {
      customerFundAnalysisQ: { idIn: customerFundIds },
      companyQ: { s: 'total_weight desc' },
      q: { customer_portfolio_analysis_id_in: accountIds },
      perPage: 20,
    })
      .then(({ data }) => {
        dispatch(loadInsightsScoredCompaniesSuccess(data));
        return Promise.resolve(data);
      })
      .catch(e => {
        dispatch(loadInsightsScoredCompaniesFail());

        return Promise.reject(e);
      });
  };

  const loadInsightsScoredCompaniesOptions = (portfolioAnalysisBatchId, accountIds, customerFundIds) => {
    dispatch(loadInsightsScoredCompaniesOptionsStart());

    return CompaniesRepository.optionsList(portfolioAnalysisBatchId, {
      customerFundAnalysisQ: { idIn: customerFundIds },
      companyQ: { s: 'total_weight desc' },
      q: { customer_portfolio_analysis_id_in: accountIds },
    })
      .then(({ data }) => {
        dispatch(loadInsightsScoredCompaniesOptionsSuccess(data));
        return Promise.resolve(data);
      })
      .catch(e => {
        dispatch(loadInsightsScoredCompaniesOptionsFail());

        return Promise.reject(e);
      });
  };

  const resetToInitialState = () => {
    dispatch(resetFlaggedTickers());
    dispatch(resetFundScores());
    dispatch(resetPortfolioAnalysis());
    dispatch(resetCustomerFundAnalyses());
    dispatch(resetCustomerFundAnalysesMetrics());
    dispatch(resetCustomerFundAnalysesReportMetrics());
    dispatch(resetCustomerFundAnalysesOptions());
    dispatch(resetScoredCompany());
    dispatch(resetScoredCompaniesOptions());
    dispatch(resetInsightsScoredCompaniesOptions());
  };

  return {
    loadCustomerFundAnalyses,
    loadCustomerFundAnalysesOptions,
    loadFullPortfolioAnalysis,
    loadFlaggedTickers,
    loadInsights,
    loadMetrics,
    loadMixedReport,
    loadReportMetrics,
    loadScoredCompanies,
    loadScoredCompany,
    loadScoredCompaniesOptions,
    loadInsightsScoredCompanies,
    loadInsightsScoredCompaniesOptions,
    resetToInitialState,
  };
};
