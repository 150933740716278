import Routes from 'routes/ApiRoutes';
import FetchHelpers from 'utils/FetchHelpers';

export default {
  create(params, token) {
    const path = Routes.integrationApiV1MoneyguidePayloadPath();
    const headers = { Authorization: `Bearer ${token}` };

    return FetchHelpers.post(path, params, headers);
  },
};
