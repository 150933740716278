import React from 'react';
import { path, sortBy, head, isNil } from 'ramda';

import Slug from 'components/Slug';

import CategoryPresenter from 'presenters/CategoryPresenter';
import PillarPresenter from 'presenters/PillarPresenter';
import CompanyPresenter from 'presenters/CompanyPresenter';
import CompanyPillarAnalysisPresenter from 'presenters/CompanyPillarAnalysisPresenter';
import CompanyCategoryAnalysisPresenter from 'presenters/CompanyCategoryAnalysisPresenter';

import useStyles from './useStyles';

const Table = props => {
  const { currentCompany } = props;
  const classes = useStyles();

  const companyPillarAnalyses = CompanyPresenter.companyPillarAnalyses(currentCompany);
  const companyCategoryAnalyses = CompanyPresenter.companyCategoryAnalyses(currentCompany);
  const companyCategoryBooleanAnalyses = CompanyPresenter.companyCategoryBooleanAnalyses(currentCompany);

  const pillarsDisplayOrderPath = path(['pillar', 'displayOrder']);
  const sortedPillarAnalyses = sortBy(pillarsDisplayOrderPath, companyPillarAnalyses);

  const categoriesDisplayOrderPath = path(['category', 'displayOrder']);
  const categoryAnalyses = [...companyCategoryAnalyses, ...companyCategoryBooleanAnalyses];
  const sortedCategoryAnalyses = sortBy(categoriesDisplayOrderPath, categoryAnalyses);

  const categoryAnalysesByPillar = pillarId => analysis => path(['category', 'pillar', 'id'], analysis) === pillarId;

  const rowsCount = sortedCategoryAnalyses.length / sortedPillarAnalyses.length;

  const init = Array(rowsCount).fill([]);
  const categoryAnalysesForTable = sortedPillarAnalyses.reduce((acc, pillarAnalysis) => {
    const pillarId = path(['pillar', 'id'], pillarAnalysis);
    const categoryAnalysesForPillar = sortedCategoryAnalyses.filter(categoryAnalysesByPillar(pillarId));

    return categoryAnalysesForPillar.map((analysis, index) => [...acc[index], analysis]);
  }, init);

  const renderPillarAnalysis = pillarAnalysis => {
    const pillar = CompanyPillarAnalysisPresenter.pillar(pillarAnalysis);

    return (
      <td key={CompanyPillarAnalysisPresenter.id(pillarAnalysis)}>
        <div className={classes.pillar}>
          <div className={classes.title}>
            <Slug slug={PillarPresenter.slug(pillar)} size="small" />
            <div className={classes.name}>{PillarPresenter.name(pillar)}</div>
          </div>
          <div className={classes.weightText}>{CompanyPillarAnalysisPresenter.weightFullText(pillarAnalysis)}</div>
        </div>
      </td>
    );
  };

  const categoryName = analysis => {
    const category = CompanyCategoryAnalysisPresenter.category(analysis);

    return CategoryPresenter.name(category);
  };

  const renderCategoryAnalysesByPillar = analyses => {
    const firstAnalysis = head(analyses);
    const key = `row-${CompanyCategoryAnalysisPresenter.id(firstAnalysis)}`;

    const getValue = analysis =>
      isNil(CompanyCategoryAnalysisPresenter.weight(analysis))
        ? CompanyCategoryAnalysisPresenter.valueText(analysis)
        : CompanyCategoryAnalysisPresenter.weightText(analysis);

    return (
      <tr className={classes.category} key={key}>
        {analyses.map(analysis => (
          <td key={CompanyCategoryAnalysisPresenter.id(analysis)}>
            <div className={classes.data}>
              <div className={classes.key}>{categoryName(analysis)}</div>
              <div className={classes.value}>{getValue(analysis)}</div>
            </div>
          </td>
        ))}
      </tr>
    );
  };

  return (
    <table className={classes.table}>
      <thead className={classes.thead}>
        <tr>{sortedPillarAnalyses.map(renderPillarAnalysis)}</tr>
      </thead>
      <tbody>
        <tr className={classes.subheader}>
          <td>
            <div className={classes.subheaderItem}>
              <div>Policies & Practices</div>
              <div className={classes.subheaderItemValue}>Values Aligned?</div>
            </div>
          </td>
          <td>
            <div className={classes.subheaderItem}>
              <div>Policies & Practices</div>
              <div className={classes.subheaderItemValue}>Values Aligned?</div>
            </div>
          </td>
          <td>
            <div className={classes.subheaderItem}>
              <div>Policies & Practices</div>
              <div className={classes.subheaderItemValue}>Values Aligned?</div>
            </div>
          </td>
        </tr>
        {categoryAnalysesForTable.map(renderCategoryAnalysesByPillar)}
      </tbody>
    </table>
  );
};

Table.propTypes = {
  currentCompany: CompanyPresenter.shape().isRequired,
};

export default Table;
