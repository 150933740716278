/* eslint-disable func-names */
import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import { head, pluck, isNil } from 'ramda';
import { countPages, getElementDetails } from 'utils/scrollChartHelpers';
import arrow from './images/arrow.svg';

import TickerMetaPresenter from 'presenters/TickerMetaPresenter';
import CustomerFundAnalysisPresenter from 'presenters/CustomerFundAnalysisPresenter';
import FundPortfolioPresenter from 'presenters/FundPortfolioPresenter';

import useStyles from './useStyles';
import clsx from 'clsx';

const CHART_PAGE_WIDTH = 960;
const MARGIN = { top: 20, right: 20, bottom: 30, left: 0 };
const Y_AXIS_MARGIN = { top: 40, left: 20 };
const ELEMENT_TOP_MARGIN = 15;

const CANVAS_HEIGHT = 355 - MARGIN.top - MARGIN.bottom;
const DEFAULT_CANVAS_WIDTH = 1000 - MARGIN.left - MARGIN.right;

const Y_AXIS_WIDTH = 10 + Y_AXIS_MARGIN.top;
const Y_AXIS_HEIGHT = 355 + Y_AXIS_MARGIN.left;

const DEFAULT_BAR_SIZE = 62;
const BAR_GAP = 18;

const Chart = props => {
  const { data, onTooltipClick, selectedFunds, chartID, barWidth, canvasWidth, isShowValues } = props;
  const yAxis = useRef(null);
  const chart = useRef(null);
  const barSize = isNil(barWidth) ? DEFAULT_BAR_SIZE : barWidth;
  const canvasSize = isNil(canvasWidth) ? DEFAULT_CANVAS_WIDTH : canvasWidth;
  const isShowArrows = !isShowValues;

  const pages = countPages(data.length);
  const [currentPage, setCurrentPage] = useState(1);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [tooltipContent, setTooltipContent] = useState('');
  const [currentCompany, setCurrentCompany] = useState({});
  const [tooltipCoordinates, setTooltipCoordinates] = useState({
    top: 0,
    left: 0,
    marginLeft: 0,
  });
  const { top, left, marginLeft } = tooltipCoordinates;
  const selectedFundIds = pluck('id', selectedFunds);
  const classes = useStyles();

  useEffect(() => {
    const scaleLinear = d3
      .scaleLinear()
      .domain([0, d3.max(pluck('scoredPercent', data))])
      .range([0, CANVAS_HEIGHT]);

    d3.select(`#${chartID}`)
      .attr('width', canvasSize + MARGIN.left + MARGIN.right)
      .attr('height', CANVAS_HEIGHT + MARGIN.top + MARGIN.bottom)
      .append('g')
      .attr('transform', `translate(${MARGIN.left},${MARGIN.top})`);

    const yAxisEl = d3
      .select(yAxis.current)
      .append('svg')
      .attr('width', Y_AXIS_WIDTH)
      .attr('height', Y_AXIS_HEIGHT)
      .append('g')
      .attr('transform', `translate(${Y_AXIS_MARGIN.top},${Y_AXIS_MARGIN.left})`);

    const yScale = d3
      .scaleLinear()
      .domain([0, d3.max(pluck('scoredPercent', data))])
      .range([CANVAS_HEIGHT, 0]);

    d3.select(`#${chartID}`)
      .append('g')
      .attr('class', 'grid')
      .style('color', '#dedede')
      .call(
        d3
          .axisLeft()
          .scale(yScale)
          .tickSize(-canvasSize, 0, 0)
          .tickFormat(''),
      )
      .select('.domain')
      .remove();

    d3.select(`#${chartID}`)
      .append('g')
      .attr('class', 'bars')
      .selectAll('rect')
      .data(data)
      .enter()
      .append('g')
      .attr('class', 'barGroup')
      .append('rect')
      .attr('class', 'bar')
      .attr('width', barSize)
      .attr('fill', 'url(#linearGradient-1)')
      .attr('rx', 3)
      .attr('height', datapoint => scaleLinear(datapoint.scoredPercent))
      .attr('x', (datapoint, iteration) => iteration * (barSize + BAR_GAP))
      .attr('y', datapoint => CANVAS_HEIGHT - scaleLinear(datapoint.scoredPercent));

    const bars = d3.select(`#${chartID} .bars`);
    bars.style('transition', 'all 0.5s ease-in-out');

    d3.selectAll(`#${chartID} .barGroup`)
      .append('text')
      .text(datapoint => datapoint.companyTicker)
      .attr('x', (datapoint, iteration) => iteration * (barSize + BAR_GAP) + barSize / 2)
      .attr('y', () => CANVAS_HEIGHT + 20)
      .attr('text-anchor', 'middle');

    if (isShowValues) {
      d3.selectAll(`#${chartID} .barGroup`)
        .append('text')
        .text(datapoint => `(${parseFloat(datapoint.scoredPercent).toFixed(2)})`)
        .attr('x', (datapoint, iteration) => iteration * (barSize + BAR_GAP) + barSize / 2)
        .attr('y', () => CANVAS_HEIGHT + 40)
        .attr('text-anchor', 'middle');
    }

    yAxisEl
      .append('g')
      .call(
        d3
          .axisLeft(yScale)
          .tickSize(0)
          .tickFormat(val => `${val}%`),
      )
      .select('.domain')
      .remove();
  }, []);

  useEffect(() => {
    const offsetValue = (currentPage - 1) * CHART_PAGE_WIDTH;

    d3.selectAll(`#${chartID} .bar`).on('mouseover', function(value, index, ...nodes) {
      const { scoredPercent, ...tickerMeta } = value;
      const ticker = TickerMetaPresenter.companyTicker(tickerMeta);
      const companyName = TickerMetaPresenter.companyName(tickerMeta);
      const fundPortfolio = TickerMetaPresenter.fundPortfolio(tickerMeta);

      const customerFundAnalysis = FundPortfolioPresenter.customerFundAnalysis(fundPortfolio);
      const customerFundAnalysisTicker = CustomerFundAnalysisPresenter.ticker(customerFundAnalysis);

      const fixedScoredPercent =
        scoredPercent < 1 ? parseFloat(scoredPercent).toFixed(2) : parseFloat(scoredPercent).toFixed(1);
      const rect = head(nodes)[index];
      const content = `${companyName} (${ticker}) is ${fixedScoredPercent}% of the value of ${customerFundAnalysisTicker}`;
      const { x, y, width } = getElementDetails(rect);
      const marginLeftValue = width / 2 - offsetValue + MARGIN.left;

      setIsTooltipVisible(true);
      setTooltipContent(content);
      setCurrentCompany(tickerMeta);
      setTooltipCoordinates({ top: y - ELEMENT_TOP_MARGIN, left: x, marginLeft: marginLeftValue });
    });

    d3.select(chart.current).on('mouseleave', function() {
      setIsTooltipVisible(false);
    });

    d3.selectAll(`#${chartID} .grid`).on('mouseenter', function() {
      setIsTooltipVisible(false);
    });

    d3.selectAll(`#${chartID} .grid`).on('mouseleave', function() {
      setIsTooltipVisible(false);
    });

    d3.select(`#${chartID} .bars`).style('transform', `translateX(-${offsetValue}px)`);
    d3.select(`#${chartID}`).on('transitionend', () => {
      d3.select(`#${chartID}`).style('pointer-events', 'auto');
    });
  }, [currentPage]);

  const handlePrevClick = () => {
    d3.select(`#${chartID}`).style('pointer-events', 'none');
    setCurrentPage(prev => prev - 1);
  };

  const handleNextClick = () => {
    d3.select(`#${chartID}`).style('pointer-events', 'none');
    setCurrentPage(prev => prev + 1);
  };

  const handleTooltipMouseEnter = () => {
    setIsTooltipVisible(true);
  };

  const handleTooltipMouseLeave = () => {
    setIsTooltipVisible(false);
  };

  const chartStyles = clsx(classes.chart, {
    [classes.hasPrevArrow]: currentPage !== 1,
    [classes.hasNextArrow]: pages > 1 && currentPage < pages,
  });

  return (
    <>
      <svg width="0" height="0">
        <defs>
          <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-1">
            <stop stopColor="#873A9E" offset="0%" />
            <stop stopColor="#5C3169" offset="100%" />
          </linearGradient>
        </defs>
      </svg>
      <div className={classes.chartContainer}>
        <div className={classes.yAxis} ref={yAxis} />
        <div className={chartStyles} ref={chart}>
          <button className={classes.prevButton} onClick={handlePrevClick} type="button">
            <img src={arrow} alt="prev icon" />
          </button>
          <svg id={chartID} />
          {isShowArrows && (
            <button className={classes.nextButton} onClick={handleNextClick} type="button">
              <img src={arrow} alt="next icon" />
            </button>
          )}
          {isTooltipVisible && (
            <div
              className={classes.customTooltip}
              style={{ top, left, marginLeft }}
              onMouseEnter={handleTooltipMouseEnter}
              onMouseLeave={handleTooltipMouseLeave}
            >
              <div className={classes.content}>
                <div className={classes.description}>{tooltipContent}</div>
                <button
                  onClick={() => onTooltipClick(currentCompany, selectedFundIds)}
                  className={classes.link}
                  type="button"
                >
                  View details
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

Chart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()),
  onTooltipClick: PropTypes.func,
  selectedFunds: PropTypes.arrayOf(PropTypes.shape()),
  chartID: PropTypes.string,
  barWidth: PropTypes.number,
  canvasWidth: PropTypes.number,
  isShowValues: PropTypes.bool,
};

Chart.defaultProps = {
  barWidth: null,
  canvasWidth: null,
  isShowValues: false,
  onTooltipClick: () => {},
};

export default Chart;
