import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import BackArrow from 'components/Icons/BackArrow';

import useStyles from './useStyles';

const COLORS = {
  black: 'black',
  white: 'white',
};

const BackButton = props => {
  const { to, children, textColor } = props;
  const classes = useStyles();

  const buttonClassNames = clsx(
    {
      [classes.black]: textColor === COLORS.black,
      [classes.white]: textColor === COLORS.white,
    },
    classes.backLink,
  );

  return (
    <Link to={to}>
      <div className={buttonClassNames}>
        <BackArrow viewBox="0 0 6 10" />
        {children}
      </div>
    </Link>
  );
};

BackButton.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  textColor: PropTypes.oneOf(Object.values(COLORS)),
};

BackButton.defaultProps = {
  textColor: COLORS.black,
};

export default BackButton;
