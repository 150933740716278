import PropTypes from 'prop-types';
import Presenter from 'utils/PropTypesPresenter';
import { format } from 'date-fns';

import { sortWith, descend, prop, isNil } from 'ramda';

import FundPresenter from 'presenters/FundPresenter';
import FlagPresenter from 'presenters/FlagPresenter';
import { US_DATE_FORMAT } from 'utils/dateUtils';

const E_SLUG = 'E';
const S_SLUG = 'S';
const G_SLUG = 'G';
const EQTYX_KIND = 'equity';
const BONDX_KIND = 'bond';

const getPillarAnalysis = (analyses, slug) => analyses.find(analysis => analysis.pillar.slug === slug);

export const SORTING_FIELDS = {
  match: 'portfolio_reflection_match_fund_total_analysis_average_weight',
  name: 'fund_name',
  ticker: 'fund_ticker',
  e_pillar: 'fund_pillar_e_analysis_groupped_weight',
  s_pillar: 'fund_pillar_s_analysis_groupped_weight',
  g_pillar: 'fund_pillar_g_analysis_groupped_weight',
  flag: 'flags_count',
};

export default new Presenter(
  {
    id: PropTypes.number,
    customerPortfoliosDollarPercentage: PropTypes.string,
    kind: PropTypes.string,
    fund: FundPresenter.shape(),
    reflectionMatchScore: PropTypes.number,
    fundPillarAnalyses: PropTypes.arrayOf(PropTypes.shape()),
    flags: PropTypes.arrayOf(FlagPresenter.shape()),
    standardDeviation: PropTypes.string,
    firstYear: PropTypes.string,
    thirdYear: PropTypes.string,
    fifthYear: PropTypes.string,
    assetClassId: PropTypes.number,
    lastUpdatedAt: PropTypes.string,
    linkUrl: PropTypes.string,
  },
  {
    EQTYXFunds(data) {
      return data.filter(item => item.kind === EQTYX_KIND);
    },
    BONDXFunds(data) {
      return data.filter(item => item.kind === BONDX_KIND);
    },
    sortedEQTYXFunds(data) {
      const sort = sortWith([descend(prop('reflectionMatchScore'))]);
      return sort(data.filter(item => item.kind === EQTYX_KIND));
    },
    sortedBONDXFunds(data) {
      const sort = sortWith([descend(prop('reflectionMatchScore'))]);
      return sort(data.filter(item => item.kind === BONDX_KIND));
    },
    roundedMatch(fund) {
      return Math.round(this.reflectionMatchScore(fund));
    },
    lastUpdatedAtInUsFormat(fund) {
      const date = this.lastUpdatedAt(fund);
      return isNil(date) ? date : format(new Date(date), US_DATE_FORMAT);
    },
    weightTextsByPillar(fund) {
      const ePillarText = getPillarAnalysis(this.fundPillarAnalyses(fund), E_SLUG).grouppedWeightText;
      const sPillarText = getPillarAnalysis(this.fundPillarAnalyses(fund), S_SLUG).grouppedWeightText;
      const gPillarText = getPillarAnalysis(this.fundPillarAnalyses(fund), G_SLUG).grouppedWeightText;

      return { environment: ePillarText, social: sPillarText, governance: gPillarText };
    },
    fundId(fund) {
      const fundItem = this.fund(fund);

      return FundPresenter.id(fundItem);
    },
    fundName(fund) {
      const fundItem = this.fund(fund);

      return FundPresenter.name(fundItem);
    },
    fundTicker(fund) {
      const fundItem = this.fund(fund);

      return FundPresenter.ticker(fundItem);
    },
    notApplicableValue(fund, property, callback) {
      const value = fund[property];
      if (isNil(value) || Number(value) === 0) {
        return 'N/A';
      }
      return callback ? callback(value) : value;
    },
  },
);
