import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { List, ListItem, ListItemText } from '@material-ui/core';
import { append, without } from 'ramda';
import clsx from 'clsx';

import SubTree from '../SubTree';
import useStyles from './useStyles';

const Tree = ({ data, selected, onChangeSelected, batchId, selectedAccountIds }) => {
  const classes = useStyles();

  const [openChildren, setOpenChildren] = useState([]);
  const toggleNode = node =>
    setOpenChildren(state => {
      if (state.includes(node.id)) {
        return without([node.id], state);
      }
      return append(node.id, state);
    });

  return (
    <List disablePadding>
      <ListItem className={clsx(classes.listItem, classes.listHead)}>
        <ListItemText className={classes.centerCell} disableTypography>
          $ Amount
        </ListItemText>
        <ListItemText className={classes.centerCell} disableTypography>
          % of Total
        </ListItemText>
        <ListItemText />
        <ListItemText className={classes.centerCell} disableTypography>
          Match Score
        </ListItemText>
        <ListItemText />
      </ListItem>
      {data.map(category => (
        <SubTree
          key={category.id}
          root={category}
          openChildren={openChildren}
          toggleNode={toggleNode}
          level={1}
          selected={selected}
          onChangeSelected={onChangeSelected}
          batchId={batchId}
          selectedAccountIds={selectedAccountIds}
        />
      ))}
    </List>
  );
};

export default Tree;

Tree.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()),
  selected: PropTypes.number,
  onChangeSelected: PropTypes.func.isRequired,
  batchId: PropTypes.number.isRequired,
  selectedAccountIds: PropTypes.arrayOf(PropTypes.number).isRequired,
};
