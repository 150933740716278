import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  () => ({
    root: {
      backgroundColor: '#fff',
      borderRadius: '10px 0 10px 0',
      boxShadow: '0 25px 20px 0 rgba(0,0,0,0.15), -4px 4px 15px 0 rgba(0,0,0,0.10)',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      border: '5px solid transparent',
      position: 'relative',
      padding: '5px 6px 0 6px',
      minHeight: 200,
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    content: {
      color: '#232020',
      textAlign: 'center',
      flexGrow: 1,
      justifyContent: 'center',
      display: 'flex',
      alignItems: 'center',
      fontWeight: 'bold',
      fontSize: 17,
      paddingBottom: 29,
    },
    question: {
      border: 'none',
      padding: 0,
      cursor: 'pointer',
      backgroundColor: 'transparent',
      '&:focus': {
        outline: 'none',
      },
    },
    helperPopup: {
      width: 320,
      borderRadius: 10,
      position: 'relative',
      fontSize: '1.1em',
      textAlign: 'center',
      fontWeight: 300,
      color: '#FFF',
      background: '#232020',
      padding: '3px 15px',
      marginTop: -15,
      '&::after': {
        content: '""',
        width: 0,
        height: 0,
        position: 'absolute',
        top: '100%',
        left: '50%',
        transform: 'translateX(-50%)',
        borderStyle: 'solid',
        borderWidth: '12px 10px 0 10px',
        borderColor: '#232020 transparent transparent transparent',
      },
    },
    popover: {
      pointerEvents: 'none',
    },
  }),
  { name: 'Card' },
);

export default useStyles;
