import React, { useState, useEffect } from 'react';
import {
  SelectInput,
  AutocompleteInput,
  Create,
  SimpleForm,
  ReferenceInput,
  useDataProvider,
  TextInput,
  NumberInput,
  DateInput,
} from 'react-admin';
import { isNil, keys } from 'ramda';
import { format } from 'date-fns';

import SelectAssetClass from './components/SelectAssetClass';

import { isBlank } from 'utils';
import { ISO_DATE_FORMAT } from 'utils/dateUtils';

const searchParams = {
  pagination: { page: 1, perPage: 20 },
  sort: { field: 'id', order: 'ASC' },
};

const InstitutionFundCreate = props => {
  const dataProvider = useDataProvider();
  const [institutions, setInstitutions] = useState([]);
  const [partnerId, setPartnerId] = useState(null);
  const [errors, setErrors] = useState({});

  const handleSystemInstitutionsLoad = async () => {
    const { data } = await dataProvider.getList('institutions', {
      ...searchParams,
      filter: { sourceEq: 'system' },
    });
    setInstitutions(data);
  };

  const handleInstitutionsLoad = async () => {
    const { data } = await dataProvider.getList('institutions', {
      ...searchParams,
      filter: { partnerIdEq: partnerId },
    });

    const listWithPartnerAndNotEmptyName = data.map(item =>
      isNil(item.name) ? { ...item, name: `External ID: ${item.external_id}` } : item,
    );
    setInstitutions(listWithPartnerAndNotEmptyName);
  };

  useEffect(() => {
    if (isBlank(partnerId)) {
      handleSystemInstitutionsLoad();
      return;
    }

    handleInstitutionsLoad();
  }, [partnerId]);

  const fundOptionText = fund => `${fund.ticker} | ${fund.name}`;

  const onFailure = error => {
    const responseErrors = error.body.errors;
    const resultErrors = {};

    keys(responseErrors).forEach(key => {
      resultErrors[key] = responseErrors[key].join(', ');
    });

    setErrors(resultErrors);
  };

  const handleResetError = key => () => {
    setErrors(state => ({ ...state, [key]: null }));
  };

  return (
    <Create onFailure={onFailure} {...props}>
      <SimpleForm>
        <ReferenceInput
          onChange={value => setPartnerId(value)}
          label="Partner Name"
          source="partner_id"
          reference="partners"
          filterToQuery={searchText => ({ NameCont: searchText })}
        >
          <AutocompleteInput resettable optionText="name" />
        </ReferenceInput>
        <SelectAssetClass source="asset_class_id" />
        <AutocompleteInput
          source="institution_id"
          choices={institutions}
          helperText={errors.institution || 'required'}
          options={{ error: !isNil(errors.institution) }}
          onChange={handleResetError('institution')}
        />
        <ReferenceInput
          label="Fund Name"
          source="fund_id"
          reference="funds"
          filterToQuery={searchText => ({ NameOrTickerCont: searchText })}
          onChange={handleResetError('fund')}
        >
          <AutocompleteInput
            optionText={fundOptionText}
            helperText={errors.fund || 'required'}
            options={{ error: !isNil(errors.fund) }}
          />
        </ReferenceInput>
        <NumberInput source="first_year" />
        <NumberInput source="third_year" />
        <NumberInput source="fifth_year" />
        <NumberInput source="standard_deviation" />
        <NumberInput source="cash" />
        <NumberInput source="bond" />
        <NumberInput source="stock" />
        <NumberInput source="alts" />
        <NumberInput source="cmdty" />
        <TextInput source="link_url" type="text" />
        <SelectInput
          label="Kind"
          source="kind"
          choices={[
            { id: 'model', name: 'model' },
            { id: 'fund', name: 'fund' },
          ]}
          alwaysOn
          helperText={errors.kind || 'required'}
          options={{ error: !isNil(errors.kind) }}
          onChange={handleResetError('kind')}
        />
        <DateInput label="Last update at" defaultValue={format(new Date(), ISO_DATE_FORMAT)} source="last_updated_at" />
      </SimpleForm>
    </Create>
  );
};

export default InstitutionFundCreate;
