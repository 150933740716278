import PropTypes from 'prop-types';
import { sortBy } from 'ramda';

import Presenter from 'utils/PropTypesPresenter';

import CompanyTotalAnalysisPresenter from 'presenters/CompanyTotalAnalysisPresenter';
import CompanyPillarAnalysisPresenter from 'presenters/CompanyPillarAnalysisPresenter';
import CompanyCategoryAnalysisPresenter from 'presenters/CompanyCategoryAnalysisPresenter';
import SustainableDevelopmentGoalPresenter from 'presenters/SustainableDevelopmentGoalPresenter';
import FlagPresenter from 'presenters/FlagPresenter';

export default new Presenter(
  {
    id: PropTypes.number,
    name: PropTypes.string,
    ticker: PropTypes.string,
    businessDescription: PropTypes.string,
    companyTotalAnalysis: CompanyTotalAnalysisPresenter.shape(),
    companyPillarAnalyses: PropTypes.arrayOf(CompanyPillarAnalysisPresenter.shape()),
    companyCategoryAnalyses: PropTypes.arrayOf(CompanyCategoryAnalysisPresenter.shape()),
    companyCategoryBooleanAnalyses: PropTypes.arrayOf(PropTypes.shape()),
    sustainableDevelopmentGoals: PropTypes.arrayOf(SustainableDevelopmentGoalPresenter.shape()),
    flags: PropTypes.arrayOf(FlagPresenter.shape()),
  },
  {
    sortedByTicker(companies) {
      const getTicker = company => this.ticker(company);

      return sortBy(getTicker, companies);
    },
  },
);
