import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  () => ({
    root: {
      paddingLeft: 30,
      '@media print': {
        display: 'flex',
      },
    },
  }),
  { name: 'Panels' },
);

export default useStyles;
