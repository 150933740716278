/* eslint-disable react/jsx-no-duplicate-props */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import useStyles from './useStyles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

const Dropdown = props => {
  const { selected, options, onChange, withSearch, handleSearchValueChange, searchValue } = props;
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const labelText = selected ? selected.value : '';

  const handleClick = () => {
    setOpen(prev => !prev);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  const handleChange = option => () => {
    setOpen(false);
    onChange(option);
  };

  const renderCurrentValue = isMenuOpen => {
    const styles = isMenuOpen
      ? { root: classes.buttonOpened, label: classes.labelOpened }
      : { root: classes.button, label: classes.label };
    const icon = isMenuOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />;

    return (
      <Button onClick={handleClick} classes={styles}>
        <span className={classes.text} title={labelText}>
          {labelText}
        </span>
        <span className={classes.icon}>{icon}</span>
      </Button>
    );
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className={classes.dropdown}>
        {renderCurrentValue()}
        {open && (
          <div className={classes.content}>
            {renderCurrentValue(open)}
            <div className={classes.container}>
              {withSearch && (
                <TextField
                  placeholder="Search"
                  className={classes.searchField}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon className={classes.searchIcon} />
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{ className: classes.searchInput }}
                  onChange={handleSearchValueChange}
                  value={searchValue}
                />
              )}
              {options.map(option => {
                const { key, value } = option;
                return (
                  <Button
                    key={key}
                    value={value}
                    onClick={handleChange(option)}
                    classes={{ root: classes.buttonRoot, label: classes.option }}
                    title={value}
                  >
                    {value}
                  </Button>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};

Dropdown.propTypes = {
  selected: PropTypes.shape().isRequired,
  options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onChange: PropTypes.func.isRequired,
  withSearch: PropTypes.bool,
  handleSearchValueChange: PropTypes.func,
  searchValue: PropTypes.string,
};

Dropdown.defaultProps = {
  withSearch: false,
  handleSearchValueChange: () => {},
  searchValue: '',
};

export default Dropdown;
