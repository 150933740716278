import * as yup from 'yup';
import { REQUIRED, REQUIRED_FILE } from 'forms/errorsText';
import { Status, StatusForCreated } from 'enums/ImportRequest';

export const validationFields = {
  file: yup.mixed().required(REQUIRED_FILE),
  ticker: yup.string().required(REQUIRED),
  name: yup.string().required(REQUIRED),
};

export const validationSchema = yup.object().shape({ ...validationFields });

export const initialValues = {
  file: null,
  ticker: '',
  name: '',
};

export const prepareData = data => {
  const preparedData = data.map(item => {
    const { id, ticker, nameInImport, nameInSystem, status, amount, type, typeId } = item;

    return {
      id,
      status,
      imported: { ticker, name: nameInImport },
      reflection: status === Status.correct ? { ticker, name: nameInSystem } : {},
      created: status === Status.correct ? { ticker, name: nameInSystem } : {},
      include: true,
      amount,
      type,
      typeId,
    };
  });

  return preparedData;
};

export const prepareDataForImport = data => {
  const filteredByInclude = data.filter(({ include }) => include);
  const preparedDataForImport = filteredByInclude.map(item => {
    const { created, imported, amount, type, typeId } = item;

    const createdItem = { amount: Number(amount), ticker: created.ticker, name: created.name, type, typeId };

    if (created.status === StatusForCreated.applied) {
      Object.assign(createdItem, {
        ticker: created.ticker,
        name: created.name,
        type: created.type,
        typeId: created.typeId,
      });
    }

    if (created.status === StatusForCreated.matched) {
      Object.assign(createdItem, {
        ticker: imported.ticker,
        name: imported.name,
        relatedTo: created.id,
      });
    }

    return createdItem;
  });
  return { importRequest: { importSchema: preparedDataForImport } };
};
