import React, { useEffect, useState } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { Switch, Route, useHistory } from 'react-router';
import { isEmpty, append, without, map, head, equals, isNil, intersection, take } from 'ramda';
import { useDebouncedCallback } from 'use-debounce';

import Button from '@material-ui/core/Button';
import AppRoutes from 'routes/AppRoutes';
import Drawer from 'components/Drawer';
import Heading from 'components/Heading';
import SaveReportConfirmation from 'components/SaveReportConfirmation';
import PortfolioAnalysisInsights from 'components/ReflectionReport/PortfolioAnalysisInsights';
import Side from 'components/ReflectionReport/Side';
import Main from 'components/ReflectionReport/Main';
import Reports from 'components/ReflectionReport/Reports';
import RecommendationLink from 'components/ReflectionReport/RecommendationLink';

import { REPORT_OVERVIEW } from 'presenters/ReflectionReportPresenter';
import PortfolioAnalysisPresenter from 'presenters/PortfolioAnalysisPresenter';
import CustomerFundAnalysisPresenter from 'presenters/CustomerFundAnalysisPresenter';
import AccountPresenter from 'presenters/AccountPresenter';
import UserPresenter from 'presenters/UserPresenter';

import {
  useReflectionReportRequest,
  useReflectionReportSaving,
  useMultipleAccountsReflectionReportRequest,
} from 'hooks';
import useApp from 'hooks/useApp';

import { findBy } from 'utils/storeUtils';
import { getUrlParam } from 'utils/location';
import { isElementExist, hasSingleElement } from 'utils/arrayUtils';
import { objectToQueryString } from 'utils/urlUtils';
import { generateReport } from 'utils/reflectionReportUtils';

import useStyles from './useStyles';

const MAXIMUM_COUNT_OF_SELECTED_INSIGHT_FUNDS = 5;

const MultipleAccountReflectionReport = () => {
  const routeParams = useParams();
  const history = useHistory();
  const currentBatchId = Number(routeParams.id);
  const accountIdsParams = map(Number, getUrlParam('account_ids'));
  const initialSelectedFundIds = map(Number, getUrlParam('selected_fund_ids', []));

  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [selectedInsightsFundIds, setSelectedInsightsFundIds] = useState([]);
  const [selectedIssuesDefinedFundIds, setSelectedIssuesDefinedFundIds] = useState([]);
  const [selectedCompaniesScoredFundIds, setSelectedCompaniesScoredFundIds] = useState([]);
  const [selectedPercentValueHeldFundIds, setSelectedPercentValueHeldFundIds] = useState([]);
  const [selectedMatchScoreReportsFundIds, setSelectedMatchScoreReportsFundIds] = useState([]);
  const [isSaveReportConfirmationShow, setIsSaveReportConfirmationShow] = useState(false);
  const [overviewFundIds, setOverviewFundIds] = useState(null);
  const [currentCompanyId, setCurrentCompanyId] = useState(null);
  const [selectedAccountIds, setSelectedAccountIds] = useState(accountIdsParams);
  const [overviewReport, setOverviewReport] = useState({});

  const classes = useStyles();

  const {
    loadCustomerFundAnalysesOptions,
    loadFullPortfolioAnalysis,
    loadFlaggedTickers,
    loadInsights,
    loadMetrics,
    loadMixedReport,
    loadReportMetrics,
    loadScoredCompanies,
    loadScoredCompany,
    loadScoredCompaniesOptions,
    loadInsightsScoredCompanies,
    loadInsightsScoredCompaniesOptions,
    customerFundAnalysesOptions,
    portfolioAnalysis,
    flaggedTickers,
    fundScores,
    customerFundAnalysesMetrics,
    customerFundsReportMetrics,
    scoredCompanies,
    scoredCompany,
    scoredCompaniesOptions,
    insightsScoredCompanies,
    mixedReportStatus,
    customerFundsReportMetricsLoadingStatus,
    insightsScoredCompaniesStatuses,
    scoredCompanyStatuses,
    scoredCompaniesOptionsStatuses,
    insightsScoredCompaniesOptions,
    insightsScoredCompaniesOptionsStatuses,
    customerFundAnalysesMetricsStatuses,
  } = useMultipleAccountsReflectionReportRequest();

  const {
    showLoader,
    hideLoader,
    changeSelectedAccount,
    changeShouldRedirectOnSelectedAccountChange,
    currentUser,
    selectedCustomer,
    accounts,
  } = useApp();

  const { currentReport, setCurrentReport, duplicateCustomerLens } = useReflectionReportRequest();

  const updateSelectedInsightsFundIds = fundIds => {
    const insightsSelectedFundIds = take(MAXIMUM_COUNT_OF_SELECTED_INSIGHT_FUNDS, fundIds);
    setSelectedInsightsFundIds(insightsSelectedFundIds);
  };

  const updateAdditionalFundFilters = fundIds => {
    updateSelectedInsightsFundIds(fundIds);
    setSelectedIssuesDefinedFundIds(fundIds);
    setSelectedCompaniesScoredFundIds(fundIds);
    setSelectedPercentValueHeldFundIds(fundIds);
  };

  const [debouncedHandleSetCurrentReport] = useDebouncedCallback(value => {
    handleSetCurrentReport(value); // eslint-disable-line
  }, 1000);

  const [debouncedUpdateFundFilters] = useDebouncedCallback(fundIds => {
    updateAdditionalFundFilters(fundIds);
  }, 1000);

  const [debouncedLoadReportMetrics] = useDebouncedCallback(fundIds => {
    loadReportMetrics(currentBatchId, selectedAccountIds, fundIds);
  }, 1000);

  const accountsList = AccountPresenter.asList(accounts, selectedCustomer);
  const customerLens = PortfolioAnalysisPresenter.customerLens(portfolioAnalysis);

  const [cachedSelectedCustomer] = useState(selectedCustomer);

  const handleRedirectAfterSaveReport = () => {
    setIsSaveReportConfirmationShow(false);
  };

  const handleSaveReportConfirmationShow = () => {
    setIsSaveReportConfirmationShow(true);
  };
  const handleSaveReportConfirmationHide = () => {
    handleRedirectAfterSaveReport();
  };

  const { isCurrentReportActive, handleReportSave } = useReflectionReportSaving({
    currentSelectedCustomer: cachedSelectedCustomer,
    analysisId: '',
  });

  changeShouldRedirectOnSelectedAccountChange(true);

  const getAnalysisIdForTargetBatchId = (analysis, batchId) => {
    if (isNil(analysis)) {
      return null;
    }

    if (batchId !== PortfolioAnalysisPresenter.customerPortfolioAnalysesBatchId(analysis)) {
      return null;
    }

    return PortfolioAnalysisPresenter.id(analysis);
  };

  const getAccountOptionList = () => {
    const getOptions = account => {
      const targetAnalysisId =
        getAnalysisIdForTargetBatchId(account.customerPortfolioAnalysis, currentBatchId) ||
        getAnalysisIdForTargetBatchId(account.cachedCustomerPortfolioAnalysis, currentBatchId);

      return { key: targetAnalysisId, label: account.value };
    };

    const options = map(getOptions, accountsList);

    return options;
  };

  const accountOptions = getAccountOptionList();

  const createMixedAnalysisReport = async (fundIds = []) => {
    const mixedAnalysisReport = await loadMixedReport(currentBatchId, selectedAccountIds, fundIds);
    const { categoryAnalyses, totalAnalysis, pillarAnalyses, reflectionMatchScore } = mixedAnalysisReport;
    const report = PortfolioAnalysisPresenter.toReflectionReport({
      ...portfolioAnalysis,
      portfolioCategoryAnalyses: categoryAnalyses,
      portfolioTotalAnalysis: totalAnalysis,
      portfolioPillarAnalyses: pillarAnalyses,
      reflectionMatchScore,
      ticker: 'Mixed Analysis',
      name: 'Mixed Analysis',
      customerPortfolioAnalysesBatchId: currentBatchId,
    });

    return report;
  };

  const handleReportLoad = () => {
    Promise.all([
      loadFullPortfolioAnalysis(currentBatchId, selectedAccountIds),
      loadCustomerFundAnalysesOptions(currentBatchId, selectedAccountIds),
    ])
      .then(([analysisData, customerFundAnalysesData]) => {
        loadFlaggedTickers(currentBatchId, selectedAccountIds);
        loadInsights(currentBatchId, selectedAccountIds);
        const { customerFundAnalyses: loadedCustomerFundAnalyses } = customerFundAnalysesData;
        const { categoryAnalyses, totalAnalysis, pillarAnalyses, reflectionMatchScore } = analysisData;
        const reportAttributes = {
          portfolioCategoryAnalyses: categoryAnalyses,
          portfolioTotalAnalysis: totalAnalysis,
          portfolioPillarAnalyses: pillarAnalyses,
          reflectionMatchScore,
          ticker: 'Mixed Analysis',
          name: 'Mixed Analysis',
          customerPortfolioAnalysesBatchId: currentBatchId,
        };
        const reportForOverview = PortfolioAnalysisPresenter.toReflectionReport(reportAttributes);
        const reportFundIds = loadedCustomerFundAnalyses.map(fund => CustomerFundAnalysisPresenter.id(fund));
        const preselectedFundIds = intersection(reportFundIds, initialSelectedFundIds);
        const selectedFundIds = isEmpty(preselectedFundIds) ? reportFundIds : preselectedFundIds;

        let report = {};
        if (!isEmpty(preselectedFundIds)) {
          report = generateReport(preselectedFundIds, currentBatchId, createMixedAnalysisReport);
          setSelectedMatchScoreReportsFundIds(preselectedFundIds);
        } else {
          report = reportForOverview;
        }

        updateAdditionalFundFilters(selectedFundIds);
        setOverviewReport(reportForOverview);
        setOverviewFundIds(reportFundIds);
        return report;
      })
      .then(report => {
        setCurrentReport(report);
      })
      .finally(() => {
        hideLoader();
        setIsDataLoaded(true);
      });
  };

  const handlePollAnalysis = async onSuccess => {
    onSuccess();
  };

  const handleChangeSelectedAccount = accountIds => {
    if (equals(accountIds, selectedAccountIds)) {
      return;
    }

    if (hasSingleElement(accountIds)) {
      const accountId = head(accountIds);
      const accountObject =
        findBy('customerPortfolioAnalysisId', accountId, accounts) ||
        findBy('cachedCustomerPortfolioAnalysisId', accountId, accounts);

      changeSelectedAccount(accountObject);
      history.push(AppRoutes.portfolioAnalysisPath(accountId));
      return;
    }

    const batchId = PortfolioAnalysisPresenter.customerPortfolioAnalysesBatchId(currentReport);
    const search = objectToQueryString({ account_ids: accountIds });
    history.push({ path: AppRoutes.multipleAccountsPortfolioAnalysisPath(batchId), search });
    setSelectedAccountIds(accountIds);
  };

  useEffect(() => {
    showLoader('loading');
    handlePollAnalysis(handleReportLoad);
  }, [selectedAccountIds]);

  useEffect(() => {
    const fundIds = isEmpty(selectedMatchScoreReportsFundIds) ? overviewFundIds : selectedMatchScoreReportsFundIds;
    debouncedUpdateFundFilters(fundIds);
    debouncedLoadReportMetrics(fundIds);
  }, [selectedMatchScoreReportsFundIds]);

  useEffect(() => {
    if (!isEmpty(selectedInsightsFundIds)) {
      loadMetrics(currentBatchId, selectedAccountIds, selectedInsightsFundIds);
      loadInsightsScoredCompanies(currentBatchId, selectedAccountIds, selectedInsightsFundIds);
      loadInsightsScoredCompaniesOptions(currentBatchId, selectedAccountIds, selectedInsightsFundIds);
    }
  }, [selectedInsightsFundIds]);

  useEffect(() => {
    if (!isEmpty(selectedCompaniesScoredFundIds)) {
      loadScoredCompanies(currentBatchId, selectedAccountIds, selectedCompaniesScoredFundIds);
      loadScoredCompaniesOptions(currentBatchId, selectedAccountIds, selectedCompaniesScoredFundIds);
    }
  }, [selectedCompaniesScoredFundIds]);

  useEffect(() => {
    if (!isNil(currentCompanyId)) {
      loadScoredCompany(currentBatchId, currentCompanyId, selectedAccountIds, selectedCompaniesScoredFundIds);
    }
  }, [currentCompanyId]);

  if (!isDataLoaded) {
    return null;
  }

  const changeReportFundIds = fundId => {
    if (isElementExist(fundId, selectedMatchScoreReportsFundIds)) {
      return without([fundId], selectedMatchScoreReportsFundIds);
    }
    return append(fundId, selectedMatchScoreReportsFundIds);
  };

  const handleSetCurrentReport = async () => {
    const report = await generateReport(selectedMatchScoreReportsFundIds, currentBatchId, createMixedAnalysisReport);
    setCurrentReport(report);
  };

  const handleChangeCurrentReport = selectedReport => {
    const { ticker: label, id } = selectedReport;
    const fundIds = changeReportFundIds(id);

    if (label === REPORT_OVERVIEW.label || isEmpty(fundIds)) {
      setSelectedMatchScoreReportsFundIds([]);
      setCurrentReport(overviewReport);
      return;
    }

    setSelectedMatchScoreReportsFundIds(fundIds);
    debouncedHandleSetCurrentReport(id);
  };

  const sortedCustomerFundAnalyses = CustomerFundAnalysisPresenter.sortedByTotalAmount(customerFundAnalysesOptions);
  const scoredFundScores = fundScores.filter(({ id }) => customerFundAnalysesOptions.some(fund => fund.id === id));
  const sortedFundScores = CustomerFundAnalysisPresenter.sortedByTotalAmount(scoredFundScores);
  const reports = [{ ticker: REPORT_OVERVIEW.label, name: REPORT_OVERVIEW.description }, ...sortedCustomerFundAnalyses];

  const { recommendationId } = portfolioAnalysis;
  const isRecommendationsAvailable = UserPresenter.recommendationsAvailable(currentUser) && recommendationId;

  const isSamplePortfolio = PortfolioAnalysisPresenter.isSample(portfolioAnalysis);

  return (
    <div className={classes.root}>
      <div className={classes.reportContainer}>
        <div className={classes.container}>
          <div className={classes.headLine}>
            <Heading
              intro="Are you aligned?"
              title="Review Your Results"
              description="Using your selections, we have reviewed your investments for how aligned they are to your values."
              className={classes.heading}
            />
          </div>
          <div className={classes.report}>
            <div className={classes.side}>
              <Side
                currentUser={currentUser}
                customerLens={customerLens}
                onDuplicateCustomerLens={duplicateCustomerLens}
                analysisId={currentBatchId}
                accounts={accounts}
              />
            </div>
            <div className={classes.main}>
              <Main
                reports={reports}
                customerLens={customerLens}
                onChangeCurrentReport={handleChangeCurrentReport}
                currentReport={currentReport}
                onRefreshReportConfirmationShow={() => {}}
                flaggedTickers={flaggedTickers}
                customerFundAnalyses={sortedCustomerFundAnalyses}
                onSetCurrentCompanyId={setCurrentCompanyId}
                selectedIssuesDefinedFundIds={selectedIssuesDefinedFundIds}
                onSetSelectedIssuesDefinedFundIds={setSelectedIssuesDefinedFundIds}
                selectedCompaniesScoredFundIds={selectedCompaniesScoredFundIds}
                onSetSelectedCompaniesScoredFundIds={setSelectedCompaniesScoredFundIds}
                selectedPercentValueHeldFundIds={selectedPercentValueHeldFundIds}
                onSetSelectedPercentValueHeldFundIds={setSelectedPercentValueHeldFundIds}
                currentCompanyId={currentCompanyId}
                currentCompany={scoredCompany}
                isCurrentCompanyLoading={scoredCompanyStatuses.isLoading}
                currentUser={currentUser}
                accounts={accountsList}
                selectedAccountIds={selectedAccountIds}
                scoredCompanies={scoredCompanies}
                scoredCompaniesOptions={scoredCompaniesOptions}
                isScoredCompaniesOptionsLoading={scoredCompaniesOptionsStatuses.isLoading}
                onChangeSelectedAccount={handleChangeSelectedAccount}
                isSamplePortfolio={isSamplePortfolio}
                customerFundsReportLoadingStatus={customerFundsReportMetricsLoadingStatus}
                mixedReportStatus={mixedReportStatus}
                customerFundsReportMetrics={customerFundsReportMetrics}
                selectedMatchScoreReportsFundIds={selectedMatchScoreReportsFundIds}
                overviewFundIds={overviewFundIds}
                isMultipleAccountMode
                accountOptions={accountOptions}
              />
            </div>
          </div>
        </div>
      </div>
      <Drawer open={isSaveReportConfirmationShow} onClose={handleSaveReportConfirmationHide}>
        <SaveReportConfirmation
          onConfirm={handleReportSave(handleRedirectAfterSaveReport)}
          onClose={handleSaveReportConfirmationHide}
        >
          <>
            <div className={classes.saveDescriptionTitle}>
              {`Would you like to save this Reflection Report for ${UserPresenter.fullName(
                cachedSelectedCustomer,
              )} to view?`}
            </div>
            Client must have access to MyBlocks within
            <br />
            the Client Portal
          </>
        </SaveReportConfirmation>
      </Drawer>
      <Switch>
        <Route path={AppRoutes.multipleAccountsPortfolioAnalysisPath(':id')} exact>
          <PortfolioAnalysisInsights
            customerFundsReport={customerFundAnalysesMetrics}
            currentReport={currentReport}
            portfolioAnalysis={portfolioAnalysis}
            fundScores={sortedFundScores}
            onSetSelectedFundIds={updateSelectedInsightsFundIds}
            selectedFundIds={selectedInsightsFundIds}
            isCustomerFundsReportLoading={customerFundAnalysesMetricsStatuses.isLoading}
            selectedAccountIds={selectedAccountIds}
            maximumNumberOfSelectedFunds={MAXIMUM_COUNT_OF_SELECTED_INSIGHT_FUNDS}
            isMultipleAccountMode
          />
        </Route>
        <Route
          path={[
            AppRoutes.multipleAccountsPortfolioAnalysisPathPercentValueHeldPath(':id'),
            AppRoutes.multipleAccountsPortfolioAnalysisIssuesDefinedPath(':id'),
            AppRoutes.multipleAccountsPortfolioAnalysisCompaniesScoredPath(':id'),
          ]}
          exact
        >
          <Reports
            customerFundsReport={customerFundAnalysesMetrics}
            customerFundAnalyses={sortedCustomerFundAnalyses}
            flaggedTickers={flaggedTickers}
            onSetCurrentCompanyId={setCurrentCompanyId}
            onSetSelectedFundIds={updateSelectedInsightsFundIds}
            selectedFundIds={selectedInsightsFundIds}
            currentCompanyId={currentCompanyId}
            scoredCompanies={insightsScoredCompanies}
            isScoredCompaniesLoading={insightsScoredCompaniesStatuses.isLoading}
            scoredCompaniesOptions={insightsScoredCompaniesOptions}
            isScoredCompaniesOptionsLoading={insightsScoredCompaniesOptionsStatuses.isLoading}
            currentCompany={scoredCompany}
            isCurrentCompanyLoading={scoredCompanyStatuses.isLoading}
            selectedAccountIds={selectedAccountIds}
            isMultipleAccountMode
          />
        </Route>

        <Redirect to={AppRoutes.multipleAccountsPortfolioAnalysisPath(currentBatchId)} />
      </Switch>
      <div className={classes.buttonsGroup}>
        {!isCurrentReportActive && (
          <Button type="button" className={classes.save} variant="text" onClick={handleSaveReportConfirmationShow}>
            SAVE
          </Button>
        )}
        {isRecommendationsAvailable && (
          <RecommendationLink
            portfolioAnalysisIds={accountIdsParams}
            recommendationId={recommendationId}
            text="See Recommendations"
          />
        )}
      </div>
    </div>
  );
};

export default MultipleAccountReflectionReport;
