import React from 'react';
import { Filter, TextInput, List, Datagrid, TextField, SelectInput, EditButton } from 'react-admin';

import ExportPresenter from 'presenters/ExportPresenter';

import ExportsRepository from 'repositories/admin/institution_fund/ExportsRepository';

import InstitutionFundActions from './InstitutionFundActions';

const InstitutionFundFilter = props => (
  <Filter {...props}>
    <TextInput label="Institution ID" source="institutionIdEq" alwaysOn />
    <TextInput label="Institution Name" source="institutionNameCont" alwaysOn />
    <TextInput label="Fund Name / Fund Ticker" source="fundNameOrFundTickerCont" alwaysOn />
    <SelectInput
      label="Kind"
      source="kindEq"
      choices={[
        { id: 'model', name: 'model' },
        { id: 'fund', name: 'fund' },
      ]}
      alwaysOn
    />
  </Filter>
);

const InstitutionFundList = props => {
  const handleDownloadExport = async () => {
    await ExportsRepository.download();
  };

  const handlePollExport = async onSuccess => {
    const institutionFundsExport = await ExportsRepository.show();

    if (ExportPresenter.notAttached(institutionFundsExport)) {
      setTimeout(() => handlePollExport(onSuccess), 500);
    } else if (ExportPresenter.isAttached(institutionFundsExport)) {
      onSuccess();
    }
  };

  const handleExport = async () => {
    await ExportsRepository.create();
    handlePollExport(handleDownloadExport);
  };

  return (
    <List
      {...props}
      filters={<InstitutionFundFilter />}
      actions={<InstitutionFundActions handleExportButtonClick={handleExport} />}
    >
      <Datagrid>
        <TextField source="id" />
        <TextField label="Institution ID" source="institution_id" />
        <TextField label="Institution Name" source="institution.name" />
        <TextField label="Fund ID" source="fund_id" />
        <TextField label="Fund Name" source="fund.name" />
        <TextField label="Fund Ticker" source="fund.ticker" />
        <TextField label="Kind" source="kind" />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default InstitutionFundList;
