import { useSelector } from 'react-redux';

import { useRecommendationActions } from 'store/RecommendationSlice';
import getFetchStatus from 'utils/getFetchStatus';

export default () => {
  const {
    loadGroupedTickerAssetClasses,
    loadRecommendation,
    loadRecommendedFunds,
    loadGroupedAssetClassesCategories,
  } = useRecommendationActions();

  const groupedTickerAssetClasses = useSelector(state => state.RecommendationSlice.groupedTickerAssetClasses.data);
  const groupedAssetClassesCategories = useSelector(
    state => state.RecommendationSlice.groupedAssetClassesCategories.data,
  );
  const recommendedFunds = useSelector(state => state.RecommendationSlice.recommendedFunds.data);
  const recommendedFundsStatus = useSelector(state => state.RecommendationSlice.recommendedFunds.status);
  const recommendedFundsStatuses = getFetchStatus(recommendedFundsStatus);

  const groupedTickerAssetClassesStatus = useSelector(
    state => state.RecommendationSlice.groupedTickerAssetClasses.status,
  );
  const groupedTickerAssetClassesStatuses = getFetchStatus(groupedTickerAssetClassesStatus);

  const groupedAssetClassesCategoriesStatus = useSelector(
    state => state.RecommendationSlice.groupedAssetClassesCategories.status,
  );
  const groupedAssetClassesCategoriesStatuses = getFetchStatus(groupedAssetClassesCategoriesStatus);

  return {
    loadGroupedTickerAssetClasses,
    loadRecommendation,
    loadRecommendedFunds,
    loadGroupedAssetClassesCategories,
    groupedTickerAssetClassesStatuses,
    groupedAssetClassesCategoriesStatuses,
    groupedTickerAssetClasses,
    groupedAssetClassesCategories,
    recommendedFunds,
    recommendedFundsStatuses,
  };
};
