import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';

import Divider from '@material-ui/core/Divider';
import Chip from '@material-ui/core/Chip';

import AppRoutes from 'routes/AppRoutes';

import RemoveIcon from 'components/Icons/RemoveIcon';
import Ball from 'components/Ball';
import Link from 'components/Link';
import InsightsChart from 'components/InsightsChart';
import PillarsFilter from 'components/PillarsFilter';
import Tooltip from 'components/Tooltip';
import PdfTemplate from 'components/PdfTemplate';

import { PILLARS_FILTER_LIST } from 'presenters/PillarPresenter';
import FundScorePresenter from 'presenters/FundScorePresenter';
import CustomerFundsReportPresenter from 'presenters/CustomerFundsReportPresenter';

import FundsFilter from 'components/ReflectionReport/PortfolioAnalysisInsights/components/FundsFilter';

import useStyles from './useStyles';

const PortfolioAnalysisInsightsPDF = props => {
  const {
    customerFundsReport,
    fundScores,
    isCustomerFundsReportLoading,
    customerLensId,
    selectedFunds,
    selectedPillarFilter,
    chartData,
    tooltipDescription,
    tooltipPosition,
  } = props;

  const classes = useStyles();
  const emptyHandler = () => {};

  return (
    <PdfTemplate>
      <div className={classes.container}>
        <h2 className={classes.title}>Alignment Insights</h2>
        <div className={classes.layout}>
          <div className={classes.side}>
            <div className={classes.balls}>
              <div className={classes.ball}>
                <Link to={AppRoutes.portfolioAnalysisCompaniesScoredPath(customerLensId)} variant="container">
                  <Ball
                    score={CustomerFundsReportPresenter.formattedScoredCompaniesCount(customerFundsReport)}
                    totalScore={CustomerFundsReportPresenter.formattedTotalCompaniesCount(customerFundsReport)}
                    description="securities scored"
                    isLoading={isCustomerFundsReportLoading}
                  />
                </Link>
              </div>
              <div className={classes.ball}>
                <Link to={AppRoutes.portfolioAnalysisPathPercentValueHeldPath(customerLensId)} variant="container">
                  <Ball
                    value={CustomerFundsReportPresenter.scoredAmountPercents(customerFundsReport)}
                    description="% dollar value held by securities scored"
                    isLoading={isCustomerFundsReportLoading}
                  />
                </Link>
              </div>
              <div className={classes.ball}>
                <Link to={AppRoutes.portfolioAnalysisIssuesDefinedPath(customerLensId)} variant="container">
                  <Ball
                    score={CustomerFundsReportPresenter.formattedCompaniesWithAttachedFlagsCount(customerFundsReport)}
                    totalScore={CustomerFundsReportPresenter.formattedScoredCompaniesCount(customerFundsReport)}
                    description="scored securities with flags"
                    isLoading={isCustomerFundsReportLoading}
                  />
                </Link>
              </div>
            </div>
          </div>
          <div className={classes.main}>
            <div className={classes.options}>
              <div className={classes.fundsFilter}>
                <FundsFilter fundScores={fundScores} selectedFunds={selectedFunds} />
              </div>
              <div>
                <PillarsFilter selectedPillarFilter={selectedPillarFilter} pillarsFilterList={PILLARS_FILTER_LIST} />
              </div>
            </div>
            <Divider />
            <div className={classes.selectedfilters}>
              {!isEmpty(selectedPillarFilter) && (
                <>
                  <div className={classes.caption}>Filtered by:</div>
                  <div className={classes.items}>
                    <div className={classes.item}>
                      <Chip
                        label={selectedPillarFilter.name}
                        classes={{ root: classes.chipRoot, label: classes.chipLabel }}
                        deleteIcon={<RemoveIcon viewBox="0 0 12 12" className={classes.removeIcon} />}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className={classes.chart}>
              <InsightsChart
                chartData={chartData}
                insights={selectedFunds}
                verticalPoints={[100, 240, 380, 520, 660, 802]}
                isShowValue
              />
              <Tooltip
                onTooltipMouseEnter={emptyHandler}
                onTooltipMouseLeave={emptyHandler}
                onViewDetailsClick={emptyHandler}
                tooltipDescription={tooltipDescription}
                tooltipPosition={tooltipPosition}
                isTooltipVisible={false}
              />
            </div>
          </div>
        </div>
      </div>
    </PdfTemplate>
  );
};

PortfolioAnalysisInsightsPDF.propTypes = {
  customerFundsReport: CustomerFundsReportPresenter.shape().isRequired,
  fundScores: PropTypes.arrayOf(FundScorePresenter.shape()).isRequired,
  isCustomerFundsReportLoading: PropTypes.bool.isRequired,
  customerLensId: PropTypes.number.isRequired,
  selectedFunds: PropTypes.arrayOf(FundScorePresenter.shape()).isRequired,
  selectedPillarFilter: PropTypes.shape().isRequired,
  chartData: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  tooltipDescription: PropTypes.string.isRequired,
  tooltipPosition: PropTypes.shape(),
};

export default PortfolioAnalysisInsightsPDF;
