/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import AuthRepository from 'repositories/AuthRepository';
import CurrentUserRepository from 'repositories/CurrentUserRepository';
import UsersRepository from 'repositories/admin/UsersRepository';
import CustomersRepository from 'repositories/advisor/CustomersRepository';
import AccountsRepository from 'repositories/customer/AccountsRepository';
import CustomerAccountsRepository from 'repositories/advisor/CustomerAccountsRepository';
import SavedFundsRepository from 'repositories/customer/SavedFundsRepository';
import AdvisorSavedFundsRepository from 'repositories/advisor/SavedFundsRepository';
import ConfigurationsRepository from 'repositories/ConfigurationsRepository';
import HouseholdRepository from 'repositories/advisor/HouseholdRepository';

import UserPresenter from 'presenters/UserPresenter';
import AccountPresenter from 'presenters/AccountPresenter';
import { FETCH_STATUSES } from 'presenters/FetchStatusPresenter';
import CustomerPresenter from 'presenters/CustomerPresenter';

const initialState = {
  loading: false,
  loaderCaption: '',
  currentUser: {
    status: FETCH_STATUSES.idle,
    data: {},
    meta: {},
  },
  savedFunds: {
    status: FETCH_STATUSES.idle,
    data: [],
  },
  customersList: {
    data: [],
    selected: null,
  },
  accounts: {
    status: FETCH_STATUSES.idle,
    data: [],
    selected: null,
  },
  configurations: {
    status: FETCH_STATUSES.idle,
    error: null,
    data: {},
  },
  advisorHousehold: {
    status: FETCH_STATUSES.idle,
    error: null,
  },
  loadAdvisorHousehold: {
    status: FETCH_STATUSES.idle,
    error: null,
    data: {},
  },
  updateAdvisorHousehold: {
    status: FETCH_STATUSES.idle,
    error: null,
  },
  shouldShowRefreshReportModal: true,
  shouldRedirectOnSelectedAccountChange: false,
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    signInStart(state) {
      state.currentUser.status = FETCH_STATUSES.loading;
    },
    signInFail(state) {
      state.currentUser.status = FETCH_STATUSES.failure;
    },
    signOut(state) {
      state.currentUser.data = {};
    },
    loadCurrentUserStart(state) {
      state.currentUser.status = FETCH_STATUSES.loading;
    },
    loadCurrentUserSuccess(state, { payload: { user, meta } }) {
      state.currentUser.status = FETCH_STATUSES.success;
      state.currentUser.data = user;
      state.currentUser.meta = meta;
    },
    loadCurrentUserFail(state) {
      state.currentUser.status = FETCH_STATUSES.failure;
    },
    turnOnLoader(state, { payload }) {
      state.loading = true;
      state.loaderCaption = payload;
    },
    turnOffLoader(state) {
      state.loading = false;
    },
    clearLoaderCaption(state) {
      state.loaderCaption = '';
    },
    changeSelectedCustomer(state, { payload }) {
      state.customersList.selected = payload;
    },
    changeShouldRedirectOnSelectedAccountChange(state, { payload }) {
      state.shouldRedirectOnSelectedAccountChange = payload;
    },
    changeCustomersListData(state, { payload }) {
      state.customersList.data = payload;
    },
    changeShouldShowRefreshReportModal(state, { payload }) {
      state.shouldShowRefreshReportModal = payload;
    },
    loadAccountsStart(state) {
      state.accounts.status = FETCH_STATUSES.loading;
    },
    loadAccountsSuccess(state) {
      state.accounts.status = FETCH_STATUSES.success;
    },
    loadAccountsFail(state) {
      state.currentUser.status = FETCH_STATUSES.failure;
    },
    changeAccounts(state, { payload }) {
      state.accounts.data = payload;
    },
    changeSelectedAccount(state, { payload }) {
      state.accounts.selected = payload;
    },
    loadSavedFundsStart(state) {
      state.savedFunds.status = FETCH_STATUSES.loading;
    },
    loadSavedFundsSuccess(state, { payload }) {
      state.savedFunds.status = FETCH_STATUSES.success;
      state.savedFunds.data = payload.savedFunds;
    },
    loadSavedFundsFail(state) {
      state.savedFunds.status = FETCH_STATUSES.failure;
    },
    loadConfigurationsStart(state) {
      state.configurations.status = FETCH_STATUSES.loading;
    },
    loadConfigurationsSuccess(state, { payload }) {
      state.configurations.status = FETCH_STATUSES.success;
      state.configurations.data = payload;
    },
    loadConfigurationsRejected(state, { payload }) {
      state.configurations.status = FETCH_STATUSES.failure;
      state.configurations.data = {};
      state.errors = payload;
    },
    createAdvisorHouseholdStart(state) {
      state.advisorHousehold.status = FETCH_STATUSES.loading;
    },
    createAdvisorHouseholdSuccess(state) {
      state.advisorHousehold.status = FETCH_STATUSES.success;
    },
    createAdvisorHouseholdRejected(state, { payload }) {
      state.advisorHousehold.status = FETCH_STATUSES.failure;
      state.advisorHousehold.errors = payload;
    },
    makeCurrentAdvisorHouseholdStart(state) {
      state.advisorHousehold.status = FETCH_STATUSES.loading;
    },
    makeCurrentAdvisorHouseholdSuccess(state) {
      state.advisorHousehold.status = FETCH_STATUSES.success;
    },
    makeCurrentAdvisorHouseholdRejected(state, { payload }) {
      state.advisorHousehold.status = FETCH_STATUSES.failure;
      state.advisorHousehold.errors = payload;
    },
    loadAdvisorHouseholdStart(state) {
      state.loadAdvisorHousehold.status = FETCH_STATUSES.loading;
    },
    loadAdvisorHouseholdSuccess(state, { payload }) {
      state.loadAdvisorHousehold.status = FETCH_STATUSES.success;
      state.loadAdvisorHousehold.data = payload;
    },
    loadAdvisorHouseholdRejected(state, { payload }) {
      state.loadAdvisorHousehold.status = FETCH_STATUSES.failure;
      state.loadAdvisorHousehold.errors = payload;
    },
    updateAdvisorHouseholdStart(state) {
      state.updateAdvisorHousehold.status = FETCH_STATUSES.loading;
    },
    updateAdvisorHouseholdSuccess(state) {
      state.updateAdvisorHousehold.status = FETCH_STATUSES.success;
    },
    updateAdvisorHouseholdRejected(state, { payload }) {
      state.updateAdvisorHousehold.status = FETCH_STATUSES.failure;
      state.updateAdvisorHousehold.errors = payload;
    },
  },
});

export const {
  signInStart,
  signInSuccess,
  signInFail,
  signOut,
  loadCurrentUserStart,
  loadCurrentUserSuccess,
  loadCurrentUserFail,
  turnOnLoader,
  turnOffLoader,
  clearLoaderCaption,
  changeSelectedCustomer,
  changeShouldRedirectOnSelectedAccountChange,
  changeCustomersListData,
  updateSelectedCustomer,
  changeShouldShowRefreshReportModal,
  loadAccountsStart,
  loadAccountsSuccess,
  loadAccountsFail,
  changeAccounts,
  changeSelectedAccount,
  loadSavedFundsStart,
  loadSavedFundsSuccess,
  loadSavedFundsFail,
  loadConfigurationsStart,
  loadConfigurationsSuccess,
  loadConfigurationsRejected,
  createAdvisorHouseholdStart,
  createAdvisorHouseholdSuccess,
  createAdvisorHouseholdRejected,
  makeCurrentAdvisorHouseholdStart,
  makeCurrentAdvisorHouseholdSuccess,
  makeCurrentAdvisorHouseholdRejected,
  loadAdvisorHouseholdStart,
  loadAdvisorHouseholdSuccess,
  loadAdvisorHouseholdRejected,
  updateAdvisorHouseholdStart,
  updateAdvisorHouseholdSuccess,
  updateAdvisorHouseholdRejected,
} = appSlice.actions;

export default appSlice.reducer;

export const useAppActions = () => {
  const dispatch = useDispatch();

  const loadCurrentUser = () => {
    dispatch(loadCurrentUserStart());

    return CurrentUserRepository.show()
      .then(({ data }) => {
        dispatch(loadCurrentUserSuccess(data));
        return Promise.resolve(data);
      })
      .catch(e => {
        dispatch(loadCurrentUserFail());
        return Promise.reject(e);
      });
  };

  const signIn = ({ email, password }) => {
    dispatch(signInStart());
    return AuthRepository.create({ user: { email, password } })
      .then(() => loadCurrentUser())
      .catch(() => dispatch(signInFail()));
  };

  const signOutAction = () => {
    return AuthRepository.delete().then(() => dispatch(signOut()));
  };

  const stopImpersonating = () => {
    return UsersRepository.stopImpersonating();
  };

  const showLoader = caption => {
    dispatch(turnOnLoader(caption));
  };

  const hideLoader = () => {
    dispatch(turnOffLoader());
  };

  const onLoaderExit = () => {
    dispatch(clearLoaderCaption());
  };

  const changeSelectedCustomerAction = customer => {
    dispatch(changeSelectedCustomer(customer));
    return Promise.resolve(customer);
  };

  const changeShouldRedirectOnSelectedAccountChangeAction = shouldRedirect => {
    dispatch(changeShouldRedirectOnSelectedAccountChange(shouldRedirect));
    return Promise.resolve();
  };

  const loadCustomers = async params => {
    const { data } = await CustomersRepository.index(params);
    return data;
  };

  const updateCustomersList = async params => {
    const { customers } = await loadCustomers({ ...params, perPage: 1000 });
    const sortedCustomers = UserPresenter.sortedCustomers(customers);
    const customersList = sortedCustomers.map(c => ({
      ...c,
      key: c.id,
      value: `${CustomerPresenter.fullName(c)} (${CustomerPresenter.displayHouseholdName(c)})`,
    }));
    dispatch(changeCustomersListData(customersList));

    return customersList;
  };

  const hideRefreshReportModal = () => {
    dispatch(changeShouldShowRefreshReportModal(false));
  };

  const showRefreshReportModal = () => {
    dispatch(changeShouldShowRefreshReportModal(true));
  };

  const changeSelectedAccountAction = account => {
    dispatch(changeSelectedAccount(account));
  };

  const loadAdvisorCustomerAccounts = async params => {
    dispatch(loadAccountsStart());
    try {
      const {
        data: { accounts },
      } = await CustomerAccountsRepository.index({ perPage: 10000, params });
      const accountsList = AccountPresenter.asList(accounts);
      dispatch(loadAccountsSuccess());

      return accountsList;
    } catch (error) {
      dispatch(loadAccountsFail());

      return error;
    }
  };

  const changeAccountsAction = accounts => {
    dispatch(changeAccounts(accounts));
  };

  const loadAccounts = async params => {
    dispatch(loadAccountsStart());
    try {
      const {
        data: { accounts },
      } = await AccountsRepository.index(params);
      const accountsList = AccountPresenter.asList(accounts);
      dispatch(loadAccountsSuccess(accountsList));

      return accountsList;
    } catch (error) {
      dispatch(loadAccountsFail());

      return error;
    }
  };

  const loadCustomerSavedFunds = async () => {
    dispatch(loadSavedFundsStart());

    try {
      const { data } = await SavedFundsRepository.index();
      dispatch(loadSavedFundsSuccess(data));
    } catch (error) {
      dispatch(loadSavedFundsFail());
    }
  };

  const createCustomerSavedFund = async params => {
    await SavedFundsRepository.create(params);
    loadCustomerSavedFunds();
  };

  const destroyCustomerSavedFund = async id => {
    await SavedFundsRepository.destroy(id);
    loadCustomerSavedFunds();
  };

  const loadAdvisorSavedFunds = async customerId => {
    dispatch(loadSavedFundsStart());

    try {
      const { data } = await AdvisorSavedFundsRepository.index({ q: { userIdEq: customerId } });
      dispatch(loadSavedFundsSuccess(data));
    } catch (error) {
      dispatch(loadSavedFundsFail());
    }
  };

  const createAdvisorSavedFund = async params => {
    const { userId } = params;
    await AdvisorSavedFundsRepository.create(params);
    loadAdvisorSavedFunds(userId);
  };

  const destroyAdvisorSavedFund = async (fundId, userId) => {
    await AdvisorSavedFundsRepository.destroy(fundId, { userId });
    loadAdvisorSavedFunds(userId);
  };

  const createAdvisorHousehold = async params => {
    dispatch(createAdvisorHouseholdStart());
    showLoader('Loading');
    try {
      await HouseholdRepository.create(params);
      dispatch(createAdvisorHouseholdSuccess());
      const { user } = await loadCurrentUser();
      const householdId = UserPresenter.householdId(user);
      const list = await updateCustomersList();
      const customerIds = list.map(customer => customer.id);
      const accountsList = await loadAdvisorCustomerAccounts({ q: { userAccountsUserIdIn: customerIds } });
      const initialSelectedCustomer = list.find(customer => customer.householdId === householdId);

      if (initialSelectedCustomer) {
        loadAdvisorSavedFunds(initialSelectedCustomer.id);
      }

      dispatch(changeSelectedCustomer(initialSelectedCustomer));
      dispatch(changeAccounts(accountsList));
      hideLoader();
    } catch (error) {
      dispatch(createAdvisorHouseholdRejected(error));
      throw error;
    }
  };

  const makeCurrentAdvisorHousehold = async (householdId, params) => {
    dispatch(makeCurrentAdvisorHouseholdStart());

    try {
      await HouseholdRepository.makeCurrent(householdId, params);
      dispatch(makeCurrentAdvisorHouseholdSuccess());
      loadCurrentUser();
    } catch (error) {
      dispatch(makeCurrentAdvisorHouseholdRejected(error));
      throw error;
    }
  };

  const loadAdvisorHousehold = async householdId => {
    dispatch(loadAdvisorHouseholdStart());

    try {
      const data = await HouseholdRepository.index(householdId);
      dispatch(loadAdvisorHouseholdSuccess());

      return data;
    } catch (error) {
      dispatch(loadAdvisorHouseholdRejected(error));
      return error;
    }
  };

  const updateAdvisorHousehold = async (householdId, params) => {
    dispatch(updateAdvisorHouseholdStart());
    showLoader('Loading');

    try {
      await HouseholdRepository.update(householdId, params);
      dispatch(updateAdvisorHouseholdSuccess());
      hideLoader();
    } catch (error) {
      dispatch(updateAdvisorHouseholdRejected(error));
      throw error;
    }
  };

  const loadConfigurations = async () => {
    dispatch(loadConfigurationsStart());

    try {
      const {
        data: { configuration },
      } = await ConfigurationsRepository.show();
      dispatch(loadConfigurationsSuccess(configuration));

      return configuration;
    } catch (error) {
      dispatch(loadConfigurationsRejected(error));
      return error;
    }
  };

  return {
    signIn,
    signOut: signOutAction,
    loadCurrentUser,
    showLoader,
    hideLoader,
    onLoaderExit,
    stopImpersonating,
    changeSelectedCustomer: changeSelectedCustomerAction,
    changeShouldRedirectOnSelectedAccountChange: changeShouldRedirectOnSelectedAccountChangeAction,
    updateCustomersList,
    hideRefreshReportModal,
    showRefreshReportModal,
    loadAdvisorCustomerAccounts,
    changeAccounts: changeAccountsAction,
    loadAccounts,
    changeSelectedAccount: changeSelectedAccountAction,
    loadCustomerSavedFunds,
    createCustomerSavedFund,
    destroyCustomerSavedFund,
    loadAdvisorSavedFunds,
    createAdvisorSavedFund,
    destroyAdvisorSavedFund,
    loadConfigurations,
    createAdvisorHousehold,
    makeCurrentAdvisorHousehold,
    loadAdvisorHousehold,
    updateAdvisorHousehold,
  };
};
