import React from 'react';
import { isNil } from 'ramda';
import PropTypes from 'prop-types';

import CardContent from './components/CardContent';
import useStyles from './useStyles';

const Card = props => {
  const { title, value, icon, onClick, isLoading, secondTitle, secondValue } = props;
  const classes = useStyles();
  const isSecondTitleExists = !isNil(secondTitle);
  return (
    <div className={classes.root} onClick={onClick} role="presentation">
      {icon}
      <CardContent title={title} value={value} isLoading={isLoading} />
      {isSecondTitleExists && <CardContent title={secondTitle} value={secondValue} isLoading={isLoading} />}
    </div>
  );
};

Card.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  icon: PropTypes.shape(),
  onClick: PropTypes.func,
  isLoading: PropTypes.bool.isRequired,
  secondTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  secondValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Card;
