export default {
  MuiLinearProgress: {
    barColorPrimary: {
      backgroundColor: '#449c7f',
    },
    bar: {
      borderRadius: 11,
    },
  },
};
