import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    position: 'absolute',
    top: 10,
    right: 10,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  popover: {
    pointerEvents: 'none',
  },
  button: {
    '&:not(:last-child)': {
      marginRight: 5,
    },
  },
  buttonIcon: {
    width: 24,
    height: 24,
    display: 'block',
  },
  helperPopup: {
    width: 300,
    boxShadow: '-2px 2px 10px 0 rgba(0,0,0,0.10)',
    border: '1px solid #e1e1e1',
    borderRadius: 10,
    padding: '3px 15px',
    marginTop: 10,
    marginLeft: 20,
  },
}));

export default useStyles;
