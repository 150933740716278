import PropTypes from 'prop-types';
import { sortBy } from 'ramda';

import Presenter from 'utils/PropTypesPresenter';

export default new Presenter(
  {
    id: PropTypes.number,
    name: PropTypes.string,
    extendedDescription: PropTypes.string,
    displayOrder: PropTypes.number,
  },
  {
    sortedByDisplayOrder(flags) {
      const getDisplayOrder = flag => this.displayOrder(flag);

      return sortBy(getDisplayOrder, flags);
    },
  },
);
