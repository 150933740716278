import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  theme => ({
    root: {
      position: 'relative',
    },
    menu: {
      position: 'absolute',
      top: 0,
      left: 0,
      border: '1px solid #000',
      borderRadius: 5,
      backgroundColor: '#fff',
      zIndex: 2,
    },
    button: {
      '& > span': {
        textTransform: 'uppercase',
      },
    },
    filterRoot: {
      padding: '4px 11px',
      borderRadius: 5,
      border: '1px solid #000',
    },
    filterLabel: {
      display: 'flex',
      alignItems: 'center',
      fontFamily: theme.typography.font.ttHovesPro,
      fontWeight: 600,
      letterSpacing: '0.01em',
      fontSize: 12,
      color: '#232020',
    },
    filterIconRoot: {
      width: 19,
      height: 11,
      marginLeft: 10,
    },
    title: {
      display: 'flex',
      padding: '7px 11px',
      lineHeight: 1.75,
      alignItems: 'center',
      justifyContent: 'space-between',
      cursor: 'pointer',
    },
    name: {
      display: 'flex',
      alignItems: 'center',
      fontFamily: theme.typography.font.ttHovesPro,
      fontWeight: 600,
      fontSize: 12,
      letterSpacing: '0.01em',
      color: '#232020',
      textTransform: 'uppercase',
    },
    list: {
      listStyleType: 'none',
      padding: '0 11px',
    },
    item: {
      display: 'flex',
      alignItems: 'center',
      whiteSpace: 'nowrap',
      marginBottom: 18,
    },
    itemName: {
      marginLeft: 9,
      fontFamily: theme.typography.font.ttHovesPro,
      fontSize: 12,
      letterSpacing: '0.01em',
      fontWeight: 300,
      color: '#232020',
    },
  }),
  { name: 'PillarsFilter' },
);

export default useStyles;
