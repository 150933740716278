import { useSelector } from 'react-redux';

import { useCustomerLensCreationActions } from 'store/CustomerLensCreationSlice';
import getFetchStatus from 'utils/getFetchStatus';

export default () => {
  const {
    loadCustomerLens,
    resetCustomerLensState,
    createCustomerLens,
    createAdvisorCustomerLens,
    updateCustomerLens,
    changeCustomerLens,
    resetCustomerLens,
  } = useCustomerLensCreationActions();

  const customerLens = useSelector(state => state.CustomerLensCreationSlice.customerLens.data);
  const customerLensStatus = useSelector(state => state.CustomerLensCreationSlice.customerLens.status);
  const customerLensStatuses = getFetchStatus(customerLensStatus);

  return {
    loadCustomerLens,
    resetCustomerLensState,
    createCustomerLens,
    createAdvisorCustomerLens,
    updateCustomerLens,
    changeCustomerLens,
    resetCustomerLens,
    customerLens,
    customerLensStatuses,
  };
};
