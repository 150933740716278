import Routes from 'routes/ApiRoutes';
import FetchHelpers from 'utils/FetchHelpers';

export default {
  authTokens(params) {
    const path = Routes.authTokensAdminApiV1PartnersPath();

    return FetchHelpers.get(path, params);
  },
};
