import Routes from 'routes/ApiRoutes';
import FetchHelpers from 'utils/FetchHelpers';

export default {
  create(params) {
    const path = Routes.adminApiV1FundImportRequestsPath();

    return FetchHelpers.postMultipartFormData(path, params);
  },

  show(importRequestId) {
    const path = Routes.adminApiV1FundImportRequestPath(importRequestId);

    return FetchHelpers.get(path);
  },

  update(importRequestId, params) {
    const path = Routes.finishAdminApiV1FundImportRequestPath(importRequestId);

    return FetchHelpers.put(path, params);
  },
};
