export default {
  user_type: 'Advisor',
  user_id: '100',
  institution_id: '2',
  advisor_name: 'John and Jane Advisor',
  advisor_id: '100',
  household_id: '100',
  clients: [
    {
      client_id: '101',
      client_name: 'John',
      birth_year: '1975',
    },
    {
      client_id: '102',
      client_name: 'Jane',
      birth_year: '1978',
    },
  ],
  accounts: [
    {
      account_id: '101',
      account_owner: ['101'],
      account_name: 'John Account',
      total_account_value: '70000.0',
      tickers: [
        {
          ticker: 'MMM',
          ticker_amount: '20000.0',
        },
        {
          ticker: 'FFF',
          ticker_amount: '30000.0',
        },
      ],
      holdings: [
        {
          holding_name: 'HoldingName1',
          holding_amount: '20000.0',
        },
      ],
      asset_classes: [
        {
          asset_class_id: '3',
          asset_class_percent: '100',
        },
      ],
    },
    {
      account_id: '102',
      account_owner: ['102'],
      account_name: 'Jane Account',
      total_account_value: '80000.0',
      tickers: [
        {
          ticker: 'AAA',
          ticker_amount: '50000.0',
        },
        {
          ticker: 'WWW',
          ticker_amount: '10000.0',
        },
        {
          ticker: 'GGG',
          ticker_amount: '10000.0',
        },
      ],
      holdings: [
        {
          holding_name: 'HoldingName2',
          holding_amount: '20000.0',
        },
      ],
      asset_classes: [
        {
          asset_class_id: '3',
          asset_class_percent: '100',
        },
      ],
    },
  ],
};
