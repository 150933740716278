import Routes from 'routes/ApiRoutes';
import FetchHelpers from 'utils/FetchHelpers';

export default {
  index() {
    const path = Routes.apiV1CustomerSavedFundsPath();
    return FetchHelpers.get(path);
  },
  create(params) {
    const path = Routes.apiV1CustomerSavedFundsPath();
    return FetchHelpers.post(path, params);
  },
  destroy(id) {
    const path = Routes.apiV1CustomerSavedFundPath(id);
    return FetchHelpers.delete(path);
  },
};
