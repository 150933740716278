import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  pillar: {
    display: 'flex',
    alignItems: 'center',
  },
  name: {
    marginLeft: 16,
    fontWeight: 300,
    fontSize: 17,
    letterSpacing: '0.01em',
  },
  chipRoot: {
    borderColor: '#fff',
    height: 30,
  },
  chipLabel: {
    color: '#fff',
  },
}));

export default useStyles;
