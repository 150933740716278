export default {
  MuiAccordionSummary: {
    root: {
      padding: 0,
      minHeight: 'auto',
      '&.Mui-expanded': {
        minHeight: 'auto',
      },
    },
    content: {
      margin: 0,
      '&.Mui-expanded': {
        margin: 0,
      },
    },
  },
};
