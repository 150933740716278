import React from 'react';
import PropTypes from 'prop-types';

import useStyles from './useStyles';
import check from './images/check.svg';

import Button from '@material-ui/core/Button';

const SaveReportConfirmation = props => {
  const { onClose, onConfirm, children } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.title}>Save for Client?</div>
      <div className={classes.description}>{children}</div>

      <img src={check} alt="check icon" width="249" height="248" className={classes.icon} />
      <div className={classes.actions}>
        <div className={classes.buttonWrapper}>
          <Button variant="outlined" size="small" onClick={onConfirm} classes={{ root: classes.buttonRoot }}>
            Yes
          </Button>
        </div>
        <div className={classes.buttonWrapper}>
          <Button variant="text" size="small" onClick={onClose} classes={{ root: classes.buttonRoot }}>
            No
          </Button>
        </div>
      </div>
    </div>
  );
};

SaveReportConfirmation.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default SaveReportConfirmation;
