import PropTypes from 'prop-types';
import * as React from 'react';
import MuiLink from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';
import { stringify } from 'query-string';
import { isNil } from 'ramda';

const LinkToUsers = props => {
  const { record } = props;

  if (isNil(record)) {
    return null;
  }

  return (
    <MuiLink
      component={RouterLink}
      color="secondary"
      to={{
        pathname: '/users',
        search: stringify({
          page: 1,
          perPage: 25,
          order: 'ASC',
          filter: JSON.stringify({ partnerIdEq: record.id }),
        }),
      }}
    >
      Users
    </MuiLink>
  );
};
LinkToUsers.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
};

export default LinkToUsers;
