import PropTypes from 'prop-types';
import Presenter from 'utils/PropTypesPresenter';

const STATES = {
  inProgress: 'in_progress',
  error: 'error',
  finished: 'finished',
};

export default new Presenter(
  {
    id: PropTypes.number,
    state: PropTypes.string,
    customerPortfolioAnalysesBatchId: PropTypes.number,
  },
  {
    isInProgress(item) {
      return this.state(item) === STATES.inProgress;
    },
    isCrashed(item) {
      return this.state(item) === STATES.error;
    },
    isFinished(item) {
      return this.state(item) === STATES.finished;
    },
  },
);
