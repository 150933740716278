export default {
  MuiTab: {
    root: {
      minWidth: 'auto',
      minHeight: 'auto',
      paddingLeft: 11,
      paddingRight: 12,
      marginRight: 15,
      paddingBottom: 6,
    },
  },
};
