export default {
  MuiTabs: {
    root: {
      minHeight: 38,
      '& > $scrollButtons:first-child:not(:empty)': {
        position: 'relative',
        '&:before': {
          top: 0,
          width: 20,
          left: 0,
          height: 20,
          content: '""',
          position: 'absolute',
          marginTop: 10,
          marginLeft: 39,
          backgroundImage: 'linear-gradient(-90deg, rgba(255,255,255,0.00) 0%, #fff 50%)',
          zIndex: 4,
        },
      },
      '& > $scrollButtons:last-child:not(:empty)': {
        position: 'relative',
        '&:before': {
          content: '""',
          backgroundImage: 'linear-gradient(90deg, rgba(255,255,255,0.00) 0%, #FFFFFF 50%)',
          width: 20,
          height: 20,
          position: 'absolute',
          right: 0,
          top: 0,
          marginTop: 10,
          marginRight: 31,
        },
      },
    },
    indicator: {
      backgroundColor: '#000',
      height: 3,
      bottom: 0,
    },
    scrollButtons: {
      '& > svg': {
        fontSize: '1.75rem',
      },
      '& :last-child': {
        transform: 'translate(-3px, -1px)',
      },
    },
    scroller: {
      borderBottom: '1px solid #d8d8d8',
    },
  },
};
