import PropTypes from 'prop-types';

import Presenter from 'utils/PropTypesPresenter';

export default new Presenter(
  {
    id: PropTypes.number,
    createdAt: PropTypes.string,
    customerPortfolioAnalysesBatchId: PropTypes.number,
    reflectionMatchScore: PropTypes.number,
    state: PropTypes.string,
  },
  {},
);
