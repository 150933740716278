import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  () => ({
    actions: {
      display: 'flex',
      justifyContent: 'center',
      margin: '30px 0 50px 0',
    },
  }),
  { name: 'CustomerWelcome' },
);

export default useStyles;
