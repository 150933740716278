import React, { useState, useEffect } from 'react';
import { WithPermissions } from 'react-admin';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import DatePicker from 'admin/components/DatePicker';
import DateSelect from 'admin/components/DateSelect';

import SelectedActualLensChart from './components/SelectedActualLensChart';
import CustomersWithMoreThanOneLensChart from './components/CustomersWithMoreThanOneLensChart';
import CustomersHavingPortfolioAnalysesChart from './components/CustomersHavingPortfolioAnalysesChart';
import AccountScoreabilityChart from './components/AccountScoreabilityChart';
import CustomersMatchScoresChart from './components/CustomersMatchScoresChart';
import PillarAlignmentDistributionChart from './components/PillarAlignmentDistributionChart';
import CategoryAlignmentDistributionChart from './components/CategoryAlignmentDistributionChart';
import ActiveUsersChart from './components/ActiveUsersChart';
import UsersSessionAbandonChart from './components/UsersSessionAbandonChart';
import AdvisorsSavesChart from './components/AdvisorsSavesChart';

import { useKpi } from 'admin/hooks/useKpi';

import DateFnsUtils from '@date-io/date-fns';
import { subDays, subMonths } from 'date-fns';

import useStyles from './useStyles';

const Kpi = () => {
  const classes = useStyles();

  const { handleKpiLoad, handleKpiExport, data, kpiStatuses } = useKpi();

  const [lastSignInDateStart, setlastSignInDateStart] = useState(subDays(new Date(), 30));
  const [lastSignInDateEnd, setlastSignInDateEnd] = useState(new Date());
  const [creationDateStart, setCreationDateStart] = useState(subDays(new Date(), 30));
  const [creationDateEnd, setCreationDateEnd] = useState(new Date());
  const [creationPeriod, setCreationPeriod] = useState('Month');
  const [activityPeriod, setActivityPeriod] = useState('Month');
  const [fromDate, setFromDate] = useState(subMonths(new Date(), 2));
  const [period, setPeriod] = useState('3 months');
  const toDate = new Date();

  useEffect(() => {
    handleKpiLoad(lastSignInDateStart, lastSignInDateEnd, creationDateStart, creationDateEnd, fromDate, toDate);
  }, [lastSignInDateStart, lastSignInDateEnd, creationDateStart, creationDateEnd, fromDate]);

  const handleExportData = template => {
    handleKpiExport(
      lastSignInDateStart,
      lastSignInDateEnd,
      creationDateStart,
      creationDateEnd,
      fromDate,
      toDate,
      template,
    );
  };

  const handlelastSignInDateStartChange = date => {
    setlastSignInDateStart(date);
    setActivityPeriod('');
  };

  const handlelastSignInDateEndChange = date => {
    setlastSignInDateEnd(date);
    setActivityPeriod('');
  };

  const handleCreationDateStartChange = date => {
    setCreationDateStart(date);
    setCreationPeriod('');
  };

  const handleCreationDateEndChange = date => {
    setCreationDateEnd(date);
    setCreationPeriod('');
  };

  const handlelastSignInDateStartSelect = date => {
    setlastSignInDateStart(date);
  };

  const handleCreationDateStartSelect = date => {
    setCreationDateStart(date);
  };

  const handleFromDateChange = date => {
    setFromDate(date);
    setPeriod('');
  };

  if (kpiStatuses.isLoading) {
    return <div className={classes.loading}>Loading…</div>;
  }

  return (
    <div className={classes.content}>
      <Container maxWidth="lg" className={classes.container}>
        <DateSelect
          period={period}
          toDate={toDate}
          onChangeFromDate={handleFromDateChange}
          onChangePeriod={setPeriod}
        />
        <Grid classes={{ root: classes.chartsContainer }} container spacing={3}>
          <Grid item xs={6}>
            <ActiveUsersChart data={data.monthlyActiveUsers} onExport={handleExportData} />
          </Grid>
          <Grid item xs={6}>
            <UsersSessionAbandonChart data={data.monthlyUsersSessionAbandoned} onExport={handleExportData} />
          </Grid>
          <Grid item xs={6}>
            <AdvisorsSavesChart data={data.monthlyAdvisorsSaves} onExport={handleExportData} />
          </Grid>
        </Grid>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <DatePicker
                title="Period of last activity:"
                period={activityPeriod}
                onChangePeriod={setActivityPeriod}
                startDate={lastSignInDateStart}
                endDate={lastSignInDateEnd}
                onChangeStartDate={handlelastSignInDateStartChange}
                onChangeEndDate={handlelastSignInDateEndChange}
                onChangeSelect={handlelastSignInDateStartSelect}
              />
            </Grid>
            <Grid item xs={6}>
              <DatePicker
                title="Period of creation:"
                period={creationPeriod}
                onChangePeriod={setCreationPeriod}
                startDate={creationDateStart}
                endDate={creationDateEnd}
                onChangeStartDate={handleCreationDateStartChange}
                onChangeEndDate={handleCreationDateEndChange}
                onChangeSelect={handleCreationDateStartSelect}
              />
            </Grid>
          </Grid>
        </MuiPickersUtilsProvider>
        <Grid classes={{ root: classes.chartsContainer }} container spacing={3}>
          <Grid item xs={6}>
            <SelectedActualLensChart data={data.selectedActualLenses} onExport={handleExportData} />
          </Grid>
          <Grid item xs={6}>
            <CustomersWithMoreThanOneLensChart data={data.usersWithMoreThanOneLens} onExport={handleExportData} />
          </Grid>
          <Grid item xs={6}>
            <CustomersHavingPortfolioAnalysesChart
              data={data.usersHavingPortfolioAnalysis}
              onExport={handleExportData}
            />
          </Grid>
          <Grid item xs={6}>
            <AccountScoreabilityChart data={data.accountScoreability} onExport={handleExportData} />
          </Grid>
          <Grid item xs={6}>
            <CustomersMatchScoresChart data={data.averageMatchScore} onExport={handleExportData} />
          </Grid>
          <Grid item xs={6}>
            <PillarAlignmentDistributionChart data={data.pillarsAlignment} onExport={handleExportData} />
          </Grid>
          <Grid item xs={12}>
            <CategoryAlignmentDistributionChart data={data.categoriesAlignment} onExport={handleExportData} />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

const KpiWithPermissions = props => {
  const { match } = props;

  return <WithPermissions authParams={{ key: match.path }} render={() => <Kpi />} />;
};

export default KpiWithPermissions;
