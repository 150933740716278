import React from 'react';
import { ListActions, useListContext, EditButton, ListBase, DeleteButton, Title } from 'react-admin';
import { values, isNil, dissoc } from 'ramda';
import { Card, Box, TextField as MuiTextField } from '@material-ui/core';
import { ExpandMore, ChevronRight } from '@material-ui/icons';
import { TreeView, TreeItem } from '@material-ui/lab';
import PropTypes from 'prop-types';

import useStyles from './useStyles';

const SubTree = ({ root, getChildNodes }) => {
  const childNodes = getChildNodes(root);
  const classes = useStyles();
  return (
    <TreeItem
      nodeId={`${root.id}`}
      className={classes.treeItem}
      label={
        <Box className={classes.treeItemContent}>
          <MuiTextField className={classes.field} label="id" value={root.id} />
          <MuiTextField className={classes.field} label="Code" value={root.code} />
          <MuiTextField className={classes.field} label="Name" value={root.name} />
          <Box className={classes.buttons}>
            <EditButton record={root} basePath="asset_class_categories" />
            <DeleteButton record={root} basePath="asset_class_categories" />
          </Box>
        </Box>
      }
    >
      {childNodes.map(node => (
        <SubTree key={node.id} root={node} getChildNodes={getChildNodes} />
      ))}
    </TreeItem>
  );
};

const Tree = () => {
  const { data: d, defaultTitle } = useListContext();
  const data = dissoc(undefined, d);

  const roots = data ? values(data).filter(node => isNil(node.parent_id)) : [];

  const getChildNodes = root => values(data).filter(node => node.parent_id === root.id);

  return (
    <TreeView defaultCollapseIcon={<ExpandMore />} defaultExpandIcon={<ChevronRight />} disableSelection>
      <Title defaultTitle={defaultTitle} />
      {roots.map(root => (
        <SubTree key={root.id} root={root} getChildNodes={getChildNodes} />
      ))}
    </TreeView>
  );
};

const AssetClassCategoryList = props => {
  const classes = useStyles();
  return (
    <ListBase {...props}>
      <ListActions />
      <Box maxWidth="20em" marginTop="1em">
        <Card className={classes.card}>
          <Tree />
        </Card>
      </Box>
    </ListBase>
  );
};

SubTree.propTypes = {
  getChildNodes: PropTypes.func.isRequired,
  root: PropTypes.shape().isRequired,
};

export default AssetClassCategoryList;
