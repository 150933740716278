import Routes from 'routes/ApiRoutes';
import FetchHelpers from 'utils/FetchHelpers';

export default {
  create(params) {
    const path = Routes.apiV1SessionPath();

    return FetchHelpers.post(path, params);
  },

  delete() {
    const path = Routes.apiV1SessionPath();

    return FetchHelpers.delete(path);
  },
};
