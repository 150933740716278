import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const FilterIcon = props => {
  return (
    <SvgIcon {...props}>
      <g stroke="#000" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
        <path d="M4.5 1a1.5 1.5 0 11-.001 3.001A1.5 1.5 0 014.5 1zM13.5 7a1.5 1.5 0 11-.001 3.001A1.5 1.5 0 0113.5 7zM1 2.5h2M7 2.5h11M12 8.5H1M16 8.5h2" />
      </g>
    </SvgIcon>
  );
};

export default FilterIcon;
