import PropTypes from 'prop-types';

import Presenter from 'utils/PropTypesPresenter';

export default new Presenter(
  {
    id: PropTypes.number,
    amount: PropTypes.string,
    name: PropTypes.string,
    ticker: PropTypes.string,
    customerFundAnalyses: PropTypes.arrayOf(PropTypes.shape()),
  },
  {},
);
