import React from 'react';
import PropTypes from 'prop-types';

import { useParams, useRouteMatch } from 'react-router-dom';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import clsx from 'clsx';
import { isNil } from 'ramda';

import AppRoutes from 'routes/AppRoutes';

import Ball from 'components/Ball';
import Link from 'components/Link';

import CustomerFundsReportPresenter from 'presenters/CustomerFundsReportPresenter';

import { objectToQueryString } from 'utils/urlUtils';

import useStyles from './useStyles';

const Side = props => {
  const { customerFundsReport, isMultipleAccountMode, selectedAccountIds } = props;
  const classes = useStyles();

  const routeParams = useParams();
  const customerLensId = Number(routeParams.id);
  const search = objectToQueryString({ account_ids: selectedAccountIds });
  const backLink = isMultipleAccountMode
    ? AppRoutes.multipleAccountsPortfolioAnalysisPath(customerLensId)
    : AppRoutes.portfolioAnalysisPath(customerLensId);

  const ballClassNames = isActive => clsx(classes.ball, isActive && classes.active);

  const renderCompaniesScoredBall = () => {
    const route = isMultipleAccountMode
      ? AppRoutes.multipleAccountsPortfolioAnalysisCompaniesScoredPath(customerLensId)
      : AppRoutes.portfolioAnalysisCompaniesScoredPath(customerLensId);

    const isActive = !isNil(useRouteMatch(route));
    const classNames = ballClassNames(isActive);

    return (
      <div className={classNames}>
        <Link to={{ pathname: route, search }} disabled={isActive}>
          <Ball
            score={CustomerFundsReportPresenter.formattedScoredCompaniesCount(customerFundsReport)}
            totalScore={CustomerFundsReportPresenter.formattedTotalCompaniesCount(customerFundsReport)}
            description="Securities scored"
          />
        </Link>
      </div>
    );
  };

  const renderValueHeldBall = () => {
    const route = isMultipleAccountMode
      ? AppRoutes.multipleAccountsPortfolioAnalysisPathPercentValueHeldPath(customerLensId)
      : AppRoutes.portfolioAnalysisPathPercentValueHeldPath(customerLensId);
    const isActive = !isNil(useRouteMatch(route));
    const classNames = ballClassNames(isActive);

    return (
      <div className={classNames}>
        <Link to={{ pathname: route, search }} disabled={isActive}>
          <Ball
            value={CustomerFundsReportPresenter.scoredAmountPercents(customerFundsReport)}
            description="% dollar value held by securities scored"
          />
        </Link>
      </div>
    );
  };

  const renderIssuesDefinedBall = () => {
    const route = isMultipleAccountMode
      ? AppRoutes.multipleAccountsPortfolioAnalysisIssuesDefinedPath(customerLensId)
      : AppRoutes.portfolioAnalysisIssuesDefinedPath(customerLensId);
    const isActive = !isNil(useRouteMatch(route));
    const classNames = ballClassNames(isActive);

    return (
      <div className={classNames}>
        <Link to={{ pathname: route, search }} disabled={isActive}>
          <Ball
            score={CustomerFundsReportPresenter.formattedCompaniesWithAttachedFlagsCount(customerFundsReport)}
            totalScore={CustomerFundsReportPresenter.formattedScoredCompaniesCount(customerFundsReport)}
            description="Scored securities with flags"
          />
        </Link>
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <div className={classes.back}>
        <Link to={{ pathname: backLink, search }}>
          <ArrowBackIosIcon classes={{ root: classes.icon }} />
          Back to Insights
        </Link>
      </div>
      <div className={classes.balls}>
        <div className={classes.ball}>{renderCompaniesScoredBall()}</div>
        <div className={classes.ball}>{renderValueHeldBall()}</div>
        <div className={classes.ball}>{renderIssuesDefinedBall()}</div>
      </div>
    </div>
  );
};

Side.propTypes = {
  customerFundsReport: CustomerFundsReportPresenter.shape().isRequired,
  isMultipleAccountMode: PropTypes.bool,
  selectedAccountIds: PropTypes.arrayOf(PropTypes.number),
};

Side.defaultProps = {
  isMultipleAccountMode: false,
  selectedAccountIds: [],
};

export default Side;
