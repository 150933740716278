import PropTypes from 'prop-types';
import FlagPresenter from 'presenters/FlagPresenter';
import Presenter from 'utils/PropTypesPresenter';

export default new Presenter(
  {
    id: PropTypes.number,
    flagId: PropTypes.number,
    flag: FlagPresenter.shape(),
  },
  {},
);
