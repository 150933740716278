import * as yup from 'yup';
import { EMAIL, REQUIRED } from './errorsText';

export const validationFields = {
  email: yup
    .string()
    .email(EMAIL)
    .required(REQUIRED),
  password: yup.string().required(REQUIRED),
};

export const validationSchema = yup.object().shape({ ...validationFields });

export const initialValues = {
  email: '',
  password: '',
};
