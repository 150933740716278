import React, { useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import ellipsis from 'assets/ellipsis';

import CustomerCategoryValuePresenter from 'presenters/CustomerCategoryValuePresenter';
import FundCategoryAnalysisPresenter from 'presenters/FundCategoryAnalysisPresenter';
import ReflectionReportPresenter from 'presenters/ReflectionReportPresenter';
import CategoryPresenter from 'presenters/CategoryPresenter';
import Drawer from 'components/Drawer';
import CategoryInfo from './components/CategoryInfo';

import useStyles from './useStyles';

const CustomerCategoryValue = props => {
  const { currentReport, customerCategories } = props;

  const classes = useStyles();

  const [isDrawerOpened, setDrawerIsDrawerOpened] = useState(false);

  const handleDrawerOpen = () => {
    setDrawerIsDrawerOpened(true);
  };

  const handleDrawerClose = () => {
    setDrawerIsDrawerOpened(false);
  };

  const { category } = currentReport;
  const customerCategory = customerCategories.find(({ category: { id: categoryId } }) => categoryId === category.id);

  return (
    <tr className={classes.itemData}>
      <td>{CategoryPresenter.name(category)}</td>
      <td className={classes.alignedValue}>{FundCategoryAnalysisPresenter.grouppedWeightText(currentReport)}</td>
      <td className={classes.actionWrapper}>
        <IconButton type="button" className={classes.action} onClick={handleDrawerOpen}>
          <img src={ellipsis} alt="more icon" />
        </IconButton>
      </td>
      <Drawer open={isDrawerOpened} onClose={handleDrawerClose}>
        <CategoryInfo onClose={handleDrawerClose} currentReport={currentReport} customerCategory={customerCategory} />
      </Drawer>
    </tr>
  );
};

CustomerCategoryValue.propTypes = {
  customerCategories: PropTypes.arrayOf(CustomerCategoryValuePresenter.shape()).isRequired,
  currentReport: ReflectionReportPresenter.shape().isRequired,
};

export default CustomerCategoryValue;
