import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import { useDebouncedCallback } from 'use-debounce';
import { isEmpty, reject, is, pluck, isNil, map, omit } from 'ramda';
import { matchSorter } from 'match-sorter';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';
import InputAdornment from '@material-ui/core/InputAdornment';
import { KeyboardDatePicker } from '@material-ui/pickers';

import { initialValues, validationSchema } from 'forms/ManuallyEnteredPortfolioForm';
import { US_DATE_FORMAT } from 'utils/dateUtils';

import { isBlank } from 'utils';

import useStyles from './useStyles';

const COMPANY_TYPE = 'company';
const FUND_TYPE = 'fund';

const PortfolioForm = props => {
  const { onLoadCompanies, onLoadFunds, onCreateUser, onPrepareApp } = props;
  const [tickers, setTickers] = useState([]);
  const [inputTicker, setInputTicker] = useState('');
  const classes = useStyles();

  const handleLoadTickers = async value => {
    const { companies } = await onLoadCompanies(value);
    const { funds } = await onLoadFunds(value);

    const companiesTickers = companies.map(({ ticker, name, id }) => ({ ticker, name, id, type: COMPANY_TYPE }));
    const fundsTickers = funds.map(({ ticker, name, id }) => ({ ticker, name, id, type: FUND_TYPE }));
    setTickers([...companiesTickers, ...fundsTickers]);
  };

  useEffect(() => {
    handleLoadTickers();
  }, []);

  const [debouncedHandleLoadTickers] = useDebouncedCallback(value => {
    handleLoadTickers(value);
  }, 500);

  useEffect(() => {
    debouncedHandleLoadTickers(inputTicker);
  }, [inputTicker]);

  const handleFormSubmit = async ({ birthDate, tickers: formTickers, ...values }) => {
    const normalizedBirthDate = isBlank(birthDate) ? birthDate : birthDate.toDateString();
    const normalizedTickers = map(omit(['id', 'type', 'entityName']), formTickers);
    const normalizedValues = reject(isBlank, { birthDate: normalizedBirthDate, tickers: normalizedTickers, ...values });
    await onCreateUser(normalizedValues);

    onPrepareApp();
  };

  const normalizeTicker = ticker => ticker?.toUpperCase();

  const handleInputChange = (handleChange, setErrors) => event => {
    setErrors({});
    handleChange(event);
  };

  const handleDateChange = (setFieldValue, setErrors) => date => {
    setErrors({});
    setFieldValue('birthDate', date);
  };

  const handleTickerInput = (setErrors, values) => (event, value) => {
    setErrors({});

    const isTickerAdded = pluck('name', values.tickers).includes(value);
    const shouldNotChangeValue = isNil(event) || (isTickerAdded && isEmpty(inputTicker));
    if (shouldNotChangeValue) {
      return;
    }

    setInputTicker(value);
  };

  const newFormTicker = ({ ticker, type, id, name }) => {
    const normalizedTicker = normalizeTicker(ticker);

    const newTicker = {
      name: normalizedTicker,
      amount: '',
      id,
      type,
      entityName: name,
    };

    if (type === COMPANY_TYPE) {
      return { ...newTicker, companyId: id };
    }
    if (type === FUND_TYPE) {
      return { ...newTicker, fundId: id };
    }
    return newTicker;
  };

  const isTickerInForm = (tickersFormState, tickerValue) => {
    const { ticker: tickerName, id: tickerId, type: tickerType } = tickerValue;
    const normalizedTicker = normalizeTicker(tickerName);
    const isTickerCreated = tickersFormState.some(
      ({ name, id, type }) => normalizedTicker === name && type === tickerType && id === tickerId,
    );
    return isTickerCreated || isBlank(tickerName);
  };

  const handleTickerCreate = (setFieldValue, setErrors, tickersFormState) => (_, value) => {
    setErrors({});

    const isTickerCreated = isTickerInForm(tickersFormState, value);
    if (isTickerCreated) {
      return;
    }

    const newTicker = newFormTicker(value);
    const newTickersFormState = [...tickersFormState, newTicker];
    setFieldValue('tickers', newTickersFormState);
    setInputTicker('');
  };

  const handleAutocompleteKeyDown = event => {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  };

  const handleTickerDelete = (setFieldValue, setErrors, tickersFormState, tickerIndex) => () => {
    setErrors({});

    const newTickersFormState = tickersFormState.filter((_, index) => index !== tickerIndex);
    setFieldValue('tickers', newTickersFormState);
  };

  const handleTickerAmountChange = (setFieldValue, setErrors, tickersFormState, tickerIndex) => event => {
    setErrors({});

    const newTickersFormState = tickersFormState.map((ticker, index) => {
      if (index === tickerIndex) {
        return { ...ticker, amount: event.target.value };
      }

      return ticker;
    });
    setFieldValue('tickers', newTickersFormState);
  };

  const getOptionsList = () => {
    if (isEmpty(inputTicker)) {
      return tickers;
    }

    const normalizedTicker = normalizeTicker(inputTicker);
    const isTickerPresented = tickers.some(({ ticker }) => normalizeTicker(ticker) === normalizedTicker);
    return isTickerPresented
      ? tickers
      : [
          { ticker: normalizedTicker },
          ...tickers.filter(({ ticker }) => normalizeTicker(ticker) !== normalizeTicker(inputTicker)),
        ];
  };

  const shouldRenderTickersErrors = errors => is(String, errors.tickers);
  const getTickersHelperText = errors => (shouldRenderTickersErrors(errors) ? errors.tickers : '');
  const filterOptions = (options, { inputValue }) => matchSorter(options, inputValue, { keys: ['ticker', 'name'] });
  const tickerTitle = ({ name, entityName }) => {
    return isNil(entityName) ? `${name}` : `${name}: ${entityName}`;
  };

  return (
    <Formik onSubmit={handleFormSubmit} initialValues={initialValues} validationSchema={validationSchema}>
      {({ handleSubmit, values, errors, handleChange, setErrors, isSubmitting, setFieldValue }) => (
        <form className={classes.form} onSubmit={handleSubmit} noValidate>
          <Field
            name="ticker"
            id="ticker"
            required
            autoHighlight
            disableClearable
            component={Autocomplete}
            classes={{ endAdornment: classes.autocompleteEndAdornment }}
            ChipProps={{ classes: { label: classes.chip } }}
            options={getOptionsList()}
            getOptionLabel={({ ticker }) => ticker}
            filterOptions={filterOptions}
            getOptionSelected={() => true}
            disabled={isSubmitting}
            onChange={handleTickerCreate(setFieldValue, setErrors, values.tickers)}
            onInputChange={handleTickerInput(setErrors, values)}
            inputValue={inputTicker}
            renderOption={({ ticker, name }) => {
              if (isBlank(name)) {
                return <span className={classes.autocompleteOption}>{ticker}</span>;
              }

              return <span className={classes.autocompleteOption}>{`${ticker}: ${name?.toLowerCase()}`}</span>;
            }}
            renderInput={params => (
              <TextField
                {...params}
                label="Tickers"
                variant="outlined"
                margin="normal"
                error={shouldRenderTickersErrors(errors)}
                helperText={getTickersHelperText(errors)}
                onKeyDown={handleAutocompleteKeyDown}
              />
            )}
          />
          <div className={classes.tickers}>
            {values.tickers.map((ticker, index) => (
              <div className={classes.tickersGroup} key={ticker.name}>
                <div className={classes.chipContainer}>
                  <Tooltip title={tickerTitle(ticker)}>
                    <Chip
                      label={tickerTitle(ticker)}
                      variant="outlined"
                      onDelete={handleTickerDelete(setFieldValue, setErrors, values.tickers, index)}
                      classes={{ root: classes.tickerChipRoot, label: classes.tickerChipLabel }}
                    />
                  </Tooltip>
                </div>
                <TextField
                  variant="outlined"
                  margin="none"
                  size="small"
                  fullWidth
                  label="Amount"
                  name="amount"
                  onChange={handleTickerAmountChange(setFieldValue, setErrors, values.tickers, index)}
                  value={values.tickers[index].amount}
                  disabled={isSubmitting}
                  error={errors.tickers && !!errors.tickers[index]?.amount}
                  helperText={errors.tickers && errors.tickers[index]?.amount}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                />
              </div>
            ))}
          </div>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="firstName"
            label="First Name"
            name="firstName"
            onChange={handleInputChange(handleChange, setErrors)}
            value={values.firstName}
            disabled={isSubmitting}
            error={!!errors.firstName}
            helperText={errors.firstName}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="lastName"
            label="Last Name"
            name="lastName"
            onChange={handleInputChange(handleChange, setErrors)}
            value={values.lastName}
            disabled={isSubmitting}
            error={!!errors.lastName}
            helperText={errors.lastName}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label="Email Address"
            name="email"
            onChange={handleInputChange(handleChange, setErrors)}
            value={values.email}
            disabled={isSubmitting}
            error={!!errors.email}
            helperText={errors.email}
          />
          <KeyboardDatePicker
            fullWidth
            disableFuture
            inputVariant="outlined"
            margin="normal"
            name="birthDate"
            label="Birth Date"
            id="birthDate"
            format={US_DATE_FORMAT}
            onChange={handleDateChange(setFieldValue, setErrors)}
            value={values.birthDate}
            disabled={isSubmitting}
            error={!!errors.birthDate}
            helperText={errors.birthDate}
            openTo="year"
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={isSubmitting}
          >
            Submit
          </Button>
        </form>
      )}
    </Formik>
  );
};

PortfolioForm.propTypes = {
  onLoadCompanies: PropTypes.func.isRequired,
  onLoadFunds: PropTypes.func.isRequired,
  onCreateUser: PropTypes.func.isRequired,
  onPrepareApp: PropTypes.func.isRequired,
};

export default PortfolioForm;
