import { useSelector } from 'react-redux';

import { useCustomerLensFlagsSetActions } from 'store/CustomerLensFlagsSetSlice';
import getFetchStatus from 'utils/getFetchStatus';

export default () => {
  const {
    loadFlags,
    resetFlags,
    createPortfolioAnalysesBatch,
    createAdvisorPortfolioAnalysesBatch,
    createCustomerRecommendation,
    createAdvisorRecommendation,
    createPortfolioAnalysis,
    createAdvisorPortfolioAnalysis,
  } = useCustomerLensFlagsSetActions();

  const flags = useSelector(state => state.CustomerLensFlagsSetSlice.flags.data);
  const flagsStatus = useSelector(state => state.CustomerLensFlagsSetSlice.flags.status);
  const flagsStatuses = getFetchStatus(flagsStatus);

  return {
    loadFlags,
    resetFlags,
    createPortfolioAnalysesBatch,
    createAdvisorPortfolioAnalysesBatch,
    createCustomerRecommendation,
    createAdvisorRecommendation,
    createPortfolioAnalysis,
    createAdvisorPortfolioAnalysis,
    flags,
    flagsStatuses,
  };
};
