import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './useStyles';
import Chip from '@material-ui/core/Chip';
import ValuePresenter from 'presenters/ValuePresenter';
import { StylesProvider } from '@material-ui/core/styles';

const Value = ({ value: currentValue, name }) => {
  const classes = useStyles();

  const importanceLevel = ValuePresenter.getLabelFor(currentValue);

  return (
    <div className={classes.root}>
      <div className={classes.title}>You value {name} as:</div>
      {/* TODO: Remove injector */}
      <StylesProvider injectFirst>
        <Chip
          label={importanceLevel}
          variant="outlined"
          classes={{ root: classes.chipRoot, label: classes.chipLabel }}
        />
      </StylesProvider>
    </div>
  );
};

Value.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string,
};

export default Value;
