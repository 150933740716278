import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const BackArrow = props => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        d="M4.796.105L.123 4.68a.48.48 0 00-.012.628l.012.013 4.673 4.574c.153.151.387.136.522-.034l.008-.01.583-.771a.463.463 0 00-.041-.6l-3.542-3.4a.113.113 0 010-.159L5.868 1.52a.443.443 0 00.13-.29.448.448 0 00-.09-.308L5.327.149a.36.36 0 00-.257-.148.345.345 0 00-.273.104z"
      />
    </SvgIcon>
  );
};

export default BackArrow;
