import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    padding: 0,
    '&.Mui-disabled $icon': {
      opacity: 0.6,
    },
  },
  icon: {
    width: 20,
    height: 20,
    borderRadius: 3,
    backgroundColor: '#ebebeb',
    border: '1px solid #c3c3c3',
  },
  checkedIcon: {
    '&:before': {
      width: 10,
      height: 10,
      display: 'block',
      content: "''",
      backgroundColor: '#25333B',
      top: '50%',
      position: 'absolute',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
  },
}));

export default useStyles;
