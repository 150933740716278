import React from 'react';
import PropTypes from 'prop-types';

import TickerMetaPresenter from 'presenters/TickerMetaPresenter';

import MuiTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { toFixedFloat } from 'utils/numberHelpers';

import useStyles from './useStyles';

const Table = props => {
  const { fundTickers } = props;
  const classes = useStyles();

  return (
    <MuiTable className={classes.table} aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell className={classes.tickerHead}>Ticker</TableCell>
          <TableCell className={classes.nameHead}>Name</TableCell>
          <TableCell align="left" className={classes.valuesHead}>
            Total Weight
          </TableCell>
          <TableCell align="right" className={classes.valuesHead}>
            Scored Weight
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {fundTickers.map(ticker => (
          <TableRow key={TickerMetaPresenter.id(ticker)} hover role="checkbox">
            <TableCell align="left" className={classes.tickerBody}>
              {TickerMetaPresenter.companyTicker(ticker)}
            </TableCell>
            <TableCell align="left">{TickerMetaPresenter.companyName(ticker)}</TableCell>
            <TableCell align="left">{toFixedFloat(TickerMetaPresenter.totalWeight(ticker), 2)}%</TableCell>
            <TableCell align="right">{toFixedFloat(TickerMetaPresenter.scoreableWeight(ticker), 2)}%</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </MuiTable>
  );
};

Table.propTypes = {
  fundTickers: PropTypes.arrayOf(TickerMetaPresenter.shape()).isRequired,
};

export default Table;
