/*
File generated by js-routes 1.4.9
Based on Rails 6.0.3.7 routes of ReflectionAnalytics::Application
 */

(function() {
  var DeprecatedGlobbingBehavior, NodeTypes, ParameterMissing, ReservedOptions, SpecialOptionsKey, UriEncoderSegmentRegex, Utils, result, root,
    hasProp = {}.hasOwnProperty,
    slice = [].slice;

  root = typeof exports !== "undefined" && exports !== null ? exports : this;

  ParameterMissing = function(message, fileName, lineNumber) {
    var instance;
    instance = new Error(message, fileName, lineNumber);
    if (Object.setPrototypeOf) {
      Object.setPrototypeOf(instance, Object.getPrototypeOf(this));
    } else {
      instance.__proto__ = this.__proto__;
    }
    if (Error.captureStackTrace) {
      Error.captureStackTrace(instance, ParameterMissing);
    }
    return instance;
  };

  ParameterMissing.prototype = Object.create(Error.prototype, {
    constructor: {
      value: Error,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });

  if (Object.setPrototypeOf) {
    Object.setPrototypeOf(ParameterMissing, Error);
  } else {
    ParameterMissing.__proto__ = Error;
  }

  NodeTypes = {"GROUP":1,"CAT":2,"SYMBOL":3,"OR":4,"STAR":5,"LITERAL":6,"SLASH":7,"DOT":8};

  DeprecatedGlobbingBehavior = false;

  SpecialOptionsKey = "_options";

  UriEncoderSegmentRegex = /[^a-zA-Z0-9\-\._~!\$&'\(\)\*\+,;=:@]/g;

  ReservedOptions = ['anchor', 'trailing_slash', 'subdomain', 'host', 'port', 'protocol'];

  Utils = {
    configuration: {
      prefix: "",
      default_url_options: {},
      special_options_key: "_options",
      serializer: null
    },
    default_serializer: function(object, prefix) {
      var element, i, j, key, len, prop, s;
      if (prefix == null) {
        prefix = null;
      }
      if (object == null) {
        return "";
      }
      if (!prefix && !(this.get_object_type(object) === "object")) {
        throw new Error("Url parameters should be a javascript hash");
      }
      s = [];
      switch (this.get_object_type(object)) {
        case "array":
          for (i = j = 0, len = object.length; j < len; i = ++j) {
            element = object[i];
            s.push(this.default_serializer(element, prefix + "[]"));
          }
          break;
        case "object":
          for (key in object) {
            if (!hasProp.call(object, key)) continue;
            prop = object[key];
            if ((prop == null) && (prefix != null)) {
              prop = "";
            }
            if (prop != null) {
              if (prefix != null) {
                key = prefix + "[" + key + "]";
              }
              s.push(this.default_serializer(prop, key));
            }
          }
          break;
        default:
          if (object != null) {
            s.push((encodeURIComponent(prefix.toString())) + "=" + (encodeURIComponent(object.toString())));
          }
      }
      if (!s.length) {
        return "";
      }
      return s.join("&");
    },
    serialize: function(object) {
      var custom_serializer;
      custom_serializer = this.configuration.serializer;
      if ((custom_serializer != null) && this.get_object_type(custom_serializer) === "function") {
        return custom_serializer(object);
      } else {
        return this.default_serializer(object);
      }
    },
    clean_path: function(path) {
      var last_index;
      path = path.split("://");
      last_index = path.length - 1;
      path[last_index] = path[last_index].replace(/\/+/g, "/");
      return path.join("://");
    },
    extract_options: function(number_of_params, args) {
      var last_el, options;
      last_el = args[args.length - 1];
      if ((args.length > number_of_params && last_el === void 0) || ((last_el != null) && "object" === this.get_object_type(last_el) && !this.looks_like_serialized_model(last_el))) {
        options = args.pop() || {};
        delete options[this.configuration.special_options_key];
        return options;
      } else {
        return {};
      }
    },
    looks_like_serialized_model: function(object) {
      return !object[this.configuration.special_options_key] && ("id" in object || "to_param" in object);
    },
    path_identifier: function(object) {
      var property;
      if (object === 0) {
        return "0";
      }
      if (!object) {
        return "";
      }
      property = object;
      if (this.get_object_type(object) === "object") {
        if ("to_param" in object) {
          if (object.to_param == null) {
            throw new ParameterMissing("Route parameter missing: to_param");
          }
          property = object.to_param;
        } else if ("id" in object) {
          if (object.id == null) {
            throw new ParameterMissing("Route parameter missing: id");
          }
          property = object.id;
        } else {
          property = object;
        }
        if (this.get_object_type(property) === "function") {
          property = property.call(object);
        }
      }
      return property.toString();
    },
    clone: function(obj) {
      var attr, copy, key;
      if ((obj == null) || "object" !== this.get_object_type(obj)) {
        return obj;
      }
      copy = obj.constructor();
      for (key in obj) {
        if (!hasProp.call(obj, key)) continue;
        attr = obj[key];
        copy[key] = attr;
      }
      return copy;
    },
    merge: function() {
      var tap, xs;
      xs = 1 <= arguments.length ? slice.call(arguments, 0) : [];
      tap = function(o, fn) {
        fn(o);
        return o;
      };
      if ((xs != null ? xs.length : void 0) > 0) {
        return tap({}, function(m) {
          var j, k, len, results, v, x;
          results = [];
          for (j = 0, len = xs.length; j < len; j++) {
            x = xs[j];
            results.push((function() {
              var results1;
              results1 = [];
              for (k in x) {
                v = x[k];
                results1.push(m[k] = v);
              }
              return results1;
            })());
          }
          return results;
        });
      }
    },
    normalize_options: function(parts, required_parts, default_options, actual_parameters) {
      var i, j, key, len, options, part, parts_options, result, route_parts, url_parameters, use_all_parts, value;
      options = this.extract_options(parts.length, actual_parameters);
      if (actual_parameters.length > parts.length) {
        throw new Error("Too many parameters provided for path");
      }
      use_all_parts = actual_parameters.length > required_parts.length;
      parts_options = {};
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        use_all_parts = true;
        if (this.indexOf(parts, key) >= 0) {
          parts_options[key] = value;
        }
      }
      options = this.merge(this.configuration.default_url_options, default_options, options);
      result = {};
      url_parameters = {};
      result['url_parameters'] = url_parameters;
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        value = options[key];
        if (this.indexOf(ReservedOptions, key) >= 0) {
          result[key] = value;
        } else {
          url_parameters[key] = value;
        }
      }
      route_parts = use_all_parts ? parts : required_parts;
      i = 0;
      for (j = 0, len = route_parts.length; j < len; j++) {
        part = route_parts[j];
        if (i < actual_parameters.length) {
          if (!parts_options.hasOwnProperty(part)) {
            url_parameters[part] = actual_parameters[i];
            ++i;
          }
        }
      }
      return result;
    },
    build_route: function(parts, required_parts, default_options, route, full_url, args) {
      var options, parameters, result, url, url_params;
      args = Array.prototype.slice.call(args);
      options = this.normalize_options(parts, required_parts, default_options, args);
      parameters = options['url_parameters'];
      result = "" + (this.get_prefix()) + (this.visit(route, parameters));
      url = Utils.clean_path(result);
      if (options['trailing_slash'] === true) {
        url = url.replace(/(.*?)[\/]?$/, "$1/");
      }
      if ((url_params = this.serialize(parameters)).length) {
        url += "?" + url_params;
      }
      url += options.anchor ? "#" + options.anchor : "";
      if (full_url) {
        url = this.route_url(options) + url;
      }
      return url;
    },
    visit: function(route, parameters, optional) {
      var left, left_part, right, right_part, type, value;
      if (optional == null) {
        optional = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return this.visit(left, parameters, true);
        case NodeTypes.STAR:
          return this.visit_globbing(left, parameters, true);
        case NodeTypes.LITERAL:
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
          return left;
        case NodeTypes.CAT:
          left_part = this.visit(left, parameters, optional);
          right_part = this.visit(right, parameters, optional);
          if (optional && ((this.is_optional_node(left[0]) && !left_part) || ((this.is_optional_node(right[0])) && !right_part))) {
            return "";
          }
          return "" + left_part + right_part;
        case NodeTypes.SYMBOL:
          value = parameters[left];
          delete parameters[left];
          if (value != null) {
            return this.encode_segment(this.path_identifier(value));
          }
          if (optional) {
            return "";
          } else {
            throw new ParameterMissing("Route parameter missing: " + left);
          }
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    encode_segment: function(segment) {
      return segment.replace(UriEncoderSegmentRegex, function(str) {
        return encodeURIComponent(str);
      });
    },
    is_optional_node: function(node) {
      return this.indexOf([NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT], node) >= 0;
    },
    build_path_spec: function(route, wildcard) {
      var left, right, type;
      if (wildcard == null) {
        wildcard = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return "(" + (this.build_path_spec(left)) + ")";
        case NodeTypes.CAT:
          return "" + (this.build_path_spec(left)) + (this.build_path_spec(right));
        case NodeTypes.STAR:
          return this.build_path_spec(left, true);
        case NodeTypes.SYMBOL:
          if (wildcard === true) {
            return "" + (left[0] === '*' ? '' : '*') + left;
          } else {
            return ":" + left;
          }
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
        case NodeTypes.LITERAL:
          return left;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    visit_globbing: function(route, parameters, optional) {
      var left, right, type, value;
      type = route[0], left = route[1], right = route[2];
      value = parameters[left];
      delete parameters[left];
      if (value == null) {
        return this.visit(route, parameters, optional);
      }
      value = (function() {
        switch (this.get_object_type(value)) {
          case "array":
            return value.join("/");
          default:
            return value;
        }
      }).call(this);
      if (DeprecatedGlobbingBehavior) {
        return this.path_identifier(value);
      } else {
        return encodeURI(this.path_identifier(value));
      }
    },
    get_prefix: function() {
      var prefix;
      prefix = this.configuration.prefix;
      if (prefix !== "") {
        prefix = (prefix.match("/$") ? prefix : prefix + "/");
      }
      return prefix;
    },
    route: function(parts_table, default_options, route_spec, full_url) {
      var j, len, part, parts, path_fn, ref, required, required_parts;
      required_parts = [];
      parts = [];
      for (j = 0, len = parts_table.length; j < len; j++) {
        ref = parts_table[j], part = ref[0], required = ref[1];
        parts.push(part);
        if (required) {
          required_parts.push(part);
        }
      }
      path_fn = function() {
        return Utils.build_route(parts, required_parts, default_options, route_spec, full_url, arguments);
      };
      path_fn.required_params = required_parts;
      path_fn.toString = function() {
        return Utils.build_path_spec(route_spec);
      };
      return path_fn;
    },
    route_url: function(route_defaults) {
      var hostname, port, protocol, subdomain;
      if (typeof route_defaults === 'string') {
        return route_defaults;
      }
      hostname = route_defaults.host || Utils.current_host();
      if (!hostname) {
        return '';
      }
      subdomain = route_defaults.subdomain ? route_defaults.subdomain + '.' : '';
      protocol = route_defaults.protocol || Utils.current_protocol();
      port = route_defaults.port || (!route_defaults.host ? Utils.current_port() : void 0);
      port = port ? ":" + port : '';
      return protocol + "://" + subdomain + hostname + port;
    },
    has_location: function() {
      return (typeof window !== "undefined" && window !== null ? window.location : void 0) != null;
    },
    current_host: function() {
      if (this.has_location()) {
        return window.location.hostname;
      } else {
        return null;
      }
    },
    current_protocol: function() {
      if (this.has_location() && window.location.protocol !== '') {
        return window.location.protocol.replace(/:$/, '');
      } else {
        return 'http';
      }
    },
    current_port: function() {
      if (this.has_location() && window.location.port !== '') {
        return window.location.port;
      } else {
        return '';
      }
    },
    _classToTypeCache: null,
    _classToType: function() {
      var j, len, name, ref;
      if (this._classToTypeCache != null) {
        return this._classToTypeCache;
      }
      this._classToTypeCache = {};
      ref = "Boolean Number String Function Array Date RegExp Object Error".split(" ");
      for (j = 0, len = ref.length; j < len; j++) {
        name = ref[j];
        this._classToTypeCache["[object " + name + "]"] = name.toLowerCase();
      }
      return this._classToTypeCache;
    },
    get_object_type: function(obj) {
      if (root.jQuery && (root.jQuery.type != null)) {
        return root.jQuery.type(obj);
      }
      if (obj == null) {
        return "" + obj;
      }
      if (typeof obj === "object" || typeof obj === "function") {
        return this._classToType()[Object.prototype.toString.call(obj)] || "object";
      } else {
        return typeof obj;
      }
    },
    indexOf: function(array, element) {
      if (Array.prototype.indexOf) {
        return array.indexOf(element);
      } else {
        return this.indexOfImplementation(array, element);
      }
    },
    indexOfImplementation: function(array, element) {
      var el, i, j, len, result;
      result = -1;
      for (i = j = 0, len = array.length; j < len; i = ++j) {
        el = array[i];
        if (el === element) {
          result = i;
        }
      }
      return result;
    },
    namespace: function(root, namespace, routes) {
      var index, j, len, part, parts;
      parts = namespace ? namespace.split(".") : [];
      if (parts.length === 0) {
        return routes;
      }
      for (index = j = 0, len = parts.length; j < len; index = ++j) {
        part = parts[index];
        if (index < parts.length - 1) {
          root = (root[part] || (root[part] = {}));
        } else {
          return root[part] = routes;
        }
      }
    },
    configure: function(new_config) {
      return this.configuration = this.merge(this.configuration, new_config);
    },
    config: function() {
      return this.clone(this.configuration);
    },
    make: function() {
      var routes;
      routes = {
// admin => /admin(.:format)
  // function(options)
  adminPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// admin_api_v1_accounts => /admin/api/v1/accounts(.:format)
  // function(options)
  adminApiV1AccountsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"accounts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// admin_api_v1_advisors => /admin/api/v1/advisors(.:format)
  // function(options)
  adminApiV1AdvisorsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"advisors",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// admin_api_v1_asset_class => /admin/api/v1/asset_classes/:id(.:format)
  // function(id, options)
  adminApiV1AssetClassPath: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"asset_classes",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// admin_api_v1_asset_class_categories => /admin/api/v1/asset_class_categories(.:format)
  // function(options)
  adminApiV1AssetClassCategoriesPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"asset_class_categories",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// admin_api_v1_asset_class_category => /admin/api/v1/asset_class_categories/:id(.:format)
  // function(id, options)
  adminApiV1AssetClassCategoryPath: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"asset_class_categories",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// admin_api_v1_asset_classes => /admin/api/v1/asset_classes(.:format)
  // function(options)
  adminApiV1AssetClassesPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"asset_classes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// admin_api_v1_base_funds => /admin/api/v1/base_funds(.:format)
  // function(options)
  adminApiV1BaseFundsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"base_funds",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// admin_api_v1_companies => /admin/api/v1/companies(.:format)
  // function(options)
  adminApiV1CompaniesPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"companies",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// admin_api_v1_company => /admin/api/v1/companies/:id(.:format)
  // function(id, options)
  adminApiV1CompanyPath: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"companies",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// admin_api_v1_configuration => /admin/api/v1/configuration(.:format)
  // function(options)
  adminApiV1ConfigurationPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"configuration",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// admin_api_v1_customers => /admin/api/v1/customers(.:format)
  // function(options)
  adminApiV1CustomersPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"customers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// admin_api_v1_dashboard => /admin/api/v1/dashboard(.:format)
  // function(options)
  adminApiV1DashboardPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"dashboard",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// admin_api_v1_external_asset_class => /admin/api/v1/external_asset_classes/:id(.:format)
  // function(id, options)
  adminApiV1ExternalAssetClassPath: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"external_asset_classes",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// admin_api_v1_external_asset_classes => /admin/api/v1/external_asset_classes(.:format)
  // function(options)
  adminApiV1ExternalAssetClassesPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"external_asset_classes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// admin_api_v1_fund => /admin/api/v1/funds/:id(.:format)
  // function(id, options)
  adminApiV1FundPath: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"funds",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// admin_api_v1_fund_import_request => /admin/api/v1/fund/import_requests/:id(.:format)
  // function(id, options)
  adminApiV1FundImportRequestPath: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"fund",false],[2,[7,"/",false],[2,[6,"import_requests",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// admin_api_v1_fund_import_requests => /admin/api/v1/fund/import_requests(.:format)
  // function(options)
  adminApiV1FundImportRequestsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"fund",false],[2,[7,"/",false],[2,[6,"import_requests",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// admin_api_v1_fund_portfolios => /admin/api/v1/fund_portfolios(.:format)
  // function(options)
  adminApiV1FundPortfoliosPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"fund_portfolios",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// admin_api_v1_fund_tickers => /admin/api/v1/fund_tickers(.:format)
  // function(options)
  adminApiV1FundTickersPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"fund_tickers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// admin_api_v1_funds => /admin/api/v1/funds(.:format)
  // function(options)
  adminApiV1FundsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"funds",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// admin_api_v1_gics_sub_industries => /admin/api/v1/gics_sub_industries(.:format)
  // function(options)
  adminApiV1GicsSubIndustriesPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"gics_sub_industries",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// admin_api_v1_gics_sub_industry => /admin/api/v1/gics_sub_industries/:id(.:format)
  // function(id, options)
  adminApiV1GicsSubIndustryPath: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"gics_sub_industries",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// admin_api_v1_household => /admin/api/v1/households/:id(.:format)
  // function(id, options)
  adminApiV1HouseholdPath: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"households",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// admin_api_v1_household_exports => /admin/api/v1/household/exports(.:format)
  // function(options)
  adminApiV1HouseholdExportsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"household",false],[2,[7,"/",false],[2,[6,"exports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// admin_api_v1_households => /admin/api/v1/households(.:format)
  // function(options)
  adminApiV1HouseholdsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"households",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// admin_api_v1_institution => /admin/api/v1/institutions/:id(.:format)
  // function(id, options)
  adminApiV1InstitutionPath: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"institutions",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// admin_api_v1_institution_exports => /admin/api/v1/institution/exports(.:format)
  // function(options)
  adminApiV1InstitutionExportsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"institution",false],[2,[7,"/",false],[2,[6,"exports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// admin_api_v1_institution_fund => /admin/api/v1/institution_funds/:id(.:format)
  // function(id, options)
  adminApiV1InstitutionFundPath: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"institution_funds",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// admin_api_v1_institution_fund_exports => /admin/api/v1/institution_fund/exports(.:format)
  // function(options)
  adminApiV1InstitutionFundExportsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"institution_fund",false],[2,[7,"/",false],[2,[6,"exports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// admin_api_v1_institution_funds => /admin/api/v1/institution_funds(.:format)
  // function(options)
  adminApiV1InstitutionFundsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"institution_funds",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// admin_api_v1_institutions => /admin/api/v1/institutions(.:format)
  // function(options)
  adminApiV1InstitutionsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"institutions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// admin_api_v1_kpi => /admin/api/v1/kpi(.:format)
  // function(options)
  adminApiV1KpiPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"kpi",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// admin_api_v1_partners => /admin/api/v1/partners(.:format)
  // function(options)
  adminApiV1PartnersPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"partners",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// admin_api_v1_session => /admin/api/v1/session(.:format)
  // function(options)
  adminApiV1SessionPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"session",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// admin_api_v1_ticker_search_index => /admin/api/v1/ticker/search(.:format)
  // function(options)
  adminApiV1TickerSearchIndexPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"ticker",false],[2,[7,"/",false],[2,[6,"search",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// admin_api_v1_user => /admin/api/v1/users/:id(.:format)
  // function(id, options)
  adminApiV1UserPath: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// admin_api_v1_user_exports => /admin/api/v1/user/exports(.:format)
  // function(options)
  adminApiV1UserExportsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"user",false],[2,[7,"/",false],[2,[6,"exports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// admin_api_v1_users => /admin/api/v1/users(.:format)
  // function(options)
  adminApiV1UsersPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"users",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_v1_advisor_asset_classes => /api/v1/advisor/asset_classes(.:format)
  // function(options)
  apiV1AdvisorAssetClassesPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"advisor",false],[2,[7,"/",false],[2,[6,"asset_classes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_v1_advisor_customer => /api/v1/advisor/customers/:id(.:format)
  // function(id, options)
  apiV1AdvisorCustomerPath: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"advisor",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_v1_advisor_customer_account => /api/v1/advisor/customer_accounts/:id(.:format)
  // function(id, options)
  apiV1AdvisorCustomerAccountPath: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"advisor",false],[2,[7,"/",false],[2,[6,"customer_accounts",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_v1_advisor_customer_accounts => /api/v1/advisor/customer_accounts(.:format)
  // function(options)
  apiV1AdvisorCustomerAccountsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"advisor",false],[2,[7,"/",false],[2,[6,"customer_accounts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_v1_advisor_customers => /api/v1/advisor/customers(.:format)
  // function(options)
  apiV1AdvisorCustomersPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"advisor",false],[2,[7,"/",false],[2,[6,"customers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_v1_advisor_household => /api/v1/advisor/households/:id(.:format)
  // function(id, options)
  apiV1AdvisorHouseholdPath: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"advisor",false],[2,[7,"/",false],[2,[6,"households",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_v1_advisor_household_customer_lenses => /api/v1/advisor/households/:household_id/customer_lenses(.:format)
  // function(household_id, options)
  apiV1AdvisorHouseholdCustomerLensesPath: Utils.route([["household_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"advisor",false],[2,[7,"/",false],[2,[6,"households",false],[2,[7,"/",false],[2,[3,"household_id",false],[2,[7,"/",false],[2,[6,"customer_lenses",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// api_v1_advisor_households => /api/v1/advisor/households(.:format)
  // function(options)
  apiV1AdvisorHouseholdsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"advisor",false],[2,[7,"/",false],[2,[6,"households",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_v1_advisor_portfolio_analyses => /api/v1/advisor/portfolio_analyses(.:format)
  // function(options)
  apiV1AdvisorPortfolioAnalysesPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"advisor",false],[2,[7,"/",false],[2,[6,"portfolio_analyses",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_v1_advisor_portfolio_parser => /api/v1/advisor/portfolio_parser(.:format)
  // function(options)
  apiV1AdvisorPortfolioParserPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"advisor",false],[2,[7,"/",false],[2,[6,"portfolio_parser",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_v1_advisor_recommendation => /api/v1/advisor/recommendations/:id(.:format)
  // function(id, options)
  apiV1AdvisorRecommendationPath: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"advisor",false],[2,[7,"/",false],[2,[6,"recommendations",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_v1_advisor_recommendations => /api/v1/advisor/recommendations(.:format)
  // function(options)
  apiV1AdvisorRecommendationsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"advisor",false],[2,[7,"/",false],[2,[6,"recommendations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_v1_advisor_recommended_funds => /api/v1/advisor/recommended_funds(.:format)
  // function(options)
  apiV1AdvisorRecommendedFundsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"advisor",false],[2,[7,"/",false],[2,[6,"recommended_funds",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_v1_advisor_saved_fund => /api/v1/advisor/saved_funds/:id(.:format)
  // function(id, options)
  apiV1AdvisorSavedFundPath: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"advisor",false],[2,[7,"/",false],[2,[6,"saved_funds",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_v1_advisor_saved_funds => /api/v1/advisor/saved_funds(.:format)
  // function(options)
  apiV1AdvisorSavedFundsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"advisor",false],[2,[7,"/",false],[2,[6,"saved_funds",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_v1_companies => /api/v1/companies(.:format)
  // function(options)
  apiV1CompaniesPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"companies",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_v1_configuration => /api/v1/configuration(.:format)
  // function(options)
  apiV1ConfigurationPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"configuration",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_v1_customer_accounts => /api/v1/customer/accounts(.:format)
  // function(options)
  apiV1CustomerAccountsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"customer",false],[2,[7,"/",false],[2,[6,"accounts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_v1_customer_customer_lens => /api/v1/customer/customer_lenses/:id(.:format)
  // function(id, options)
  apiV1CustomerCustomerLensPath: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"customer",false],[2,[7,"/",false],[2,[6,"customer_lenses",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_v1_customer_customer_lenses => /api/v1/customer/customer_lenses(.:format)
  // function(options)
  apiV1CustomerCustomerLensesPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"customer",false],[2,[7,"/",false],[2,[6,"customer_lenses",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_v1_customer_portfolio_analyses => /api/v1/customer/portfolio_analyses(.:format)
  // function(options)
  apiV1CustomerPortfolioAnalysesPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"customer",false],[2,[7,"/",false],[2,[6,"portfolio_analyses",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_v1_customer_portfolio_analyses_batch_companies => /api/v1/customer_portfolio_analyses_batches/:customer_portfolio_analyses_batch_id/companies(.:format)
  // function(customer_portfolio_analyses_batch_id, options)
  apiV1CustomerPortfolioAnalysesBatchCompaniesPath: Utils.route([["customer_portfolio_analyses_batch_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"customer_portfolio_analyses_batches",false],[2,[7,"/",false],[2,[3,"customer_portfolio_analyses_batch_id",false],[2,[7,"/",false],[2,[6,"companies",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_v1_customer_portfolio_analyses_batch_company => /api/v1/customer_portfolio_analyses_batches/:customer_portfolio_analyses_batch_id/companies/:id(.:format)
  // function(customer_portfolio_analyses_batch_id, id, options)
  apiV1CustomerPortfolioAnalysesBatchCompanyPath: Utils.route([["customer_portfolio_analyses_batch_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"customer_portfolio_analyses_batches",false],[2,[7,"/",false],[2,[3,"customer_portfolio_analyses_batch_id",false],[2,[7,"/",false],[2,[6,"companies",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// api_v1_customer_portfolio_analyses_batch_customer_fund_analyses => /api/v1/customer_portfolio_analyses_batches/:customer_portfolio_analyses_batch_id/customer_fund_analyses(.:format)
  // function(customer_portfolio_analyses_batch_id, options)
  apiV1CustomerPortfolioAnalysesBatchCustomerFundAnalysesPath: Utils.route([["customer_portfolio_analyses_batch_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"customer_portfolio_analyses_batches",false],[2,[7,"/",false],[2,[3,"customer_portfolio_analyses_batch_id",false],[2,[7,"/",false],[2,[6,"customer_fund_analyses",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_v1_customer_portfolio_analyses_batch_fund_tickers => /api/v1/customer_portfolio_analyses_batches/:customer_portfolio_analyses_batch_id/fund_tickers(.:format)
  // function(customer_portfolio_analyses_batch_id, options)
  apiV1CustomerPortfolioAnalysesBatchFundTickersPath: Utils.route([["customer_portfolio_analyses_batch_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"customer_portfolio_analyses_batches",false],[2,[7,"/",false],[2,[3,"customer_portfolio_analyses_batch_id",false],[2,[7,"/",false],[2,[6,"fund_tickers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_v1_customer_portfolio_analyses_batch_grouped_asset_classes_categories => /api/v1/customer_portfolio_analyses_batches/:customer_portfolio_analyses_batch_id/grouped_asset_classes_categories(.:format)
  // function(customer_portfolio_analyses_batch_id, options)
  apiV1CustomerPortfolioAnalysesBatchGroupedAssetClassesCategoriesPath: Utils.route([["customer_portfolio_analyses_batch_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"customer_portfolio_analyses_batches",false],[2,[7,"/",false],[2,[3,"customer_portfolio_analyses_batch_id",false],[2,[7,"/",false],[2,[6,"grouped_asset_classes_categories",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_v1_customer_portfolio_analyses_batch_grouped_ticker_asset_classes => /api/v1/customer_portfolio_analyses_batches/:customer_portfolio_analyses_batch_id/grouped_ticker_asset_classes(.:format)
  // function(customer_portfolio_analyses_batch_id, options)
  apiV1CustomerPortfolioAnalysesBatchGroupedTickerAssetClassesPath: Utils.route([["customer_portfolio_analyses_batch_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"customer_portfolio_analyses_batches",false],[2,[7,"/",false],[2,[3,"customer_portfolio_analyses_batch_id",false],[2,[7,"/",false],[2,[6,"grouped_ticker_asset_classes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_v1_customer_portfolio_analyses_batch_mixed_analysis => /api/v1/customer_portfolio_analyses_batches/:customer_portfolio_analyses_batch_id/mixed_analysis(.:format)
  // function(customer_portfolio_analyses_batch_id, options)
  apiV1CustomerPortfolioAnalysesBatchMixedAnalysisPath: Utils.route([["customer_portfolio_analyses_batch_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"customer_portfolio_analyses_batches",false],[2,[7,"/",false],[2,[3,"customer_portfolio_analyses_batch_id",false],[2,[7,"/",false],[2,[6,"mixed_analysis",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_v1_customer_portfolio_analysis => /api/v1/customer/portfolio_analyses/:id(.:format)
  // function(id, options)
  apiV1CustomerPortfolioAnalysisPath: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"customer",false],[2,[7,"/",false],[2,[6,"portfolio_analyses",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_v1_customer_recommendation => /api/v1/customer/recommendations/:id(.:format)
  // function(id, options)
  apiV1CustomerRecommendationPath: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"customer",false],[2,[7,"/",false],[2,[6,"recommendations",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_v1_customer_recommendations => /api/v1/customer/recommendations(.:format)
  // function(options)
  apiV1CustomerRecommendationsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"customer",false],[2,[7,"/",false],[2,[6,"recommendations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_v1_customer_recommended_funds => /api/v1/customer/recommended_funds(.:format)
  // function(options)
  apiV1CustomerRecommendedFundsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"customer",false],[2,[7,"/",false],[2,[6,"recommended_funds",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_v1_customer_saved_fund => /api/v1/customer/saved_funds/:id(.:format)
  // function(id, options)
  apiV1CustomerSavedFundPath: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"customer",false],[2,[7,"/",false],[2,[6,"saved_funds",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_v1_customer_saved_funds => /api/v1/customer/saved_funds(.:format)
  // function(options)
  apiV1CustomerSavedFundsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"customer",false],[2,[7,"/",false],[2,[6,"saved_funds",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_v1_flags => /api/v1/flags(.:format)
  // function(options)
  apiV1FlagsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"flags",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_v1_funds => /api/v1/funds(.:format)
  // function(options)
  apiV1FundsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"funds",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_v1_lenses => /api/v1/lenses(.:format)
  // function(options)
  apiV1LensesPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"lenses",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_v1_portfolio_analysis_companies => /api/v1/portfolio_analyses/:portfolio_analysis_id/companies(.:format)
  // function(portfolio_analysis_id, options)
  apiV1PortfolioAnalysisCompaniesPath: Utils.route([["portfolio_analysis_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"portfolio_analyses",false],[2,[7,"/",false],[2,[3,"portfolio_analysis_id",false],[2,[7,"/",false],[2,[6,"companies",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_v1_portfolio_analysis_company => /api/v1/portfolio_analyses/:portfolio_analysis_id/companies/:id(.:format)
  // function(portfolio_analysis_id, id, options)
  apiV1PortfolioAnalysisCompanyPath: Utils.route([["portfolio_analysis_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"portfolio_analyses",false],[2,[7,"/",false],[2,[3,"portfolio_analysis_id",false],[2,[7,"/",false],[2,[6,"companies",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// api_v1_portfolio_analysis_customer_fund_analyses => /api/v1/portfolio_analyses/:portfolio_analysis_id/customer_fund_analyses(.:format)
  // function(portfolio_analysis_id, options)
  apiV1PortfolioAnalysisCustomerFundAnalysesPath: Utils.route([["portfolio_analysis_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"portfolio_analyses",false],[2,[7,"/",false],[2,[3,"portfolio_analysis_id",false],[2,[7,"/",false],[2,[6,"customer_fund_analyses",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_v1_portfolio_analysis_fund_tickers => /api/v1/portfolio_analyses/:portfolio_analysis_id/fund_tickers(.:format)
  // function(portfolio_analysis_id, options)
  apiV1PortfolioAnalysisFundTickersPath: Utils.route([["portfolio_analysis_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"portfolio_analyses",false],[2,[7,"/",false],[2,[3,"portfolio_analysis_id",false],[2,[7,"/",false],[2,[6,"fund_tickers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_v1_portfolio_analysis_mixed_analysis => /api/v1/portfolio_analyses/:portfolio_analysis_id/mixed_analysis(.:format)
  // function(portfolio_analysis_id, options)
  apiV1PortfolioAnalysisMixedAnalysisPath: Utils.route([["portfolio_analysis_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"portfolio_analyses",false],[2,[7,"/",false],[2,[3,"portfolio_analysis_id",false],[2,[7,"/",false],[2,[6,"mixed_analysis",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_v1_session => /api/v1/session(.:format)
  // function(options)
  apiV1SessionPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"session",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_v1_users => /api/v1/users(.:format)
  // function(options)
  apiV1UsersPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"users",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// apipie_apipie => /api/overview(/:version)(/:resource)(/:method)(.:format)
  // function(options)
  apipieApipiePath: Utils.route([["version",false],["resource",false],["method",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"overview",false],[2,[1,[2,[7,"/",false],[3,"version",false]],false],[2,[1,[2,[7,"/",false],[3,"resource",false]],false],[2,[1,[2,[7,"/",false],[3,"method",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// apipie_apipie_checksum => /api/overview/apipie_checksum(.:format)
  // function(options)
  apipieApipieChecksumPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"overview",false],[2,[7,"/",false],[2,[6,"apipie_checksum",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// auth_tokens_admin_api_v1_partners => /admin/api/v1/partners/auth_tokens(.:format)
  // function(options)
  authTokensAdminApiV1PartnersPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"partners",false],[2,[7,"/",false],[2,[6,"auth_tokens",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// bulk_destroy_admin_api_v1_asset_class_categories => /admin/api/v1/asset_class_categories/bulk_destroy(.:format)
  // function(options)
  bulkDestroyAdminApiV1AssetClassCategoriesPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"asset_class_categories",false],[2,[7,"/",false],[2,[6,"bulk_destroy",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// bulk_destroy_admin_api_v1_asset_classes => /admin/api/v1/asset_classes/bulk_destroy(.:format)
  // function(options)
  bulkDestroyAdminApiV1AssetClassesPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"asset_classes",false],[2,[7,"/",false],[2,[6,"bulk_destroy",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// bulk_destroy_admin_api_v1_companies => /admin/api/v1/companies/bulk_destroy(.:format)
  // function(options)
  bulkDestroyAdminApiV1CompaniesPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"companies",false],[2,[7,"/",false],[2,[6,"bulk_destroy",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// bulk_destroy_admin_api_v1_external_asset_classes => /admin/api/v1/external_asset_classes/bulk_destroy(.:format)
  // function(options)
  bulkDestroyAdminApiV1ExternalAssetClassesPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"external_asset_classes",false],[2,[7,"/",false],[2,[6,"bulk_destroy",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// bulk_destroy_admin_api_v1_funds => /admin/api/v1/funds/bulk_destroy(.:format)
  // function(options)
  bulkDestroyAdminApiV1FundsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"funds",false],[2,[7,"/",false],[2,[6,"bulk_destroy",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// bulk_destroy_admin_api_v1_gics_sub_industries => /admin/api/v1/gics_sub_industries/bulk_destroy(.:format)
  // function(options)
  bulkDestroyAdminApiV1GicsSubIndustriesPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"gics_sub_industries",false],[2,[7,"/",false],[2,[6,"bulk_destroy",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// bulk_destroy_admin_api_v1_households => /admin/api/v1/households/bulk_destroy(.:format)
  // function(options)
  bulkDestroyAdminApiV1HouseholdsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"households",false],[2,[7,"/",false],[2,[6,"bulk_destroy",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// bulk_destroy_admin_api_v1_institution_funds => /admin/api/v1/institution_funds/bulk_destroy(.:format)
  // function(options)
  bulkDestroyAdminApiV1InstitutionFundsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"institution_funds",false],[2,[7,"/",false],[2,[6,"bulk_destroy",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// bulk_destroy_admin_api_v1_institutions => /admin/api/v1/institutions/bulk_destroy(.:format)
  // function(options)
  bulkDestroyAdminApiV1InstitutionsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"institutions",false],[2,[7,"/",false],[2,[6,"bulk_destroy",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// bulk_destroy_admin_api_v1_users => /admin/api/v1/users/bulk_destroy(.:format)
  // function(options)
  bulkDestroyAdminApiV1UsersPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"bulk_destroy",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// create_batch_api_v1_advisor_portfolio_analyses => /api/v1/advisor/portfolio_analyses/create_batch(.:format)
  // function(options)
  createBatchApiV1AdvisorPortfolioAnalysesPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"advisor",false],[2,[7,"/",false],[2,[6,"portfolio_analyses",false],[2,[7,"/",false],[2,[6,"create_batch",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// create_batch_api_v1_customer_portfolio_analyses => /api/v1/customer/portfolio_analyses/create_batch(.:format)
  // function(options)
  createBatchApiV1CustomerPortfolioAnalysesPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"customer",false],[2,[7,"/",false],[2,[6,"portfolio_analyses",false],[2,[7,"/",false],[2,[6,"create_batch",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// create_export_admin_api_v1_household_exports => /admin/api/v1/household/exports/create_export(.:format)
  // function(options)
  createExportAdminApiV1HouseholdExportsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"household",false],[2,[7,"/",false],[2,[6,"exports",false],[2,[7,"/",false],[2,[6,"create_export",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// create_export_admin_api_v1_institution_exports => /admin/api/v1/institution/exports/create_export(.:format)
  // function(options)
  createExportAdminApiV1InstitutionExportsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"institution",false],[2,[7,"/",false],[2,[6,"exports",false],[2,[7,"/",false],[2,[6,"create_export",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// create_export_admin_api_v1_institution_fund_exports => /admin/api/v1/institution_fund/exports/create_export(.:format)
  // function(options)
  createExportAdminApiV1InstitutionFundExportsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"institution_fund",false],[2,[7,"/",false],[2,[6,"exports",false],[2,[7,"/",false],[2,[6,"create_export",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// create_export_admin_api_v1_user_exports => /admin/api/v1/user/exports/create_export(.:format)
  // function(options)
  createExportAdminApiV1UserExportsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"user",false],[2,[7,"/",false],[2,[6,"exports",false],[2,[7,"/",false],[2,[6,"create_export",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// current_api_v1_users => /api/v1/users/current(.:format)
  // function(options)
  currentApiV1UsersPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"current",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// download_admin_api_v1_company_exports => /admin/api/v1/company/exports/download(.:format)
  // function(options)
  downloadAdminApiV1CompanyExportsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"company",false],[2,[7,"/",false],[2,[6,"exports",false],[2,[7,"/",false],[2,[6,"download",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// download_admin_api_v1_fund_exports => /admin/api/v1/fund/exports/download(.:format)
  // function(options)
  downloadAdminApiV1FundExportsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"fund",false],[2,[7,"/",false],[2,[6,"exports",false],[2,[7,"/",false],[2,[6,"download",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// download_admin_api_v1_fund_portfolio_exports => /admin/api/v1/fund_portfolio/exports/download(.:format)
  // function(options)
  downloadAdminApiV1FundPortfolioExportsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"fund_portfolio",false],[2,[7,"/",false],[2,[6,"exports",false],[2,[7,"/",false],[2,[6,"download",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// download_admin_api_v1_fund_ticker_exports => /admin/api/v1/fund_ticker/exports/download(.:format)
  // function(options)
  downloadAdminApiV1FundTickerExportsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"fund_ticker",false],[2,[7,"/",false],[2,[6,"exports",false],[2,[7,"/",false],[2,[6,"download",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// download_admin_api_v1_gics_sub_industry_exports => /admin/api/v1/gics_sub_industry/exports/download(.:format)
  // function(options)
  downloadAdminApiV1GicsSubIndustryExportsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"gics_sub_industry",false],[2,[7,"/",false],[2,[6,"exports",false],[2,[7,"/",false],[2,[6,"download",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// download_admin_api_v1_household_exports => /admin/api/v1/household/exports/download(.:format)
  // function(options)
  downloadAdminApiV1HouseholdExportsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"household",false],[2,[7,"/",false],[2,[6,"exports",false],[2,[7,"/",false],[2,[6,"download",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// download_admin_api_v1_institution_exports => /admin/api/v1/institution/exports/download(.:format)
  // function(options)
  downloadAdminApiV1InstitutionExportsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"institution",false],[2,[7,"/",false],[2,[6,"exports",false],[2,[7,"/",false],[2,[6,"download",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// download_admin_api_v1_institution_fund_exports => /admin/api/v1/institution_fund/exports/download(.:format)
  // function(options)
  downloadAdminApiV1InstitutionFundExportsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"institution_fund",false],[2,[7,"/",false],[2,[6,"exports",false],[2,[7,"/",false],[2,[6,"download",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// download_admin_api_v1_user_exports => /admin/api/v1/user/exports/download(.:format)
  // function(options)
  downloadAdminApiV1UserExportsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"user",false],[2,[7,"/",false],[2,[6,"exports",false],[2,[7,"/",false],[2,[6,"download",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// download_api_v1_advisor_portfolio_import_templates => /api/v1/advisor/portfolio_import_templates/download(.:format)
  // function(options)
  downloadApiV1AdvisorPortfolioImportTemplatesPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"advisor",false],[2,[7,"/",false],[2,[6,"portfolio_import_templates",false],[2,[7,"/",false],[2,[6,"download",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// duplicate_api_v1_advisor_household_customer_lens => /api/v1/advisor/households/:household_id/customer_lenses/:id/duplicate(.:format)
  // function(household_id, id, options)
  duplicateApiV1AdvisorHouseholdCustomerLensPath: Utils.route([["household_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"advisor",false],[2,[7,"/",false],[2,[6,"households",false],[2,[7,"/",false],[2,[3,"household_id",false],[2,[7,"/",false],[2,[6,"customer_lenses",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"duplicate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]),
// edit_rails_conductor_inbound_email => /rails/conductor/action_mailbox/inbound_emails/:id/edit(.:format)
  // function(id, options)
  editRailsConductorInboundEmailPath: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// embed => /embed(.:format)
  // function(options)
  embedPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"embed",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// export_admin_api_v1_kpi => /admin/api/v1/kpi/export(.:format)
  // function(options)
  exportAdminApiV1KpiPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"kpi",false],[2,[7,"/",false],[2,[6,"export",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// finish_admin_api_v1_fund_import_request => /admin/api/v1/fund/import_requests/:id/finish(.:format)
  // function(id, options)
  finishAdminApiV1FundImportRequestPath: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"fund",false],[2,[7,"/",false],[2,[6,"import_requests",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"finish",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// flagged_tickers_api_v1_customer_portfolio_analyses_batch_customer_fund_analyses => /api/v1/customer_portfolio_analyses_batches/:customer_portfolio_analyses_batch_id/customer_fund_analyses/flagged_tickers(.:format)
  // function(customer_portfolio_analyses_batch_id, options)
  flaggedTickersApiV1CustomerPortfolioAnalysesBatchCustomerFundAnalysesPath: Utils.route([["customer_portfolio_analyses_batch_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"customer_portfolio_analyses_batches",false],[2,[7,"/",false],[2,[3,"customer_portfolio_analyses_batch_id",false],[2,[7,"/",false],[2,[6,"customer_fund_analyses",false],[2,[7,"/",false],[2,[6,"flagged_tickers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// flagged_tickers_api_v1_portfolio_analysis_customer_fund_analyses => /api/v1/portfolio_analyses/:portfolio_analysis_id/customer_fund_analyses/flagged_tickers(.:format)
  // function(portfolio_analysis_id, options)
  flaggedTickersApiV1PortfolioAnalysisCustomerFundAnalysesPath: Utils.route([["portfolio_analysis_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"portfolio_analyses",false],[2,[7,"/",false],[2,[3,"portfolio_analysis_id",false],[2,[7,"/",false],[2,[6,"customer_fund_analyses",false],[2,[7,"/",false],[2,[6,"flagged_tickers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// impersonate_admin_api_v1_user => /admin/api/v1/users/:id/impersonate(.:format)
  // function(id, options)
  impersonateAdminApiV1UserPath: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"impersonate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// insights_api_v1_customer_portfolio_analyses_batch_customer_fund_analyses => /api/v1/customer_portfolio_analyses_batches/:customer_portfolio_analyses_batch_id/customer_fund_analyses/insights(.:format)
  // function(customer_portfolio_analyses_batch_id, options)
  insightsApiV1CustomerPortfolioAnalysesBatchCustomerFundAnalysesPath: Utils.route([["customer_portfolio_analyses_batch_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"customer_portfolio_analyses_batches",false],[2,[7,"/",false],[2,[3,"customer_portfolio_analyses_batch_id",false],[2,[7,"/",false],[2,[6,"customer_fund_analyses",false],[2,[7,"/",false],[2,[6,"insights",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// insights_api_v1_customer_recommended_funds => /api/v1/customer/recommended_funds/insights(.:format)
  // function(options)
  insightsApiV1CustomerRecommendedFundsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"customer",false],[2,[7,"/",false],[2,[6,"recommended_funds",false],[2,[7,"/",false],[2,[6,"insights",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// insights_api_v1_portfolio_analysis_customer_fund_analyses => /api/v1/portfolio_analyses/:portfolio_analysis_id/customer_fund_analyses/insights(.:format)
  // function(portfolio_analysis_id, options)
  insightsApiV1PortfolioAnalysisCustomerFundAnalysesPath: Utils.route([["portfolio_analysis_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"portfolio_analyses",false],[2,[7,"/",false],[2,[3,"portfolio_analysis_id",false],[2,[7,"/",false],[2,[6,"customer_fund_analyses",false],[2,[7,"/",false],[2,[6,"insights",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// integration_api_v1_moneyguide_payload => /integration/api/v1/moneyguide/payload(.:format)
  // function(options)
  integrationApiV1MoneyguidePayloadPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"integration",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"moneyguide",false],[2,[7,"/",false],[2,[6,"payload",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// integration_api_v1_moneyguide_session => /integration/api/v1/moneyguide/session(.:format)
  // function(options)
  integrationApiV1MoneyguideSessionPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"integration",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"moneyguide",false],[2,[7,"/",false],[2,[6,"session",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// make_current_api_v1_advisor_household => /api/v1/advisor/households/:id/make_current(.:format)
  // function(id, options)
  makeCurrentApiV1AdvisorHouseholdPath: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"advisor",false],[2,[7,"/",false],[2,[6,"households",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"make_current",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// metrics_api_v1_customer_portfolio_analyses_batch_customer_fund_analyses => /api/v1/customer_portfolio_analyses_batches/:customer_portfolio_analyses_batch_id/customer_fund_analyses/metrics(.:format)
  // function(customer_portfolio_analyses_batch_id, options)
  metricsApiV1CustomerPortfolioAnalysesBatchCustomerFundAnalysesPath: Utils.route([["customer_portfolio_analyses_batch_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"customer_portfolio_analyses_batches",false],[2,[7,"/",false],[2,[3,"customer_portfolio_analyses_batch_id",false],[2,[7,"/",false],[2,[6,"customer_fund_analyses",false],[2,[7,"/",false],[2,[6,"metrics",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// metrics_api_v1_portfolio_analysis_customer_fund_analyses => /api/v1/portfolio_analyses/:portfolio_analysis_id/customer_fund_analyses/metrics(.:format)
  // function(portfolio_analysis_id, options)
  metricsApiV1PortfolioAnalysisCustomerFundAnalysesPath: Utils.route([["portfolio_analysis_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"portfolio_analyses",false],[2,[7,"/",false],[2,[3,"portfolio_analysis_id",false],[2,[7,"/",false],[2,[6,"customer_fund_analyses",false],[2,[7,"/",false],[2,[6,"metrics",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// new_rails_conductor_inbound_email => /rails/conductor/action_mailbox/inbound_emails/new(.:format)
  // function(options)
  newRailsConductorInboundEmailPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// options_list_api_v1_customer_portfolio_analyses_batch_companies => /api/v1/customer_portfolio_analyses_batches/:customer_portfolio_analyses_batch_id/companies/options_list(.:format)
  // function(customer_portfolio_analyses_batch_id, options)
  optionsListApiV1CustomerPortfolioAnalysesBatchCompaniesPath: Utils.route([["customer_portfolio_analyses_batch_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"customer_portfolio_analyses_batches",false],[2,[7,"/",false],[2,[3,"customer_portfolio_analyses_batch_id",false],[2,[7,"/",false],[2,[6,"companies",false],[2,[7,"/",false],[2,[6,"options_list",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// options_list_api_v1_customer_portfolio_analyses_batch_customer_fund_analyses => /api/v1/customer_portfolio_analyses_batches/:customer_portfolio_analyses_batch_id/customer_fund_analyses/options_list(.:format)
  // function(customer_portfolio_analyses_batch_id, options)
  optionsListApiV1CustomerPortfolioAnalysesBatchCustomerFundAnalysesPath: Utils.route([["customer_portfolio_analyses_batch_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"customer_portfolio_analyses_batches",false],[2,[7,"/",false],[2,[3,"customer_portfolio_analyses_batch_id",false],[2,[7,"/",false],[2,[6,"customer_fund_analyses",false],[2,[7,"/",false],[2,[6,"options_list",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// options_list_api_v1_portfolio_analysis_companies => /api/v1/portfolio_analyses/:portfolio_analysis_id/companies/options_list(.:format)
  // function(portfolio_analysis_id, options)
  optionsListApiV1PortfolioAnalysisCompaniesPath: Utils.route([["portfolio_analysis_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"portfolio_analyses",false],[2,[7,"/",false],[2,[3,"portfolio_analysis_id",false],[2,[7,"/",false],[2,[6,"companies",false],[2,[7,"/",false],[2,[6,"options_list",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// options_list_api_v1_portfolio_analysis_customer_fund_analyses => /api/v1/portfolio_analyses/:portfolio_analysis_id/customer_fund_analyses/options_list(.:format)
  // function(portfolio_analysis_id, options)
  optionsListApiV1PortfolioAnalysisCustomerFundAnalysesPath: Utils.route([["portfolio_analysis_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"portfolio_analyses",false],[2,[7,"/",false],[2,[3,"portfolio_analysis_id",false],[2,[7,"/",false],[2,[6,"customer_fund_analyses",false],[2,[7,"/",false],[2,[6,"options_list",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// rails_blob_representation => /rails/active_storage/representations/:signed_blob_id/:variation_key/*filename(.:format)
  // function(signed_blob_id, variation_key, filename, options)
  railsBlobRepresentationPath: Utils.route([["signed_blob_id",true],["variation_key",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"representations",false],[2,[7,"/",false],[2,[3,"signed_blob_id",false],[2,[7,"/",false],[2,[3,"variation_key",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// rails_conductor_inbound_email => /rails/conductor/action_mailbox/inbound_emails/:id(.:format)
  // function(id, options)
  railsConductorInboundEmailPath: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// rails_conductor_inbound_email_reroute => /rails/conductor/action_mailbox/:inbound_email_id/reroute(.:format)
  // function(inbound_email_id, options)
  railsConductorInboundEmailReroutePath: Utils.route([["inbound_email_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[3,"inbound_email_id",false],[2,[7,"/",false],[2,[6,"reroute",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// rails_conductor_inbound_emails => /rails/conductor/action_mailbox/inbound_emails(.:format)
  // function(options)
  railsConductorInboundEmailsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_direct_uploads => /rails/active_storage/direct_uploads(.:format)
  // function(options)
  railsDirectUploadsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"direct_uploads",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// rails_disk_service => /rails/active_storage/disk/:encoded_key/*filename(.:format)
  // function(encoded_key, filename, options)
  railsDiskServicePath: Utils.route([["encoded_key",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"disk",false],[2,[7,"/",false],[2,[3,"encoded_key",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// rails_info => /rails/info(.:format)
  // function(options)
  railsInfoPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"info",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// rails_info_properties => /rails/info/properties(.:format)
  // function(options)
  railsInfoPropertiesPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"info",false],[2,[7,"/",false],[2,[6,"properties",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// rails_info_routes => /rails/info/routes(.:format)
  // function(options)
  railsInfoRoutesPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"info",false],[2,[7,"/",false],[2,[6,"routes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// rails_mailers => /rails/mailers(.:format)
  // function(options)
  railsMailersPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"mailers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// rails_mailgun_inbound_emails => /rails/action_mailbox/mailgun/inbound_emails/mime(.:format)
  // function(options)
  railsMailgunInboundEmailsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"mailgun",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[6,"mime",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// rails_mandrill_inbound_emails => /rails/action_mailbox/mandrill/inbound_emails(.:format)
  // function(options)
  railsMandrillInboundEmailsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"mandrill",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_mandrill_inbound_health_check => /rails/action_mailbox/mandrill/inbound_emails(.:format)
  // function(options)
  railsMandrillInboundHealthCheckPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"mandrill",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_postmark_inbound_emails => /rails/action_mailbox/postmark/inbound_emails(.:format)
  // function(options)
  railsPostmarkInboundEmailsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"postmark",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_relay_inbound_emails => /rails/action_mailbox/relay/inbound_emails(.:format)
  // function(options)
  railsRelayInboundEmailsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"relay",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_sendgrid_inbound_emails => /rails/action_mailbox/sendgrid/inbound_emails(.:format)
  // function(options)
  railsSendgridInboundEmailsPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"sendgrid",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_service_blob => /rails/active_storage/blobs/:signed_id/*filename(.:format)
  // function(signed_id, filename, options)
  railsServiceBlobPath: Utils.route([["signed_id",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"blobs",false],[2,[7,"/",false],[2,[3,"signed_id",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// refresh_admin_api_v1_fund_fund_portfolios => /admin/api/v1/funds/:fund_id/fund_portfolios/refresh(.:format)
  // function(fund_id, options)
  refreshAdminApiV1FundFundPortfoliosPath: Utils.route([["fund_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"funds",false],[2,[7,"/",false],[2,[3,"fund_id",false],[2,[7,"/",false],[2,[6,"fund_portfolios",false],[2,[7,"/",false],[2,[6,"refresh",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// refresh_integration_api_v1_moneyguide_auth_tokens => /integration/api/v1/moneyguide/auth_tokens/refresh(.:format)
  // function(options)
  refreshIntegrationApiV1MoneyguideAuthTokensPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"integration",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"moneyguide",false],[2,[7,"/",false],[2,[6,"auth_tokens",false],[2,[7,"/",false],[2,[6,"refresh",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// reset_api_v1_customer_customer_lens => /api/v1/customer/customer_lenses/:id/reset(.:format)
  // function(id, options)
  resetApiV1CustomerCustomerLensPath: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"customer",false],[2,[7,"/",false],[2,[6,"customer_lenses",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"reset",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// root => /
  // function(options)
  rootPath: Utils.route([], {}, [7,"/",false]),
// sidekiq_web => /admin/sidekiq
  // function(options)
  sidekiqWebPath: Utils.route([], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[6,"sidekiq",false]]]]),
// stop_impersonating_admin_api_v1_users => /admin/api/v1/users/stop_impersonating(.:format)
  // function(options)
  stopImpersonatingAdminApiV1UsersPath: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"stop_impersonating",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// update_rails_disk_service => /rails/active_storage/disk/:encoded_token(.:format)
  // function(encoded_token, options)
  updateRailsDiskServicePath: Utils.route([["encoded_token",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"disk",false],[2,[7,"/",false],[2,[3,"encoded_token",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]])}
;
      routes.configure = function(config) {
        return Utils.configure(config);
      };
      routes.config = function() {
        return Utils.config();
      };
      Object.defineProperty(routes, 'defaults', {
        get: function() {
          throw new Error("Routes" + ".defaults is removed. Use " + "Routes" + ".configure() instead.");
        },
        set: function(value) {}
      });
      routes.default_serializer = function(object, prefix) {
        return Utils.default_serializer(object, prefix);
      };
      return Utils.namespace(root, "Routes", routes);
    }
  };

  result = Utils.make();

  if (typeof define === "function" && define.amd) {
    define([], function() {
      return result;
    });
  }

  return result;

}).call(this);
