import { createMuiTheme } from '@material-ui/core/styles';
import { defaultTheme } from 'react-admin';

import overrides from './overrides';

const theme = createMuiTheme(defaultTheme, {
  overrides,
});

export default theme;
