import PropTypes from 'prop-types';
import Presenter from 'utils/PropTypesPresenter';

import PillarPresenter from 'presenters/PillarPresenter';

export const E_CATEGORIES = {
  energyUse: {
    name: 'Energy Use',
    slug: 'E1',
  },
  waterUse: {
    name: 'Water Use',
    slug: 'E2',
  },
  emissions: {
    name: 'Emissions',
    slug: 'E3',
  },
  compliance: {
    name: 'Compliance',
    slug: 'E4',
  },
  packaging: {
    name: 'Packaging',
    slug: 'E5',
  },
  environmentalPolicies: {
    name: 'Environmental Policies',
    slug: 'E6',
  },
};
export const S_CATEGORIES = {
  energyUse: {
    name: 'Gender Equality',
    slug: 'S1',
  },
  waterUse: {
    name: 'Diversity',
    slug: 'S2',
  },
  emissions: {
    name: 'Product Safety',
    slug: 'S3',
  },
  compliance: {
    name: 'Employee Benefits',
    slug: 'S4',
  },
  packaging: {
    name: 'Human Rights',
    slug: 'S5',
  },
  environmentalPolicies: {
    name: 'Business Ethics',
    slug: 'S6',
  },
};
export const G_CATEGORIES = {
  energyUse: {
    name: 'Board Governance',
    slug: 'G1',
  },
  waterUse: {
    name: 'Board Compensation',
    slug: 'G2',
  },
  emissions: {
    name: 'Executive Compensation',
    slug: 'G3',
  },
  compliance: {
    name: 'Community Investment',
    slug: 'G4',
  },
  packaging: {
    name: 'CEO/Chair Separation',
    slug: 'G5',
  },
  environmentalPolicies: {
    name: 'Shareholder Rights',
    slug: 'G6',
  },
};

export const CATEGORIES = { ...E_CATEGORIES, ...S_CATEGORIES, ...G_CATEGORIES };

export default new Presenter(
  {
    id: PropTypes.number,
    name: PropTypes.string,
    displayOrder: PropTypes.number,
    slug: PropTypes.string,
    pillarId: PropTypes.number,
    extendedDescription: PropTypes.string,
    imageUrl: PropTypes.string,
    pillar: PillarPresenter.shape(),
  },
  {},
);
