import React from 'react';
import { BooleanInput, Create, NumberInput, SimpleForm, TextInput, ReferenceInput, SelectInput } from 'react-admin';

const InstitutionCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput label="External ID" source="external_id" />
      <ReferenceInput label="Partner ID" source="partner_id" reference="partners" allowEmpty>
        <SelectInput optionText="id" />
      </ReferenceInput>
      <NumberInput source="first_year" />
      <NumberInput source="third_year" />
      <NumberInput source="fifth_year" />
      <NumberInput source="standard_deviation" />
      <BooleanInput source="recommendations_available" />
    </SimpleForm>
  </Create>
);

export default InstitutionCreate;
