import React from 'react';
import useStyles from './useStyles';
import PropTypes from 'prop-types';

const Tooltip = props => {
  const classes = useStyles();
  const {
    onTooltipMouseEnter,
    onTooltipMouseLeave,
    tooltipPosition,
    tooltipDescription,
    onViewDetailsClick,
    isTooltipVisible,
  } = props;
  const { top, left, marginLeft } = tooltipPosition;

  return (
    <div className={classes.root}>
      {isTooltipVisible && (
        <div
          className={classes.customTooltip}
          style={{ top, left, marginLeft }}
          onMouseEnter={onTooltipMouseEnter}
          onMouseLeave={onTooltipMouseLeave}
        >
          <div className={classes.content}>
            <div className={classes.description}>{tooltipDescription}</div>
            <button onClick={onViewDetailsClick} className={classes.link} type="button">
              View details
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

Tooltip.propTypes = {
  onTooltipMouseEnter: PropTypes.func.isRequired,
  onTooltipMouseLeave: PropTypes.func.isRequired,
  tooltipPosition: PropTypes.shape({
    top: PropTypes.number,
    left: PropTypes.number,
    marginLeft: PropTypes.number,
  }),
  tooltipDescription: PropTypes.string.isRequired,
  onViewDetailsClick: PropTypes.func,
  isTooltipVisible: PropTypes.bool.isRequired,
};

export default Tooltip;
