import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router';
import { useParams, useHistory } from 'react-router-dom';

import AppRoutes from 'routes/AppRoutes';
import { objectToQueryString } from 'utils/urlUtils';

import CustomerFundsReportPresenter from 'presenters/CustomerFundsReportPresenter';
import CustomerFundAnalysisPresenter from 'presenters/CustomerFundAnalysisPresenter';
import TickerMetaPresenter from 'presenters/TickerMetaPresenter';
import CompanyPresenter from 'presenters/CompanyPresenter';

import IssuesDefined from 'components/IssuesDefined';
import PercentValueHeld from 'containers/PercentValueHeld';
import CompaniesScored from 'containers/CompaniesScored';

import Side from './components/Side';

import useStyles from './useStyles';

const Reports = props => {
  const {
    customerFundsReport,
    customerFundAnalyses,
    flaggedTickers,
    selectedFundIds,
    currentCompanyId,
    onSetSelectedFundIds,
    onSetCurrentCompanyId,
    scoredCompanies,
    isMultipleAccountMode,
    selectedAccountIds,
    scoredCompaniesOptions,
    isScoredCompaniesOptionsLoading,
    currentCompany,
    isCurrentCompanyLoading,
  } = props;

  const classes = useStyles();
  const routeParams = useParams();
  const history = useHistory();
  const customerLensId = Number(routeParams.id);
  const search = objectToQueryString({ account_ids: selectedAccountIds });

  const handleViewCompanyDetailsClick = (company, fundIds) => {
    const scoredCompanyTickers = scoredCompanies.map(scoredCompany => CompanyPresenter.ticker(scoredCompany));

    if (scoredCompanyTickers.includes(TickerMetaPresenter.companyTicker(company))) {
      onSetCurrentCompanyId(TickerMetaPresenter.companyId(company));
      onSetSelectedFundIds(fundIds);

      const companiesScoredLink = isMultipleAccountMode
        ? AppRoutes.multipleAccountsPortfolioAnalysisCompaniesScoredPath(customerLensId)
        : AppRoutes.portfolioAnalysisCompaniesScoredPath(customerLensId);

      history.push({ pathname: companiesScoredLink, search });
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.side}>
        <Side
          customerFundsReport={customerFundsReport}
          isMultipleAccountMode={isMultipleAccountMode}
          selectedAccountIds={selectedAccountIds}
        />
      </div>
      <div className={classes.panel}>
        <Switch>
          <Route
            path={[
              AppRoutes.portfolioAnalysisIssuesDefinedPath(':id'),
              AppRoutes.multipleAccountsPortfolioAnalysisIssuesDefinedPath(':id'),
            ]}
            exact
          >
            <IssuesDefined
              customerFundAnalyses={customerFundAnalyses}
              flaggedTickers={flaggedTickers}
              selectedFundIds={selectedFundIds}
              onSetSelectedFundIds={onSetSelectedFundIds}
              onClickViewDetails={handleViewCompanyDetailsClick}
              scoredCompanies={scoredCompanies}
            />
          </Route>
          <Route
            path={[
              AppRoutes.portfolioAnalysisPathPercentValueHeldPath(':id'),
              AppRoutes.multipleAccountsPortfolioAnalysisPathPercentValueHeldPath(':id'),
            ]}
            exact
          >
            <PercentValueHeld
              customerFundAnalyses={customerFundAnalyses}
              onClickViewDetails={handleViewCompanyDetailsClick}
              selectedFundIds={selectedFundIds}
              onSetSelectedFundIds={onSetSelectedFundIds}
              chartID="reports"
              isMultipleAccountMode={isMultipleAccountMode}
            />
          </Route>
          <Route
            path={[
              AppRoutes.portfolioAnalysisCompaniesScoredPath(':id'),
              AppRoutes.multipleAccountsPortfolioAnalysisCompaniesScoredPath(':id'),
            ]}
            exact
          >
            <CompaniesScored
              customerFundAnalyses={customerFundAnalyses}
              onSetCurrentCompanyId={onSetCurrentCompanyId}
              onSetSelectedFundIds={onSetSelectedFundIds}
              selectedFundIds={selectedFundIds}
              currentCompanyId={currentCompanyId}
              currentCompany={currentCompany}
              isCurrentCompanyLoading={isCurrentCompanyLoading}
              scoredCompaniesOptions={scoredCompaniesOptions}
              isScoredCompaniesOptionsLoading={isScoredCompaniesOptionsLoading}
              variant="embedded"
            />
          </Route>
        </Switch>
      </div>
    </div>
  );
};

Reports.propTypes = {
  customerFundsReport: CustomerFundsReportPresenter.shape().isRequired,
  flaggedTickers: PropTypes.arrayOf(PropTypes.shape()),
  customerFundAnalyses: PropTypes.arrayOf(CustomerFundAnalysisPresenter.shape()),
  onSetSelectedFundIds: PropTypes.func.isRequired,
  onSetCurrentCompanyId: PropTypes.func.isRequired,
  selectedFundIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  currentCompanyId: PropTypes.number,
  scoredCompanies: PropTypes.arrayOf(CompanyPresenter.shape()).isRequired,
  isMultipleAccountMode: PropTypes.bool,
  selectedAccountIds: PropTypes.arrayOf(PropTypes.number),
  scoredCompaniesOptions: PropTypes.arrayOf(CompanyPresenter.shape()).isRequired,
  isScoredCompaniesOptionsLoading: PropTypes.bool.isRequired,
  currentCompany: CompanyPresenter.shape().isRequired,
  isCurrentCompanyLoading: PropTypes.bool.isRequired,
};

Reports.defaultProps = {
  currentCompanyId: null,
  isMultipleAccountMode: false,
  selectedAccountIds: [],
};

export default Reports;
