import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  theme => ({
    '@keyframes blink': {
      from: {
        transform: 'scale(1)',
        filter: 'brightness(1.6)',
      },
      to: {
        transform: 'scale(1.2875)',
      },
    },
    alertEnter: {},
    alertEnterActive: {
      animation: '200ms ease-in-out alternate $blink',
    },
    alertExit: {},
    alertExitActive: {},
    item: {
      display: 'flex',
      '&:last-child': {
        '& $side': {
          '&::after': {
            backgroundColor: 'transparent',
          },
        },
        '& $mainContent': {
          '&::after': {
            backgroundColor: 'transparent',
          },
        },
      },
      '&:first-child $side': {
        paddingTop: 17,
      },
    },
    side: {
      width: 240,
      backgroundColor: theme.palette.background.bossanova,
      color: '#fff',
      paddingTop: 48,
      flexShrink: 0,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingBottom: 20,
      position: 'relative',
      '&::after': {
        content: '""',
        height: 2,
        backgroundColor: '#fff',
        width: 'calc(100% - 37px)',
        left: 0,
        marginLeft: 37,
        position: 'absolute',
        bottom: 0,
      },
      [theme.breakpoints.up('md')]: {
        width: 300,
      },
    },
    icon: {
      marginBottom: 17,
    },
    title: {
      margin: 0,
      color: '#fff',
      lineHeight: '20px',
      textAlign: 'center',
      marginBottom: 17,
      fontFamily: theme.typography.font.ttHovesPro,
      fontSize: 19,
      fontWeight: 700,
    },
    mainContent: {
      flexGrow: 1,
      paddingLeft: 31,
      paddingRight: 60,
      position: 'relative',
      marginTop: 25,
      paddingBottom: 26,
      '&::after': {
        content: '""',
        height: 2,
        backgroundColor: '#000',
        width: 'calc(100% - 60px)',
        right: 0,
        marginRight: 60,
        position: 'absolute',
        bottom: 0,
      },
    },
    name: {
      width: 185,
      fontWeight: 'bold',
      [theme.breakpoints.up('md')]: {
        width: 292,
      },
    },
    checkboxName: {
      textAlign: 'center',
      fontWeight: 'bold',
    },
    buttonRoot: {
      borderColor: '#fff',
    },
    buttonLabel: {
      color: '#fff',
    },
    selectRoot: {
      border: '1px solid #fff',
      borderRadius: 5,
      color: '#fff',
      fontWeight: 600,
      fontSize: 12,
      padding: '11px 30px 11px 10px',
      textTransform: 'uppercase',
      '&:focus': {
        borderRadius: 5,
      },
    },
    selectIcon: {
      color: '#fff',
    },
    table: {
      width: '100%',
      borderSpacing: 0,
      tableLayout: 'fixed',
      '& tbody': {
        '& td': {
          borderBottom: '1px solid #e4e4e4',
          padding: 0,
          lineHeight: '31px',
        },
        '& tr': {
          '&:last-child': {
            '& td': {
              borderBottom: '1px solid transparent',
            },
          },
        },
      },
    },
  }),
  { name: 'Pillar' },
);

export default useStyles;
