import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  () => ({
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    pillar: {
      display: 'flex',
      alignItems: 'center',
    },
    name: {
      marginLeft: 16,
      fontWeight: 300,
      fontSize: 17,
      letterSpacing: '0.01em',
    },
    chipRoot: {
      borderColor: '#fff',
      height: 30,
    },
    chipLabel: {
      color: '#fff',
    },
  }),
  { name: 'KeyAreasItem' },
);

export default useStyles;
