import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  chart: {
    width: '100%',
    maxWidth: 803,
    height: 355,
    marginBottom: 140,
    position: 'relative',
    marginTop: 64,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  barChart: {
    '& svg': {
      overflow: 'initial',
    },
    '@global': {
      '.recharts-label': {
        fontSize: 13,
        fontFamily: theme.typography.font.ttHovesPro,
        fontWeight: 300,
        fill: '#515151',
        transform: 'translate(-25px, 0px)',
      },
    },
  },
  customTooltip: {
    position: 'absolute',
    backgroundColor: '#232020',
    width: 300,
    borderRadius: 5,
    transform: 'translate(-50%, -100%)',
    marginTop: 10,
  },
  content: {
    padding: '27px 37px',
    position: 'relative',
    '&:after': {
      content: '""',
      width: 0,
      height: 0,
      borderStyle: 'solid',
      borderWidth: '20px 15px 0 15px',
      borderColor: '#232020 transparent transparent transparent',
      position: 'absolute',
      bottom: 0,
      left: '50%',
      transform: 'translateX(-50%)',
      marginBottom: '-9px',
    },
  },
  description: {
    fontFamily: theme.typography.font.ttHovesPro,
    fontWeight: 500,
    fontSize: 17,
    color: '#FFFFFF',
    textAlign: 'left',
    lineHeight: '18px',
  },
}));

export default useStyles;
