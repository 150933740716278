import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    backdropFilter: 'blur(4px)',
  },
  paper: {
    boxShadow: 'inset -4px 4px 15px 0 rgba(0,0,0,0.10)',
    border: '1px solid #e1e1e1',
    maxWidth: 774,
    width: '100%',
  },
  wide: {
    '& $paper': {
      maxWidth: '100%',
      [theme.breakpoints.up('md')]: {
        maxWidth: '75%',
      },
    },
  },
  header: {
    paddingTop: 15,
    paddingRight: 15,
  },
  close: {
    marginLeft: 'auto',
    border: 'none',
    backgroundColor: 'transparent',
  },
}));

export default useStyles;
