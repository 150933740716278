import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import MobileStepper from '@material-ui/core/MobileStepper';
import { useLocation, matchPath } from 'react-router-dom';
import AppRoutes from 'routes/AppRoutes';
import { isNil, find } from 'ramda';

import useStyles from './useStyles';

const stepsWithRecommendation = [
  { id: 1, title: 'Step 1 / Identify Values' },
  { id: 2, title: 'Step 2 / View Report' },
  { id: 3, title: 'Step 3 / Align Portfolio' },
];
const stepsWithoutRecommendation = [
  { id: 1, title: 'Step 1 / Identify Values' },
  { id: 2, title: 'Step 2 / View Report' },
];

const activeStepMappingWithRecommendation = [
  { id: 1, value: 12.5, path: AppRoutes.customerLensLensesPath() },
  { id: 1, value: 25, path: AppRoutes.customerLensValuesPath(':id') },
  { id: 1, value: 37.5, path: AppRoutes.customerLensFlagsPath(':id') },
  { id: 2, value: 50, path: AppRoutes.portfolioAnalysisPath(':id') },
  { id: 2, value: 50, path: AppRoutes.portfolioAnalysisCompaniesScoredPath(':id') },
  { id: 2, value: 50, path: AppRoutes.portfolioAnalysisIssuesDefinedPath(':id') },
  { id: 2, value: 50, path: AppRoutes.portfolioAnalysisPathPercentValueHeldPath(':id') },
  { id: 3, value: 100, path: AppRoutes.recommendationsPath(':recommendationId') },
];
const activeStepMappingWithoutRecommendation = [
  { id: 1, value: 25, path: AppRoutes.customerLensLensesPath() },
  { id: 1, value: 50, path: AppRoutes.customerLensValuesPath(':id') },
  { id: 1, value: 75, path: AppRoutes.customerLensFlagsPath(':id') },
  { id: 2, value: 100, path: AppRoutes.portfolioAnalysisPath(':id') },
  { id: 2, value: 100, path: AppRoutes.portfolioAnalysisCompaniesScoredPath(':id') },
  { id: 2, value: 100, path: AppRoutes.portfolioAnalysisIssuesDefinedPath(':id') },
  { id: 2, value: 100, path: AppRoutes.portfolioAnalysisPathPercentValueHeldPath(':id') },
];

const Progress = props => {
  const { recommendationsAvailable } = props;
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState({ value: 0 });
  const [userSteps, setUserSteps] = useState([]);
  const [userActiveStepMapping, setUserActiveStepMapping] = useState([]);
  const location = useLocation();
  const stepsCount = userSteps.length;

  const changeActiveStep = () => {
    const mappedActiveStep = find(
      ({ path }) => matchPath(location.pathname, { path, exact: true }),
      userActiveStepMapping,
    );
    if (!isNil(mappedActiveStep)) {
      setActiveStep(mappedActiveStep);
    }
  };

  useEffect(() => {
    changeActiveStep();
  }, [userSteps, location]);

  useEffect(() => {
    if (recommendationsAvailable) {
      setUserSteps(stepsWithRecommendation);
      setUserActiveStepMapping(activeStepMappingWithRecommendation);
    } else {
      setUserSteps(stepsWithoutRecommendation);
      setUserActiveStepMapping(activeStepMappingWithoutRecommendation);
    }
  }, [recommendationsAvailable]);

  const renderStep = step => {
    const styles = clsx([classes.step], { [classes.activeStep]: activeStep.id >= step.id });
    return (
      <div className={styles} key={step.id}>
        {step.title}
      </div>
    );
  };

  return (
    <>
      <div className={classes.steps}>{userSteps.map(renderStep)}</div>
      <MobileStepper
        variant="progress"
        position="static"
        LinearProgressProps={{ value: activeStep.value, variant: 'determinate' }}
        steps={stepsCount}
      />
    </>
  );
};

Progress.propTypes = {
  recommendationsAvailable: PropTypes.bool.isRequired,
};

export default Progress;
