import React from 'react';
import { EditButton } from 'react-admin';

import LinkToFundPortfolios from './components/LinkToFundPortfolios';
import FundRefreshPortfolio from './components/FundRefreshPortfolio';
import useStyles from './useStyles';

const RowActions = props => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.links}>
        <LinkToFundPortfolios {...props} />
      </div>
      <div>
        <FundRefreshPortfolio {...props} />
        <EditButton {...props} />
      </div>
    </div>
  );
};

export default RowActions;
