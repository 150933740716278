import Routes from 'routes/ApiRoutes';
import FetchHelpers from 'utils/FetchHelpers';

export default {
  create(householdId, params) {
    const path = Routes.apiV1AdvisorHouseholdCustomerLensesPath(householdId);
    return FetchHelpers.post(path, params);
  },
  duplicate(householdId, customerLensId) {
    const path = Routes.duplicateApiV1AdvisorHouseholdCustomerLensPath(householdId, customerLensId);
    return FetchHelpers.post(path);
  },
};
