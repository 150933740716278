import PropTypes from 'prop-types';
import { sortBy } from 'ramda';

import Presenter from 'utils/PropTypesPresenter';

import CustomerLensFlagPresenter from 'presenters/CustomerLensFlagPresenter';
import CustomerPillarValuePresenter from 'presenters/CustomerPillarValuePresenter';
import CustomerCategoryValuePresenter from 'presenters/CustomerCategoryValuePresenter';
import PillarPresenter from 'presenters/PillarPresenter';
import CategoryPresenter from 'presenters/CategoryPresenter';

export default new Presenter(
  {
    id: PropTypes.number,
    customerPillarValues: PropTypes.arrayOf(CustomerPillarValuePresenter.shape()),
    customerCategoryValues: PropTypes.arrayOf(CustomerCategoryValuePresenter.shape()),
    customerLensFlags: PropTypes.arrayOf(CustomerLensFlagPresenter.shape()),
    selectedLenses: PropTypes.arrayOf(PropTypes.shape()),
    lensDefault: PropTypes.boolean,
  },
  {
    sortedCustomerPillarValues(customerLens) {
      const getDisplayOrder = customerPillarValue => {
        const pillar = CustomerPillarValuePresenter.pillar(customerPillarValue);
        return PillarPresenter.displayOrder(pillar);
      };

      return sortBy(getDisplayOrder, this.customerPillarValues(customerLens));
    },
    sortedCustomerCategoryValues(customerLens) {
      const getDisplayOrder = customerCategoryValue => {
        const category = CustomerCategoryValuePresenter.category(customerCategoryValue);
        return CategoryPresenter.displayOrder(category);
      };

      return sortBy(getDisplayOrder, this.customerCategoryValues(customerLens));
    },
  },
);
