import PropTypes from 'prop-types';

export const customerShape = PropTypes.shape({
  customerName: PropTypes.string.isRequired,
  email: PropTypes.string,
  birthDate: PropTypes.instanceOf(Date),
});

export const tickerShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  amount: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  type: PropTypes.oneOf(['company', 'fund']).isRequired,
  entityName: PropTypes.string.isRequired,
}).isRequired;

export const accountShape = PropTypes.shape({
  accountName: PropTypes.string.isRequired,
  accountOwner: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  tickers: PropTypes.arrayOf(tickerShape).isRequired,
});

export const householdShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  customers: PropTypes.arrayOf(customerShape).isRequired,
  accounts: PropTypes.arrayOf(accountShape).isRequired,
}).isRequired;

export const valuesShape = PropTypes.shape({
  household: householdShape,
}).isRequired;
