/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import ConfigurationsRepository from 'repositories/admin/ConfigurationsRepository';
import { FETCH_STATUSES } from 'presenters/FetchStatusPresenter';

const initialState = {
  configurations: {},
  status: FETCH_STATUSES.idle,
  errors: {},
};

const configurationsSlice = createSlice({
  name: 'configurations',
  initialState,
  reducers: {
    updateConfigurationsStart(state) {
      state.status = FETCH_STATUSES.loading;
    },
    updateConfigurationsSuccess(state, { payload }) {
      state.configurations = payload;
      state.status = FETCH_STATUSES.success;
    },
    updateConfigurationsRejected(state, { payload }) {
      state.status = FETCH_STATUSES.failure;
      state.configurations = [];
      state.errors = payload;
    },
    loadConfigurationsStart(state) {
      state.status = FETCH_STATUSES.loading;
    },
    loadConfigurationsSuccess(state, { payload }) {
      state.status = FETCH_STATUSES.success;
      state.configurations = payload;
    },
    loadConfigurationsRejected(state, { payload }) {
      state.status = FETCH_STATUSES.failure;
      state.configurations = [];
      state.errors = payload;
    },
  },
});

export const {
  updateConfigurationsStart,
  updateConfigurationsSuccess,
  updateConfigurationsRejected,
  loadConfigurationsStart,
  loadConfigurationsSuccess,
  loadConfigurationsRejected,
} = configurationsSlice.actions;

export default configurationsSlice.reducer;

export const useConfigurationsActions = () => {
  const dispatch = useDispatch();

  const updateConfigurations = async params => {
    dispatch(updateConfigurationsStart());

    try {
      const { configuration } = await ConfigurationsRepository.update(params);
      dispatch(updateConfigurationsSuccess(configuration));
      return configuration;
    } catch (error) {
      dispatch(updateConfigurationsRejected(error));
      return error;
    }
  };

  const loadConfigurations = async () => {
    dispatch(loadConfigurationsStart());

    try {
      const {
        data: { configuration },
      } = await ConfigurationsRepository.show();
      dispatch(loadConfigurationsSuccess(configuration));

      return configuration;
    } catch (error) {
      dispatch(loadConfigurationsRejected(error));
      return error;
    }
  };

  return {
    updateConfigurations,
    loadConfigurations,
  };
};
