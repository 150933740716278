import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex',
      width: '100%',
      padding: '45px 0',
      [theme.breakpoints.up('md')]: {
        padding: '109px 0',
      },
    },
    main: {
      flexGrow: 1,
      width: '100%',
      overflow: 'hidden',
    },
    titleWrapper: {
      display: 'flex',
      justifyContent: 'center',
    },
    title: {
      fontFamily: theme.typography.font.ttHovesPro,
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: 52,
      color: '#232020',
      textAlign: 'center',
    },
    subTitle: {
      fontFamily: theme.typography.font.ttHovesPro,
      fontWeight: 700,
      fontSize: 18,
      color: '#232020',
      textAlign: 'center',
      lineHeight: '20px',
      marginBottom: 12,
      width: '100%',
      maxWidth: 430,
      margin: '0 auto',
    },
  }),
  { name: 'SecuritiesList' },
);

export default useStyles;
