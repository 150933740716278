import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  chart: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    height: 560,
    position: 'relative',
  },
}));

export default useStyles;
