import React from 'react';
import { TextField, Create, SimpleForm, TextInput } from 'react-admin';

const GicsSubIndustryCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextField source="id" />
      <TextInput source="name" />
      <TextInput source="code" />
      <TextInput source="gics_sector_id" />
    </SimpleForm>
  </Create>
);

export default GicsSubIndustryCreate;
