import Routes from 'routes/ApiRoutes';
import FetchHelpers from 'utils/FetchHelpers';

export default {
  index(portfolioAnalysisId, params) {
    const path = Routes.apiV1PortfolioAnalysisCompaniesPath(portfolioAnalysisId);
    return FetchHelpers.get(path, params);
  },
  show(portfolioAnalysisId, companyId, params) {
    const path = Routes.apiV1PortfolioAnalysisCompanyPath(portfolioAnalysisId, companyId);
    return FetchHelpers.get(path, params);
  },
  optionsList(portfolioAnalysisId, params) {
    const path = Routes.optionsListApiV1PortfolioAnalysisCompaniesPath(portfolioAnalysisId);
    return FetchHelpers.get(path, params);
  },
};
