export const EMAIL = 'Please enter an email';
export const REQUIRED = "Can't be blank";
export const SPACE_IN_PASSWORD = 'Spaces are not allowed for passwords.';
export const PASSWORDS_DONT_MATCHES = "Passwords don't match";
export const TICKERS_LIMIT = limit => `Can't add more than ${limit} tickers`;
export const NUMBER = 'Must be a number';
export const POSITIVE = 'Number must be positive';
export const EARLIER_THAN_ACTUAL_DATE = 'Must be earlier than actual date';
export const DATE = 'Must be a date';
export const REQUIRED_FILE = 'File is required';
