import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';

import AppRoutes from 'routes/AppRoutes';

import SignIn from 'components/SignIn';
import ManuallyEnteredPortfolio from 'containers/ManuallyEnteredPortfolio';

const NotAuthenticatedUserSwitch = props => {
  const { onSignIn, onPrepareApp } = props;

  return (
    <Switch>
      <Route path={AppRoutes.signInPath()} exact>
        <SignIn onSignIn={onSignIn} />
      </Route>
      <Route path={AppRoutes.rootPath()} exact>
        <ManuallyEnteredPortfolio onPrepareApp={onPrepareApp} />
      </Route>

      <Redirect to={AppRoutes.signInPath()} />
    </Switch>
  );
};

NotAuthenticatedUserSwitch.propTypes = {
  onSignIn: PropTypes.func.isRequired,
  onPrepareApp: PropTypes.func.isRequired,
};

export default NotAuthenticatedUserSwitch;
