import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { equals, isEmpty, uniqBy } from 'ramda';
import { useReactToPrint } from 'react-to-print';
import clsx from 'clsx';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import IconButton from '@material-ui/core/IconButton';

import CustomerFundAnalysisPresenter from 'presenters/CustomerFundAnalysisPresenter';
import TickerMetaPresenter from 'presenters/TickerMetaPresenter';
import CompanyPresenter from 'presenters/CompanyPresenter';

import pdfIcon from 'assets/pdf.svg';

import Compare from 'components/Compare';
import IssuesDefinedPDF from 'components/IssuesDefinedPDF/IssuesDefinedPDF';

import Chart from './components/Chart';
import Table from './components/Table';
import TabPanel from './components/TabPanel';

import useStyles from './useStyles';

const INTERVAL_BEFORE_OPEN_PRINTING_FRAME = 500;

const IssuesDefined = props => {
  const {
    customerFundAnalyses,
    flaggedTickers,
    selectedFundIds,
    onSetSelectedFundIds,
    onClickViewDetails,
    scoredCompanies,
  } = props;
  const classes = useStyles();

  const [activeTab, setActiveTab] = useState(0);
  const [isPdfContentVisible, setIsPdfContentVisible] = useState(false);
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: '@page { size: letter; margin: 0px }',
    documentTitle: 'FlaggedCompanies.pdf',
    onBeforeGetContent: () => {
      return new Promise(resolve => {
        setIsPdfContentVisible(true);
        setTimeout(() => {
          resolve();
        }, INTERVAL_BEFORE_OPEN_PRINTING_FRAME);
      });
    },
    onAfterPrint: () => {
      setIsPdfContentVisible(false);
    },
  });

  if (isEmpty(customerFundAnalyses)) {
    return null;
  }

  const handleChangeActiveTab = (_, newTab) => {
    setActiveTab(newTab);
  };

  const handleChangeSelectedFunds = fundId => {
    if (equals([fundId], selectedFundIds)) {
      return;
    }

    const isFundSelected = selectedFundIds.some(id => id === fundId);

    if (isFundSelected) {
      const newSelectedFundIds = selectedFundIds.filter(id => id !== fundId);

      onSetSelectedFundIds(newSelectedFundIds);
      return;
    }

    onSetSelectedFundIds([...selectedFundIds, fundId]);
  };

  const selectedFunds = customerFundAnalyses.filter(({ id }) => selectedFundIds.includes(id));
  const selectedFundTickers = selectedFunds.map(({ ticker }) => ticker).join(', ');

  const tickersByFund = flaggedTickers.filter(ticker =>
    selectedFundIds.includes(TickerMetaPresenter.customerFundAnalysisId(ticker)),
  );
  const uniqTickers = uniqBy(({ companyId }) => companyId, tickersByFund);
  const sortedTickers = TickerMetaPresenter.sortedByTicker(uniqTickers);

  const uniqTickersCount = uniqTickers.length;

  const pdfContentClassNames = clsx({
    [classes.hidden]: !isPdfContentVisible,
    [classes.notVisible]: true,
  });

  return (
    <div>
      <div className={classes.root}>
        <div className={classes.main}>
          <div className={classes.titleWrapper}>
            <div className={classes.title}>Flagged Securities</div>
            <IconButton
              type="button"
              className={classes.PDFIcon}
              onClick={handlePrint}
              label="save to pdf"
              size="medium"
            >
              <img src={pdfIcon} alt="Save to PDF" />
            </IconButton>
          </div>
          <div className={classes.select}>
            <Compare
              list={customerFundAnalyses}
              selectedToCompare={selectedFundIds}
              onChange={handleChangeSelectedFunds}
              multiple
            />
          </div>
          <p className={classes.caption}>
            {isEmpty(selectedFundTickers) ? (
              'Please, select one or more funds.'
            ) : (
              <>
                {selectedFundTickers} has <strong>{uniqTickersCount}</strong> securities with one or more flags.
              </>
            )}
          </p>
          {uniqTickersCount > 0 && (
            <div className={classes.tabs}>
              <Tabs
                value={activeTab}
                onChange={handleChangeActiveTab}
                classes={{ indicator: classes.tabsIndicator, scroller: classes.tabsScroller, root: classes.tabsRoot }}
                centered
              >
                <Tab label="Securities" classes={{ wrapper: classes.tabWrapper, root: classes.tabRoot }} />
                <Tab label="% Value" classes={{ wrapper: classes.tabWrapper, root: classes.tabRoot }} />
              </Tabs>
              <TabPanel value={activeTab} index={0}>
                <Table
                  flaggedTickers={sortedTickers}
                  onCompanyClick={onClickViewDetails}
                  selectedFundIds={selectedFundIds}
                  scoredCompanies={scoredCompanies}
                />
              </TabPanel>
              <TabPanel value={activeTab} index={1}>
                <Chart selectedFunds={selectedFunds} flaggedTickers={sortedTickers} />
              </TabPanel>
            </div>
          )}
        </div>
      </div>
      <div className={pdfContentClassNames}>
        <div className={classes.pdfWrapper} ref={componentRef}>
          <IssuesDefinedPDF
            customerFundAnalyses={customerFundAnalyses}
            selectedFundIds={selectedFundIds}
            scoredCompanies={scoredCompanies}
            selectedFundTickers={selectedFundTickers}
            uniqTickersCount={uniqTickersCount}
            sortedTickers={sortedTickers}
            selectedFunds={selectedFunds}
          />
        </div>
      </div>
    </div>
  );
};

IssuesDefined.propTypes = {
  customerFundAnalyses: PropTypes.arrayOf(CustomerFundAnalysisPresenter.shape()).isRequired,
  flaggedTickers: PropTypes.arrayOf(TickerMetaPresenter.shape()).isRequired,
  onSetSelectedFundIds: PropTypes.func.isRequired,
  selectedFundIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  onClickViewDetails: PropTypes.func.isRequired,
  scoredCompanies: PropTypes.arrayOf(CompanyPresenter.shape()).isRequired,
};

export default IssuesDefined;
