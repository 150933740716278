import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    padding: 5,
  },
  disabled: {
    pointerEvents: 'none',
    cursor: 'default',
  },
  container: {
    padding: 0,
  },
}));

export default useStyles;
