import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { isEmpty } from 'ramda';

import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import HelpIcon from '@material-ui/icons/Help';
import exportIcon from 'assets/fileSave.svg';

import useStyles from './useStyles';

const AuxiliaryButtons = props => {
  const { onExport, helperText, templateName } = props;

  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const renderHelper = () => {
    return (
      <>
        <IconButton
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
          size="small"
          classes={{ root: classes.button, label: classes.buttonIcon }}
          aria-label="help"
        >
          <HelpIcon />
        </IconButton>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          disableRestoreFocus
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          className={classes.popover}
          classes={{ paper: classes.helperPopup }}
        >
          <p>{helperText}</p>
        </Popover>
      </>
    );
  };

  const renderExportButton = () => {
    return (
      <IconButton
        size="small"
        classes={{ root: classes.button, label: classes.buttonIcon }}
        onClick={() => onExport(templateName)}
        aria-label="export"
      >
        <img src={exportIcon} alt="Icon export" />
      </IconButton>
    );
  };

  return (
    <div className={classes.root}>
      {!isEmpty(helperText) && renderHelper()}
      {!isEmpty(templateName) && renderExportButton()}
    </div>
  );
};

AuxiliaryButtons.propTypes = {
  onExport: PropTypes.func.isRequired,
  templateName: PropTypes.string,
  helperText: PropTypes.string,
};

AuxiliaryButtons.defaultProps = {
  templateName: '',
  helperText: '',
};

export default AuxiliaryButtons;
