import { useEffect, useRef, useState } from 'react';

export default function useTimeout(callback, delay, ...params) {
  const timeoutRef = useRef(null);
  const savedCallback = useRef(callback);
  const [isTimerActive, setIsTimerActive] = useState(false);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  const disableTimer = () => {
    clearTimeout(timeoutRef.current);
    setIsTimerActive(false);
  };

  const enableTimer = () => {
    setIsTimerActive(true);

    const tick = (...tickParams) => {
      savedCallback.current(...tickParams);
      setIsTimerActive(false);
    };

    timeoutRef.current = setTimeout(tick, delay, ...params, { enableTimer, disableTimer });
  };

  return { enableTimer, disableTimer, isTimerActive };
}
