import Presenter from 'utils/PropTypesPresenter';

export const FETCH_STATUSES = {
  idle: 'idle',
  loading: 'loading',
  success: 'success',
  failure: 'failure',
};

export default new Presenter(
  {},
  {
    isLoading(status) {
      return status === FETCH_STATUSES.loading;
    },
  },
);
