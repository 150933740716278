import React from 'react';
import PropTypes from 'prop-types';

import AuxiliaryButtons from 'admin/components/AuxiliaryButtons';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

import useStyles from './useStyles';

const TEMPLATE_NAME = 'users_having_portfolio_analysis';
const HELPER_TEXT = 'Chart displays the percentage of clients that have lens and analyzed portfolio per age group';

const CustomersHavingPortfolioAnalysesChart = props => {
  const { data, onExport } = props;

  const classes = useStyles();

  return (
    <Paper className={classes.chart}>
      <AuxiliaryButtons onExport={onExport} helperText={HELPER_TEXT} templateName={TEMPLATE_NAME} />
      <Typography component="h2" variant="h5" color="primary">
        % Clients with Lens/Analyzed Portfolio (Age)
      </Typography>
      <BarChart width={500} height={350} data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="age" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="value" stackId="a" fill="#8884d8" />
      </BarChart>
    </Paper>
  );
};

CustomersHavingPortfolioAnalysesChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onExport: PropTypes.func.isRequired,
};

export default CustomersHavingPortfolioAnalysesChart;
