import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  customTooltip: {
    position: 'absolute',
    backgroundColor: '#232020',
    width: 300,
    borderRadius: 5,
    transform: 'translate(-50%, -100%)',
    marginTop: 10,
  },
  content: {
    padding: '27px 37px',
    position: 'relative',
    '&:after': {
      content: '""',
      width: 0,
      height: 0,
      borderStyle: 'solid',
      borderWidth: '20px 15px 0 15px',
      borderColor: '#232020 transparent transparent transparent',
      position: 'absolute',
      bottom: 0,
      left: '50%',
      transform: 'translateX(-50%)',
      marginBottom: '-9px',
    },
  },
  description: {
    fontFamily: theme.typography.font.ttHovesPro,
    fontWeight: 500,
    fontSize: 16,
    color: '#FFFFFF',
    textAlign: 'left',
    lineHeight: '18px',
    marginBottom: 20,
  },
  link: {
    fontFamily: theme.typography.font.ttHovesPro,
    fontWeight: 700,
    fontSize: 12,
    color: '#FFFFFF',
    letterSpacing: 0,
    lineHeight: '20px',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    backgroundRepeat: 'no-repeat',
    border: 'none',
    overflow: 'hidden',
    outline: 'none',
    padding: 0,
  },
}));

export default useStyles;
