import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  theme => ({
    footer: {
      backgroundColor: '#2B2B2B',
      padding: '40px 120px',
      color: '#fff',
      display: 'flex',
      justifyContent: 'space-between',
      flexShrink: 0,
    },
    docs: {
      fontSize: 12,
      color: '#fff',
      letterSpacing: 0,
      marginBottom: 11,
    },
    docsLink: {
      fontFamily: theme.typography.font.ttHovesPro,
      padding: 0,
      fontSize: 12,
      color: '#fff',
      letterSpacing: 0,
      textTransform: 'uppercase',
      fontWeight: 700,
    },
    logo: {
      alignSelf: 'flex-end',
    },
    actions: {
      display: 'flex',
      alignItems: 'flex-start',
    },
    action: {
      border: 'none',
      color: '#fff',
      cursor: 'pointer',
      backgroundColor: 'transparent',
      padding: 0,
      marginTop: 20,
      textDecoration: 'underline',
      fontFamily: theme.typography.font.ttHovesPro,
      fontWeight: 600,
      '&:not(:last-child)': {
        marginRight: 15,
      },
    },
    modal: {
      width: '90%',
      height: '90%',
      position: 'absolute',
      padding: 25,
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      background: '#FFF',
      overflowY: 'scroll',
    },
    footerImage: {
      maxWidth: 50,
    },
  }),
  { name: 'Footer' },
);

export default useStyles;
