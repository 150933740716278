import * as yup from 'yup';
import { nanoid } from 'nanoid';
import { omit, map, assoc, find, propEq, isNil } from 'ramda';
import { isNumber } from 'utils/numberHelpers';

import { COMPANY_TYPE } from 'enums/Ticker';
import { isBlank } from 'utils';
import { REQUIRED, NUMBER, POSITIVE, EARLIER_THAN_ACTUAL_DATE, DATE, EMAIL } from './errorsText';

export const initialCustomerValues = {
  id: nanoid(),
  customerName: '',
  birthDate: null,
  email: '',
};

export const initialAccountValues = {
  id: nanoid(),
  accountName: '',
  accountOwner: [],
  tickers: [],
};

export const initialHouseholdValues = {
  id: nanoid(),
  name: '',
  customers: [{ ...initialCustomerValues }],
  accounts: [{ ...initialAccountValues }],
};

export const initialValues = {
  household: { ...initialHouseholdValues },
};

export const validationFields = {
  household: yup.object().shape({
    name: yup.string().required(REQUIRED),
    customers: yup
      .array()
      .of(
        yup.object().shape({
          customerName: yup.string().required(REQUIRED),
          birthDate: yup
            .date()
            .typeError(DATE)
            .max(new Date(), EARLIER_THAN_ACTUAL_DATE)
            .nullable(),
          email: yup.string().email(EMAIL),
        }),
      )
      .required(REQUIRED),
    accounts: yup
      .array()
      .of(
        yup.object().shape({
          accountName: yup.string().required(REQUIRED),
          accountOwner: yup
            .array()
            .of(yup.string())
            .required(REQUIRED),
          tickers: yup
            .array()
            .of(
              yup.object().shape({
                name: yup.string(),
                amount: yup
                  .number()
                  .typeError(NUMBER)
                  .positive(POSITIVE)
                  .required(REQUIRED),
                assetClass: yup
                  .object()
                  .nullable()
                  .required(REQUIRED),
              }),
            )
            .required(REQUIRED),
        }),
      )
      .required(REQUIRED),
  }),
};

export const validationSchema = yup.object().shape({ ...validationFields });

export const attributesToSubmit = ({ household }) => {
  const normalizedAccountOwner = (accountOwner, customers) => {
    return accountOwner.reduce((acc, customerId) => {
      const customerIndex = customers.findIndex(customer => customer.id === customerId);
      return customerIndex !== -1 ? [...acc, customerIndex] : acc;
    }, []);
  };

  const normalizedAccounts = (accounts, customers) => {
    return accounts.map(account => {
      const { accountName, accountOwner, tickers, id } = account;

      const newTickers = map(tickerItem => assoc('assetClassId', tickerItem.assetClass.id, tickerItem), tickers);

      return {
        accountName,
        accountOwner: normalizedAccountOwner(accountOwner, customers),
        tickers: map(omit(['id', 'type', 'entityName', 'assetClass']), newTickers),
        id: isNumber(id) ? id : undefined,
      };
    });
  };
  const normalizedCustomers = customers => {
    return customers.map(customer => {
      const { email, customerName, birthDate, id } = customer;

      const normalizedBirthDate = isBlank(birthDate) ? birthDate : birthDate.toDateString();

      return {
        email,
        customerName,
        birthDate: normalizedBirthDate,
        id: isNumber(id) ? id : undefined,
      };
    });
  };
  const normalizedHousehold = householdParam => {
    const { name, customers, accounts } = householdParam;
    return {
      name,
      customers: normalizedCustomers(customers),
      accounts: normalizedAccounts(accounts, customers),
    };
  };

  return { household: normalizedHousehold(household) };
};

export const prepareImportedTickers = (importedTickers, assetClasses) => {
  const tickers = map(item => {
    const assetClass = find(propEq('id', item.assetClassId))(assetClasses);

    if (item.type === COMPANY_TYPE.fund) {
      return { ...item, fundId: item.id, assetClass };
    }
    if (item.type === COMPANY_TYPE.company) {
      return { ...item, companyId: item.id, assetClass };
    }
    return {
      ...item,
      assetClass,
      id: isNil(item.id) ? undefined : item.id,
      type: isNil(item.id) ? undefined : item.type,
    };
  }, importedTickers);

  return tickers;
};
