import React, { useEffect, useState, useRef } from 'react';
import { Formik } from 'formik';
import { head } from 'ramda';
import { Button, useNotify } from 'react-admin';
import Loader from 'react-loader';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

import { initialValues, validationSchema } from 'forms/FundImportRequestForm';
import InputFile from 'components/InputFile';
import { useFundImportRequests } from 'admin/hooks/useFundImportRequest';
import xlsxExample from 'assets/xlsx/fund_portfolio_example.xlsx';
import ImportRequestPresenter from 'presenters/ImportRequestPresenter';

import ImportTable from '../ImportTable';
import useStyles from './useStyles';

const REQUEST_INTERVAL_TIME = 1000;
const NOTIFY_DURATION = 5000;
const ERROR_NOTIFICATION_TYPE = 'error';

const ImportForm = () => {
  const classes = useStyles();
  const notify = useNotify();
  const [isUsePolling, setUsePolling] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isTableVisible, setIsTableVisible] = useState(false);
  const timerRef = useRef(null);

  const { createFundImportRequest, loadFundImportRequest, importRequestId, funds } = useFundImportRequests();

  const handlePollImportRequest = async () => {
    const importRequest = await loadFundImportRequest(importRequestId);
    clearTimeout(timerRef.current);
    const isImportRequestFinished = ImportRequestPresenter.isValidated(importRequest);

    if (ImportRequestPresenter.isInProgress(importRequest)) {
      const timerId = setTimeout(() => handlePollImportRequest(), REQUEST_INTERVAL_TIME);
      timerRef.current = timerId;
      return;
    }

    if (isImportRequestFinished) {
      setIsTableVisible(true);
    } else {
      notify(ImportRequestPresenter.message(importRequest), {
        status: ERROR_NOTIFICATION_TYPE,
        undoable: false,
        duration: NOTIFY_DURATION,
      });
    }

    setIsSubmitting(false);
    setUsePolling(false);
  };

  useEffect(() => {
    if (isUsePolling) {
      handlePollImportRequest();
    }
    return () => clearTimeout(timerRef.current);
  }, [isUsePolling]);

  const handleFormSubmit = values => {
    const dataToSubmit = { importRequest: values };

    setIsSubmitting(true);
    createFundImportRequest(dataToSubmit).then(() => {
      setUsePolling(true);
    });
  };

  const handleInputChange = (handleChange, setErrors) => event => {
    setErrors({});
    handleChange(event);
  };

  const hideTable = () => {
    setIsTableVisible(false);
  };

  if (isTableVisible)
    return (
      <div className={classes.tableContainer}>
        <ImportTable funds={funds} onTableClose={hideTable} />
      </div>
    );

  return (
    <Formik onSubmit={handleFormSubmit} initialValues={initialValues} validationSchema={validationSchema}>
      {({ handleSubmit, values, errors, handleChange, setErrors, setFieldValue, touched }) => {
        const handleInputFileChange = field => event => {
          setFieldValue(field, head(event.currentTarget.files));
        };
        const handleInputFileClear = field => () => {
          setFieldValue(field, null);
        };
        return (
          <Paper elevation={3}>
            <div>
              <div className={classes.warning}>{isSubmitting && <Loader />}</div>
              <form className={classes.form} onSubmit={handleSubmit} noValidate>
                <div className={classes.fields}>
                  <TextField
                    variant="filled"
                    margin="normal"
                    fullWidth
                    id="ticker"
                    label="Fund Ticker"
                    name="ticker"
                    onChange={handleInputChange(handleChange, setErrors)}
                    value={values.ticker}
                    disabled={isSubmitting}
                    error={touched.ticker && !!errors.ticker}
                    helperText={touched.ticker && errors.ticker}
                    className={classes.formWrapper}
                  />
                  <TextField
                    variant="filled"
                    margin="normal"
                    fullWidth
                    id="name"
                    label="Fund Name"
                    name="name"
                    onChange={handleInputChange(handleChange, setErrors)}
                    value={values.name}
                    disabled={isSubmitting}
                    error={touched.name && !!errors.name}
                    helperText={touched.name && errors.name}
                    className={classes.formWrapper}
                  />
                  <InputFile
                    file={values.file}
                    label="Choose a File"
                    name="file"
                    onChange={handleInputFileChange('file')}
                    onRemove={handleInputFileClear('file')}
                    helperText={touched.file && errors.file}
                    error={touched.file && !!errors.file}
                    accept=".xlsx"
                  />
                  <div>
                    <a className={classes.templateLink} href={xlsxExample}>
                      Download document template
                    </a>
                  </div>
                </div>
                <div className={classes.footerWrapper}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    disabled={isSubmitting}
                    label="Submit"
                  />
                </div>
              </form>
            </div>
          </Paper>
        );
      }}
    </Formik>
  );
};

export default ImportForm;
