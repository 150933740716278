import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { head, isEmpty, isNil, equals } from 'ramda';
import { useReactToPrint } from 'react-to-print';
import clsx from 'clsx';

import Chip from '@material-ui/core/Chip';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

import CustomerFundAnalysisPresenter from 'presenters/CustomerFundAnalysisPresenter';
import CompanyPresenter from 'presenters/CompanyPresenter';

import Compare from 'components/Compare';
import PdfTemplate from 'components/PdfTemplate';

import Table from './components/Table';
import Panels from './components/Panels';
import Loader from 'components/Loader';

import pdfIcon from 'assets/pdf.svg';

import useStyles from './useStyles';

const INTERVAL_BEFORE_OPEN_PRINTING_FRAME = 500;

const CompaniesScored = props => {
  const {
    customerFundAnalyses,
    selectedFundIds,
    currentCompanyId,
    currentCompany,
    isCurrentCompanyLoading,
    onSetCurrentCompanyId,
    onSetSelectedFundIds,
    scoredCompaniesOptions,
    isScoredCompaniesOptionsLoading,
    variant,
  } = props;

  const classes = useStyles();

  const rootRef = useRef(null);
  const headerRef = useRef(null);
  const componentRef = useRef(null);

  const [isPdfContentVisible, setIsPdfContentVisible] = useState(false);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: '@page { size: letter; margin: 0px }',
    documentTitle: 'CompaniesScored.pdf',
    onBeforeGetContent: () => {
      return new Promise(resolve => {
        setIsPdfContentVisible(true);
        setTimeout(() => {
          resolve();
        }, INTERVAL_BEFORE_OPEN_PRINTING_FRAME);
      });
    },
    onAfterPrint: () => {
      setIsPdfContentVisible(false);
    },
  });

  const getInitialCompanyId = companies => {
    const firstCompany = head(companies);
    if (isEmpty(currentCompany)) {
      return CompanyPresenter.id(firstCompany);
    }

    return isNil(currentCompanyId) ? CompanyPresenter.id(firstCompany) : currentCompanyId;
  };

  const handleSelectedCustomerFundsChange = fundId => {
    if (equals([fundId], selectedFundIds)) {
      return;
    }

    const isFundSelected = selectedFundIds.some(id => id === fundId);

    const newSelectedFundIds = isFundSelected
      ? selectedFundIds.filter(id => id !== fundId)
      : [...selectedFundIds, fundId];

    onSetSelectedFundIds(newSelectedFundIds);
  };

  useEffect(() => {
    if (!isEmpty(selectedFundIds)) {
      const initialCurrentCompanyId = getInitialCompanyId(scoredCompaniesOptions);
      onSetCurrentCompanyId(initialCurrentCompanyId);
    }
  });

  useEffect(() => {
    if (variant === 'embedded') {
      rootRef.current.scrollIntoView();
    }
  }, [rootRef.current]);

  useEffect(() => {
    if (variant === 'modal' && !isNil(headerRef.current)) {
      headerRef.current.scrollIntoView(false);
    }
  }, [headerRef.current]);

  const handleCurrentCompanyChange = (_, companyId) => {
    onSetCurrentCompanyId(companyId);
  };

  const renderTab = company => {
    return (
      <Tooltip
        title={CompanyPresenter.name(company)}
        key={company.id}
        value={CompanyPresenter.id(company)}
        placement="top"
        TransitionProps={{ timeout: 500 }}
        arrow
      >
        <Tab
          label={CompanyPresenter.ticker(company)}
          classes={{ root: classes.tabRoot, wrapper: classes.tabWrapper, selected: classes.tabSelected }}
        />
      </Tooltip>
    );
  };

  const pdfContentClassNames = clsx({
    [classes.hidden]: !isPdfContentVisible,
    [classes.notVisible]: true,
  });

  const renderContent = () => {
    if (isEmpty(selectedFundIds)) {
      return <div className={classes.warning}>Please select one or more funds</div>;
    }
    if (isScoredCompaniesOptionsLoading || isEmpty(currentCompany)) {
      return (
        <div className={classes.warning}>
          <Loader caption="Loading..." />
        </div>
      );
    }

    return (
      <>
        <h1 className={classes.title} ref={headerRef}>
          Top Scored Securities
          <IconButton type="button" className={classes.PDFIcon} onClick={handlePrint} label="save to pdf" size="medium">
            <img src={pdfIcon} alt="Save to PDF" />
          </IconButton>
          <div className={classes.remark}>(by dollar)</div>
        </h1>
        <div className={classes.compare}>
          <Compare
            list={customerFundAnalyses}
            selectedToCompare={selectedFundIds}
            onChange={handleSelectedCustomerFundsChange}
          />
        </div>
        <div className={classes.slider}>
          <div className={classes.tabsWrapper}>
            <Tabs
              value={currentCompanyId}
              onChange={handleCurrentCompanyChange}
              indicatorColor="primary"
              variant="scrollable"
              scrollButtons="auto"
            >
              {scoredCompaniesOptions.map(renderTab)}
            </Tabs>
          </div>
        </div>
        {isCurrentCompanyLoading || isEmpty(currentCompanyId) ? (
          <div className={classes.loader}>
            <Loader />
          </div>
        ) : (
          <div>
            <div className={classes.info}>
              <div className={classes.short}>
                <div className={classes.ticker}>{CompanyPresenter.ticker(currentCompany)}</div>
                <Chip label={CompanyPresenter.companyTotalAnalysis(currentCompany).weightFullText} variant="outlined" />
              </div>
              <div className={classes.description}>{CompanyPresenter.businessDescription(currentCompany)}</div>
            </div>
            <div className={classes.table}>
              <Table currentCompany={currentCompany} />
            </div>
            <Panels company={currentCompany} />
          </div>
        )}
      </>
    );
  };

  return (
    <div className={classes.root} ref={rootRef}>
      {renderContent()}
      <div className={pdfContentClassNames}>
        <div className={classes.pdfWrapper} ref={componentRef}>
          <PdfTemplate>{renderContent()}</PdfTemplate>
        </div>
      </div>
    </div>
  );
};

CompaniesScored.propTypes = {
  customerFundAnalyses: PropTypes.arrayOf(CustomerFundAnalysisPresenter.shape()).isRequired,
  onSetCurrentCompanyId: PropTypes.func.isRequired,
  onSetSelectedFundIds: PropTypes.func.isRequired,
  selectedFundIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  currentCompanyId: PropTypes.number,
  isScoredCompaniesOptionsLoading: PropTypes.bool.isRequired,
  scoredCompaniesOptions: PropTypes.arrayOf(CompanyPresenter.shape()).isRequired,
  currentCompany: CompanyPresenter.shape().isRequired,
  isCurrentCompanyLoading: PropTypes.bool.isRequired,
  variant: PropTypes.oneOf(['modal', 'embedded']).isRequired,
};

CompaniesScored.defaultProps = {
  currentCompanyId: null,
};

export default CompaniesScored;
