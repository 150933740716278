import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#fff',
    paddingTop: 106,
    paddingBottom: 15,
  },
  container: {
    width: '100%',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 840,
  },
  layout: {
    justifyContent: 'space-between',
    display: 'block',
  },
  side: {
    width: '100%',
    maxWidth: '100%',
  },
  main: {
    width: '100%',
    maxWidth: 809,
    paddingTop: 25,
  },
  balls: {
    paddingTop: 0,
    display: 'flex',
    justifyContent: 'space-around',
  },
  ball: {
    marginBottom: 20,
  },
  fundsFilter: {
    width: '80%',
  },
  title: {
    fontFamily: theme.typography.font.ttHovesPro,
    fontWeight: 'normal',
    fontSize: 51,
    color: '#232020',
    textAlign: 'center',
    marginBottom: 41,
    marginTop: 0,
  },
  options: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 20,
    paddingRight: 4,
  },
  chart: {
    width: '100%',
    height: 355,
    marginBottom: 140,
    position: 'relative',
  },
  selectedfilters: {
    marginTop: 20,
    marginBottom: 35,
    height: 20,
    display: 'flex',
  },
  caption: {
    marginRight: 20,
    flexShrink: 0,
    fontFamily: theme.typography.font.ttHovesPro,
    fontSize: 12,
    fontWeight: 300,
  },
  items: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  item: {
    marginRight: 10,
    marginBottom: 10,
  },
  chipRoot: {
    backgroundColor: '#232020',
    borderRadius: 13,
    height: 20,
    display: 'flex',
    flexDirection: 'row-reverse',
    paddingLeft: 4,
    paddingRight: 8,
  },
  chipLabel: {
    color: '#fff',
    fontSize: 12,
    letterSpacing: '0.01em',
    fontWeight: 300,
    textTransform: 'initial',
    padding: 0,
    lineHeight: 1,
  },
  removeIcon: {
    width: 12,
    height: 12,
    margin: 0,
    marginRight: 8,
  },
}));

export default useStyles;
