/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import ImportTickersRepository from 'repositories/advisor/ImportTickersRepository';

const initialState = {
  importTickers: {
    loading: false,
    error: null,
  },
};

const importPortfolioSlice = createSlice({
  name: 'importPortfolio',
  initialState,
  reducers: {
    importTickersStart(state) {
      state.importTickers.loading = true;
      state.importTickers.error = null;
    },
    importTickersSuccess(state) {
      state.importTickers.loading = false;
    },
    importTickersFail(state, { payload }) {
      state.importTickers.loading = false;
      state.importTickers.error = payload.file.join(', ');
    },
  },
});

export const { importTickersStart, importTickersSuccess, importTickersFail } = importPortfolioSlice.actions;

export default importPortfolioSlice.reducer;

export const useImportPortfolioActions = () => {
  const dispatch = useDispatch();

  const importTickers = async file => {
    dispatch(importTickersStart());

    try {
      const data = await ImportTickersRepository.create(file);
      dispatch(importTickersSuccess());

      return data;
    } catch (e) {
      dispatch(importTickersFail(e));

      throw e;
    }
  };

  const downloadTickerImportTemplate = async () => {
    await ImportTickersRepository.download();
  };

  return {
    importTickers,
    downloadTickerImportTemplate,
  };
};
