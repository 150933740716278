import { useSelector } from 'react-redux';
import { isNil } from 'ramda';

import { useAppActions } from 'store/AppSlice';
import getFetchStatus from 'utils/getFetchStatus';
import ConfigurationPresenter from 'presenters/ConfigurationPresenter';

import logo from 'assets/logo.svg';
import footerLogo from 'assets/footerLogo.svg';

export default () => {
  const {
    signIn,
    signOut,
    loadCurrentUser,
    showLoader,
    hideLoader,
    onLoaderExit,
    stopImpersonating,
    changeSelectedCustomer,
    changeShouldRedirectOnSelectedAccountChange,
    updateCustomersList,
    hideRefreshReportModal,
    showRefreshReportModal,
    loadAdvisorCustomerAccounts,
    changeAccounts,
    loadAccounts,
    changeSelectedAccount,
    loadCustomerSavedFunds,
    createCustomerSavedFund,
    destroyCustomerSavedFund,
    loadAdvisorSavedFunds,
    createAdvisorSavedFund,
    destroyAdvisorSavedFund,
    loadConfigurations,
    createAdvisorHousehold,
    makeCurrentAdvisorHousehold,
    loadAdvisorHousehold,
    updateAdvisorHousehold,
  } = useAppActions();

  const loading = useSelector(state => state.AppSlice.loading);
  const currentUser = useSelector(state => state.AppSlice.currentUser.data);
  const currentUserMeta = useSelector(state => state.AppSlice.currentUser.meta);
  const currentUserStatus = useSelector(state => state.AppSlice.currentUser.status);
  const currentUserStatuses = getFetchStatus(currentUserStatus);

  const customersList = useSelector(state => state.AppSlice.customersList.data);
  const shouldShowRefreshReportModal = useSelector(state => state.AppSlice.shouldShowRefreshReportModal);
  const selectedCustomer = useSelector(state => state.AppSlice.customersList.selected);
  const selectedAccount = useSelector(state => state.AppSlice.accounts.selected);
  const accounts = useSelector(state => state.AppSlice.accounts.data);
  const isLoading = useSelector(state => state.AppSlice.loading);
  const loaderCaption = useSelector(state => state.AppSlice.loaderCaption);
  const savedFunds = useSelector(state => state.AppSlice.savedFunds.data);
  const shouldRedirectOnSelectedAccountChange = useSelector(
    state => state.AppSlice.shouldRedirectOnSelectedAccountChange,
  );

  const configurations = useSelector(state => state.AppSlice.configurations.data);
  const configurationsStatus = useSelector(state => state.AppSlice.configurations.status);

  const configurationsStatuses = getFetchStatus(configurationsStatus);

  const configurationLogoUrl = ConfigurationPresenter.logoUrl(configurations);
  const appLogoUrl = isNil(configurationLogoUrl) ? logo : configurationLogoUrl;

  const configurationFooterLogoUrl = ConfigurationPresenter.footerLogoUrl(configurations);
  const appFooterLogoUrl = isNil(configurationFooterLogoUrl) ? footerLogo : configurationFooterLogoUrl;

  return {
    signIn,
    signOut,
    loadCurrentUser,
    showLoader,
    hideLoader,
    onLoaderExit,
    stopImpersonating,
    changeSelectedCustomer,
    changeShouldRedirectOnSelectedAccountChange,
    updateCustomersList,
    hideRefreshReportModal,
    showRefreshReportModal,
    loadAdvisorCustomerAccounts,
    changeAccounts,
    loadAccounts,
    changeSelectedAccount,
    loadCustomerSavedFunds,
    createCustomerSavedFund,
    destroyCustomerSavedFund,
    loadAdvisorSavedFunds,
    createAdvisorSavedFund,
    destroyAdvisorSavedFund,
    currentUser,
    currentUserMeta,
    currentUserStatuses,
    customersList,
    shouldShowRefreshReportModal,
    selectedCustomer,
    selectedAccount,
    accounts,
    loadConfigurations,
    configurations,
    configurationsStatuses,
    appLogoUrl,
    appFooterLogoUrl,
    createAdvisorHousehold,
    makeCurrentAdvisorHousehold,
    loadAdvisorHousehold,
    updateAdvisorHousehold,
    isLoading,
    loading,
    loaderCaption,
    savedFunds,
    shouldRedirectOnSelectedAccountChange,
  };
};
