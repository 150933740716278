import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  listItem: {
    gridTemplateColumns: '140px 50px 1fr 50px 22px',
    columnGap: '12px',
    display: 'grid',
    paddingTop: '6px',
    paddingBottom: '6px',
    borderTop: '1px #e0e0e0 solid',
  },
  listItemText: {
    color: '#232020',
    fontWeight: 300,
    overflowWrap: 'break-word',
  },
  icon: {
    color: '#757575',
  },
  centerCell: {
    textAlign: 'center',
  },
}));

export default useStyles;
