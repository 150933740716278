import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  theme => ({
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '35px',
    },
    title: {
      fontWeight: 700,
      fontSize: 13,
    },
    edit: {
      color: '#fff',
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      fontWeight: 700,
      fontSize: 13,
    },
    listItem: {
      borderBottom: `1px solid ${theme.palette.affair}`,
      marginBottom: 14,
      paddingBottom: 15,
    },
    filterIconRoot: {
      width: 14,
      height: 14,
      marginRight: 6,
      transform: 'translateY(-1px)',
    },
    description: {
      fontSize: 16,
      fontWeight: 300,
      marginTop: 0,
      marginBottom: 0,
    },
  }),
  { name: 'List' },
);

export default useStyles;
