import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      marginBottom: 10,
    },
    title: {
      fontFamily: theme.typography.font.ttHovesPro,
      fontWeight: 700,
      fontSize: 19,
      textAlign: 'center',
      marginBottom: 15,
      marginTop: 10,
      letterSpacing: -0.5,
    },
    chipRootFirst: {
      height: 35,
      marginBottom: 30,
      alignSelf: 'center',
      position: 'relative',
    },
    chipLabelFirst: {
      maxWidth: 240,
      fontSize: 15,
    },
    chipRootSecond: {
      alignSelf: 'center',
      position: 'relative',
      height: 35,
    },
    chipLabelSecond: {
      maxWidth: 240,
      fontSize: 17,
    },
    item: {
      marginBottom: 40,
      alignSelf: 'center',
      position: 'relative',
    },
  }),
  { name: 'Value' },
);

export default useStyles;
