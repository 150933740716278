import React, { useState } from 'react';
import PropTypes from 'prop-types';

import TermsOfUse from './components/TermsOfUse';

import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';

import { Link } from 'react-router-dom';
import AppRoutes from 'routes/AppRoutes';

import useApp from 'hooks/useApp';

import useStyles from './useStyles';

const Footer = props => {
  const { onSignOut, onStopImpersonating, currentUserMeta } = props;
  const classes = useStyles();

  const { appFooterLogoUrl } = useApp();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const { impersonated } = currentUserMeta;

  return (
    <>
      <Modal open={isModalOpen} onClose={handleCloseModal} aria-labelledby="terms-of-use">
        <div className={classes.modal}>
          <TermsOfUse />
        </div>
      </Modal>
      <footer className={classes.footer}>
        <div className={classes.content}>
          <div className={classes.docs}>
            <Button onClick={handleOpenModal} classes={{ root: classes.docsLink }}>
              terms of use
            </Button>
          </div>
          <div className={classes.actions}>
            <button type="button" onClick={onSignOut} className={classes.action}>
              Sign Out
            </button>
            {impersonated && (
              <button type="button" onClick={onStopImpersonating} className={classes.action}>
                Stop Impersonating
              </button>
            )}
          </div>
        </div>
        <div className={classes.logo}>
          <Link to={AppRoutes.rootPath()}>
            <img src={appFooterLogoUrl} alt="Reflection Analytics Logo" className={classes.footerImage} />
          </Link>
        </div>
      </footer>
    </>
  );
};

Footer.propTypes = {
  onSignOut: PropTypes.func.isRequired,
  onStopImpersonating: PropTypes.func.isRequired,
  currentUserMeta: PropTypes.shape().isRequired,
};

export default Footer;
