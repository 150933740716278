import {
  find,
  reject,
  propEq,
  over,
  lensPath,
  set as ramdaSet,
  findIndex,
  append as ramdaAppend,
  prepend as ramdaPrepend,
} from 'ramda';

const rejectById = id => reject(propEq('id', id));
const mergeIfSameId = (mergeId, mergeAttrs, original) =>
  original.map(el => (el.id !== mergeId ? el : { ...el, ...mergeAttrs }));

export const createEmptyArray = length => new Array(length).fill(null);

export const findBy = (propertyName, propertyValue, collection) =>
  find(propEq(propertyName, propertyValue))(collection);

export const change = (object, path, func) => {
  const lens = lensPath(path);
  return over(lens, func, object);
};

export const mergeById = (object, path, id, attrs) => {
  const lens = lensPath(path);
  return over(lens, mergeIfSameId.bind(null, id, attrs), object);
};

export const set = (object, path, value) => {
  const lens = lensPath(path);
  return ramdaSet(lens, value, object);
};

export const setById = (array, id, path, value) => {
  const idx = findIndex(propEq('id', id), array);
  const lens = lensPath([idx]);
  return over(lens, object => set(object, path, value), array);
};

export const merge = (object, path, attrs) => {
  const lens = lensPath(path);
  return over(lens, value => ({ ...value, ...attrs }), object);
};

export const rejectBy = (propertyName, propertyValue, list) => reject(propEq(propertyName, propertyValue), list);

export const removeById = (object, path, id) => {
  const lens = lensPath(path);
  return over(lens, rejectById(id), object);
};

export const append = (object, path, element) => {
  const lens = lensPath(path);
  return over(lens, ramdaAppend(element), object);
};

export const prepend = (object, path, element) => {
  const lens = lensPath(path);
  return over(lens, ramdaPrepend(element), object);
};

export const removeFromArray = (object, path, element) => {
  const lens = lensPath(path);
  return over(
    lens,
    reject(e => e === element),
    object,
  );
};
