import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { isNil, isEmpty } from 'ramda';
import { InputLabel, FormControl, FormHelperText, IconButton } from '@material-ui/core';
import { CloseOutlined as CloseOutlinedIcon } from '@material-ui/icons';

import FilePresenter from 'presenters/FilePresenter';
import useStyles from './useStyles';

const InputFile = props => {
  const {
    label,
    placeholder,
    name,
    file,
    onChange,
    error,
    helperText,
    fieldClassName,
    accept,
    onRemove,
    imagePreviewUrl,
  } = props;
  const classes = useStyles();

  const renderEmptyInput = () => (
    <div className={classes.uploadField}>
      <input
        onChange={onChange}
        type="file"
        id={name}
        name={name}
        accept={accept}
        placeholder={placeholder}
        className={clsx(classes.input, { [classes.error]: error })}
      />
      <div className={classes.uploadWrapper}>
        {isEmpty(imagePreviewUrl) ? (
          <div className={classes.uploadText}>Drag and drop file in this area or click to attach</div>
        ) : (
          <div className={classes.imagePreviewer}>
            <div className={classes.removeImageWrapper}>
              <IconButton color="inherit" className={classes.removeButton} onClick={onRemove}>
                <CloseOutlinedIcon className={classes.removeButtonIcon} />
              </IconButton>
            </div>
            <img src={imagePreviewUrl} alt="Test Alt" className={classes.imagePreview} />
          </div>
        )}
      </div>
    </div>
  );

  const renderFileInfo = () => (
    <div className={classes.uploadedFile}>
      <div className={classes.uploadedFileName}>{FilePresenter.name(file)}</div>
      <IconButton color="inherit" className={classes.removeButton} onClick={onRemove}>
        <CloseOutlinedIcon className={classes.removeButtonIcon} />
      </IconButton>
    </div>
  );

  return (
    <FormControl className={clsx(classes.field, fieldClassName)}>
      {label && (
        <InputLabel shrink htmlFor={name}>
          {label}
        </InputLabel>
      )}
      {isNil(file) ? renderEmptyInput() : renderFileInfo()}
      <FormHelperText className={classes.error}>{helperText}</FormHelperText>
    </FormControl>
  );
};

InputFile.propTypes = {
  file: FilePresenter.shape(),
  error: PropTypes.bool,
  onChange: PropTypes.func,
  helperText: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  fieldClassName: PropTypes.string,
  accept: PropTypes.string,
  onRemove: PropTypes.func,
  imagePreviewUrl: PropTypes.string,
};

InputFile.defaultProps = {
  helperText: '',
  error: false,
  onChange: null,
  file: null,
  label: null,
  placeholder: '',
  fieldClassName: 'marginBottomMd',
  accept: '',
  onRemove: null,
  imagePreviewUrl: '',
};

export default InputFile;
