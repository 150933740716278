import React from 'react';
import {
  Filter,
  TextInput,
  List,
  Datagrid,
  TextField,
  EmailField,
  RadioButtonGroupInput,
  SelectField,
  ReferenceField,
  FunctionField,
} from 'react-admin';

import ImpersonateButton from './components/ImpersonateButton';

import UserActions from './UserActions';
import { USER_TYPES } from './Constants';

import ExportPresenter from 'presenters/ExportPresenter';

import ExportsRepository from 'repositories/admin/user/ExportsRepository';

const UserFilter = props => (
  <Filter {...props}>
    <TextInput label="ID" source="idEq" alwaysOn />
    <TextInput label="Email" source="EmailCont" alwaysOn />
    <TextInput label="Partner ID" source="partnerIdEq" alwaysOn />
    <RadioButtonGroupInput label="Type" source="TypeMatches" alwaysOn choices={USER_TYPES} />
  </Filter>
);

const UserList = props => {
  const handleDownloadExport = async () => {
    await ExportsRepository.download();
  };

  const handlePollExport = async onSuccess => {
    const usersExport = await ExportsRepository.show();

    if (ExportPresenter.notAttached(usersExport)) {
      setTimeout(() => handlePollExport(onSuccess), 500);
    } else if (ExportPresenter.isAttached(usersExport)) {
      onSuccess();
    }
  };

  const handleExport = async () => {
    await ExportsRepository.create();
    handlePollExport(handleDownloadExport);
  };

  const renderFullName = record => {
    const firstName = record.first_name || '';
    const lastName = record.last_name || '';

    return `${firstName} ${lastName}`;
  };

  return (
    <List filters={<UserFilter />} actions={<UserActions handleExportButtonClick={handleExport} />} {...props}>
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <ReferenceField source="household_id" reference="households">
          <TextField source="id" />
        </ReferenceField>
        <ReferenceField source="partner_id" reference="partners">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="external_id" />
        <EmailField source="email" />
        <FunctionField label="Full Name" render={record => renderFullName(record)} />
        <SelectField source="type" choices={USER_TYPES} />
        <ImpersonateButton />
      </Datagrid>
    </List>
  );
};

export default UserList;
