export default {
  rootPath: () => '/',
  adminPath: () => '/admin',
  signInPath: () => '/signin',
  signOutPath: () => '/signout',
  callUsPath: () => '/call-us',
  emailUsPath: () => '/email-us',
  chatWithUsPath: () => '/chat-with-us',
  termsOfUsePath: () => '/terms-of-use',
  fundsPath: () => '/funds',
  privacyStatementPath: () => '/privacy-statement',
  customerLensPath: () => '/customer-lens',
  customerLensLensesPath: () => '/customer-lens/lenses',
  customerLensValuesPath: id => `/customer-lens/${id}/values`,
  customerLensFlagsPath: id => `/customer-lens/${id}/flags`,
  portfolioAnalysisPath: id => `/portfolio-analysis/${id}`,
  portfolioAnalysisCompaniesScoredPath: id => `/portfolio-analysis/${id}/companies-scored`,
  portfolioAnalysisIssuesDefinedPath: id => `/portfolio-analysis/${id}/issues-defined`,
  portfolioAnalysisPathPercentValueHeldPath: id => `/portfolio-analysis/${id}/percent-value-held`,
  multipleAccountsPortfolioAnalysisPath: id => `/multiple-accounts-portfolio-analysis/${id}`,
  multipleAccountsPortfolioAnalysisCompaniesScoredPath: id =>
    `/multiple-accounts-portfolio-analysis/${id}/companies-scored`,
  multipleAccountsPortfolioAnalysisIssuesDefinedPath: id =>
    `/multiple-accounts-portfolio-analysis/${id}/issues-defined`,
  multipleAccountsPortfolioAnalysisPathPercentValueHeldPath: id =>
    `/multiple-accounts-portfolio-analysis/${id}/percent-value-held`,
  recommendationsPath: recommendationId => `/recommendations/${recommendationId}`,
  advisorFormPath: () => '/advisor-form',
  advisorEditFormPath: () => '/advisor-edit-form',
};
