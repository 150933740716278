export default {
  purple: '#6D2092',
  magenta: '#C858BA',
  mossGreen: '#A9D4A4', // environmental
  deYork: '#97CA91', // environmental darker
  macaroniAndCheese: '#FFC973', // social
  saffronMango: '#FABD5C', // social darker
  viking: '#74CDD9', // governance
  shakespeare: '#57C2D1', // governance darker
  affair: '#6C437A',
  background: {
    ivory: '#FBF7F1',
    bossanova: '#4B2259',
  },
};
