/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';
import { isEmpty } from 'ramda';
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import { useFundImportRequests } from 'admin/hooks/useFundImportRequest';

import { Status, StatusForCreated, StatusAction, State } from 'enums/ImportRequest';
import useStyles from './useStyles';

const Sidebar = ({ open, onClose, selectedRow, onMergeConflict, state, baseFunds }) => {
  const classes = useStyles();
  const { fundsSearch, matchedFunds } = useFundImportRequests();

  const {
    imported: { name, ticker },
    created,
  } = selectedRow;

  const initialSelectedAction = state === State.edited ? StatusAction[created.status] : State.added;
  const [selectedAction, setSelectedAction] = useState(initialSelectedAction);
  const [result, setResult] = useState({});

  useEffect(() => {
    fundsSearch({ name });
  }, []);

  useEffect(() => {
    setResult({});
    if (state === State.edited) {
      setResult(created);
    }
    if (selectedAction === StatusAction.added) {
      setResult({
        ticker,
        name,
        status: StatusForCreated.added,
        message: 'Company with new ticker added',
      });
    }
  }, [selectedAction]);

  const handleChangeAction = event => setSelectedAction(event.target.value);

  const handleChangeAvailableTickerResult = (value, item) => {
    const { ticker, name, id, type } = item;
    setResult({ ticker, name, status: StatusForCreated.applied, message: 'RA base ticker applied', typeId: id, type });
  };

  const handleChangeMatchResult = (value, index) => {
    const { ticker, name, id } = index;
    setResult({ ticker, name, id, status: StatusForCreated.matched, message: 'Available index applied' });
  };

  const handleApply = () => {
    const newData = { ...selectedRow, created: result, status: Status.merged };
    onMergeConflict(selectedRow.id, newData);
    onClose();
  };

  const renderResult = () => {
    return (
      <div className={classes.result}>
        {selectedAction === StatusAction.added && (
          <div
            className={classes.text}
          >{`A company ${name} with a new ticker ${ticker} will be added to the RA database.`}</div>
        )}

        {selectedAction === StatusAction.applied &&
          (isEmpty(matchedFunds) ? (
            <div className={classes.text}>{`Index corresponding to the name ${name} has not been found.`}</div>
          ) : (
            <>
              <div className={classes.text}>{`Choose one of available tickers to ${name}:`}</div>
              <FormControl className={classes.radioGroup}>
                {matchedFunds.map(fund => {
                  const { ticker, name, id } = fund;
                  return (
                    <FormControlLabel
                      key={id}
                      value={ticker}
                      checked={result.ticker === ticker}
                      onChange={event => handleChangeAvailableTickerResult(event.target.value, fund)}
                      control={<Radio color="primary" />}
                      label={`${ticker} / ${name}`}
                    />
                  );
                })}
              </FormControl>
            </>
          ))}

        {selectedAction === StatusAction.matched &&
          (isEmpty(baseFunds) ? (
            <div className={classes.text}>There are no available indexes.</div>
          ) : (
            <>
              <div className={classes.text}>Choose one of available indexes:</div>
              <FormControl className={classes.radioGroup}>
                {baseFunds.map(item => {
                  const { ticker, name } = item;
                  return (
                    <FormControlLabel
                      key={ticker}
                      value={ticker}
                      checked={result.ticker === ticker}
                      onChange={event => handleChangeMatchResult(event.target.value, item)}
                      control={<Radio color="primary" />}
                      label={`${ticker} / ${name}`}
                    />
                  );
                })}
              </FormControl>
            </>
          ))}
      </div>
    );
  };

  return (
    <Drawer anchor="right" open={open} onClose={onClose} PaperProps={{ className: classes.container }}>
      <IconButton type="button" className={classes.closeButton} onClick={onClose}>
        <ClearIcon />
      </IconButton>
      <div className={classes.head}>
        <h3 className={classes.title}>{state === State.edited ? 'Edit Fund Item' : 'Ticker Conflict Detected'}</h3>
        <p className={classes.name}>{state === State.edited ? 'New Company NAME' : name}</p>
      </div>
      <div className={classes.text}>
        {state === State.edited
          ? 'You can choose another action to resolve the ticker conflict:'
          : 'We did not find a ticker corresponding to the selected company in the RA database. Choose next action:'}
      </div>
      <RadioGroup value={selectedAction} onChange={handleChangeAction} className={classes.radioGroup}>
        <FormControlLabel
          value={StatusAction.added}
          control={<Radio color="primary" />}
          label="Include, but not scored"
        />
        <FormControlLabel
          value={StatusAction.applied}
          control={<Radio color="primary" />}
          label="Apply/select Reflection Analytics Base ticker"
        />
        <FormControlLabel value={StatusAction.matched} control={<Radio color="primary" />} label="Match to an index" />
      </RadioGroup>
      {renderResult()}
      <Button
        variant="contained"
        color="primary"
        onClick={handleApply}
        className={classes.button}
        disabled={isEmpty(result)}
      >
        APPLY
      </Button>
    </Drawer>
  );
};

Sidebar.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedRow: PropTypes.shape().isRequired,
  onMergeConflict: PropTypes.func.isRequired,
  state: PropTypes.string.isRequired,
  baseFunds: PropTypes.instanceOf(Array).isRequired,
};

export default Sidebar;
