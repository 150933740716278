import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const CheckIcon = props => {
  return (
    <SvgIcon {...props}>
      <path
        d="M14.905 2.007L13.05.1a.326.326 0 00-.47 0L4.747 8.134a.326.326 0 01-.47 0L2.422 6.228a.326.326 0 00-.47-.001L.097 8.135a.347.347 0 000 .481l4.18 4.285c.13.133.34.133.47 0L14.905 2.483a.346.346 0 000-.476z"
        fill="#000"
        fillRule="nonzero"
      />
    </SvgIcon>
  );
};

export default CheckIcon;
