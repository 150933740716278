import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#232020',
    width: 170,
    height: 170,
    borderRadius: '50%',
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    '@media print': {
      colorAdjust: 'exact',
      '-webkit-print-color-adjust': 'exact',
    },
  },
  scores: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transform: 'translateY(-2px)',
  },
  counter: {
    fontFamily: theme.typography.font.ttHovesPro,
    fontWeight: 'normal',
    fontSize: 51,
    color: '#FFFFFF',
    textAlign: 'center',
  },
  description: {
    fontFamily: theme.typography.font.ttHovesPro,
    fontWeight: 300,
    fontSize: 12,
    letterSpacing: '0.01em',
    color: '#FFFFFF',
    textAlign: 'center',
    lineHeight: '14px',
    padding: '0 10px',
  },
  percentage: {
    fontFamily: theme.typography.font.ttHovesPro,
    fontWeight: 'normal',
    fontSize: 51,
    lineHeight: '51px',
    position: 'relative',
    top: '-6px',
  },
  loader: {
    zIndex: 1,
  },
}));

export default useStyles;
