import React from 'react';
import { Admin, Resource } from 'react-admin';
import { Provider } from 'react-redux';
import { createHashHistory } from 'history';
import { StylesProvider, createGenerateClassName } from '@material-ui/core/styles';
import { create } from 'jss';
import preset from 'jss-preset-default';

import GlobalStyles from 'layouts/GlobalStyles';

import authProvider, { httpClient } from './providers/authProvider';
import restProvider from './providers/restProvider';
import theme from './theme';

import { UserEdit, UserList, UserCreate } from './sections/Users';
import { AssetClassEdit, AssetClassList, AssetClassCreate } from './sections/AssetClasses';
import {
  AssetClassCategoryEdit,
  AssetClassCategoryList,
  AssetClassCategoryCreate,
} from './sections/AssetClassCategories';
import {
  ExternalAssetClassEdit,
  ExternalAssetClassList,
  ExternalAssetClassCreate,
} from './sections/ExternalAssetClasses';
import { FundEdit, FundList, FundCreate } from './sections/Funds';
import { FundPortfolioList } from './sections/FundPortfolios';
import { HouseholdList } from './sections/Households';
import { PartnerList } from './sections/Partners';
import { InstitutionList, InstitutionEdit, InstitutionCreate } from './sections/Institutions';
import { FundTickerList } from './sections/FundTickers';
import { InstitutionFundList, InstitutionFundEdit, InstitutionFundCreate } from './sections/InstitutionFunds';
import { CompanyList, CompanyCreate, CompanyEdit } from './sections/Companies';
import { GicsSubIndustryList, GicsSubIndustryEdit, GicsSubIndustryCreate } from './sections/GicsSubIndustries';
import Dashboard from './sections/Dashboard/Dashboard';

import customRoutes from './routes/customRoutes';
import AdminLayout from './AdminLayout';
import createAdminStore from './createAdminStore';

const history = createHashHistory();
const dataProvider = restProvider('/admin/api/v1', httpClient);
const generateClassName = createGenerateClassName({ productionPrefix: 'admin' });
const jss = create(preset());

const AdminApp = () => (
  <Provider
    store={createAdminStore({
      authProvider,
      dataProvider,
      history,
    })}
  >
    <StylesProvider jss={jss} generateClassName={generateClassName}>
      <GlobalStyles />
      <Admin
        layout={AdminLayout}
        dashboard={Dashboard}
        history={history}
        dataProvider={dataProvider}
        authProvider={authProvider}
        customRoutes={customRoutes}
        theme={theme}
      >
        <Resource
          name="external_asset_classes"
          list={ExternalAssetClassList}
          edit={ExternalAssetClassEdit}
          create={ExternalAssetClassCreate}
        />
        <Resource name="asset_classes" list={AssetClassList} edit={AssetClassEdit} create={AssetClassCreate} />
        <Resource
          name="asset_class_categories"
          list={AssetClassCategoryList}
          edit={AssetClassCategoryEdit}
          create={AssetClassCategoryCreate}
        />
        <Resource name="users" list={UserList} edit={UserEdit} create={UserCreate} />
        <Resource name="funds" list={FundList} edit={FundEdit} create={FundCreate} />
        <Resource name="fund_portfolios" list={FundPortfolioList} />
        <Resource name="households" list={HouseholdList} />
        <Resource name="companies" list={CompanyList} edit={CompanyEdit} create={CompanyCreate} />
        <Resource
          name="gics_sub_industries"
          list={GicsSubIndustryList}
          edit={GicsSubIndustryEdit}
          create={GicsSubIndustryCreate}
        />
        <Resource name="fund_tickers" list={FundTickerList} />
        <Resource name="institutions" list={InstitutionList} edit={InstitutionEdit} create={InstitutionCreate} />
        <Resource
          name="institution_funds"
          list={InstitutionFundList}
          edit={InstitutionFundEdit}
          create={InstitutionFundCreate}
        />
        <Resource name="partners" list={PartnerList} />
      </Admin>
    </StylesProvider>
  </Provider>
);

export default AdminApp;
