export default {
  MuiIconButton: {
    root: {
      display: 'block',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
};
