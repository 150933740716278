import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { includes, isEmpty, pluck, join, isNil } from 'ramda';
import clsx from 'clsx';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Button from '@material-ui/core/Button';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Tooltip from '@material-ui/core/Tooltip';

import SquareCheckbox from 'components/SquareCheckbox';
import useStyles from './useStyles';

const ALL_ACCOUNTS_OPTION = { key: 'all', label: 'All Accounts' };

const MultipleAccountsDropdown = props => {
  const { selected, options, onChange } = props;
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState(selected);

  const selectedOptionNames = () => {
    const optionNames = pluck(
      'label',
      options.filter(option => includes(option.key, selectedOptions)),
    );
    return join(' ', optionNames);
  };

  const labelText = isEmpty(selectedOptions) ? ALL_ACCOUNTS_OPTION.label : selectedOptionNames();

  const handleClick = () => {
    setOpen(prev => !prev);
  };

  const handleClickAway = () => {
    if (!open) {
      return;
    }
    const selectedOptionIds = isEmpty(selectedOptions) ? pluck('key', options) : selectedOptions;

    setOpen(false);
    onChange(selectedOptionIds);
  };

  const handleChange = ({ key }) => () => {
    if (key === ALL_ACCOUNTS_OPTION.key) {
      setSelectedOptions([]);
      return;
    }

    const changedSelectedOptions = includes(key, selectedOptions)
      ? selectedOptions.filter(option => option !== key)
      : [...selectedOptions, key];

    setSelectedOptions(changedSelectedOptions);
  };

  const renderCurrentValue = isMenuOpen => {
    const styles = isMenuOpen
      ? { root: classes.buttonOpened, label: classes.labelOpened }
      : { root: classes.button, label: classes.label };
    const icon = isMenuOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />;

    return (
      <Button onClick={handleClick} classes={styles}>
        <span className={classes.text} title={labelText}>
          {labelText}
        </span>
        <span className={classes.icon}>{icon}</span>
      </Button>
    );
  };

  const renderOptionElement = option => {
    const { key, label } = option;
    const disabledOption = isNil(key);
    const titleClassName = clsx({
      [classes.disabledLabel]: disabledOption,
    });
    return (
      <Button
        key={key}
        value={label}
        onClick={handleChange(option)}
        classes={{ root: classes.buttonRoot, label: classes.option }}
        title={label}
        disabled={disabledOption}
      >
        <SquareCheckbox
          disabled={disabledOption}
          className={classes.checkbox}
          checked={selectedOptions.includes(key)}
        />
        <span className={titleClassName}>{label}</span>
      </Button>
    );
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className={classes.dropdown}>
        {renderCurrentValue()}
        {open && (
          <div className={classes.content}>
            {renderCurrentValue(open)}
            <Button
              key={ALL_ACCOUNTS_OPTION.key}
              value={ALL_ACCOUNTS_OPTION.label}
              onClick={handleChange(ALL_ACCOUNTS_OPTION)}
              classes={{ root: classes.buttonRoot, label: classes.option }}
              title={ALL_ACCOUNTS_OPTION.label}
            >
              <SquareCheckbox checked={isEmpty(selectedOptions)} className={classes.checkbox} />
              {ALL_ACCOUNTS_OPTION.label}
            </Button>
            {options.map(option => {
              const { key } = option;
              const disabledOption = isNil(key);
              if (disabledOption) {
                return (
                  <Tooltip title="You should recreate lens for using this account" key={`tooltip_${key}`}>
                    <span>{renderOptionElement(option)}</span>
                  </Tooltip>
                );
              }

              return renderOptionElement(option);
            })}
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};

MultipleAccountsDropdown.propTypes = {
  selected: PropTypes.arrayOf(PropTypes.number).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.number,
      label: PropTypes.string,
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default MultipleAccountsDropdown;
