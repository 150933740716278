import _ from 'lodash';
import axios from 'axios';
import store from 'store';
import qs from 'qs';
import { serialize } from 'object-to-formdata';
import destroySessionTypes from 'AppConstants';
import { camelize, decamelize } from './keysConverter';
import { extractFileName } from './fileUtils';
import FileSaver from 'file-saver';

const ENDPOINT_TO_GET_SIGNED_URL = '/api/presign';

function authenticityToken() {
  const token = document.querySelector('meta[name="csrf-token"]');
  return token ? token.content : null;
}

function headers() {
  return {
    Accept: '*/*',
    'Content-Type': 'application/json',
    'X-CSRF-Token': authenticityToken(),
    'X-Requested-With': 'XMLHttpRequest',
  };
}

axios.defaults.headers.post = headers();
axios.interceptors.response.use(null, error => {
  if (error.response.status === 401) {
    store.dispatch({ type: destroySessionTypes.SESSION_DESTROY_SUCCESS });
  }

  if (error.response.status === 422) {
    const {
      response: { data: errors },
    } = error;
    return Promise.reject(camelize(errors.errors));
  }

  if (error.response.status === 500) {
    return Promise.reject(new Error('Something went wrong, please retry again'));
  }

  return Promise.reject(error);
});

function headersMultipartFormData() {
  return {
    Accept: '*/*',
    'X-Requested-With': 'XMLHttpRequest',
  };
}

function toFormDataQuery(key, value, lastKey = null) {
  if ((!_.isObject(value) && !_.isNil(value)) || value instanceof File) {
    return { key, value };
  }

  if (_.isArray(value)) {
    return _.flatten(
      value.map((arrayValue, i) => {
        const arKey = _.isObject(arrayValue) && lastKey.includes('_attributes') ? `${key}[${i}]` : `${key}[]`;
        return toFormDataQuery(arKey, arrayValue, lastKey);
      }),
    );
  }

  return _.map(value, (nestedValue, nestedKey) => toFormDataQuery(`${key}[${nestedKey}]`, nestedValue, nestedKey));
}

export default {
  get(url, params = {}, customHeaders = {}) {
    return axios
      .get(url, {
        headers: customHeaders,
        params: decamelize(params),
        paramsSerializer: parameters => qs.stringify(parameters, { encode: false, arrayFormat: 'brackets' }),
      })
      .then(camelize);
  },

  post(url, json, customHeaders = {}) {
    const body = decamelize(json);

    return axios.post(url, body, { headers: customHeaders }).then(camelize);
  },

  put(url, json) {
    const body = decamelize(json);

    return axios.put(url, body).then(camelize);
  },

  delete(url, json) {
    const body = decamelize(json);

    return axios.delete(url, { data: body }).then(camelize);
  },

  download(url, params = {}) {
    return axios
      .get(url, {
        params: decamelize(params),
        paramsSerializer: parameters => qs.stringify(parameters, { encode: false }),
        responseType: 'blob',
      })
      .then(response => {
        const fileName = extractFileName(response);
        FileSaver.saveAs(new Blob([response.data]), fileName);
      });
  },

  toFormData(model, attributes) {
    const formData = new FormData();
    const convertedParams = toFormDataQuery(model, attributes);
    _.flattenDeep(convertedParams).forEach(({ key, value }) => {
      formData.append(key, value);
    });
    return formData;
  },

  postMultipartFormData(url, body) {
    const formData = serialize(decamelize(body));
    return axios
      .post(url, formData, {
        headers: headersMultipartFormData(),
        withCredentials: true,
        credentials: 'same-origin',
      })
      .then(response => camelize(response.data));
  },

  putMultipartFormData(url, formData) {
    const serializedFormData = serialize(decamelize(formData));
    return axios
      .put(url, serializedFormData, {
        headers: headersMultipartFormData(),
        withCredentials: true,
        credentials: 'same-origin',
      })
      .then(response => camelize(response.data));
  },

  putS3FilePresigned(file) {
    return axios
      .get(ENDPOINT_TO_GET_SIGNED_URL, {
        filename: file.name,
        filetype: file.type,
      })
      .then(result => {
        const { url, fields } = result.data;

        const options = {
          ...fields,
          headers: {
            'Content-Type': file.type,
            Accept: '*/*',
            'X-Requested-With': 'XMLHttpRequest',
          },
        };
        const formData = new FormData();
        Object.keys(fields).forEach(key => {
          formData.append(key, fields[key]);
        });

        const [storage, id] = fields.key.split('/');

        formData.append('file', file);
        return axios.post(url, formData, options).then(response => {
          const data = response.data || { storage, id };
          return {
            data,
          };
        });
      });
  },
};
