import React, { useState } from 'react';
import { head, addIndex, map } from 'ramda';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { ListItem, ListItemText } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';

import {
  getFormattedNumber,
  getPointedFormattedPercent,
  getPointedIntPercent,
  POINTED_THOUSAND_SEPARATOR_FORMAT,
} from 'utils/numberHelpers';
import { isLastElement, hasSingleElement } from 'utils/arrayUtils';
import { objectToQueryString } from 'utils/urlUtils';

import AppRoutes from 'routes/AppRoutes';

import GroupedTickerAssetClassPresenter from 'presenters/GroupedTickerAssetClassPresenter';
import TickerAssetClassPresenter from 'presenters/TickerAssetClassPresenter';

import useStyles from './useStyles';

const Row = props => {
  const {
    groupedTickerAssetClass,
    selectedAssetClass,
    onChangeSelectedAssetClass,
    selectedAccountIds,
    batchId,
  } = props;

  const history = useHistory();
  const classes = useStyles();

  const [isOpened, setIsOpened] = useState(false);

  const groupedTickerAssetClassId = GroupedTickerAssetClassPresenter.id(groupedTickerAssetClass);
  const isActiveRow = groupedTickerAssetClassId === selectedAssetClass;
  const items = GroupedTickerAssetClassPresenter.items(groupedTickerAssetClass);

  const handleChangeSelectedAssetClass = () => {
    onChangeSelectedAssetClass(groupedTickerAssetClassId);
  };

  const generateLinkObject = tickerAssetClass => {
    const customerFundAnalysisId = TickerAssetClassPresenter.customerFundAnalysisId(tickerAssetClass);
    const search = objectToQueryString({ selected_fund_ids: [customerFundAnalysisId] });

    if (hasSingleElement(selectedAccountIds)) {
      const accountId = head(selectedAccountIds);
      return {
        pathname: AppRoutes.portfolioAnalysisPath(accountId),
        search,
      };
    }

    const multipleAccountSearch = objectToQueryString({
      selected_fund_ids: [customerFundAnalysisId],
      account_ids: selectedAccountIds,
    });
    const link = {
      pathname: AppRoutes.multipleAccountsPortfolioAnalysisPath(batchId),
      search: multipleAccountSearch,
    };
    return link;
  };

  const handleButtonClick = tickerAssetClass => () => {
    const linkObject = generateLinkObject(tickerAssetClass);
    history.push(linkObject);
  };

  const renderFundLink = (tickerAssetClass, index) => {
    return (
      <Tooltip
        title={`Position Match Score ${TickerAssetClassPresenter.roundedMatch(tickerAssetClass)}%`}
        key={TickerAssetClassPresenter.id(tickerAssetClass)}
      >
        <Button variant="text" onClick={handleButtonClick(tickerAssetClass)} className={classes.fundNameLink}>
          {TickerAssetClassPresenter.fundName(tickerAssetClass)}
          {!isLastElement(index, items) && ','}
        </Button>
      </Tooltip>
    );
  };

  const rowClassNames = clsx({
    [classes.activeRow]: isActiveRow,
    [classes.listItem]: true,
  });

  return (
    <>
      <ListItem onClick={handleChangeSelectedAssetClass} className={rowClassNames}>
        <ListItemText className={clsx(classes.listItemText, classes.centerCell)} disableTypography>
          {getFormattedNumber(
            GroupedTickerAssetClassPresenter.totalAmount(groupedTickerAssetClass),
            POINTED_THOUSAND_SEPARATOR_FORMAT,
          )}
        </ListItemText>
        <ListItemText className={clsx(classes.listItemText, classes.centerCell)} disableTypography>
          {getPointedFormattedPercent(GroupedTickerAssetClassPresenter.percent(groupedTickerAssetClass))}
        </ListItemText>
        <ListItemText className={classes.listItemText} disableTypography style={{ textAlign: 'end' }}>
          {GroupedTickerAssetClassPresenter.name(groupedTickerAssetClass)}
        </ListItemText>
        <ListItemText className={clsx(classes.listItemText, classes.centerCell)} disableTypography>
          {getPointedIntPercent(GroupedTickerAssetClassPresenter.totalMatchScore(groupedTickerAssetClass))}
        </ListItemText>
        <IconButton
          aria-label="expand row"
          size="small"
          onClick={() => setIsOpened(!isOpened)}
          className={classes.iconButton}
        >
          {isOpened ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </ListItem>
      <Collapse in={isOpened} timeout="auto" unmountOnExit>
        <div className={clsx({ [classes.collapsedContainer]: true, [classes.activeRow]: isActiveRow })}>
          <span className={classes.holdingTitle}>Holdings:</span>
          {addIndex(map)(renderFundLink, items)}
        </div>
      </Collapse>
    </>
  );
};

Row.propTypes = {
  groupedTickerAssetClass: GroupedTickerAssetClassPresenter.shape().isRequired,
  selectedAssetClass: PropTypes.number,
  onChangeSelectedAssetClass: PropTypes.func.isRequired,
  selectedAccountIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  batchId: PropTypes.number.isRequired,
};

export default Row;
