import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  header: {
    minHeight: 94,
    boxShadow: '-4px 4px 15px 0 rgba(0,0,0,0.10)',
    backgroundColor: '#fff',
    display: 'flex',
    alignItems: 'center',
    padding: '0 48px 0 40px',
    zIndex: 1,
    flexShrink: 0,
    position: 'fixed',
    top: 0,
    width: '100%',
  },
  headerLogo: {
    width: 140,
    marginRight: 40,
    '& img': {
      maxHeight: 110,
      display: 'block',
    },
    [theme.breakpoints.up('md')]: {
      width: 170,
      marginRight: 60,
    },
  },
  footer: {
    backgroundColor: '#2B2B2B',
    padding: '40px 120px',
    color: '#fff',
    display: 'flex',
    justifyContent: 'flex-end',
    flexShrink: 0,
    position: 'fixed',
    bottom: 0,
    width: '100%',
    colorAdjust: 'exact',
    '-webkit-print-color-adjust': 'exact',
  },
  footerImage: {
    maxWidth: 50,
  },
  headerImage: {
    maxWidth: 215,
  },
  headerSpace: {
    height: 94,
  },
  footerSpace: {
    height: 128,
  },
  table: {
    width: '100%',
  },
  pdfThead: {
    height: 94,
    '@media print': {
      display: 'table-header-group',
    },
  },
  pdfTfoot: {
    height: 128,
    '@media print': {
      display: 'table-footer-group',
    },
  },
  bodySpace: {
    padding: 10,
  },
}));

export default useStyles;
