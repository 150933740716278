import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { scrollToTop } from 'utils/windowHelpers';
import { isMSIE } from 'utils/browserHelpers';

import useStyles from './useStyles';
import clsx from 'clsx';

import { isEmpty } from 'ramda';

const Loader = ({ caption, size }) => {
  const classes = useStyles();

  const isBig = size === 'big';
  const isSmall = size === 'small';

  const rootStyles = clsx(classes.root, { [classes.blendModeFallback]: isMSIE() });
  const containerStyles = clsx({ [classes.containerBig]: isBig, [classes.containerSmall]: isSmall });
  const figuresStyles = clsx({ [classes.figuresBig]: isBig, [classes.figuresSmall]: isSmall });
  const figureStyles = name => clsx({ [classes.figureBig]: isBig, [classes.figureSmall]: isSmall }, classes[name]);
  const captionStyles = clsx({ [classes.captionBig]: isBig, [classes.captionSmall]: isSmall });

  useEffect(() => {
    if (isBig) scrollToTop();
  }, []);

  return (
    <div className={rootStyles}>
      <div className={containerStyles}>
        <div className={figuresStyles}>
          <div className={figureStyles('center')} />
          <div className={figureStyles('left')} />
          <div className={figureStyles('right')} />
        </div>
        {!isEmpty(caption) && <div className={captionStyles}>{caption}</div>}
      </div>
    </div>
  );
};

Loader.propTypes = {
  caption: PropTypes.string,
  size: PropTypes.oneOf(['small', 'big']),
};

Loader.defaultProps = {
  caption: '',
  size: 'small',
};

export default Loader;
