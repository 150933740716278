import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  theme => ({
    root: {
      paddingBottom: 91,
      display: 'flex',
      flexDirection: 'column',
    },
    heading: {
      marginTop: 65,
      marginBottom: 17,
    },
    intro: {
      fontSize: 19,
      color: '#232020',
      letterSpacing: 0,
      lineHeight: '21px',
      textTransform: 'uppercase',
    },
    title: {
      fontSize: 51,
      color: '#232020',
      letterSpacing: 0,
      textAlign: 'center',
      lineHeight: '60px',
      margin: 0,
    },
    description: {
      fontSize: 19,
      color: '#454545',
      textAlign: 'center',
      lineHeight: '28px',
    },
    container: {
      maxWidth: 840,
      width: '100%',
      margin: '0 auto',
      [theme.breakpoints.up('md')]: {
        maxWidth: 1241,
      },
    },
    selectGroup: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 840,
      width: '100%',
      margin: '0 auto 15px auto',
      [theme.breakpoints.up('md')]: {
        maxWidth: 1241,
      },
    },
    selectGroupHeader: {
      fontFamily: theme.typography.font.ttHovesPro,
      fontSize: 19,
      color: '#232020',
      letterSpacing: '-0.01em',
      lineHeight: '20px',
      fontWeight: 700,
      marginBottom: 28,
    },
    lens: {
      backgroundColor: '#fff',
      borderRadius: '10px 0 10px 0',
      boxShadow: '0 25px 20px 0 rgba(0,0,0,0.15), -4px 4px 15px 0 rgba(0,0,0,0.10)',
      paddingBottom: 31,
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    lensColumn: {
      minHeight: 210,
      '& $lensInfo': {
        flexDirection: 'row',
      },
      '& $lensImage': {
        marginRight: 21,
      },
    },
    lensHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '10px',
    },
    lensInfo: {
      display: 'flex',
      flexDirection: 'column',
      padding: '0 30px',
      alignItems: 'center',
      flexGrow: 1,
    },
    lensContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      '& $lensTitle': {
        padding: 0,
      },
      '& $lensDescription': {
        textAlign: 'left',
        margin: '0 0 25px 0',
      },
    },
    lensTitle: {
      fontSize: 16,
      color: '#232020',
      letterSpacing: 0,
      textAlign: 'center',
      padding: '0 30px',
    },
    lensImage: {
      borderRadius: '50%',
      alignSelf: 'center',
    },
    lensDescription: {
      fontSize: 15,
      color: '#000',
      textAlign: 'center',
      lineHeight: '22px',
    },
    buttonRoot: {
      border: '2px solid #232020',
      borderRadius: 31,
      width: 132,
      marginTop: 'auto',
    },
    buttonLabel: {
      fontSize: 12,
      color: '#232020',
      letterSpacing: 0,
      textTansform: 'uppercase',
      fontWeight: 'bold',
    },
    formActions: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      marginTop: 47,
    },
    buttonWrapper: {
      alignSelf: 'center',
      marginBottom: 21,
    },
  }),
  { name: 'CustomerLensDefinition' },
);

export default useStyles;
