import Routes from 'routes/ApiRoutes';
import FetchHelpers from 'utils/FetchHelpers';

export default {
  create() {
    const path = Routes.createExportAdminApiV1InstitutionFundExportsPath();

    return FetchHelpers.get(path);
  },
  show() {
    const path = Routes.adminApiV1InstitutionFundExportsPath();

    return FetchHelpers.get(path);
  },
  download() {
    const path = Routes.downloadAdminApiV1InstitutionFundExportsPath();

    return FetchHelpers.download(path);
  },
};
