import React from 'react';
import { Filter, TextInput, List, Datagrid, TextField, ReferenceField } from 'react-admin';

const ExternalAssetClassFilter = props => (
  <Filter {...props}>
    <TextInput label="ExternalID" source="externalIdEq" alwaysOn />
    <TextInput label="Name" source="NameCont" alwaysOn />
  </Filter>
);

const ExternalAssetClassList = props => (
  <List filters={<ExternalAssetClassFilter />} {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="external_id" />
      <TextField source="name" />
      <ReferenceField source="asset_class_id" reference="asset_classes">
        <TextField source="name" />
      </ReferenceField>
    </Datagrid>
  </List>
);

export default ExternalAssetClassList;
