import Routes from 'routes/ApiRoutes';
import FetchHelpers from 'utils/FetchHelpers';

export default {
  show() {
    const path = Routes.currentApiV1UsersPath();

    return FetchHelpers.get(path);
  },
};
