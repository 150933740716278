import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  theme => ({
    reportContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    headLine: {
      marginBottom: 43,
    },
    heading: {
      marginBottom: 20,
    },
    container: {
      width: '100%',
      maxWidth: 840,
      margin: '0 auto',
      padding: '63px 0 0 0',
      marginBottom: 73,
      [theme.breakpoints.up('md')]: {
        maxWidth: '1400px',
      },
    },
    report: {
      background: '#fff',
      border: '1px solid #e1e1e1',
      boxShadow: '-4px 4px 15px 0 rgba(0,0,0,0.10)',
      borderRadius: '0 70px 70px 0',
      width: 'auto',
      display: 'flex',
      flexWrap: 'wrap',
      [theme.breakpoints.up('md')]: {
        flexWrap: 'nowrap',
      },
    },
    side: {
      width: '100%',
      maxWidth: '100%',
      backgroundColor: theme.palette.background.bossanova,
      color: '#fff',
      padding: '61px 60px 65px 60px',
      flexShrink: 0,
      [theme.breakpoints.up('md')]: {
        maxWidth: '460px',
      },
    },
    main: {
      padding: '58px 48px 26px 60px',
      width: '100%',
      position: 'relative',
    },
    buttonsGroup: {
      display: 'flex',
      justifyContent: 'center',
      '& > *': {
        margin: '0 5px',
      },
      padding: 35,
    },
    save: {
      fontSize: 18,
      color: theme.palette.primary.main,
      fontWeight: 'bold',
      padding: '3px 35px',
    },
    saveDescriptionTitle: {
      marginBottom: 20,
    },
  }),
  { name: 'ReflectionReport' },
);

export default useStyles;
