import { createEmptyArray } from 'utils/storeUtils';

const GRID_START_POINT = 100;
const GRID_END_POINT = 800;

export const getGridVerticalPoints = (count, startPoint = GRID_START_POINT, endPoint = GRID_END_POINT) => {
  const spacing = (endPoint - startPoint) / (count + 1);
  const emptyArray = createEmptyArray(count);
  const points = emptyArray.map((_, index) => startPoint + (index + 1) * spacing);

  return [startPoint, ...points, endPoint];
};
