import React from 'react';
import PropTypes from 'prop-types';
import { lensPath, view } from 'ramda';

import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { KeyboardDatePicker } from '@material-ui/pickers';

import useStyles from './useStyles';
import { customerShape } from 'containers/AdvisorForm/propTypes';

const Customer = props => {
  const classes = useStyles();
  const {
    customerIndex,
    onCustomerRemove,
    onChange,
    setFieldValue,
    customer,
    disabled,
    disabledDeletion,
    errors,
  } = props;

  const fieldError = fieldName => {
    const fieldLens = lensPath(['household', 'customers', customerIndex, fieldName]);
    return view(fieldLens, errors);
  };

  const customerNameError = fieldError('customerName');
  const emailError = fieldError('email');
  const birthDateError = fieldError('birthDate');

  const birthDateFieldName = `household.customers.${customerIndex}.birthDate`;

  const handleDateChange = date => {
    setFieldValue(birthDateFieldName, date);
  };

  return (
    <div className={classes.root}>
      <div className={classes.flex}>
        <TextField
          InputProps={{
            className: classes.input,
          }}
          variant="outlined"
          margin="normal"
          label="Customer Name"
          required
          fullWidth
          autoFocus
          key={`household.customers.${customer.id}.customerName`}
          id={`household.customers[${customerIndex}].customerName`}
          name={`household.customers[${customerIndex}].customerName`}
          onChange={onChange}
          value={customer.customerName}
          disabled={disabled}
          error={Boolean(customerNameError)}
          helperText={Boolean(customerNameError) && customerNameError}
        />
        <IconButton
          className={classes.deleteButton}
          color="primary"
          aria-label="delete"
          disabled={disabledDeletion}
          onClick={() => onCustomerRemove(customerIndex)}
        >
          <DeleteForeverIcon />
        </IconButton>
      </div>
      <div className={classes.flex}>
        <TextField
          InputProps={{
            className: classes.input,
          }}
          variant="outlined"
          margin="normal"
          fullWidth
          label="Email Address"
          key={`household.customers.${customer.id}.email`}
          id={`household.customers[${customerIndex}].email`}
          name={`household.customers[${customerIndex}].email`}
          onChange={onChange}
          value={customer.email}
          disabled={disabled}
          error={Boolean(emailError)}
          helperText={Boolean(emailError) && emailError}
        />
        <KeyboardDatePicker
          InputProps={{
            className: classes.input,
          }}
          fullWidth
          disableFuture
          inputVariant="outlined"
          margin="normal"
          label="Birth Date"
          key={`household.customers.${customer.id}.birthDate`}
          id={`household.customers[${customerIndex}].birthDate`}
          name={`household.customers[${customerIndex}].birthDate`}
          format="MM/dd/yyyy"
          onChange={handleDateChange}
          value={customer.birthDate}
          disabled={disabled}
          error={Boolean(birthDateError)}
          helperText={Boolean(birthDateError) && birthDateError}
          openTo="year"
        />
      </div>
    </div>
  );
};

Customer.propTypes = {
  customerIndex: PropTypes.number.isRequired,
  disabled: PropTypes.bool.isRequired,
  disabledDeletion: PropTypes.bool.isRequired,
  onCustomerRemove: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  customer: customerShape,
  errors: PropTypes.shape({}).isRequired,
};

export default Customer;
