import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  () => ({
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    controls: {
      width: '100%',
      maxWidth: 820,
    },
    active: {
      '&$button': {
        '&:after': {
          content: '""',
          height: 3,
          width: '100%',
          display: 'block',
          position: 'absolute',
          backgroundColor: '#000',
          bottom: -2,
        },
      },
      '& $buttonLabel': {
        color: '#232020',
      },
    },
    button: {
      marginRight: 21,
      marginBottom: 10,
      borderColor: '#dfdfdf',
      color: '#868686',
      '&:last-child': {
        marginRight: 0,
      },
    },
    slider: {
      '& .slick-arrow::before': {
        color: 'black',
      },
    },
    buttonRoot: {
      border: 'none',
      borderRadius: 0,
      position: 'relative',
      padding: '0 0 4px 0',
      maxWidth: 62,
    },
    buttonLabel: {
      fontWeight: 700,
      color: '#868686',
      fontSize: 13,
      textTransform: 'uppercase',
    },
  }),
  { name: 'Compare' },
);

export default useStyles;
