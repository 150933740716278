/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';

import { MenuItem, ListSubheader, TextField } from '@material-ui/core';
import { isNil, isEmpty } from 'ramda';
import { useInput, useDataProvider } from 'react-admin';

const SelectAssetClass = selectProps => {
  const dataProvider = useDataProvider();
  const [groupedAssetClasses, setGroupedAssetClasses] = useState([]);

  const handleAssetClassesLoad = async () => {
    const params = {
      pagination: { page: 1, perPage: 1000 },
      sort: { field: 'id', order: 'DESC' },
    };
    const categories = await dataProvider.getList('asset_class_categories', params);
    const assetClasses = await dataProvider.getList('asset_classes', params);
    const groupedAssetClassesByCategory = categories.data
      .reduce((acc, category) => {
        const categoryAssetClasses = assetClasses.data.filter(
          ({ asset_class_category_id }) => asset_class_category_id === category.id,
        );
        return [...acc, { categoryName: category.name, categoryId: category.id, assetClasses: categoryAssetClasses }];
      }, [])
      .filter(item => !isEmpty(item.assetClasses));
    const assetClassesWithoutCategory = assetClasses.data.filter(({ asset_class_category_id }) =>
      isNil(asset_class_category_id),
    );
    setGroupedAssetClasses([
      { categoryName: null, categoryId: null, assetClasses: assetClassesWithoutCategory },
      ...groupedAssetClassesByCategory,
    ]);
  };

  useEffect(() => {
    handleAssetClassesLoad();
  }, []);

  const { input } = useInput(selectProps);
  return (
    <TextField
      select
      label="Asset Class"
      helperText=" "
      variant="filled"
      style={{ width: '256px' }}
      {...input}
      onChange={event => input.onChange(event.target.value)}
    >
      {groupedAssetClasses.map(({ categoryName, assetClasses, categoryId }) => [
        <ListSubheader key={categoryId} disableSticky>
          {categoryName}
        </ListSubheader>,
        assetClasses.map(assetClass => (
          <MenuItem key={assetClass.id} value={assetClass.id} role="option">
            {assetClass.name}
          </MenuItem>
        )),
      ])}
    </TextField>
  );
};

export default SelectAssetClass;
