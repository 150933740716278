import oneClient from './oneClient';
import advisorWithMultipleClients from './advisorWithMultipleClients';
import advisorWithOneClient from './advisorWithOneClient';
import multipleClients from './multipleClients';
import blackrockClient from './blackrockClient';
import samplePortfolioClient from './samplePortfolioClient';
import qaTest from './qaTest';
import assetClass from './assetClass';

export default {
  advisorWithMultipleClients,
  advisorWithOneClient,
  multipleClients,
  oneClient,
  blackrockClient,
  samplePortfolioClient,
  qaTest,
  assetClass,
};
