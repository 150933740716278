import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { MenuItemLink, getResources } from 'react-admin';

import LabelIcon from '@material-ui/icons/Label';
import { makeStyles } from '@material-ui/core/styles';
import { startCase } from 'lodash';

import AdminRoutes from 'routes/AdminRoutes';

const useStyles = makeStyles(() => ({
  menu: {
    marginTop: '1.5em',
  },
}));

const AdminMenu = props => {
  const { resources } = props;
  const classes = useStyles();

  return (
    <div className={classes.menu}>
      <MenuItemLink to={AdminRoutes.dashboard()} primaryText="Dashboard" leftIcon={<LabelIcon />} />
      <MenuItemLink to={AdminRoutes.kpi()} primaryText="KPI" leftIcon={<LabelIcon />} />
      <MenuItemLink to={AdminRoutes.configurations()} primaryText="Configurations" leftIcon={<LabelIcon />} />
      {resources.map(resource => (
        <MenuItemLink
          key={resource.name}
          to={`/${resource.name}`}
          primaryText={startCase(resource.name)}
          leftIcon={<LabelIcon />}
        />
      ))}
    </div>
  );
};

const mapStateToProps = state => ({
  resources: getResources(state),
});

AdminMenu.propTypes = {
  resources: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default withRouter(connect(mapStateToProps)(AdminMenu));
