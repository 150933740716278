import React from 'react';
import useStyles from './useStyles';
import PropTypes from 'prop-types';

import LensPresenter from 'presenters/LensPresenter';

const CoreFocusItem = props => {
  const { lensName } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <img src={LensPresenter.getIcon(lensName)} width="24" height="24" alt="selected lens icon" />
      <div className={classes.name}>{lensName}</div>
    </div>
  );
};

CoreFocusItem.propTypes = {
  lensName: PropTypes.string.isRequired,
};

export default CoreFocusItem;
