import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  theme => ({
    root: {
      paddingRight: 15,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    title: {
      fontFamily: theme.typography.font.ttHovesPro,
      fontWeight: 300,
      fontSize: 40,
      textAlign: 'center',
      marginBottom: 2,
      marginTop: 1,
    },
    description: {
      fontFamily: theme.typography.font.ttHovesPro,
      fontWeight: 300,
      fontSize: 15,
      color: '#000',
      textAlign: 'center',
      lineHeight: '22px',
      marginBottom: 33,
      maxWidth: 536,
    },
    image: {
      borderRadius: '50%',
    },
    actions: {
      marginTop: 60,
      display: 'flex',
      flexDirection: 'column',
    },
    buttonWrapper: {
      alignSelf: 'center',
      marginTop: 13,
      textTransform: 'uppercase',
    },
    buttonRoot: {
      fontFamily: theme.typography.font.ttHovesPro,
      fontSize: 12,
      letterSpacing: '0.01em',
    },
  }),
  { name: 'UpdateInfo' },
);

export default useStyles;
