import PropTypes from 'prop-types';

import { REPORT_OVERVIEW } from 'presenters/ReflectionReportPresenter';
import { getFormattedPercent } from 'utils/numberHelpers';
import { formatTimestampToET } from 'utils/dateUtils';

import Presenter from 'utils/PropTypesPresenter';

import CustomerLensPresenter from 'presenters/CustomerLensPresenter';

const STATES = {
  inProgress: 'in_progress',
  error: 'error',
  finished: 'finished',
};
const SAMPLE_SOURCE = 'sample';

export default new Presenter(
  {
    id: PropTypes.number,
    customerPortfolioId: PropTypes.number,
    customerLens: CustomerLensPresenter.shape(),
    scoredCompaniesCount: PropTypes.number,
    totalCompaniesCount: PropTypes.number,
    scoredAmount: PropTypes.string,
    totalAmount: PropTypes.string,
    companiesWithAttachedFlagsCount: PropTypes.number,
    createdAt: PropTypes.number,
    state: PropTypes.string,
    customerPortfolioAnalysesBatchId: PropTypes.number,
    portfolioSource: PropTypes.string,
  },
  {
    scoredAmountPercents(analysis) {
      const value = this.scoredAmount(analysis) / this.totalAmount(analysis);
      return getFormattedPercent(value);
    },
    toReflectionReport(analysis) {
      const {
        portfolioTotalAnalysis: totalAnalysis,
        portfolioPillarAnalyses: pillarAnalyses,
        portfolioCategoryAnalyses: categoryAnalyses,
        name,
        ticker,
        ...rest
      } = analysis;

      const report = {
        ...rest,
        name: name || REPORT_OVERVIEW.label,
        ticker: ticker || REPORT_OVERVIEW.label,
        totalAnalysis,
        pillarAnalyses,
        categoryAnalyses,
      };

      return report;
    },
    formattedCreatedAt(analysis) {
      return formatTimestampToET(this.createdAt(analysis));
    },
    isInProgress(analysis) {
      return this.state(analysis) === STATES.inProgress;
    },
    isCrashed(analysis) {
      return this.state(analysis) === STATES.error;
    },
    isFinished(analysis) {
      return this.state(analysis) === STATES.finished;
    },
    isSample(analysis) {
      return this.portfolioSource(analysis) === SAMPLE_SOURCE;
    },
  },
);
