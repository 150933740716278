import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import DrawerMaterial from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';

import closeIcon from 'assets/close.svg';

import useStyles from './useStyles';

const Drawer = ({ open, onClose, children, wide }) => {
  const classes = useStyles();
  const handleCloseClick = () => {
    onClose();
  };

  const drawerStyles = clsx({ [classes.wide]: wide });

  return (
    <DrawerMaterial
      anchor="right"
      open={open}
      onClose={handleCloseClick}
      classes={{ root: classes.root, paper: classes.paper }}
      className={drawerStyles}
    >
      <div className={classes.header}>
        <IconButton type="button" className={classes.close} onClick={handleCloseClick}>
          <img src={closeIcon} alt="" />
        </IconButton>
      </div>
      <div className={classes.content}>{children}</div>
    </DrawerMaterial>
  );
};

Drawer.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.node,
  wide: PropTypes.bool,
};

Drawer.defaultProps = {
  children: null,
  wide: false,
};
export default Drawer;
