import { pathOr } from 'ramda';
import queryString from 'query-string';
import { isBlank } from '.';
import { matchPath } from 'react-router';

export const getUrlParam = (param, defaultValue = null) => {
  const { location } = window;
  const search = queryString.parse(location.search, { arrayFormat: 'bracket' });

  return pathOr(defaultValue, [param], search);
};

export const getAnalysisId = () => {
  const { location } = window;
  const paths = location.pathname.split('/').filter(path => !isBlank(path));

  return Number(paths[1]);
};

export const isMatchedPath = path => requiredPath => {
  const match = matchPath(path, { path: requiredPath });

  return pathOr(false, ['isExact'], match);
};
