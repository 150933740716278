import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  theme => ({
    barChart: {
      '& svg': {
        overflow: 'initial',
      },
      '@global': {
        '.recharts-label': {
          fontFamily: theme.typography.font.ttHovesPro,
          fontSize: 13,
          fontWeight: 300,
          fill: '#515151',
          transform: 'translate(-25px, 0px)',
        },
      },
    },
  }),
  { name: 'Chart' },
);

export default useStyles;
