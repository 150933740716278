import PropTypes from 'prop-types';
import React from 'react';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { stringify } from 'query-string';

const LinkToInstitutionFunds = ({ record }) => {
  return record ? (
    <Button
      label="Funds"
      component={Link}
      to={{
        pathname: '/institution_funds',
        search: stringify({
          page: 1,
          perPage: 25,
          order: 'ASC',
          filter: JSON.stringify({ institutionIdEq: record.id }),
        }),
      }}
    >
      Funds
    </Button>
  ) : null;
};

LinkToInstitutionFunds.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
};

export default LinkToInstitutionFunds;
