import React from 'react';
import PropTypes from 'prop-types';

import UserPresenter from 'presenters/UserPresenter';
import SavedFundPresenter from 'presenters/SavedFundPresenter';

import Footer from './components/Footer';
import Header from './components/Header';

import useStyles from './useStyles';

const AppLayout = props => {
  const {
    children,
    onSignOut,
    onStopImpersonating,
    currentUser,
    currentUserMeta,
    customersList,
    selectedCustomer,
    onChangeSelectedCustomer,
    savedFunds,
    onDestroySavedFund,
  } = props;
  const classes = useStyles();

  return (
    <>
      <Header
        savedFunds={savedFunds}
        onDestroySavedFund={onDestroySavedFund}
        currentUser={currentUser}
        customersList={customersList}
        selectedCustomer={selectedCustomer}
        onChangeSelectedCustomer={onChangeSelectedCustomer}
      />
      <div className={classes.main}>{children}</div>
      <Footer currentUserMeta={currentUserMeta} onSignOut={onSignOut} onStopImpersonating={onStopImpersonating} />
    </>
  );
};

export default AppLayout;

AppLayout.propTypes = {
  children: PropTypes.node,
  onSignOut: PropTypes.func.isRequired,
  currentUser: UserPresenter.shape().isRequired,
  currentUserMeta: PropTypes.shape().isRequired,
  onStopImpersonating: PropTypes.func.isRequired,
  customersList: PropTypes.arrayOf(PropTypes.shape()),
  selectedCustomer: PropTypes.shape(),
  onChangeSelectedCustomer: PropTypes.func,
  savedFunds: PropTypes.arrayOf(SavedFundPresenter.shape()).isRequired,
  onDestroySavedFund: PropTypes.func.isRequired,
};

AppLayout.defaultProps = {
  children: null,
  customersList: null,
  selectedCustomer: null,
  onChangeSelectedCustomer: null,
};
