import React from 'react';
import PropTypes from 'prop-types';
import CheckIcon from 'components/Icons/CheckIcon';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Slider from 'react-slick';

import ReflectionReportPresenter from 'presenters/ReflectionReportPresenter';

import useStyles from './useStyles';
import clsx from 'clsx';

const Compare = props => {
  const { list, onChange, selectedToCompare } = props;
  const classes = useStyles();

  const renderButton = item => {
    const isActive = selectedToCompare.some(id => id === item.id);
    const styles = clsx(classes.button, { [classes.active]: isActive });

    return (
      <Tooltip
        title={ReflectionReportPresenter.description(item)}
        key={item.id}
        placement="top"
        TransitionProps={{ timeout: 500 }}
        arrow
      >
        <Button
          key={item.id}
          size="small"
          onClick={() => onChange(item.id)}
          className={styles}
          classes={{ root: classes.buttonRoot, label: classes.buttonlabel }}
        >
          {isActive && <CheckIcon viewBox="0 0 15 13" className={classes.checkIcon} />}
          {item.ticker}
        </Button>
      </Tooltip>
    );
  };

  return (
    <div className={classes.root}>
      <div className={classes.controls}>
        <Slider className={classes.slider} slidesToShow={8} slidesToScroll={8} infinite={false} dots speed={500}>
          {list.map(renderButton)}
        </Slider>
      </div>
    </div>
  );
};

Compare.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onChange: PropTypes.func,
  selectedToCompare: PropTypes.arrayOf(PropTypes.number),
};

Compare.defaultProps = {
  selectedToCompare: [],
  onChange: () => {},
};

export default Compare;
