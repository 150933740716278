import React from 'react';
import PropTypes from 'prop-types';
import { StylesProvider, ThemeProvider } from '@material-ui/core/styles';

import GlobalStyles from 'layouts/GlobalStyles';

import theme from './theme';
import jss from './jss';

const CustomThemeProvider = ({ children }) => (
  <StylesProvider jss={jss}>
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      {children}
    </ThemeProvider>
  </StylesProvider>
);

CustomThemeProvider.propTypes = {
  children: PropTypes.node,
};

CustomThemeProvider.defaultProps = {
  children: null,
};

export default CustomThemeProvider;
