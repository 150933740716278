import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  () => ({
    title: {
      color: '#707070',
      fontWeight: 600,
      fontSize: 13,
      lineHeight: '16px',
    },
    value: {
      fontWeight: 'normal',
      fontSize: 28,
      marginTop: 'auto',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    loader: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
    block: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      position: 'relative',
      flexGrow: 0.4,
    },
  }),
  { name: 'CardContent' },
);

export default useStyles;
