import React, { useState } from 'react';
import { WithPermissions } from 'react-admin';
// import { head } from 'ramda';
// import { useHistory } from 'react-router-dom';

import PayloadRepository from 'repositories/integration/moneyguide/PayloadRepository';
import PartnersRepository from 'repositories/admin/PartnersRepository';
// import UsersRepository from 'repositories/admin/UsersRepository';

// import AdminRoutes from 'routes/AdminRoutes';

import { MG_SLUG } from 'presenters/PartnerPresenter';

import SampleJsonInput from './components/SampleJsonInput';

const UserImport = () => {
  const [apiErrors, setApiErrors] = useState({});
  const [iframeUrl, setIframeUrl] = useState(null);
  // const history = useHistory();

  const handleFormSubmit = async json => {
    const {
      data: { accessToken },
    } = await PartnersRepository.authTokens({ slug: MG_SLUG });

    try {
      setApiErrors({});
      const {
        data: { iframeUrl: url },
      } = await PayloadRepository.create(json, accessToken);
      setIframeUrl(url);
      // const { user_id: userId } = json;
      // const {
      //   data: { users },
      // } = await UsersRepository.index({ q: { external_id_eq: userId } });

      // const { id } = head(users);

      // await UsersRepository.impersonate(id);
      // history.push(AdminRoutes.moneyguide());
    } catch (e) {
      setApiErrors(e);
    }
  };

  return <SampleJsonInput iframeUrl={iframeUrl} onSubmit={handleFormSubmit} apiErrors={apiErrors} />;
};

const UserImportWithPermissions = props => {
  const { match } = props;

  return <WithPermissions authParams={{ key: match.path }} render={() => <UserImport />} />;
};

export default UserImportWithPermissions;
