import { useSnackbar } from 'notistack';

import { SOMETHING_WENT_WRONG, SUCCESS } from 'enums/NotificationMessages';

export default function useNotification() {
  const { enqueueSnackbar } = useSnackbar();

  const showSuccessNotification = (message = SUCCESS) => {
    enqueueSnackbar(message, { variant: 'success' });
  };

  const showErrorNotification = (message = SOMETHING_WENT_WRONG) => {
    enqueueSnackbar(message, { variant: 'error' });
  };

  return { showSuccessNotification, showErrorNotification };
}
