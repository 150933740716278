import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './useStyles';
import clsx from 'clsx';

const Heading = ({ intro, title, description, className }) => {
  const classes = useStyles();
  const rootStyles = clsx([classes.heading], className);
  return (
    <div className={rootStyles}>
      <p className={classes.intro}>{intro}</p>
      <h2 className={classes.title} dangerouslySetInnerHTML={{ __html: title }} />
      <p className={classes.description}>{description}</p>
    </div>
  );
};

Heading.propTypes = {
  intro: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  className: PropTypes.string,
};

export default Heading;
