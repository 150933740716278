import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  fields: {
    width: 400,
    padding: 15,
  },
  footerWrapper: {
    padding: 15,
    backgroundColor: '#f5f5f5',
  },
}));
