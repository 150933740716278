import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { length } from 'ramda';

import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';

import CheckIcon from 'components/Icons/CheckIcon';

import FundScorePresenter from 'presenters/FundScorePresenter';

import useStyles from './useStyles';
import clsx from 'clsx';

const FundsFilter = props => {
  const { fundScores, onChangeSelectedFunds, selectedFunds, maximumNumberOfSelectedFunds } = props;
  const classes = useStyles();

  const renderFilter = fund => {
    const isActive = selectedFunds.some(({ id }) => id === FundScorePresenter.id(fund));
    const selectedFundsCount = length(selectedFunds);
    const isDisabled = !isActive && selectedFundsCount === maximumNumberOfSelectedFunds;
    const styles = clsx(classes.button, { [classes.active]: isActive });

    return (
      <Tooltip
        title={FundScorePresenter.description(fund)}
        key={FundScorePresenter.id(fund)}
        placement="top"
        TransitionProps={{ timeout: 500 }}
        arrow
      >
        <Button
          key={FundScorePresenter.id(fund)}
          size="small"
          onClick={() => onChangeSelectedFunds(fund)}
          className={styles}
          classes={{ root: classes.buttonRoot, label: classes.buttonlabel }}
          disabled={isDisabled}
        >
          {isActive && <CheckIcon viewBox="0 0 15 13" className={classes.checkIcon} />}
          {FundScorePresenter.fundTicker(fund)}
        </Button>
      </Tooltip>
    );
  };

  return (
    <div className={classes.root}>
      <Slider className={classes.slider} slidesToShow={6} slidesToScroll={6} infinite={false} dots speed={500}>
        {fundScores.map(renderFilter)}
      </Slider>
    </div>
  );
};

FundsFilter.propTypes = {
  fundScores: PropTypes.arrayOf(FundScorePresenter.shape()).isRequired,
  onChangeSelectedFunds: PropTypes.func,
  maximumNumberOfSelectedFunds: PropTypes.number.isRequired,
  selectedFunds: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

FundsFilter.defaultProps = {
  onChangeSelectedFunds: () => {},
};

export default FundsFilter;
