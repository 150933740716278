import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  activeRow: {
    background: '#F1F1F1D9',
  },
  holdingTitle: {
    fontWeight: 600,
    marginRight: 10,
    fontSize: 12,
  },
  collapsedContainer: {
    padding: [6, 16],
    lineHeight: '36px',
    fontSize: 14,
    fontFamily: theme.typography.font.ttHovesPro,
    fontWeight: 300,
    borderTop: '1px #e0e0e0 solid',
  },
  fundNameLink: {
    marginRight: 10,
    color: '#0F5E75',
    textDecoration: 'underline',
    padding: 0,
    fontFamily: theme.typography.font.ttHovesPro,
    fontWeight: 300,
  },
  listItem: {
    gridTemplateColumns: '140px 50px 1fr 50px 22px',
    columnGap: '12px',
    display: 'grid',
    paddingTop: '6px',
    paddingBottom: '6px',
    borderTop: '1px #e0e0e0 solid',
  },
  listItemText: {
    color: '#232020',
    fontWeight: 300,
    overflowWrap: 'break-word',
  },
  iconButton: {
    padding: 0,
  },
  centerCell: {
    textAlign: 'center',
  },
}));

export default useStyles;
