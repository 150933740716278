import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  text: {
    fontWeight: 'bold',
    fontFamily: theme.typography.font.ttHovesPro,
    fontSize: 9,
  },
  smallText: {
    fontWeight: 'bold',
    fontFamily: theme.typography.font.ttHovesPro,
    fontSize: 7,
  },
}));

export default useStyles;
