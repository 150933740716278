import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    minWidth: 350,
  },
  links: {
    marginTop: 4,
    marginRight: 15,
  },
  fields: {
    width: 400,
    padding: 15,
  },
  footerWrapper: {
    padding: 15,
    backgroundColor: '#f5f5f5',
  },
  templateLink: {
    fontWeight: 'bold',
    textDecoration: 'underline',
  },
  tableContainer: {
    margin: '50px 0',
  },
}));
