import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  listItem: {
    gridTemplateColumns: '140px 50px 1fr 50px 22px',
    columnGap: '12px',
    display: 'grid',
    paddingTop: '6px',
    paddingBottom: '6px',
    borderTop: '1px #e0e0e0 solid',
  },
  listHead: {
    padding: theme.spacing(2),
    color: '#232020',
  },
  centerCell: {
    textAlign: 'center',
  },
}));

export default useStyles;
