import React from 'react';
import { TextField, Edit, SimpleForm, TextInput, ReferenceInput, SelectInput } from 'react-admin';

const AssetClassCategoryEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextField source="id" />
      <TextInput source="external_id" />
      <TextInput multiline source="name" />
      <TextInput source="code" />
      <TextInput source="priority" />
      <ReferenceInput source="parent_id" reference="asset_class_categories" allowEmpty>
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);

export default AssetClassCategoryEdit;
