import React from 'react';
import PropTypes from 'prop-types';

import CheckCircleOutlineIcon from 'components/Icons/CheckCircleOutlineIcon';

import useStyles from './useStyles';

const SystemMessage = props => {
  const { title, onClick } = props;
  const classes = useStyles();

  return (
    <div className={classes.root} role="presentation" onClick={onClick}>
      <div className={classes.icon}>
        <CheckCircleOutlineIcon />
      </div>
      <div className={classes.title}>{title}</div>
    </div>
  );
};

SystemMessage.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default SystemMessage;
