import React from 'react';
import PropTypes from 'prop-types';

const CustomBar = props => {
  const { x, y, width, height } = props;

  return (
    <svg>
      <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-1">
          <stop stopColor="#873A9E" offset="0%" />
          <stop stopColor="#5C3169" offset="100%" />
        </linearGradient>
      </defs>
      <rect x={x} y={y} width={width} height={height} fill="url(#linearGradient-1)" rx="3" />
    </svg>
  );
};

CustomBar.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default CustomBar;
