import React, { useState, useEffect } from 'react';
import { useDataProvider } from 'react-admin';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Card from './Card';

const useStyles = makeStyles(theme => ({
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 300,
  },
}));

const Dashboard = () => {
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [dashboard, setDashboard] = useState([]);
  const { getSingle } = useDataProvider();

  useEffect(() => {
    getSingle('dashboard').then(
      r => {
        setIsLoaded(true);
        setDashboard(r.data.dashboard);
      },
      e => {
        setIsLoaded(true);
        setError(e);
      },
    );
  }, []);

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <main className={classes.content}>
      <div className={classes.appBarSpacer} />
      <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4} lg={3}>
            <Paper className={fixedHeightPaper}>
              <Card
                title="Core Funds"
                stat={[
                  {
                    name: 'Total',
                    value: dashboard.core_funds_total,
                  },
                  {
                    name: 'Missing CIK',
                    value: dashboard.core_funds_with_missing_cik,
                  },
                  {
                    name: 'Missing Series',
                    value: dashboard.core_funds_with_missing_series,
                  },
                  {
                    name: 'Sync Error',
                    value: dashboard.core_funds_with_sync_error,
                  },
                ]}
              />
            </Paper>
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <Paper className={fixedHeightPaper}>
              <Card
                title="Users"
                stat={[
                  {
                    name: 'Total',
                    value: dashboard.users_total,
                  },
                  {
                    name: 'Advisors',
                    value: dashboard.advisors_total,
                  },
                  {
                    name: 'Customers',
                    value: dashboard.customers_total,
                  },
                ]}
              />
            </Paper>
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <Paper className={fixedHeightPaper}>
              <Card
                title="ESG Funds"
                stat={[
                  {
                    name: 'Total',
                    value: dashboard.esg_funds_total,
                  },
                  {
                    name: 'Missing CIK',
                    value: dashboard.esg_funds_with_missing_cik,
                  },
                  {
                    name: 'Missing Series',
                    value: dashboard.esg_funds_with_missing_series,
                  },
                  {
                    name: 'Sync Error',
                    value: dashboard.esg_funds_with_sync_error,
                  },
                ]}
              />
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </main>
  );
};

export default Dashboard;
