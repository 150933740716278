import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
  },
  '@keyframes slideLeft': {
    from: { left: '30%' },
    to: { left: '70%' },
  },
  '@keyframes slideRight': {
    from: { left: '70%' },
    to: { left: '30%' },
  },
  containerBig: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    backgroundColor: '#fff',
    zIndex: 10,
    width: '100vw',
    height: '100vh',
  },
  containerSmall: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    zIndex: 10,
  },
  figuresBig: {
    position: 'relative',
    width: 610,
    height: 230,
    marginBottom: 47,
  },
  figuresSmall: {
    position: 'relative',
    width: 130,
    height: 40,
    margin: '0 auto',
  },
  figureBig: {
    width: '140px',
    height: '230px',
    borderRadius: '15%',
    position: 'absolute',
    mixBlendMode: 'multiply',
    transform: 'translateX(-50%) skewY(18deg)',
  },
  figureSmall: {
    width: '24px',
    height: '40px',
    borderRadius: '15%',
    position: 'absolute',
    mixBlendMode: 'multiply',
    transform: 'translateX(-50%) skewY(18deg)',
  },
  left: {
    backgroundColor: theme.palette.mossGreen,
    left: '30%',
    animation: '0.48s ease-in-out infinite alternate $slideLeft',
  },
  right: {
    backgroundColor: theme.palette.macaroniAndCheese,
    left: '70%',
    animation: '0.48s ease-in-out infinite alternate $slideRight',
  },
  center: {
    backgroundColor: theme.palette.viking,
    top: 0,
    left: '50%',
  },
  captionBig: {
    fontFamily: theme.typography.font.ttHovesPro,
    fontWeight: 600,
    fontSize: 39,
    letterSpacing: '0.02em',
    color: '#232020',
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  captionSmall: {
    fontFamily: theme.typography.font.ttHovesPro,
    fontWeight: 600,
    fontSize: 16,
    color: '#232020',
    letterSpacing: '0.02em',
    textTransform: 'uppercase',
    textAlign: 'center',
    marginTop: 10,
  },
}));

export default useStyles;
