import PropTypes from 'prop-types';
import { sortBy, sort, take } from 'ramda';

import Presenter from 'utils/PropTypesPresenter';

import CategoryPresenter from 'presenters/CategoryPresenter';
import ValuePresenter from 'presenters/ValuePresenter';

export default new Presenter(
  {
    id: PropTypes.number,
    value: PropTypes.string,
    category: CategoryPresenter.shape(),
  },
  {
    getCustomerCategoryValuesForPillar(targetPillarId, customerCategoryValues) {
      return customerCategoryValues.filter(newCustomerCategoryValue => {
        const newCustomerCategoryValueCategory = this.category(newCustomerCategoryValue);
        const pillarId = CategoryPresenter.pillarId(newCustomerCategoryValueCategory);
        return pillarId === targetPillarId;
      });
    },
    getPointsForCustomerCategoryValues(customerCategoryValues) {
      return customerCategoryValues.reduce((acc, pillarCustomerCategoryValue) => {
        const value = this.value(pillarCustomerCategoryValue);
        const totalPoints = acc[value];
        const categoryValuePoints = ValuePresenter.getPointsFor(value);
        const points = totalPoints ? totalPoints + categoryValuePoints : categoryValuePoints;

        return { ...acc, [value]: points };
      }, {});
    },
    getWeightiestCategoryValue(categoryValuesPoints) {
      const sortedByValueCategoryValuesPoints = sortBy(
        ([value]) => ValuePresenter.getPointsFor(value),
        Object.entries(categoryValuesPoints),
      );

      const getPoints = ([, points]) => points;
      const [weightiestCategoryValue] = sortedByValueCategoryValuesPoints.reduce((acc, value) =>
        getPoints(acc) < getPoints(value) ? value : acc,
      );

      return weightiestCategoryValue;
    },
    getTopNWeightiestCategoryValues(n, customerCategoryValues) {
      const sortedValues = sort((a, b) => b.weight - a.weight, customerCategoryValues);
      return take(n, sortedValues);
    },
  },
);
