import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  datePickersContainer: {
    marginTop: 40,
    fontSize: 16,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: '0.15px',
  },
  datePickerTitle: {
    display: 'block',
    margin: '0 0 10px',
  },
  datePickersBlock: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  dash: {
    lineHeight: 1,
    margin: '6px 20px 0',
  },
  datePickersTrends: {
    marginLeft: 30,
    whiteSpace: 'nowrap',
    '& > span': {
      marginRight: 5,
    },
  },
  datePickerInput: {
    width: 145,
  },
}));

export default useStyles;
