import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  theme => ({
    main: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.ivory,
      fontFamily: theme.typography.font.ttHovesPro,
    },
  }),
  { name: 'AppLayout' },
);

export default useStyles;
