import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TableCell, TableRow, Link } from '@material-ui/core';

import RecommendedFundPresenter from 'presenters/RecommendedFundPresenter';
import { RecommendedFundsView } from 'enums/RecommendedFunds';
import { getIntPercent, getPointedIntPercent } from 'utils/numberHelpers';

import FundCheckbox from 'components/FundCheckbox';

import useStyles from './useStyles';

const Row = props => {
  const { recommendedFund, currentView } = props;

  const classes = useStyles();

  const [isSelectedRow, setIsSelectedRow] = useState(false);

  const handleCheckRecommendedFund = () => {
    setIsSelectedRow(!isSelectedRow);
  };

  const renderFundRow = () => (
    <TableRow className={classes.tableRow}>
      <TableCell width="5%">
        <div className={classes.checkbox}>
          <FundCheckbox
            checked={isSelectedRow}
            id={RecommendedFundPresenter.id(recommendedFund)}
            onChange={handleCheckRecommendedFund}
          />
        </div>
      </TableCell>
      <TableCell width="10%" className={classes.centerCell}>
        {RecommendedFundPresenter.fundTicker(recommendedFund)}
      </TableCell>
      <TableCell width="20%" className={classes.centerCell}>
        {RecommendedFundPresenter.linkUrl(recommendedFund) ? (
          <Link href={RecommendedFundPresenter.linkUrl(recommendedFund)} color="inherit" target="_blank">
            {RecommendedFundPresenter.fundName(recommendedFund)}
          </Link>
        ) : (
          RecommendedFundPresenter.fundName(recommendedFund)
        )}
      </TableCell>
      <TableCell width="10%" className={classes.centerCell}>
        {getPointedIntPercent(RecommendedFundPresenter.reflectionMatchScore(recommendedFund))}
      </TableCell>
      <TableCell width="10%" className={classes.centerCell}>
        {RecommendedFundPresenter.notApplicableValue(recommendedFund, 'firstYear', getPointedIntPercent)}
      </TableCell>
      <TableCell width="10%" className={classes.centerCell}>
        {RecommendedFundPresenter.notApplicableValue(recommendedFund, 'thirdYear', getPointedIntPercent)}
      </TableCell>
      <TableCell width="10%" className={classes.centerCell}>
        {RecommendedFundPresenter.notApplicableValue(recommendedFund, 'fifthYear', getPointedIntPercent)}
      </TableCell>
      <TableCell width="10%" className={classes.centerCell}>
        {RecommendedFundPresenter.notApplicableValue(recommendedFund, 'standardDeviation', getPointedIntPercent)}
      </TableCell>
      <TableCell width="15%" className={classes.centerCell}>
        {RecommendedFundPresenter.lastUpdatedAtInUsFormat(recommendedFund)}
      </TableCell>
    </TableRow>
  );

  const renderModelRow = () => (
    <TableRow className={classes.tableRow}>
      <TableCell>
        <div className={classes.checkbox}>
          <FundCheckbox
            checked={isSelectedRow}
            id={RecommendedFundPresenter.id(recommendedFund)}
            onChange={handleCheckRecommendedFund}
          />
        </div>
      </TableCell>
      <TableCell className={classes.centerCell}>Model 1</TableCell>
      <TableCell className={classes.centerCell}>
        {getIntPercent(RecommendedFundPresenter.roundedMatch(recommendedFund))}
      </TableCell>
      <TableCell className={classes.centerCell}>5%</TableCell>
      <TableCell className={classes.centerCell}>25%</TableCell>
      <TableCell className={classes.centerCell}>65%</TableCell>
      <TableCell className={classes.centerCell}>5%</TableCell>
      <TableCell className={classes.centerCell}>0%</TableCell>
      <TableCell className={classes.centerCell}>
        {RecommendedFundPresenter.notApplicableValue(recommendedFund, 'firstYear', getPointedIntPercent)}
      </TableCell>
      <TableCell className={classes.centerCell}>
        {RecommendedFundPresenter.notApplicableValue(recommendedFund, 'thirdYear', getPointedIntPercent)}
      </TableCell>
      <TableCell className={classes.centerCell}>
        {RecommendedFundPresenter.notApplicableValue(recommendedFund, 'fifthYear', getPointedIntPercent)}
      </TableCell>
      <TableCell className={classes.centerCell}>
        {RecommendedFundPresenter.notApplicableValue(recommendedFund, 'standardDeviation', getPointedIntPercent)}
      </TableCell>
      <TableCell className={classes.centerCell}>
        {RecommendedFundPresenter.lastUpdatedAtInUsFormat(recommendedFund)}
      </TableCell>
    </TableRow>
  );

  return currentView.value === RecommendedFundsView.model ? renderModelRow() : renderFundRow();
};

Row.propTypes = {
  recommendedFund: RecommendedFundPresenter.shape().isRequired,
  currentView: PropTypes.shape().isRequired,
};

export default Row;
