import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './useStyles';

const xOffset = 19;
const yOffset = 4;

const CustomizedYAxisTick = props => {
  const {
    x,
    y,
    payload: { value },
  } = props;

  const classes = useStyles();
  return (
    <g>
      <text x={x - xOffset} y={y + yOffset} textAnchor="middle" fill="#666" className={classes.text}>
        {value}%
      </text>
    </g>
  );
};

CustomizedYAxisTick.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  payload: PropTypes.shape(),
};

export default CustomizedYAxisTick;
