import PropTypes from 'prop-types';
import Presenter from 'utils/PropTypesPresenter';
import TickerAssetClassPresenter from 'presenters/TickerAssetClassPresenter';

export default new Presenter(
  {
    id: PropTypes.number,
    externalId: PropTypes.string,
    name: PropTypes.string,
    percent: PropTypes.number,
    totalAmount: PropTypes.number,
    totalMatchScore: PropTypes.number,
    items: PropTypes.arrayOf(TickerAssetClassPresenter.shape()),
  },
  {
    roundedTotalMatchScore(groupedTickerAssetClass) {
      return Math.round(this.totalMatchScore(groupedTickerAssetClass));
    },
  },
);
