import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  theme => ({
    root: {
      width: '100%',
      padding: '109px 61px 109px 60px',
      flexGrow: 1,
    },
    side: {
      flexBasis: 200,
      backgroundColor: '#232020',
      color: '#fff',
      paddingTop: 145,
      flexShrink: 0,
    },
    title: {
      marginTop: 0,
      fontFamily: theme.typography.font.ttHovesPro,
      fontWeight: 'normal',
      fontSize: 52,
      color: '#232020',
      textAlign: 'center',
      marginBottom: 27,
    },
    remark: {
      fontSize: 30,
    },
    compare: {
      width: '100%',
      marginBottom: 30,
    },
    slider: {
      marginBottom: 42,
      maxWidth: 955,
      margin: '0 auto',
      '@global': {
        '.MuiTab-root': {
          minWidth: 'auto',
          paddingLeft: 11,
          paddingRight: 12,
          marginRight: 15,
          paddingBottom: 6,
        },
      },
    },
    info: {
      display: 'flex',
      margin: '0 auto 50px',
      maxWidth: 785,
      justifyContent: 'center',
      paddingLeft: 63,
    },
    short: { marginRight: 37, display: 'flex', flexDirection: 'column' },
    description: {
      fontFamily: theme.typography.font.ttHovesPro,
      fontSize: 15,
      color: '#000',
      lineHeight: '20px',
      maxWidth: 540,
    },
    PDFIcon: {
      marginLeft: 25,
      display: 'inline-block',
      color: '#000',
      '@media print': {
        visibility: 'hidden',
      },
    },
    icon: {
      fontSize: 40,
    },
    ticker: {
      border: '3px solid #313048',
      fontFamily: theme.typography.font.ttHovesPro,
      fontStyle: 'normal',
      fontWeight: 900,
      textTransform: 'uppercase',
      display: 'inline-block',
      alignSelf: 'center',
      color: '#313048',
      marginBottom: 15,
      padding: '7px 10px',
      fontSize: 21,
    },
    table: {
      marginBottom: 55,
      '@media print': {
        padding: [0, 20],
      },
    },
    tabWrapper: {
      color: '#868686',
      fontWeight: 700,
      fontSize: 12,
      letterSpacing: '0.01em',
      '@media print': {
        visibility: 'hidden',
      },
    },
    tabSelected: {
      '& > span': {
        color: '#000',
      },
    },
    tabsWrapper: {
      display: 'flex',
      justifyContent: 'center',
    },
    warning: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      fontSize: 18,
      marginTop: 88,
    },
    hidden: {
      display: 'none',
    },
    notVisible: {
      visibility: 'hidden',
    },
  }),
  { name: 'CompaniesScored' },
);

export default useStyles;
