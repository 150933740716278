import React from 'react';
import { BooleanField, Filter, TextInput, List, Datagrid, TextField, SelectInput } from 'react-admin';

import RowActions from './components/RowActions';
import FundActions from './FundActions';

import ExportsRepository from 'repositories/admin/fund/ExportsRepository';

const FundFilter = props => (
  <Filter {...props}>
    <TextInput label="ID" source="idEq" alwaysOn />
    <TextInput
      label="Name / Ticker / CIK / Series"
      source="SeriesOrNameOrTickerOrCikCont"
      style={{ width: 250 }}
      alwaysOn
    />
    <SelectInput
      label="Kind"
      source="kindEq"
      choices={[
        { id: 'core', name: 'Core' },
        { id: 'esg', name: 'ESG' },
        { id: 'other', name: 'Other' },
      ]}
      alwaysOn
    />
    <SelectInput
      label="Sync State"
      source="SyncStateEq"
      choices={[
        { id: 'active', name: 'Active' },
        { id: 'error', name: 'Error' },
        { id: 'finished', name: 'Finished' },
        { id: 'disabled', name: 'Disabled' },
      ]}
      alwaysOn
    />
  </Filter>
);

const FundList = props => {
  const handleExport = async () => {
    await ExportsRepository.download();
  };

  return (
    <List filters={<FundFilter />} actions={<FundActions handleExportButtonClick={handleExport} />} {...props}>
      <Datagrid>
        <TextField source="id" />
        <TextField source="ticker" />
        <TextField source="name" />
        <TextField source="series" />
        <TextField source="cik" />
        <TextField source="sync_state" />
        <BooleanField source="is_core" />
        <RowActions />
      </Datagrid>
    </List>
  );
};

export default FundList;
