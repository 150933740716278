import { configureStore } from '@reduxjs/toolkit';
import AppSlice from './AppSlice';
import CustomerLensDefinitionSlice from './CustomerLensDefinitionSlice';
import CustomerLensCreationSlice from './CustomerLensCreationSlice';
import CustomerLensFlagsSetSlice from './CustomerLensFlagsSetSlice';
import ReflectionReportSlice from './ReflectionReportSlice';
import PercentValueHeldSlice from './PercentValueHeldSlice';
import RecommendationSlice from './RecommendationSlice';
import ManuallyEnteredPortfolioSlice from './ManuallyEnteredPortfolioSlice';
import MultipleAccountsReflectionReportSlice from './MultipleAccountsReflectionReportSlice';
import ImportPortfolioSlice from './ImportPortfolioSlice';
import DetailedFundInformationSlice from './DetailedFundInformationSlice';

const store = configureStore({
  reducer: {
    AppSlice,
    CustomerLensDefinitionSlice,
    CustomerLensCreationSlice,
    CustomerLensFlagsSetSlice,
    ReflectionReportSlice,
    PercentValueHeldSlice,
    RecommendationSlice,
    ManuallyEnteredPortfolioSlice,
    MultipleAccountsReflectionReportSlice,
    ImportPortfolioSlice,
    DetailedFundInformationSlice,
  },
});
export default store;
