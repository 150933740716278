import React from 'react';
import { isNil } from 'ramda';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';

import AppRoutes from 'routes/AppRoutes';

import { useApp } from 'hooks';
import Heading from 'components/Heading';
import CustomersDropdown from 'components/CustomersDropdown';

import AccountPresenter from 'presenters/AccountPresenter';
import UserPresenter from 'presenters/UserPresenter';

import useStyles from './useStyles';

const AdvisorWelcome = () => {
  const classes = useStyles();
  const history = useHistory();

  const { currentUser, customersList, selectedCustomer, selectedAccount, changeSelectedCustomer } = useApp();

  const handleRedirect = () => {
    const customerPortfolioAnalysisId = AccountPresenter.customerPortfolioAnalysisId(selectedAccount);
    if (isNil(customerPortfolioAnalysisId)) {
      history.push(AppRoutes.customerLensLensesPath());
    } else {
      history.push(AppRoutes.portfolioAnalysisPath(customerPortfolioAnalysisId));
    }
  };

  const title = isNil(UserPresenter.fullName(currentUser))
    ? 'Welcome.'
    : `Welcome, ${UserPresenter.fullName(currentUser)}.`;

  return (
    <div className={classes.root}>
      <Heading title={title} description="Let’s get started on your Values Investing journey." />
      <div className={classes.selectContainer}>
        <CustomersDropdown
          customersList={customersList}
          selectedCustomer={selectedCustomer}
          onChangeSelectedCustomer={changeSelectedCustomer}
        />
      </div>
      <div className={classes.actions}>
        <Button variant="contained" color="primary" size="large" onClick={handleRedirect}>
          Get Started
        </Button>
      </div>
    </div>
  );
};

export default AdvisorWelcome;
