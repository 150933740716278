import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  content: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  chartsContainer: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(5),
  },
  loading: {
    marginTop: theme.spacing(4),
    marginLeft: 'auto',
    marginRight: 'auto',
    fontSize: 16,
  },
  error: {
    marginTop: theme.spacing(4),
    marginLeft: 'auto',
    marginRight: 'auto',
    fontSize: 16,
    color: '#800000',
  },
}));

export default useStyles;
