import React, { useState, useEffect } from 'react';
import { useFormik, FormikProvider } from 'formik';
import { isEmpty } from 'ramda';
import { useSelector } from 'react-redux';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { useHistory } from 'react-router-dom';

import { initialValues, validationSchema, attributesToSubmit } from 'forms/AdvisorForm';
import useApp from 'hooks/useApp';
import { useManuallyEnteredPortfolioActions } from 'store/ManuallyEnteredPortfolioSlice';
import AppRoutes from 'routes/AppRoutes';
import Loader from 'components/Loader';
import { prepareHousehold } from 'forms/AdvisorEditForm';
import { isNumber } from 'utils/numberHelpers';

import Household from 'containers/AdvisorForm/components/Household';
import useStyles from './useStyles';

const AdvisorEditForm = () => {
  const classes = useStyles();

  const [serverError, setServerError] = useState('');
  const [removedCustomerIds, setRemovedCustomerIds] = useState([]);
  const [removedAccountIds, setRemovedAccountIds] = useState([]);
  const {
    updateAdvisorHousehold,
    isLoading,
    changeShouldRedirectOnSelectedAccountChange,
    currentUser,
    loadAdvisorHousehold,
  } = useApp();
  const history = useHistory();

  const { loadAssetClasses } = useManuallyEnteredPortfolioActions();
  const isLoadingAssetClasses = useSelector(state => state.ManuallyEnteredPortfolioSlice.assetClasses.loading);

  const handleInputChange = (handleChange, setErrors) => event => {
    setErrors({});
    handleChange(event);
  };

  const handleSubmitForm = async (params, actions) => {
    try {
      const values = attributesToSubmit(params);
      const valuesWithDeletedAttributes = { household: { ...values.household, removedCustomerIds, removedAccountIds } };
      await updateAdvisorHousehold(params.household.id, valuesWithDeletedAttributes);
      actions.setSubmitting(false);
      history.push(AppRoutes.rootPath());
    } catch (error) {
      setServerError('Something went wrong!');
    }
  };

  const handleRemoveCustomer = (handleRemove, customer) => event => {
    if (isNumber(customer.id)) {
      setRemovedCustomerIds([...removedCustomerIds, customer.id]);
    }
    handleRemove(event);
  };

  const handleRemoveAccount = (handleRemove, account) => event => {
    if (isNumber(account.id)) {
      setRemovedAccountIds([...removedAccountIds, account.id]);
    }
    handleRemove(event);
  };

  const formik = useFormik({
    onSubmit: handleSubmitForm,
    validationSchema,
    initialValues,
    validateOnChange: false,
    validateOnBlur: true,
  });
  const { handleSubmit, values, handleChange, errors, setFieldValue, setErrors, isSubmitting } = formik;

  useEffect(() => {
    const loadHouseholdInfo = async () => {
      const { assetClasses } = await loadAssetClasses();
      const { data } = await loadAdvisorHousehold(currentUser.householdId);
      const state = prepareHousehold(data.household, assetClasses);
      setFieldValue('household', state);
    };

    changeShouldRedirectOnSelectedAccountChange(false);
    loadHouseholdInfo();
  }, []);

  if (isLoading && isLoadingAssetClasses) return <Loader />;

  return (
    <main className={classes.main}>
      <Container component="main" maxWidth="md" className={classes.container}>
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5" gutterBottom>
            Edit
          </Typography>
          <form onSubmit={handleSubmit} noValidate>
            <FormikProvider value={formik}>
              <Household
                values={values}
                errors={errors}
                setErrors={setErrors}
                setFieldValue={setFieldValue}
                disabled={isSubmitting}
                onChange={handleInputChange(handleChange, setErrors)}
                onRemoveCustomer={handleRemoveCustomer}
                onRemoveAccount={handleRemoveAccount}
              />
              {!isEmpty(serverError) && <div className={classes.error}>{serverError}</div>}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={isSubmitting}
              >
                Submit
              </Button>
            </FormikProvider>
          </form>
        </div>
      </Container>
    </main>
  );
};

export default AdvisorEditForm;
