import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  hasNotification: {
    '& $menu': {
      color: '#0f5e75',
      overflow: 'visible',
    },
  },
  menu: {
    position: 'relative',
    padding: 0,
    minWidth: 'auto',
  },
  badge: {
    marginRight: 3,
  },
  badgeCounter: {
    fontFamily: theme.typography.font.ttHovesPro,
    fontWeight: 'bold',
    fontSize: 14,
    marginRight: 3,
  },
  paper: {
    width: 290,
    minHeight: 175,
    position: 'relative',
    overflow: 'visible',
    boxShadow: '-2px 2px 10px 0 rgba(0,0,0,0.10)',
    border: '1px solid #e1e1e1',
    borderRadius: 10,
    padding: '25px 17px 30px 20px',
    marginTop: 14,
    marginLeft: 22,
    '&::before': {
      content: '""',
      width: 0,
      height: 0,
      borderStyle: 'solid',
      borderWidth: '0 7px 12px 7px',
      borderColor: 'transparent transparent #e1e1e1 transparent',
      top: 0,
      right: 0,
      marginTop: -13,
      position: 'absolute',
      marginRight: 26,
    },
    '&::after': {
      content: '""',
      width: 0,
      height: 0,
      borderStyle: 'solid',
      borderWidth: '0 7px 12px 7px',
      borderColor: 'transparent transparent #fff transparent',
      top: 0,
      right: 0,
      marginTop: -11,
      position: 'absolute',
      marginRight: 26,
    },
  },
  title: {
    fontFamily: theme.typography.font.ttHovesPro,
    fontWeight: 700,
    fontSize: 18,
    color: '#232020',
    marginTop: 0,
    marginBottom: 16,
  },
  fundsContainer: {
    marginBottom: 20,
  },
  savedFund: {
    background: '#fff',
    border: '1px solid #e1e1e1',
    borderRadius: '10px 0 10px 0',
    minHeight: 58,
    position: 'relative',
    padding: '11px 49px 11px 21px',
    fontFamily: theme.typography.font.ttHovesPro,
    fontWeight: 300,
    fontSize: 12,
    letterSpacing: '0.01em',
    color: '#515151',
    marginBottom: 10,
    lineHeight: '15px',
    '&:last-child': {
      marginBottom: 0,
    },
  },
  close: {
    color: '#7a7a7a',
    width: 16,
    height: 16,
    position: 'absolute',
    top: 0,
    right: 0,
    marginTop: 8,
    marginRight: 9,
    cursor: 'pointer',
  },
  ticker: {
    textTransform: 'uppercase',
  },
  name: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

export default useStyles;
