import PropTypes from 'prop-types';

import Presenter from 'utils/PropTypesPresenter';

import FundPresenter from 'presenters/FundPresenter';

export default new Presenter(
  {
    id: PropTypes.number,
    amount: PropTypes.string,
    fund: FundPresenter.shape(),
  },
  {},
);
