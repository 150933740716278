import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import { isEmpty, find, propEq, isNil } from 'ramda';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import AppRoutes from 'routes/AppRoutes';

import { useApp, useCustomerLensFlagsSet, useMountMethods } from 'hooks';

import { rejectBy } from 'utils/storeUtils';

import CustomerLensPresenter from 'presenters/CustomerLensPresenter';
import CustomerLensFlagPresenter from 'presenters/CustomerLensFlagPresenter';
import FlagPresenter from 'presenters/FlagPresenter';
import UserPresenter from 'presenters/UserPresenter';
import AccountPresenter from 'presenters/AccountPresenter';
import PortfolioAnalysisPresenter from 'presenters/PortfolioAnalysisPresenter';

import Heading from 'components/Heading';

import Card from './components/Card';

import useStyles from './useStyles';

const CustomerLensFlagsSet = props => {
  const {
    customerLens,
    customerLensFlags,
    onLoadCustomerLens,
    onUpdateCustomerLens,
    currentUser,
    selectedCustomer,
  } = props;

  const history = useHistory();
  const routeParams = useParams();
  const classes = useStyles();

  const {
    showLoader,
    hideLoader,
    hideRefreshReportModal,
    loadAccounts,
    loadAdvisorCustomerAccounts,
    changeAccounts,
    changeShouldRedirectOnSelectedAccountChange,
    selectedAccount,
    customersList,
  } = useApp();

  const {
    loadFlags,
    resetFlags,
    createPortfolioAnalysesBatch,
    createAdvisorPortfolioAnalysesBatch,
    createCustomerRecommendation,
    createAdvisorRecommendation,
    createPortfolioAnalysis,
    createAdvisorPortfolioAnalysis,
    flags,
  } = useCustomerLensFlagsSet();

  const [destroyedCustomerLensFlags, changeDestroyedCustomerLensFlags] = useState([]);
  const [customerLensFlagsState, changeCustomerLensFlagsState] = useState([]);

  const customerLensId = Number(routeParams.id);
  const handleMount = () => {
    showLoader('calibrating');
    const actions = isEmpty(customerLens) ? [loadFlags(), onLoadCustomerLens(customerLensId)] : [loadFlags()];
    Promise.all(actions).finally(() => {
      hideLoader();
    });
  };

  useMountMethods({ onMount: handleMount, onUnmount: resetFlags });
  useEffect(() => {
    if (!isNil(customerLensFlags)) {
      changeCustomerLensFlagsState(customerLensFlags);
    }
  }, [customerLensFlags]);

  if (isEmpty(customerLens)) {
    return null;
  }

  const sortedFlags = FlagPresenter.sortedByDisplayOrder(flags);
  const flagsWithChecked = sortedFlags.map(flag => {
    const id = FlagPresenter.id(flag);
    const customerLensFlag = find(propEq('flagId', id), customerLensFlagsState);
    const checked = !isNil(customerLensFlag);

    return { ...flag, checked };
  });

  const handleChangeSelection = flag => {
    const flagId = FlagPresenter.id(flag);
    const customerLensFlag = find(propEq('flagId', flagId), customerLensFlags);
    const customerLensFlagId = isNil(customerLensFlag) ? null : CustomerLensFlagPresenter.id(customerLensFlag);

    if (flag.checked) {
      const newCustomerLensFlagsState = rejectBy('flagId', flagId, customerLensFlagsState);
      changeCustomerLensFlagsState(newCustomerLensFlagsState);

      if (!isNil(customerLensFlagId)) {
        const newDestroyedCustomerLensFlagsState = [
          ...destroyedCustomerLensFlags,
          { id: customerLensFlagId, _destroy: true },
        ];
        changeDestroyedCustomerLensFlags(newDestroyedCustomerLensFlagsState);
      }
    } else {
      const newCustomerLensFlagsState = [...customerLensFlagsState, { id: customerLensFlagId, flagId }];
      changeCustomerLensFlagsState(newCustomerLensFlagsState);

      const newDestroyedCustomerLensFlagsState = rejectBy('id', customerLensFlagId, destroyedCustomerLensFlags);
      changeDestroyedCustomerLensFlags(newDestroyedCustomerLensFlagsState);
    }
  };

  const handleCreateRecommendatonForCustomer = analysesBatchId => {
    createCustomerRecommendation({
      recommendation: {
        customerLensId: customerLens.id,
        customerPortfolioAnalysesBatchId: analysesBatchId,
      },
    });
  };
  const handleCreateRecommendatonForAdvisor = analysesBatchId => {
    const customerId = UserPresenter.id(selectedCustomer);

    createAdvisorRecommendation({
      recommendation: {
        customerId,
        customerLensId: customerLens.id,
        customerPortfolioAnalysesBatchId: analysesBatchId,
      },
    });
  };

  const handleRedirectAdvisor = async analysis => {
    changeShouldRedirectOnSelectedAccountChange(false);
    const accountIds = UserPresenter.accountIds(selectedCustomer).filter(id => id !== selectedAccount.id);
    const batchId = PortfolioAnalysisPresenter.customerPortfolioAnalysesBatchId(analysis);
    if (!isEmpty(accountIds)) {
      await createAdvisorPortfolioAnalysesBatch({
        customerPortfolioAnalysis: { customerPortfolioAnalysesBatchId: batchId, customerLensId, accountIds },
      });
    }

    if (UserPresenter.recommendationsAvailable(currentUser)) handleCreateRecommendatonForAdvisor(batchId);

    const customerIds = customersList.map(({ id }) => id);
    const accounts = await loadAdvisorCustomerAccounts({ q: { userAccountsUserIdIn: customerIds } });
    changeAccounts(accounts);
    const initAccount = accounts.find(account => account.id === selectedAccount.id);
    const initAnalysisId = AccountPresenter.cachedCustomerPortfolioAnalysisId(initAccount);

    hideRefreshReportModal();
    history.push(AppRoutes.portfolioAnalysisPath(initAnalysisId));
    hideLoader();
  };
  const handleRedirectCustomer = async analysis => {
    changeShouldRedirectOnSelectedAccountChange(false);
    const accountIds = UserPresenter.accountIds(currentUser).filter(id => id !== selectedAccount.id);
    const batchId = PortfolioAnalysisPresenter.customerPortfolioAnalysesBatchId(analysis);
    if (!isEmpty(accountIds)) {
      await createPortfolioAnalysesBatch({
        customerPortfolioAnalysis: { customerPortfolioAnalysesBatchId: batchId, customerLensId, accountIds },
      });
    }

    if (UserPresenter.recommendationsAvailable(currentUser)) handleCreateRecommendatonForCustomer(batchId);

    const accounts = await loadAccounts();
    changeAccounts(accounts);
    const initAccount = accounts.find(account => account.id === selectedAccount.id);
    const initAnalysisId = AccountPresenter.customerPortfolioAnalysisId(initAccount);

    hideRefreshReportModal();
    history.push(AppRoutes.portfolioAnalysisPath(initAnalysisId));
    hideLoader();
  };

  const handleCreateAnalysisForAdvisor = async () => {
    const accountId = selectedAccount.id;
    const analysis = await createAdvisorPortfolioAnalysis({
      customerPortfolioAnalysis: { accountId, customerLensId },
    });
    handleRedirectAdvisor(analysis);
  };
  const handleCreateAnalysisForCustomer = async () => {
    const accountId = selectedAccount.id;
    const analysis = await createPortfolioAnalysis({ customerPortfolioAnalysis: { accountId, customerLensId } });
    handleRedirectCustomer(analysis);
  };

  const handleUpdateCustomerLensFlags = async () => {
    showLoader('finalizing report');
    const newCustomerLensFlags = customerLensFlagsState.filter(customerLensFlagState => {
      const customerLensFlag = find(propEq('flagId', customerLensFlagState.flagId), customerLensFlags);

      return isNil(customerLensFlag);
    });
    const params = {
      final: true,
      customerLensFlagsAttributes: [...newCustomerLensFlags, ...destroyedCustomerLensFlags],
    };

    await onUpdateCustomerLens(CustomerLensPresenter.id(customerLens), params);

    if (UserPresenter.isAdvisor(currentUser)) {
      handleCreateAnalysisForAdvisor();
    } else {
      handleCreateAnalysisForCustomer();
    }
  };

  const handleClickBackButton = () => {
    history.push(AppRoutes.customerLensValuesPath(customerLensId));
  };

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Heading
          intro="What to avoid?"
          title="Identify Any Issues"
          description="A company's policies and practices indicate how they operate, but there may be certain types of companies you want to avoid based on what they do."
          className={classes.heading}
        />
        <div className={classes.main}>
          <Grid container spacing={5} justify="center">
            {flagsWithChecked.map(flag => (
              <Grid item xs={3} key={flag.id}>
                <Card flag={flag} checked={flag.checked} onChangeSelection={handleChangeSelection} />
              </Grid>
            ))}
          </Grid>
        </div>
        <div className={classes.formActions}>
          <div className={classes.buttonWrapper}>
            <Button variant="contained" color="primary" size="large" onClick={handleUpdateCustomerLensFlags}>
              View report
            </Button>
          </div>
          <div className={classes.buttonWrapper}>
            <Button size="small" variant="text" onClick={handleClickBackButton}>
              Back
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

CustomerLensFlagsSet.propTypes = {
  customerLens: CustomerLensPresenter.shape().isRequired,
  customerLensFlags: PropTypes.arrayOf(CustomerLensFlagPresenter.shape()),
  onLoadCustomerLens: PropTypes.func.isRequired,
  onUpdateCustomerLens: PropTypes.func.isRequired,
  currentUser: UserPresenter.shape().isRequired,
  selectedCustomer: UserPresenter.shape(),
};

CustomerLensFlagsSet.defaultProps = {
  customerLensFlags: [],
  selectedCustomer: null,
};

export default CustomerLensFlagsSet;
