import { useSelector } from 'react-redux';

import { useReflectionReportActions } from 'store/ReflectionReportSlice';
import getFetchStatus from 'utils/getFetchStatus';

export default () => {
  const {
    loadCustomerFundAnalyses,
    loadCustomerFundAnalysesOptions,
    loadPortfolioAnalysis,
    loadFullPortfolioAnalysis,
    loadFlaggedTickers,
    loadInsights,
    setCurrentReport,
    loadMetrics,
    loadMixedReport,
    loadReportMetrics,
    updateAccount,
    duplicateCustomerLens,
    createPortfolioAnalysis,
    createPortfolioAnalysesBatch,
    createAdvisorPortfolioAnalysis,
    createAdvisorPortfolioAnalysesBatch,
    loadScoredCompanies,
    loadScoredCompany,
    loadScoredCompaniesOptions,
    loadInsightsScoredCompanies,
    loadInsightsScoredCompaniesOptions,
    resetToInitialState,
    createCustomerRecommendation,
    createAdvisorRecommendation,
  } = useReflectionReportActions();

  const customerFundAnalyses = useSelector(state => state.ReflectionReportSlice.customerFundAnalyses.data);
  const customerFundAnalysesOptions = useSelector(
    state => state.ReflectionReportSlice.customerFundAnalysesOptions.data,
  );
  const currentReport = useSelector(state => state.ReflectionReportSlice.currentReport.data);
  const currentReportStatus = useSelector(state => state.ReflectionReportSlice.currentReport.status);
  const currentReportStatuses = getFetchStatus(currentReportStatus);

  const portfolioAnalysis = useSelector(state => state.ReflectionReportSlice.portfolioAnalysis.data);
  const flaggedTickers = useSelector(state => state.ReflectionReportSlice.flaggedTickers.data);
  const fundScores = useSelector(state => state.ReflectionReportSlice.fundScores.data);
  const customerFundsReport = useSelector(state => state.ReflectionReportSlice.customerFundsReport.data);
  const customerFundsReportMetrics = useSelector(state => state.ReflectionReportSlice.customerFundsReportMetrics.data);
  const customerFundsReportStatus = useSelector(state => state.ReflectionReportSlice.customerFundsReport.status);
  const customerFundsReportStatuses = getFetchStatus(customerFundsReportStatus);

  const scoredCompanies = useSelector(state => state.ReflectionReportSlice.scoredCompanies.data);
  const scoredCompaniesStatus = useSelector(state => state.ReflectionReportSlice.scoredCompanies.status);
  const scoredCompaniesStatuses = getFetchStatus(scoredCompaniesStatus);

  const scoredCompany = useSelector(state => state.ReflectionReportSlice.scoredCompany.data);
  const scoredCompanyStatus = useSelector(state => state.ReflectionReportSlice.scoredCompany.status);
  const scoredCompanyStatuses = getFetchStatus(scoredCompanyStatus);

  const scoredCompaniesOptions = useSelector(state => state.ReflectionReportSlice.scoredCompaniesOptions.data);
  const scoredCompaniesOptionsStatus = useSelector(state => state.ReflectionReportSlice.scoredCompaniesOptions.status);
  const scoredCompaniesOptionsStatuses = getFetchStatus(scoredCompaniesOptionsStatus);

  const insightsScoredCompanies = useSelector(state => state.ReflectionReportSlice.insightsScoredCompanies.data);
  const insightsScoredCompaniesStatus = useSelector(
    state => state.ReflectionReportSlice.insightsScoredCompanies.status,
  );
  const insightsScoredCompaniesStatuses = getFetchStatus(insightsScoredCompaniesStatus);

  const insightsScoredCompaniesOptions = useSelector(
    state => state.ReflectionReportSlice.insightsScoredCompaniesOptions.data,
  );
  const insightsScoredCompaniesOptionsStatus = useSelector(
    state => state.ReflectionReportSlice.insightsScoredCompaniesOptions.status,
  );
  const insightsScoredCompaniesOptionsStatuses = getFetchStatus(insightsScoredCompaniesOptionsStatus);

  const mixedReportStatus = useSelector(state => state.ReflectionReportSlice.mixedPortfolioAnalysis.status);
  const customerFundsReportMetricsLoadingStatus = useSelector(
    state => state.ReflectionReportSlice.customerFundsReportMetrics.status,
  );

  return {
    loadCustomerFundAnalyses,
    loadCustomerFundAnalysesOptions,
    loadPortfolioAnalysis,
    loadFullPortfolioAnalysis,
    loadFlaggedTickers,
    loadInsights,
    setCurrentReport,
    loadMetrics,
    loadMixedReport,
    loadReportMetrics,
    updateAccount,
    duplicateCustomerLens,
    createPortfolioAnalysis,
    createPortfolioAnalysesBatch,
    createAdvisorPortfolioAnalysis,
    createAdvisorPortfolioAnalysesBatch,
    loadScoredCompanies,
    loadInsightsScoredCompanies,
    loadInsightsScoredCompaniesOptions,
    resetToInitialState,
    createCustomerRecommendation,
    createAdvisorRecommendation,
    customerFundAnalyses,
    customerFundAnalysesOptions,
    currentReport,
    portfolioAnalysis,
    flaggedTickers,
    fundScores,
    customerFundsReport,
    customerFundsReportMetrics,
    scoredCompanies,
    scoredCompaniesStatuses,
    loadScoredCompany,
    scoredCompany,
    scoredCompanyStatuses,
    loadScoredCompaniesOptions,
    scoredCompaniesOptions,
    scoredCompaniesOptionsStatuses,
    insightsScoredCompanies,
    insightsScoredCompaniesStatuses,
    insightsScoredCompaniesOptions,
    insightsScoredCompaniesOptionsStatuses,
    mixedReportStatus,
    customerFundsReportMetricsLoadingStatus,
    currentReportStatuses,
    customerFundsReportStatuses,
  };
};
