/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import FlagsRepository from 'repositories/FlagsRepository';
import CustomerRecommendationsRepository from 'repositories/customer/RecommendationsRepository';
import AdvisorRecommendationsRepository from 'repositories/advisor/RecommendationsRepository';
import CustomerPortfolioAnalysesRepository from 'repositories/customer/CustomerPortfolioAnalysesRepository';
import AdvisorCustomerPortfolioAnalysesRepository from 'repositories/advisor/CustomerPortfolioAnalysesRepository';
import { FETCH_STATUSES } from 'presenters/FetchStatusPresenter';

const initialState = {
  flags: {
    status: FETCH_STATUSES.idle,
    data: [],
    meta: {},
  },
};

const customerLensFlagsSetSlice = createSlice({
  name: 'customerLensFlagsSet',
  initialState,
  reducers: {
    loadFlagsStart(state) {
      state.flags.status = FETCH_STATUSES.loading;
    },
    loadFlagsSuccess(state, { payload }) {
      state.flags.status = FETCH_STATUSES.success;
      state.flags.data = payload.flags;
      state.flags.meta = payload.meta;
    },
    loadFlagsFail(state) {
      state.flags.status = FETCH_STATUSES.failure;
      state.flags.data = [];
      state.flags.meta = {};
    },
    resetFlags(state) {
      state.flags.status = FETCH_STATUSES.idle;
      state.flags.data = [];
      state.flags.meta = {};
    },
  },
});

export const {
  loadFlagsStart,
  loadFlagsSuccess,
  loadFlagsFail,
  resetFlags,
  createRecommendationSuccess,
} = customerLensFlagsSetSlice.actions;

export default customerLensFlagsSetSlice.reducer;

export const useCustomerLensFlagsSetActions = () => {
  const dispatch = useDispatch();

  const loadFlags = () => {
    dispatch(loadFlagsStart());

    return FlagsRepository.index()
      .then(({ data }) => dispatch(loadFlagsSuccess(data)))
      .catch(() => dispatch(loadFlagsFail()));
  };

  const resetFlagsAction = () => dispatch(resetFlags());

  const createPortfolioAnalysis = async params => {
    const {
      data: { customerPortfolioAnalysis },
    } = await CustomerPortfolioAnalysesRepository.create(params);

    return customerPortfolioAnalysis;
  };

  const createAdvisorPortfolioAnalysis = async params => {
    const {
      data: { customerPortfolioAnalysis },
    } = await AdvisorCustomerPortfolioAnalysesRepository.create(params);

    return customerPortfolioAnalysis;
  };

  const createPortfolioAnalysesBatch = async params => {
    const {
      data: { customerPortfolioAnalysesBatch },
    } = await CustomerPortfolioAnalysesRepository.create_batch(params);

    return customerPortfolioAnalysesBatch;
  };

  const createAdvisorPortfolioAnalysesBatch = async params => {
    const {
      data: { customerPortfolioAnalysesBatch },
    } = await AdvisorCustomerPortfolioAnalysesRepository.create_batch(params);

    return customerPortfolioAnalysesBatch;
  };

  const createCustomerRecommendation = async params => {
    const {
      data: { recommendation },
    } = await CustomerRecommendationsRepository.create(params);

    return recommendation;
  };

  const createAdvisorRecommendation = async params => {
    const {
      data: { recommendation },
    } = await AdvisorRecommendationsRepository.create(params);

    return recommendation;
  };

  return {
    loadFlags,
    resetFlags: resetFlagsAction,
    createPortfolioAnalysesBatch,
    createAdvisorPortfolioAnalysesBatch,
    createCustomerRecommendation,
    createAdvisorRecommendation,
    createPortfolioAnalysis,
    createAdvisorPortfolioAnalysis,
  };
};
