import PropTypes from 'prop-types';
import { sortBy } from 'ramda';

import Presenter from 'utils/PropTypesPresenter';
import FundPortfolioPresenter from 'presenters/FundPortfolioPresenter';

export default new Presenter(
  {
    id: PropTypes.number,
    amount: PropTypes.string,
    companyId: PropTypes.number,
    companyName: PropTypes.string,
    companyTicker: PropTypes.string,
    fundName: PropTypes.string,
    fundTicker: PropTypes.string,
    customerFundAnalysisId: PropTypes.number,
    fundId: PropTypes.number,
    edgarId: PropTypes.string,
    fundPortfolio: FundPortfolioPresenter.shape(),
    flags: PropTypes.arrayOf(PropTypes.string),
    scoreableWeight: PropTypes.number,
    totalWeight: PropTypes.number,
    weight: PropTypes.number,
    weightText: PropTypes.string,
  },
  {
    sortedByTicker(tickersMeta) {
      const getTicker = tickerMeta => this.companyTicker(tickerMeta);

      return sortBy(getTicker, tickersMeta);
    },
  },
);
