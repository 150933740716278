import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: '45px 0',
    fontFamily: theme.typography.font.ttHovesPro,
  },
  main: {
    flexGrow: 1,
    width: '100%',
    overflow: 'hidden',
  },
  title: {
    fontFamily: theme.typography.font.ttHovesPro,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 52,
    color: '#232020',
    textAlign: 'center',
    marginBottom: 27,
  },
  select: {
    width: '100%',
    marginBottom: 40,
    padding: [0, 25],
  },
  caption: {
    fontFamily: theme.typography.font.ttHovesPro,
    fontWeight: 300,
    fontSize: 19,
    color: '#454545',
    textAlign: 'center',
    marginBottom: 34,
  },
  tabs: {
    marginBottom: 90,
    display: 'block',
    textAlign: 'center',
  },
  reportTitle: {
    fontSize: 24,
    fontWeight: 'bold',
  },
  tableWrapper: {
    marginBottom: 40,
    pageBreakAfter: 'always',
  },
  chartContainer: {
    padding: [0, 40],
  },
}));

export default useStyles;
