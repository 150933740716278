import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  theme => ({
    root: {
      paddingBottom: 15,
    },
    container: {
      width: '100%',
      maxWidth: 840,

      margin: '0 auto',
      display: 'flex',
      backgroundColor: '#fff',
      padding: [50, 100],
      flexDirection: 'column',
      boxShadow: '-4px 4px 15px 0 rgba(0,0,0,0.10)',
      borderRadius: '0 70px 70px 0',
      [theme.breakpoints.up('md')]: {
        maxWidth: 1400,
      },
    },
    layout: {
      display: 'block',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
        justifyContent: 'space-between',
      },
    },
    side: {
      width: '100%',
      maxWidth: '100%',
      [theme.breakpoints.up('md')]: {
        maxWidth: 170,
      },
    },
    main: {
      width: '100%',
      maxWidth: 809,
      paddingTop: 25,
    },
    balls: {
      display: 'flex',
      justifyContent: 'space-around',
      paddingTop: 0,
      [theme.breakpoints.up('md')]: {
        display: 'block',
      },
    },
    ball: {
      marginBottom: 20,
    },
    title: {
      fontFamily: theme.typography.font.ttHovesPro,
      fontWeight: 'normal',
      fontSize: 52,
      color: '#232020',
      textAlign: 'center',
      marginBottom: 41,
      marginTop: 0,
    },
    options: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      marginBottom: 20,
      paddingRight: 4,
    },
    fundsFilter: {
      width: '80%',
    },
    chart: {
      width: '100%',
      height: 355,
      marginBottom: 140,
      position: 'relative',
    },
    selectedfilters: {
      marginTop: 20,
      marginBottom: 35,
      height: 20,
      display: 'flex',
    },
    caption: {
      marginRight: 20,
      flexShrink: 0,
      fontFamily: theme.typography.font.ttHovesPro,
      fontSize: 13,
      fontWeight: 300,
    },
    items: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
    item: {
      marginRight: 10,
      marginBottom: 10,
    },
    chipRoot: {
      backgroundColor: '#232020',
      borderRadius: 13,
      height: 20,
      display: 'flex',
      flexDirection: 'row-reverse',
      paddingLeft: 4,
      paddingRight: 8,
    },
    PDFIcon: {
      marginLeft: 25,
      display: 'inline-block',
      color: '#000',
      '@media print': {
        visibility: 'hidden',
      },
    },
    icon: {
      fontSize: 40,
    },
    chipLabel: {
      color: '#fff',
      fontFamily: theme.typography.font.ttHovesPro,
      fontSize: 12,
      letterSpacing: '0.01em',
      fontWeight: 300,
      textTransform: 'initial',
      padding: 0,
      lineHeight: 1,
    },
    removeIcon: {
      width: 12,
      height: 12,
      margin: 0,
      marginRight: 8,
    },
    header: {
      fontFamily: theme.typography.font.ttHovesPro,
      fontWeight: 300,
      fontSize: 19,
      color: '#FFFFFF',
      textAlign: 'left',
      lineHeight: '20px',
      textTransform: 'uppercase',
      marginBottom: 8,
    },
    link: {
      fontFamily: theme.typography.font.ttHovesPro,
      fontWeight: 700,
      fontSize: 13,
      color: '#FFFFFF',
      lineHeight: '20px',
      cursor: 'pointer',
      backgroundColor: 'Transparent',
      backgroundRepeat: 'no-repeat',
      border: 'none',
      overflow: 'hidden',
      outline: 'none',
      padding: 0,
    },
    hidden: {
      display: 'none',
    },
    pdfWrapper: {
      width: 1000,
      margin: 'auto',
    },
    notVisible: {
      visibility: 'hidden',
    },
  }),
  { name: 'PortfolioAnalysisInsights' },
);

export default useStyles;
