import { utcToZonedTime, format } from 'date-fns-tz';

export const US_DATE_FORMAT = 'MM/dd/yyyy';
export const ISO_DATE_FORMAT = 'yyyy-MM-dd';

export const formatTimestampToET = timestamp => {
  const timezone = 'America/New_York';
  const date = new Date(timestamp);
  const zonedDate = utcToZonedTime(date, timezone);
  return format(zonedDate, "LLLL d, y, p 'ET'", { timeZone: timezone });
};
