import React from 'react';
import PropTypes from 'prop-types';
import Value from './components/Value';
import Button from '@material-ui/core/Button';
import useStyles from './useStyles';
import CategoryPresenter from 'presenters/CategoryPresenter';

const PillarInfo = ({ onClose, category, value }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.title}>{CategoryPresenter.name(category)}</div>
      <div className={classes.description}>{CategoryPresenter.extendedDescription(category)}</div>
      <img
        src={CategoryPresenter.imageUrl(category)}
        className={classes.image}
        width="250"
        height="250"
        alt="Category icon"
      />
      <div className={classes.value}>
        <Value value={value} name={CategoryPresenter.name(category)} />
      </div>
      <Button
        variant="outlined"
        classes={{
          root: classes.buttonRoot,
          label: classes.buttonLabel,
        }}
        onClick={onClose}
      >
        Close
      </Button>
    </div>
  );
};

PillarInfo.propTypes = {
  onClose: PropTypes.func,
  category: PropTypes.shape(),
  value: PropTypes.string,
};

export default PillarInfo;
