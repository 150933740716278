import React from 'react';

import SustainableDevelopmentGoalPresenter from 'presenters/SustainableDevelopmentGoalPresenter';
import CompanyPresenter from 'presenters/CompanyPresenter';

import noPoverty from './images/noPoverty.svg';
import zeroHunger from './images/zeroHunger.svg';
import goodHealthAndWellBeing from './images/goodHealthAndWellBeing.svg';
import qualityEducation from './images/qualityEducation.svg';
import genderEquality from './images/genderEquality.svg';
import cleanWaterAndSanitation from './images/cleanWaterAndSanitation.svg';
import affordableAndCleanEnergy from './images/affordableAndCleanEnergy.svg';
import decentWorkAndEconomicGrowth from './images/decentWorkAndEconomicGrowth.svg';
import industryInnovationAndInfrastructure from './images/industryInnovationAndInfrastructure.svg';
import reducedInequality from './images/reducedInequality.svg';
import sustainableCitiesAndCommunities from './images/sustainableCitiesAndCommunities.svg';
import responsibleConsumptionAndProduction from './images/responsibleConsumptionAndProduction.svg';
import climateAction from './images/climateAction.svg';
import lifeBelowWater from './images/lifeBelowWater.svg';
import lifeOnLand from './images/lifeOnLand.svg';
import peaceAndJusticeStrongInstitutions from './images/peaceAndJusticeStrongInstitutions.svg';
import partnershipsToAchieveTheGoal from './images/partnershipsToAchieveTheGoal.svg';
import logo from './images/logo.svg';

import useStyles from './useStyles';

const PANELS = [
  { id: 1, name: 'no poverty', icon: noPoverty },
  { id: 2, name: 'zero hunger', icon: zeroHunger },
  { id: 3, name: 'good health and well being', icon: goodHealthAndWellBeing },
  { id: 4, name: 'quality education', icon: qualityEducation },
  { id: 5, name: 'gender equality', icon: genderEquality },
  { id: 6, name: 'clean water and sanitation', icon: cleanWaterAndSanitation },
  { id: 7, name: 'affordable and clean energy', icon: affordableAndCleanEnergy },
  { id: 8, name: 'decent work and economic growth', icon: decentWorkAndEconomicGrowth },
  { id: 9, name: 'industry innovation and infrastructure', icon: industryInnovationAndInfrastructure },
  { id: 10, name: 'reduced inequality', icon: reducedInequality },
  { id: 11, name: 'sustainable cities and communities', icon: sustainableCitiesAndCommunities },
  { id: 12, name: 'responsible consumption and production', icon: responsibleConsumptionAndProduction },
  { id: 13, name: 'climate action', icon: climateAction },
  { id: 14, name: 'life below water', icon: lifeBelowWater },
  { id: 15, name: 'life on land', icon: lifeOnLand },
  { id: 16, name: 'peace and justice strong institutions', icon: peaceAndJusticeStrongInstitutions },
  { id: 17, name: 'partnerships to achieve the goal', icon: partnershipsToAchieveTheGoal },
];

const COLORS = [
  { id: 1, color: '#eb1c2d' },
  { id: 2, color: '#d3a029' },
  { id: 3, color: '#279b48' },
  { id: 4, color: '#c31f33' },
  { id: 5, color: '#ef402b' },
  { id: 6, color: '#00aed9' },
  { id: 7, color: '#fdb713' },
  { id: 8, color: '#8f1838' },
  { id: 9, color: '#f36d25' },
  { id: 10, color: '#e11484' },
  { id: 11, color: '#f99d26' },
  { id: 12, color: '#cf8d2a' },
  { id: 13, color: '#48773e' },
  { id: 14, color: '#007dbc' },
  { id: 15, color: '#3eb049' },
  { id: 16, color: '#02558b' },
  { id: 17, color: '#183668' },
];

const DEFAULT_COLOR = '#c4c3c0';

const Panels = props => {
  const { company } = props;
  const classes = useStyles();

  const sustainableDevelopmentGoals = CompanyPresenter.sustainableDevelopmentGoals(company);

  const anyId = id =>
    sustainableDevelopmentGoals.some(goal => Number(SustainableDevelopmentGoalPresenter.slug(goal)) === id);

  const panelColors = COLORS.reduce((acc, panel) => {
    const color = anyId(panel.id) ? panel.color : DEFAULT_COLOR;

    return { ...acc, [panel.id]: color };
  }, {});

  const renderPanels = panel => {
    const { id, name, icon } = panel;
    return (
      <div key={id} className={classes.panel} style={{ backgroundColor: panelColors[id] }}>
        <img src={icon} alt={`${name} icon`} />
      </div>
    );
  };

  return (
    <div className={classes.container}>
      <div className={classes.heading}>
        <div className={classes.title}>
          {CompanyPresenter.name(company)} aligns with {sustainableDevelopmentGoals.length} United Nations Sustainable
          Development Goals (UNSDG)
        </div>
        <div className={classes.description}>
          The Sustainable Development Goals are the blueprint to achieve a better and more sustainable future for all.
          They address the global challenges we face, including those related to poverty, inequality, climate change,
          environmental degradation, peace and justice.
        </div>
      </div>
      <div className={classes.panelsWrapper}>
        <div className={classes.panelsContainer}>
          {PANELS.map(renderPanels)}
          <div className={classes.panel}>
            <img src={logo} alt="logo" />
          </div>
        </div>
      </div>
    </div>
  );
};

Panels.propTypes = {
  company: CompanyPresenter.shape().isRequired,
};

export default Panels;
