import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'ramda';

import CustomerLensPresenter from 'presenters/CustomerLensPresenter';
import PillarPresenter from 'presenters/PillarPresenter';
import LensPresenter from 'presenters/LensPresenter';
import CustomerLensFlagPresenter from 'presenters/CustomerLensFlagPresenter';
import UserPresenter from 'presenters/UserPresenter';
import AccountPresenter from 'presenters/AccountPresenter';

import AppRoutes from 'routes/AppRoutes';

import List from './components/List';
import KeyAreasItem from './components/KeyAreasItem';
import IssuesItem from './components/IssuesItem';
import CoreFocusItem from './components/CoreFocusItem';

import useStyles from './useStyles';

const Side = props => {
  const { currentUser, customerLens, onDuplicateCustomerLens, accounts, analysisId } = props;
  const classes = useStyles();
  const history = useHistory();
  if (isEmpty(customerLens)) {
    return null;
  }
  const customerLensId = CustomerLensPresenter.id(customerLens);

  const keyAreasData = CustomerLensPresenter.sortedCustomerPillarValues(customerLens).map(({ value, pillar, id }) => (
    <KeyAreasItem slug={PillarPresenter.slug(pillar)} name={PillarPresenter.name(pillar)} level={value} key={id} />
  ));

  const selectedLensesData = CustomerLensPresenter.selectedLenses(customerLens).map(selectedLens => (
    <CoreFocusItem lensName={LensPresenter.name(selectedLens.lens)} key={LensPresenter.id(selectedLens.lens)} />
  ));

  const issuesData = CustomerLensPresenter.customerLensFlags(customerLens).map(flag => (
    <IssuesItem flag={CustomerLensFlagPresenter.flag(flag)} key={CustomerLensFlagPresenter.id(flag)} />
  ));

  const handleRedirectToCustomerLens = async getPath => {
    const isReportActiveForAny = accounts.some(account => AccountPresenter.isReportActive(account, analysisId));
    if (UserPresenter.isAdvisor(currentUser) && isReportActiveForAny) {
      const {
        data: { customerLens: newCustomerLens },
      } = await onDuplicateCustomerLens(UserPresenter.householdId(currentUser), customerLensId);
      const id = CustomerLensPresenter.id(newCustomerLens);
      const path = getPath(id);
      history.push(path);

      return;
    }

    const path = getPath(customerLensId);
    history.push(path);
  };

  const handleEditFlags = () => {
    const getPath = id => AppRoutes.customerLensFlagsPath(id);
    handleRedirectToCustomerLens(getPath);
  };

  const handleEditKeyAreas = () => {
    const getPath = id => AppRoutes.customerLensValuesPath(id);
    handleRedirectToCustomerLens(getPath);
  };

  const handleEditCoreFocuses = () => {
    history.push(AppRoutes.customerLensLensesPath());
  };

  return (
    <div className={classes.root}>
      <div className={classes.info}>
        <h2 className={classes.title}>Your Lens</h2>
        <p className={classes.description}>This is the values-based lens we used to evaluate your investments.</p>
      </div>
      <div className={classes.list}>
        <List title="Your Core Focus" data={selectedLensesData} onEdit={handleEditCoreFocuses} />
      </div>
      <div className={classes.list}>
        <List title="Key Areas of Focus" data={keyAreasData} onEdit={handleEditKeyAreas} />
      </div>
      <div className={classes.list}>
        <List title="Securities to Flag" data={issuesData} onEdit={handleEditFlags} noDataText="No flags selected" />
      </div>
    </div>
  );
};

Side.propTypes = {
  currentUser: UserPresenter.shape().isRequired,
  customerLens: CustomerLensPresenter.shape(),
  accounts: PropTypes.arrayOf(AccountPresenter.shape()).isRequired,
  onDuplicateCustomerLens: PropTypes.func.isRequired,
  analysisId: PropTypes.number.isRequired,
};

export default Side;
