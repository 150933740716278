import React from 'react';
import { dissoc, omit } from 'ramda';
import {
  PasswordInput,
  TextField,
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  ReferenceInput,
  FormDataConsumer,
} from 'react-admin';
import { USER_TYPES, UserType } from './Constants';

const transform = data => {
  if (data.type === UserType.customer) {
    return dissoc('external_id', data);
  }
  if (data.type === UserType.advisor) {
    return dissoc('household_id', data);
  }
  return omit(['external_id', 'household_id'], data);
};

const UserEdit = props => (
  <Edit {...props} transform={transform}>
    <SimpleForm>
      <TextField source="id" />
      <TextInput source="email" type="email" />
      <TextInput source="first_name" />
      <TextInput source="last_name" />
      <PasswordInput source="password" />
      <SelectInput source="type" choices={USER_TYPES} />
      <ReferenceInput label="Partner" source="partner_id" reference="partners" allowEmpty>
        <SelectInput optionText="name" />
      </ReferenceInput>
      <FormDataConsumer>
        {({ formData }) => {
          if (formData.type === UserType.customer) {
            return (
              <ReferenceInput source="household_id" reference="households" allowEmpty>
                <SelectInput optionText="id" />
              </ReferenceInput>
            );
          }
          if (formData.type === UserType.advisor) {
            return <TextInput source="external_id" />;
          }
          return null;
        }}
      </FormDataConsumer>
    </SimpleForm>
  </Edit>
);

export default UserEdit;
