import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    heading: {
      marginTop: 83,
      marginBottom: 34,
    },
    container: {
      maxWidth: 840,
      margin: '0 auto',
      width: '100%',
      [theme.breakpoints.up('md')]: {
        maxWidth: 1180,
      },
    },
    main: {
      background: '#fff',
      border: '1px solid #e1e1e1',
      boxShadow: '-4px 4px 15px 0 rgba(0,0,0,0.10)',
      borderRadius: '0 70px 70px 0',
      width: 'auto',
    },
    titleItem: {
      display: 'flex',
    },
    titleItemSide: {
      width: 240,
      backgroundColor: theme.palette.background.bossanova,
      flexShrink: 0,
      [theme.breakpoints.up('md')]: {
        width: 300,
      },
    },
    titleItemMain: {
      flexGrow: 1,
      paddingLeft: 50,
      paddingRight: 60,
      paddingTop: 36,
    },
    titleItemTable: {
      width: '100%',
      tableLayout: 'fixed',
      borderSpacing: 0,
      '& thead td': {
        fontFamily: theme.typography.font.ttHovesPro,
        fontWeight: 700,
        fontSize: 13,
        letterSpacing: '0.01em',
      },
    },
    titleItemAction: {
      width: 55,
    },
    name: {
      width: 185,
      fontWeight: 'bold',
      [theme.breakpoints.up('md')]: {
        width: 280,
      },
    },
    buttonWrapper: {
      alignSelf: 'center',
      marginBottom: 25,
    },
    formActions: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      marginTop: 64,
      marginBottom: 102,
    },
    important: {
      width: 'auto',
      textAlign: 'center',
      fontWeight: 'bold',
      [theme.breakpoints.up('md')]: {
        width: 151,
      },
    },
    very_important: {
      width: 'auto',
      textAlign: 'center',
      fontWeight: 'bold',
      [theme.breakpoints.up('md')]: {
        width: 116,
      },
    },
    extremely_important: {
      width: 'auto',
      textAlign: 'center',
      fontWeight: 'bold',
      [theme.breakpoints.up('md')]: {
        width: 166,
      },
    },
    buttonRoot: {
      border: '1px solid #dfdfdf',
      display: 'flex',
      transform: 'translateY(-2px)',
      alignSelf: 'center',
      position: 'relative',
    },
    buttonlabel: {
      fontFamily: theme.typography.font.ttHovesPro,
      fontWeight: 700,
      color: '#a8b0b2',
      fontSize: 12,
      letterSpacing: 0,
      textTransform: 'uppercase',
      marginRight: 7,
    },
    refreshIconBlack: {
      width: 18,
      height: 16,
      marginRight: 7,
      marginLeft: 7,
      transform: 'translateY(-1px)',
    },
    refreshIconGray: {
      width: 16,
      height: 14,
      marginRight: 8,
      marginLeft: 8,
      transform: 'translateY(-1px)',
    },
    button: {
      marginLeft: 500,
      marginBottom: 10,
      borderColor: '#a8b0b2',
      color: '#a8b0b2',
      '&:last-child': {
        marginRight: 10,
      },
      alignSelf: 'center',
      position: 'relative',
      backgroundColor: '#e8f0f2',
    },
    active: {
      '&$button': {
        borderColor: '#101010',
        backgroundColor: '#fff',
      },
      '& $buttonlabel': {
        color: '#101010',
      },
    },
  }),
  { name: 'CustomerLensValuesSet' },
);

export default useStyles;
