export default {
  user_type: 'Client',
  user_id: '888',
  institution_id: '2',
  advisor_name: null,
  advisor_id: null,
  household_id: '999',
  clients: [
    {
      client_id: '888',
      client_name: 'Sample Portfolio Client',
      birth_year: '1975',
    },
  ],
  accounts: [
    {
      account_id: '888',
      account_owner: ['888'],
      account_name: 'Sample Portfolio Account',
      total_account_value: '200000.0',
      tickers: [
        {
          ticker: 'CGRNX',
          ticker_amount: '100000.0',
        },
        {
          ticker: 'NOTEXISTINGTICKER',
          ticker_amount: '100000.0',
        },
      ],
      holdings: [
        {
          holding_name: 'HoldingName1',
          holding_amount: '20000.0',
        },
      ],
      assetClasses: [
        {
          asset_class_id: '3',
          asset_class_percent: '75',
        },
        {
          asset_class_id: '6',
          asset_class_percent: '25',
        },
      ],
    },
  ],
};
