import Routes from 'routes/ApiRoutes';
import FetchHelpers from 'utils/FetchHelpers';

export default {
  create(params) {
    const path = Routes.apiV1UsersPath();

    return FetchHelpers.post(path, params);
  },
};
