import React from 'react';
import { TextField, Create, SimpleForm, TextInput, ReferenceInput, SelectInput } from 'react-admin';

const AssetClassCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextField source="id" />
      <TextInput source="external_id" />
      <TextInput source="name" />
      <ReferenceInput source="asset_class_category_id" reference="asset_class_categories" allowEmpty>
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);

export default AssetClassCreate;
