import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isBlank } from 'utils';

import Dropdown from 'components/Dropdown';

import useApp from 'hooks/useApp';
import useDebounce from 'hooks/useDebounce';

const CustomersDropdown = props => {
  const { customersList, selectedCustomer, onChangeSelectedCustomer } = props;

  const { updateCustomersList } = useApp();

  const [searchValue, setSearchValue] = useState('');
  const handleSearchValueChange = event => setSearchValue(event.target.value);
  const debouncedValue = useDebounce(searchValue, 500);

  useEffect(() => {
    const searchParams = isBlank(searchValue) ? {} : { first_name_or_last_name_or_household_name_cont: searchValue };
    updateCustomersList({ q: { ...searchParams } });
  }, [debouncedValue]);

  return (
    <Dropdown
      options={customersList}
      selected={selectedCustomer}
      onChange={onChangeSelectedCustomer}
      withSearch
      searchValue={searchValue}
      handleSearchValueChange={handleSearchValueChange}
    />
  );
};

CustomersDropdown.propTypes = {
  customersList: PropTypes.arrayOf(PropTypes.shape()),
  selectedCustomer: PropTypes.shape(),
  onChangeSelectedCustomer: PropTypes.func,
};

CustomersDropdown.defaultProps = {
  customersList: null,
  selectedCustomer: null,
  onChangeSelectedCustomer: null,
};

export default CustomersDropdown;
