import React from 'react';
import { TextField, Create, SimpleForm, TextInput, SelectInput, ReferenceInput } from 'react-admin';
import { COMPANIES_SCORED } from './Constants';

const CompanyCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextField source="id" />
      <TextInput source="name" />
      <TextInput source="cusip" />
      <TextInput source="ticker" />
      <ReferenceInput
        label="Gics Sub Industry"
        source="gics_sub_industry_id"
        reference="gics_sub_industries"
        perPage={200}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput source="current_market_cup" />
      <SelectInput source="scored" choices={COMPANIES_SCORED} />
    </SimpleForm>
  </Create>
);

export default CompanyCreate;
