import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './useStyles';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';

import refreshIconGray from 'assets/refreshIconGray.svg';
import refreshIconBlack from 'assets/refreshIconBlack.svg';

const ResetButton = props => {
  const { onClick, isActive } = props;
  const classes = useStyles();
  const styles = clsx(classes.button, { [classes.active]: isActive });

  return (
    <Button
      size="small"
      className={styles}
      classes={{ root: classes.buttonRoot, label: classes.buttonlabel }}
      onClick={onClick}
      disabled={!isActive}
    >
      {isActive ? (
        <img src={refreshIconBlack} className={classes.refreshIconBlack} alt="Icon energy use" />
      ) : (
        <img src={refreshIconGray} className={classes.refreshIconGray} alt="Icon energy use" />
      )}
      Reset Selections
    </Button>
  );
};

ResetButton.propTypes = {
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ResetButton;
