import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Button } from 'react-admin';
import { head } from 'ramda';
import Loader from 'react-loader';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

import { initialValues, validationSchema } from 'forms/ConfigurationForm';
import { useConfigurations } from 'admin/hooks/useConfigurations';
import InputFile from 'components/InputFile';

import useStyles from './useStyles';

const ConfigurationEdit = () => {
  const [isEdited, setIsEdited] = useState({
    firstYear: false,
    thirdYear: false,
    fifthYear: false,
    standardDeviation: false,
  });
  const classes = useStyles();
  const { loadConfigurations, updateConfigurations, configurationsStatuses, configurations } = useConfigurations();
  const handleFormSubmit = configurationValues => {
    updateConfigurations(configurationValues);
  };

  const { handleSubmit, values, errors, handleChange, setErrors, setFieldValue, touched } = useFormik({
    initialValues: initialValues(configurations),
    validationSchema,
    onSubmit: handleFormSubmit,
    validateOnChange: true,
    enableReinitialize: true,
  });

  const handleInputFileChange = field => event => {
    setFieldValue(field, head(event.currentTarget.files));
  };

  const handleInputFileClear = fieldName => () => {
    const urlFieldName = `${fieldName}Url`;

    setFieldValue(fieldName, null);
    setFieldValue(urlFieldName, '');
  };

  useEffect(() => {
    loadConfigurations();
  }, []);

  const handleInputChange = property => {
    return event => {
      setErrors({});
      setIsEdited(state => ({ ...state, [property]: true }));
      handleChange(event);
    };
  };

  const notApplicableValue = value => {
    return Number(value) === 0 || value.length === 0 ? 'N/A' : value;
  };

  return (
    <Paper elevation={3}>
      <div>
        <div className={classes.warning}>{configurationsStatuses.isLoading && <Loader />}</div>
        <form className={classes.form} onSubmit={handleSubmit} noValidate>
          <div className={classes.fields}>
            <TextField
              variant="filled"
              margin="normal"
              fullWidth
              id="first_year"
              label="First Year"
              name="firstYear"
              onChange={handleInputChange('firstYear')}
              value={isEdited.firstYear ? values.firstYear : notApplicableValue(values.firstYear)}
              disabled={configurationsStatuses.isLoading}
              error={touched.firstYear && !!errors.firstYear}
              helperText={touched.firstYear && errors.firstYear}
              className={classes.formWrapper}
            />
            <TextField
              variant="filled"
              margin="normal"
              fullWidth
              id="third_year"
              label="Third Year"
              name="thirdYear"
              onChange={handleInputChange('thirdYear')}
              value={isEdited.thirdYear ? values.thirdYear : notApplicableValue(values.thirdYear)}
              disabled={configurationsStatuses.isLoading}
              error={touched.thirdYear && !!errors.thirdYear}
              helperText={touched.thirdYear && errors.thirdYear}
              className={classes.formWrapper}
            />
            <TextField
              variant="filled"
              margin="normal"
              fullWidth
              id="fifth_year"
              label="Fifth Year"
              name="fifthYear"
              onChange={handleInputChange('fifthYear')}
              value={isEdited.fifthYear ? values.fifthYear : notApplicableValue(values.fifthYear)}
              disabled={configurationsStatuses.isLoading}
              error={touched.fifthYear && !!errors.fifthYear}
              helperText={touched.fifthYear && errors.fifthYear}
              className={classes.formWrapper}
            />
            <TextField
              variant="filled"
              margin="normal"
              fullWidth
              id="standardDeviation"
              label="Standard Deviation"
              name="standardDeviation"
              onChange={handleInputChange('standardDeviation')}
              value={
                isEdited.standardDeviation ? values.standardDeviation : notApplicableValue(values.standardDeviation)
              }
              disabled={configurationsStatuses.isLoading}
              error={touched.standardDeviation && !!errors.standardDeviation}
              helperText={touched.standardDeviation && errors.standardDeviation}
              className={classes.formWrapper}
            />
            <InputFile
              file={values.logo}
              label="Choose a logo image"
              name="logo"
              onChange={handleInputFileChange('logo')}
              onRemove={handleInputFileClear('logo')}
              helperText={touched.logo && errors.logo}
              error={touched.logo && !!errors.logo}
              imagePreviewUrl={values.logoUrl}
              accept="image/*"
            />
            <InputFile
              file={values.footerLogo}
              label="Choose a footer logo image"
              name="footerLogo"
              onChange={handleInputFileChange('footerLogo')}
              onRemove={handleInputFileClear('footerLogo')}
              helperText={touched.footerLogo && errors.footerLogo}
              error={touched.footerLogo && !!errors.footerLogo}
              imagePreviewUrl={values.footerLogoUrl}
              accept="image/*"
            />
          </div>
          <div className={classes.footerWrapper}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={configurationsStatuses.isLoading}
              label="Submit"
            />
          </div>
        </form>
      </div>
    </Paper>
  );
};

export default ConfigurationEdit;
