import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  theme => ({
    table: {
      width: '100%',
      tableLayout: 'fixed',
      maxWidth: 636,
      marginLeft: 'auto',
      marginRight: 'auto',
      borderCollapse: 'collapse',
      marginTop: 50,
      '& thead th': {
        fontFamily: theme.typography.font.ttHovesPro,
        fontWeight: '700',
        fontSize: 13,
        color: '#232020',
        letterSpacing: '0.01em',
        paddingTop: 0,
        paddingBottom: 15,
      },
      '& thead th:last-child': {
        textAlign: 'right',
      },
      '& tbody td': {
        fontFamily: theme.typography.font.ttHovesPro,
        fontWeight: '300',
        fontSize: 16,
        color: '#232020',
        borderBottom: '1px solid #d8d8d8',
        paddingBottom: 10,
        paddingTop: 7,
        verticalAlign: 'top',
        paddingLeft: 0,
        '@media print': {
          fontSize: 12,
          paddingTop: 3,
          letterSpacing: '0.01em',
          paddingBottom: 3,
        },
      },
    },
    nameHead: {
      width: 260,
      paddingLeft: 0,
    },
    flagsHead: {
      width: 170,
      padding: 0,
    },
    tickerHead: {
      width: 120,
      padding: 0,
    },
    flag: {
      paddingRight: 0,
    },
    valuesHead: {
      width: 110,
      padding: 0,
    },
    ticker: {
      paddingLeft: 0,
      border: 'none',
      backgroundColor: 'transparent',
    },
    link: {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  }),
  { name: 'Table' },
);

export default useStyles;
