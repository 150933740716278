import React from 'react';
import PropTypes from 'prop-types';
import { Filter, TextInput, List, Datagrid, TextField } from 'react-admin';
import LinkToUsers from './LinkToUsers';

const PartnerFilter = props => (
  <Filter {...props}>
    <TextInput label="ID" source="idEq" alwaysOn />
    <TextInput label="Name Or Slug" source="nameOrSlugCont" alwaysOn />
  </Filter>
);

const PartnerList = props => {
  const { data } = props;
  return (
    <List filters={<PartnerFilter />} {...props}>
      <Datagrid>
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="slug" />
        <LinkToUsers record={data} />
      </Datagrid>
    </List>
  );
};

PartnerList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
};

export default PartnerList;
