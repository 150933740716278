import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  switch: {
    '& .MuiIconButton-root': {
      position: 'absolute',
      padding: '9px',
    },
  },
  contentRow: {
    position: 'relative',
    '&::after': {
      content: '""',
      position: 'absolute',
      width: '4px',
      height: '100%',
      left: 0,
      top: 0,
    },
  },
  correct: {
    '&::after': {
      background: '#008000',
    },
  },
  conflict: {
    '&::after': {
      background: '#FF0000',
    },
  },
  merged: {
    '&::after': {
      background: '#FECA57',
    },
  },
  headRow: {
    '& th': {
      fontWeight: '600',
    },
  },
  headCell: {
    border: 'none',
  },
  exclude: {
    opacity: '0.5',
    backgroundColor: '#F5F5F5',
  },
  conflictText: {
    color: '#FF0000',
    borderBottom: '1px dashed',
  },
  mergedText: {
    color: '#FFA500',
    borderBottom: '1px dashed',
  },
  addedText: {
    color: '#008000',
    borderBottom: '1px dashed',
  },
  errorText: {
    color: '#FF0000',
  },
  actionButton: {
    width: '160px',
  },
  footer: {
    padding: '20px',
    background: '#F5F5F5',
  },
  tooltip: {
    backgroundColor: '#FFFFFF !important',
    padding: '16px !important',
    color: '#000000 !important',
    fontSize: '18px !important',
    lineHeight: '22px !important',
    boxShadow: `0px 1.25px 4px rgba(0, 0, 0, 0.25)`,
    borderRadius: '8px !important',
  },
}));
