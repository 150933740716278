import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  side: {
    flexBasis: 200,
    backgroundColor: '#232020',
    color: '#fff',
    padding: '10px',
    flexShrink: 0,
    [theme.breakpoints.up('md')]: {
      padding: 0,
      paddingTop: 147,
    },
  },
  panel: {
    width: '80%',
  },
}));

export default useStyles;
