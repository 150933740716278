import React, { useState, useEffect } from 'react';
import { isEmpty, update } from 'ramda';
import clsx from 'clsx';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';
import SettingsIcon from '@material-ui/icons/Settings';
import { Status, StatusForCreated } from 'enums/ImportRequest';
import { useNotify, Button } from 'react-admin';
import { PropTypes } from 'prop-types';
import Loader from 'react-loader';

import Sidebar from './components/Sidebar';
import useStyles from './useStyles';
import { isNil } from 'lodash';
import { IconButton } from '@material-ui/core';
import ImportRequestPresenter from 'presenters/ImportRequestPresenter';
import { useFundImportRequests } from 'admin/hooks/useFundImportRequest';
import { prepareData, prepareDataForImport } from 'forms/FundImportRequestForm';
import useTimeout from 'hooks/useTimeout';

const NOTIFY_DURATION = 5000;
const REQUEST_INTERVAL_TIME = 1000;
const ERROR_NOTIFICATION_TYPE = 'error';
const SUCCESS_NOTIFICATION_TYPE = 'success';
const SUCCESSFUL_MESSAGE = 'Successful import';

const ImportTable = props => {
  const { funds, onTableClose } = props;
  const classes = useStyles();
  const notify = useNotify();

  const [rows, setRows] = useState(prepareData(funds));

  const [selectedRow, setSelectedRow] = useState({});
  const handleCloseSidebar = () => setSelectedRow({});

  const [state, setState] = useState('create');

  const {
    finishFundImport,
    loadFundImportRequest,
    importRequestId,
    loadBaseFunds,
    baseFunds,
  } = useFundImportRequests();

  const handlePollImportRequest = async timerControl => {
    const { enableTimer, disableTimer } = timerControl;

    const importRequest = await loadFundImportRequest(importRequestId);
    disableTimer();

    if (ImportRequestPresenter.isValidated(importRequest)) {
      enableTimer();
      return;
    }

    const isImportRequestFinished = ImportRequestPresenter.isFinished(importRequest);
    const msgStatus = isImportRequestFinished ? SUCCESS_NOTIFICATION_TYPE : ERROR_NOTIFICATION_TYPE;
    const notificationMessage = isImportRequestFinished
      ? SUCCESSFUL_MESSAGE
      : ImportRequestPresenter.message(importRequest);

    if (isImportRequestFinished) {
      onTableClose();
    }
    notify(notificationMessage, { status: msgStatus, undoable: false, duration: NOTIFY_DURATION });
  };

  const { enableTimer, isTimerActive } = useTimeout(handlePollImportRequest, REQUEST_INTERVAL_TIME);

  useEffect(() => {
    loadBaseFunds();
  }, []);

  const handleToggleInclude = (checked, rowId) => {
    const row = rows.find(({ id }) => id === rowId);
    const rowIndex = rows.findIndex(({ id }) => id === rowId);
    setRows(update(rowIndex, { ...row, include: checked }, rows));
  };

  const handleMergeConflict = (rowId, newData) => {
    const rowIndex = rows.findIndex(({ id }) => id === rowId);
    setRows(update(rowIndex, newData, rows));
  };

  const isDisabledImport =
    isTimerActive ||
    rows.every(({ include }) => !include) ||
    rows.some(({ status, include }) => status === Status.conflict && include);

  const handleImport = async () => {
    await finishFundImport(importRequestId, prepareDataForImport(rows));
    enableTimer();
  };

  const renderImportedCells = row => {
    const {
      imported: { ticker, name },
      include,
      status,
    } = row;
    return (
      <>
        <TableCell>
          <span
            className={clsx({
              [classes.conflictText]: status === Status.conflict && include,
              [classes.mergedText]: status === Status.merged,
            })}
          >
            {ticker}
          </span>
        </TableCell>
        <TableCell>{name}</TableCell>
      </>
    );
  };

  const renderReflectionCells = row => {
    const { reflection, include, status } = row;
    if (isEmpty(reflection))
      return (
        <TableCell colSpan={2} className={clsx({ [classes.errorText]: include && status !== Status.merged })}>
          Conflict
        </TableCell>
      );
    const { ticker, name } = reflection;
    return (
      <>
        <TableCell>{ticker}</TableCell>
        <TableCell>{name}</TableCell>
      </>
    );
  };

  const renderNewStatusTickerCell = (tickerCreated, include) => {
    const { ticker, status, message } = tickerCreated;

    return (
      <Tooltip title={message} classes={{ tooltip: classes.tooltip }}>
        <span
          className={clsx({
            [classes.addedText]: !isNil(status) && include,
          })}
        >
          {ticker}
        </span>
      </Tooltip>
    );
  };

  const renderNewCells = row => {
    const { created, include, status: rowStatus } = row;
    if (isEmpty(created))
      return (
        <>
          <TableCell colSpan={2}>
            <Button
              label="Select Action"
              variant="contained"
              disabled={!include}
              className={classes.actionButton}
              onClick={() => {
                setSelectedRow(row);
                setState('create');
              }}
            />
          </TableCell>
          <TableCell />
        </>
      );
    const { ticker, name, status } = created;
    return (
      <>
        <TableCell>
          {!isNil(status) && include ? renderNewStatusTickerCell(created, include) : <span>{ticker}</span>}
        </TableCell>
        <TableCell>
          <span
            className={clsx({
              [classes.addedText]: status === StatusForCreated.matched && include,
            })}
          >
            {name}
          </span>
        </TableCell>
        <TableCell>
          {rowStatus === Status.merged && (
            <IconButton
              color="primary"
              onClick={() => {
                setSelectedRow(row);
                setState('edit');
              }}
              disabled={!include}
            >
              <SettingsIcon />
            </IconButton>
          )}
        </TableCell>
      </>
    );
  };

  return (
    <>
      {isTimerActive && <Loader />}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow className={classes.headRow}>
              <TableCell colSpan={2} className={classes.headCell}>
                IMPORTED FILE ITEMS
              </TableCell>
              <TableCell colSpan={2} className={classes.headCell}>
                REFLECTION ANALYTICS BASE ITEMS
              </TableCell>
              <TableCell colSpan={2} className={classes.headCell}>
                NEW FUND ITEMS
              </TableCell>
              <TableCell rowSpan={2} />
              <TableCell rowSpan={2}>INCLUDE</TableCell>
            </TableRow>
            <TableRow className={classes.headRow}>
              <TableCell>Ticker</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Ticker</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Ticker</TableCell>
              <TableCell>Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => {
              const { status, id, include } = row;
              return (
                <TableRow
                  key={id}
                  className={clsx(classes.contentRow, {
                    [classes.correct]: status === Status.correct,
                    [classes.conflict]: status === Status.conflict,
                    [classes.merged]: status === Status.merged,
                    [classes.exclude]: !include,
                  })}
                >
                  {renderImportedCells(row)}
                  {renderReflectionCells(row)}
                  {renderNewCells(row)}
                  <TableCell>
                    <Switch
                      checked={include}
                      color="primary"
                      className={classes.switch}
                      onChange={event => handleToggleInclude(event.target.checked, id)}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <div className={classes.footer}>
          <Button label="IMPORT" type="button" variant="contained" disabled={isDisabledImport} onClick={handleImport} />
        </div>
      </TableContainer>
      {!isEmpty(selectedRow) && (
        <Sidebar
          open={!isEmpty(selectedRow)}
          onClose={handleCloseSidebar}
          selectedRow={selectedRow}
          onMergeConflict={handleMergeConflict}
          state={state}
          baseFunds={baseFunds}
        />
      )}
    </>
  );
};

ImportTable.propTypes = {
  funds: PropTypes.instanceOf(Array).isRequired,
  onTableClose: PropTypes.func.isRequired,
};

export default ImportTable;
