import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '0 66px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    fontFamily: theme.typography.font.ttHovesPro,
    fontWeight: 300,
    fontSize: 40,
    color: '#232020',
    textAlign: 'center',
    lineHeight: '39px',
  },
  description: {
    fontFamily: theme.typography.font.ttHovesPro,
    fontWeight: 300,
    fontSize: 15,
    color: '#000000',
    textAlign: 'center',
    lineHeight: '22px',
    marginTop: 10,
  },
  icon: {
    marginTop: 33,
    marginBottom: 58,
  },
  actions: {
    display: 'flex',
    flexDirection: 'column',
  },
  buttonWrapper: {
    alignSelf: 'center',
    marginTop: 13,
    textTransform: 'uppercase',
  },
  buttonRoot: {
    fontFamily: theme.typography.font.ttHovesPro,
    fontSize: 12,
    letterSpacing: '0.01em',
  },
}));

export default useStyles;
