import Routes from 'routes/ApiRoutes';
import FetchHelpers from 'utils/FetchHelpers';

export default {
  index(params) {
    const path = Routes.apiV1AdvisorCustomerAccountsPath();

    return FetchHelpers.get(path, params);
  },
  update(accountId, params) {
    const path = Routes.apiV1AdvisorCustomerAccountPath(accountId);

    return FetchHelpers.put(path, params);
  },
};
