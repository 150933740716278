import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  theme => ({
    table: {
      width: '100%',
      tableLayout: 'fixed',
      borderCollapse: 'collapse',
      '& tbody tr:last-child td': {
        borderBottom: '3px solid #000',
      },
      '& tbody td:last-child': {
        borderRight: 'none',
      },
    },
    thead: {
      border: '1px solid #d8d8d8',
      '& td': {
        borderRight: '1px solid #d8d8d8',
      },
    },
    subheader: {
      '& td': {
        borderRight: '1px solid #d8d8d8',
        padding: '21px 19px 12px 20px',
      },
      '& td:last-child': {
        borderRight: 'none',
      },
    },
    subheaderItem: {
      display: 'flex',
      justifyContent: 'space-between',
      fontFamily: theme.typography.font.ttHovesPro,
      color: '#232020',
      lineHeight: '19px',
      fontWeight: 700,
    },
    subheaderItemValue: {
      textAlign: 'right',
    },
    pillar: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '18px 10px',

      [theme.breakpoints.up('md')]: {
        padding: '21px 18px',
      },
    },
    title: {
      display: 'flex',
      alignItems: 'center',
      fontFamily: theme.typography.font.ttHovesPro,
    },
    weightText: {
      fontFamily: theme.typography.font.ttHovesPro,
      fontWeight: 700,
      fontSize: 13,
      color: '#232020',
      lineHeight: '19px',
      textTransform: 'uppercase',
      width: '50%',
      textAlign: 'right',
      [theme.breakpoints.up('md')]: {
        width: 'auto',
      },
    },
    name: {
      fontSize: 15,
      color: '#232020',
      lineHeight: '20px',
      fontWeight: 700,
      marginLeft: 10,
      [theme.breakpoints.up('md')]: {
        fontSize: 19,
        marginLeft: 14,
      },
    },
    data: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      fontFamily: theme.typography.font.ttHovesPro,
      fontWeight: 300,
      fontSize: 13,
      letterSpacing: '0.01em',
      color: '#232020',
      lineHeight: '36px',
      paddingLeft: 18,
    },
    value: {
      width: 127,
      textAlign: 'right',
      paddingRight: 18,
      [theme.breakpoints.up('md')]: {
        paddingRight: 0,
        textAlign: 'left',
      },
    },
    category: {
      '& td': {
        borderBottom: '1px solid #d8d8d8',
        borderRight: '1px solid #d8d8d8',
      },
    },
  }),
  { name: 'Table' },
);

export default useStyles;
