import PropTypes from 'prop-types';

import Presenter from 'utils/PropTypesPresenter';

export default new Presenter(
  {
    id: PropTypes.number,
    weight: PropTypes.number,
    weightFullText: PropTypes.string,
  },
  {},
);
