import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  buttonRoot: {
    border: '1px solid #dfdfdf',
    display: 'flex',
    transform: 'translateY(-2px)',
    alignSelf: 'center',
    position: 'relative',
  },
  buttonlabel: {
    fontFamily: theme.typography.font.ttHovesPro,
    fontWeight: 700,
    color: '#a8b0b2',
    fontSize: 12,
    letterSpacing: 0,
    textTransform: 'uppercase',
    marginRight: 7,
  },
  refreshIconBlack: {
    width: 18,
    height: 16,
    marginRight: 7,
    marginLeft: 7,
    transform: 'translateY(-1px)',
  },
  refreshIconGray: {
    width: 16,
    height: 14,
    marginRight: 8,
    marginLeft: 8,
    transform: 'translateY(-1px)',
  },
  button: {
    margin: '0 auto 10px',
    borderColor: '#a8b0b2',
    color: '#a8b0b2',
    '&:last-child': {
      marginRight: 10,
    },
    alignSelf: 'center',
    position: 'relative',
    backgroundColor: '#e8f0f2',
    [theme.breakpoints.up('md')]: {
      margin: '0 auto 10px 500px',
    },
  },
  active: {
    '&$button': {
      borderColor: '#101010',
      backgroundColor: '#fff',
    },
    '& $buttonlabel': {
      color: '#101010',
    },
  },
}));

export default useStyles;
