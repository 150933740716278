import PropTypes from 'prop-types';
import * as React from 'react';
import { useRefresh, Mutation } from 'react-admin';
import Checkbox from '@material-ui/core/Checkbox';

const RecommendationsSwitch = ({ record }) => {
  const checked = record.recommendations_available;
  const refresh = useRefresh();
  const payload = { id: record.id, data: { recommendations_available: !checked } };
  const options = {
    onSuccess: () => {
      refresh();
    },
  };

  return (
    <Mutation type="update" resource="institutions" payload={payload} options={options}>
      {approve => <Checkbox checked={checked} onChange={approve} name="recommendations" />}
    </Mutation>
  );
};

RecommendationsSwitch.propTypes = {
  record: PropTypes.shape({
    recommendations_available: PropTypes.bool.isRequired,
    id: PropTypes.number.isRequired,
  }),
};

export default RecommendationsSwitch;
