export default {
  MuiTooltip: {
    arrow: {
      color: '#232020',
    },
    tooltip: {
      maxWidth: 300,
      backgroundColor: '#232020',
      fontFamily: 'TTHovesPro',
      fontWeight: 500,
      fontSize: 16,
      color: '#FFFFFF',
      textAlign: 'center',
      lineHeight: '18px',
      marginBottom: 20,
      paddingTop: 10,
      paddingBottom: 10,
    },
  },
};
