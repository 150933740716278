import Routes from 'routes/ApiRoutes';
import FetchHelpers from 'utils/FetchHelpers';

export default {
  refresh(fundId) {
    const path = Routes.refreshAdminApiV1FundFundPortfoliosPath(fundId);

    return FetchHelpers.post(path);
  },
};
