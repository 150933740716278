export default {
  MuiButton: {
    root: {
      fontFamily: 'TTHovesPro',
    },
    text: {
      fontFamily: 'TTHovesPro',
      textTransform: 'inherit',
      fontWeight: 500,
      color: '#1e1d1d',
      letterSpacing: 0,
      lineHeight: '20px',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    contained: {
      borderRadius: 35,
      fontWeight: 'bold',
      letterSpacing: 0,
      color: '#FFF',
      textTransform: 'uppercase',
      '&:disabled': {
        backgroundColor: '#6D2092A5',
        color: '#fff',
      },
    },
    outlined: {
      border: '2px solid #232020',
      borderRadius: 31,
      marginTop: 'auto',
      height: 36,
      color: '#232020',
      fontFamily: 'TTHovesPro',
      fontWeight: 'bold',
    },
    sizeSmall: {
      fontSize: 16,
      fontWeight: 700,
      letterSpacing: '0.02em',
    },
    sizeLarge: {
      lineHeight: '20px',
      fontSize: 18,
      letterSpacing: '-0.01em',
      padding: '25px 99px',
      fontFamily: 'TTHovesPro',
      fontWeight: 'bold',
    },
  },
};
