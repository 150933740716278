import Routes from 'routes/ApiRoutes';
import FetchHelpers from 'utils/FetchHelpers';

export default {
  create(params) {
    const path = Routes.apiV1CustomerCustomerLensesPath();
    return FetchHelpers.post(path, params);
  },
  update(customerLensId, params) {
    const path = Routes.apiV1CustomerCustomerLensPath(customerLensId);
    return FetchHelpers.put(path, params);
  },
  show(customerLensId, params) {
    const path = Routes.apiV1CustomerCustomerLensPath(customerLensId);
    return FetchHelpers.get(path, params);
  },
  reset(customerLensId) {
    const path = Routes.resetApiV1CustomerCustomerLensPath(customerLensId);
    return FetchHelpers.put(path);
  },
};
