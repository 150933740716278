import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, equals, isNil } from 'ramda';
import { useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import clsx from 'clsx';

import Divider from '@material-ui/core/Divider';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';

import { useDetailedFundInformation } from 'hooks';

import pdfIcon from 'assets/pdf.svg';

import AppRoutes from 'routes/AppRoutes';

import RemoveIcon from 'components/Icons/RemoveIcon';
import Ball from 'components/Ball';
import Link from 'components/Link';
import InsightsChart from 'components/InsightsChart';
import PillarsFilter from 'components/PillarsFilter';
import SecuritiesList from 'components/SecuritiesList';
import Tooltip from 'components/Tooltip';
import Drawer from 'components/Drawer';
import PortfolioAnalysisInsightsPDF from 'components/ReflectionReport/PortfolioAnalysisInsightsPDF';

import { PILLARS_FILTER_LIST } from 'presenters/PillarPresenter';
import FundScorePresenter from 'presenters/FundScorePresenter';
import CustomerFundsReportPresenter from 'presenters/CustomerFundsReportPresenter';

import { toFixedFloat } from 'utils/numberHelpers';
import { getInsightsChartTotalData, getInsightsChartPillarData } from 'utils/chartUtils';
import { objectToQueryString } from 'utils/urlUtils';
import { Weight } from 'enums/Weights';
import { PillarFilter as PillarFilterEnum } from 'enums/PillarFilters';

import FundsFilter from './components/FundsFilter';

import useStyles from './useStyles';

const INTERVAL_BEFORE_OPEN_PRINTING_FRAME = 500;

const PortfolioAnalysisInsights = props => {
  const {
    customerFundsReport,
    fundScores,
    onSetSelectedFundIds,
    selectedFundIds,
    isCustomerFundsReportLoading,
    selectedAccountIds,
    isMultipleAccountMode,
    maximumNumberOfSelectedFunds,
  } = props;

  const { loadFundTickers, loadMultipleAccountsFundTickers } = useDetailedFundInformation();

  const classes = useStyles();
  const componentRef = useRef();

  const routeParams = useParams();
  const customerLensId = Number(routeParams.id);

  const [selectedPillarFilter, setSelectedPillarFilter] = useState({});
  const [tooltipDescription, setTooltipDescription] = useState('');
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({
    top: 0,
    left: 0,
    marginLeft: 0,
  });
  const [isPdfContentVisible, setIsPdfContentVisible] = useState(false);
  const [isDrawerOpened, setIsDrawerOpened] = useState(false);

  const [hoverFundForDetailedPage, setHoverFundForDetailedPage] = useState(null);
  const [hoverAlignmentForDetailedPage, setHoverAlignmentForDetailedPage] = useState(Weight.notScored);
  const [selectedFundForDetailedPage, setSelectedFundForDetailedPage] = useState(null);
  const [selectedAlignmentForDetailedPage, setSelectedAlignmentForDetailedPage] = useState(Weight.notScored);

  const formattedSelectedFundIds = isNil(selectedFundIds) ? [] : selectedFundIds;

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: '@page { size: letter; margin: 0px }',
    documentTitle: 'ReflectionInsights.pdf',
    onBeforeGetContent: () => {
      return new Promise(resolve => {
        setIsPdfContentVisible(true);
        setTimeout(() => {
          resolve();
        }, INTERVAL_BEFORE_OPEN_PRINTING_FRAME);
      });
    },
    onAfterPrint: () => {
      setIsPdfContentVisible(false);
    },
  });

  const rangeFilterField = () => {
    if (isEmpty(selectedPillarFilter)) {
      return 'customerFundTickerAnalysisTotalScore';
    }

    const { name: pillarFilterName } = selectedPillarFilter;

    switch (pillarFilterName) {
      case PillarFilterEnum.environment: {
        return 'customerFundTickerAnalysisEnvironmentScore';
      }
      case PillarFilterEnum.social: {
        return 'customerFundTickerAnalysisSocialScore';
      }
      case PillarFilterEnum.government: {
        return 'customerFundTickerAnalysisGovernmentScore';
      }
      default: {
        return 'customerFundTickerAnalysisTotalScore';
      }
    }
  };

  const convertAlignmentToTotalWeightRange = alignment => {
    const filterField = rangeFilterField();
    switch (alignment) {
      case Weight.no: {
        return { [`${filterField}Lt`]: 2.25 };
      }
      case Weight.somewhat: {
        return { [`${filterField}Gteq`]: 2.25, [`${filterField}Lteq`]: 2.5 };
      }
      case Weight.aligned: {
        return { [`${filterField}Gt`]: 2.5, [`${filterField}Lteq`]: 2.9 };
      }
      case Weight.strongly: {
        return { [`${filterField}Gt`]: 2.9 };
      }
      default: {
        return { [`${filterField}Null`]: true };
      }
    }
  };

  useEffect(() => {
    if (!isNil(selectedFundForDetailedPage)) {
      const totalWeightRange = convertAlignmentToTotalWeightRange(selectedAlignmentForDetailedPage);
      const params = {
        fundPortfolioCustomerFundAnalysesIdIn: selectedFundForDetailedPage.id,
        customerFundTickerAnalysisCustomerFundAnalysisIdEq: selectedFundForDetailedPage.id,
        ...totalWeightRange,
      };
      if (isMultipleAccountMode) {
        loadMultipleAccountsFundTickers(customerLensId, selectedAccountIds, params);
      } else {
        loadFundTickers(customerLensId, params);
      }
    }
  }, [selectedFundForDetailedPage, selectedAlignmentForDetailedPage, selectedPillarFilter]);

  const pdfContentClassNames = clsx({
    [classes.hidden]: !isPdfContentVisible,
    [classes.notVisible]: true,
  });

  const search = objectToQueryString({ account_ids: selectedAccountIds });
  const companiesScoredLink = isMultipleAccountMode
    ? AppRoutes.multipleAccountsPortfolioAnalysisCompaniesScoredPath(customerLensId)
    : AppRoutes.portfolioAnalysisCompaniesScoredPath(customerLensId);

  const percentValueHeldLink = isMultipleAccountMode
    ? AppRoutes.multipleAccountsPortfolioAnalysisPathPercentValueHeldPath(customerLensId)
    : AppRoutes.portfolioAnalysisPathPercentValueHeldPath(customerLensId);

  const issuesDefinedLink = isMultipleAccountMode
    ? AppRoutes.multipleAccountsPortfolioAnalysisIssuesDefinedPath(customerLensId)
    : AppRoutes.portfolioAnalysisIssuesDefinedPath(customerLensId);

  const handleChangeSelectedFunds = fundScore => {
    const fundScoreId = FundScorePresenter.id(fundScore);
    if (equals([fundScoreId], formattedSelectedFundIds)) {
      return;
    }

    const hasFundId = formattedSelectedFundIds.some(id => id === fundScoreId);

    if (hasFundId) {
      const filteredFundIds = formattedSelectedFundIds.filter(id => id !== FundScorePresenter.id(fundScore));
      onSetSelectedFundIds(filteredFundIds);
      return;
    }

    onSetSelectedFundIds([...formattedSelectedFundIds, fundScoreId]);
  };

  const handleCustomBarMouseEnter = fund => payload => {
    const { width, barOptions, label, x, y } = payload;
    const marginLeftValue = width / 2;

    setTooltipPosition({
      top: y,
      left: x,
      marginLeft: marginLeftValue,
    });

    const fundName = FundScorePresenter.fundName(fund);
    const fundTicker = FundScorePresenter.fundTicker(fund);
    const barOptionsByFund = barOptions[fundName];
    const value = toFixedFloat(barOptionsByFund.value, 1);
    const description = `${value}% of ${fundTicker} is ${label}`;
    setHoverAlignmentForDetailedPage(payload.name);
    setHoverFundForDetailedPage(fund);

    setTooltipDescription(description);
    setIsTooltipVisible(true);
  };

  const handleTooltipMouseEnter = () => {
    setIsTooltipVisible(true);
  };

  const handleTooltipMouseLeave = () => {
    setIsTooltipVisible(false);
  };

  const handleCustomBarMouseLeave = () => {
    setIsTooltipVisible(false);
  };

  const selectedFunds = fundScores.filter(fundScore =>
    formattedSelectedFundIds.includes(FundScorePresenter.id(fundScore)),
  );

  const getChartData = () => {
    if (isEmpty(selectedPillarFilter)) {
      return getInsightsChartTotalData(selectedFunds);
    }

    return getInsightsChartPillarData(selectedFunds, selectedPillarFilter);
  };

  const chartData = getChartData();

  const handleUnselectPillarFilter = () => {
    setSelectedPillarFilter({});
  };

  const handleSelectPillarFilter = pillarFilter => {
    setSelectedPillarFilter(pillarFilter);
  };

  const handleViewDetailsClick = () => {
    setSelectedAlignmentForDetailedPage(hoverAlignmentForDetailedPage);
    setSelectedFundForDetailedPage(hoverFundForDetailedPage);
    setIsDrawerOpened(true);
  };

  const handleDrawerHide = () => {
    setIsDrawerOpened(false);
  };

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <h2 className={classes.title}>
          Alignment Insights
          <IconButton type="button" className={classes.PDFIcon} onClick={handlePrint} label="save to pdf" size="medium">
            <img src={pdfIcon} alt="Save to PDF" />
          </IconButton>
        </h2>
        <div className={classes.layout}>
          <div className={classes.side}>
            <div className={classes.balls}>
              <div className={classes.ball}>
                <Link to={{ pathname: companiesScoredLink, search }} variant="container">
                  <Ball
                    score={CustomerFundsReportPresenter.formattedScoredCompaniesCount(customerFundsReport)}
                    totalScore={CustomerFundsReportPresenter.formattedTotalCompaniesCount(customerFundsReport)}
                    description="securities scored"
                    isLoading={isCustomerFundsReportLoading}
                  />
                </Link>
              </div>
              <div className={classes.ball}>
                <Link to={{ pathname: percentValueHeldLink, search }} variant="container">
                  <Ball
                    value={CustomerFundsReportPresenter.scoredAmountPercents(customerFundsReport)}
                    description="% dollar value held by securities scored"
                    isLoading={isCustomerFundsReportLoading}
                  />
                </Link>
              </div>
              <div className={classes.ball}>
                <Link to={{ pathname: issuesDefinedLink, search }} variant="container">
                  <Ball
                    score={CustomerFundsReportPresenter.formattedCompaniesWithAttachedFlagsCount(customerFundsReport)}
                    totalScore={CustomerFundsReportPresenter.formattedScoredCompaniesCount(customerFundsReport)}
                    description="scored securities with flags"
                    isLoading={isCustomerFundsReportLoading}
                  />
                </Link>
              </div>
            </div>
          </div>
          <div className={classes.main}>
            <div className={classes.options}>
              <div className={classes.fundsFilter}>
                <FundsFilter
                  fundScores={fundScores}
                  selectedFunds={selectedFunds}
                  onChangeSelectedFunds={handleChangeSelectedFunds}
                  maximumNumberOfSelectedFunds={maximumNumberOfSelectedFunds}
                />
              </div>
              <div>
                <PillarsFilter
                  selectedPillarFilter={selectedPillarFilter}
                  pillarsFilterList={PILLARS_FILTER_LIST}
                  onSelectPillarFilter={handleSelectPillarFilter}
                  onUnselectPillarFilter={handleUnselectPillarFilter}
                />
              </div>
            </div>
            <Divider />
            <div className={classes.selectedfilters}>
              {!isEmpty(selectedPillarFilter) && (
                <>
                  <div className={classes.caption}>Filtered by:</div>
                  <div className={classes.items}>
                    <div className={classes.item}>
                      <Chip
                        label={selectedPillarFilter.name}
                        onDelete={handleUnselectPillarFilter}
                        classes={{
                          root: classes.chipRoot,
                          label: classes.chipLabel,
                        }}
                        deleteIcon={<RemoveIcon viewBox="0 0 12 12" className={classes.removeIcon} />}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className={classes.chart}>
              <InsightsChart
                chartData={chartData}
                insights={selectedFunds}
                onBarMouseEnter={handleCustomBarMouseEnter}
                onBarMouseLeave={handleCustomBarMouseLeave}
                verticalPoints={[100, 240, 380, 520, 660, 802]}
              />
              <Tooltip
                onTooltipMouseEnter={handleTooltipMouseEnter}
                onTooltipMouseLeave={handleTooltipMouseLeave}
                onViewDetailsClick={handleViewDetailsClick}
                tooltipDescription={tooltipDescription}
                tooltipPosition={tooltipPosition}
                isTooltipVisible={isTooltipVisible}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={pdfContentClassNames}>
        <div className={classes.pdfWrapper} ref={componentRef}>
          <PortfolioAnalysisInsightsPDF
            customerFundsReport={customerFundsReport}
            fundScores={fundScores}
            isCustomerFundsReportLoading={isCustomerFundsReportLoading}
            customerLensId={customerLensId}
            selectedFunds={selectedFunds}
            selectedPillarFilter={selectedPillarFilter}
            chartData={chartData}
            tooltipDescription={tooltipDescription}
            tooltipPosition={tooltipPosition}
          />
        </div>
      </div>
      <Drawer wide open={isDrawerOpened} onClose={handleDrawerHide}>
        <SecuritiesList
          portfolioAnalysisId={customerLensId}
          fund={hoverFundForDetailedPage}
          alignment={hoverAlignmentForDetailedPage}
        />
        ;
      </Drawer>
    </div>
  );
};

PortfolioAnalysisInsights.propTypes = {
  selectedFundIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  customerFundsReport: CustomerFundsReportPresenter.shape().isRequired,
  fundScores: PropTypes.arrayOf(FundScorePresenter.shape()).isRequired,
  onSetSelectedFundIds: PropTypes.func.isRequired,
  isCustomerFundsReportLoading: PropTypes.bool.isRequired,
  isMultipleAccountMode: PropTypes.bool,
  selectedAccountIds: PropTypes.arrayOf(PropTypes.number),
  maximumNumberOfSelectedFunds: PropTypes.number,
};

PortfolioAnalysisInsights.defaultProps = {
  isMultipleAccountMode: false,
  selectedAccountIds: [],
};

export default PortfolioAnalysisInsights;
