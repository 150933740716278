import React from 'react';
import { TextField, Edit, SimpleForm, TextInput, ReferenceInput, SelectInput } from 'react-admin';

const ExternalAssetClassEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextField source="id" />
      <TextInput source="external_id" />
      <TextInput multiline source="name" />
      <ReferenceInput source="asset_class_id" reference="asset_classes" allowEmpty>
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);

export default ExternalAssetClassEdit;
