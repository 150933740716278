import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(4),
    width: '100%',
    padding: theme.spacing(2),
  },
  content: {
    margin: theme.spacing(4, 0),
    padding: theme.spacing(2, 3, 3),
    background: theme.palette.grey[100],
  },
  addCustomerButton: {
    marginTop: theme.spacing(2),
  },
  addAccountButton: {
    marginTop: theme.spacing(2),
  },
}));

export default useStyles;
