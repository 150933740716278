import React from 'react';
import PropTypes from 'prop-types';

import TickerMetaPresenter from 'presenters/TickerMetaPresenter';
import CompanyPresenter from 'presenters/CompanyPresenter';

import MuiTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import clsx from 'clsx';

import useStyles from './useStyles';

const Table = props => {
  const { flaggedTickers, onCompanyClick, selectedFundIds, scoredCompanies } = props;
  const classes = useStyles();

  const scoredCompanyTickers = scoredCompanies.map(scoredCompany => CompanyPresenter.ticker(scoredCompany));

  const isLink = ticker => scoredCompanyTickers.includes(TickerMetaPresenter.companyTicker(ticker));
  const tickerClassNames = ticker => clsx(classes.ticker, { [classes.link]: isLink(ticker) });

  const renderFlags = ticker =>
    TickerMetaPresenter.flags(ticker).map(flag => <div key={TickerMetaPresenter.id(ticker)}>{flag}</div>);

  const handleViewCompanyReport = (company, fundIds) => {
    onCompanyClick(company, fundIds);
  };

  return (
    <MuiTable className={classes.table} aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell className={classes.tickerHead}>Ticker</TableCell>
          <TableCell className={classes.nameHead}>Name</TableCell>
          <TableCell align="left" className={classes.valuesHead}>
            Values Aligned?
          </TableCell>
          <TableCell align="right" className={classes.flagsHead}>
            Flags Identified
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {flaggedTickers.map(ticker => (
          <TableRow key={TickerMetaPresenter.id(ticker)} hover role="checkbox">
            <TableCell align="left" className={classes.tickerBody}>
              <button
                type="button"
                onClick={() => handleViewCompanyReport(ticker, selectedFundIds)}
                className={tickerClassNames(ticker)}
              >
                {TickerMetaPresenter.companyTicker(ticker)}
              </button>
            </TableCell>
            <TableCell align="left">{TickerMetaPresenter.companyName(ticker)}</TableCell>
            <TableCell align="left">{TickerMetaPresenter.weightText(ticker)}</TableCell>
            <TableCell align="right" className={classes.flag}>
              {renderFlags(ticker)}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </MuiTable>
  );
};

Table.propTypes = {
  flaggedTickers: PropTypes.arrayOf(TickerMetaPresenter.shape()).isRequired,
  onCompanyClick: PropTypes.func,
  selectedFundIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  scoredCompanies: PropTypes.arrayOf(CompanyPresenter.shape()).isRequired,
};

Table.defaultProps = {
  onCompanyClick: () => {},
};

export default Table;
