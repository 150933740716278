import React from 'react';
import PropTypes from 'prop-types';

import FundScorePresenter from 'presenters/FundScorePresenter';

const CustomBar = props => {
  const { x, y, width, height, fund, barOptions } = props;

  const { gradientStartColor, gradientStopColor } = barOptions[fund.fundName];
  const gradientId = `linearGradient-${fund.fundId}`;

  return (
    <svg>
      <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id={gradientId}>
          <stop stopColor={gradientStartColor} offset="0%" />
          <stop stopColor={gradientStopColor} offset="100%" />
        </linearGradient>
      </defs>
      <rect x={x} y={y} width={width} height={height} fill={`url(#${gradientId})`} rx="3" />
    </svg>
  );
};

CustomBar.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  fund: FundScorePresenter.shape(),
  barOptions: PropTypes.shape(),
};

export default CustomBar;
