import { useSelector } from 'react-redux';

import { useConfigurationsActions } from 'admin/sections/Configurations/containers/ConfigurationSlice';
import getFetchStatus from 'utils/getFetchStatus';

export const useConfigurations = () => {
  const { updateConfigurations, loadConfigurations } = useConfigurationsActions();
  const configurations = useSelector(state => state.ConfigurationSlice.configurations);
  const configurationsStatus = useSelector(state => state.ConfigurationSlice.status);
  const configurationsStatuses = getFetchStatus(configurationsStatus);

  return {
    updateConfigurations,
    loadConfigurations,
    configurations,
    configurationsStatuses,
  };
};
