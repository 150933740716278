import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const CheckCircleOutlineIcon = props => {
  return (
    <SvgIcon {...props}>
      <path
        d="M9.35433333,17.9506849 C8.075,17.9506849 6.78933333,17.6794521 5.567,17.1431507 C3.876,16.4034247 2.47,15.2321918 1.48833333,13.7527397 C0.0253333333,11.5458904 -0.354666667,8.81506849 0.430666667,6.26917808 C1.197,3.79109589 3.002,1.78767123 5.38966667,0.770547945 C7.91666667,-0.308219178 10.9883333,-0.17260274 13.4076667,1.12808219 C13.7623333,1.31917808 13.889,1.75068493 13.6926667,2.08972603 C13.4963333,2.43493151 13.053,2.55821918 12.7046667,2.36712329 C10.678,1.2760274 8.094,1.16506849 5.97233333,2.07123288 C3.97733333,2.92191781 2.46366667,4.60479452 1.824,6.68219178 C1.159,8.83356164 1.482,11.1267123 2.71066667,12.9883562 C4.39533333,15.5342466 7.65066667,16.9458904 10.6273333,16.4219178 C12.996,16.0089041 15.1113333,14.4369863 16.15,12.3164384 C16.321,11.9650685 16.758,11.8109589 17.119,11.9835616 C17.48,12.15 17.6383333,12.5753425 17.461,12.9267123 C16.2386667,15.4479452 13.718,17.3219178 10.8933333,17.8150685 C10.3866667,17.9075342 9.87366667,17.9506849 9.35433333,17.9506849 Z"
        id="Path"
      />
      <path
        d="M8.56266667,12.9513699 C8.379,12.9513699 8.19533333,12.8835616 8.056,12.7479452 L3.914,8.81506849 C3.629,8.54383562 3.62266667,8.09383562 3.90133333,7.81027397 C4.18,7.53287671 4.64233333,7.52671233 4.93366667,7.79794521 L8.56266667,11.25 L17.7206667,2.51506849 C18.0056667,2.24383562 18.468,2.24383562 18.753,2.52739726 C19.0316667,2.80479452 19.0316667,3.25479452 18.7403333,3.53219178 L9.07566667,12.7479452 C8.93633333,12.8835616 8.75266667,12.9513699 8.56266667,12.9513699 Z"
        id="Path"
      />
    </SvgIcon>
  );
};

export default CheckCircleOutlineIcon;
