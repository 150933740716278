import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  () => ({
    title: {
      fontWeight: 'bold',
      fontSize: 23,
      letterSpacing: '-0.01em',
      color: '#232020',
      lineHeight: '21px',
      display: 'flex',
      alignItems: 'center',
      marginBottom: 11,
      marginTop: 5,
    },
    header: {
      display: 'flex',
    },
    headline: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 48,
    },
    compare: {
      marginBottom: 32,
      '@media print': {
        marginBottom: 8,
      },
    },
    value: {
      fontSize: 66,
      fontWeight: 500,
      letterSpacing: '-0.01em',
      top: -3,
      position: 'relative',
      width: '100%',
    },
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      height: '100%',
      textAlign: 'center',
    },
    info: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 25,
      justifyContent: 'center',
    },
    PDFIcon: {
      marginLeft: 15,
      display: 'inline-block',
      color: '#000',
      padding: 0,
      '@media print': {
        visibility: 'hidden',
      },
    },
    cards: {
      display: 'flex',
      alignItems: 'center',
    },
    card: {
      marginRight: 10,
      '&:last-child': {
        marginRight: 0,
      },
    },
    icon: {
      width: 32,
      height: 32,
      color: '#999',
      marginBottom: 10,
    },
    calc: {
      color: '#0f5e75',
      fontWeight: 300,
      fontSize: 16,
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'center',
      marginLeft: 34,
      '& span': {
        marginLeft: 9,
      },
    },
    questionIcon: {
      width: 17,
      height: 17,
    },
    time: {
      fontWeight: 300,
      fontSize: 13,
      letterSpacing: '0.02em',
      color: '#6E6E6E',
      textAlign: 'left',
      lineHeight: '12px',
      marginRight: 5,
    },
    dropdown: {
      marginLeft: 'auto',
      marginRight: 12,
      width: 165,
    },
    alert: {
      position: 'absolute',
      top: 0,
      right: 0,
      borderTopRightRadius: 70,
    },
    refresh: {
      left: 'auto',
      border: 'none',
      cursor: 'pointer',
      backgroundColor: 'transparent',
      marginLeft: 4,
      '&:focus': {
        outline: 'none',
      },
    },
    question: {
      padding: 0,
      marginLeft: 7,
      marginTop: '-3px',
    },
    systemMessage: {
      marginBottom: 15,
      marginRight: -45,
      marginTop: -58,
      borderTopRightRadius: '68px 100%',
    },
    pdfWrapper: {
      padding: [20, 48, 26, 60],
    },
    hidden: {
      display: 'none',
    },
    notVisible: {
      visibility: 'hidden',
      marginLeft: -108,
    },
  }),
  { name: 'Main' },
);

export default useStyles;
