import React from 'react';
import { TextInput, Edit, SimpleForm, BooleanInput, NumberInput } from 'react-admin';

const InstitutionEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput label="External ID" source="external_id" />
      <TextInput label="Partner ID" source="partner.id" />
      <NumberInput source="first_year" />
      <NumberInput source="third_year" />
      <NumberInput source="fifth_year" />
      <NumberInput source="standard_deviation" />
      <BooleanInput source="recommendations_available" />
    </SimpleForm>
  </Edit>
);

export default InstitutionEdit;
