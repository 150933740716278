import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%',
  },
  autocompleteEndAdornment: {
    display: 'flex',
  },
  tickers: {
    display: 'flex',
    flexDirection: 'column',
  },
  tickersGroup: {
    marginTop: 15,
    verticalAlign: 'center',
    display: 'flex',
    '&:first-child': {
      marginTop: 5,
    },
  },
  tickerChipRoot: {
    height: 40,
    width: 'inherit',
    justifyContent: 'space-between',
    background: theme.palette.common.white,
  },
  tickerChipLabel: {
    width: '100%',
    textAlign: 'center',
  },
  chipContainer: {
    width: 350,
    marginRight: 16,
  },
  autocompleteOption: {
    textTransform: 'capitalize',
  },
  amountInput: {
    width: 150,
    marginRight: 16,
    '& .MuiInputBase-root': {
      background: theme.palette.common.white,
    },
  },
  tickersInput: {
    background: theme.palette.common.white,
  },
  assetClassInput: {
    width: 300,
    '& .MuiAutocomplete-inputRoot': {
      background: theme.palette.common.white,
    },
    '& .MuiAutocomplete-endAdornment': {
      display: 'flex',
    },
  },
}));

export default useStyles;
