import React from 'react';
import PropTypes from 'prop-types';
import Slug from 'components/Slug';
import Chip from '@material-ui/core/Chip';
import useStyles from './useStyles';

import ValuePresenter from 'presenters/ValuePresenter';

const KeyAreasItem = ({ slug, name, level }) => {
  const classes = useStyles();

  const importanceLevel = ValuePresenter.getLabelFor(level);

  return (
    <div className={classes.root}>
      <div className={classes.pillar}>
        <Slug slug={slug} size="small" />
        <div className={classes.name}>{name}</div>
      </div>
      <div className={classes.level}>
        <Chip
          label={importanceLevel}
          variant="outlined"
          classes={{ root: classes.chipRoot, label: classes.chipLabel }}
        />
      </div>
    </div>
  );
};

KeyAreasItem.propTypes = {
  slug: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  level: PropTypes.string.isRequired,
};

export default KeyAreasItem;
