import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { head, sum, pluck, includes, isEmpty, equals } from 'ramda';
import { useReactToPrint } from 'react-to-print';
import clsx from 'clsx';

import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';

import pdfIcon from 'assets/pdf.svg';

import { usePercentValueHeld } from 'hooks';
import Compare from 'components/Compare';
import Chart from './components/Chart';
import Loader from 'components/Loader';
import PercentValueHeldPDF from 'components/PercentValueHeldPDF';
import { getUrlParam } from 'utils/location';

import TickerMetaPresenter from 'presenters/TickerMetaPresenter';
import CustomerFundAnalysisPresenter from 'presenters/CustomerFundAnalysisPresenter';

import useStyles from './useStyles';

const INTERVAL_BEFORE_OPEN_PRINTING_FRAME = 500;

const PercentValueHeld = props => {
  const {
    customerFundAnalyses,
    onClickViewDetails,
    selectedFundIds,
    onSetSelectedFundIds,
    chartID,
    isMultipleAccountMode,
  } = props;

  const classes = useStyles();
  const routeParams = useParams();
  const componentRef = useRef();

  const [isPdfContentVisible, setIsPdfContentVisible] = useState(false);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: '@page { size: landscape; margin: 0px }',
    documentTitle: 'PercentValueHeld.pdf',
    onBeforeGetContent: () => {
      return new Promise(resolve => {
        setIsPdfContentVisible(true);
        setTimeout(() => {
          resolve();
        }, INTERVAL_BEFORE_OPEN_PRINTING_FRAME);
      });
    },
    onAfterPrint: () => {
      setIsPdfContentVisible(false);
    },
  });

  const analysisId = Number(routeParams.id);
  const accountIdsParams = getUrlParam('account_ids');

  const { loadFundTickers, loadMultipleAccountsFundTickers, fundTickers, fundTickersStatuses } = usePercentValueHeld();

  const selectedFunds = customerFundAnalyses.filter(fund =>
    includes(CustomerFundAnalysisPresenter.id(fund), selectedFundIds),
  );
  const totalCompaniesCountOfSelectedFunds = sum(pluck('totalCompaniesCount', selectedFunds));

  const handleLoadFundTickers = fundIds => {
    if (isMultipleAccountMode) {
      loadMultipleAccountsFundTickers(analysisId, accountIdsParams, fundIds);
    } else {
      loadFundTickers(analysisId, fundIds);
    }
  };

  useEffect(() => {
    if (isEmpty(selectedFundIds)) {
      const initialSelectedFund = head(customerFundAnalyses);
      const initialSelectedFundId = CustomerFundAnalysisPresenter.id(initialSelectedFund);
      onSetSelectedFundIds([initialSelectedFundId]);

      return;
    }
    handleLoadFundTickers(selectedFundIds);
  }, []);

  const handleSelectedFundsChange = fundId => {
    if (equals([fundId], selectedFundIds)) {
      return;
    }

    const isFundSelected = selectedFundIds.some(id => id === fundId);

    const newSelectedFundIds = isFundSelected
      ? selectedFundIds.filter(id => id !== fundId)
      : [...selectedFundIds, fundId];

    onSetSelectedFundIds(newSelectedFundIds);
    handleLoadFundTickers(newSelectedFundIds);
  };

  const chartData = fundTickers.map(fundTicker => {
    const scoredPercent = Number(TickerMetaPresenter.totalWeight(fundTicker));

    return { ...fundTicker, scoredPercent };
  });

  const fundTickersCount = fundTickers.length;

  const pdfContentClassNames = clsx({
    [classes.hidden]: !isPdfContentVisible,
    [classes.notVisible]: true,
  });

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        Top Scored Securities
        <IconButton type="button" className={classes.PDFIcon} onClick={handlePrint} label="save to pdf" size="medium">
          <img src={pdfIcon} alt="Save to PDF" />
        </IconButton>
        <div className={classes.remark}>(by dollar)</div>
      </div>
      <div className={classes.select}>
        <Compare list={customerFundAnalyses} selectedToCompare={selectedFundIds} onChange={handleSelectedFundsChange} />
      </div>
      <Divider className={classes.divider} />
      {fundTickersStatuses.isLoading ? (
        <div className={classes.warning}>
          <Loader caption="Loading..." />
        </div>
      ) : (
        <div className={classes.chart}>
          <div className={classes.label}>
            <div className={classes.caption}>Value of Fund</div>
            <div className={classes.chartDescription}>
              Showing {fundTickersCount} of {totalCompaniesCountOfSelectedFunds} securities
            </div>
          </div>
          <Chart data={chartData} selectedFunds={selectedFunds} onTooltipClick={onClickViewDetails} chartID={chartID} />
        </div>
      )}
      <div className={pdfContentClassNames}>
        <div className={classes.pdfWrapper} ref={componentRef}>
          <PercentValueHeldPDF
            customerFundAnalyses={customerFundAnalyses}
            selectedFundIds={selectedFundIds}
            selectedFunds={selectedFunds}
            loading={fundTickersStatuses.isLoading}
            fundTickersCount={fundTickersCount}
            totalCompaniesCountOfSelectedFunds={totalCompaniesCountOfSelectedFunds}
            chartData={chartData}
            chartID={chartID + 1}
          />
        </div>
      </div>
    </div>
  );
};

PercentValueHeld.propTypes = {
  customerFundAnalyses: PropTypes.arrayOf(CustomerFundAnalysisPresenter.shape()).isRequired,
  onClickViewDetails: PropTypes.func.isRequired,
  onSetSelectedFundIds: PropTypes.func.isRequired,
  selectedFundIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  chartID: PropTypes.string,
  isMultipleAccountMode: PropTypes.bool,
};

PercentValueHeld.defaultProps = {
  isMultipleAccountMode: false,
};

export default PercentValueHeld;
