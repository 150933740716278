import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  () => ({
    title: {
      fontWeight: 'bold',
      fontSize: 22,
      letterSpacing: '-0.01em',
      marginTop: 0,
      marginBottom: 4,
    },
    description: {
      fontSize: 17,
      fontWeight: 300,
      marginTop: 0,
      marginBottom: 0,
    },
    info: {
      marginBottom: 77,
    },
    list: {
      marginBottom: 64,
    },
  }),
  { name: 'Side' },
);

export default useStyles;
