import React from 'react';
import { Route } from 'react-router-dom';

import AdminRoutes from 'routes/AdminRoutes';

import { UserImportWithPermissions } from 'admin/sections/Users';
import { FundImport } from 'admin/sections/Funds';
import MoneyguideWithPermissions from 'admin/sections/MoneyguideWithPermissions';
import Dashboard from 'admin/sections/Dashboard';
import { ConfigurationEdit } from 'admin/sections/Configurations';
import Kpi from 'admin/sections/Kpi';

export default [
  <Route exact path={AdminRoutes.usersImportPath()} component={UserImportWithPermissions} />,
  <Route exact path={AdminRoutes.fundsImportPath()} component={FundImport} />,
  <Route exact path={AdminRoutes.moneyguide()} component={MoneyguideWithPermissions} />,
  <Route exact path={AdminRoutes.dashboard()} component={Dashboard} />,
  <Route exact path={AdminRoutes.configurations()} component={ConfigurationEdit} />,
  <Route exact path={AdminRoutes.kpi()} component={Kpi} />,
];
