import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './useStyles';
import clsx from 'clsx';

import E from './images/E.svg';
import S from './images/S.svg';
import G from './images/G.svg';

const icons = {
  E,
  S,
  G,
};

const Slug = ({ slug, size }) => {
  const classes = useStyles();
  const rootStyles = clsx(classes.root, classes[size]);

  return (
    <div className={rootStyles}>
      <img src={icons[slug]} alt="" />
    </div>
  );
};

Slug.propTypes = {
  slug: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['small', 'medium', 'large']).isRequired,
};

export default Slug;
