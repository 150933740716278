import * as yup from 'yup';
import { EMAIL, REQUIRED, EARLIER_THAN_ACTUAL_DATE, NUMBER, POSITIVE, DATE } from './errorsText';

export const validationFields = {
  firstName: yup.string(),
  lastName: yup.string(),
  birthDate: yup
    .date()
    .typeError(DATE)
    .max(new Date(), EARLIER_THAN_ACTUAL_DATE)
    .nullable(),
  email: yup.string().email(EMAIL),
  tickers: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string(),
        amount: yup
          .number()
          .typeError(NUMBER)
          .positive(POSITIVE)
          .nullable(),
      }),
    )
    .required(REQUIRED),
};

export const validationSchema = yup.object().shape({ ...validationFields });

export const initialValues = {
  firstName: '',
  lastName: '',
  birthDate: null,
  email: '',
  tickers: [],
};
