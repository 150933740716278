import { useSelector } from 'react-redux';

import { useManuallyEnteredPortfolioActions } from 'store/ManuallyEnteredPortfolioSlice';
import getFetchStatus from 'utils/getFetchStatus';

export default () => {
  const { loadFunds, loadCompanies, createUser } = useManuallyEnteredPortfolioActions();

  const fundsStatus = useSelector(state => state.ManuallyEnteredPortfolioSlice.funds.status);
  const fundsStatuses = getFetchStatus(fundsStatus);

  const companiesStatus = useSelector(state => state.ManuallyEnteredPortfolioSlice.companies.status);
  const companiesStatuses = getFetchStatus(companiesStatus);

  return {
    loadFunds,
    loadCompanies,
    createUser,
    fundsStatuses,
    companiesStatuses,
  };
};
