export const Weight = {
  notScored: 'notScored',
  no: 'no',
  somewhat: 'somewhat',
  aligned: 'aligned',
  strongly: 'strongly',
};

export const WeightLabel = {
  notScored: 'Not Scored',
  no: 'Not Aligned',
  somewhat: 'Somewhat Aligned',
  aligned: 'Aligned',
  strongly: 'Strongly Aligned',
};

export const SeeWeightLabel = {
  notScored: '',
  no: '(Article 6)',
  somewhat: '(Article 6)',
  aligned: '(Article 8)',
  strongly: '(Article 9)',
};
