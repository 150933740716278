import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import AppRoutes from 'routes/AppRoutes';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import { Formik } from 'formik';
import { initialValues, validationSchema } from 'forms/SignInForm';
import useStyles from './useStyles';

const SignIn = ({ onSignIn }) => {
  const classes = useStyles();

  const handleInputChange = (handleChange, setErrors) => event => {
    setErrors({});
    handleChange(event);
  };

  return (
    <main className={classes.main}>
      <Container component="div" maxWidth="xs" className={classes.container}>
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Formik onSubmit={onSignIn} initialValues={initialValues} validationSchema={validationSchema}>
            {({ handleSubmit, values, touched, errors, handleChange, setErrors, isSubmitting }) => (
              <form className={classes.form} onSubmit={handleSubmit} noValidate>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoFocus
                  onChange={handleInputChange(handleChange, setErrors)}
                  value={values.name}
                  disabled={isSubmitting}
                  error={touched.email && !!errors.email}
                  helperText={<span>{touched.email && errors.email}</span>}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  onChange={handleInputChange(handleChange, setErrors)}
                  value={values.password}
                  disabled={isSubmitting}
                  error={touched.password && !!errors.password}
                  helperText={<span>{touched.password && errors.password}</span>}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  disabled={isSubmitting}
                >
                  Sign In
                </Button>
              </form>
            )}
          </Formik>
        </div>
        <hr />
        <Button
          component={Link}
          to={AppRoutes.rootPath()}
          fullWidth
          variant="contained"
          color="secondary"
          className={classes.manualPortfolioLink}
        >
          Enter Portfolio Manually
        </Button>
      </Container>
    </main>
  );
};

SignIn.propTypes = {
  onSignIn: PropTypes.func.isRequired,
};

export default SignIn;
