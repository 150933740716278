import PropTypes from 'prop-types';

import Presenter from 'utils/PropTypesPresenter';
import { isBlank } from 'utils';

export default new Presenter(
  {
    id: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    customerPortfolioAnalysisId: PropTypes.number,
    externalId: PropTypes.number,
    fullName: PropTypes.string,
    household: PropTypes.arrayOf(PropTypes.shape()),
  },
  {
    displayHouseholdName(customer) {
      const householdName = this.household(customer).name;
      const householdId = this.household(customer).id;

      return isBlank(householdName) ? householdId : householdName;
    },
  },
);
