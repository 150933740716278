import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  balls: {
    paddingLeft: 16,
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  active: {
    left: 54,
    position: 'relative',
  },
  ball: {
    marginBottom: 20,
  },
  back: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: theme.typography.font.ttHovesPro,
    fontWeight: 700,
    fontSize: 12,
    color: '#fff',
    letterSpacing: 0,
    textAlign: 'center',
    width: '100%',
    justifyContent: 'flex-start',
    [theme.breakpoints.up('md')]: {
      marginBottom: 52,
      justifyContent: 'center',
    },
  },
  icon: {
    width: 13,
    height: 13,
    left: -1,
    top: 2,
    position: 'relative',
  },
}));

export default useStyles;
