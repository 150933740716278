import React from 'react';
import PropTypes from 'prop-types';

import Divider from '@material-ui/core/Divider';

import CustomerFundAnalysisPresenter from 'presenters/CustomerFundAnalysisPresenter';

import Compare from 'components/Compare';
import PdfTemplate from 'components/PdfTemplate';

import Chart from 'containers/PercentValueHeld/components/Chart';
import Loader from 'components/Loader';

import useStyles from './useStyles';

const BAR_WIDTH = 40;
const CANVAS_WIDTH = 1240;

const PercentValueHeldPDF = props => {
  const {
    customerFundAnalyses,
    selectedFundIds,
    selectedFunds,
    loading,
    fundTickersCount,
    totalCompaniesCountOfSelectedFunds,
    chartData,
    chartID,
  } = props;
  const classes = useStyles();

  return (
    <PdfTemplate>
      <div className={classes.title}>
        Top Scored Securities
        <div className={classes.remark}>(by dollar)</div>
      </div>
      <div className={classes.select}>
        <Compare list={customerFundAnalyses} selectedToCompare={selectedFundIds} />
      </div>
      <Divider className={classes.divider} />
      {loading ? (
        <div className={classes.warning}>
          <Loader caption="Loading..." />
        </div>
      ) : (
        <div className={classes.chart}>
          <div className={classes.label}>
            <div className={classes.caption}>Value of Fund</div>
            <div className={classes.chartDescription}>
              Showing {fundTickersCount} of {totalCompaniesCountOfSelectedFunds} securities
            </div>
          </div>
          <Chart
            data={chartData}
            selectedFunds={selectedFunds}
            chartID={chartID}
            barWidth={BAR_WIDTH}
            canvasWidth={CANVAS_WIDTH}
            isShowValues
          />
        </div>
      )}
    </PdfTemplate>
  );
};

PercentValueHeldPDF.propTypes = {
  customerFundAnalyses: PropTypes.arrayOf(CustomerFundAnalysisPresenter.shape()).isRequired,
  selectedFunds: PropTypes.arrayOf(CustomerFundAnalysisPresenter.shape()).isRequired,
  selectedFundIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  loading: PropTypes.bool.isRequired,
  totalCompaniesCountOfSelectedFunds: PropTypes.number.isRequired,
  fundTickersCount: PropTypes.number.isRequired,
  chartData: PropTypes.arrayOf(PropTypes.shape()),
  chartID: PropTypes.string,
};

export default PercentValueHeldPDF;
