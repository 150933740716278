import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const EditIcon = props => {
  return (
    <SvgIcon {...props}>
      <g stroke="currentColor" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
        <path d="M12.095 1.905a1.82 1.82 0 00-2.573.005l-.012.012-7.25 7.25-.885 3.453 3.453-.886 7.25-7.25a1.819 1.819 0 00.03-2.571l-.013-.013zM9.303 2.13l2.567 2.567M2.26 9.172l2.57 2.565" />
      </g>
    </SvgIcon>
  );
};

export default EditIcon;
