import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  theme => ({
    root: {
      paddingLeft: 30,
      '@media print': {
        display: 'flex',
      },
    },
    expansionTable: {
      tableLayout: 'fixed',
      width: '100%',
      borderCollapse: 'collapse',
      '& tr:last-child td': {
        borderBottom: '3px solid #000',
        paddingBottom: 20,
      },
      '& $tableValue': {
        paddingLeft: 18,
      },
      '& $action': {
        paddingLeft: 46,
      },
      '& $itemData $action': {
        paddingLeft: 60,
        position: 'relative',
        top: 2,
      },
    },
    alignedValue: {
      paddingLeft: 18,
    },
    body: {
      '& $itemData': {
        '& td': {
          borderBottom: '1px solid #d8d8d8',
          paddingBottom: 4,
          paddingTop: 2,
        },
      },
    },
    itemData: {
      fontWeight: 300,
      fontSize: 15,
      '& td:first-child': {
        paddingLeft: 21,
      },
    },
    title: {
      '& td': {
        fontWeight: 700,
        fontSize: 13,
        letterSpacing: '0.01em',
        paddingTop: 16,
        paddingBottom: 17,
        '@media print': {
          paddingTop: 6,
          paddingBottom: 6,
        },
      },
      '& td:first-child': {
        paddingLeft: 24,
      },
    },
    header: {
      borderBottom: '1px solid #d8d8d8',
      '& td': {
        paddingBottom: 18,
        '@media print': {
          paddingBottom: 10,
        },
      },
    },
    pillar: {
      display: 'flex',
      alignItems: 'center',
      fontSize: 18,
      fontWeight: 'bold',
    },
    pillarTitle: {
      marginLeft: 9,
      fontWeight: 700,
      fontSize: 19,
      '@media print': {
        fontSize: 13,
      },
    },
    name: {
      width: 330,
      verticalAlign: 'top',
      [theme.breakpoints.up('md')]: {
        width: 515,
      },
      '@media print': {
        width: 180,
      },
    },
    tableValue: {
      width: 146,
      verticalAlign: 'top',
    },
    action: {
      justifyContent: 'flex-end',
      display: 'flex',
      width: '100%',
      [theme.breakpoints.up('md')]: {
        justifyContent: 'center',
      },
    },
    expandIcon: {
      width: 84,
      marginRight: 0,
      top: '-6px',
      '@media print': {
        display: 'none',
      },
    },
    chipRoot: {
      height: 30,
      position: 'relative',
      top: 6,
      '@media print': {
        height: 20,
      },
    },
    summaryRoot: {
      borderBottom: '1px solid #e4e4e4',
      '@media print': {
        marginTop: 0,
      },
    },
    panel: {
      marginBottom: 24,
      '@media print': {
        '&.MuiAccordion-root': {
          marginBottom: 0,
          width: '33%',
        },
      },
    },
    actionWrapper: {
      '@media print': {
        display: 'none',
      },
    },
  }),
  { name: 'Pillar' },
);

export default useStyles;
