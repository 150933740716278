import PropTypes from 'prop-types';
import Presenter from 'utils/PropTypesPresenter';
import GroupedTickerAssetClassPresenter from 'presenters/GroupedTickerAssetClassPresenter';

export default new Presenter(
  {
    id: PropTypes.number,
    externalId: PropTypes.string,
    name: PropTypes.string,
    percent: PropTypes.number,
    totalAmount: PropTypes.number,
    totalMatchScore: PropTypes.number,
    assetClasses: PropTypes.arrayOf(GroupedTickerAssetClassPresenter.shape()),
  },
  {
    weightedAverageSummand(groupedTickerAssetClass) {
      return Math.round(this.totalMatchScore(groupedTickerAssetClass)) * this.percent(groupedTickerAssetClass);
    },
  },
);
