import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  theme => ({
    root: {
      padding: '0 60px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    title: {
      fontFamily: theme.typography.font.ttHovesPro,
      fontWeight: 'normal',
      fontSize: 40,
      textAlign: 'center',
      marginBottom: 9,
      marginTop: 12,
    },
    description: {
      fontFamily: theme.typography.font.ttHovesPro,
      fontWeight: 300,
      fontSize: 16,
      color: '#000',
      lineHeight: '22px',
      maxWidth: 536,
      marginTop: 20,
    },
    terms: {
      marginTop: 10,
    },
    termsList: {
      padding: 0,
      marginTop: 10,
      marginLeft: 20,
    },
    term: {
      listStyle: 'none',
      position: 'relative',
      marginBottom: 1,
      '&:before': {
        content: '"·"',
        fontSize: 30,
        position: 'absolute',
        top: '50%',
        left: 0,
        marginLeft: -26,
        transform: 'translateY(-50%)',
      },
    },
    buttonWrapper: {
      alignSelf: 'center',
      marginTop: 17,
      textTransform: 'uppercase',
    },
    buttonRoot: {
      fontSize: 12,
      letterSpacing: '0.01em',
      fontFamily: theme.typography.font.ttHovesPro,
    },
  }),
  { name: 'HowCalculated' },
);

export default useStyles;
