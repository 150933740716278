import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './useStyles';

const TabPanel = props => {
  const classes = useStyles();
  const { children, value, index } = props;

  if (index !== value) {
    return null;
  }

  return <div className={classes.root}>{children}</div>;
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default TabPanel;
