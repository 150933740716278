import { useSelector } from 'react-redux';

import { useFundImportRequestActions } from 'admin/sections/Funds/containers/FundImportRequestSlice';
import getFetchStatus from 'utils/getFetchStatus';

export const useFundImportRequests = () => {
  const {
    createFundImportRequest,
    loadFundImportRequest,
    finishFundImport,
    loadBaseFunds,
    fundsSearch,
  } = useFundImportRequestActions();
  const currentFundImportStatus = useSelector(state => state.FundImportRequestSlice.currentFundImport.status);
  const currentFundImportStatuses = getFetchStatus(currentFundImportStatus);
  const importRequestId = useSelector(state => state.FundImportRequestSlice.currentFundImport.id);
  const errors = useSelector(state => state.FundImportRequestSlice.currentFundImport.errors);
  const importRequestState = useSelector(state => state.FundImportRequestSlice.currentFundImport.state);
  const funds = useSelector(state => state.FundImportRequestSlice.currentFundImport.data);

  const baseFunds = useSelector(state => state.FundImportRequestSlice.baseFunds.data);
  const matchedFunds = useSelector(state => state.FundImportRequestSlice.matchedFunds.data);

  return {
    createFundImportRequest,
    loadFundImportRequest,
    finishFundImport,
    loadBaseFunds,
    fundsSearch,
    importRequestState,
    importRequestId,
    currentFundImportStatuses,
    errors,
    baseFunds,
    funds,
    matchedFunds,
  };
};
