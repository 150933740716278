import { useState } from 'react';

import { useApp } from 'hooks';
import { useReflectionReportActions } from 'store/ReflectionReportSlice';

import AccountPresenter from 'presenters/AccountPresenter';
import UserPresenter from 'presenters/UserPresenter';

export default initialParams => {
  const { currentSelectedCustomer, analysisId } = initialParams;

  const { updateAccount } = useReflectionReportActions();

  const {
    currentUser,
    selectedAccount,
    customersList,
    selectedCustomer,
    accounts,
    updateCustomersList,
    changeAccounts,
    changeSelectedCustomer,
    loadAdvisorCustomerAccounts,
  } = useApp();

  const [isCurrentReportActive, setIsCurrentReportActive] = useState(
    UserPresenter.isCustomer(currentUser) || AccountPresenter.isReportActive(selectedAccount, analysisId),
  );

  const handleReportSave = (onSave = () => {}) => async () => {
    const customerIds = customersList.map(({ id }) => id);
    const cachedCustomerAccounts = AccountPresenter.asList(accounts, currentSelectedCustomer);

    await Promise.all(
      cachedCustomerAccounts.map(account =>
        updateAccount(account.id, {
          account: {
            customerPortfolioAnalysisId: AccountPresenter.cachedCustomerPortfolioAnalysisId(account),
          },
        }),
      ),
    );
    const accs = await loadAdvisorCustomerAccounts({ q: { userAccountsUserIdIn: customerIds } });
    changeAccounts(accs);
    setIsCurrentReportActive(true);
    const newCustomersList = await updateCustomersList(currentUser);
    const newSelectedCustomer = newCustomersList.find(({ id }) => id === selectedCustomer.id);
    changeSelectedCustomer(newSelectedCustomer);
    onSave();
  };

  return {
    isCurrentReportActive,
    handleReportSave,
  };
};
