import Routes from 'routes/ApiRoutes';
import FetchHelpers from 'utils/FetchHelpers';

export default {
  show(params) {
    const path = Routes.apiV1ConfigurationPath();

    return FetchHelpers.get(path, params);
  },
};
