import React, { useState } from 'react';
import PropTypes from 'prop-types';

import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';

import FlagPresenter from 'presenters/FlagPresenter';

import SquareCheckbox from 'components/SquareCheckbox';

import question from 'assets/question.svg';

import useStyles from './useStyles';

const Card = props => {
  const { flag, checked, onChangeSelection } = props;
  const classes = useStyles();

  const flagId = FlagPresenter.id(flag);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleChangeSelection = () => {
    onChangeSelection(flag);
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <SquareCheckbox checked={checked} onChange={handleChangeSelection} name={`flags[${flagId}]`} value={flagId} />
        <IconButton
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
          type="button"
          className={classes.question}
        >
          <img src={question} width="18" height="18" alt="question icon" />
        </IconButton>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          disableRestoreFocus
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          className={classes.popover}
          classes={{ paper: classes.helperPopup }}
        >
          <p>{FlagPresenter.extendedDescription(flag)}</p>
        </Popover>
      </div>
      <div className={classes.content}>{FlagPresenter.name(flag)}</div>
    </div>
  );
};

Card.propTypes = {
  flag: FlagPresenter.shape().isRequired,
  checked: PropTypes.bool.isRequired,
  onChangeSelection: PropTypes.func.isRequired,
};

export default Card;
