import { useSelector } from 'react-redux';

import { usePercentValueHeldActions } from 'store/PercentValueHeldSlice';
import getFetchStatus from 'utils/getFetchStatus';

export default () => {
  const { loadFundTickers, loadMultipleAccountsFundTickers } = usePercentValueHeldActions();

  const fundTickers = useSelector(state => state.PercentValueHeldSlice.fundTickers.data);

  const fundTickersStatus = useSelector(state => state.PercentValueHeldSlice.fundTickers.status);
  const fundTickersStatuses = getFetchStatus(fundTickersStatus);

  return {
    loadFundTickers,
    loadMultipleAccountsFundTickers,
    fundTickers,
    fundTickersStatuses,
  };
};
