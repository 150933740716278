import PropTypes from 'prop-types';

import CompanyPresenter from 'presenters/CompanyPresenter';

import Presenter from 'utils/PropTypesPresenter';

export default new Presenter(
  {
    id: PropTypes.number,
    amount: PropTypes.string,
    scoreableWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    scoredAmount: PropTypes.string,
    scoredPercent: PropTypes.string,
    company: CompanyPresenter.shape(),
  },
  {
    companyFlags(fundTicker) {
      const company = this.company(fundTicker);

      return CompanyPresenter.flags(company);
    },
    sortedByScoredPercent(fundTickers) {
      const getScoredPercent = ticker => Number(this.scoredPercent(ticker));
      const sortedFundTickers = [...fundTickers].sort(
        (ticker1, ticker2) => getScoredPercent(ticker2) - getScoredPercent(ticker1),
      );

      return sortedFundTickers;
    },
  },
);
