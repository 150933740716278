export const countPages = (itemsCount, perPage = 12) => {
  const count = Math.floor(itemsCount / perPage);
  return itemsCount % perPage >= 1 ? count + 1 : count;
};

export const getElementDetails = element => {
  const {
    x: {
      baseVal: { value: x },
    },
    y: {
      baseVal: { value: y },
    },
    width: {
      baseVal: { value: width },
    },
    height: {
      baseVal: { value: height },
    },
  } = element;

  return { x, y, width, height };
};
