import React from 'react';
import PropTypes from 'prop-types';

import useApp from 'hooks/useApp';

import useStyles from './useStyles';

const PdfTemplate = props => {
  const { children } = props;
  const classes = useStyles();

  const { appLogoUrl, appFooterLogoUrl } = useApp();

  return (
    <div>
      <div className={classes.header}>
        <div className={classes.headerLogo}>
          <img src={appLogoUrl} alt="Reflection Analytics Logo" className={classes.headerImage} />
        </div>
      </div>
      <div className={classes.footer}>
        <div className={classes.logo}>
          <img src={appFooterLogoUrl} alt="Reflection Analytics Logo" className={classes.footerImage} />
        </div>
      </div>
      <table className={classes.table}>
        <thead className={classes.pdfThead}>
          <tr>
            <td>
              <div className={classes.headerSpace} />
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{children}</td>
          </tr>
        </tbody>
        <tfoot className={classes.pdfTfoot}>
          <tr>
            <td>
              <div className={classes.footerSpace} />
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

PdfTemplate.propTypes = {
  children: PropTypes.node,
};

export default PdfTemplate;
