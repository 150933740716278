import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './useStyles';

const CustomizedLabel = props => {
  const { x, y, width, height, text, value, isShowValue } = props;
  const classes = useStyles();
  return (
    <>
      <text
        x={x + width / 2}
        y={y + height}
        textAnchor="middle"
        dominantBaseline="Hanging"
        transform="translate(0, 17)"
        className={classes.text}
      >
        {text}
      </text>
      {isShowValue && (
        <text
          x={x + width / 2}
          y={y + height + 10}
          textAnchor="middle"
          dominantBaseline="Hanging"
          transform="translate(0, 17)"
          className={classes.smallText}
        >
          ({parseFloat(value).toFixed(2)})
        </text>
      )}
    </>
  );
};

CustomizedLabel.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  text: PropTypes.string,
  value: PropTypes.number,
  isShowValue: PropTypes.bool,
};

export default CustomizedLabel;
