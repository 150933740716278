import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Dropdown from './components/Dropdown';

import CustomerPillarValuePresenter from 'presenters/CustomerPillarValuePresenter';
import CustomerCategoryValuePresenter from 'presenters/CustomerCategoryValuePresenter';
import PillarPresenter from 'presenters/PillarPresenter';
import { IMPORTANCE_LEVELS } from 'presenters/ValuePresenter';

import CustomerCategoryValue from './components/CustomerCategoryValue';
import Slug from 'components/Slug';
import { CSSTransition } from 'react-transition-group';

import useStyles from './useStyles';

const Pillar = props => {
  const {
    customerPillarValue,
    customerCategoryValues,
    onChangeCustomerCategoryValue,
    onChangeCustomerPillarValue,
  } = props;

  const [animation, setAnimation] = useState(false);
  const [prevCustomerPillarValue, setPrevCustomerPillarValue] = useState(customerPillarValue);
  const importantsLevel = CustomerPillarValuePresenter.importantsLevel(customerPillarValue);

  useEffect(() => {
    if (customerPillarValue !== prevCustomerPillarValue) {
      setPrevCustomerPillarValue(customerPillarValue);
      setAnimation(true);
    }
  }, [importantsLevel]);

  const classes = useStyles();
  const pillar = CustomerPillarValuePresenter.pillar(customerPillarValue);
  const filteredCustomerCategoryValues = customerCategoryValues.filter(
    i => i.category.pillarId === PillarPresenter.id(pillar),
  );

  const handleChangePillarValue = value => {
    onChangeCustomerPillarValue(customerPillarValue, value);
  };

  return (
    <div className={classes.item}>
      <div className={classes.side}>
        <CSSTransition
          in={animation}
          timeout={{ appear: 0, enter: 200, exit: 0 }}
          onEntered={() => setAnimation(false)}
          classNames={{
            enterActive: classes.alertEnterActive,
          }}
        >
          <div className={classes.icon}>
            <Slug slug={PillarPresenter.slug(pillar)} size="large" />
          </div>
        </CSSTransition>
        <h4 className={classes.title}>{PillarPresenter.name(pillar)}</h4>
        <div className={classes.menu}>
          <Dropdown currentValue={importantsLevel} options={IMPORTANCE_LEVELS} onChange={handleChangePillarValue} />
        </div>
      </div>
      <div className={classes.mainContent}>
        <table className={classes.table}>
          <tbody>
            {filteredCustomerCategoryValues.map(customerCategoryValue => (
              <CustomerCategoryValue
                key={CustomerCategoryValuePresenter.id(customerCategoryValue)}
                customerCategoryValue={customerCategoryValue}
                onChangeCustomerCategoryValue={onChangeCustomerCategoryValue}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

Pillar.propTypes = {
  customerPillarValue: CustomerPillarValuePresenter.shape().isRequired,
  customerCategoryValues: PropTypes.arrayOf(CustomerCategoryValuePresenter.shape()).isRequired,
  onChangeCustomerCategoryValue: PropTypes.func.isRequired,
  onChangeCustomerPillarValue: PropTypes.func.isRequired,
};

export default Pillar;
