import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { SaveButton } from 'react-admin';
import { FormControlLabel, RadioGroup, Radio } from '@material-ui/core';
import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';

import useStyles from './useStyles';
import fixtures from './fixtures';

const DEFAULT_FIXTURE = Object.keys(fixtures)[0];

const SampleJsonInput = props => {
  const { onSubmit, apiErrors, iframeUrl } = props;
  const [selectedFixture, setSelectedFixture] = useState(DEFAULT_FIXTURE);
  const [errors, setErrors] = useState(false);
  const initJson = fixtures[selectedFixture];
  const [json, setJson] = useState(initJson);

  const classes = useStyles();

  const handleFixtureChange = e => {
    const fixture = e.target.value;
    setSelectedFixture(fixture);

    setJson(fixtures[fixture]);
  };

  const handleFormChange = form => {
    setErrors(form.error);
    setJson(form.jsObject);
  };

  const handleFormSubmit = () => {
    onSubmit(json);
  };

  const renderApiErrors = () => {
    const keys = Object.keys(apiErrors);
    return keys.map(key => (
      <div className={classes.apiErrors} key={key}>
        <span>{`${key} `}</span>
        <span>{apiErrors[key].join('; ')}</span>
      </div>
    ));
  };

  return (
    <>
      <RadioGroup aria-label="gender" name="gender1" value={selectedFixture} onChange={handleFixtureChange}>
        <FormControlLabel value="advisorWithMultipleClients" control={<Radio />} label="Advisor with John and Jane" />
        <FormControlLabel value="advisorWithOneClient" control={<Radio />} label="Advisor with Jake" />
        <FormControlLabel value="multipleClients" control={<Radio />} label="John and Jane Clients" />
        <FormControlLabel value="oneClient" control={<Radio />} label="Jake Client" />
        <FormControlLabel value="blackrockClient" control={<Radio />} label="BlackRock Client" />
        <FormControlLabel value="samplePortfolioClient" control={<Radio />} label="Sample Portfolio Client" />
        <FormControlLabel value="qaTest" control={<Radio />} label="QA Test" />
        <FormControlLabel value="assetClass" control={<Radio />} label="Asset Class" />
      </RadioGroup>
      <JSONInput placeholder={initJson} locale={locale} onChange={handleFormChange} />
      <div>{renderApiErrors()}</div>
      <span>{iframeUrl}</span>
      <div className={classes.actions}>
        <SaveButton handleSubmitWithRedirect={handleFormSubmit} disabled={!!errors} />
      </div>
    </>
  );
};

SampleJsonInput.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  apiErrors: PropTypes.shape(),
  iframeUrl: PropTypes.string,
};

SampleJsonInput.defaultProps = {
  apiErrors: {},
  iframeUrl: null,
};

export default SampleJsonInput;
