import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  container: {
    width: '401px',
    padding: '16px',
  },
  closeButton: {
    position: 'absolute !important',
    top: '4px',
    right: '4px',
  },
  head: {
    marginBottom: '32px',
  },
  title: {
    fontSize: '20px',
    lineHeight: '24px',
    margin: 0,
    marginBottom: '4px',
  },
  name: {
    fontWeight: '600',
    margin: 0,
  },
  text: {
    fontSize: '16px',
    lineHeight: '20px',
  },
  radioGroup: {
    margin: '24px 0',
  },
  button: {
    width: '76px',
  },
  result: {
    marginBottom: '32px',
  },
}));
