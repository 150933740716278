import React from 'react';
import { Filter, TextInput, List, Datagrid, TextField, ReferenceField } from 'react-admin';

import ExportPresenter from 'presenters/ExportPresenter';

import ExportsRepository from 'repositories/admin/household/ExportsRepository';

import HouseholdActions from './HouseholdActions';

const HouseholdFilter = props => (
  <Filter {...props}>
    <TextInput label="ID" source="idEq" alwaysOn />
  </Filter>
);

const HouseholdList = props => {
  const handleDownloadExport = async () => {
    await ExportsRepository.download();
  };

  const handlePollExport = async onSuccess => {
    const householdsExport = await ExportsRepository.show();

    if (ExportPresenter.notAttached(householdsExport)) {
      setTimeout(() => handlePollExport(onSuccess), 500);
    } else if (ExportPresenter.isAttached(householdsExport)) {
      onSuccess();
    }
  };

  const handleExport = async () => {
    await ExportsRepository.create();
    handlePollExport(handleDownloadExport);
  };

  return (
    <List
      filters={<HouseholdFilter />}
      actions={<HouseholdActions handleExportButtonClick={handleExport} />}
      {...props}
    >
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="external_id" />
        <ReferenceField source="institution_id" reference="institutions">
          <TextField source="id" />
        </ReferenceField>
      </Datagrid>
    </List>
  );
};

export default HouseholdList;
