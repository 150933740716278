import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  tableRow: {
    '& > td': {
      padding: [16, 6],
      fontSize: 12,
    },
  },
  activeRow: {
    background: '#FAFAFA',
  },
  checkbox: {
    width: 19,
    textAlign: 'left',
    padding: 0,
    position: 'relative',
    top: 1,
    left: 3,
  },
  centerCell: {
    textAlign: 'center',
  },
}));

export default useStyles;
