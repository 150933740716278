import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { head, tail, isNil, isEmpty } from 'ramda';
import Slider from 'react-slick';

import { isElementExist } from 'utils/arrayUtils';

import useStyles from './useStyles';
import clsx from 'clsx';
import Tooltip from '@material-ui/core/Tooltip';

import ReflectionReportPresenter from 'presenters/ReflectionReportPresenter';
import { REPORT_OVERVIEW } from 'presenters/ReflectionReportPresenter';
import FetchStatusPresenter from 'presenters/FetchStatusPresenter';

const Compare = ({ list, onChange, mixedReportStatus, selectedReportsFundIds }) => {
  const classes = useStyles();

  const isSelectedReport = report => {
    const { ticker: reportTicker } = report;

    if (isEmpty(selectedReportsFundIds)) {
      return reportTicker === REPORT_OVERVIEW.label;
    }

    if (isNil(selectedReportsFundIds)) {
      return false;
    }

    return isElementExist(report.id, selectedReportsFundIds);
  };

  const overviewOption = head(list);
  const fundList = tail(list);

  const renderButton = report => {
    const isSelected = isSelectedReport(report);
    const styles = clsx(classes.button, { [classes.active]: isSelected });
    return (
      <Tooltip
        title={ReflectionReportPresenter.description(report)}
        key={report.id}
        placement="top"
        TransitionProps={{ timeout: 500 }}
        arrow
      >
        <Button
          key={report.id}
          size="small"
          onClick={() => onChange(report)}
          className={styles}
          disabled={FetchStatusPresenter.isLoading(mixedReportStatus)}
          classes={{ root: classes.buttonRoot, label: classes.buttonLabel }}
        >
          {report.ticker}
        </Button>
      </Tooltip>
    );
  };
  return (
    <div className={classes.root}>
      <div className={classes.controls}>
        <Slider className={classes.slider} slidesToShow={10} slidesToScroll={10} infinite={false} dots speed={500}>
          {renderButton(overviewOption)}
          {fundList.map(renderButton)}
        </Slider>
      </div>
    </div>
  );
};

Compare.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onChange: PropTypes.func.isRequired,
  mixedReportStatus: PropTypes.string.isRequired,
  selectedReportsFundIds: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default Compare;
