import React from 'react';
import PropTypes from 'prop-types';

import { useDetailedFundInformation } from 'hooks';

import FundScorePresenter from 'presenters/FundScorePresenter';
import Loader from 'components/Loader';

import { WeightLabel } from 'enums/Weights';

import Table from './components/Table';
import useStyles from './useStyles';

const SecuritiesList = props => {
  const { fundTickers, fundTickersStatuses } = useDetailedFundInformation();
  const { fund, alignment } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.main}>
        <div className={classes.titleWrapper}>
          <div className={classes.title}>{fund.fundName}</div>
        </div>
        <div className={classes.subTitle}>Alignment: {WeightLabel[alignment]}</div>
        {fundTickersStatuses.isLoading ? <Loader /> : <Table fundTickers={fundTickers} />}
      </div>
    </div>
  );
};

SecuritiesList.propTypes = {
  fund: FundScorePresenter.shape(),
  alignment: PropTypes.string,
};

export default SecuritiesList;
