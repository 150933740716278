import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  text: {
    fontFamily: theme.typography.font.ttHovesPro,
    fontSize: 12,
    fill: '#232020',
    fontWeight: 700,
  },
}));

export default useStyles;
