import React from 'react';
import { TextField, Create, SimpleForm, TextInput, ReferenceInput, SelectInput } from 'react-admin';

const ExternalAssetClassCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextField source="id" />
      <TextInput source="external_id" />
      <TextInput source="name" />
      <ReferenceInput source="asset_class_id" reference="asset_classes" allowEmpty>
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);

export default ExternalAssetClassCreate;
