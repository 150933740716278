/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import GroupedTickerAssetClassesRepository from 'repositories/customerPortfolioAnalysesBatches/GroupedTickerAssetClassesRepository';
import GroupedAssetClassesCategoriesRepository from 'repositories/customerPortfolioAnalysesBatches/GroupedAssetClassesCategoriesRepository';
import RecommendationsRepository from 'repositories/customer/RecommendationsRepository';
import RecommendedFundsRepository from 'repositories/customer/RecommendedFundsRepository';

import { FETCH_STATUSES } from 'presenters/FetchStatusPresenter';

const initialState = {
  groupedTickerAssetClasses: {
    status: FETCH_STATUSES.idle,
    error: null,
    data: [],
  },
  groupedAssetClassesCategories: {
    status: FETCH_STATUSES.idle,
    error: null,
    data: [],
  },
  recommendation: {
    status: FETCH_STATUSES.idle,
    error: null,
    data: {},
  },
  recommendedFunds: {
    status: FETCH_STATUSES.idle,
    error: null,
    data: [],
  },
};

const recommendationSlice = createSlice({
  name: 'recommendation',
  initialState,
  reducers: {
    loadGroupedTickerAssetClassesStart(state) {
      state.groupedTickerAssetClasses.status = FETCH_STATUSES.loading;
    },
    loadGroupedTickerAssetClassesSuccess(state, { payload }) {
      state.groupedTickerAssetClasses.status = FETCH_STATUSES.success;
      state.groupedTickerAssetClasses.data = payload.groupedTickerAssetClasses;
    },
    loadGroupedTickerAssetClassesFail(state) {
      state.groupedTickerAssetClasses.status = FETCH_STATUSES.failure;
    },
    loadGroupedAssetClassesCategoriesStart(state) {
      state.groupedAssetClassesCategories.status = FETCH_STATUSES.loading;
    },
    loadGroupedAssetClassesCategoriesSuccess(state, { payload }) {
      state.groupedAssetClassesCategories.status = FETCH_STATUSES.success;
      state.groupedAssetClassesCategories.data = payload.groupedAssetClassCategories;
    },
    loadGroupedAssetClassesCategoriesFail(state) {
      state.groupedAssetClassesCategories.status = FETCH_STATUSES.failure;
    },
    loadRecommendationStart(state) {
      state.recommendation.status = FETCH_STATUSES.loading;
    },
    loadRecommendationSuccess(state, { payload }) {
      state.recommendation.status = FETCH_STATUSES.success;
      state.recommendation.data = payload;
    },
    loadRecommendationFail(state) {
      state.recommendation.status = FETCH_STATUSES.failure;
    },
    loadRecommendedFundsStart(state) {
      state.recommendedFunds.status = FETCH_STATUSES.loading;
    },
    loadRecommendedFundsSuccess(state, { payload }) {
      state.recommendedFunds.status = FETCH_STATUSES.success;
      state.recommendedFunds.data = payload.recommendedFunds;
    },
    loadRecommendedFundsFail(state) {
      state.recommendedFunds.status = FETCH_STATUSES.failure;
    },
  },
});

export const {
  loadGroupedTickerAssetClassesStart,
  loadGroupedTickerAssetClassesSuccess,
  loadGroupedTickerAssetClassesFail,
  loadGroupedAssetClassesCategoriesStart,
  loadGroupedAssetClassesCategoriesSuccess,
  loadGroupedAssetClassesCategoriesFail,
  loadRecommendationStart,
  loadRecommendationSuccess,
  loadRecommendationFail,
  loadRecommendedFundsStart,
  loadRecommendedFundsSuccess,
  loadRecommendedFundsFail,
} = recommendationSlice.actions;

export default recommendationSlice.reducer;

export const useRecommendationActions = () => {
  const dispatch = useDispatch();

  const loadRecommendation = async recommendationId => {
    dispatch(loadRecommendationStart());
    try {
      const {
        data: { recommendation },
      } = await RecommendationsRepository.show(recommendationId);

      dispatch(loadRecommendationSuccess(recommendation));
      return recommendation;
    } catch (error) {
      dispatch(loadRecommendedFundsFail());
      return error;
    }
  };

  const loadGroupedTickerAssetClasses = async (batchId, accountIds) => {
    dispatch(loadGroupedTickerAssetClassesStart());
    try {
      const { data } = await GroupedTickerAssetClassesRepository.index(batchId, {
        q: {
          customer_portfolio_customer_portfolio_analyses_id_in: accountIds,
        },
      });
      dispatch(loadGroupedTickerAssetClassesSuccess(data));
    } catch (error) {
      dispatch(loadGroupedTickerAssetClassesFail());
    }
  };

  const loadGroupedAssetClassesCategories = async (batchId, accountIds) => {
    dispatch(loadGroupedAssetClassesCategoriesStart());
    try {
      const { data } = await GroupedAssetClassesCategoriesRepository.index(batchId, {
        q: {
          customer_portfolio_customer_portfolio_analyses_id_in: accountIds,
        },
      });
      dispatch(loadGroupedAssetClassesCategoriesSuccess(data));
    } catch (error) {
      dispatch(loadGroupedAssetClassesCategoriesFail());
    }
  };

  const loadRecommendedFunds = async (
    recommendationId,
    kind,
    sortString = 'portfolio_reflection_match_fund_total_analysis_average_weight DESC',
  ) => {
    dispatch(loadRecommendedFundsStart());
    try {
      const { data } = await RecommendedFundsRepository.index({
        q: {
          recommendation_id_eq: recommendationId,
          s: sortString,
          kind_eq: kind,
        },
      });
      dispatch(loadRecommendedFundsSuccess(data));
    } catch (error) {
      dispatch(loadRecommendedFundsFail());
    }
  };

  return {
    loadGroupedTickerAssetClasses,
    loadGroupedAssetClassesCategories,
    loadRecommendation,
    loadRecommendedFunds,
  };
};
