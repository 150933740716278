import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  theme => ({
    root: {
      width: 180,
      height: 180,
      boxShadow: '-6px 2px 10px 2px rgba(0,0,0,0.10)',
      borderRadius: 5,
      padding: '12px 15px',
      display: 'flex',
      flexDirection: 'column',
      cursor: 'pointer',
      [theme.breakpoints.up('md')]: {
        width: 190,
        height: 180,
        padding: '18px 18px 12px',
      },
    },
    icon: {
      width: 14,
      height: 12,
    },
    loader: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
  }),
  { name: 'Card' },
);

export default useStyles;
