import PropTypes from 'prop-types';

import Presenter from 'utils/PropTypesPresenter';

export const MG_SLUG = 'moneyguide';
export const LPL_SLUG = 'lpl';

export default new Presenter(
  {
    id: PropTypes.number,
    name: PropTypes.string,
    slug: PropTypes.string,
  },
  {},
);
