import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './useStyles';

const CustomizedTick = props => {
  const {
    x,
    y,
    payload: { value },
  } = props;

  const classes = useStyles();
  return (
    <g>
      <text x={x} y={y} textAnchor="middle" fill="#666" transform="translate(0, -22)" className={classes.text}>
        {value}
      </text>
    </g>
  );
};

CustomizedTick.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  payload: PropTypes.shape(),
};

export default CustomizedTick;
