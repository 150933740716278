import PropTypes from 'prop-types';

import Presenter from 'utils/PropTypesPresenter';

export default new Presenter(
  {
    firstYear: PropTypes.number,
    thirdYear: PropTypes.number,
    fifthYear: PropTypes.number,
    standardDeviation: PropTypes.number,
    logoUrl: PropTypes.string,
    footerLogoUrl: PropTypes.string,
  },
  {},
);
