import Routes from 'routes/ApiRoutes';
import FetchHelpers from 'utils/FetchHelpers';

export default {
  download() {
    const path = Routes.downloadAdminApiV1GicsSubIndustryExportsPath();

    return FetchHelpers.download(path);
  },
};
