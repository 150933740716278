import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { useDebouncedCallback } from 'use-debounce';
import { isEmpty, is, pluck, isNil, lensPath, view, head } from 'ramda';
import { matchSorter } from 'match-sorter';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';
import InputAdornment from '@material-ui/core/InputAdornment';

import InputFile from 'components/InputFile';
import { valuesShape } from 'containers/AdvisorForm/propTypes';
import { useManuallyEnteredPortfolioActions } from 'store/ManuallyEnteredPortfolioSlice';
import { useImportPortfolioActions } from 'store/ImportPortfolioSlice';
import { prepareImportedTickers } from 'forms/AdvisorForm';
import { COMPANY_TYPE } from 'enums/Ticker';
import { FETCH_STATUSES } from 'presenters/FetchStatusPresenter';
import useNotification from 'hooks/useNotification';
import { IMPORT_HAS_BEEN_SUCCESSFUL } from 'enums/NotificationMessages';

import { isBlank } from 'utils';
import { uniqArray } from 'utils/arrayUtils';

import useStyles from './useStyles';

const Tickers = props => {
  const { values: allValues, errors: allErrors, accountIndex, setFieldValue, setErrors, disabled } = props;
  const { loadCompanies, loadFunds } = useManuallyEnteredPortfolioActions();
  const { importTickers } = useImportPortfolioActions();
  const [tickers, setTickers] = useState([]);
  const [inputTicker, setInputTicker] = useState('');
  const classes = useStyles();
  const assetClasses = useSelector(state => state.ManuallyEnteredPortfolioSlice.assetClasses.data);
  const assetClassesLoadStatus = useSelector(state => state.ManuallyEnteredPortfolioSlice.assetClasses.status);
  const isLoadingAssetClasses = assetClassesLoadStatus === FETCH_STATUSES.loading;
  const { showSuccessNotification, showErrorNotification } = useNotification;

  const tickersLens = lensPath(['household', 'accounts', accountIndex]);

  const errors = view(tickersLens, allErrors);
  const values = view(tickersLens, allValues);

  const handleLoadTickers = async value => {
    const { companies } = await loadCompanies(value);
    const { funds } = await loadFunds(value);

    const companiesTickers = companies.map(({ ticker, name, id }) => ({
      ticker,
      name,
      id,
      type: COMPANY_TYPE.company,
    }));
    const fundsTickers = funds.map(({ ticker, name, id }) => ({
      ticker,
      name,
      id,
      type: COMPANY_TYPE.fund,
    }));
    setTickers([...companiesTickers, ...fundsTickers]);
  };

  useEffect(() => {
    handleLoadTickers();
  }, []);

  const [debouncedHandleLoadTickers] = useDebouncedCallback(value => {
    handleLoadTickers(value);
  }, 500);

  useEffect(() => {
    debouncedHandleLoadTickers(inputTicker);
  }, [inputTicker]);

  const normalizeTicker = ticker => ticker?.toUpperCase();

  const handleTickerInput = () => (event, value) => {
    setErrors({});

    const isTickerAdded = pluck('name', values.tickers).includes(value);
    const shouldNotChangeValue = isNil(event) || (isTickerAdded && isEmpty(inputTicker));
    if (shouldNotChangeValue) {
      return;
    }

    setInputTicker(value);
  };

  const newFormTicker = ({ ticker, type, id, name }) => {
    const normalizedTicker = normalizeTicker(ticker);

    const newTicker = {
      name: normalizedTicker,
      amount: '',
      id,
      type,
      entityName: name,
      assetClass: null,
    };

    if (type === COMPANY_TYPE.company) {
      return { ...newTicker, companyId: id };
    }
    if (type === COMPANY_TYPE.fund) {
      return { ...newTicker, fundId: id };
    }
    return newTicker;
  };

  const isTickerInForm = (tickersFormState, tickerValue) => {
    const { ticker: tickerName, id: tickerId, type: tickerType } = tickerValue;
    const normalizedTicker = normalizeTicker(tickerName);
    const isTickerCreated = tickersFormState.some(
      ({ name, id, type }) => normalizedTicker === name && type === tickerType && id === tickerId,
    );
    return isTickerCreated || isBlank(tickerName);
  };

  const handleTickerCreate = tickersFormState => (_, value) => {
    setErrors({});

    const isTickerCreated = isTickerInForm(tickersFormState, value);
    if (isTickerCreated) {
      return;
    }

    const newTicker = newFormTicker(value);
    const newTickersFormState = [...tickersFormState, newTicker];
    setFieldValue(`household.accounts[${accountIndex}].tickers`, newTickersFormState);
    setInputTicker('');
  };

  const handleAutocompleteKeyDown = event => {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  };

  const handleTickerDelete = (tickersFormState, tickerIndex) => () => {
    setErrors({});

    const newTickersFormState = tickersFormState.filter((_, index) => index !== tickerIndex);
    setFieldValue(`household.accounts[${accountIndex}].tickers`, newTickersFormState);
  };

  const handleTickerAmountChange = (tickersFormState, tickerIndex) => event => {
    setErrors({});

    const newTickersFormState = tickersFormState.map((ticker, index) => {
      if (index === tickerIndex) {
        return { ...ticker, amount: event.target.value };
      }

      return ticker;
    });
    setFieldValue(`household.accounts[${accountIndex}].tickers`, newTickersFormState);
  };

  const handleAssetClassChange = (tickersFormState, tickerIndex) => (_, value) => {
    setErrors({});

    const newTickersFormState = tickersFormState.map((ticker, index) => {
      if (index === tickerIndex) {
        return { ...ticker, assetClass: value };
      }

      return ticker;
    });
    setFieldValue(`household.accounts[${accountIndex}].tickers`, newTickersFormState);
  };

  const handleInputFileChange = tickersFormState => async event => {
    const { target } = event;
    const file = head(target.files);
    target.value = null;

    if (isNil(file)) {
      return;
    }

    try {
      const data = await importTickers({ file });

      showSuccessNotification(IMPORT_HAS_BEEN_SUCCESSFUL);

      const importedTickers = prepareImportedTickers(data, assetClasses);
      const newTickersFormState = uniqArray(['name', 'id', 'type'], [...tickersFormState, ...importedTickers]);

      setFieldValue(`household.accounts[${accountIndex}].tickers`, newTickersFormState);
    } catch (error) {
      showErrorNotification(error.file.join(', '));
    }
  };

  const getOptionsList = () => {
    if (isEmpty(inputTicker)) {
      return tickers;
    }

    const normalizedTicker = normalizeTicker(inputTicker);
    const isTickerPresented = tickers.some(({ ticker }) => normalizeTicker(ticker) === normalizedTicker);
    return isTickerPresented
      ? tickers
      : [
          { ticker: normalizedTicker },
          ...tickers.filter(({ ticker }) => normalizeTicker(ticker) !== normalizeTicker(inputTicker)),
        ];
  };

  const shouldRenderTickersErrors = () => Boolean(errors?.tickers) && is(String, errors?.tickers);
  const getTickersHelperText = () => (shouldRenderTickersErrors(errors) ? errors.tickers : '');
  const filterOptions = (options, { inputValue }) => matchSorter(options, inputValue, { keys: ['ticker', 'name'] });
  const tickerTitle = ({ name, entityName }) => {
    return isNil(entityName) ? `${name}` : `${name}: ${entityName}`;
  };

  return (
    <>
      <Field
        name="ticker"
        id="ticker"
        required
        autoHighlight
        disableClearable
        component={Autocomplete}
        classes={{ endAdornment: classes.autocompleteEndAdornment }}
        ChipProps={{ classes: { label: classes.chip } }}
        options={getOptionsList()}
        getOptionLabel={({ ticker }) => ticker}
        filterOptions={filterOptions}
        getOptionSelected={() => true}
        disabled={disabled}
        onChange={handleTickerCreate(values.tickers)}
        onInputChange={handleTickerInput()}
        inputValue={inputTicker}
        renderOption={({ ticker, name }) => {
          if (isBlank(name)) {
            return <span className={classes.autocompleteOption}>{ticker}</span>;
          }

          return <span className={classes.autocompleteOption}>{`${ticker}: ${name?.toLowerCase()}`}</span>;
        }}
        renderInput={params => (
          <TextField
            {...params}
            className={classes.tickersInput}
            label="Tickers"
            required
            variant="outlined"
            margin="normal"
            error={shouldRenderTickersErrors()}
            helperText={getTickersHelperText()}
            onKeyDown={handleAutocompleteKeyDown}
          />
        )}
      />
      <div className={classes.tickers}>
        {values.tickers.map((ticker, index) => (
          <div className={classes.tickersGroup} key={`${ticker.name}${ticker.type}${ticker.id}`}>
            <div className={classes.chipContainer}>
              <Tooltip title={tickerTitle(ticker)}>
                <Chip
                  label={tickerTitle(ticker)}
                  variant="outlined"
                  onDelete={handleTickerDelete(values.tickers, index)}
                  classes={{
                    root: classes.tickerChipRoot,
                    label: classes.tickerChipLabel,
                  }}
                />
              </Tooltip>
            </div>
            <TextField
              variant="outlined"
              margin="none"
              size="small"
              fullWidth
              label="Amount"
              name="amount"
              required
              className={classes.amountInput}
              onChange={handleTickerAmountChange(values.tickers, index)}
              value={values.tickers[index].amount}
              disabled={disabled}
              error={errors?.tickers && !!errors?.tickers[index]?.amount}
              helperText={errors?.tickers && errors?.tickers[index]?.amount}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
            />
            <Autocomplete
              options={assetClasses}
              value={values.tickers[index].assetClass}
              groupBy={option => option.assetClassCategory.name}
              getOptionLabel={option => option.name}
              getOptionSelected={(option, value) => option.id === value}
              onChange={handleAssetClassChange(values.tickers, index)}
              loading={isLoadingAssetClasses}
              renderInput={params => (
                <TextField
                  {...params}
                  className={classes.assetClassInput}
                  error={errors?.tickers && !!errors?.tickers[index]?.assetClass}
                  helperText={errors?.tickers && errors?.tickers[index]?.assetClass}
                  label="Asset Class"
                  size="small"
                  margin="none"
                  variant="outlined"
                />
              )}
            />
          </div>
        ))}
      </div>
      <InputFile
        name="file"
        onChange={handleInputFileChange(values.tickers)}
        fieldClassName={classes.importInput}
        placeholder="Import from file"
        accept=".xlsx"
      />
    </>
  );
};

Tickers.propTypes = {
  accountIndex: PropTypes.number.isRequired,
  values: valuesShape,
  errors: PropTypes.shape({}).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setErrors: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default Tickers;
