import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';

import EditIcon from 'components/Icons/EditIcon';
import BlankButton from 'components/BlankButton';

import useStyles from './useStyles';

const List = ({ title, data, onEdit, noDataText }) => {
  const classes = useStyles();
  const handleClick = () => {
    onEdit();
  };

  const renderListItems = () => {
    if (isEmpty(data)) {
      return <p className={classes.description}>{noDataText}</p>;
    }
    return data.map(item => (
      <div className={classes.listItem} key={item.key}>
        {item}
      </div>
    ));
  };
  return (
    <>
      <div className={classes.header}>
        <div className={classes.title}>{title}</div>
        <div className={classes.edit}>
          <BlankButton type="button" className={classes.action} onClick={handleClick}>
            <EditIcon viewBox="0 0 14 14" classes={{ root: classes.filterIconRoot }} />
            Edit
          </BlankButton>
        </div>
      </div>
      <div className={classes.description}>{renderListItems()}</div>
    </>
  );
};

List.propTypes = {
  title: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape),
  onEdit: PropTypes.func,
  noDataText: PropTypes.string,
};

export default List;
