import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const AlarmIcon = props => {
  return (
    <SvgIcon {...props}>
      <svg width="60" height="60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M58 30c0 15.464-12.536 28-28 28S2 45.464 2 30 14.536 2 30 2s28 12.536 28 28zm2 0c0 16.569-13.431 30-30 30C13.431 60 0 46.569 0 30 0 13.431 13.431 0 30 0c16.569 0 30 13.431 30 30zm-45.434-1.403l14.03-14.032a1.984 1.984 0 012.807 0l14.032 14.032a1.984 1.984 0 010 2.806L31.402 45.435a1.984 1.984 0 01-2.806 0L14.565 31.402a1.984 1.984 0 010-2.806zm-1.404 4.21a3.969 3.969 0 010-5.613l14.032-14.032a3.969 3.969 0 015.612 0l14.032 14.032a3.969 3.969 0 010 5.612L32.806 46.838a3.969 3.969 0 01-5.612 0L13.162 32.806zM31 22v12h-2V22h2zm0 14v2h-2v-2h2z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};

export default AlarmIcon;
