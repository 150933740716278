import React from 'react';
import PropTypes from 'prop-types';
import { Button, TopToolbar, sanitizeListRestProps } from 'react-admin';

const HouseholdActions = props => {
  const {
    basePath,
    total,
    resource,
    currentSort,
    exporter,
    maxResults,
    filterValues,
    permanentFilter,
    handleExportButtonClick,
    ...rest
  } = props;

  return (
    <TopToolbar {...sanitizeListRestProps(rest)}>
      <Button label="Export" onClick={handleExportButtonClick} />
    </TopToolbar>
  );
};

HouseholdActions.propTypes = {
  basePath: PropTypes.string,
  total: PropTypes.number,
  resource: PropTypes.string,
  currentSort: PropTypes.shape(),
  exporter: PropTypes.func,
  filterValues: PropTypes.shape(),
  permanentFilter: PropTypes.shape(),
  maxResults: PropTypes.number,
  handleExportButtonClick: PropTypes.func,
};

export default HouseholdActions;
