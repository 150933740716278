import numeral from 'numeral';
import { is } from 'ramda';

export const POINTED_THOUSAND_SEPARATOR_FORMAT = '$0,0.0';

export const getFormattedNumber = (num, format = '$0') => numeral(num).format(format);
export const getFormattedPercent = val => numeral(val).format('0%');
export const getIntPercent = val =>
  numeral(val)
    .divide(100)
    .format('%');

export const getPointedFormattedNumber = num => numeral(num).format('$0.0');
export const getPointedFormattedPercent = val => numeral(val).format('0.0%');
export const getPointedIntPercent = val =>
  numeral(val)
    .divide(100)
    .format('0.0%');

export const toFixedFloat = (number, digits) => parseFloat(number).toFixed(digits);

export const getFormattedThousands = number => numeral(number).format('0,0');

export const isNumber = value => is(Number, value);
