import PropTypes from 'prop-types';

import Presenter from 'utils/PropTypesPresenter';

export default new Presenter(
  {
    id: PropTypes.number,
    modelTitle: PropTypes.string,
    fileAttached: PropTypes.boolean,
  },
  {
    isAttached(dataExport) {
      return dataExport.data.export.fileAttached === true;
    },
    notAttached(dataExport) {
      return dataExport.data.export.fileAttached === false;
    },
  },
);
