import React from 'react';
import PropTypes from 'prop-types';
import { lensPath, view } from 'ramda';

import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Button from '@material-ui/core/Button';

import { accountShape, valuesShape } from 'containers/AdvisorForm/propTypes';
import { useImportPortfolioActions } from 'store/ImportPortfolioSlice';

import Tickers from './components/Tickers';

import useStyles from './useStyles';

const Account = props => {
  const classes = useStyles();
  const {
    accountIndex,
    onAccountRemove,
    onChange,
    account,
    disabled,
    disabledDeletion,
    values,
    errors,
    setFieldValue,
    setErrors,
  } = props;

  const { customers } = values.household;

  const { downloadTickerImportTemplate } = useImportPortfolioActions();

  const accountNameErrorLens = lensPath(['household', 'accounts', accountIndex, 'accountName']);
  const accountNameError = view(accountNameErrorLens, errors);

  const accountOwnerErrorLens = lensPath(['household', 'accounts', accountIndex, 'accountOwner']);
  const accountOwnerError = view(accountOwnerErrorLens, errors);

  const handleDownloadTemplateButtonClick = () => {
    downloadTickerImportTemplate();
  };

  return (
    <div className={classes.root}>
      <div className={classes.flex}>
        <TextField
          variant="outlined"
          margin="none"
          className={classes.accountNameControl}
          required
          fullWidth
          InputProps={{
            className: classes.input,
          }}
          id={`household.accounts[${accountIndex}].accountName`}
          label="Account Name"
          name={`household.accounts[${accountIndex}].accountName`}
          onChange={onChange}
          value={account.accountName}
          disabled={disabled}
          error={Boolean(accountNameError)}
          helperText={Boolean(accountNameError) && accountNameError}
        />

        <FormControl variant="outlined" fullWidth required disabled={disabled}>
          <InputLabel error={Boolean(accountOwnerError)} id={`household.accounts[${accountIndex}].accountOwner.label`}>
            Account Owner(s)
          </InputLabel>
          <Select
            multiple
            className={classes.select}
            label="Account Owner(s)"
            labelId={`household.accounts[${accountIndex}].accountOwner.label`}
            inputProps={{
              id: `household.accounts[${accountIndex}].accountOwner`,
              name: `household.accounts[${accountIndex}].accountOwner`,
            }}
            value={values.household.accounts[accountIndex].accountOwner}
            onChange={onChange}
            error={Boolean(accountOwnerError)}
          >
            <MenuItem disabled>
              <em>Select from the customers above</em>
            </MenuItem>
            {customers.map(customer => (
              <MenuItem key={customer.id} value={customer.id}>
                {customer.customerName}
              </MenuItem>
            ))}
          </Select>
          {Boolean(accountOwnerError) && <FormHelperText error>{accountOwnerError}</FormHelperText>}
        </FormControl>
      </div>

      <Tickers
        values={values}
        errors={errors}
        accountIndex={accountIndex}
        setFieldValue={setFieldValue}
        setErrors={setErrors}
        disabled={disabled}
      />

      <div className={classes.controlGroup}>
        <Button type="button" variant="contained" color="primary" onClick={handleDownloadTemplateButtonClick}>
          download template
        </Button>
        <IconButton
          color="primary"
          aria-label="delete"
          disabled={disabledDeletion}
          onClick={() => onAccountRemove(accountIndex)}
        >
          <DeleteForeverIcon />
        </IconButton>
      </div>
    </div>
  );
};

Account.propTypes = {
  accountIndex: PropTypes.number.isRequired,
  disabled: PropTypes.bool.isRequired,
  disabledDeletion: PropTypes.bool.isRequired,
  onAccountRemove: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  account: accountShape,
  values: valuesShape,
  errors: PropTypes.shape({}).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setErrors: PropTypes.func.isRequired,
};

export default Account;
