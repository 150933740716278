import React from 'react';
import PropTypes from 'prop-types';

import AuxiliaryButtons from 'admin/components/AuxiliaryButtons';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

import useStyles from './useStyles';

const TEMPLATE_NAME = 'monthly_active_users';
const HELPER_TEXT =
  'Chart displays the percentage of users created in a certain month that have used the app in the last 30 days';

const ActiveUsersChart = props => {
  const { data, onExport } = props;

  const classes = useStyles();

  return (
    <Paper className={classes.chart}>
      <AuxiliaryButtons onExport={onExport} helperText={HELPER_TEXT} templateName={TEMPLATE_NAME} />
      <Typography component="h2" variant="h5" color="primary">
        % Active / New Users
      </Typography>
      <LineChart width={500} height={350} data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="month" />
        <YAxis label={{ value: 'Percentage', angle: -90, position: 'insideLeft' }} />
        <Tooltip />
        <Legend />
        <Line name="Advisors" type="monotone" dataKey="advisorsValue" stroke="#8884d8" activeDot={{ r: 8 }} />
        <Line name="Customers" type="monotone" dataKey="customersValue" stroke="#e82525" activeDot={{ r: 8 }} />
      </LineChart>
    </Paper>
  );
};

ActiveUsersChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onExport: PropTypes.func.isRequired,
};

export default ActiveUsersChart;
