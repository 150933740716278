import React from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';

import useStyles from './useStyles';

const SamplePortfolioInfo = props => {
  const { onClose } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.title}>Why sample portfolio?</div>
      <div className={classes.description}>
        <p>TBD.</p>
      </div>
      <div className={classes.buttonWrapper}>
        <Button variant="text" size="small" onClick={onClose} classes={{ root: classes.buttonRoot }}>
          Close
        </Button>
      </div>
    </div>
  );
};

SamplePortfolioInfo.propTypes = {
  onClose: PropTypes.func.isRequired,
};
export default SamplePortfolioInfo;
