import React from 'react';
import PropTypes from 'prop-types';
import LensPresenter from 'presenters/LensPresenter';

import question from 'assets/question.svg';

import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import SquareCheckbox from 'components/SquareCheckbox';

import useStyles from './useStyles';
import clsx from 'clsx';

const LensCard = ({ lens, onSelect, selected, horizontal, disabled, onOpenExtendedDescription }) => {
  const classes = useStyles();
  const isSelected = !!selected.find(i => i.id === LensPresenter.id(lens));
  const icon = isSelected ? LensPresenter.iconSelected(lens) : LensPresenter.icon(lens);

  const lensStyles = clsx([classes.lens], {
    [classes.selected]: isSelected,
    [classes.horizontal]: horizontal,
    [classes.disabled]: disabled,
  });
  const handleSelect = () => {
    onSelect(lens);
  };

  return (
    <>
      <div className={lensStyles}>
        <div className={classes.lensHeader}>
          <SquareCheckbox checked={isSelected} onClick={handleSelect} />
          {!horizontal && (
            <IconButton type="button" className={classes.question} onClick={onOpenExtendedDescription(lens)}>
              <img src={question} alt="Tooltip" width="18" height="18" />
            </IconButton>
          )}
        </div>
        <div className={classes.lensInfo}>
          <h3 className={classes.lensTitle}>{LensPresenter.name(lens)}</h3>
          <img src={icon} className={classes.lensImage} alt={LensPresenter.name(lens)} width="120" height="120" />
          <p className={classes.lensDescription}>{LensPresenter.description(lens)}</p>
          <Button
            variant="outlined"
            classes={{
              root: classes.buttonRoot,
              label: classes.buttonLabel,
            }}
            onClick={handleSelect}
          >
            {isSelected ? 'Selected' : 'Select'}
          </Button>
        </div>
      </div>
    </>
  );
};

export default LensCard;

LensCard.propTypes = {
  lens: PropTypes.shape(),
  onSelect: PropTypes.func,
  selected: PropTypes.arrayOf(PropTypes.shape()),
  horizontal: PropTypes.bool,
  disabled: PropTypes.bool,
  onOpenExtendedDescription: PropTypes.func,
};
