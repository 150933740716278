import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';

import Progress from 'components/Progress';
import Funds from './components/Funds';

import UserPresenter from 'presenters/UserPresenter';
import SavedFundPresenter from 'presenters/SavedFundPresenter';

import account from 'assets/account.svg';

import AppRoutes from 'routes/AppRoutes';

import useApp from 'hooks/useApp';

import useStyles from './useStyles';
import CustomersDropdown from 'components/CustomersDropdown';

const Header = props => {
  const {
    currentUser,
    customersList,
    selectedCustomer,
    onChangeSelectedCustomer,
    savedFunds,
    onDestroySavedFund,
  } = props;
  const classes = useStyles();

  const { appLogoUrl } = useApp();

  const isAdvisor = UserPresenter.isAdvisor(currentUser);
  const shouldShowDropDown = isAdvisor;

  return (
    <div className={classes.header}>
      <div className={classes.logo}>
        <Link to={AppRoutes.rootPath()}>
          <img src={appLogoUrl} alt="Reflection Analytics Logo" className={classes.logoImage} />
        </Link>
      </div>
      <div className={classes.progress}>
        <Progress recommendationsAvailable={UserPresenter.recommendationsAvailable(currentUser)} />
      </div>
      <div className={classes.menu}>
        <div className={classes.userContainer}>
          <img src={account} className={classes.userpic} alt="Userpic" />
          <div className={classes.greeting}>Hi. {UserPresenter.fullName(currentUser)}</div>
          <Funds savedFunds={savedFunds} onDestroySavedFund={onDestroySavedFund} />
        </div>
        {shouldShowDropDown && (
          <div className={classes.selectContainer}>
            <CustomersDropdown
              customersList={customersList}
              selectedCustomer={selectedCustomer}
              onChangeSelectedCustomer={onChangeSelectedCustomer}
            />
          </div>
        )}
      </div>
      {isAdvisor && (
        <div className={classes.advisorButtonGroup}>
          <Button
            label="Create new Household"
            component={Link}
            to={AppRoutes.advisorFormPath()}
            variant="outlined"
            color="default"
            startIcon={<AddIcon />}
            className={classes.linkButton}
          >
            Create new Household
          </Button>
          <Button
            label="Edit Household"
            component={Link}
            to={AppRoutes.advisorEditFormPath()}
            variant="outlined"
            color="default"
            startIcon={<EditIcon />}
            className={classes.linkButton}
          >
            Edit Household
          </Button>
        </div>
      )}
    </div>
  );
};

Header.propTypes = {
  currentUser: UserPresenter.shape().isRequired,
  savedFunds: PropTypes.arrayOf(SavedFundPresenter.shape()).isRequired,
  onDestroySavedFund: PropTypes.func.isRequired,
  customersList: PropTypes.arrayOf(PropTypes.shape()),
  selectedCustomer: PropTypes.shape(),
  onChangeSelectedCustomer: PropTypes.func,
};

Header.defaultProps = {
  customersList: null,
  selectedCustomer: null,
  onChangeSelectedCustomer: null,
};

export default Header;
