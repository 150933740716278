import React from 'react';
import PropTypes from 'prop-types';
import { CreateButton, Button, TopToolbar, sanitizeListRestProps } from 'react-admin';
import { useHistory } from 'react-router-dom';

import AdminRoutes from 'routes/AdminRoutes';

const UserActions = props => {
  const {
    basePath,
    total,
    resource,
    currentSort,
    exporter,
    maxResults,
    filterValues,
    permanentFilter,
    handleExportButtonClick,
    ...rest
  } = props;

  const history = useHistory();

  const handleImportButtonClick = () => {
    history.push(AdminRoutes.usersImportPath());
  };

  return (
    <TopToolbar {...sanitizeListRestProps(rest)}>
      <Button label="Import" onClick={handleImportButtonClick} />
      <CreateButton basePath={basePath} />
      <Button label="Export" onClick={handleExportButtonClick} />
    </TopToolbar>
  );
};

UserActions.propTypes = {
  basePath: PropTypes.string,
  total: PropTypes.number,
  resource: PropTypes.string,
  currentSort: PropTypes.shape(),
  exporter: PropTypes.func,
  filterValues: PropTypes.shape(),
  permanentFilter: PropTypes.shape(),
  maxResults: PropTypes.number,
  handleExportButtonClick: PropTypes.func,
};

export default UserActions;
