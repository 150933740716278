import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { pluck, map, isNil, isEmpty, head } from 'ramda';

import Button from '@material-ui/core/Button';

import { useApp, useRecommendation } from 'hooks';

import AppRoutes from 'routes/AppRoutes';

import Heading from 'components/Heading';
import BackButton from 'components/BackButton';
import Loader from 'components/Loader';

import AccountPresenter from 'presenters/AccountPresenter';
import RecommendationPresenter from 'presenters/RecommendationPresenter';
import UserPresenter from 'presenters/UserPresenter';
import PortfolioAnalysisPresenter from 'presenters/PortfolioAnalysisPresenter';

import { getUrlParam } from 'utils/location';
import { isEquals } from 'utils/arrayUtils';
import { RecommendedFundsKind } from 'enums/RecommendedFunds';
import useNotification from 'hooks/useNotification';

import AssetClasses from './components/AssetClasses';
import RecommendedFunds from './components/RecommendedFunds';

import useStyles from './useStyles';

const Recommendation = () => {
  const classes = useStyles();

  const routeParams = useParams();
  const recommendationId = Number(routeParams.recommendationId);
  const portfolioAnalysesParams = map(Number, getUrlParam('portfolio_analyses_ids', []));

  const [accounts, setAccounts] = useState([]);
  const [currentBatchId, setCurrentBatchId] = useState(null);
  const [selectedAccounts, setSelectedAccounts] = useState([]);
  const [selectedAssetClass, setSelectedAssetClass] = useState(null);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [kindOfFunds, setKindOfFunds] = useState(RecommendedFundsKind.fund);

  const { selectedCustomer, currentUser, loadAdvisorCustomerAccounts, loadAccounts: loadCustomerAccounts } = useApp();

  const {
    recommendedFunds,
    loadRecommendation,
    loadRecommendedFunds,
    groupedAssetClassesCategoriesStatuses,
    loadGroupedAssetClassesCategories,
    recommendedFundsStatuses,
    groupedAssetClassesCategories,
  } = useRecommendation();
  const { showErrorNotification } = useNotification();

  const isPortfolioAnalysisWithSameBatchId = (portfolioAnalysis, batchId) => {
    if (isNil(portfolioAnalysis)) {
      return false;
    }

    return PortfolioAnalysisPresenter.customerPortfolioAnalysesBatchId(portfolioAnalysis) === batchId;
  };

  const getAccountOption = ({ value: label, key }) => ({ key, label });
  const accountOptions = map(getAccountOption, accounts);

  const loadAccounts = async () => {
    if (UserPresenter.isCustomer(currentUser)) {
      const accs = await loadCustomerAccounts();
      return accs;
    }

    const searchParams = { q: { userAccountsUserIdIn: [selectedCustomer.id] } };
    const accs = await loadAdvisorCustomerAccounts(searchParams);

    return accs;
  };

  const accountWithPortfolioAnalysisKey = batchId => account => {
    let key = null;
    const { customerPortfolioAnalysis, cachedCustomerPortfolioAnalysis } = account;
    if (isPortfolioAnalysisWithSameBatchId(customerPortfolioAnalysis, batchId)) {
      key = PortfolioAnalysisPresenter.id(customerPortfolioAnalysis);
    } else if (isPortfolioAnalysisWithSameBatchId(cachedCustomerPortfolioAnalysis, batchId)) {
      key = PortfolioAnalysisPresenter.id(cachedCustomerPortfolioAnalysis);
    }
    return { ...account, key };
  };

  const getAccountFormattedList = (accs, batchId) => {
    const accountList = AccountPresenter.asList(accs, selectedCustomer);
    const accountListWithPortfolioAnalysisKey = map(accountWithPortfolioAnalysisKey(batchId), accountList);
    return accountListWithPortfolioAnalysisKey;
  };

  const initializeSelectedAccounts = accountList => {
    const selectedAccountIds = isEmpty(portfolioAnalysesParams) ? pluck('key', accountList) : portfolioAnalysesParams;
    setSelectedAccounts(selectedAccountIds);
  };

  const handlePollRecommendations = async () => {
    const recommendation = await loadRecommendation(recommendationId);

    if (RecommendationPresenter.isInProgress(recommendation)) {
      setTimeout(handlePollRecommendations, 1000);
    }
    if (RecommendationPresenter.isFinished(recommendation)) {
      const accs = await loadAccounts();
      const batchId = RecommendationPresenter.customerPortfolioAnalysesBatchId(recommendation);
      setCurrentBatchId(batchId);

      const accountList = getAccountFormattedList(accs, batchId);

      setAccounts(accountList);
      initializeSelectedAccounts(accountList);

      setIsDataLoaded(true);
    }
    if (RecommendationPresenter.isCrashed(recommendation)) {
      setIsDataLoaded(true);
      showErrorNotification();
    }
  };

  const handleChangeSelectedAccounts = accountIds => {
    if (isEquals(accountIds, selectedAccounts)) {
      return;
    }

    setSelectedAccounts(accountIds);
  };

  useEffect(() => {
    handlePollRecommendations();
  }, []);

  useEffect(() => {
    loadGroupedAssetClassesCategories(currentBatchId, selectedAccounts);
  }, [selectedAccounts]);

  useEffect(() => {
    loadRecommendedFunds(recommendationId, kindOfFunds);
  }, [kindOfFunds]);

  const renderContent = () => {
    if (!isDataLoaded) {
      return (
        <div className={classes.loaderWrap}>
          <Loader />
        </div>
      );
    }

    return (
      <div className={classes.container}>
        <AssetClasses
          groupedAssetClassesCategories={groupedAssetClassesCategories}
          isGroupedAssetClassesCategoriesLoading={groupedAssetClassesCategoriesStatuses.isLoading}
          accountOptions={accountOptions}
          selectedAccountIds={selectedAccounts}
          onChangeSelectedAccounts={handleChangeSelectedAccounts}
          onChangeSelectedAssetClass={setSelectedAssetClass}
          selectedAssetClass={selectedAssetClass}
          batchId={currentBatchId}
        />
        <RecommendedFunds
          selectedAssetClass={selectedAssetClass}
          recommendedFunds={recommendedFunds}
          kind={kindOfFunds}
          setKind={setKindOfFunds}
          isLoading={recommendedFundsStatuses.isLoading}
        />
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <div className={classes.nav}>
        <BackButton to={AppRoutes.portfolioAnalysisPath(head(selectedAccounts))}>
          <p>Back to Results</p>
        </BackButton>
      </div>
      <div className={classes.headLine}>
        <Heading
          intro="Make a values-based change"
          title="Pre-Screened Strategies"
          description="The following pre-screened funds have been evaluated using your values-based lens.
            Identify any funds you would like to discuss with your advisor that may better align your values with your financial goals."
          className={classes.heading}
        />
      </div>
      {renderContent()}
      <div className={classes.buttonsGroup}>
        <Button type="button" variant="contained" color="primary" className={classes.continueButton} onClick={() => {}}>
          CONTINUE
        </Button>
        <Button type="button" className={classes.backBottom} variant="text" size="medium" onClick={() => {}}>
          BACK
        </Button>
      </div>
    </div>
  );
};

export default Recommendation;
