import React from 'react';
import { TextField, Edit, SimpleForm, TextInput, SelectInput } from 'react-admin';

const FundEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextField source="id" />
      <TextInput source="ticker" />
      <TextInput source="name" />
      <TextInput source="cik" />
      <TextInput source="series" />
      <SelectInput
        label="Kind"
        source="kind"
        choices={[
          { id: 'core', name: 'Core' },
          { id: 'esg', name: 'ESG' },
          { id: 'other', name: 'Other' },
        ]}
        alwaysOn
      />
    </SimpleForm>
  </Edit>
);

export default FundEdit;
