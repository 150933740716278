import React from 'react';
import PropTypes from 'prop-types';
import { flatten, head } from 'ramda';

import AuxiliaryButtons from 'admin/components/AuxiliaryButtons';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

import useStyles from './useStyles';

const TEMPLATE_NAME = 'pillars_alignment';
const HELPER_TEXT = 'Chart displays the percentage of pillars’ alignment per age group';
const X_AXIS_TICK_PROPERTIES = { yOffset: 4, length: -35 };
const X_AXIS_TEXT_PROPERTIES = { yOffset: 2 };

const PillarAlignmentDistributionChart = props => {
  const { data, onExport } = props;

  const pillarData = flatten(data);

  const classes = useStyles();

  const renderTick = tickProps => {
    const { x, y, payload } = tickProps;
    const { value, index, offset } = payload;

    const gap = head(data).length;
    const isFirst = index === 0;
    const isLast = pillarData.length - 1 === index;

    if (index % gap === Math.floor(gap / 2)) {
      return (
        <text x={x} y={y + X_AXIS_TEXT_PROPERTIES.yOffset} textAnchor="middle">
          {value}
        </text>
      );
    }

    if (index % gap === 0 && !isLast && !isFirst) {
      const pathX = Math.floor(x) - offset;

      return (
        <path d={`M${pathX},${y - X_AXIS_TICK_PROPERTIES.yOffset}v${X_AXIS_TICK_PROPERTIES.length}`} stroke="red" />
      );
    }
    return null;
  };

  return (
    <Paper className={classes.chart}>
      <AuxiliaryButtons onExport={onExport} helperText={HELPER_TEXT} templateName={TEMPLATE_NAME} />
      <Typography component="h2" variant="h5" color="primary">
        Pillar Alignment Distribution (Age)
      </Typography>
      <BarChart width={500} height={350} data={pillarData} margin={{ top: 30, right: 30, left: 20, bottom: 25 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <XAxis
          tick={renderTick}
          dataKey="age"
          axisLine={false}
          tickLine={false}
          interval={0}
          height={10}
          scale="auto"
          xAxisId="period"
        />
        <YAxis type="number" domain={[0, 'dataMax']} />
        <Tooltip />
        <Legend wrapperStyle={{ bottom: 5 }} />
        <Bar name="No Analysis" dataKey="noAnalysis" stackId="a" fill="#8884d8" />
        <Bar name="No Aligned" dataKey="noAligned" stackId="a" fill="#82ca9d" />
        <Bar name="Somewhat Aligned" dataKey="somewhatAligned" stackId="a" fill="#ffc658" />
        <Bar name="Aligned" dataKey="aligned" stackId="a" fill="#d21010" />
        <Bar name="Strongly Aligned" dataKey="stronglyAligned" stackId="a" fill="#101fd2" />
      </BarChart>
    </Paper>
  );
};

PillarAlignmentDistributionChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape())).isRequired,
  onExport: PropTypes.func.isRequired,
};

export default PillarAlignmentDistributionChart;
