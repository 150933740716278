import React, { useState } from 'react';
import PropTypes from 'prop-types';
import keyMirror from 'keymirror';
import { isNil } from 'ramda';
import Tooltip from '@material-ui/core/Tooltip';

import ReflectionReportPresenter from 'presenters/ReflectionReportPresenter';
import CustomerFundAnalysisPresenter from 'presenters/CustomerFundAnalysisPresenter';
import CustomerLensPresenter from 'presenters/CustomerLensPresenter';
import TickerMetaPresenter from 'presenters/TickerMetaPresenter';
import CustomerFundsReportPresenter from 'presenters/CustomerFundsReportPresenter';
import CompanyPresenter from 'presenters/CompanyPresenter';
import FetchStatusPresenter from 'presenters/FetchStatusPresenter';

import Drawer from 'components/Drawer';
import CompaniesIcon from 'components/Icons/CompaniesIcon';
import AlarmIcon from 'components/Icons/AlarmIcon';
import FinanceIcon from 'components/Icons/FinanceIcon';
import IssuesDefined from 'components/IssuesDefined';
import CompaniesScored from 'containers/CompaniesScored';
import PercentValueHeld from 'containers/PercentValueHeld';
import Loader from 'components/Loader';

import Card from './components/Card';
import Panels from './components/Panels';

import useStyles from './useStyles';

const drawerContentKeys = keyMirror({
  issuesDefined: null,
  companiesScored: null,
  valueHeld: null,
});

const { issuesDefined, companiesScored, valueHeld } = drawerContentKeys;

const Stat = props => {
  const {
    currentReport,
    customerLens,
    flaggedTickers,
    customerFundAnalyses,
    onSetCurrentCompanyId,
    selectedReportsFundIds,
    selectedIssuesDefinedFundIds,
    onSetSelectedIssuesDefinedFundIds,
    selectedCompaniesScoredFundIds,
    onSetSelectedCompaniesScoredFundIds,
    selectedPercentValueHeldFundIds,
    onSetSelectedPercentValueHeldFundIds,
    currentCompanyId,
    scoredCompanies,
    scoredCompaniesOptions,
    isScoredCompaniesOptionsLoading,
    currentCompany,
    isCurrentCompanyLoading,
    customerFundsReportLoadingStatus,
    mixedReportStatus,
    customerFundsReportMetrics,
    isMultipleAccountMode,
  } = props;

  const classes = useStyles();

  const [drawerRenderContent, changeDrawerContent] = useState(null);

  const handleDrawerHide = () => {
    changeDrawerContent(null);
    onSetSelectedIssuesDefinedFundIds(selectedReportsFundIds);
    onSetSelectedCompaniesScoredFundIds(selectedReportsFundIds);
    onSetSelectedPercentValueHeldFundIds(selectedReportsFundIds);
  };

  const handleIssuesDefinedShow = () => {
    changeDrawerContent(issuesDefined);
  };

  const handleCompaniesScoredShow = () => {
    changeDrawerContent(companiesScored);
  };

  const handleValueHeldShow = () => {
    changeDrawerContent(valueHeld);
  };

  const handleViewCompanyDetailsClick = (company, fundIds) => {
    const scoredCompanyTickers = scoredCompanies.map(scoredCompany => CompanyPresenter.ticker(scoredCompany));

    if (scoredCompanyTickers.includes(TickerMetaPresenter.companyTicker(company))) {
      onSetSelectedCompaniesScoredFundIds(fundIds);
      onSetCurrentCompanyId(TickerMetaPresenter.companyId(company));
      handleCompaniesScoredShow();
    }
  };

  const renderDrawerContent = () => {
    switch (drawerRenderContent) {
      case issuesDefined: {
        return (
          <IssuesDefined
            customerFundAnalyses={customerFundAnalyses}
            flaggedTickers={flaggedTickers}
            selectedFundIds={selectedIssuesDefinedFundIds}
            onSetSelectedFundIds={onSetSelectedIssuesDefinedFundIds}
            onClickViewDetails={handleViewCompanyDetailsClick}
            scoredCompanies={scoredCompanies}
          />
        );
      }
      case companiesScored: {
        return (
          <CompaniesScored
            customerFundAnalyses={customerFundAnalyses}
            onSetCurrentCompanyId={onSetCurrentCompanyId}
            onSetSelectedFundIds={onSetSelectedCompaniesScoredFundIds}
            selectedFundIds={selectedCompaniesScoredFundIds}
            currentCompanyId={currentCompanyId}
            currentCompany={currentCompany}
            isCurrentCompanyLoading={isCurrentCompanyLoading}
            scoredCompaniesOptions={scoredCompaniesOptions}
            isScoredCompaniesOptionsLoading={isScoredCompaniesOptionsLoading}
            variant="modal"
          />
        );
      }
      case valueHeld: {
        return (
          <PercentValueHeld
            customerFundAnalyses={customerFundAnalyses}
            onClickViewDetails={handleViewCompanyDetailsClick}
            selectedFundIds={selectedPercentValueHeldFundIds}
            onSetSelectedFundIds={onSetSelectedPercentValueHeldFundIds}
            chartID="modal"
            isMultipleAccountMode={isMultipleAccountMode}
          />
        );
      }
      default: {
        return null;
      }
    }
  };

  const shouldShowDrawer = !isNil(drawerRenderContent);
  const isCustomerFundsReportLoading = FetchStatusPresenter.isLoading(customerFundsReportLoadingStatus);

  return (
    <>
      <div className={classes.info}>
        {FetchStatusPresenter.isLoading(mixedReportStatus) ? (
          <div className={classes.loader}>
            <Loader />
          </div>
        ) : (
          <Tooltip title="% of the dollar value of the investments in the 1st & 2nd quartiles per the provided screens">
            <div className={classes.counter}>
              <div className={classes.container}>
                <div className={classes.value}>
                  <span className={classes.number}>{ReflectionReportPresenter.matchScorePercent(currentReport)}</span>
                  <span className={classes.counterTitle}>Match</span>
                </div>
              </div>
            </div>
          </Tooltip>
        )}
        <div className={classes.cards}>
          <div className={classes.card}>
            <Card
              icon={<CompaniesIcon viewBox="0 0 64 64" classes={{ root: classes.icon }} />}
              title="Securities scored"
              value={CustomerFundsReportPresenter.companiesScoredValue(customerFundsReportMetrics)}
              secondTitle="Aligned companies"
              secondValue={CustomerFundsReportPresenter.alignedCompaniesCount(customerFundsReportMetrics)}
              onClick={handleCompaniesScoredShow}
              isLoading={isCustomerFundsReportLoading}
            />
          </div>
          <div className={classes.card}>
            <Card
              icon={<FinanceIcon viewBox="0 0 64 64" classes={{ root: classes.icon }} />}
              title="% dollar value held by securities scored"
              value={CustomerFundsReportPresenter.formattedScoredAmountPercents(customerFundsReportMetrics)}
              onClick={handleValueHeldShow}
              isLoading={isCustomerFundsReportLoading}
            />
          </div>
          <div className={classes.card}>
            <Card
              icon={<AlarmIcon viewBox="0 0 64 64" classes={{ root: classes.icon }} />}
              title="Scored securities with flags"
              value={CustomerFundsReportPresenter.scoredCompaniesWithFlags(customerFundsReportMetrics)}
              onClick={handleIssuesDefinedShow}
              isLoading={isCustomerFundsReportLoading}
            />
          </div>
        </div>
      </div>
      <Panels currentReport={currentReport} customerLens={customerLens} />

      <Drawer wide open={shouldShowDrawer} onClose={handleDrawerHide}>
        {renderDrawerContent()}
      </Drawer>
    </>
  );
};

Stat.propTypes = {
  currentReport: ReflectionReportPresenter.shape().isRequired,
  customerLens: CustomerLensPresenter.shape().isRequired,
  flaggedTickers: PropTypes.arrayOf(TickerMetaPresenter.shape()).isRequired,
  customerFundAnalyses: PropTypes.arrayOf(CustomerFundAnalysisPresenter.shape()).isRequired,
  onSetCurrentCompanyId: PropTypes.func.isRequired,
  selectedReportsFundIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  onSetSelectedIssuesDefinedFundIds: PropTypes.func.isRequired,
  selectedIssuesDefinedFundIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  onSetSelectedCompaniesScoredFundIds: PropTypes.func.isRequired,
  selectedCompaniesScoredFundIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  onSetSelectedPercentValueHeldFundIds: PropTypes.func.isRequired,
  selectedPercentValueHeldFundIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  currentCompanyId: PropTypes.number,
  scoredCompanies: PropTypes.arrayOf(CompanyPresenter.shape()).isRequired,
  scoredCompaniesOptions: PropTypes.arrayOf(CompanyPresenter.shape()).isRequired,
  isScoredCompaniesOptionsLoading: PropTypes.bool.isRequired,
  currentCompany: CompanyPresenter.shape().isRequired,
  isCurrentCompanyLoading: PropTypes.bool.isRequired,
  customerFundsReportLoadingStatus: PropTypes.string.isRequired,
  mixedReportStatus: PropTypes.string.isRequired,
  customerFundsReportMetrics: CustomerFundsReportPresenter.shape().isRequired,
  isMultipleAccountMode: PropTypes.bool,
};

Stat.defaultProps = {
  currentCompanyId: null,
  isMultipleAccountMode: false,
};

export default Stat;
