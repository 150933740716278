export default {
  MuiMobileStepper: {
    root: {
      backgroundColor: 'transparent',
      padding: 0,
    },
    progress: {
      backgroundColor: '#ebebeb',
      width: '100%',
      height: 5,
      borderRadius: 11,
    },
  },
};
