import React from 'react';

import useStyles from './useStyles';

const TermsOfUse = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <h2>Reflection Analytics Web site - Terms and Conditions of Use</h2>
      <p>
        Access to the Reflection Analytics Web site defined below is provided by RA. For purposes of this agreement,
        “RA” shall mean Reflection Analytics LLC and its affiliates, and their officers, directors, managing directors,
        partners, and employees. The “Reflection Web site” shall mean the Web site of Reflection Analytics LLC available
        to the general public and located at Reflection-Analytics.com and select other domains.
      </p>
      <p>
        <b>Monitoring by RA:</b> Your use of the products and services on this Web site may be monitored by RA, and that
        the resultant information may be used by RA for its internal business purposes or in accordance with the rules
        of any applicable regulatory or self-regulatory organization.
      </p>
      <p>
        <b>Information Made Available Through this Service:</b> You are permitted to store, display, analyze, modify,
        reformat, and print the information made available to you via these services only for your own use. You are not
        permitted to publish, transmit, or otherwise reproduce this information, in whole or in part, in any format to
        any third party without the express written consent of RA. In addition, you are not permitted to alter, obscure,
        or remove any copyright, trademark or any other notices that are provided to you in connection with the
        information. RA reserves the right, at any time and from time to time, in the interests of its own editorial
        discretion and business judgment to add, modify, or remove any of the information. These Terms and Conditions of
        Use are not intended to, and will not, transfer or grant any rights in or to the information other than those
        which are specifically described herein, and all rights not expressly granted herein are reserved by RA or the
        third party providers from whom RA has obtained the information. You are required to read and abide by any
        additional terms and conditions that may be posted on this service from time to time concerning information
        obtained from specific third party providers. Such third party providers shall have no liability to you for
        monetary damages on account of the information provided to you via this service.
      </p>
      <p>
        <b>No warranties made as to content; no responsibilities to update:</b> RA makes no warranty, express or
        implied, concerning this service.
        <b>
          The services provided by us and our third party providers are on an “AS IS” basis at your sole risk. RA
          expressly disclaims any implied warranty of merchantability or fitness for a particular purpose, including any
          warranty for the use or the results of the use of the services with respect to their correctness, quality,
          accuracy, completeness, reliability, performance, timeliness, or continued availability.
        </b>
      </p>
      <p>
        Neither RA nor any of its third party providers shall have any responsibility to maintain the data and services
        made available on this Web site or to supply any corrections, updates, or releases in connection therewith.
        Availability of data and services are subject to change without notice.
      </p>
      <p>
        <b>No liability for content; no liability arising from use:</b> RA shall have no liability, contingent or
        otherwise, to you or to third parties, or any responsibility whatsoever, for the failure of any connection or
        communication service to provide or maintain your access to this service, or for any interruption or disruption
        of such access or any erroneous communication between RA and you, regardless of whether the connection or
        communication service is provided by RA or a third party service provider. RA shall have no liability,
        contingent or otherwise, to you or to third parties, for the correctness, quality, accuracy, timeliness,
        reliability, performance, continued availability, completeness or delays, omissions, or interruptions in the
        delivery of the data and services available on this Web site or for any other aspect of the performance of this
        service or for any failure or delay in the execution of any transactions through this service. In no event will
        RA be liable for any special, indirect, incidental, or consequential damages which may be incurred or
        experienced on account of you using the data or services made available on this Web site, even if RA has been
        advised of the possibility of such damages. RA will have no responsibility to inform you of any difficulties
        experienced by RA or third parties with respect to the use of the services or to take any action in connection
        therewith.
      </p>
      <p>
        <b>No warranties made as to security:</b> RA makes no warranty whatsoever to you, express or implied, regarding
        the security of the site, including with respect to the ability of unauthorized persons to intercept or access
        information transmitted by you through this service.
      </p>
      <p>
        <b>
          System Outages, Slowdowns, and Capacity Limitations/Delays in Order Routing, Executions, and Trade Reports:
        </b>
        As a result of high Internet traffic, transmission problems, systems capacity limitations, and other problems,
        you may, at times, experience difficulty accessing the Web site or communicating with RA through the Internet or
        other electronic and wireless services. Any computer system or other electronic device, whether, it is yours, an
        Internet service provider’s or RA’ can experience unanticipated outages or slowdowns, or have capacity
        limitations.
      </p>
      <p>
        <b>Content not to be construed as a solicitation or recommendation:</b> This material has been prepared for
        informational purposes only without regard to any particular user’s investment objectives, financial situation,
        or means, and RA is not soliciting any action based upon it. This material is not to be construed as a
        recommendation; or an offer to buy or sell; or the solicitation of an offer to buy or sell any security,
        financial product, or instrument; or to participate in any particular trading strategy in any jurisdiction in
        which such an offer or solicitation, or trading strategy would be illegal. Certain transactions, including those
        involving futures, options, and high-yield securities, give rise to substantial risk and are not suitable for
        all investors. Although this material is based upon information that RA considers reliable and endeavors to keep
        current, RA has not verified this information and does not represent that this material is accurate, current, or
        complete and it should not be relied upon as such.
      </p>
      <p>
        <b>
          No determination of suitability has been made; not all risks are disclosed; private advisors should be
          consulted:
        </b>
        The fact that RA has made the data and services provided on this Web site available to you constitutes neither a
        recommendation that you enter into a particular transaction nor a representation that any product described on
        this Web site is suitable or appropriate for you. Many of the products described on this Web site involve
        significant risks, and you should not enter into any transactions unless you have fully understood all such
        risks and has independently determined that such transactions are appropriate for you. Any discussion of the
        risks contained herein with respect to any product should not be considered to be a disclosure of all risks or
        complete discussion of the risks which are mentioned. You should neither construe any of the material contained
        herein as business, financial, investment, hedging, trading, legal, regulatory, tax, or accounting advice nor
        make this service the primary basis for any investment decisions made by or on behalf of you, your accountants,
        or your managed or fiduciary accounts, and you may want to consult your business advisor, attorney, and tax and
        accounting advisors concerning any contemplated transactions.
      </p>
      <p>
        <b>No representations made as to other sites or links:</b> This service may provide links to certain Internet
        sites (the “Sites”) sponsored and maintained by third parties. RA is providing such links solely as a
        convenience to you. Accordingly, RA makes no representations concerning the content of the Sites. The fact that
        RA has provided a link to the Site does not constitute an endorsement, authorization, sponsorship, or
        affiliation by RA with respect to the Site, its owners, or its providers. RA has not tested any information,
        software, or products found on any of the Sites and therefore does not make any representations with respect
        thereto, including any representations regarding the content or sponsors of the Site, or the suitability or
        appropriateness of the products or transactions described therein
      </p>
    </div>
  );
};

export default TermsOfUse;
