import PropTypes from 'prop-types';

import { getFormattedPercent, getFormattedThousands } from 'utils/numberHelpers';

import Presenter from 'utils/PropTypesPresenter';

export default new Presenter(
  {
    scoredCompaniesCount: PropTypes.number,
    totalCompaniesCount: PropTypes.number,
    scoredAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    totalAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    companiesWithAttachedFlagsCount: PropTypes.number,
    alignedCompaniesCount: PropTypes.number,
  },
  {
    scoredAmountPercents(report) {
      const value = this.scoredAmount(report) / this.totalAmount(report);
      return getFormattedPercent(value);
    },
    formattedScoredCompaniesCount(report) {
      const scoredCompaniesCount = this.scoredCompaniesCount(report);
      return getFormattedThousands(scoredCompaniesCount);
    },
    formattedTotalCompaniesCount(report) {
      const totalCompaniesCount = this.totalCompaniesCount(report);
      return getFormattedThousands(totalCompaniesCount);
    },
    formattedCompaniesWithAttachedFlagsCount(report) {
      const companiesWithAttachedFlagsCount = this.companiesWithAttachedFlagsCount(report);
      return getFormattedThousands(companiesWithAttachedFlagsCount);
    },
    companiesScoredValue(report) {
      return `${this.scoredCompaniesCount(report)}/${this.totalCompaniesCount(report)}`;
    },
    formattedScoredAmountPercents(report) {
      const value = this.scoredAmount(report) / this.totalAmount(report);
      return getFormattedPercent(value);
    },
    scoredCompaniesWithFlags(report) {
      return `${this.companiesWithAttachedFlagsCount(report)}/${this.scoredCompaniesCount(report)}`;
    },
  },
);
