import React, { useState } from 'react';
import Radio from 'components/Radio';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';

import ellipsis from 'assets/ellipsis';

import { IMPORTANCE_LEVELS } from 'presenters/ValuePresenter';
import CustomerCategoryValuePresenter from 'presenters/CustomerCategoryValuePresenter';
import Drawer from 'components/Drawer';
import PillarInfo from './components/PillarInfo';

import useStyles from './useStyles';

const CustomerCategoryValue = props => {
  const { customerCategoryValue, onChangeCustomerCategoryValue } = props;

  const classes = useStyles();
  const [isDrawerOpen, setDrawer] = useState(false);

  const category = CustomerCategoryValuePresenter.category(customerCategoryValue);
  const value = CustomerCategoryValuePresenter.value(customerCategoryValue);

  const handleChangeValue = newValue => () => {
    onChangeCustomerCategoryValue(customerCategoryValue, newValue);
  };

  const handleDrawerOpen = () => {
    setDrawer(true);
  };

  const handleDrawerClose = () => {
    setDrawer(false);
  };

  const renderRadio = key => {
    const isChecked = key === value;
    return (
      <Radio
        onChange={handleChangeValue(key)}
        checked={isChecked}
        value={key}
        color="default"
        classes={{ root: classes.radioRoot }}
      />
    );
  };

  return (
    <>
      <tr>
        <td className={classes.colTitle}>
          <span>{category.name}</span>
        </td>
        {IMPORTANCE_LEVELS.map(({ key }) => (
          <td key={key} className={classes[key]}>
            {renderRadio(key, value)}
          </td>
        ))}
        <td className={classes.action}>
          <IconButton type="button" className={classes.button} onClick={handleDrawerOpen}>
            <img src={ellipsis} alt="" />
          </IconButton>
        </td>
      </tr>
      <Drawer open={isDrawerOpen} onClose={handleDrawerClose}>
        <PillarInfo onClose={handleDrawerClose} category={category} value={value} />
      </Drawer>
    </>
  );
};

CustomerCategoryValue.propTypes = {
  customerCategoryValue: CustomerCategoryValuePresenter.shape().isRequired,
  onChangeCustomerCategoryValue: PropTypes.func.isRequired,
};

export default CustomerCategoryValue;
