import React from 'react';
import PropTypes from 'prop-types';
import { List, Datagrid, TextField, Filter, TextInput, EditButton } from 'react-admin';
import RecommendationsSwitch from './RecommendationsSwitch';
import LinkToInstitutionFunds from './LinkToInstitutionFunds';

import ExportPresenter from 'presenters/ExportPresenter';

import ExportsRepository from 'repositories/admin/institution/ExportsRepository';

import InstitutionActions from './InstitutionActions';

const InstitutionFilter = props => (
  <Filter {...props}>
    <TextInput label="ID" source="idEq" alwaysOn />
    <TextInput label="Name / Partner name" source="nameOrPartnerNameCont" alwaysOn />
    <TextInput label="External ID" source="externalIdEq" alwaysOn />
    <TextInput label="Partner ID" source="partnerIdEq" alwaysOn />
  </Filter>
);

const InstitutionList = props => {
  const { data } = props;

  const handleDownloadExport = async () => {
    await ExportsRepository.download();
  };

  const handlePollExport = async onSuccess => {
    const institutionsExport = await ExportsRepository.show();

    if (ExportPresenter.notAttached(institutionsExport)) {
      setTimeout(() => handlePollExport(onSuccess), 500);
    } else if (ExportPresenter.isAttached(institutionsExport)) {
      onSuccess();
    }
  };

  const handleExport = async () => {
    await ExportsRepository.create();
    handlePollExport(handleDownloadExport);
  };

  return (
    <List
      filters={<InstitutionFilter />}
      actions={<InstitutionActions handleExportButtonClick={handleExport} />}
      {...props}
    >
      <Datagrid>
        <TextField source="id" />
        <TextField source="name" />
        <TextField label="External ID" source="external_id" />
        <TextField label="Partner ID" source="partner.id" />
        <TextField label="Partner name" source="partner.name" />
        <RecommendationsSwitch label="Recommendations" record={data} />
        <LinkToInstitutionFunds record={data} />
        <EditButton />
      </Datagrid>
    </List>
  );
};

InstitutionList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
};

export default InstitutionList;
