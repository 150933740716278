import { createMuiTheme } from '@material-ui/core/styles';
import overrides from './overrides';
import props from './props';
import palette from './palette';
import typography from './typography';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: palette.purple,
    },
    secondary: {
      main: palette.macaroniAndCheese,
      contrastText: '#FFFFFF',
    },
    text: {
      primary: '#232020',
    },
    ...palette,
  },
  props,
  overrides,
  typography,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 891,
      lg: 1280,
      xl: 1920,
    },
  },
});

export default theme;
