import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './useStyles';

const CustomizedYAxisTick = ({ x, y, payload: { value } }) => {
  const classes = useStyles();
  return (
    <g>
      <text x={x} y={y} textAnchor="middle" fill="#666" className={classes.text}>
        {value}%
      </text>
    </g>
  );
};

CustomizedYAxisTick.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  payload: PropTypes.shape(),
};

export default CustomizedYAxisTick;
