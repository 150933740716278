import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const FundsIcon = props => {
  return (
    <SvgIcon {...props}>
      <svg width="24" height="22" xmlns="http://www.w3.org/2000/svg">
        <g
          stroke="currentColor"
          strokeWidth="1.25"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M23.25 7.96c0 1.625-1.343 2.942-3 2.942M8.25 6.49a6.883 6.883 0 016 0M6.375 9.431A.372.372 0 006 9.8c0 .203.168.368.375.368a.372.372 0 00.375-.368.372.372 0 00-.375-.368" />
          <path d="M7.9 3.434c-1.174-2.085-4.15-2.09-4.15-2.09l.626 4.297A7.851 7.851 0 002.6 8.696H.75v5.882h2.49a8.345 8.345 0 002.01 2.326v2.821c0 .406.336.736.75.736h1.5c.414 0 .75-.33.75-.736V18.51a9.69 9.69 0 006 .01v1.206c0 .406.336.736.75.736h1.5c.414 0 .75-.33.75-.736V16.92a7.73 7.73 0 003-6.017c0-6.367-7.05-9.205-12.35-7.468z" />
        </g>
      </svg>
    </SvgIcon>
  );
};

export default FundsIcon;
