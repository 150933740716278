import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';

import AppRoutes from 'routes/AppRoutes';

import { useManuallyEnteredPortfolio } from 'hooks';

import PortfolioForm from './components/PortfolioForm';
import useApp from 'hooks/useApp';

import useStyles from './useStyles';

const ManuallyEnteredPortfolio = props => {
  const { onPrepareApp } = props;

  const { appLogoUrl } = useApp();

  const classes = useStyles();
  const { loadCompanies, loadFunds, createUser } = useManuallyEnteredPortfolio();

  return (
    <main className={classes.main}>
      <Container component="div" maxWidth="xs" className={classes.container}>
        <div className={classes.paper}>
          <div className={classes.logo}>
            <img src={appLogoUrl} alt="Reflection Analytics Logo" width={215} />
          </div>
          <PortfolioForm
            onLoadCompanies={loadCompanies}
            onLoadFunds={loadFunds}
            onCreateUser={createUser}
            onPrepareApp={onPrepareApp}
          />
        </div>
        <hr />
        <Button
          component={Link}
          to={AppRoutes.signInPath()}
          fullWidth
          variant="contained"
          color="secondary"
          className={classes.signInLink}
        >
          Sign In
        </Button>
      </Container>
    </main>
  );
};

ManuallyEnteredPortfolio.propTypes = {
  onPrepareApp: PropTypes.func.isRequired,
};

export default ManuallyEnteredPortfolio;
