import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  theme => ({
    selected: {
      '& $buttonRoot': {
        backgroundColor: theme.palette.purple,
        borderColor: 'transparent',
      },
      '& $buttonLabel': {
        color: '#fff',
      },
    },
    disabled: {},
    lens: {
      backgroundColor: '#fff',
      borderRadius: '10px 0 10px 0',
      boxShadow: '0 25px 20px 0 rgba(0,0,0,0.15), -4px 4px 15px 0 rgba(0,0,0,0.10)',
      paddingBottom: 28,
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      border: '5px solid transparent',
      position: 'relative',
      minHeight: 420,
      '&$selected': {
        border: `5px solid ${theme.palette.purple}`,
      },
      '&$disabled': {
        opacity: 0.4,
        pointerEvents: 'none',
      },
    },
    horizontal: {
      minHeight: 270,
      paddingBottom: 33,
      '& $lensInfo': {
        flexDirection: 'column',
        paddingLeft: 176,
        justifyContent: 'center',
        alignItems: 'flex-start',
        position: 'relative',
      },
      '& $lensImage': {
        position: 'absolute',
        left: 0,
        marginLeft: 35,
        top: 0,
      },
      '& $lensDescription': {
        textAlign: 'left',
      },
      '& $lensTitle': {
        padding: 0,
        marginTop: 6,
        marginBottom: 10,
      },
      [theme.breakpoints.up('md')]: {
        minHeight: 209,
      },
    },
    lensHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '5px 7px 0 4px',
    },
    question: {
      border: 'none',
      padding: 0,
      cursor: 'pointer',
      backgroundColor: 'transparent',
      '&:focus': {
        outline: 'none',
      },
    },
    lensInfo: {
      display: 'flex',
      flexDirection: 'column',
      padding: '0 17px',
      alignItems: 'center',
      flexGrow: 1,
    },
    lensContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      '& $lensTitle': {
        padding: 0,
      },
      '& $lensDescription': {
        textAlign: 'left',
        margin: '0 0 25px 0',
      },
    },
    lensTitle: {
      fontFamily: theme.typography.font.ttHovesPro,
      fontSize: 17,
      color: '#232020',
      textAlign: 'center',
      padding: '0 30px',
      fontWeight: 'bold',
      marginBottom: 10,
      marginTop: 4,
    },
    lensImage: {
      borderRadius: '50%',
      alignSelf: 'center',
      marginBottom: 25,
    },
    lensDescription: {
      fontSize: 16,
      fontFamily: theme.typography.font.ttHovesPro,
      color: '#000',
      textAlign: 'center',
      lineHeight: '22px',
      fontStyle: 'normal',
      fontWeight: 300,
      marginTop: 0,
      marginBottom: 0,
      width: '100%',
    },
    buttonRoot: {
      border: `2px solid ${theme.palette.purple}`,
      borderRadius: 31,
      width: 132,
      marginTop: 'auto',
    },
    buttonLabel: {
      fontSize: 12,
      letterSpacing: '0.02em',
      color: theme.palette.purple,
      textTansform: 'uppercase',
      fontFamily: theme.typography.font.ttHovesPro,
      fontWeight: 'bold',
    },
  }),
  { name: 'LensCard' },
);

export default useStyles;
