import Routes from 'routes/ApiRoutes';
import FetchHelpers from 'utils/FetchHelpers';

export default {
  index(portfolioAnalysisBatchId, params) {
    const path = Routes.apiV1CustomerPortfolioAnalysesBatchCustomerFundAnalysesPath(portfolioAnalysisBatchId);
    return FetchHelpers.get(path, params);
  },
  metrics(portfolioAnalysisBatchId, params) {
    const path = Routes.metricsApiV1CustomerPortfolioAnalysesBatchCustomerFundAnalysesPath(portfolioAnalysisBatchId);
    return FetchHelpers.get(path, params);
  },
  mixedAnalysis(portfolioAnalysisBatchId, params) {
    const path = Routes.apiV1CustomerPortfolioAnalysesBatchMixedAnalysisPath(portfolioAnalysisBatchId);
    return FetchHelpers.get(path, params);
  },
  insights(portfolioAnalysisBatchId, params) {
    const path = Routes.insightsApiV1CustomerPortfolioAnalysesBatchCustomerFundAnalysesPath(portfolioAnalysisBatchId);
    return FetchHelpers.get(path, params);
  },
  flaggedTickers(portfolioAnalysisBatchId, params) {
    const path = Routes.flaggedTickersApiV1CustomerPortfolioAnalysesBatchCustomerFundAnalysesPath(
      portfolioAnalysisBatchId,
    );
    return FetchHelpers.get(path, params);
  },
  optionsList(portfolioAnalysisBatchId, params) {
    const path = Routes.optionsListApiV1CustomerPortfolioAnalysesBatchCustomerFundAnalysesPath(
      portfolioAnalysisBatchId,
    );
    return FetchHelpers.get(path, params);
  },
};
