import PropTypes from 'prop-types';

import Presenter from 'utils/PropTypesPresenter';

const STOCK = 'stock';

export default new Presenter(
  {
    id: PropTypes.number,
    fundName: PropTypes.string,
    fundTicker: PropTypes.string,
    totalScores: PropTypes.shape(),
    pillarScores: PropTypes.shape(),
  },
  {
    description(fund) {
      if (fund.kind === STOCK) {
        return '';
      }
      return `${this.fundName(fund)} (${this.fundTicker(fund)})`;
    },
  },
);
