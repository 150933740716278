import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useStyles from './useStyles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Button from '@material-ui/core/Button';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { CSSTransition } from 'react-transition-group';

const Dropdown = props => {
  const { currentValue, options, onChange } = props;
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [animation, setAnimation] = useState(false);
  const [previousValue, setPreviousValue] = useState(currentValue);
  const [labelText, setLabelText] = useState(currentValue);

  useEffect(() => {
    if (currentValue !== previousValue) {
      setPreviousValue(currentValue);
      setAnimation(true);
    }
  }, [currentValue]);

  const handleClick = () => {
    setOpen(prev => !prev);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  const handleChange = key => {
    onChange(key);
    setOpen(false);
  };

  const renderCurrentValue = isMenuOpen => {
    const styles = isMenuOpen
      ? { root: classes.buttonOpened, label: classes.labelOpened }
      : { root: classes.button, label: classes.label };
    return (
      <Button onClick={handleClick} classes={styles}>
        {labelText} {isMenuOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </Button>
    );
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <CSSTransition
        in={animation}
        timeout={{ appear: 0, enter: 430, exit: 0 }}
        classNames={{
          enterActive: classes.dropDownAnimation,
        }}
        onEntered={() => {
          setAnimation(false);
          setLabelText(currentValue);
        }}
      >
        <div className={classes.dropdown}>
          {renderCurrentValue()}
          {open && (
            <div className={classes.content}>
              {renderCurrentValue(open)}
              {options.map(({ key, value }) => (
                <Button
                  key={key}
                  value={key}
                  onClick={() => handleChange(key)}
                  classes={{ root: classes.buttonRoot, label: classes.option }}
                >
                  {value}
                </Button>
              ))}
            </div>
          )}
        </div>
      </CSSTransition>
    </ClickAwayListener>
  );
};

Dropdown.propTypes = {
  currentValue: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape()),
  onChange: PropTypes.func,
};

export default Dropdown;
