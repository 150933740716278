import PropTypes from 'prop-types';

import Presenter from 'utils/PropTypesPresenter';

import CustomerFundPresenter from 'presenters/CustomerFundPresenter';
import FundPresenter from 'presenters/FundPresenter';

export default new Presenter(
  {
    id: PropTypes.number,
    amount: PropTypes.number,
    assetClassId: PropTypes.number,
    customerPortfolioId: PropTypes.number,
    customerPortfolioAnalysisId: PropTypes.number,
    customerFundAnalysisId: PropTypes.number,
    customerFundId: PropTypes.number,
    customerFund: CustomerFundPresenter.shape(),
    reflectionMatchScore: PropTypes.number,
  },
  {
    fundName(tickerAssetClass) {
      const customerFundItem = this.customerFund(tickerAssetClass);
      const fundItem = CustomerFundPresenter.fund(customerFundItem);

      return FundPresenter.name(fundItem);
    },
    roundedMatch(fund) {
      return Math.round(this.reflectionMatchScore(fund));
    },
  },
);
