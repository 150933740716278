import PropTypes from 'prop-types';
import CategoryPresenter from 'presenters/CategoryPresenter';
import Presenter from 'utils/PropTypesPresenter';

const CATEGORY_BOOLEAN_ANALYSIS_SLUGS = ['G5', 'G6'];

export default new Presenter(
  {
    id: PropTypes.number,
    weight: PropTypes.number,
    weightText: PropTypes.string,
    value: PropTypes.bool,
    category: CategoryPresenter.shape(),
  },
  {
    valueText(analysis) {
      const category = this.category(analysis);
      const isBoolean = CATEGORY_BOOLEAN_ANALYSIS_SLUGS.some(slug => slug === CategoryPresenter.slug(category));
      if (isBoolean) {
        return this.value(analysis) ? 'Aligned' : 'No';
      }

      return this.value(analysis) ? 'Yes' : 'No';
    },
  },
);
