import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  datePickersContainer: {
    fontSize: 16,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: '0.15px',
  },
  datePickersTrends: {
    whiteSpace: 'nowrap',
    '& > span': {
      marginRight: 5,
    },
  },
}));

export default useStyles;
