import { stringify } from 'qs';
import { fetchUtils } from 'ra-core';
import { keys, head } from 'ramda';
import { isBlank } from 'utils';
import { decamelize } from 'utils/keysConverter';

function buildFilterParams({ sort, filter }) {
  const { field, order } = sort;

  const sortParams = isBlank(field) || isBlank(order) ? {} : { s: `${field} ${order}` };

  return { ...decamelize(filter), ...sortParams };
}

export default (apiUrl, httpClient = fetchUtils.fetchJson) => ({
  getList: (resource, params) => {
    const { page, perPage } = params.pagination;
    const query = {
      q: buildFilterParams(params),
      page,
      per_page: perPage,
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ json }) => {
      if (!json.meta) {
        throw new Error('The meta param is missing in the JSON');
      }
      return {
        data: json[resource],
        total: json.meta.total_count,
      };
    });
  },

  getSingle: (resource, params) =>
    httpClient(`${apiUrl}/${resource}${stringify(params)}`).then(({ json }) => ({
      data: json,
    })),

  getOne: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => {
      const jsonKeys = keys(json);
      const entityKey = head(jsonKeys);

      return { data: json[entityKey] };
    }),

  getMany: (resource, params) => {
    const query = { q: { id_in: params.ids } };
    const url = `${apiUrl}/${resource}?${stringify(query, { arrayFormat: 'brackets' })}`;
    return httpClient(url).then(({ json }) => ({ data: json[resource] }));
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;
    const filter = stringify({
      ...params.filter,
      [`${params.target}_eq`]: params.id,
    });

    const query = {
      q: buildFilterParams({ filter, sort: params.sort }),
      page,
      per_page: perPage,
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ json }) => {
      if (!json.meta) {
        throw new Error('The meta param is missing in the JSON');
      }
      return {
        data: json[resource],
        total: json.meta.total_count,
      };
    });
  },

  update: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'PUT',
      body: JSON.stringify(params.data),
    }).then(({ json }) => {
      const jsonKeys = keys(json);
      const entityKey = head(jsonKeys);

      return { data: json[entityKey] };
    }),

  // simple-rest doesn't handle provide an updateMany route, so we fallback to calling update n times instead
  updateMany: (resource, params) =>
    Promise.all(
      params.ids.map(id =>
        httpClient(`${apiUrl}/${resource}/${id}`, {
          method: 'PUT',
          body: JSON.stringify(params.data),
        }),
      ),
    ).then(responses => ({ data: responses.map(({ json }) => json.id) })),

  create: (resource, params) =>
    httpClient(`${apiUrl}/${resource}`, {
      method: 'POST',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({
      data: { ...params.data, id: json.id },
    })),

  delete: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'DELETE',
    }).then(() => ({ data: params.id })),

  deleteMany: (resource, params) => {
    const ids = stringify({ ids: params.ids }, { arrayFormat: 'brackets' });
    return httpClient(`${apiUrl}/${resource}/bulk_destroy?${ids}`, {
      method: 'DELETE',
    }).then(() => ({ data: params.ids }));
  },
});
