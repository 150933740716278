import { useSelector } from 'react-redux';

import { useDetailedFundInformationActions } from 'store/DetailedFundInformationSlice';
import getFetchStatus from 'utils/getFetchStatus';

export default () => {
  const { loadFundTickers, loadMultipleAccountsFundTickers } = useDetailedFundInformationActions();

  const fundTickers = useSelector(state => state.DetailedFundInformationSlice.fundTickers.data);

  const fundTickersStatus = useSelector(state => state.DetailedFundInformationSlice.fundTickers.status);
  const fundTickersStatuses = getFetchStatus(fundTickersStatus);

  return {
    loadFundTickers,
    loadMultipleAccountsFundTickers,
    fundTickers,
    fundTickersStatuses,
  };
};
