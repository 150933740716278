import React from 'react';
import { TextField, Edit, SimpleForm, TextInput } from 'react-admin';

const GicsSubIndustryEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextField source="id" />
      <TextInput multiline source="name" />
      <TextInput source="code" />
      <TextInput source="gics_sector_id" />
    </SimpleForm>
  </Edit>
);

export default GicsSubIndustryEdit;
