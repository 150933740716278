import PropTypes from 'prop-types';
import { partition } from 'ramda';

import FundTickerPresenter from 'presenters/FundTickerPresenter';
import CustomerFundPresenter from 'presenters/CustomerFundPresenter';

import { getFormattedPercent, getIntPercent } from 'utils/numberHelpers';
import Presenter from 'utils/PropTypesPresenter';

const STOCK_KIND = 'stock';

export default new Presenter(
  {
    id: PropTypes.number,
    name: PropTypes.string,
    kind: PropTypes.string,
    ticker: PropTypes.string,
    customerFund: CustomerFundPresenter.shape(),
    reflectionMatchScore: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    totalCompaniesCount: PropTypes.number,
    scoredCompaniesCount: PropTypes.number,
    scoredAmountPercents: PropTypes.string,
    companies: PropTypes.arrayOf(PropTypes.shape()),
    scoredAmount: PropTypes.string,
    totalAmount: PropTypes.string,
    fundPillarAnalyses: PropTypes.arrayOf(PropTypes.shape()),
    fundCategoryAnalyses: PropTypes.arrayOf(PropTypes.shape()),
    companiesWithAttachedFlagsCount: PropTypes.number,
    fundTickersWithAttachedFlags: PropTypes.arrayOf(FundTickerPresenter.shape()),
  },
  {
    matchScorePercent(fund) {
      const value = this.reflectionMatchScore(fund);
      return getIntPercent(value);
    },
    companiesScoredValue(fund) {
      return `${this.scoredCompaniesCount(fund)}/${this.totalCompaniesCount(fund)}`;
    },
    formattedScoredAmountPercents(fund) {
      const value = this.scoredAmount(fund) / this.totalAmount(fund);
      return getFormattedPercent(value);
    },
    scoredCompaniesWithFlags(fund) {
      return `${this.companiesWithAttachedFlagsCount(fund)}/${this.scoredCompaniesCount(fund)}`;
    },
    fundCategoryAnalysesBySlug(fund, slug) {
      return this.fundCategoryAnalyses(fund)
        .filter(({ category: { pillar } }) => pillar.slug === slug)
        .sort((a, b) => a.category.displayOrder - b.category.displayOrder);
    },
    sortedByTotalAmount(funds) {
      const [stocks, otherFunds] = partition(fund => this.kind(fund) === STOCK_KIND, funds);

      return [...otherFunds, ...stocks];
    },
    toReflectionReport(fund) {
      const {
        fundTotalAnalysis: totalAnalysis,
        fundPillarAnalyses: pillarAnalyses,
        fundCategoryAnalyses: categoryAnalyses,
        ...rest
      } = fund;

      const report = { ...rest, totalAnalysis, pillarAnalyses, categoryAnalyses };

      return report;
    },
    sortedByCompaniesWithAttachedFlagsCount(funds) {
      return [...funds].sort(
        (a, b) => this.companiesWithAttachedFlagsCount(b) - this.companiesWithAttachedFlagsCount(a),
      );
    },
    companiesWithAttachedFlags(fund) {
      const fundTickers = this.fundTickersWithAttachedFlags(fund);

      return fundTickers.map(fundTicker => FundTickerPresenter.company(fundTicker));
    },
  },
);
