import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './useStyles';
import Tooltip from '@material-ui/core/Tooltip';

const CustomizedItalicTick = ({ x, y, payload: { value } }) => {
  const classes = useStyles();
  return (
    <g>
      <Tooltip title="Accoring to European S.E.E. standards" key={{ value }}>
        <text x={x} y={y} textAnchor="middle" fill="#666" transform="translate(0, -22)" className={classes.text}>
          {value}
        </text>
      </Tooltip>
    </g>
  );
};

CustomizedItalicTick.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  payload: PropTypes.shape(),
};

export default CustomizedItalicTick;
