import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  '@global': {
    '.bar': {
      backgroundImage: 'linear-gradient(180deg, #873A9E 0%, #5C3169 100%)',
      boxShadow: '0 5px 20px 0 rgba(0,0,0,0.15)',
    },
  },
  chart: {
    position: 'relative',
    '&::before': {
      content: '""',
      backgroundImage: 'linear-gradient(90deg, #fff 0%, rgba(255,255,255,0.00) 100%)',
      width: 73,
      height: '100%',
      position: 'absolute',
      display: 'none',
      left: 0,
      top: 0,
      pointerEvents: 'none',
    },
    '&::after': {
      content: '""',
      backgroundImage: 'linear-gradient(90deg, rgba(255,255,255,0.00) 0%, #FFFFFF 100%)',
      width: 73,
      height: '100%',
      position: 'absolute',
      display: 'none',
      right: 0,
      top: 0,
      pointerEvents: 'none',
    },
  },
  prevButton: {
    top: '50%',
    left: 0,
    border: 'none',
    zIndex: 5,
    position: 'absolute',
    transform: 'translateY(-50%) rotateZ(-180deg)',
    backgroundColor: 'transparent',
    width: 25,
    height: 42,
    padding: 0,
    cursor: 'pointer',
    display: 'none',
    '&:focus': {
      outline: 'none',
    },
  },
  nextButton: {
    border: 'none',
    backgroundColor: 'transparent',
    position: 'absolute',
    top: '50%',
    right: 0,
    transform: 'translateY(-50%)',
    zIndex: 5,
    cursor: 'pointer',
    display: 'none',
    '&:focus': {
      outline: 'none',
    },
  },
  hasPrevArrow: {
    '& $prevButton': {
      display: 'block',
    },
    '&$chart': {
      '&::before': {
        display: 'block',
      },
    },
  },
  hasNextArrow: {
    '& $nextButton': {
      display: 'block',
    },
    '&$chart': {
      '&::after': {
        display: 'block',
      },
    },
  },
  customTooltip: {
    position: 'absolute',
    backgroundColor: '#232020',
    width: 300,
    borderRadius: 5,
    transform: 'translate(-50%, -100%)',
    marginTop: 10,
    zIndex: 5,
  },
  header: {
    fontFamily: theme.typography.font.ttHovesPro,
    fontWeight: 300,
    fontSize: 18,
    color: '#FFFFFF',
    letterSpacing: 0,
    textAlign: 'left',
    lineHeight: '20px',
    textTransform: 'uppercase',
    marginBottom: 8,
  },
  description: {
    fontFamily: theme.typography.font.ttHovesPro,
    fontWeight: 300,
    fontSize: 12,
    color: '#FFFFFF',
    textAlign: 'left',
    lineHeight: '18px',
    marginBottom: 20,
  },
  link: {
    fontFamily: theme.typography.font.ttHovesPro,
    fontWeight: 700,
    fontSize: 12,
    color: '#FFFFFF',
    letterSpacing: 0,
    lineHeight: '20px',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    backgroundRepeat: 'no-repeat',
    border: 'none',
    overflow: 'hidden',
    outline: 'none',
    padding: 0,
  },
  content: {
    padding: '27px 37px',
    position: 'relative',
    '&:after': {
      content: '""',
      width: 0,
      height: 0,
      borderStyle: 'solid',
      borderWidth: '20px 15px 0 15px',
      borderColor: '#232020 transparent transparent transparent',
      position: 'absolute',
      bottom: 0,
      left: '50%',
      transform: 'translateX(-50%)',
      marginBottom: '-9px',
    },
  },
  chartContainer: {
    display: 'flex',
    '& svg#modal': {
      width: '700px !important',
    },
    '& svg#reports': {
      width: '700px !important',
    },
    [theme.breakpoints.up('md')]: {
      '& svg#modal': {
        width: 'unset !important',
      },
      '& svg#reports': {
        width: 'unset !important',
      },
    },
  },
  yAxis: {
    marginTop: '-20px',
    '@global': {
      '.tick': {
        fontSize: 12,
        color: '#515151',
      },
    },
  },
}));

export default useStyles;
