import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    '& > *:not(:last-child)': {
      marginBottom: 15,
    },
  },
}));

export default useStyles;
