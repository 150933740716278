import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  theme => ({
    steps: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: 8,
      [theme.breakpoints.up('md')]: {
        marginBottom: 18,
      },
    },
    step: {
      fontSize: '13px',
      fontWeight: 500,
      color: '#898B8B',
    },
    activeStep: {
      color: '#232020',
    },
  }),
  { name: 'Progress' },
);

export default useStyles;
