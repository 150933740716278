import React from 'react';
import PropTypes from 'prop-types';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { US_DATE_FORMAT } from 'utils/dateUtils';

import { subDays, subMonths, subWeeks } from 'date-fns';

import useStyles from './useStyles';

const DatePicker = props => {
  const {
    title,
    period,
    startDate,
    endDate,
    onChangePeriod,
    onChangeStartDate,
    onChangeEndDate,
    onChangeSelect,
  } = props;

  const classes = useStyles();

  const handleChangeSelect = event => {
    switch (event.target.value) {
      case 'Day':
        onChangeSelect(subDays(endDate, 1));
        break;
      case 'Week':
        onChangeSelect(subWeeks(endDate, 1));
        break;
      case 'Month':
        onChangeSelect(subMonths(endDate, 1));
        break;
      case '3 months':
        onChangeSelect(subMonths(endDate, 3));
        break;
      default:
        break;
    }

    onChangePeriod(event.target.value);
  };

  return (
    <div className={classes.datePickersContainer}>
      <p className={classes.datePickerTitle}>{title}</p>
      <div className={classes.datePickersBlock}>
        <KeyboardDatePicker
          id="data-start-dialog"
          format={US_DATE_FORMAT}
          classes={{ root: classes.datePickerInput }}
          value={startDate}
          onChange={onChangeStartDate}
          maxDate={endDate}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
        <span className={classes.dash}>&mdash;</span>
        <KeyboardDatePicker
          id="data-end-dialog"
          format={US_DATE_FORMAT}
          classes={{ root: classes.datePickerInput }}
          value={endDate}
          onChange={onChangeEndDate}
          disableFuture
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
        <div className={classes.datePickersTrends}>
          <span>Trend views:</span>
          <Select labelId="date-select-label" id="date-select" value={period} onChange={handleChangeSelect}>
            <MenuItem value="Day">Day</MenuItem>
            <MenuItem value="Week">Week</MenuItem>
            <MenuItem value="Month">Month</MenuItem>
            <MenuItem value="3 months">3 months</MenuItem>
          </Select>
        </div>
      </div>
    </div>
  );
};

DatePicker.propTypes = {
  title: PropTypes.string.isRequired,
  period: PropTypes.string.isRequired,
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
  onChangePeriod: PropTypes.func.isRequired,
  onChangeStartDate: PropTypes.func.isRequired,
  onChangeEndDate: PropTypes.func.isRequired,
  onChangeSelect: PropTypes.func.isRequired,
};

export default DatePicker;
