import React, { useState, useEffect } from 'react';
import { WithPermissions } from 'react-admin';

import CurrentUserRepository from 'repositories/CurrentUserRepository';

import AppRoutes from 'routes/AppRoutes';

const Moneyguide = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [currentUserMeta, setCurrentUserMeta] = useState({});
  const { impersonated } = currentUserMeta;

  const handleCurrentUserMetaLoad = async () => {
    const {
      data: { meta },
    } = await CurrentUserRepository.show();

    setCurrentUserMeta(meta);
    setIsLoading(false);
  };

  useEffect(() => {
    handleCurrentUserMetaLoad();
  }, []);

  if (isLoading) {
    return null;
  }

  if (!impersonated) {
    return 'Not Impersonated';
  }

  return <iframe title="impersonate frame" src={AppRoutes.rootPath()} width="100%" height="960" />;
};

const MoneyguideWithPermissions = props => {
  const { match } = props;

  return <WithPermissions authParams={{ key: match.path }} render={() => <Moneyguide />} />;
};

export default MoneyguideWithPermissions;
