import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  theme => ({
    heading: {
      maxWidth: 840,
      textAlign: 'center',
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '100%',
      [theme.breakpoints.up('md')]: {
        maxWidth: 941,
      },
    },
    intro: {
      fontFamily: theme.typography.font.ttHovesPro,
      fontSize: 19,
      color: '#232020',
      letterSpacing: '0.02em',
      lineHeight: '21px',
      textTransform: 'uppercase',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      fontWeight: 300,
      marginBottom: 5,
    },
    title: {
      fontSize: 46,
      color: '#232020',
      letterSpacing: '0.02em',
      textAlign: 'center',
      lineHeight: '52px',
      fontFamily: theme.typography.font.ttHovesPro,
      fontWeight: 500,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      marginTop: 0,
      marginBottom: 12,
      [theme.breakpoints.up('md')]: {
        fontSize: 53,
        lineHeight: '60px',
      },
    },
    description: {
      fontSize: 19,
      color: '#454545',
      textAlign: 'center',
      lineHeight: '28px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      fontFamily: theme.typography.font.ttHovesPro,
      letterSpacing: '0.01em',
      fontWeight: 300,
      marginTop: 0,
      marginBottom: 0,
    },
  }),
  { name: 'Heading' },
);

export default useStyles;
