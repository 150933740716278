import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  checkbox: {
    width: '100%',
    padding: 0,
    '& img': {
      width: '100%',
      display: 'block',
    },
  },
}));

export default useStyles;
