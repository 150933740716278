import Routes from 'routes/ApiRoutes';
import FetchHelpers from 'utils/FetchHelpers';

export default {
  index() {
    const path = Routes.adminApiV1BaseFundsPath();
    return FetchHelpers.get(path);
  },
  search(params) {
    const path = Routes.adminApiV1TickerSearchIndexPath(params);
    return FetchHelpers.get(path);
  },
};
