import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './useStyles';
import Chip from '@material-ui/core/Chip';
import ValuePresenter from 'presenters/ValuePresenter';
import ReflectionReportPresenter from 'presenters/ReflectionReportPresenter';
import PortfolioCategoryAnalysisPresenter from 'presenters/PortfolioCategoryAnalysisPresenter';

const Value = props => {
  const { categoryValue: value, name, currentReport } = props;
  const classes = useStyles();

  const importanceLevel = ValuePresenter.getLabelFor(value);

  return (
    <div className={classes.root}>
      <div className={classes.title}>Overall {name} is:</div>
      <Chip
        label={PortfolioCategoryAnalysisPresenter.grouppedWeightFullText(currentReport)}
        variant="outlined"
        classes={{ root: classes.chipRootFirst, label: classes.chipLabelFirst }}
      />
      <div className={classes.title}>You value {name} as:</div>
      <Chip
        label={importanceLevel}
        variant="outlined"
        classes={{ root: classes.chipRootSecond, label: classes.chipLabelSecond }}
      />
    </div>
  );
};

Value.propTypes = {
  categoryValue: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  currentReport: ReflectionReportPresenter.shape().isRequired,
};

export default Value;
