import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  autocompleteEndAdornment: {
    display: 'flex',
  },
  tickers: {
    display: 'flex',
    flexDirection: 'column',
  },
  tickersGroup: {
    marginTop: 15,
    verticalAlign: 'center',
    display: 'flex',
    '&:first-child': {
      marginTop: 5,
    },
  },
  tickerChipRoot: {
    height: 40,
    width: 'inherit',
    justifyContent: 'space-between',
  },
  tickerChipLabel: {
    width: '100%',
    textAlign: 'center',
  },
  chipContainer: {
    width: 191,
    marginRight: 14,
  },
  autocompleteOption: {
    textTransform: 'capitalize',
  },
}));

export default useStyles;
