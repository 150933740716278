/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import FundTickersRepository from 'repositories/portfolioAnalysis/FundTickersRepository';
import MultipleAccountFundTickersRepository from 'repositories/customerPortfolioAnalysesBatches/FundTickersRepository';
import { FETCH_STATUSES } from 'presenters/FetchStatusPresenter';

const initialState = {
  fundTickers: {
    status: FETCH_STATUSES.idle,
    data: [],
  },
};

const percentValueHeldSlice = createSlice({
  name: 'percentValueHeld',
  initialState,
  reducers: {
    loadFundTickersStart(state) {
      state.fundTickers.status = FETCH_STATUSES.loading;
    },
    loadFundTickersSuccess(state, { payload }) {
      state.fundTickers.status = FETCH_STATUSES.success;
      state.fundTickers.data = payload.fundTickers;
    },
    loadFundTickersFail(state) {
      state.fundTickers.status = FETCH_STATUSES.failure;
    },
  },
});

export const { loadFundTickersStart, loadFundTickersSuccess, loadFundTickersFail } = percentValueHeldSlice.actions;

export default percentValueHeldSlice.reducer;

export const usePercentValueHeldActions = () => {
  const dispatch = useDispatch();

  const loadFundTickers = (portfolioAnalysisId, customerFundIds) => {
    dispatch(loadFundTickersStart());

    return FundTickersRepository.index(portfolioAnalysisId, {
      q: {
        fund_portfolio_fund_customer_funds_customer_fund_analyses_id_in: customerFundIds,
        type_eq: 'ScorableFundTicker',
        s: 'total_weight desc',
      },
      per_page: 20,
    })
      .then(({ data }) => {
        dispatch(loadFundTickersSuccess(data));
        return Promise.resolve(data);
      })
      .catch(() => dispatch(loadFundTickersFail()));
  };

  const loadMultipleAccountsFundTickers = (portfolioAnalysisBatchId, accountIds, customerFundIds) => {
    dispatch(loadFundTickersStart());

    return MultipleAccountFundTickersRepository.index(portfolioAnalysisBatchId, {
      q: {
        fund_portfolio_fund_customer_funds_customer_fund_analyses_id_in: customerFundIds,
        customer_portfolio_analysis_id_in: accountIds,
        type_eq: 'ScorableFundTicker',
        s: 'total_weight desc',
      },
      per_page: 20,
    })
      .then(({ data }) => {
        dispatch(loadFundTickersSuccess(data));
        return Promise.resolve(data);
      })
      .catch(() => dispatch(loadFundTickersFail()));
  };

  return {
    loadFundTickers,
    loadMultipleAccountsFundTickers,
  };
};
