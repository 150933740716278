import Routes from 'routes/ApiRoutes';
import FetchHelpers from 'utils/FetchHelpers';

export default {
  index(params) {
    const path = Routes.apiV1AdvisorCustomersPath();

    return FetchHelpers.get(path, params);
  },
  show(customerId) {
    const path = Routes.apiV1AdvisorCustomerPath(customerId);

    return FetchHelpers.get(path);
  },
};
