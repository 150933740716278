import React from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import FundsIcon from 'components/Icons/FundsIcon';
import Badge from '@material-ui/core/Badge';

import SavedFundPresenter from 'presenters/SavedFundPresenter';

import clsx from 'clsx';
import useStyles from './useStyles';

const Funds = props => {
  const { savedFunds, onDestroySavedFund } = props;
  const classes = useStyles();
  const rootStyles = clsx({ [classes.hasNotification]: savedFunds.length > 0 });

  const renderSavedFunds = item => {
    return (
      <div key={SavedFundPresenter.id(item)} className={classes.savedFund}>
        <div className={classes.ticker}>{SavedFundPresenter.fundTicker(item)}</div>
        <div className={classes.name}>{SavedFundPresenter.fundName(item)}</div>
        <CancelOutlinedIcon
          onClick={() => onDestroySavedFund(SavedFundPresenter.fundId(item))}
          fontSize="small"
          className={classes.close}
        />
      </div>
    );
  };

  return (
    <PopupState variant="popover" popupId="funds-popover">
      {popupState => (
        <div className={rootStyles}>
          <Button {...bindTrigger(popupState)} className={classes.menu}>
            <Badge
              badgeContent={savedFunds.length}
              color="primary"
              invisible={savedFunds.length === 0}
              className={classes.badge}
              classes={{ badge: classes.badgeCounter }}
            >
              <FundsIcon />
            </Badge>
          </Button>
          {savedFunds.length > 0 && (
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              classes={{ paper: classes.paper }}
            >
              <h3 className={classes.title}>Saved Funds</h3>
              <div className={classes.fundsContainer}>{savedFunds.map(renderSavedFunds)}</div>
            </Popover>
          )}
        </div>
      )}
    </PopupState>
  );
};

Funds.propTypes = {
  savedFunds: PropTypes.arrayOf(SavedFundPresenter.shape()).isRequired,
  onDestroySavedFund: PropTypes.func.isRequired,
};

export default Funds;
