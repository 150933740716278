import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  theme => ({
    main: {
      backgroundColor: theme.palette.background.ivory,
    },
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '& form': {
        display: 'block',
        width: '100%',
      },
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    container: {
      display: 'block',
    },
    manualPortfolioLink: {
      margin: theme.spacing(2, 0, 2),
    },
    error: {
      color: '#f44336',
      fontSize: '16px',
    },
  }),
  { name: 'AdvisorForm' },
);

export default useStyles;
