export default {
  user_type: 'Client',
  user_id: '101',
  institution_id: '2',
  advisor_name: 'Advisor Name',
  advisor_id: '100',
  household_id: '100',
  clients: [
    {
      client_id: '101',
      client_name: 'John',
      birthYear: '1975',
    },
    {
      client_id: '102',
      client_name: 'Jance',
      birthYear: '1978',
    },
  ],
  accounts: [
    {
      account_id: '101',
      account_owner: ['101'],
      account_name: 'John Account',
      total_account_value: '70000.0',
      tickers: [
        {
          ticker: 'MMM',
          tickerAmount: '20000.0',
        },
        {
          ticker: 'FFF',
          tickerAmount: '30000.0',
        },
      ],
      holdings: [
        {
          holding_name: 'Holding Name1',
          holdingAmount: '20000.0',
        },
      ],
      assetClasses: [
        {
          assetClass_id: '3',
          assetClassPercent: '100',
        },
      ],
    },
    {
      account_id: '102',
      account_owner: ['102'],
      account_name: 'Jane Account',
      total_account_value: '80000.0',
      tickers: [
        {
          ticker: 'AAA',
          tickerAmount: '50000.0',
        },
        {
          ticker: 'WWW',
          tickerAmount: '10000.0',
        },
        {
          ticker: 'GGG',
          tickerAmount: '10000.0',
        },
      ],
      holdings: [
        {
          holding_name: 'Holding Name2',
          holdingAmount: '20000.0',
        },
      ],
      asset_classes: [
        {
          assetClass_id: '3',
          assetClassPercent: '100',
        },
      ],
    },
  ],
};
