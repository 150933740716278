import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  () => ({
    button: {
      border: '1px solid #000',
      borderRadius: 5,
      color: '#000',
      fontWeight: 600,
      fontSize: 12,
      letterSpacing: '0.01em',
      padding: '1px 3px 1px 15px',
      textTransform: 'initial',
      width: '100%',
      justifyContent: 'space-between',
    },
    buttonOpened: {
      width: '100%',
      padding: '1px 3px 1px 15px',
      display: 'flex',
      textAlign: 'left',
      marginBottom: 5,
      justifyContent: 'space-between',
    },
    label: {
      fontSize: 12,
      fontWeight: 700,
      letterSpacing: '0.01em',
      whiteSpace: 'nowrap',
      display: 'flex',
      alignItems: 'center',
    },
    labelOpened: {
      minHeight: 'initial',
      fontSize: 12,
      fontWeight: 700,
      letterSpacing: '0.01em',
      textTransform: 'initial',
      color: '#000',
      whiteSpace: 'nowrap',
    },
    content: {
      backgroundColor: '#fff',
      color: '#000',
      border: '1px solid #000',
      borderRadius: '5px',
      position: 'absolute',
      top: 0,
      zIndex: 3,
      left: 0,
      textAlign: 'left',
      overflow: 'hidden',
      width: '100%',
    },
    dropdown: {
      position: 'relative',
    },
    option: {
      minHeight: 'initial',
      fontSize: 12,
      fontWeight: 700,
      letterSpacing: '0.01em',
      textTransform: 'initial',
      padding: '2px 10px 2px 15px',
      color: '#000',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      width: '100%',
      textOverflow: 'ellipsis',
      display: 'flex',
      justifyContent: 'flex-start',
    },
    text: {
      overflow: 'hidden',
      display: 'inline-block',
      textOverflow: 'ellipsis',
    },
    icon: {
      height: 26,
    },
    buttonRoot: {
      paddingLeft: 0,
      width: '100%',
      textAlign: 'left',
    },
    checked: {
      '& span': {
        color: '#000',
      },
    },
    checkbox: {
      marginRight: 5,
    },
    disabledLabel: {
      color: 'grey',
    },
  }),
  { name: 'MultipleAccountsDropdown' },
);

export default useStyles;
