import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';
import Button from '@material-ui/core/Button';

import useStyles from './useStyles';
import LensPresenter from 'presenters/LensPresenter';

const Info = props => {
  const { lens, onClose, onSelect, selected } = props;
  const classes = useStyles();
  if (isEmpty(lens)) {
    return null;
  }

  const handleSelect = () => {
    onSelect(lens);
    onClose();
  };

  const { items, title } = LensPresenter.getDescriptionItems(lens);

  return (
    <div className={classes.root}>
      <div className={classes.title}>{LensPresenter.name(lens)}</div>
      <div className={classes.description}>{LensPresenter.extendedDescription(lens)}</div>
      <img src={LensPresenter.imageUrl(lens)} className={classes.image} width="250" height="250" alt="" />
      <div className={classes.itemsTitle}>{title}:</div>
      <ul className={classes.descriptionItems}>
        {items.map(item => (
          <li className={classes.item} key={item}>
            {item}
          </li>
        ))}
      </ul>
      <div className={classes.actions}>
        <div className={classes.buttonWrapper}>
          <Button
            variant={selected ? 'contained' : 'outlined'}
            onClick={handleSelect}
            color={selected ? 'primary' : 'default'}
            classes={{ root: classes.outlinedButtonRoot, label: classes.outlinedButtonLabel }}
          >
            {selected ? 'Selected' : 'Select'} {LensPresenter.name(lens)}
          </Button>
        </div>
        <div className={classes.buttonWrapper}>
          <Button variant="text" size="small" onClick={onClose} classes={{ root: classes.buttonRoot }}>
            Close
          </Button>
        </div>
      </div>
    </div>
  );
};

Info.propTypes = {
  lens: LensPresenter.shape().isRequired,
  onClose: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
};

export default Info;
