import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  theme => ({
    root: {
      paddingRight: 15,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    title: {
      fontFamily: theme.typography.font.ttHovesPro,
      fontWeight: 'normal',
      fontSize: 40,
      textAlign: 'center',
      marginBottom: 2,
      marginTop: 1,
    },
    description: {
      fontFamily: theme.typography.font.ttHovesPro,
      fontWeight: 300,
      fontSize: 16,
      letterSpacing: '0.01em',
      color: '#000',
      textAlign: 'center',
      lineHeight: '22px',
      marginBottom: 33,
      maxWidth: 536,
    },
    image: {
      borderRadius: '50%',
    },
    itemsTitle: {
      fontFamily: theme.typography.font.ttHovesPro,
      fontSize: 19,
      fontWeight: 300,
      margin: '40px 0 19px 0',
      letterSpacing: '-0.01em',
    },
    descriptionItems: {
      fontSize: 16,
      fontWeight: 300,
      lineHeight: '25px',
      padding: 0,
      marginTop: 0,
      marginLeft: 9,
    },
    item: {
      listStyle: 'none',
      fontFamily: theme.typography.font.ttHovesPro,
      fontWeight: 300,
      fontSize: 16,
      letterSpacing: '0.01em',
      position: 'relative',
      marginBottom: 1,
      '&:before': {
        content: '"·"',
        fontSize: 30,
        position: 'absolute',
        top: '50%',
        left: 0,
        marginLeft: -26,
        transform: 'translateY(-50%)',
      },
    },
    actions: {
      marginTop: 24,
      display: 'flex',
      flexDirection: 'column',
    },
    buttonWrapper: {
      alignSelf: 'center',
      marginTop: 13,
      textTransform: 'uppercase',
    },
    buttonRoot: {
      fontSize: 12,
      fontFamily: theme.typography.font.ttHovesPro,
    },
    outlinedButtonRoot: {
      fontSize: 12,
      letterSpacing: '0.02em',
      paddingLeft: 53,
      paddingRight: 53,
    },
    outlinedButtonLabel: {
      letterSpacing: '0.02em',
    },
  }),
  { name: 'Info' },
);

export default useStyles;
