import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './useStyles';

const BlankButton = props => {
  const { children, onClick } = props;
  const classes = useStyles();

  return (
    <button type="button" className={classes.root} onClick={onClick}>
      {children}
    </button>
  );
};

BlankButton.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func.isRequired,
};
BlankButton.defaultProps = {
  children: null,
};

export default BlankButton;
