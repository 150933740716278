import React from 'react';
import { Filter, TextInput, List, Datagrid, TextField } from 'react-admin';

import ExportsRepository from 'repositories/admin/gics_sub_industry/ExportsRepository';

import GicsSubIndustryActions from './GicsSubIndustryActions';

const GicsSubIndustryFilter = props => (
  <Filter {...props}>
    <TextInput label="ID" source="idEq" alwaysOn />
    <TextInput label="Name" source="nameEq" alwaysOn />
    <TextInput label="Gics Sector Id" source="gics_sector_idEq" alwaysOn />
  </Filter>
);

const GicsSubIndustryList = props => {
  const handleExport = () => {
    ExportsRepository.download();
  };

  return (
    <List
      filters={<GicsSubIndustryFilter />}
      actions={<GicsSubIndustryActions handleExportButtonClick={handleExport} />}
      {...props}
    >
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="code" />
        <TextField source="gics_sector_id" />
      </Datagrid>
    </List>
  );
};

export default GicsSubIndustryList;
