import React from 'react';
import { isEmpty, sum, pluck, map } from 'ramda';
import PropTypes from 'prop-types';
import { List, ListItem, ListItemText } from '@material-ui/core';
import clsx from 'clsx';

import Loader from 'components/Loader';
import MultipleAccountsDropdown from 'components/MultipleAccountsDropdown';
import GroupedAssetClassesCategoriesPresenter from 'presenters/GroupedAssetClassesCategories';
import {
  POINTED_THOUSAND_SEPARATOR_FORMAT,
  getPointedFormattedPercent,
  getPointedIntPercent,
  getFormattedNumber,
} from 'utils/numberHelpers';
import Tree from './components/Tree';

import useStyles from './useStyles';

const AssetClasses = props => {
  const {
    selectedAccountIds,
    accountOptions,
    selectedAssetClass,
    onChangeSelectedAccounts,
    onChangeSelectedAssetClass,
    batchId,
    groupedAssetClassesCategories,
    isGroupedAssetClassesCategoriesLoading,
  } = props;

  const classes = useStyles();

  const totalAssetClassesAmount = sum(pluck('totalAmount', groupedAssetClassesCategories));
  const totalPercent = sum(pluck('percent', groupedAssetClassesCategories));
  const totalMatchScore = sum(
    map(item => GroupedAssetClassesCategoriesPresenter.weightedAverageSummand(item), groupedAssetClassesCategories),
  );

  return (
    <div className={classes.container}>
      <div className={classes.head}>
        <div className={classes.title}>Asset Classes</div>
        {!isEmpty(accountOptions) && (
          <div className={classes.dropdown}>
            <MultipleAccountsDropdown
              onChange={onChangeSelectedAccounts}
              selected={selectedAccountIds}
              options={accountOptions}
            />
          </div>
        )}
      </div>

      {isGroupedAssetClassesCategoriesLoading ? (
        <div className={classes.loader}>
          <Loader caption="Loading..." />
        </div>
      ) : (
        <>
          <Tree
            data={groupedAssetClassesCategories}
            selected={selectedAssetClass}
            onChangeSelected={onChangeSelectedAssetClass}
            batchId={batchId}
            selectedAccountIds={selectedAccountIds}
          />
          <List disablePadding className={classes.list}>
            <ListItem className={clsx(classes.listItem, classes.listHead)}>
              <ListItemText disableTypography className={clsx(classes.bold, classes.listItemText)}>
                Total
              </ListItemText>
              <ListItemText
                disableTypography
                style={{ gridColumn: '2 / span 2' }}
                className={clsx(classes.bold, classes.listItemText)}
              >
                Total (%)
              </ListItemText>
              <ListItemText
                disableTypography
                style={{ gridColumn: '4 / span 2' }}
                className={clsx(classes.bold, classes.listItemText)}
              >
                Weighted Average
              </ListItemText>
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemText disableTypography className={clsx(classes.bold, classes.listItemText)}>
                {getFormattedNumber(totalAssetClassesAmount, POINTED_THOUSAND_SEPARATOR_FORMAT)}
              </ListItemText>
              <ListItemText
                disableTypography
                className={clsx(classes.bold, classes.listItemText)}
                style={{ gridColumn: '2 / span 2' }}
              >
                {getPointedFormattedPercent(totalPercent)}
              </ListItemText>
              <ListItemText
                disableTypography
                className={clsx(classes.bold, classes.listItemText)}
                style={{ gridColumn: '4 / span 2' }}
              >
                {getPointedIntPercent(totalMatchScore)}
              </ListItemText>
            </ListItem>
          </List>
        </>
      )}
    </div>
  );
};

AssetClasses.propTypes = {
  onChangeSelectedAccounts: PropTypes.func.isRequired,
  onChangeSelectedAssetClass: PropTypes.func.isRequired,
  batchId: PropTypes.number.isRequired,
  groupedAssetClassesCategories: PropTypes.arrayOf(GroupedAssetClassesCategoriesPresenter.shape()).isRequired,
  isGroupedAssetClassesCategoriesLoading: PropTypes.bool.isRequired,
  selectedAccountIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  selectedAssetClass: PropTypes.number,
  accountOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.number,
      label: PropTypes.string,
    }),
  ).isRequired,
};

export default AssetClasses;
