import { head } from 'ramda';

import Presenter from 'utils/PropTypesPresenter';
import FundPresenter from 'presenters/FundPresenter';

export default new Presenter(
  {
    fund: FundPresenter.shape(),
  },
  {
    customerFundAnalysis(fundPortfolio) {
      const fund = this.fund(fundPortfolio);
      const fundAnalysis = head(FundPresenter.customerFundAnalyses(fund));

      return fundAnalysis;
    },
  },
);
