import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  theme => ({
    heading: {
      width: '100%',
      maxWidth: 730,
      margin: '0 auto',
      marginBottom: 35,
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      pageBreakBefore: 'always',
    },
    title: {
      fontFamily: theme.typography.font.ttHovesPro,
      fontWeight: 700,
      fontSize: 18,
      color: '#232020',
      textAlign: 'center',
      lineHeight: '20px',
      marginBottom: 12,
      width: '100%',
      maxWidth: 430,
      margin: '0 auto',
    },
    description: {
      fontFamily: theme.typography.font.ttHovesPro,
      fontWeight: 300,
      fontSize: 16,
      color: '#232020',
      textAlign: 'center',
      lineHeight: '24px',
    },
    panelsContainer: {
      position: 'relative',
      paddingBottom: '50%',
      display: 'inline-flex',
      flexWrap: 'wrap',
      width: '100%',
      justifyContent: 'center',
      [theme.breakpoints.up('md')]: {
        width: 1115,
        marginRight: 'auto',
        marginLeft: 'auto',
      },
      '@media print': {
        paddingBottom: 0,
      },
    },
    panelsWrapper: {
      textAlign: 'center',
    },
    panel: {
      width: 167,
      height: 167,
      marginRight: 16,
      marginBottom: 12,
      '@media print': {
        width: 140,
        height: 140,
        colorAdjust: 'exact',
        '-webkit-print-color-adjust': 'exact',
        '& > img': {
          width: 140,
          height: 140,
        },
      },
    },
  }),
  { name: 'Panels' },
);

export default useStyles;
