import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  theme => ({
    root: {
      width: '100%',
      paddingTop: 109,
      fontFamily: theme.typography.font.ttHovesPro,
    },
    warning: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      fontSize: 18,
    },
    title: {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: 52,
      color: '#232020',
      textAlign: 'center',
      marginBottom: 27,
    },
    remark: {
      fontSize: 30,
    },
    PDFIcon: {
      marginLeft: 25,
      display: 'inline-block',
      color: '#000',
      '@media print': {
        visibility: 'hidden',
      },
    },
    icon: {
      fontSize: 40,
    },
    select: {
      width: '100%',
      marginBottom: 30,
      padding: [0, 25],
    },
    chartContainer: {
      padding: '0 74px',
    },
    chartDescription: {
      fontWeight: 700,
      fontSize: 12,
      letterSpacing: '0.02em',
      color: '#232020',
    },
    divider: {
      marginBottom: 45,
      width: '100%',
      maxWidth: 800,
      margin: '0 auto',
    },
    chart: {
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '85%',
      [theme.breakpoints.up('md')]: {
        width: 1000,
      },
    },
    caption: {
      fontWeight: 300,
      fontSize: 12,
      letterSpacing: '0.01em',
      color: '#515151',
      textAlign: 'left',
      marginRight: 14,
    },
    label: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: '-26px',
    },
    hidden: {
      display: 'none',
    },
    notVisible: {
      visibility: 'hidden',
    },
  }),
  { name: 'PercentValueHeld' },
);

export default useStyles;
