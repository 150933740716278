import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.divider,
    '&:last-of-type': {
      marginBottom: 0,
      paddingBottom: 0,
      border: 'none',
    },
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '16px',
    marginTop: '16px',
  },
  input: {
    backgroundColor: theme.palette.common.white,
  },
  select: {
    backgroundColor: theme.palette.common.white,
  },
  controlGroup: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

export default useStyles;
