import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const FinanceIcon = props => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M58 30c0 15.464-12.536 28-28 28S2 45.464 2 30 14.536 2 30 2s28 12.536 28 28zm2 0c0 16.569-13.431 30-30 30C13.431 60 0 46.569 0 30 0 13.431 13.431 0 30 0c16.569 0 30 13.431 30 30zm-29.98-1c.484 0 .782-.322.782-.907v-1.229C33.395 26.578 35 24.878 35 22.387c0-2.147-1.132-3.456-3.611-4.156l-2.305-.655c-1.306-.378-2.006-1.217-2.006-2.376 0-1.504 1.132-2.503 2.87-2.503 1.348 0 2.243.563 3.149 1.952.38.585.607.757.956.757.453 0 .741-.333.741-.86 0-.62-.36-1.378-.967-2.021-.73-.781-1.77-1.298-2.932-1.47V9.907c0-.586-.309-.907-.782-.907-.483 0-.782.321-.782.907v1.102c-2.48.241-4.043 1.906-4.043 4.294 0 2.044 1.173 3.513 3.292 4.11l2.346.666c1.595.46 2.263 1.183 2.263 2.446 0 1.653-1.131 2.64-3.055 2.64-1.502 0-2.541-.585-3.478-1.974-.442-.643-.576-.735-.884-.735-.463 0-.772.356-.772.93 0 .666.401 1.435 1.09 2.078.803.746 1.914 1.228 3.149 1.378v1.251c0 .585.288.907.782.907zm10.093 1a.993.993 0 00-.986 1c0 .552.441 1 .986 1h4.414l-9.567 9.126-7.167-5.854a.976.976 0 00-1.353 0l-13.13 11.5a1.01 1.01 0 00-.041 1.414.976.976 0 001.393.042l12.454-10.854 7.167 5.854a.976.976 0 001.352 0l10.393-9.915V38c0 .552.442 1 .986 1A.993.993 0 0048 38v-7a1.004 1.004 0 00-.595-.918.97.97 0 00-.39-.082h-6.902z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default FinanceIcon;
