import React from 'react';
import PropTypes from 'prop-types';
import Slug from 'components/Slug';
import Chip from '@material-ui/core/Chip';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import PillarPresenter from 'presenters/PillarPresenter';
import FundPillarAnalysisPresenter from 'presenters/FundPillarAnalysisPresenter';
import CustomerCategoryValuePresenter from 'presenters/CustomerCategoryValuePresenter';
import CustomerCategoryValue from './components/CustomerCategoryValue';
import ReflectionReportPresenter from 'presenters/ReflectionReportPresenter';

import useStyles from './useStyles';

const Pillar = props => {
  const { fundPillarAnalysis, currentReport, customerCategoryValues } = props;

  const classes = useStyles();

  const id = FundPillarAnalysisPresenter.id(fundPillarAnalysis);
  const pillar = FundPillarAnalysisPresenter.pillar(fundPillarAnalysis);
  const filteredCategoryAnalyses = ReflectionReportPresenter.categoryAnalysesBySlug(
    currentReport,
    PillarPresenter.slug(pillar),
  );

  const filteredCustomerCategoryValues = CustomerCategoryValuePresenter.getCustomerCategoryValuesForPillar(
    PillarPresenter.id(pillar),
    customerCategoryValues,
  );

  return (
    <Accordion className={classes.panel} defaultExpanded>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`environment-panel-${id}-content`}
        id={`environment-panel-${id}-header`}
        classes={{ expandIcon: classes.expandIcon, root: classes.summaryRoot }}
      >
        <table className={classes.summaryTable}>
          <tbody className={classes.body}>
            <tr className={classes.header}>
              <td className={classes.name}>
                <div className={classes.pillar}>
                  <Slug slug={PillarPresenter.slug(pillar)} size="medium" />
                  <span className={classes.pillarTitle}>{PillarPresenter.name(pillar)}</span>
                </div>
              </td>
              <td className={classes.tableValue}>
                <Chip
                  label={FundPillarAnalysisPresenter.grouppedWeightFullText(fundPillarAnalysis)}
                  variant="outlined"
                  classes={{ root: classes.chipRoot }}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </AccordionSummary>
      <AccordionDetails>
        <table className={classes.expansionTable}>
          <tbody className={classes.body}>
            <tr className={classes.title}>
              <td className={classes.name}>Policies & Practices</td>
              <td className={classes.tableValue}>Values Aligned?</td>
              <td className={classes.actionWrapper}>
                <div className={classes.action}>More info</div>
              </td>
            </tr>
            {filteredCategoryAnalyses.map(categoryAnalysis => (
              <CustomerCategoryValue
                key={categoryAnalysis.category.id}
                customerCategories={filteredCustomerCategoryValues}
                currentReport={categoryAnalysis}
              />
            ))}
          </tbody>
        </table>
      </AccordionDetails>
    </Accordion>
  );
};

Pillar.propTypes = {
  fundPillarAnalysis: FundPillarAnalysisPresenter.shape().isRequired,
  customerCategoryValues: PropTypes.arrayOf(CustomerCategoryValuePresenter.shape()).isRequired,
  currentReport: ReflectionReportPresenter.shape().isRequired,
};

export default Pillar;
