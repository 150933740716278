import React from 'react';
import PropTypes from 'prop-types';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { subMonths, subDays } from 'date-fns';

import useStyles from './useStyles';

const DateSelect = props => {
  const { period, toDate, onChangePeriod, onChangeFromDate } = props;

  const classes = useStyles();

  const handleChangeSelect = event => {
    switch (event.target.value) {
      case '3 months':
        onChangeFromDate(subMonths(toDate, 2));
        break;
      case '6 months':
        onChangeFromDate(subMonths(toDate, 5));
        break;
      case '12 months':
        onChangeFromDate(subMonths(toDate, 11));
        break;
      case '1 day':
        onChangeFromDate(subDays(toDate, 1));
        break;
      default:
        break;
    }

    onChangePeriod(event.target.value);
  };

  return (
    <div className={classes.datePickersContainer}>
      <div className={classes.datePickersTrends}>
        <span>Trend views:</span>
        <Select labelId="date-select-label" id="date-select" value={period} onChange={handleChangeSelect}>
          <MenuItem value="3 months">3 months</MenuItem>
          <MenuItem value="6 months">6 months</MenuItem>
          <MenuItem value="12 months">12 months</MenuItem>
          <MenuItem value="1 day">1 day</MenuItem>
        </Select>
      </div>
    </div>
  );
};

DateSelect.propTypes = {
  period: PropTypes.string.isRequired,
  toDate: PropTypes.instanceOf(Date).isRequired,
  onChangePeriod: PropTypes.func.isRequired,
  onChangeFromDate: PropTypes.func.isRequired,
};

export default DateSelect;
