import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

import useStyles from './useStyles';
import iconEnergyUse from 'assets/iconEnergyUse.svg';

const UpdateInfo = props => {
  const { onClose, onUpdate } = props;
  const classes = useStyles();

  const handleSelect = () => {
    onUpdate();
    onClose();
  };

  return (
    <div className={classes.root}>
      <div className={classes.title}>Refresh Report?</div>
      <div className={classes.description}>
        Would you like to update your Reflection Report <br />
        with recent changes to your portfolio?
      </div>
      <img src={iconEnergyUse} className={classes.image} alt="Icon energy use" width="250" height="250" />
      <div className={classes.actions}>
        <div className={classes.buttonWrapper}>
          <Button variant="outlined" size="small" onClick={handleSelect} classes={{ root: classes.buttonRoot }}>
            Yes
          </Button>
        </div>
        <div className={classes.buttonWrapper}>
          <Button variant="text" size="small" onClick={onClose} classes={{ root: classes.buttonRoot }}>
            No
          </Button>
        </div>
      </div>
    </div>
  );
};

UpdateInfo.propTypes = {
  onClose: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default UpdateInfo;
