import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  selectContainer: {
    marginTop: 10,
    width: 250,
  },
  actions: {
    display: 'flex',
    justifyContent: 'center',
    margin: '30px 0 50px 0',
  },
}));

export default useStyles;
