import React from 'react';
import PropTypes from 'prop-types';
import Value from './components/Value';
import Button from '@material-ui/core/Button';
import useStyles from './useStyles';
import CategoryPresenter from 'presenters/CategoryPresenter';
import ReflectionReportPresenter from 'presenters/ReflectionReportPresenter';
import CustomerCategoryValuePresenter from 'presenters/CustomerCategoryValuePresenter';

const CategoryInfo = props => {
  const { onClose, customerCategory, currentReport } = props;
  const classes = useStyles();

  const { category } = currentReport;
  const value = CustomerCategoryValuePresenter.value(customerCategory);

  return (
    <div className={classes.root}>
      <div className={classes.title}>{CategoryPresenter.name(category)}</div>
      <div className={classes.description}>{CategoryPresenter.extendedDescription(category)}</div>
      <img
        src={CategoryPresenter.imageUrl(category)}
        className={classes.image}
        width="250"
        height="250"
        alt="Category icon"
      />
      <div className={classes.value}>
        <Value categoryValue={value} name={CategoryPresenter.name(category)} currentReport={currentReport} />
      </div>
      <Button
        variant="outlined"
        classes={{
          root: classes.buttonRoot,
          label: classes.buttonLabel,
        }}
        onClick={onClose}
      >
        Close
      </Button>
    </div>
  );
};

CategoryInfo.propTypes = {
  onClose: PropTypes.func.isRequired,
  customerCategory: PropTypes.shape().isRequired,
  currentReport: ReflectionReportPresenter.shape().isRequired,
};

export default CategoryInfo;
