import PropTypes from 'prop-types';
import React, { useState } from 'react';

import Button from '@material-ui/core/Button';
import RefreshIcon from '@material-ui/icons/Refresh';

import FundPortfoliosRepository from 'repositories/admin/fund/FundPortfoliosRepository';

const FundRefreshPortfolio = props => {
  const { record } = props;

  const [isRefreshed, setIsRefreshed] = useState(false);

  const handlePortfolioRefresh = () => {
    FundPortfoliosRepository.refresh(record.id);
    setIsRefreshed(true);
  };

  const label = isRefreshed ? 'Refreshed' : 'Refresh Portfolio';

  return (
    <Button
      onClick={handlePortfolioRefresh}
      disabled={isRefreshed}
      color="primary"
      size="small"
      startIcon={<RefreshIcon />}
    >
      {label}
    </Button>
  );
};

FundRefreshPortfolio.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
};

export default FundRefreshPortfolio;
