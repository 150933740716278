import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { isNil, map, filter, isEmpty, values } from 'ramda';
import clsx from 'clsx';

import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';

import RecommendedFundPresenter from 'presenters/RecommendedFundPresenter';
import { LPL_SLUG } from 'presenters/PartnerPresenter';
import UserPresenter from 'presenters/UserPresenter';
import { RecommendedFundsView, RecommendedFundsKind } from 'enums/RecommendedFunds';

import Row from './components/Row';

import useStyles from './useStyles';
import Dropdown from 'components/Dropdown';

const RecommendedFunds = props => {
  const { recommendedFunds, selectedAssetClass, kind, setKind, isLoading } = props;

  const currentUser = useSelector(state => state.AppSlice.currentUser.data);
  const isShowSelectKind = UserPresenter.isAdvisor(currentUser) && UserPresenter.partnerSlug(currentUser) === LPL_SLUG;

  const options = values(RecommendedFundsView).map((item, key) => ({ value: item, key }));
  const initialRecommendedFundsView = options.find(({ value }) => value === RecommendedFundsView[kind]);

  const [currentView, setCurrentView] = useState(initialRecommendedFundsView);
  const onChangeCurrentView = view => {
    setCurrentView(view);
    if (view.value === RecommendedFundsView.fund) {
      setKind(RecommendedFundsKind.fund);
    } else {
      setKind(RecommendedFundsKind.model);
    }
  };

  const classes = useStyles();

  const filteredRecommendedFunds = filter(
    recommendedFund => RecommendedFundPresenter.assetClassId(recommendedFund) === selectedAssetClass,
    recommendedFunds,
  );

  const recommendationContainerClassNames = clsx({
    [classes.fullHeight]: isNil(selectedAssetClass) && currentView.value === RecommendedFundsView.fund,
    [classes.recommendationContainer]: true,
  });

  const renderRecommendedFundRow = recommendedFund => {
    return <Row recommendedFund={recommendedFund} currentView={currentView} key={recommendedFund.id} />;
  };

  const renderEmptyState = () => <div className={classes.emptyText}>Select an Asset Class to see recommendations.</div>;

  const renderFundsTable = () =>
    isNil(selectedAssetClass) ? (
      renderEmptyState()
    ) : (
      <Table className={classes.table}>
        <TableHead>
          <TableRow className={classes.headRow}>
            <TableCell width="5%" />
            <TableCell className={classes.centerCell} width="10%">
              Ticker
            </TableCell>
            <TableCell className={classes.centerCell} width="20%">
              Investment Name
            </TableCell>
            <TableCell className={classes.centerCell} width="10%">
              Match Score
            </TableCell>
            <TableCell className={classes.centerCell} width="10%">
              1 Year
            </TableCell>
            <TableCell className={classes.centerCell} width="10%">
              3 Year
            </TableCell>
            <TableCell className={classes.centerCell} width="10%">
              5 Year
            </TableCell>
            <TableCell className={classes.centerCell} width="10%">
              STD
            </TableCell>
            <TableCell className={classes.centerCell} width="15%">
              Last updated at
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading ? (
            <TableRow>
              <TableCell colSpan={8}>Loading...</TableCell>
            </TableRow>
          ) : (
            <>
              {isEmpty(filteredRecommendedFunds) && (
                <TableRow>
                  <TableCell colSpan={8} />
                </TableRow>
              )}
              {map(renderRecommendedFundRow, filteredRecommendedFunds)}
            </>
          )}
        </TableBody>
      </Table>
    );

  const renderModelsTable = () => (
    <Table className={classes.table}>
      <TableHead>
        <TableRow className={classes.headRow}>
          <TableCell />
          <TableCell className={classes.centerCell}>Model</TableCell>
          <TableCell className={classes.centerCell}>Match %</TableCell>
          <TableCell className={classes.centerCell}>Cash %</TableCell>
          <TableCell className={classes.centerCell}>Bond %</TableCell>
          <TableCell className={classes.centerCell}>Stock %</TableCell>
          <TableCell className={classes.centerCell}>Alts %</TableCell>
          <TableCell className={classes.centerCell}>CMDTY %</TableCell>
          <TableCell className={classes.centerCell}>1 Year</TableCell>
          <TableCell className={classes.centerCell}>3 Year</TableCell>
          <TableCell className={classes.centerCell}>5 Year</TableCell>
          <TableCell className={classes.centerCell}>STD</TableCell>
          <TableCell className={classes.centerCell}>Last updated at</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {isLoading ? (
          <TableRow>
            <TableCell colSpan={8}>Loading...</TableCell>
          </TableRow>
        ) : (
          <>
            {isEmpty(recommendedFunds) && (
              <TableRow>
                <TableCell colSpan={8} />
              </TableRow>
            )}
            {map(renderRecommendedFundRow, recommendedFunds)}
          </>
        )}
      </TableBody>
    </Table>
  );

  const renderContent = () =>
    currentView.value === RecommendedFundsView.model ? renderModelsTable() : renderFundsTable();

  return (
    <div className={classes.recommendedFundsContainer}>
      <div className={classes.head}>
        <div className={classes.title}>Recommendations</div>
        {isShowSelectKind && (
          <div className={classes.dropdownContainer}>
            <Dropdown selected={currentView} options={options} onChange={onChangeCurrentView} />
          </div>
        )}
      </div>
      <div className={recommendationContainerClassNames}>{renderContent()}</div>
    </div>
  );
};

RecommendedFunds.propTypes = {
  selectedAssetClass: PropTypes.number.isRequired,
  recommendedFunds: PropTypes.arrayOf(RecommendedFundPresenter.shape()).isRequired,
  kind: PropTypes.string.isRequired,
  setKind: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default RecommendedFunds;
