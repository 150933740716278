import { useSelector } from 'react-redux';

import { useMultipleAccountReflectionReportActions } from 'store/MultipleAccountsReflectionReportSlice';
import getFetchStatus from 'utils/getFetchStatus';

export default () => {
  const {
    loadCustomerFundAnalyses,
    loadCustomerFundAnalysesOptions,
    loadFullPortfolioAnalysis,
    loadFlaggedTickers,
    loadInsights,
    loadMetrics,
    loadMixedReport,
    loadReportMetrics,
    loadScoredCompanies,
    loadScoredCompany,
    loadScoredCompaniesOptions,
    loadInsightsScoredCompanies,
    loadInsightsScoredCompaniesOptions,
    resetToInitialState,
  } = useMultipleAccountReflectionReportActions();

  const customerFundAnalyses = useSelector(
    state => state.MultipleAccountsReflectionReportSlice.customerFundAnalyses.data,
  );
  const customerFundAnalysesOptions = useSelector(
    state => state.MultipleAccountsReflectionReportSlice.customerFundAnalysesOptions.data,
  );
  const portfolioAnalysis = useSelector(state => state.MultipleAccountsReflectionReportSlice.portfolioAnalysis.data);
  const flaggedTickers = useSelector(state => state.MultipleAccountsReflectionReportSlice.flaggedTickers.data);
  const fundScores = useSelector(state => state.MultipleAccountsReflectionReportSlice.fundScores.data);
  const customerFundAnalysesMetrics = useSelector(
    state => state.MultipleAccountsReflectionReportSlice.customerFundAnalysesMetrics.data,
  );
  const customerFundsReportMetrics = useSelector(
    state => state.MultipleAccountsReflectionReportSlice.customerFundsReportMetrics.data,
  );

  const scoredCompanies = useSelector(state => state.MultipleAccountsReflectionReportSlice.scoredCompanies.data);

  const scoredCompany = useSelector(state => state.MultipleAccountsReflectionReportSlice.scoredCompany.data);
  const scoredCompanyStatus = useSelector(state => state.MultipleAccountsReflectionReportSlice.scoredCompany.status);
  const scoredCompanyStatuses = getFetchStatus(scoredCompanyStatus);

  const scoredCompaniesOptions = useSelector(
    state => state.MultipleAccountsReflectionReportSlice.scoredCompaniesOptions.data,
  );
  const scoredCompaniesOptionsStatus = useSelector(
    state => state.MultipleAccountsReflectionReportSlice.scoredCompaniesOptions.status,
  );
  const scoredCompaniesOptionsStatuses = getFetchStatus(scoredCompaniesOptionsStatus);

  const insightsScoredCompanies = useSelector(
    state => state.MultipleAccountsReflectionReportSlice.insightsScoredCompanies.data,
  );
  const mixedReportStatus = useSelector(
    state => state.MultipleAccountsReflectionReportSlice.mixedPortfolioAnalysis.status,
  );
  const customerFundsReportMetricsLoadingStatus = useSelector(
    state => state.MultipleAccountsReflectionReportSlice.customerFundsReportMetrics.status,
  );

  const insightsScoredCompaniesStatus = useSelector(
    state => state.MultipleAccountsReflectionReportSlice.insightsScoredCompanies.status,
  );
  const insightsScoredCompaniesStatuses = getFetchStatus(insightsScoredCompaniesStatus);

  const insightsScoredCompaniesOptions = useSelector(
    state => state.MultipleAccountsReflectionReportSlice.insightsScoredCompaniesOptions.data,
  );
  const insightsScoredCompaniesOptionsStatus = useSelector(
    state => state.MultipleAccountsReflectionReportSlice.insightsScoredCompaniesOptions.status,
  );
  const insightsScoredCompaniesOptionsStatuses = getFetchStatus(insightsScoredCompaniesOptionsStatus);

  const scoredCompaniesStatus = useSelector(
    state => state.MultipleAccountsReflectionReportSlice.scoredCompanies.status,
  );
  const scoredCompaniesStatuses = getFetchStatus(scoredCompaniesStatus);

  const customerFundAnalysesMetricsStatus = useSelector(
    state => state.MultipleAccountsReflectionReportSlice.customerFundAnalysesMetrics.status,
  );
  const customerFundAnalysesMetricsStatuses = getFetchStatus(customerFundAnalysesMetricsStatus);

  return {
    loadCustomerFundAnalyses,
    loadCustomerFundAnalysesOptions,
    loadFullPortfolioAnalysis,
    loadFlaggedTickers,
    loadInsights,
    loadMetrics,
    loadMixedReport,
    loadReportMetrics,
    loadScoredCompanies,
    loadScoredCompany,
    loadScoredCompaniesOptions,
    loadInsightsScoredCompanies,
    loadInsightsScoredCompaniesOptions,
    resetToInitialState,
    customerFundAnalyses,
    customerFundAnalysesOptions,
    portfolioAnalysis,
    flaggedTickers,
    fundScores,
    customerFundAnalysesMetrics,
    customerFundsReportMetrics,
    scoredCompanies,
    scoredCompany,
    scoredCompanyStatuses,
    scoredCompaniesOptions,
    scoredCompaniesOptionsStatuses,
    insightsScoredCompanies,
    insightsScoredCompaniesOptions,
    insightsScoredCompaniesOptionsStatuses,
    mixedReportStatus,
    customerFundsReportMetricsLoadingStatus,
    insightsScoredCompaniesStatuses,
    scoredCompaniesStatuses,
    customerFundAnalysesMetricsStatuses,
  };
};
