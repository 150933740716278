import { isEmpty, either, isNil, is, when, pipe, map, reject } from 'ramda';

const isArrayOrObject = either(is(Array), is(Object));

export const isBlank = either(isEmpty, isNil);

export const compactParams = when(
  isArrayOrObject,
  pipe(
    map(a => compactParams(a)),
    reject(isBlank),
  ),
);
