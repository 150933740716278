import Routes from 'routes/ApiRoutes';
import FetchHelpers from 'utils/FetchHelpers';

export default {
  create(params) {
    const path = Routes.apiV1AdvisorRecommendationsPath();
    return FetchHelpers.post(path, params);
  },
  show(recommendationId) {
    const path = Routes.apiV1AdvisorRecommendationPath(recommendationId);
    return FetchHelpers.get(path);
  },
};
