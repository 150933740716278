import Routes from 'routes/ApiRoutes';
import FetchHelpers from 'utils/FetchHelpers';

export default {
  create(params) {
    const path = Routes.apiV1AdvisorPortfolioParserPath();
    return FetchHelpers.postMultipartFormData(path, params);
  },
  download() {
    const path = Routes.downloadApiV1AdvisorPortfolioImportTemplatesPath();
    return FetchHelpers.download(path);
  },
};
