import React from 'react';
import { Filter, TextInput, List, Datagrid, TextField, ReferenceField } from 'react-admin';

import ExportsRepository from 'repositories/admin/company/ExportsRepository';

import CompanyActions from './CompanyActions';

const CompanyFilter = props => (
  <Filter {...props}>
    <TextInput label="ID" source="idEq" alwaysOn />
    <TextInput label="Name / Cusip / Ticker" source="NameOrCusipOrTickerCont" alwaysOn />
  </Filter>
);

const CompanyList = props => {
  const handleExport = () => {
    ExportsRepository.download();
  };

  return (
    <List filters={<CompanyFilter />} actions={<CompanyActions handleExportButtonClick={handleExport} />} {...props}>
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="cusip" />
        <TextField source="ticker" />
        <ReferenceField source="gics_sub_industry_id" reference="gics_sub_industries">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="current_market_cup" />
        <TextField source="scored" />
      </Datagrid>
    </List>
  );
};

export default CompanyList;
