import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const RemoveIcon = props => {
  return (
    <SvgIcon {...props}>
      <svg width="12" height="12" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.833 3.167a.625.625 0 00-.883-.002l-.001.002-1.861 1.86a.125.125 0 01-.176 0L4.05 3.167a.625.625 0 10-.883.883l1.86 1.861a.125.125 0 010 .177L3.167 7.95a.625.625 0 000 .884.635.635 0 00.883 0l1.862-1.861a.125.125 0 01.176 0l1.862 1.86a.636.636 0 00.884 0 .625.625 0 000-.884L6.973 6.088a.125.125 0 010-.177l1.86-1.861a.625.625 0 000-.883zM6 0a6 6 0 106 6 6.007 6.007 0 00-6-6zm0 11A5 5 0 116 1a5 5 0 010 10z"
          fill="#FFF"
          fillRule="nonzero"
        />
      </svg>
    </SvgIcon>
  );
};

export default RemoveIcon;
