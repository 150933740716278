import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  theme => ({
    radioGroup: {
      textAlign: 'center',
    },
    colTitle: {
      fontSize: 16,
      color: '#232020',
      letterSpacing: 0,
      lineHeight: 1,
      width: 175,
      fontFamily: theme.typography.font.ttHovesPro,
      fontWeight: 300,
      '& span': {
        display: 'inline-block',
        marginTop: 4,
        marginLeft: 17,
      },
    },
    radioRoot: {
      color: '#25333B',
      padding: 0,
      '&:checked': {
        color: '#25333B',
      },
    },
    action: {
      width: 44,
      textAlign: 'center',
    },
    button: {
      border: 'none',
      transform: 'translate(7px, -3px)',
      cursor: 'pointer',
      backgroundColor: 'transparent',
      '&:focus': {
        outline: 'none',
      },
    },
    important: {
      width: 75,
      textAlign: 'center',
    },
    very_important: {
      width: 84,
      textAlign: 'center',
    },
    extremely_important: {
      width: 75,
      textAlign: 'center',
    },
  }),
  { name: 'CustomerCategoryValue' },
);

export default useStyles;
