import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  depositContext: {
    flex: 1,
  },
});

const Card = props => {
  const { title, stat } = props;
  const classes = useStyles();
  return (
    <>
      <Typography component="h2" variant="h5" color="primary" gutterBottom>
        {title}
      </Typography>
      {stat.map(s => (
        <Fragment key={s.name}>
          <Typography component="p" variant="h6">
            {s.name}
          </Typography>
          <Typography color="textSecondary" className={classes.depositContext}>
            {s.value}
          </Typography>
        </Fragment>
      ))}
    </>
  );
};

Card.propTypes = {
  title: PropTypes.string.isRequired,
  stat: PropTypes.arrayOf(PropTypes.object),
};

export default Card;
