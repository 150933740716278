import React from 'react';
import { TextField, Create, SimpleForm, TextInput, ReferenceInput, SelectInput } from 'react-admin';

const AssetClassCategoryCreate = props => (
  <Create {...props}>
    <SimpleForm redirect="list">
      <TextField source="id" />
      <TextInput source="external_id" />
      <TextInput source="name" />
      <TextInput source="code" />
      <TextInput source="priority" />
      <ReferenceInput source="parent_id" reference="asset_class_categories" allowEmpty>
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);

export default AssetClassCategoryCreate;
