import Routes from 'routes/ApiRoutes';
import FetchHelpers from 'utils/FetchHelpers';

export default {
  index(params) {
    const path = Routes.apiV1CustomerAccountsPath();

    return FetchHelpers.get(path, params);
  },
};
