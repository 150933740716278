import { isNil } from 'ramda';

import FundScorePresenter from 'presenters/FundScorePresenter';
import { Weight, WeightLabel, SeeWeightLabel } from 'enums/Weights';

const INIT_INSIGHTS_CHART_DATA = [
  {
    name: Weight.notScored,
    label: WeightLabel.notScored,
    seeWeightLabel: SeeWeightLabel.notScored,
    barOptions: {},
  },
  {
    name: Weight.no,
    label: WeightLabel.no,
    seeWeightLabel: SeeWeightLabel.no,
    barOptions: {},
  },
  {
    name: Weight.somewhat,
    label: WeightLabel.somewhat,
    seeWeightLabel: SeeWeightLabel.somewhat,
    barOptions: {},
  },
  {
    name: Weight.aligned,
    label: WeightLabel.aligned,
    seeWeightLabel: SeeWeightLabel.aligned,
    barOptions: {},
  },
  {
    name: Weight.strongly,
    label: WeightLabel.strongly,
    seeWeightLabel: SeeWeightLabel.strongly,
    barOptions: {},
  },
];

export const getInsightsChartTotalData = insights => {
  const chartData = INIT_INSIGHTS_CHART_DATA.map(data => {
    const barOptions = insights.reduce((acc, fund) => {
      const totalScores = FundScorePresenter.totalScores(fund);
      const value = isNil(totalScores[data.name]) ? 0 : totalScores[data.name];
      const fundName = FundScorePresenter.fundName(fund);
      const options = { value, gradientStartColor: '#873A9E', gradientStopColor: '#5C3169' };

      return { ...acc, [fundName]: options };
    }, {});

    return { ...data, barOptions };
  });

  return chartData;
};

export const getInsightsChartPillarData = (insights, pilarFilter) => {
  const chartData = INIT_INSIGHTS_CHART_DATA.map(data => {
    const barOptions = insights.reduce((acc, fund) => {
      const pillarScores = FundScorePresenter.pillarScores(fund);
      const fundName = FundScorePresenter.fundName(fund);
      const value = isNil(pillarScores[pilarFilter.key][data.name]) ? 0 : pillarScores[pilarFilter.key][data.name];
      const { gradientStartColor, gradientStopColor } = pilarFilter;
      const options = { value, gradientStartColor, gradientStopColor };

      return { ...acc, [fundName]: options };
    }, {});

    return { ...data, barOptions };
  });

  return chartData;
};
