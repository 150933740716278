import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  actions: {
    marginTop: 10,
    width: 70,
  },
  apiErrors: {
    margin: '20px 0',
    color: '#FF0000',
  },
}));

export default useStyles;
