import * as yup from 'yup';
import ConfigurationPresenter from 'presenters/ConfigurationPresenter';

export const validationFields = {
  firstYear: yup.number(),
  thirdYear: yup.number(),
  fifthYear: yup.number(),
  standardDeviation: yup.number(),
  logo: yup.mixed(),
  footerLogo: yup.mixed(),
};

export const validationSchema = yup.object().shape({ ...validationFields });

export const initialValues = configurations => ({
  firstYear: ConfigurationPresenter.firstYear(configurations) || '',
  thirdYear: ConfigurationPresenter.thirdYear(configurations) || '',
  fifthYear: ConfigurationPresenter.fifthYear(configurations) || '',
  standardDeviation: ConfigurationPresenter.standardDeviation(configurations) || '',
  logo: undefined,
  logoUrl: ConfigurationPresenter.logoUrl(configurations) || '',
  footerLogo: undefined,
  footerLogoUrl: ConfigurationPresenter.footerLogoUrl(configurations) || '',
});
