import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';
import { isNil, any } from 'ramda';

import AppLayout from 'layouts/AppLayout';

import AppRoutes from 'routes/AppRoutes';

import CustomerLensCreation from 'containers/CustomerLensCreation';
import Welcome from 'containers/Welcome';
import AdvisorForm from 'containers/AdvisorForm';
import AdvisorEditForm from 'containers/AdvisorEditForm';
import ReflectionReport from 'containers/ReflectionReport';
import MultipleAccountReflectionReport from 'containers/MultipleAccountReflectionReport';
import Recommendation from 'containers/Recommendation';

import UserPresenter from 'presenters/UserPresenter';
import SavedFundPresenter from 'presenters/SavedFundPresenter';
import { isMatchedPath } from 'utils/location';

const AdvisorUserSwitch = withRouter(props => {
  const {
    savedFunds,
    onDestroySavedFund,
    currentUser,
    onSignOut,
    onStopImpersonating,
    currentUserMeta,
    customersList,
    selectedCustomer,
    onChangeSelectedCustomer,
  } = props;

  const { pathname } = props.location;

  const householdId = UserPresenter.householdId(currentUser);
  const nonLayoutPath = [AppRoutes.advisorFormPath(), AppRoutes.advisorEditFormPath()];
  const isNonLayoutPagePath = any(isMatchedPath(pathname), nonLayoutPath);

  if (isNonLayoutPagePath) {
    return (
      <Switch>
        <Route path={AppRoutes.advisorFormPath()} exact>
          <AdvisorForm currentUser={currentUser} />
        </Route>
        <Route path={AppRoutes.advisorEditFormPath()} exact>
          <AdvisorEditForm />
        </Route>
        <Redirect to={AppRoutes.rootPath()} />
      </Switch>
    );
  }

  return (
    <AppLayout
      savedFunds={savedFunds}
      onDestroySavedFund={onDestroySavedFund}
      onSignOut={onSignOut}
      currentUser={currentUser}
      onStopImpersonating={onStopImpersonating}
      currentUserMeta={currentUserMeta}
      customersList={customersList}
      selectedCustomer={selectedCustomer}
      onChangeSelectedCustomer={onChangeSelectedCustomer}
    >
      <Switch>
        <Route path={AppRoutes.rootPath()} exact>
          {isNil(householdId) ? <Redirect to={AppRoutes.advisorFormPath()} /> : <Welcome currentUser={currentUser} />}
        </Route>
        <Route path={AppRoutes.customerLensPath()}>
          <CustomerLensCreation currentUser={currentUser} />
        </Route>
        <Route
          path={AppRoutes.recommendationsPath(':recommendationId')}
          render={routerProps => <Recommendation key={routerProps.match.params.id} />}
        />
        <Route
          path={AppRoutes.portfolioAnalysisPath(':id')}
          render={routerProps => <ReflectionReport key={routerProps.match.params.id} />}
        />
        <Route
          path={AppRoutes.multipleAccountsPortfolioAnalysisPath(':id')}
          render={routerProps => <MultipleAccountReflectionReport key={routerProps.match.params.id} />}
        />
        <Redirect to={AppRoutes.rootPath()} />
      </Switch>
    </AppLayout>
  );
});

AdvisorUserSwitch.propTypes = {
  savedFunds: PropTypes.arrayOf(SavedFundPresenter.shape()).isRequired,
  onDestroySavedFund: PropTypes.func.isRequired,
  onSignOut: PropTypes.func.isRequired,
  currentUser: UserPresenter.shape().isRequired,
  onStopImpersonating: PropTypes.func.isRequired,
  currentUserMeta: PropTypes.shape().isRequired,
  customersList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  selectedCustomer: PropTypes.shape(),
  onChangeSelectedCustomer: PropTypes.func.isRequired,
};

export default AdvisorUserSwitch;
